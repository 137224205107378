<template>
  <div class="block no-filters">
    <el-row v-loading="loading" :gutter="20">
      <el-col :span="24">
        <el-button
          class="float-left"
          round
          @click="$router.push('/settings/users/')"
        >
          <font-awesome-icon icon="long-arrow-alt-left" />Indietro
        </el-button>
      </el-col>
      <el-col :span="24" class="margin-top">
        <el-form
          :model="form"
          label-position="top"
          label-width="100px"
          :rules="rules"
          ref="form"
        >
          <div class="md-title" style="text-align: center; padding: 10px 0">
            {{ operator.surname + " " + operator.name }}
          </div>

          <el-col :span="12">
            <el-form-item label="Ruolo" prop="role_id">
              <el-select
                v-model="form.user_role"
                placeholder="Selezionare ruolo"
              >
                <el-option
                  v-for="(item, index) in roles"
                  :key="index"
                  :label="item"
                  :value="Number(index)"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Professione" prop="profession_id">
              <el-select
                v-model="form.user_profession"
                placeholder="Selezionare professione"
              >
                <el-option
                  v-for="(item, index) in professions"
                  :key="index"
                  :label="item.label"
                  :value="Number(index)"
                  :disabled="!item.enabled"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-divider></el-divider>
            <el-col :span="12" :gutter="0">
              <el-button
                size="large"
                type="warning"
                @click="$router.push('/settings/users')"
              >
                Annulla
              </el-button>
            </el-col>

            <el-col :span="12" :gutter="0" align="right">
              <el-button
                size="large"
                type="success"
                @click.native.prevent="submit()"
                :loading="loading"
              >
                Aggiorna Operatore
              </el-button>
            </el-col>
          </el-col>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import User from "@/js/api/services/User";

export default {
  name: "UsersAdminEdit",
  data() {
    return {
      loading: true,
      roles: [],
      professions: [],
      operator: [],
      form: {
        user_role: null,
        user_profession: null,
      },
      rules: {
        user_role: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        user_profession: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 205;
    },
  },
  methods: {
    async fetch() {
      const response = await User.get(this.$route.params.id);
      this.form.user_role = _.get(response, "data.operator.role_id");
      this.form.user_profession = _.get(
        response,
        "data.operator.profession_id"
      );
      this.roles = response.data.roles;
      this.professions = this.$store.state.professions;
      this.structures = response.data.structures;
      this.operator = response.data.operator;
      this.loading = false;
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.store();
        } else {
          return false;
        }
      });
    },
    async store() {
      const response = await User.update(this.operator.id, this.form);
      this.$router.push({ path: "/settings/users" });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>


<style lang="scss" scoped>
.checkbox-title {
  color: #606266;
}
.md-app-content {
  height: auto !important;
}
</style>

