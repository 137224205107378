<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_glasgow)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_glasgow.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_glasgow.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button class="float-right" round :disabled="disableNext" @click="show(test_next_glasgow)">
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_glasgow" :test_model="test_model_glasgow" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>

         <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q1_result">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="Apertura degli occhi" required />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="24">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="test_glasgow.q1_result">
                            <el-form-item size="mini">
                              <el-radio
                                :label="4"
                              >Apre gli occhi spontaneamente</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="3">Apre gli occhi alla chiamata</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio
                                :label="2"
                              >Apre gli occhi allo stimolo doloroso</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="1">Non apre gli occhi</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q2_result">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="Risposta verbale" required />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="24">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="test_glasgow.q2_result">
                            <el-form-item size="mini">
                              <el-radio
                                :label="5"
                              >Risponde in modo appropriato</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio
                                :label="4"
                              >Risponde in modo confuso</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio
                                :label="3"
                              >Risponde con parole senza senso</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="2">Emette solo suoni inarticolati</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="1">Non risponde</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q3_result">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="Risposta motoria" required />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="24">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="test_glasgow.q3_result">
                            <el-form-item size="mini">
                              <el-radio
                                :label="6"
                              >Esegue ordini semplici</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio
                                :label="5"
                              >Localizza uno stimolo doloroso</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="4">Rifugge dallo stimolo doloroso (retrae)</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio
                                :label="3"
                              >Flette gli arti allo stimolo doloroso</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="2">Estende gli arti allo stimolo doloroso</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="1">Non reagisce</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>

      </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_glasgow.total_string}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Risultato">{{test_glasgow.total_description}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Glasgow",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_glasgow: this.test,
      test_prev_glasgow: this.test_prev,
      test_next_glasgow: this.test_next,
      test_name_glasgow: this.test_name,
      test_model_glasgow: this.test_model,
      test_type_glasgow: this.test_type,
      patient_id_glasgow: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_glasgow,
        test_model: this.test_model_glasgow,
        test_id: test_id,
        type_id: this.test_type_glasgow
      };

      const response = await Test.show(params);
      this.test_glasgow = response.data.test;
      this.test_name_glasgow = response.data.test_name;
      this.test_prev_glasgow = response.data.test_prev;
      this.test_next_glasgow = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_glasgow === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_glasgow === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
