import _ from "lodash";

const isDev = () => _.isEqual(process.env.NODE_ENV, "development");

const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

const extractHttpError = e => {
    const paths = ["response.data", "response.status", "response.statusText"];
    const err = _.pick(e, paths);
    return {
        message: getErrorMessage(err) || 'Errors',
        errorsMap: getErrorsMap(err)
    };
};

const getErrorMessage = err => _.get(err, "response.data.message");

const getErrorsMap = err => _.get(err, "response.data.errors");

export { isDev, sleep, extractHttpError };
