<template>
  <div>
    <arki-filters
      ref="filters"
      @filtered="fetch"
      :dateRangeFilter="true"
      target="exams"
      :optionsFilter="false"
    />
    <router-link to="/exams/create"
      ><el-button
        class="add-button"
        type="success"
        icon="el-icon-plus"
        round
      ></el-button
    ></router-link>
    <arki-broadcast ref="broadcast" listen=".exam.created" @update="update" />
    <div class="block with-filters" v-loading="loading">
      <arki-timeline
        action="Exam"
        v-show="!loading"
        :items="exams"
        :hasIcon="false"
        confirmDescription="Annullare rilevamento di"
        @update="update"
      />
      <div class="show-more text-center">
        <el-button
          icon="el-icon-arrow-down"
          circle
          @click="load"
          :disabled="disabled"
          :loading="moreLoading"
        ></el-button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

import Exam from "@/js/api/services/Exam";

import ArkiFilters from "@/js/components/ArkiFilters";
import ArkiTimeline from "@/js/components/ArkiTimeline";
import ArkiBroadcast from "@/js/components/ArkiBroadcast";

export default {
  name: "Exams",
  components: {
    ArkiFilters,
    ArkiTimeline,
    ArkiBroadcast,
  },
  data() {
    return {
      loading: true,
      moreLoading: false,
      lastPage: null,
      currentPage: 1,
      currentFilters: {},
      exams: [],
    };
  },
  computed: {
    noMore() {
      return this.currentPage == this.lastPage;
    },
    disabled() {
      return this.noMore;
    },
  },
  methods: {
    update() {
      if (this.$refs.filters) {
        this.$refs.filters.emit();
        this.$refs.broadcast.reset();
      }
    },
    async fetch(filters) {
      if (filters.patient_id != this.$route.query.patient_id) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            patient_id: filters.patient_id,
            t: new Date().getTime(),
          },
        });
        return;
      }
      this.loading = true;
      this.currentFilters = filters;
      let params = {
        date_from: moment(filters.dateRange[0]).format("DD/MM/YYYY"),
        date_to: moment(filters.dateRange[1]).format("DD/MM/YYYY"),
        patient_id: filters.patient_id,
        exam_filters: filters.option,
        exam_filters_criticals: filters.criticals,
      };
      const response = await Exam.fetch(params);
      this.exams = response.data.exams.data;
      this.currentPage = 1;
      this.lastPage = response.data.exams.last_page;
      this.loading = false;
    },
    async load() {
      this.moreLoading = true;
      this.currentPage++;
      let filters = this.currentFilters;
      let params = {
          date_from: moment(filters.dateRange[0]).format("DD/MM/YYYY"),
          date_to: moment(filters.dateRange[1]).format("DD/MM/YYYY"),
        patient_id: filters.patient_id,
        exam_filters: filters.option,
        exam_filters_criticals: filters.criticals,
        page: this.currentPage,
      };
      const response = await Exam.fetch(params);
      this.exams = this.exams.concat(response.data.exams.data);
      this.moreLoading = false;
    },
  },
};
</script>
