import _ from 'lodash';

const rules = (numberOfQuestions) => {
    let defaultRule = { required: true, message: "Campo Obbligatorio", trigger: "change" };
    return _.range(numberOfQuestions)
        .reduce((acc, next, idx) => {
            idx++;
            acc[`q${idx}_result`] = defaultRule;
            return acc;
        }, {})
}

const filterResults = (data, specific) => {
    if (specific) {
        return _.pickBy(data, (_, key) => specific.includes(key));
    }
    return _.pickBy(data, (_, key) => key.endsWith("result"));
}


const categories = [
    {
        name: 'Respiro indipendente dalla vocalizzazione',
        result: 'q1_result',
        options: [
            {
                label: 'Normale',
                points: 0
            },
            {
                label: 'Respiro a tratti alterato brevi periodi di iperventilazione',
                points: 1
            },
            {
                label: 'Respiro alterato iperventilazione cheyne stokes',
                points: 2
            },
        ]
    },
    {
        name: 'Vocalizzazione',
        result: 'q2_result',
        options: [
            {
                label: 'Nessuna',
                points: 0
            },
            {
                label: 'Occasionali lamenti saltuarie espressioni negative.',
                points: 1
            },
            {
                label: 'Ripetuti richiami lamenti pianto',
                points: 2
            },
        ]
    },
    {
        name: 'Espressione facciale',
        result: 'q3_result',
        options: [
            {
                label: 'Sorridente o inespressiva',
                points: 0
            },
            {
                label: 'Triste ansiosa contratta',
                points: 1
            },
            {
                label: 'Smorfie',
                points: 2
            },
        ]
    },
    {
        name: 'Linguaggio del corpo',
        result: 'q4_result',
        options: [
            {
                label: 'Rilassato',
                points: 0
            },
            {
                label: `Teso movimenti nervosi irrequietezza `,
                points: 1
            },
            {
                label: 'Rigidità agitazione ginocchia piegate movimento a-finalistico a scatti',
                points: 2
            },
        ]
    },
    {
        name: 'Consolabilità',
        result: 'q5_result',
        options: [
            {
                label: 'Non necessita di consolazione',
                points: 0
            },
            {
                label: 'Distratto o rassicurato da voce o tocco',
                points: 1
            },
            {
                label: 'Inconsolabile non si distrae né si rassicura',
                points: 2
            },
        ]
    },
]


export default { rules, filterResults, categories };
