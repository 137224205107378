<script>
import SelectAts from '../Sosia/SelectAts.vue';
import SelectIstat from '../Sosia/SelectIstat.vue';
import SelectState from '../Sosia/SelectState.vue';
import SelectCommune from '../Sosia/SelectCommune.vue';
import SosiaCdiService from '@/js/api/services/SosiaCdi';
import { cdi1Rules as rules } from './cdiHelper';
import moment from 'moment';

export default {
  name: 'CdiDataForm',
  props: [
    'config',
    'visible',
    'patient',
    'edit',
    'create',
    'hasDismissal',
    'hasExitEvaluation',
    'hasEntryEvaluation',
    'record'
  ],
  components: { SelectAts, SelectIstat, SelectState, SelectCommune },
  data() {
    return {
      rules,
      loading: false,
      loadingSelect: false,
      form: {
        num_pratica: null,
        professione: null,
      },
      domicilioFlag: false,
      invalidState: false,
      pickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
        shortcuts: [
          {
            text: 'Annulla',
            onClick(picker) {
              picker.$emit('pick', null);
            }
          }
        ]
      }
    };
  },
  computed: {
    domicilioCheck() {
      return this.form.com_domicilio || this.domicilioFlag;
    },
    editMode() {
      return Boolean(this.edit && this.edit.id);
    },
    isGenerateDisabled() {
      const f = [this.form.cod_struttura, this.form.anno_ricovero];
      return f.some((x) => x == null);
    },
    admissionOptions() {
      const options = {};
      const data_dimissione = this.form.data_dimissione;
      const disabledDate = (date) => {
        const future = date > new Date();
        let afterDismissal = moment(date).isSameOrAfter(data_dimissione, 'day');
        return future || afterDismissal;
      };
      options.disabledDate = disabledDate;
      options.shortcuts = [
        {
          text: 'Annulla',
          onClick(picker) {
            picker.$emit('pick', null);
          }
        }
      ];
      return options;
    },
    dismissalOptions() {
      const options = {};
      const data_ammissione = this.form.data_ammissione;
      const exitVal = this.edit && this.edit.valutazioni.find((v) => v.tipo_valutazione == 4 && v.deleted_at == null);
      const disabledDate = (date) => {
        const future = date > new Date();
        let beforeAdmission = moment(date).isSameOrBefore(data_ammissione, 'day');
        let beforeExitVal = false;
        if (exitVal) beforeExitVal = moment(date).isSameOrBefore(exitVal.data_valutazione, 'day');
        return future || beforeAdmission || beforeExitVal;
      };
      options.disabledDate = disabledDate;
      options.shortcuts = [
        {
          text: 'Annulla',
          onClick(picker) {
            picker.$emit('pick', null);
          }
        }
      ];
      return options;
    }
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
    generateNumber() {
      const payload = {
        anno_ricovero: this.form.anno_ricovero,
        codice_struttura_rsa: this.form.cod_struttura
      };
      SosiaCdiService.generatePracticeNumber(payload).then((res) => {
        this.form.num_pratica = res.data.value;
        this.$refs['form'].clearValidate();
      });
    },
    fetchLast() {
      this.prefill();
      const loading = this.$loading({
        lock: true,
        text: 'Caricamento in corso',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7) !important'
      });
      SosiaCdiService.lastDataRecord(this.patient.id)
        .then(({ data }) => {
          if (!data.id) return;
          this.form = {
            ...this.form,
            ..._.omit(data, [
              'id',
              'num_pratica',
              'created_at',
              'deleted_at',
              'enabled',
              'updated_at',
              'valutazioni',
              'data_dimissione',
              'cod_individuale',
              'clean_cod_ats',
              'clean_cod_ats_resid',
              'clean_cod_ats_prov',
              'clean_nazione',
              'clean_cittadinanza',
              'clean_com_nascita',
              'clean_com_resid',
              'clean_com_resid_carico',
              'clean_com_domicilio',
              'clean_com_domicilio_carico'
            ])
          };
          this.form.anno_ricovero = String(data.anno_ricovero);
        })
        .finally(() => {
          loading.close();
        });
    },
    onSubmit() {
        if (!this.form.retta_utente == undefined) {
            this.form.retta_utente = this.changeCommasToDots(this.form.retta_utente);
        }
        if (!this.form.retta_comune == undefined) {
            this.form.retta_comune = this.changeCommasToDots(this.form.retta_comune);
        }
        if (!this.form.retta_enti == undefined) {
            this.form.retta_enti = this.changeCommasToDots(this.form.retta_enti);
        }
      this.form.tariffa_ssr = this.changeCommasToDots(this.form.tariffa_ssr);

      this.$refs.form.validate((valid, errors) => {
        if (!valid) {
          this.$message({
            type: 'error',
            message: `Campi non validi`
          });
          return false;
        }
        if (this.form.data_dimissione != null && !this.hasEntryEvaluation) {
          this.$message({
            type: 'error',
            message: `Valutazione all'inserimento obbligatoria`
          });
          return false;
        }
        if (this.form.data_dimissione != null && !this.hasExitEvaluation) {
          this.$message({
            type: 'error',
            message: `Valutazione alla dimissione obbligatoria per chiudere la pratica`
          });
          return false;
        }
        this.editMode ? this.update() : this.store();
      });
    },
    store() {
      this.form = {
        ...this.form,
        tipo_trasmissione: 'I',
        patient_id: this.patient.id
      };
      SosiaCdiService.storeAnagrafica(this.form)
        .then(() => this.$emit('stored'))
        .then(() => this.close());
    },
    update() {
      const payload = {
        ..._.omit(this.form, ['updated_at', 'valutazioni'])
      };
      SosiaCdiService.updateData(this.edit.id, payload)
        .then(() => this.$emit('stored'))
        .then(() => this.close());
    },
    numberOnly($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      const notNumber = (keyCode < 48 || keyCode > 57) && keyCode !== 46;
      if (notNumber) {
        $event.preventDefault();
      }
    },
    setupBirthplace() {
      const params = { string: this.patient.birthplace };
      axios
        .get('/config/istat/comuni', { params })
        .then((res) => {
          if (res.data && res.data.length == 1) {
            this.comuni = res.data;
            this.form.com_nascita = this.comuni[0].codice_istat;
          } else {
            this.comuni = res.data || [];
          }
        })
        .catch(() => {});
    },
    prefill() {
      // prefill unmutable fields
      this.setupBirthplace();
    },
    handleCreate() {
      this.form = {
        num_pratica: null
      };
      this.fetchLast();
    },
    handleEdit() {
      this.form = {
        ...this.form,
        ..._.omit(this.edit, [
          'id',
          'created_at',
          'deleted_at',
          'enabled',
          'cod_individuale',
          'updated_at',
          'valutazioni',
          'clean_cod_ats',
          'clean_cod_ats_resid',
          'clean_cod_ats_prov',
          'clean_nazione',
          'clean_cittadinanza',
          'clean_com_nascita',
          'clean_com_resid',
          'clean_com_resid_carico',
          'clean_com_domicilio',
          'clean_com_domicilio_carico'
        ])
      };
      this.form.num_pratica = null;
      this.form.professione = this.form.professione ? this.form.professione.padStart(2, '0') : null;
      this.edit.num_pratica && (this.form.num_pratica = Number(this.edit.num_pratica));

      this.form.anno_ricovero = String(this.edit.anno_ricovero);
      this.prefill();
    },
    handleInvalidChange(v) {
      this.invalidState = v ? false : true;
    },
    changeCommasToDots(number){
      return number.replace(/,/g, '.')
    }
  },
  watch: {
    'form.anno_ricovero': {
      handler(v) {
        this.handleInvalidChange(v);
      }
    },
    'form.cod_struttura': {
      handler(v) {
        this.handleInvalidChange(v);
      }
    }
  },
  created() {
    if (this.create) this.handleCreate();
    else if (this.edit && this.edit.id) this.handleEdit();
  }
};
</script>
<template>
  <el-dialog
    :visible.sync="visible"
    :title="`${editMode ? 'Modifica Pratica' : 'Nuova Pratica'}`"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :show-close="false"
    :destroy-on-close="true"
    center
    fullscreen
  >
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
          labelPosition="top"
          size="mini"
          :rules="rules"
          v-loading="loading"
        >
          <el-row :gutter="10">
            <el-col :sm="16">
              <el-card shadow="hover">
                <el-row :gutter="10">
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Cittadinanza" prop="cittadinanza">
                      <select-state v-model="form.cittadinanza" :defaultValue="_.get(record, 'clean_cittadinanza')" />
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Comune di nascita" prop="com_nascita">
                      <select-commune v-model="form.com_nascita" :defaultValue="_.get(record, 'clean_com_nascita')" />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10" v-if="form.cittadinanza && form.cittadinanza != 100">
                  <el-divider content-position="left">Tessera TEAM</el-divider>
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Nazione" prop="nazione">
                      <select-state v-model="form.nazione" :defaultValue="_.get(record, 'clean_nazione')" />
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Numero identificazione tessera sanitaria" prop="num_id_ts">
                      <el-input v-model="form.num_id_ts" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Data scadenza tessera sanitaria" prop="scadenza_ts">
                      <el-date-picker
                        v-model="form.scadenza_ts"
                        type="date"
                        placeholder="Selezionare Data"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Numero identificazione istituzione competente" prop="num_id_istituzione">
                      <el-input v-model="form.num_id_istituzione" maxlength="10" show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Nome identificazione istituzione competente" prop="nom_id_istituzione">
                      <el-input v-model="form.nom_id_istituzione" maxlength="21" show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-divider content-position="left">Residenza</el-divider>
                <el-row :gutter="10">
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Codice ATS di residenza" prop="cod_ats_resid">
                      <select-ats v-model="form.cod_ats_resid" :defaultValue="_.get(record, 'clean_cod_ats_resid')" />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Comune" prop="com_resid">
                      <select-commune v-model="form.com_resid" :defaultValue="_.get(record, 'clean_com_resid')" />
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="12">
                    <el-form-item label="Indirizzo" prop="ind_resid">
                      <el-input v-model="form.ind_resid" maxlength="30" show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="4">
                    <el-form-item label="Numero civico" prop="civico_resid">
                      <el-input v-model="form.civico_resid" maxlength="6" show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-divider content-position="left"
                  >Domicilio <el-switch v-model="domicilioFlag" @click="domicilioFlag = !domicilioFlag"></el-switch
                ></el-divider>
                <el-row :gutter="10" v-if="domicilioFlag">
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Comune" prop="com_domicilio">
                      <select-commune
                        v-model="form.com_domicilio"
                        :defaultValue="_.get(record, 'clean_com_domicilio')"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="12">
                    <el-form-item label="Indirizzo" prop="ind_domicilio">
                      <el-input v-model="form.ind_domicilio" maxlength="30" show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="4">
                    <el-form-item label="Numero civico" prop="civico_domicilio">
                      <el-input v-model="form.civico_domicilio" maxlength="6" show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-divider content-position="left">Presa in carico</el-divider>
                <el-row :gutter="10">
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Richiesta di inserimento" prop="richiesta_insert">
                      <el-select v-model="form.richiesta_insert" clearable>
                        <el-option
                          v-for="(item, k) in config.richiesta_inserimento"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Motivazione della richiesta di inserimento" prop="motiv_richiesta_insert">
                      <el-select v-model="form.motiv_richiesta_insert" clearable>
                        <el-option
                          v-for="(item, k) in config.motivazione_richiesta_inserimento"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Valutazione richiesta di inserimento" prop="val_richiesta_insert">
                      <el-select v-model="form.val_richiesta_insert" clearable>
                        <el-option
                          v-for="(item, k) in config.valutazione_richiesta_inserimento"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Provenienza Assistito" prop="prov_assistito">
                      <el-select v-model="form.prov_assistito" clearable>
                        <el-option
                          v-for="(item, k) in config.provenienza_assistito"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Codice ATS di provenienza" prop="cod_ats_prov">
                      <select-ats v-model="form.cod_ats_prov" :defaultValue="_.get(record, 'clean_cod_ats_prov')" />
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Comune di residenza di presa in carico" prop="com_resid_carico">
                      <select-commune
                        v-model="form.com_resid_carico"
                        :defaultValue="_.get(record, 'clean_com_resid_carico')"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Comune di domicilio di presa in carico" prop="com_domicilio_carico">
                      <select-commune
                        v-model="form.com_domicilio_carico"
                        :defaultValue="_.get(record, 'clean_com_domicilio_carico')"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Stato Civile" prop="stato_civile">
                      <el-select v-model="form.stato_civile" clearable>
                        <el-option v-for="(item, k) in config.stato_civile" :key="k" :label="item" :value="Number(k)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Titolo di studio" prop="titolo_studio">
                      <el-select v-model="form.titolo_studio" clearable>
                        <el-option
                          v-for="(item, k) in config.titolo_di_studio"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="8">
                    <el-form-item label="Professione" prop="professione">
                      <el-select v-model="form.professione" clearable>
                        <el-option
                          v-for="(item, k) in config.posizione_nella_professione"
                          :key="k"
                          :label="item"
                          :value="`${k}`"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
            <el-col :sm="8">
              <el-card shadow="hover">
                <el-row :gutter="10">
                  <el-col :md="24">
                    <el-form-item prop="cod_ats">
                      <span slot="label" class="cdiKey">Codice ATS/ASST</span>
                      <select-ats
                        v-model="form.cod_ats"
                        :defaultValue="_.get(record, 'clean_cod_ats')"
                      />
                    </el-form-item>
                    <el-form-item prop="cod_struttura">
                      <span slot="label" class="cdiKey">Codice Struttura CDI</span>
                      <el-input
                        v-model="form.cod_struttura"
                        maxlength="6"
                        minlength="6"
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                    <el-form-item prop="anno_ricovero">
                      <span slot="label" class="cdiKey">Anno di Ricovero</span>
                      <el-date-picker
                        v-model="form.anno_ricovero"
                        type="year"
                        placeholder="Selezionare Anno"
                        value-format="yyyy"
                        :picker-options="pickerOptions"
                      ></el-date-picker>
                    </el-form-item>
                    <el-form-item prop="num_pratica">
                      <span slot="label" class="cdiKey">Numero Pratica</span>
                      <el-input
                        @keypress.native="numberOnly"
                        v-model.number="form.num_pratica"
                        :disabled="isGenerateDisabled || invalidState"
                        ><el-button
                          slot="append"
                          icon="el-icon-refresh-right"
                          @click="generateNumber"
                          :disabled="isGenerateDisabled"
                        ></el-button>
                      </el-input>
                    </el-form-item>
                    <el-form-item prop="data_ammissione">
                      <span slot="label" class="cdiKey">Data di ammissione</span>
                      <el-date-picker
                        v-model="form.data_ammissione"
                        type="date"
                        placeholder="Selezionare Data"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="admissionOptions"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-divider content-position="left">Dimissione</el-divider>
                <el-row :gutter="10">
                  <el-col :md="24">
                    <el-form-item label="Data dimissione" prop="data_dimissione">
                      <el-date-picker
                        v-model="form.data_dimissione"
                        type="date"
                        placeholder="Selezionare Data"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="dismissalOptions"
                        :disabled="Boolean(create)"
                      ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="Motivo della dimissione" prop="motiv_dimissione" v-if="form.data_dimissione">
                      <el-select v-model="form.motiv_dimissione" clearable>
                        <el-option
                          v-for="(item, k) in config.motivo_dimissione"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="Tipologia di dimissione" prop="tipo_dimissione" v-if="form.data_dimissione">
                      <el-select v-model="form.tipo_dimissione" clearable>
                        <el-option
                          v-for="(item, k) in config.tipologia_dimissione"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-divider content-position="left">Dati economici</el-divider>
                <el-row :gutter="10">
                  <el-col :md="24">
                    <el-form-item label="Tariffa SSR" prop="tariffa_ssr">
                      <el-input v-model="form.tariffa_ssr" maxlength="7" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="Retta utente applicata" prop="retta_utente">
                      <el-input v-model="form.retta_utente" maxlength="7" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="Retta a carico del Comune" prop="retta_comune">
                      <el-input v-model="form.retta_comune" maxlength="7" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="Retta a carico di altri Enti" prop="retta_enti">
                      <el-input v-model="form.retta_enti" maxlength="7" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="Tipologia altri enti" prop="tipo_enti">
                      <el-select v-model="form.tipo_enti" clearable>
                        <el-option
                          v-for="(item, index) in config.tipologia_enti"
                          :key="index"
                          :label="item"
                          :value="index"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" justify="center">
            <el-button class="modal-btn" @click="$emit('update:visible', false)">Annulla</el-button>
            <el-button class="modal-btn" type="primary" @click="onSubmit">Salva</el-button>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<style lang="scss">
.el-form-item {
  margin-bottom: 0.6rem;
  .el-form-item__label {
    padding: 0 !important;
  }
}
.el-divider__text {
  font-weight: bold;
}
.modal-btn {
  width: 15%;
  margin: 10px;
}
</style>
