<template>
    <el-form label-position="top" label-width="80px" :model="form" :rules="rules" ref="form">
        <el-row class="form-header" :gutter="5">
            <el-col :span="8">
                <el-form-item label="Data" prop="date">
                    <el-date-picker v-model="form.date" :editable="false" type="date" placeholder="Scegli data"
                        :picker-options="pickerOptions" format="dd/MM/yyyy" value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="Orario" prop="time">
                    <el-time-picker v-model="form.time" :editable="false" format="HH:mm" value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="Seleziona Orario"></el-time-picker>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="Ospite" prop="patient_id">
                    <el-select v-model="form.patient_id" placeholder="Seleziona Ospite" filterable clearable>
                        <el-option v-for="item in patients" :key="item.id" :label="item.full_name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <div class="block with-form-header" v-loading="loading">
            <el-row :gutter="20" v-if="form.patient_id">
                <span v-if="showAbsentPatientAlert" style="float: right; margin-right: 15px;">
                    <font-awesome-icon icon="exclamation" class="danger-text" />
                    <font style="color: red"
                    >Attenzione: l'ospite è attualmente assente</font
                    >
                </span>
                <span v-if="showOldAbsencePatientAlert" style="float: right; margin-right: 15px;">
                    <font-awesome-icon icon="exclamation" class="danger-text" />
                    <font style="color: red"
                        >Attenzione: l'ospite era assente in questo periodo</font
                    >
                </span>
                <el-col :span="12">
                    <el-form-item label="Tipo di evento" prop="event_type">
                        <el-select v-model="form.event_type" filterable clearable>
                            <el-option v-for="item in criticalEventTypes" :key="item.id" :label="item.label"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-divider></el-divider>
                </el-col>

                <el-col :span="24">
                    <el-form-item label="Descrizione" prop="description">
                        <el-input type="textarea" v-model="form.description"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="Suggerimenti">
                        <el-input type="textarea" v-model="form.improvements"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="24">
                    <el-divider></el-divider>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="Evento Risolto" prop="resolved">
                        <el-radio v-model="form.resolved" :label="1" border size="large"
                            @change="handleResolvedChange()">Risolto
                        </el-radio>
                        <el-radio v-model="form.resolved" :label="2" border size="large"
                            @change="handleResolvedChange()">Non risolto
                        </el-radio>
                    </el-form-item>
                </el-col>

                <el-col :span="8" v-if="form.resolved == 1">
                    <el-form-item label="Data" prop="resolved_date">
                        <el-date-picker v-model="form.resolved_date" :editable="false" type="date"
                            :picker-options="pickerOptions" format="dd/MM/yyyy"
                            value-format="yyyy-MM-dd"></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="form.resolved == 1">
                    <el-form-item label="Orario" prop="resolved_time">
                        <el-time-picker v-model="form.resolved_time" format="HH:mm" value-format="yyyy-MM-dd HH:mm:ss"
                            :editable="false"></el-time-picker>
                    </el-form-item>
                </el-col>

                <el-col :span="24" class="margin-top">
                    <el-button size="large" type="success" @click="submit()" class="float-right fixed-confirm-button" plain
                        :loading="submitLoading">Conferma</el-button>
                </el-col>
            </el-row>
        </div>
    </el-form>
</template>
<script>
import moment from "moment";
import Settings from "@/js/api/services/Settings";
import CriticalEvent from "@/js/api/services/CriticalEvent";

export default {
    name: "CreateCriticalEvent",
    data() {
        return {
            patient_id: null,
            loading: false,
            submitLoading: false,
            upload: false,
            criticalEventTypes: [],
            form: {
                date: new Date(),
                time: new Date(),
                resolved_date: new Date(),
                resolved_time: new Date(),
            },
            rules: {
                time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
                date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
                patient_id: [
                    { required: true, message: "Campo Obbligatorio", trigger: "blur" }
                ],
                event_type: [
                    { required: true, message: "Campo Obbligatorio", trigger: "blur" }
                ],
                description: [
                    { required: true, message: "Campo Obbligatorio", trigger: "blur" }
                ],
                resolved_date: [
                    { required: false, message: "Campo Obbligatorio", trigger: "blur" }
                ],
                resolved_time: [
                    { required: false, message: "Campo Obbligatorio", trigger: "blur" }
                ],
                resolved: [
                    { required: true, message: "Campo Obbligatorio", trigger: "blur" }
                ],
                showAbsentPatientAlert: false,
                showOldAbsencePatientAlert: false,
                patient: null,
            },
            pickerOptions: {
                firstDayOfWeek: 1,
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
        };
    },
    computed: {
        patients() {
            return this.$store.state.patients;
        },
    },
    methods: {
        async init() {
            const response = await Settings.fetch("criticalEvents");
            this.criticalEventTypes = response.data;
        },
        submit() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.$confirm("Confermare valutazione?", "Attenzione", {
                        confirmButtonText: "Sì",
                        cancelButtonText: "Annulla",
                        type: "success"
                    })
                        .then(() => {
                            this.submitLoading = true;
                            this.store();
                        })
                        .catch(() => {
                            this.$message({
                                type: "info",
                                message: "Operazione annullata"
                            });
                        });
                } else {
                    return false;
                }
            });
        },
        async store() {
            let params = {
                date: moment(this.form.date).format("YYYY-MM-DD"),
                time: moment(this.form.time).format("HH:mm:ss"),
                resolved: this.form.resolved,
                resolved_date: moment(this.form.resolved_date).format("YYYY-MM-DD"),
                resolved_time: moment(this.form.resolved_time).format("HH:mm:ss"),
                data: this.form
            };
            try {
                await CriticalEvent.store(params);
                if (this.upload) {
                    this.$refs.uploader.submit();
                }
                this.$router.push({ name: "Eventi Critici" });
            } catch (error) {
                this.submitLoading = false;
            }
        },
        handleResolvedChange() {
            if (this.form.resolved == 1) {
                this.rules.resolved_date[0].required = true;
                this.rules.resolved_time[0].required = true;
            } else {
                this.rules.resolved_date[0].required = false;
                this.rules.resolved_time[0].required = false;
            }
        },
    },
    watch: {
        form: {
            deep: true,
            handler(data) {
                if (this.form.patient_id) {
                    this.patient = _.filter(this.patients, { id: this.form.patient_id })[0];
                    this.showAbsentPatientAlert = this.patient.absence_info.absence_status;
                    this.showOldAbsencePatientAlert = false;
                    if (!this.showAbsentPatientAlert && this.patient.absence_info.last_absence) {
                        var date = `${moment(this.form.date).format("YYYY-MM-DD")} ${moment(this.form.time).format("HH:mm:ss")}`;
                        if (date > this.patient.absence_info.last_absence['confirmation_date'] && date < this.patient.absence_info.last_absence['verification_date']) {
                            this.showOldAbsencePatientAlert = true;
                        }
                    }
                }
            }
        },
    },
    mounted() {
        this.init();
    }
};
</script>
<style lang="scss" scoped>
.margin-top {
    margin-top: 15px;
}
</style>
