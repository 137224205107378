<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-divider content-position="center">CRITERI ASSOLUTI</el-divider>
            <el-col :span="24">
              <el-form-item>
                <el-row>
                  <el-col :span="24">
                    <el-form-item
                      label="È motivo di appartenenza alla categoria dei non autosufficienti per 'ragioni sanitarie' il riscontro di almeno una delle seguenti situazioni:"
                    />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="24">
                    <el-row>
                      <el-col>
                        <div>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q1_result"
                              :true-label="1"
                              :false-label="0"
                            >Grave deficit di forza e/o di movimento ad almeno due arti</el-checkbox>
                          </el-form-item>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q2_result"
                              :true-label="1"
                              :false-label="0"
                            >Doppia incontinenza</el-checkbox>
                          </el-form-item>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q3_result"
                              :true-label="1"
                              :false-label="0"
                            >Piaghe da decubito</el-checkbox>
                          </el-form-item>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q4_result"
                              :true-label="1"
                              :false-label="0"
                            >Gravi disturbi del linguaggio e della comunicazione</el-checkbox>
                          </el-form-item>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q5_result"
                              :true-label="1"
                              :false-label="0"
                            >Marcata compromissione delle capacità visive e/o uditive non correggibili con protesi</el-checkbox>
                          </el-form-item>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q6_result"
                              :true-label="1"
                              :false-label="0"
                            >Malattie in fase terminale (quando si prevede il decesso entro 3-6 mesi)</el-checkbox>
                          </el-form-item>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q7_result"
                              :true-label="1"
                              :false-label="0"
                            >Necessità di terapie multiple e complesse</el-checkbox>
                          </el-form-item>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-divider content-position="center">CRITERI RELATIVI</el-divider>
              <el-form-item>
                <el-row>
                  <el-col :span="24">
                    <el-form-item
                      label="È motivo di appartenenza alla categoria dei non autosufficienti per 'ragioni sanitarie' il riscontro di tre o più delle seguenti situazioni:"
                    />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="24">
                    <el-row>
                      <el-col>
                        <div>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q8_result"
                              :true-label="1"
                              :false-label="0"
                            >Modesto deficit di forza e/o di movimento ad almeno due arti</el-checkbox>
                          </el-form-item>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q9_result"
                              :true-label="1"
                              :false-label="0"
                            >Incontinenza saltuaria</el-checkbox>
                          </el-form-item>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q10_result"
                              :true-label="1"
                              :false-label="0"
                            >Confusione temporo-spaziale episodica</el-checkbox>
                          </el-form-item>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q11_result"
                              :true-label="1"
                              :false-label="0"
                            >Disturbi del linguaggio e della comunicazione moderati</el-checkbox>
                          </el-form-item>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q12_result"
                              :true-label="1"
                              :false-label="0"
                            >Deficit sensoriali visivi e/o uditivi solo parzialmente correggibili</el-checkbox>
                          </el-form-item>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q13_result"
                              :true-label="1"
                              :false-label="0"
                            >Turbe vertiginose con tendenza alle cadute</el-checkbox>
                          </el-form-item>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q14_result"
                              :true-label="1"
                              :false-label="0"
                            >Patologie croniche di rilievo in compenso labile</el-checkbox>
                          </el-form-item>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q15_result"
                              :true-label="1"
                              :false-label="0"
                            >Uso obbligato ma autonomo della carrozzella</el-checkbox>
                          </el-form-item>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">{{totalDescription}}</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults, createResults } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Adico",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 2
      },
      result: {},
      testRules: {},
      totalDescription: "Non dipendente",
      aResults: createResults(1, 8),
      bResults: createResults(8, 16)
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_description: this.totalDescription
          };
          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    computePartialResults() {
      const { aResults, bResults } = this;
      let a = _.size(
        _.values(filterResults(this.result, aResults)).filter(Boolean)
      );
      let b = _.size(
        _.values(filterResults(this.result, bResults)).filter(Boolean)
      );
      return [a, b];
    },
    resetForm() {
      _.keys(this.result).map(k => (this.result[k] = 0));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return  !_.every(formValues, x => x === 0);
    }
  },
  watch: {
    result: {
      handler: function() {
        let [a, b] = this.computePartialResults();
        let desc = "Non dipendente";
        let total = 0;
        if (b === 2) {
          desc = "Parzialmente dipendente";
          total = 1;
        }

        if (a > 0 || b >= 3) {
          desc = "Dipendente per motivi sanitari";
          total = 2;
        }

        this.totalDescription = desc;
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.bottom-space {
  margin-bottom: 50px;
}
</style>