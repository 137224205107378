<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item>
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="A" />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="24">
                    <el-row>
                      <el-col>
                        <div>
                          <el-form-item size="mini">
                            <el-checkbox
                              v-model="result.q1_result"
                              :true-label="1"
                              :false-label="0"
                            >Assenza o lievi disturbi cognitivi e del comportamento</el-checkbox>
                          </el-form-item>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <!-- <el-divider /> -->
            </el-col>
          </el-col>
        </el-col>
        <div v-if="!aChecked">
          <el-col :span="24">
            <el-col :span="24">
              <el-col :span="24">
                <el-form-item>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="B" />
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :offset="1" :span="24">
                      <el-row>
                        <el-col>
                          <div>
                            <el-form-item size="mini">
                              <el-checkbox
                                v-model="result.q2_result"
                                :true-label="1"
                                :false-label="0"
                              >1. Aggressività verbale</el-checkbox>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-checkbox
                                v-model="result.q3_result"
                                :true-label="1"
                                :false-label="0"
                              >2. Pone domande ripetitivamente</el-checkbox>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-checkbox
                                v-model="result.q4_result"
                                :true-label="1"
                                :false-label="0"
                              >3. Si sente ansioso, agitato, preoccupato, costantemente inquieto</el-checkbox>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-checkbox
                                v-model="result.q5_result"
                                :true-label="1"
                                :false-label="0"
                              >4. Allucinazioni/deliri</el-checkbox>
                            </el-form-item>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-divider />
              </el-col>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-col :span="24">
                <el-form-item>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="C" />
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :offset="1" :span="24">
                      <el-row>
                        <el-col>
                          <div>
                            <el-form-item size="mini">
                              <el-checkbox
                                v-model="result.q6_result"
                                :true-label="2"
                                :false-label="0"
                              >5.Attività motoria afinalistica (wandering, fughe)</el-checkbox>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-checkbox
                                v-model="result.q7_result"
                                :true-label="2"
                                :false-label="0"
                              >6. Aggressività fisica</el-checkbox>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-checkbox
                                v-model="result.q8_result"
                                :true-label="2"
                                :false-label="0"
                              >7. Comportamento socialmente inadeguato/disinibizione</el-checkbox>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-checkbox
                                v-model="result.q9_result"
                                :true-label="2"
                                :false-label="0"
                              >8.Alterazione severa del ritmo sonno veglia/insonnia grave</el-checkbox>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-checkbox
                                v-model="result.q10_result"
                                :true-label="2"
                                :false-label="0"
                              >9. Rifiuto dell’assistenza</el-checkbox>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-checkbox
                                v-model="result.q11_result"
                                :true-label="2"
                                :false-label="0"
                              >10. Comportamento alimentare gravemente alterato</el-checkbox>
                            </el-form-item>
                          </div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                </el-form-item>
              </el-col>
            </el-col>
          </el-col>
        </div>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults, createResults } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Adico",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 16
      },
      result: {},
      testRules: {},
      aResults: createResults(1, 2),
      bResults: createResults(2, 6),
      cResults: createResults(6, 12)
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$emit("submitTest", this.form, this.result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    computePartialResults() {
      const { aResults, bResults, cResults } = this;
      let a = _.values(filterResults(this.result, aResults)).map(() => 0);

      let b = _.values(filterResults(this.result, bResults))
        .filter(Boolean)
        .map(() => 1);

      let c = _.values(filterResults(this.result, cResults))
        .filter(Boolean)
        .map(() => 2);
      return [a, b, c];
    },
    checkA() {
      const {
        result: { q1_result },
        bResults,
        cResults
      } = this;
      if (_.isEqual(q1_result, 1)) {
        const keys = _.flattenDeep([bResults, cResults]);
        keys.map(k => this.$delete(this.result, k));
      }
    },
    resetForm() {
      _.keys(this.result).map(k => (this.result[k] = 0));
    }
  },
  computed: {
    aChecked() {
      return this.result.q1_result === 1;
    },
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, x => x === 0);
    }
  },
  watch: {
    result: {
      handler: function() {
        this.checkA();
        let results = _.flattenDeep(this.computePartialResults());
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.bottom-space {
  margin-bottom: 50px;
}
</style>