import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Settings extends ArkiBaseService {
    
    static endpoint = 'settings';

    static fetch(target){
        return Api.get(`${this.endpoint}/${target}`);
    }
}