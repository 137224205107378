import Api from '@/js/api/Api';

export default class SuperadminPatientsService {

    static endpoint = 'superadmin/patients';

    static update(id, params) {
        return Api.post(`${this.endpoint}/${id}`, params);
    }

    static export(params) {
        return Api.get(`${this.endpoint}/export`, { params, responseType: 'blob' })
    }

    static getPatients(structure, option) {

        let params = {
            all: structure,
            option: option
        }

        return Api.get(this.endpoint, {
            params: params
        })
    }
}
