<script>
export default {
  name: 'CdiPatientsExportModal',
  props: ['showExportModal'],
  data() {
    return {
      loading: false,
      exportForm: {},
      optionsTrimestre: {
        1: 'I Trimestre',
        2: 'II Trimestre',
        3: 'III Trimestre',
        4: 'IV Trimestre'
      },
      pickerOptions: {
        disabledDate(date) {
          return date > new Date();
        }
      },
      sosiaRules: {
        year: [{ required: true, message: 'Campo Obbligatorio', trigger: ['blur', 'change'] }],
        quarter: [{ required: true, message: 'Campo Obbligatorio', trigger: ['blur', 'change'] }],
      }
    };
  },
  methods: {
    closeExportDialog() {
      this.$emit('update:showExportModal', false);
    },
    onExportSosiaPress() {
      this.$refs['sosiaForm'].validate((valid) => {
        if (!valid) return false;
        this.$emit('exportSosia', this.exportForm);
      });
    }
  },
  watch: {
    exportForm: {
      handler(v) {
        this.$refs.sosiaForm.clearValidate();
      },
      deep: true
    }
  }
};
</script>

<template>
  <el-dialog
    append-to-body
    title="Export Sosia"
    :center="true"
    :visible.sync="showExportModal"
    width="50%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <div>
      <el-form ref="sosiaForm" :model="exportForm" label-width="120px" labelPosition="top" :rules="sosiaRules">
        <el-row :gutter="10">
          <el-col>
            <el-form-item prop="year">
              <el-date-picker
                v-model="exportForm.year"
                type="year"
                placeholder="Anno"
                value-format="yyyy"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item prop="quarter">
              <el-select v-model="exportForm.quarter" placeholder="Trimestre">
                <el-option v-for="(item, index) in optionsTrimestre" :key="index" :label="item" :value="index">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div slot="footer">
      <el-button type="warning" style="width: 120px" @click="closeExportDialog">Annulla</el-button>
      <el-button type="success" style="width: 120px" @click.native.prevent="onExportSosiaPress" :disabled="loading"
        >Export</el-button
      >
    </div>
  </el-dialog>
</template>

<style scss scoped>
.container {
  height: 100%;
  margin: 5px;
}
</style>