<template>
  <div>
    <!-- scheduler modal -->
    <md-dialog :md-active.sync="schedulerVisible">
      <md-dialog-title>Schedula medicazione</md-dialog-title>
      <md-dialog-content>
        <el-form
          label-position="top"
          label-width="80px"
          :model="schedulerForm"
          ref="schedulerForm"
          :rules="schedulerRules"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Data" prop="date">
                <el-date-picker
                  v-model="schedulerForm.date"
                  type="date"
                  placeholder="Scegli data"
                  :editable="false"
                  :picker-options="{
                    firstDayOfWeek: 1,
                    disabledDate(time, schedulerPrevDate) {
                      return (
                        time.getTime() < Date.now() - 3600 * 1000 * 24 ||
                        (schedulerPrevDate != null &&
                          time.getTime() < schedulerPrevDate)
                      );
                    },
                  }"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Orario" prop="time">
                <el-time-picker
                  v-model="schedulerForm.time"
                  :editable="false"
                  format="HH:mm"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="Seleziona Orario"
                  :picker-options="{
                    selectableRange: selectableTime,
                  }"
                >
                </el-time-picker>
                <span
                  v-if="
                    schedulerPrevDate >= schedulerForm.date + schedulerForm.time
                  "
                >
                  alert
                </span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="Note" prop="note">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="Note"
                  v-model="schedulerForm.note"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </md-dialog-content>
      <md-dialog-actions>
        <el-button type="info" plain @click="closeScheduler()"
          >Annulla</el-button
        >
        <el-button
          type="success"
          plain
          @click="actionConfirmAlert(schedulerForm.medication, 'scheduler')"
          :loading="schedulerLoading"
          >Salva</el-button
        >
      </md-dialog-actions>
    </md-dialog>
    <!-- arki-filter -->
    <arki-filters
      ref="filters"
      @filtered="fetch"
      target="medications"
      :dateRangeFilter="true"
      :optionsFilter="true"
    />
    <!-- medication create -->
    <router-link to="/medications/create">
      <el-button
        class="add-button"
        type="success"
        icon="el-icon-plus"
        round
      ></el-button>
    </router-link>
    <!-- arki-broadcast -->
    <arki-broadcast
      ref="broadcast"
      listen=".medication.created"
      @update="update"
    />
    <!-- medication list -->
    <div class="with-filters">
      <el-table
        :class="paginateTable"
        :data="medications.data"
        :default-sort="{ prop: 'time', order: 'descending' }"
        style="width: 100%"
        :row-class-name="rowClass"
        v-loading="loading"
        :max-height="maxHeight"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-col
              :xl="12"
              :lg="12"
              :md="24"
              v-if="scope.row.allPictures.length"
            >
              <el-carousel
                :autoplay="false"
                arrow="always"
                class="mobile-margin-bottom"
              >
                <el-carousel-item
                  v-for="picture in scope.row.allPictures"
                  :key="picture.id"
                >
                  <img
                    :src="`/app/public/${picture.path}`"
                    class="carousel-image"
                  />
                </el-carousel-item>
              </el-carousel>
            </el-col>
            <el-col :xl="12" :lg="12" :md="24">
              <el-col
                :span="8"
                v-if="scope.row.stadio_npuap"
                class="margin-top-10"
              >
                <b>Stadio (NPUAP):</b>
                {{ config.stadio[scope.row.stadio_npuap] }}
              </el-col>
              <el-col :span="8" v-if="scope.row.cute_perilesionale">
                <b>Cute Perilesionale:</b>
                {{ config.cute_perilesionale[scope.row.cute_perilesionale] }}
              </el-col>

              <el-col :span="8" v-if="scope.row.rbc">
                <b>RBC:</b>
                {{ scope.row.rbc }} g/dL
              </el-col>
              <el-col :span="8" v-if="scope.row.albumina">
                <b>Albumina:</b>
                {{ scope.row.albumina }} g/dL
              </el-col>
              <el-col :span="8" v-if="scope.row.hgb">
                <b>HGB:</b>
                {{ scope.row.hgb }} g/dL
              </el-col>
              <el-col :span="8" v-if="scope.row.protidemia">
                <b>Protidemia:</b>
                {{ scope.row.protidemia }} g/dL
              </el-col>
              <el-col :span="8" v-if="scope.row.wbc">
                <b>WBC:</b>
                {{ scope.row.wbc }} g/dL
              </el-col>
              <el-col :span="8" v-if="scope.row.sideremia">
                <b>Sideremia:</b>
                {{ scope.row.sideremia }} g/dL
              </el-col>

              <el-col :span="24"></el-col>

              <el-col :span="8" v-if="scope.row.ferita_altezza">
                <b>Altezza Ferita:</b>
                {{ scope.row.ferita_altezza }} cm
              </el-col>
              <el-col :span="8" v-if="scope.row.ferita_larghezza">
                <b>Larghezza Ferita:</b>
                {{ scope.row.ferita_larghezza }} cm
              </el-col>
              <el-col :span="8" v-if="scope.row.ferita_profondita">
                <b>Profondità Ferita:</b>
                {{ scope.row.ferita_profondita }} cm
              </el-col>

              <el-col :span="24"></el-col>

              <el-col :span="8" v-if="scope.row.tessuto">
                <b>Tessuto:</b>
                {{ config.tessuto[scope.row.tessuto] }}
              </el-col>
              <el-col :span="8" v-if="scope.row.essudato">
                <b>Essudato:</b>
                {{ config.essudato[scope.row.essudato] }}
              </el-col>
              <el-col :span="8" v-if="scope.row.dolore">
                <b>Dolore:</b>
                {{ config.dolore[scope.row.dolore] }}
              </el-col>

              <el-col :span="24" v-if="scope.row.medicazione_primaria">
                <b>Medicazione Primaria:</b>
                {{ scope.row.medicazione_primaria }}
              </el-col>
              <el-col :span="24" v-if="scope.row.medicazione_secondaria">
                <b>Medicazione Secondaria:</b>
                {{ scope.row.medicazione_secondaria }}
              </el-col>
              <el-col :span="24" v-if="scope.row.terapia_sistemica">
                <b>Terapia Sistemica:</b>
                {{ scope.row.terapia_sistemica }}
              </el-col>

              <el-col :span="24">
                <b>Inserimento:</b>
                {{ scope.row.full_creator }} - {{ scope.row.created_at_time }}
              </el-col>
              <el-col :span="24" v-if="scope.row.full_verification_operator">
                <b v-if="scope.row.enabled == 1">Verifica:</b>
                <b v-else-if="scope.row.enabled == 0">Annullamento:</b>
                {{ scope.row.full_verification_operator }} -
                {{ scope.row.clean_verification_time }}
                {{ scope.row.clean_verification_date }}
                {{ scope.row.removed_at_time }}
              </el-col>
              <el-col :span="24" v-if="scope.row.verification_note">
                <b>Note:</b>
                {{ scope.row.verification_note }}
              </el-col>
            </el-col>
          </template>
        </el-table-column>
        <el-table-column
          prop="clean_date_time"
          label="Data e Ora"
          sortable
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="full_patient"
          label="Ospite"
          min-width="200"
        ></el-table-column>
        <el-table-column label="Descrizione" min-width="200">
          <template slot-scope="scope">{{
            scope.row.wound.description
          }}</template>
        </el-table-column>
        <el-table-column label="Esito" align="right" min-width="190">
          <template slot-scope="scope">
            <el-button-group>
              <!-- annullo e rischedulo medicazione (medicazione da fare o ultima medicazione con lesione guarita )-->
              <el-button
                type="warning"
                plain
                @click="showScheduler(scope.row)"
                v-if="
                  scope.row.creator_id == user.id &&
                  scope.row.enabled == 1 &&
                  (scope.row.verification_result == 0 ||
                    (scope.row.verification_result == 1 &&
                      scope.row.wound.healted == 1 &&
                      scope.row.verification_date ==
                        scope.row.wound.healted_date &&
                      scope.row.created_at != scope.row.wound.created_at))
                "
                ><i class="el-icon-time" style="text-decoration: none"></i
              ></el-button>
              <!-- annullo medicazione (medicazione gia eseguita ma che non sia ne la prima ne l'ultima )-->
              <el-button
                type="warning"
                plain
                @click="actionConfirmAlert(scope.row, 'delete')"
                v-if="
                  scope.row.creator_id == user.id &&
                  scope.row.enabled == 1 &&
                  scope.row.verification_result == 1 &&
                  scope.row.verification_date != scope.row.wound.healted_date &&
                  scope.row.date_time != scope.row.wound.date_time
                "
                ><i
                  class="el-icon-circle-close"
                  style="text-decoration: none"
                ></i
              ></el-button>
              <!-- elimino medicazione e lesione -->
              <el-button
                type="danger"
                plain
                @click="actionConfirmAlert(scope.row, 'delete-all')"
                v-if="scope.row.creator_id == user.id && scope.row.enabled == 1"
                ><i class="el-icon-delete" style="text-decoration: none"></i
              ></el-button>
              <!-- eseguo medicazione -->
              <el-button
                icon="el-icon-check"
                plain
                class="float-right margin-left"
                type="success"
                @click="edit(scope.row.id)"
                v-if="
                  scope.row.enabled === 1 && scope.row.verification_result === 0
                "
              ></el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="medications.per_page"
        :total="medications.total"
        :hide-on-single-page="true"
        :current-page="current_page"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Wound from "@/js/api/services/Wound";
import Medication from "@/js/api/services/Medication";
import ArkiFilters from "@/js/components/ArkiFilters";
import ArkiCheckDialog from "@/js/components/ArkiCheckDialog";
import ArkiBroadcast from "@/js/components/ArkiBroadcast";

export default {
  name: "Medications",
  components: {
    ArkiFilters,
    ArkiCheckDialog,
    ArkiBroadcast,
  },
  data() {
    return {
      loading: true,
      submitLoading: false,
      woundTypes: [],
      medications: {
        data: [],
        per_page: null,
        total: null,
      },
      current_page: 1,
      patient_id: null,
      schedulerRules: {
        date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
        time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
        note: [
          {
            required: true,
            message: "Inserire motivo della rischedulazione",
            trigger: "blur",
          },
        ],
      },
      paginateTable: null,
      // first fetch is from filter
      unchangedFilter: false,
      schedulerVisible: false,
      schedulerForm: {},
      schedulerLoading: false,
      schedulerPrevDate: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    config() {
      return this.$store.state.config.wounds;
    },
    maxHeight() {
      return window.innerHeight - 235;
    },
    selectableTime() {
      let selectedDate = moment(this.schedulerForm.date);
      let prevDate = moment(this.schedulerPrevDate);
      if (selectedDate.format("YYYY-MM-DD") == prevDate.format("YYYY-MM-DD")) {
        return prevDate.add(1, "minutes").format("HH:mm:ss") + " - 23:59:59";
      } else {
        return "00:00:00 - 23:59:59";
      }
    },
  },
  watch: {
    medications: {
      handler: function () {
        this.medications.total / this.medications.per_page > 1
          ? (this.paginateTable = "paginate-table")
          : (this.paginateTable = null);
      },
      deep: true,
    },
  },
  methods: {
    update() {
      this.unchangedFilter = true;
      if (this.$refs.filters) {
        this.$refs.filters.emit();
        this.$refs.broadcast.reset();
      }
    },
    async fetch(filters) {
      if (filters.patient_id != this.$route.query.patient_id) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            patient_id: filters.patient_id || undefined,
            t: new Date().getTime(),
          },
        });
        return;
      }
      // need to reset page in case filter change
      if (!this.unchangedFilter) {
        this.current_page = 1;
      }
      this.loading = true;
      let params = {
        date_from: moment(filters.dateRange[0]).format("DD/MM/YYYY"),
        date_to: moment(filters.dateRange[1]).format("DD/MM/YYYY"),
        patient_id: filters.patient_id,
        wound_type: filters.option,
        page: this.current_page,
      };
      const response = await Medication.fetch(params);
      this.medications.data = response.data.medications.data;
      this.medications.total = response.data.medications.total;
      this.medications.per_page = response.data.medications.per_page;
      this.loading = false;
      // reset the checked flag
      this.unchangedFilter = false;
    },
    changePage(page) {
      this.loading = true;
      this.current_page = page;
      this.update();
    },
    edit(id) {
      this.$router.push(`/medications/${id}/edit`);
    },
    actionConfirmAlert(medication, action) {
      let wound = medication.wound;
      switch (action) {
        case "scheduler":
          this.$refs["schedulerForm"].validate((valid) => {
            if (valid) {
              let message = "";
              if (wound.healted)
                message = `Rischedulare la medicazione "${wound.description}"? Comporterà la riapertura della lesione`;
              else
                message = `Rischedulare la medicazione "${wound.description}"?`;

              this.$confirm(message, "Attenzione", {
                confirmButtonText: "OK",
                cancelButtonText: "Annulla",
                type: "warning",
              })
                .then(() => {
                  this.rescheduleMedication(medication.id);
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "Operazione annullata",
                  });
                });
            } else {
              return false;
            }
          });
          break;
        case "delete":
          this.$confirm(
            `Annullare la medicazione "${wound.description}"?`,
            "Attenzione",
            {
              confirmButtonText: "OK",
              cancelButtonText: "Annulla",
              type: "warning",
            }
          )
            .then(() => {
              this.deleteMedication(medication.id);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "Operazione annullata",
              });
            });
          break;
        case "delete-all":
          this.$confirm(
            `Annullare la medicazione "${wound.description}"? Comporterà l'annullamento di tutte le rivalutazioni e della lesione stessa`,
            "Attenzione",
            {
              confirmButtonText: "OK",
              cancelButtonText: "Annulla",
              type: "warning",
            }
          )
            .then(() => {
              this.deleteWound(wound.id);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "Operazione annullata",
              });
            });
          break;
      }
    },
    async rescheduleMedication(id) {
      this.schedulerForm.date = moment(this.schedulerForm.date).format(
        "YYYY-MM-DD"
      );
      this.schedulerForm.time = moment(this.schedulerForm.time).format(
        "HH:mm:ss"
      );
      this.schedulerForm.patient_id = this.schedulerForm.medication.patient_id;
      delete this.schedulerForm.medication;

      await Medication.reschedule(id, this.schedulerForm);
      this.closeScheduler();
      this.update();
    },
    async deleteMedication(id) {
      await Medication.destroy(id);
      this.update();
    },
    async deleteWound(id) {
      await Wound.destroy(id);
      this.update();
    },
    rowClass({ row, rowIndex }) {
      if (row.enabled == 0) {
        return "deleted-row";
      } else if (row.verification_result == 2) {
        return "warning-row";
      } else if (row.verification_result == 1) {
        return "success-row";
      }
    },
    showScheduler(medication) {
      this.schedulerForm.medication = medication;
      this.schedulerPrevDate = medication.prev_executed_medicatione_date;
      this.schedulerVisible = true;
    },
    closeScheduler() {
      this.setFormDefaultValues();
      this.schedulerLoading = false;
      this.schedulerVisible = false;
    },
    setFormDefaultValues() {
      this.schedulerForm = {
        medication: null,
        date: new Date(),
        time: new Date(),
        note: null,
      };
      this.schedulerPrevDate = null;
    },
  },
  mounted() {
    this.setFormDefaultValues();
  },
};
</script>
<style lang="scss" scoped>
.margin-left {
  margin-left: 10px;
}
.carousel-image {
  height: 100%;
  width: auto;
}

@media screen and (max-width: 1199px) {
  .mobile-margin-bottom {
    margin-bottom: 15px;
  }
}
</style>
