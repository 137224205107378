import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class User extends ArkiBaseService {

    static endpoint = 'operators';

    static config() {
        return Api.get('config/roles');
    }

    static check(params) {
        return Api.post(`${this.endpoint}/check`, params);
    }

    static disassociate(structure, id) {
        return Api.post(`${this.endpoint}/disassociate/${structure}/${id}`);
    }

    static restore(structure, id) {
        return Api.post(`${this.endpoint}/restore/${structure}/${id}`);
    }

    static roles() {
        return Api.get('settings/roles');
    }

    static professions() {
        return Api.get('settings/professions');
    }

    static inStructure(structure_id) {
        return Api.get(`${this.endpoint}/structure/${structure_id}`);
    }

    static exampleExport(params) {
        return Api.get(`${this.endpoint}/exampleExport`, { params, responseType: 'blob' })
    }

    static importFile(params) {
        return Api.post(`${this.endpoint}/importFile`, params)
    }
}
