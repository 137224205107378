import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Sosia2 extends ArkiBaseService {

    static endpoint = 'sosia2';

    static config() {
        return Api.get(`${this.endpoint}/config`);
    }

    static patients(params) {
        return Api.get(`${this.endpoint}/patients`, { params });
    }

    static findByPatient(id, params) {
        return Api.get(`${this.endpoint}/patient/${id}`, { params });
    }

    static deleteAbsence(id){
        return Api.delete(`${this.endpoint}/assenza/${id}`);
    }

    static updateAbsence(id, payload){
        return Api.patch(`${this.endpoint}/assenza/${id}`, payload);
    }

    static storeAbsence(payload){
        return Api.post(`${this.endpoint}/assenza` , payload);
    }

    static deleteTeam(id){
        return Api.delete(`${this.endpoint}/team/${id}`);
    }

    static updateTeam(id, payload){
        return Api.patch(`${this.endpoint}/team/${id}`, payload);
    }

    static storeTeam(payload){
        return Api.post(`${this.endpoint}/team` , payload);
    }

    static deleteRate(id){
        return Api.delete(`${this.endpoint}/retta/${id}`);
    }

    static updateRate(id, payload){
        return Api.patch(`${this.endpoint}/retta/${id}`, payload);
    }

    static storeRate(payload){
        return Api.post(`${this.endpoint}/retta` , payload);
    }

    static deleteTreatment(id){
        return Api.delete(`${this.endpoint}/trattamento/${id}`);
    }

    static updateTreatment(id, payload){
        return Api.patch(`${this.endpoint}/trattamento/${id}`, payload);
    }

    static storeTreatment(payload){
        return Api.post(`${this.endpoint}/trattamento` , payload);
    }

    static generatePracticeNumber(payload) {
        return Api.post(`${this.endpoint}/generate/practice`, payload);
    }

    static deleteEvaluation(id){
        return Api.delete(`${this.endpoint}/valutazione/${id}`);
    }

    static updateEvaluation(id, payload){
        return Api.patch(`${this.endpoint}/valutazione/${id}`, payload);
    }

    static storeEvaluation(payload){
        return Api.post(`${this.endpoint}/valutazione` , payload);
    }

    static deleteValidation(id){
        return Api.delete(`${this.endpoint}/valutazione/${id}`);
    }

    static updateValidation(id, payload){
        return Api.patch(`${this.endpoint}/valutazione/convalida/${id}`, payload);
    }

    static storeValidation(id, payload){
        return Api.post(`${this.endpoint}/valutazione/${id}/convalida` , payload);
    }

    static printPdf(id){
        return Api.get(`${this.endpoint}/print/${id}`, { responseType: 'blob', Accept: 'application/pdf'});
    }

    static export(params) {
        return Api.get(`${this.endpoint}/export`, { params, responseType: 'blob' })
    }

    static exportPdf(params) {
        return Api.get(`${this.endpoint}/exportPdf`, { params, responseType: 'blob' })
    }

    static exportExcel(params) {
        return Api.get(`${this.endpoint}/exportExcel`, { params, responseType: 'blob' })
    }

    static lastEvaluationRecord(id) {
        return Api.get(`${this.endpoint}/trattamento/${id}/ultima-valutazione`);
    }
}