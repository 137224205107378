import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Auth extends ArkiBaseService {

    static endpoint = 'auth';

    static getStructures() {
        return Api.get(`${this.endpoint}/get_structures`);
    }

    static fetchDepartments(params) {
        return Api.post(`${this.endpoint}/get_departments`, params);
    }

    static checkDepartment(params) {
        return Api.post('check_department', params);
    }

    static getDepartment() {
        return Api.get('department');
    }

    static getUser() {
        return Api.get('user');
    }

    static generateTwoFactorCode() {
        return Api.post('two_factor_auth_code');
    }

    static reGenerateTwoFactorCode(params) {
        return Api.post(`${this.endpoint}/two_factor_auth_code_renew`, params);
    }

    static patientsExport() {
        return Api.post(`${this.endpoint}/patients_export`);
    }

    static guideExport() {
        return axios({
            url: `${this.endpoint}/guide_export`,
            method: 'POST',
            responseType: 'blob'
        });
    }

    static updateLdapPassword(payload) {
        return Api.post(`${this.endpoint}/ldap/updatePassword`, payload).then(({ data }) => data);
    }

    static resetLdapPassword(payload) {
        return Api.post(`${this.endpoint}/ldap/resetPassword`, payload).then(({ data }) => data);
    }
}
