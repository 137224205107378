import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Test extends ArkiBaseService {

    static endpoint = 'tests';

    static show(params) {
        return Api.get(`${this.endpoint}/detail`, {
            params: params
        })
    }

    static delete(params) {
        return Api.delete(`${this.endpoint}/delete`, {
            params: params
        })
    }

    static getTestChartData(params) {
        return Api.get(`${this.endpoint}/chart-data`, {
            params: params
        })
    }

    static getTestChartDataRss(params) {
        return Api.get(`${this.endpoint}/chart-data-rss`, {
            params: params
        })
    }

    static getTestConfig() {
        return Api.get(`${this.endpoint}/config`)
    }

    static getTestData(params) {
        return Api.get(`${this.endpoint}/form-data`, {
            params: params
        })
    }
    static generatePdf(params) {
        return axios({
            url: `${this.endpoint}/print_pdf/${params['test_model']}/${params['test_id']}`,
            method: 'GET',
            responseType: 'blob'
        })
    }
}
