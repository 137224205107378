import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Comunication extends ArkiBaseService {

    static endpoint = 'comunications';

    static count() {
        return Api.get(`${this.endpoint}/count`);
    }

    static markAsSeen(id, params) {
        return Api.patch(`${this.endpoint}/${id}/seen`, params);
    }
}
