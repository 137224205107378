<script>
import SelectAts from "../SelectAts.vue";
import SelectIstat from "../SelectIstat.vue";
import SelectState from "../SelectState.vue";
import SelectCommune from "../SelectCommune.vue";
import SelectDiagnosis from "../SelectDiagnosis.vue";
import SosiaService from "@/js/api/services/Sosia2";
import moment from "moment";

export default {
  name: "SosiaEvaluationForm",
  props: [
    "visible",
    "record",
    "config",
    "patientId",
    "treatmentInfo",
    "isInitial",
    "initialDate",
  ],
  components: {
    SelectAts,
    SelectIstat,
    SelectState,
    SelectCommune,
    SelectDiagnosis,
  },
  data() {
    return {
      loading: false,
      form: {
        data_inizio_condizione: null,
        tipologia_di_verifica: null,
        indicatore_mobilita_trasferimento_letto_sedia: null,
        indicatore_mobilita_deambulazione: null,
        indicatore_mobilita_locomozione_su_sedia_a_rotelle: null,
        indicatore_mobilita_igiene_personale: null,
        indicatore_mobilita_alimentazione: null,
        indicatore_cognitivita_e_comportamento_confusione: null,
        indicatore_cognitivita_e_comportamento_irritabilita: null,
        indicatore_cognitivita_e_comportamento_irrequietezza: null,
        indicatore_comorbilita_cardiaca: null,
        indicatore_comorbilita_ipertensione: null,
        indicatore_comorbilita_vascolari: null,
        indicatore_comorbilita_respiratorie: null,
        indicatore_comorbilita_oongl: null,
        indicatore_comorbilita_apparato_gastro_superiore: null,
        indicatore_comorbilita_apparato_gastro_inferiore: null,
        indicatore_comorbilita_epatiche: null,
        indicatore_comorbilita_renali: null,
        indicatore_comorbilita_altre_patologie: null,
        indicatore_comorbilita_muscolo_scheletrico: null,
        indicatore_comorbilita_sistema_nervoso: null,
        indicatore_comorbilita_endocrine: null,
        indicatore_comorbilita_psichiatrico: null,
        diagnosi1: null,
        diagnosi2: null,
        diagnosi3: null,
        diagnosi4: null,
        diagnosi5: null,
        diagnosi6: null,
        diagnosi7: null,
        diagnosi8: null,
        lesioni_da_decubito: null,
        catetere_urinario: null,
        cadute: 2,
        numero_cadute: 0,
        contenzione_permanente: null,
        ausili_per_movimento: null,
        gestione_incontinenza: null,
        gestione_diabete: null,
        alimentazione_artificiale: null,
        insufficienza_respiratoria: null,
        dialisi: null,
        data_evento_indice: null,
      },
      calcolaLoading: false,
      editMode: Boolean(_.get(this.record, "id")),
      lastEvaluationRecord: null,
      pickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
        shortcuts: [
          {
            text: "Annulla",
            onClick(picker) {
              picker.$emit("pick", null);
            },
          },
        ],
      },
      saveLoading: false,
    };
  },
  computed: {
    options() {
      return this.$store.state.config.sosia;
    },
    dateOptions() {
      const options = {};
      const ingresso =
        this.form.tipologia_di_verifica == "I" ? null : this.initialDate;
      const ammissione = this.treatmentInfo.data_ammissione;
      const dimissione = this.treatmentInfo.data_dimissione;
      const disabledDate = (date) => {
        const future = date > new Date();
        let before = moment(date).isBefore(ammissione, "day");
        let after = false;
        let beforeInit = false;
        if (dimissione) after = moment(date).isSameOrAfter(dimissione, "day");
        if (ingresso) beforeInit = moment(date).isSameOrBefore(ingresso, "day");
        return [future, before, after, beforeInit].some(Boolean);
      };
      options.disabledDate = disabledDate;
      options.shortcuts = [
        {
          text: "Annulla",
          onClick(picker) {
            picker.$emit("pick", null);
          },
        },
      ];
      return options;
    },
    rules() {
      return {
        data_inizio_condizione: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        tipologia_di_verifica: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_mobilita_trasferimento_letto_sedia: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_mobilita_deambulazione: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_mobilita_locomozione_su_sedia_a_rotelle: [
          {
            required: this.form.indicatore_mobilita_deambulazione == 1,
            message: "Campo Obbligatorio",
            trigger: "change",
          },
        ],
        indicatore_mobilita_igiene_personale: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_mobilita_alimentazione: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_cognitivita_e_comportamento_confusione: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_cognitivita_e_comportamento_irritabilita: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_cognitivita_e_comportamento_irrequietezza: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_cardiaca: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_ipertensione: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_vascolari: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_respiratorie: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_oongl: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_apparato_gastro_superiore: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_apparato_gastro_inferiore: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_epatiche: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_renali: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_altre_patologie: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_muscolo_scheletrico: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_sistema_nervoso: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_endocrine: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indicatore_comorbilita_psichiatrico: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        lesioni_da_decubito: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        catetere_urinario: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        cadute: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        numero_cadute: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        contenzione_permanente: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        ausili_per_movimento: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        gestione_incontinenza: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        gestione_diabete: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        alimentazione_artificiale: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        insufficienza_respiratoria: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        dialisi: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        data_evento_indice: [
          {
            required: [8, "C", "D"].some(
              (x) => x == this.treatmentInfo.tipo_ospite
            ),
            message: "Campo Obbligatorio",
            trigger: "change",
          },
        ],
      };
    },
    statoVegetativo() {
      return [8, "C", "D"].some((x) => x == this.treatmentInfo.tipo_ospite);
    },
    tipoVerificaOptions() {
      const all = this.config.tipologia_di_verifica;
      return _.cond([
        [_.matches({ editMode: true }), _.constant(all)],
        [_.matches({ isInitial: true }), _.constant(_.pick(all, ["I"]))],
        [_.matches({ isInitial: false }), _.constant(_.pick(all, ["S"]))],
      ])(this);
    },
  },
  methods: {
    validationFields() {
      return [
        "lesioni_da_decubito",
        "catetere_urinario",
        "cadute",
        "numero_cadute",
        "contenzione_permanente",
        "ausili_per_movimento",
        "gestione_incontinenza",
        "gestione_diabete",
        "alimentazione_artificiale",
        "insufficienza_respiratoria",
        "dialisi",
      ];
    },
    fetchLast() {
      const loader = this.$loading({
        lock: true,
        text: "Caricamento in corso",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7) !important",
      });

      let omittedField = [
        "tipo_record",
        "trattamento_id",
        "data_inizio_condizione",
        "patient_id",
        "id",
        "valutazione_id",
        "data_convalida",
        "tipologia_di_verifica",
        "created_at",
        "updated_at",
        "deleted_at",
        "clean_inizio",
        "clean_fine",
        "clean_convalida",
        "clean_evento_indice",
        "convalide",
      ];

      SosiaService.lastEvaluationRecord(this.treatmentInfo.id)
        .then(({ data }) => {
          if (!data.id) return;
          this.lastEvaluationRecord = data;
          if (!this.samePeriod(data)) {
            omittedField.push(...this.validationFields());
          }
          this.form = {
            ...this.form,
            ..._.omit(data, omittedField),
          };
        })
        .finally(() => loader.close());
    },
    dateUpdateHandler(date) {
      const q1 = new moment(date);
      const q2 = new moment(
        _.get(this.lastEvaluationRecord, "data_inizio_condizione")
      );

      const samePeriod = [
        q1.year() === q2.year(),
        q1.quarter() === q2.quarter(),
      ].every(Boolean);

      if (!date || samePeriod) {
        Object.assign(
          this.form,
          _.pick(this.lastEvaluationRecord, this.validationFields())
        );
        return;
      }

      if (samePeriod) return;

      const nulled = this.validationFields().reduce(
        (res, field) => Object.assign(res, { [field]: null }),
        {}
      );

      Object.assign(this.form, nulled);
      this.form.cadute = 2;
      this.form.numero_cadute = 0;
    },
    samePeriod(last) {
      const prev = last.data_inizio_condizione;
      return [
        moment().year() == moment(prev).year(),
        moment().quarter() == moment(prev).quarter(),
      ].every(Boolean);
    },
    defaultDiagnosi(diagnosiNumber) {
      const fromRecord = this.editMode
        ? this.record
        : this.lastEvaluationRecord;
      return _.get(fromRecord, `clean_diagnosi${diagnosiNumber}`);
    },
    onSubmit() {
      this.saveLoading = true;
      this.$refs.form.validate((valid, errors) => {
        if (!valid) {
          this.$message({
            type: "error",
            message: `Campi non validi`,
          });
          this.saveLoading = false;
          return false;
        }
        this.editMode ? this.update() : this.create();
      });
    },
    save() {
      this.editMode ? this.update() : this.create();
    },
    create() {
      const payload = {
        ...this.form,
        patient_id: this.patientId,
        trattamento_id: this.treatmentInfo.id,
      };
      SosiaService.storeEvaluation(payload)
        .then(() => this.$root.$emit("fetchPatient"))
        .then(() => {
            this.close();
            this.saveLoading = false;
        });
    },
    update() {
      const payload = {
        ...this.form,
        patient_id: this.record.patient_id,
        trattamento_id: this.treatmentInfo.id,
      };
      SosiaService.updateEvaluation(this.record.id, payload)
        .then(() => this.$root.$emit("fetchPatient"))
        .then(() => {
            this.close();
            this.saveLoading = false;
        });
    },
    close() {
      this.$emit("update:visible", false);
    },
    updateNumber(value) {
      if (value == 2) {
        this.form.numero_cadute = 0;
      }
    },
    classeSosia() {
      this.calcolaLoading = true;
      this.sosia_m =
        this.options.sosia_44_45[
          this.form.indicatore_mobilita_trasferimento_letto_sedia
        ] *
          0.151 +
        this.options.sosia_44_45[this.form.indicatore_mobilita_deambulazione] *
          0.275 +
        this.options.sosia_46_47[
          this.form.indicatore_mobilita_igiene_personale
        ] *
          0.621 +
        this.options.sosia_48[this.form.indicatore_mobilita_alimentazione] *
          0.081;

      if (this.sosia_m <= 4) this.sosia_m = 1;
      else this.sosia_m = 2;

      this.sosia_c =
        this.options.sosia_49_50_51[
          this.form.indicatore_cognitivita_e_comportamento_confusione
        ] *
          0.984 +
        this.options.sosia_49_50_51[
          this.form.indicatore_cognitivita_e_comportamento_irrequietezza
        ] *
          0.406 +
        this.form.indicatore_comorbilita_psichiatrico * 0.608;

      if (this.sosia_c >= 7.5) this.sosia_c = 1;
      else this.sosia_c = 2;

      this.sosia_s =
        this.options.sosia_52_104[this.form.indicatore_comorbilita_cardiaca] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_ipertensione
        ] *
        this.options.sosia_52_104[this.form.indicatore_comorbilita_vascolari] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_respiratorie
        ] *
        this.options.sosia_52_104[this.form.indicatore_comorbilita_oongl] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_apparato_gastro_superiore
        ] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_apparato_gastro_inferiore
        ] *
        this.options.sosia_52_104[this.form.indicatore_comorbilita_epatiche] *
        this.options.sosia_52_104[this.form.indicatore_comorbilita_renali] *
        //ELEVATO ALLA 3
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_altre_patologie
        ] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_altre_patologie
        ] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_altre_patologie
        ] *
        //ELEVATO ALLA 3
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_muscolo_scheletrico
        ] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_muscolo_scheletrico
        ] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_muscolo_scheletrico
        ] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_sistema_nervoso
        ] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_sistema_nervoso
        ] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_sistema_nervoso
        ] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_sistema_nervoso
        ] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_sistema_nervoso
        ] *
        this.options.sosia_52_104[this.form.indicatore_comorbilita_endocrine] *
        this.options.sosia_52_104[
          this.form.indicatore_comorbilita_psichiatrico
        ];

      if (this.sosia_s > 64) this.sosia_s = 1;
      else this.sosia_s = 2;

      switch (true) {
        case this.sosia_m == 1 && this.sosia_c == 1 && this.sosia_s == 1:
          this.form.classe = 1;
          break;
        case this.sosia_m == 1 && this.sosia_c == 1 && this.sosia_s == 2:
          this.form.classe = 2;
          break;
        case this.sosia_m == 1 && this.sosia_c == 2 && this.sosia_s == 1:
          this.form.classe = 3;
          break;
        case this.sosia_m == 1 && this.sosia_c == 2 && this.sosia_s == 2:
          this.form.classe = 4;
          break;
        case this.sosia_m == 2 && this.sosia_c == 1 && this.sosia_s == 1:
          this.form.classe = 5;
          break;
        case this.sosia_m == 2 && this.sosia_c == 1 && this.sosia_s == 2:
          this.form.classe = 6;
          break;
        case this.sosia_m == 2 && this.sosia_c == 2 && this.sosia_s == 1:
          this.form.classe = 7;
          break;
        case this.sosia_m == 2 && this.sosia_c == 2 && this.sosia_s == 2:
          this.form.classe = 8;
          break;
        default:
          this.$notify({
            title: "Errore",
            message: "Compilare tutti gli indicatori!!",
            type: "error",
          });
          break;
      }
      this.calcolaLoading = false;
    },
  },
  created() {
    if (this.editMode) {
      const fields = _.pick(this.record, [
        "data_inizio_condizione",
        "tipologia_di_verifica",
        "indicatore_mobilita_trasferimento_letto_sedia",
        "indicatore_mobilita_deambulazione",
        "indicatore_mobilita_locomozione_su_sedia_a_rotelle",
        "indicatore_mobilita_igiene_personale",
        "indicatore_mobilita_alimentazione",
        "indicatore_cognitivita_e_comportamento_confusione",
        "indicatore_cognitivita_e_comportamento_irritabilita",
        "indicatore_cognitivita_e_comportamento_irrequietezza",
        "indicatore_comorbilita_cardiaca",
        "indicatore_comorbilita_ipertensione",
        "indicatore_comorbilita_vascolari",
        "indicatore_comorbilita_respiratorie",
        "indicatore_comorbilita_oongl",
        "indicatore_comorbilita_apparato_gastro_superiore",
        "indicatore_comorbilita_apparato_gastro_inferiore",
        "indicatore_comorbilita_epatiche",
        "indicatore_comorbilita_renali",
        "indicatore_comorbilita_altre_patologie",
        "indicatore_comorbilita_muscolo_scheletrico",
        "indicatore_comorbilita_sistema_nervoso",
        "indicatore_comorbilita_endocrine",
        "indicatore_comorbilita_psichiatrico",
        "diagnosi1",
        "diagnosi2",
        "diagnosi3",
        "diagnosi4",
        "diagnosi5",
        "diagnosi6",
        "diagnosi7",
        "diagnosi8",
        "lesioni_da_decubito",
        "catetere_urinario",
        "cadute",
        "numero_cadute",
        "contenzione_permanente",
        "ausili_per_movimento",
        "gestione_incontinenza",
        "gestione_diabete",
        "alimentazione_artificiale",
        "insufficienza_respiratoria",
        "dialisi",
        "data_evento_indice",
      ]);
      Object.assign(this.form, fields);
    } else {
      this.fetchLast();
    }
  },
};
</script>
<template>
  <el-dialog
    :visible.sync="visible"
    title="Dati Sanitari Sensibili"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :show-close="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    center
    width="80%"
  >
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
          labelPosition="top"
          size="mini"
          :rules="rules"
          v-loading="loading"
          :validate-on-rule-change="false"
        >
          <el-row :gutter="10">
            <el-col :span="24">
              <el-card shadow="hover">
                <el-row :gutter="20" class="no-margin-row">
                  <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                    <el-form-item
                      label="Tipologia Verifica"
                      prop="tipologia_di_verifica"
                    >
                      <el-select
                        v-model="form.tipologia_di_verifica"
                        clearable
                        :disabled="editMode"
                      >
                        <el-option
                          v-for="(item, index) in tipoVerificaOptions"
                          :key="index"
                          :label="item"
                          :value="index"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                    <el-form-item
                      label="Data inizio condizione"
                      prop="data_inizio_condizione"
                    >
                      <el-date-picker
                        v-model="form.data_inizio_condizione"
                        type="date"
                        size="mini"
                        placeholder="Selezionare Data"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="dateOptions"
                        @change="dateUpdateHandler"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col
                    :xs="12"
                    :sm="12"
                    :md="8"
                    :lg="8"
                    :xl="8"
                    v-if="statoVegetativo"
                  >
                    <el-tooltip placement="bottom" effect="light">
                      <div slot="content">
                        Obbligatorio se l'ospite è classificato "Stato
                        Vegetativo".
                      </div>
                      <el-form-item
                        label="Data Evento Indice"
                        prop="data_evento_indice"
                      >
                        <el-date-picker
                          v-model="form.data_evento_indice"
                          type="date"
                          placeholder="Selezionare Data"
                          format="dd/MM/yyyy"
                          value-format="yyyy-MM-dd"
                        ></el-date-picker>
                      </el-form-item>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="no-margin-row">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-divider content-position="right"
                      >Indicatore Mobilità</el-divider
                    >
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <el-form-item
                      label="Trasferimento Letto Sedia"
                      prop="indicatore_mobilita_trasferimento_letto_sedia"
                    >
                      <el-select
                        v-model="
                          form.indicatore_mobilita_trasferimento_letto_sedia
                        "
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_mobilita_trasferimento_letto_sedia"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <el-form-item
                      label="Deambulazione"
                      prop="indicatore_mobilita_deambulazione"
                    >
                      <el-select
                        v-model="form.indicatore_mobilita_deambulazione"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_mobilita_deambulazione"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <el-form-item
                      label="Locomozione su Sedia a Rotelle"
                      prop="indicatore_mobilita_locomozione_su_sedia_a_rotelle"
                    >
                      <el-select
                        v-model="
                          form.indicatore_mobilita_locomozione_su_sedia_a_rotelle
                        "
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_mobilita_locomozione_su_sedia_a_rotelle"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <el-form-item
                      label="Igiene Personale"
                      prop="indicatore_mobilita_igiene_personale"
                    >
                      <el-select
                        v-model="form.indicatore_mobilita_igiene_personale"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_mobilita_igiene_personale"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <el-form-item
                      label="Alimentazione"
                      prop="indicatore_mobilita_alimentazione"
                    >
                      <el-select
                        v-model="form.indicatore_mobilita_alimentazione"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_mobilita_alimentazione"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-divider content-position="right"
                      >Indicatore Cognitività e Comportamento</el-divider
                    >
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <el-form-item
                      label="Confusione"
                      prop="indicatore_cognitivita_e_comportamento_confusione"
                    >
                      <el-select
                        v-model="
                          form.indicatore_cognitivita_e_comportamento_confusione
                        "
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_cognitivita_e_comportamento_confusione"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <el-form-item
                      label="Irritabilità"
                      prop="indicatore_cognitivita_e_comportamento_irritabilita"
                    >
                      <el-select
                        v-model="
                          form.indicatore_cognitivita_e_comportamento_irritabilita
                        "
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_cognitivita_e_comportamento_irritabilità"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                    <el-form-item
                      label="Irrequietezza"
                      prop="indicatore_cognitivita_e_comportamento_irrequietezza"
                    >
                      <el-select
                        v-model="
                          form.indicatore_cognitivita_e_comportamento_irrequietezza
                        "
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_cognitivita_e_comportamento_irrequietezza"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-divider content-position="right"
                      >Indicatore Comorbilità</el-divider
                    >
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Cardiaca"
                      prop="indicatore_comorbilita_cardiaca"
                    >
                      <el-select
                        v-model="form.indicatore_comorbilita_cardiaca"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Ipertensione"
                      prop="indicatore_comorbilita_ipertensione"
                    >
                      <el-select
                        v-model="form.indicatore_comorbilita_ipertensione"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Vascolari"
                      prop="indicatore_comorbilita_vascolari"
                    >
                      <el-select
                        v-model="form.indicatore_comorbilita_vascolari"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Respiratorie"
                      prop="indicatore_comorbilita_respiratorie"
                    >
                      <el-select
                        v-model="form.indicatore_comorbilita_respiratorie"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="O.O.N.G.L"
                      prop="indicatore_comorbilita_oongl"
                    >
                      <el-select
                        v-model="form.indicatore_comorbilita_oongl"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Apparato G.I. Superiore"
                      prop="indicatore_comorbilita_apparato_gastro_superiore"
                    >
                      <el-select
                        v-model="
                          form.indicatore_comorbilita_apparato_gastro_superiore
                        "
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Apparato G.I. Inferiore"
                      prop="indicatore_comorbilita_apparato_gastro_inferiore"
                    >
                      <el-select
                        v-model="
                          form.indicatore_comorbilita_apparato_gastro_inferiore
                        "
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Epatiche"
                      prop="indicatore_comorbilita_epatiche"
                    >
                      <el-select
                        v-model="form.indicatore_comorbilita_epatiche"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Renali"
                      prop="indicatore_comorbilita_renali"
                    >
                      <el-select
                        v-model="form.indicatore_comorbilita_renali"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Genito Urinarie"
                      prop="indicatore_comorbilita_altre_patologie"
                    >
                      <el-select
                        v-model="form.indicatore_comorbilita_altre_patologie"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Muscolo-Scheletrico, Cute"
                      prop="indicatore_comorbilita_muscolo_scheletrico"
                    >
                      <el-select
                        v-model="
                          form.indicatore_comorbilita_muscolo_scheletrico
                        "
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Sistema Nervoso"
                      prop="indicatore_comorbilita_sistema_nervoso"
                    >
                      <el-select
                        v-model="form.indicatore_comorbilita_sistema_nervoso"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Endocrine - Metaboliche"
                      prop="indicatore_comorbilita_endocrine"
                    >
                      <el-select
                        v-model="form.indicatore_comorbilita_endocrine"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Psichiatrico Comportamentali"
                      prop="indicatore_comorbilita_psichiatrico"
                    >
                      <el-select
                        v-model="form.indicatore_comorbilita_psichiatrico"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.indicatore_comorbilita_valori_assoluti"
                          :key="index"
                          :label="index + '. ' + item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Calcolatore Classe SOSIA"
                      prop="classe"
                    >
                      <el-col :span="12">
                        <b>Risultato:</b> {{ this.form.classe }}
                      </el-col>
                      <el-col :span="12">
                        <el-button
                          type="success"
                          class="w-100"
                          :loading="calcolaLoading"
                          @click="classeSosia()"
                          >Calcola</el-button
                        >
                      </el-col>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-divider content-position="right">Diagnosi</el-divider>
                  </el-col>

                  <el-col
                    :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="6"
                    :xl="6"
                    v-for="item in _.range(1, 9)"
                    :key="'diagnosi' + item"
                  >
                    <el-form-item
                      :label="`Diagnosi N°${item}`"
                      :prop="`diagnosi${item}`"
                    >
                      <select-diagnosis
                        v-model="form[`diagnosi${item}`]"
                        :defaultValue="defaultDiagnosi(item)"
                      />
                    </el-form-item>
                  </el-col>

                  <el-col :span="24">
                    <el-divider></el-divider>
                  </el-col>

                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Lesioni da Decubito"
                      prop="lesioni_da_decubito"
                    >
                      <el-select v-model="form.lesioni_da_decubito" clearable>
                        <el-option
                          v-for="(item, index) in options.valori_assoluti"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Catetere Urinario"
                      prop="catetere_urinario"
                    >
                      <el-select v-model="form.catetere_urinario" clearable>
                        <el-option
                          v-for="(item, index) in options.valori_assoluti"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item label="Cadute" prop="cadute">
                      <el-select
                        v-model="form.cadute"
                        clearable
                        @change="updateNumber"
                      >
                        <el-option
                          v-for="(item, index) in options.valori_assoluti"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col
                    :xs="24"
                    :sm="12"
                    :md="8"
                    :lg="6"
                    :xl="6"
                    v-if="form.cadute == 1"
                  >
                    <el-form-item label="Numero di Cadute" prop="numero_cadute">
                      <el-input-number
                        v-model="form.numero_cadute"
                        controls-position="right"
                        :min="0"
                        :max="999"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Contenzione Permanente"
                      prop="contenzione_permanente"
                    >
                      <el-select
                        v-model="form.contenzione_permanente"
                        clearable
                      >
                        <el-option
                          v-for="(item, index) in options.valori_assoluti"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Ausili per Movimento"
                      prop="ausili_per_movimento"
                    >
                      <el-select v-model="form.ausili_per_movimento" clearable>
                        <el-option
                          v-for="(item, index) in options.ausili_per_movimento"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Gestione Incontinenza"
                      prop="gestione_incontinenza"
                    >
                      <el-select v-model="form.gestione_incontinenza" clearable>
                        <el-option
                          v-for="(item, index) in options.gestione_incontinenza"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Gestione Diabete"
                      prop="gestione_diabete"
                    >
                      <el-select v-model="form.gestione_diabete" clearable>
                        <el-option
                          v-for="(item, index) in options.gestione_diabete"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Alimentazione Artificiale"
                      prop="alimentazione_artificiale"
                    >
                      <el-select
                        v-model="form.alimentazione_artificiale"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.alimentazione_artificiale"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Insufficienza Respiratoria"
                      prop="insufficienza_respiratoria"
                    >
                      <el-select
                        v-model="form.insufficienza_respiratoria"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.insufficienza_respiratoria"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item label="Dialisi" prop="dialisi">
                      <el-select v-model="form.dialisi" clearable>
                        <el-option
                          v-for="(item, index) in options.dialisi"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" justify="center">
            <el-button class="modal-btn" @click="$emit('update:visible', false)"
              >Annulla</el-button
            >
            <el-button class="modal-btn" type="primary" @click="onSubmit" :loading="saveLoading"
              >Salva</el-button
            >
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<style lang="scss">
.el-form-item {
  margin-bottom: 0.6rem;
  .el-form-item__label {
    padding: 0 !important;
  }
}
.el-divider__text {
  font-weight: bold;
}
.modal-btn {
  width: 15%;
  margin: 10px;
}
</style>
