require("./bootstrap");
import Vue from "vue";
import Vuex from "vuex";
import UUID from "vue-uuid";
import _ from "lodash";

_.mixin({
    clearObject: obj => Object.keys(obj).forEach(key => delete obj[key]),
    machineName: string =>
        string
            .toLocaleLowerCase()
            .replace(/[àáäâ]/g, "a")
            .replace(/[èéëê]/g, "e")
            .replace(/[ìíïî]/g, "i")
            .replace(/[òóöô]/g, "o")
            .replace(/[ùúüû]/g, "u")
            .replace(/[^A-Za-z0-9 ]/g, "")
            .replace(/\s{2,}/g, " ")
            .replace(/\s/g, "-")
});

//VUE MATERIAL
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";

//ELEMENT UI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import locale from "element-ui/lib/locale/lang/it";

import "es6-promise/auto";
import axios from "axios";

Vue.use(UUID);
Vue.use(require("vue-moment"));

import Echo from "laravel-echo";
import VueEcho from "vue-echo";

import VueAuth from "@websanova/vue-auth";
import VueAxios from "vue-axios";
import VueRouter from "vue-router";

import auth from "./core/auth";
import router from "./core/router";
import store from "./core/store";

//Layouts
import Index from "./layouts/Index";
import Default from "./layouts/Default";
import Arkicare from "./layouts/Arkicare";
import Superadmin from "./layouts/Superadmin";

//FONTAWESOME
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faBookMedical,
    faTasks,
    faThermometerHalf,
    faBath,
    faDumbbell,
    faClipboard,
    faClipboardCheck,
    faPuzzlePiece,
    faChalkboardTeacher,
    faUsers,
    faPlus,
    faLevelUpAlt,
    faExclamationTriangle,
    faShieldAlt,
    faEllipsisV,
    faTrashAlt,
    faSignOutAlt,
    faCameraRetro,
    faExclamation,
    faBandAid,
    faLongArrowAltRight,
    faLongArrowAltLeft,
    faCogs,
    faToilet,
    faToiletPaper,
    faPills,
    faBars,
    faTablets,
    faUtensils,
    faEdit,
    faUserShield,
    faCalendarTimes,
    faCheckCircle,
    faTimesCircle,
    faUserInjured,
    faTemperatureHigh,
    faCheck,
    faPrescriptionBottleAlt,
    faBalanceScaleRight,
    faKey,
    faDoorOpen,
    faBell,
    faFileAlt,
    faExchangeAlt,
    faHardHat,
    faFileDownload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
    faBookMedical,
    faTasks,
    faThermometerHalf,
    faBath,
    faDumbbell,
    faClipboard,
    faClipboardCheck,
    faPuzzlePiece,
    faChalkboardTeacher,
    faUsers,
    faPlus,
    faLevelUpAlt,
    faExclamationTriangle,
    faShieldAlt,
    faEllipsisV,
    faTrashAlt,
    faSignOutAlt,
    faCameraRetro,
    faExclamation,
    faShieldAlt,
    faBandAid,
    faLongArrowAltLeft,
    faLongArrowAltRight,
    faCogs,
    faToilet,
    faToiletPaper,
    faPills,
    faBars,
    faTablets,
    faUtensils,
    faEdit,
    faUserShield,
    faCalendarTimes,
    faCheckCircle,
    faTimesCircle,
    faUserInjured,
    faTemperatureHigh,
    faBandAid,
    faCheck,
    faTrashAlt,
    faPrescriptionBottleAlt,
    faBalanceScaleRight,
    faKey,
    faDoorOpen,
    faBell,
    faFileAlt,
    faExchangeAlt,
    faHardHat,
    faFileDownload
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import fullscreen from "vue-fullscreen";
Vue.use(fullscreen);

Vue.config.productionTip = false;

Vue.use(VueMaterial);
Vue.use(ElementUI, { locale });

// Set Vue globally
window.Vue = Vue;

Vue.router = router;
Vue.use(VueRouter);

Vue.use(VueAxios, axios);
axios.defaults.baseURL = "/api";

Vue.use(VueAuth, auth);

Vue.use(Vuex);

Vue.use(UUID);
Vue.prototype._ = _;

window.io = require("socket.io-client");
if (typeof io !== "undefined") {
    Vue.use(VueEcho, {
        broadcaster: 'socket.io',
        host: window.location.hostname,
        transports: ['polling']
    });
}

// Load Layouts
Vue.component("default-layout", Default);
Vue.component("arkicare-layout", Arkicare);
Vue.component("superadmin-layout", Superadmin);

// Load Index
Vue.component("index", Index);
const app = new Vue({
    el: "#app",
    router,
    store
});

// Prototype
Vue.prototype.$log = console.log;

//fix el-select on iOS (it did not open keyboard)
let fixElSelect = function () {
    document.querySelectorAll('.el-select[data-is-search="true"]:hover').forEach((v) => {
        let elInput = v.querySelector('input');
        if (elInput.hasAttribute('readonly')) {
            elInput.readOnly = false;
            elInput.blur();
            elInput.focus();
        }
    });
};
document.addEventListener("focusin", fixElSelect);
document.addEventListener("click", fixElSelect);
document.addEventListener("touchstart", fixElSelect);
