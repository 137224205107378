import store from '@/js/core/store';
import VueRouter from 'vue-router'

// Pages
import Absences from '@/js/pages/Absences';
import AdminAbsences from '@/js/pages/Admin/Absences';
import AdminPatients from '@/js/pages/Admin/Patients';
import AdminPatientsCreate from "@/js/pages/Admin/CreatePatient";
import AdminPatientsEdit from "@/js/pages/Admin/EditPatient";
import AdminReports from "@/js/pages/Admin/Reports";
import AdminUsers from '@/js/pages/Admin/Users';
import AdminUsersEdit from '@/js/pages/Admin/UsersEdit';
import AdminUsersCreate from "@/js/pages/Admin/CreateUser";
import AdminActivitiesList from "@/js/pages/Admin/ActivitiesList";
import Baths from '@/js/pages/Baths';
import CreateExams from '@/js/pages/Exams/Create';
import CreateMedication from '@/js/pages/Medications/Create';
import CreateCriticalEvent from '@/js/pages/CriticalEvents/Create';
import CreatePhysiotherapy from '@/js/pages/Physiotherapies/Create';
import Diary from '@/js/pages/Diary';
import Diets from '@/js/pages/Diets';
import Mobilizzazioni from '@/js/pages/Mobilizzazioni';
import EditMedication from '@/js/pages/Medications/Edit';
import Exams from '@/js/pages/Exams/Index';
import Login from '@/js/pages/Auth/Login';
import Medication from '@/js/pages/Medications/Index';
import CriticalEvents from '@/js/pages/CriticalEvents/Index';
import NotFound from '@/js/mock/404';
import Forbidden from '@/js/mock/403';
import Patients from '@/js/pages/Patients/Index';
import PatientsProfile from '@/js/pages/Patients/Profile';
import Physiotherapy from '@/js/pages/Physiotherapies/Index';
import PrescriptedProtections from '@/js/pages/Protections/Prescripted';
import Protections from '@/js/pages/Protections/Protections';
import Purges from '@/js/pages/Purges';
import CdiPatients from '@/js/pages/SosiaCDI/CdiPatients';
import SosiaPatients from '@/js/pages/Sosia/SosiaPatients';
import SosiaPatient from '@/js/pages/Sosia/SosiaPatient';
import CdiPatient from '@/js/pages/SosiaCDI/CdiPatient';
import Subministrations from '@/js/pages/Subministrations';
import Tasks from '@/js/pages/Tasks';
import Tests from '@/js/pages/Tests/Index';
import Unauthorized from '@/js/mock/401';
import Activities from '@/js/pages/Activities/Index';
import ArkiComunication from '@/js/components/ArkiComunication';
import MonitoringMeals from '@/js/pages/Meals/monitoringMeals';
import VerificationMeals from '@/js/pages/Meals/verificationMeals';

//Superadmin
import SuperadminDashboard from '@/js/pages/Superadmin/Dashboard';
import SuperadminDropdowns from '@/js/pages/Superadmin/Dropdowns';
import SuperadminOperators from '@/js/pages/Superadmin/Operators';
import SuperadminOperatorsEdit from '@/js/pages/Superadmin/OperatorsEdit';
import SuperadminOperatorsInsert from '@/js/pages/Superadmin/OperatorsInsert';

// Routes
const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
        meta: {
            auth: undefined,
            layout: "default"
        }
    },
    {
        path: "/401",
        name: "401",
        component: Unauthorized,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/403",
        name: "403",
        component: Forbidden,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "*",
        name: "404",
        component: NotFound,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },

    // ROUTES

    {
        path: "/absences",
        name: "Assenze",
        component: Absences,
        meta: {
            auth: true,
            layout: "arkicare",
            redirect: { name: "login" }
        }
    },
    {
        path: "/baths",
        name: "Bagni",
        component: Baths,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/diary",
        name: "Diario",
        component: Diary,
        meta: {
            auth: true,
            layout: "arkicare",
            redirect: { name: "login" }
        }
    },
    {
        path: "/diets",
        name: "Diete",
        component: Diets,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/mobilizzazioni",
        name: "Mobilizzazioni",
        component: Mobilizzazioni,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/exams",
        name: "Rilevamenti",
        component: Exams,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/exams/create",
        name: "Nuovo Rilevamento",
        component: CreateExams,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/medications",
        name: "Medicazioni",
        component: Medication,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/medications/create",
        name: "Lesione",
        component: CreateMedication,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/medications/:id/edit",
        name: "Medicazione",
        component: EditMedication,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/critical-events",
        name: "Eventi Critici",
        component: CriticalEvents,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/critical-events/create",
        name: "Evento Critico",
        component: CreateCriticalEvent,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/patients",
        name: "Ospiti",
        component: Patients,
        meta: {
            auth: true,
            layout: "arkicare",
            redirect: { name: "Login" }
        }
    },
    {
        path: "/patients/:id",
        name: "Scheda Ospite",
        component: PatientsProfile,
        meta: {
            auth: true,
            layout: "arkicare",
            redirect: { name: "login" }
        }
    },
    {
        path: "/protections",
        name: "Contenzioni",
        component: Protections,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/protections/evaluated",
        name: "Contenzioni Indicate",
        component: PrescriptedProtections,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/physiotherapies",
        name: "Fisioterapie",
        component: Physiotherapy,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/physiotherapies/create",
        name: "Valutazione Fisioterapica",
        component: CreatePhysiotherapy,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/purges",
        name: "Evacuazioni",
        component: Purges,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/cdi",
        name: "Sosia CDI",
        component: CdiPatients,
        meta: {
            auth: true,
            layout: "arkicare"
        },
    },
    {
        path: "/cdi/:id",
        name: "Patient CDI",
        component: CdiPatient,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/sosia",
        name: "Sosia",
        component: SosiaPatients,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/sosia/:id",
        name: "Patient Sosia",
        component: SosiaPatient,
        meta: {
            auth: true,
            layout: "arkicare"
        }
    },
    {
        path: "/comunications",
        name: "Comunicazioni",
        component: ArkiComunication,
        meta: {
            auth: true,
            layout: "arkicare",
            redirect: { name: "login" }
        }
    },
    {
        path: "/settings/absences",
        name: "Gestione Assenze",
        component: AdminAbsences,
        meta: {
            auth: {
                roles: [1, 2],
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "arkicare"
        }
    },
    {
        path: "/settings/patients",
        name: "Gestione Ospiti",
        component: AdminPatients,
        meta: {
            auth: {
                roles: [1, 2],
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "arkicare"
        }
    },
    {
        path: "/settings/patients/create",
        name: "Nuovo Ospite",
        component: AdminPatientsCreate,
        meta: {
            auth: {
                roles: [1, 2],
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "arkicare"
        }
    },
    {
        path: "/settings/patients/:id/edit",
        name: "Modifica Ospite",
        component: AdminPatientsEdit,
        props: true,
        meta: {
            auth: {
                roles: [1, 2],
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "arkicare"
        }
    },
    {
        path: "/settings/users",
        name: "Gestione Utenti",
        component: AdminUsers,
        meta: {
            auth: {
                roles: [1, 2],
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "arkicare"
        }
    },
    {
        path: "/settings/users/create",
        name: "Nuovo Utente",
        component: AdminUsersCreate,
        meta: {
            auth: {
                roles: [1, 2],
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "arkicare"
        }
    },
    {
        path: "/settings/users/:id/edit",
        name: "Gestione Utente",
        component: AdminUsersEdit,
        meta: {
            auth: {
                roles: [1, 2],
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "arkicare"
        }
    },
    {
        path: "/settings/reports",
        name: "Report",
        component: AdminReports,
        meta: {
            auth: {
                roles: [1, 2],
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "arkicare"
        }
    },
    {
        path: "/subministrations",
        name: "Somministrazioni Terapie",
        component: Subministrations,
        meta: {
            auth: true,
            props: true,
            layout: "arkicare"
        }
    },
    {
        path: "/tasks",
        name: "Consegne",
        component: Tasks,
        meta: {
            auth: true,
            layout: "arkicare",
            redirect: { name: "login" }
        }
    },
    {
        path: "/tests/:patientId?",
        name: "Scale di Valutazione",
        component: Tests,
        meta: {
            auth: true,
            layout: "arkicare",
        }
    },
    {
        path: "/activities",
        name: "Attivitá",
        component: Activities,
        meta: {
            auth: true,
            layout: "arkicare",
        }
    },
    {
        path: "/meals",
        name: "Pasti",
        component: MonitoringMeals,
        meta: {
            auth: true,
            layout: "arkicare",
        }
    },
    {
        path: "/meals/verification",
        name: "Verifica Pasti",
        component: VerificationMeals,
        meta: {
            auth: true,
            layout: "arkicare",
        }
    },
    {
        path: "/settings/activities",
        name: "Gestione Attività",
        component: AdminActivitiesList,
        meta: {
            auth: {
                roles: [1, 2],
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "arkicare"
        }
    },

    //Superadmin
    {
        path: "/superadmin/dashboard",
        name: "Dashboard",
        component: SuperadminDashboard,
        meta: {
            auth: {
                roles: 1,
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "superadmin"
        }
    },
    {
        path: "/superadmin/dropdowns",
        name: "Gestione Tendine",
        component: SuperadminDropdowns,
        meta: {
            auth: {
                roles: 1,
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "superadmin"
        }
    },
    {
        path: "/superadmin/operators",
        name: "Gestione Operatori",
        component: SuperadminOperators,
        meta: {
            auth: {
                roles: 1,
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "superadmin"
        }
    },
    {
        path: "/superadmin/operators/:id/edit",
        name: "Gestione Operatore",
        component: SuperadminOperatorsEdit,
        meta: {
            auth: {
                roles: 1,
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "superadmin"
        }
    },
    {
        path: "/superadmin/operators/create",
        name: "Inserisci Operatore",
        component: SuperadminOperatorsInsert,
        meta: {
            auth: {
                roles: 1,
                redirect: { name: "Login" },
                forbiddenRedirect: "/401"
            },
            layout: "superadmin"
        }
    },
];
const router = new VueRouter({
    history: true,
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    store.commit('setPatientName', '');
    next();
})

export default router
