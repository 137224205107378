<template>
  <div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Importa / Esporta PDF</md-dialog-title>
      <el-form
        label-position="right"
        label-width="80px"
        :model="paiModalForm"
        ref="form"
      >
        <el-row type="flex" justify="space-between" align="center">
          <el-col :span="12">
            <el-form-item prop="download">
              <el-button
                round
                icon="el-icon-download"
                style="align-self: end"
                @click="dialogPaiConfirmed == 0 ? exportPdf() : downloadPdf()"
                :disabled="isDownloading"
                :loading="isDownloading"
                >Download</el-button
              >
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="file">
              <el-upload
                class="upload-demo"
                ref="uploader"
                :action="uploadAction"
                accept="application/pdf"
                :auto-upload="false"
                :on-success="onUploadSuccess"
                :on-error="onUploadError"
                :before-upload="beforeUpload"
                :on-change="handleFileChange"
                :limit="1"
                :with-credentials="true"
                :headers="uploadHeaders"
              >
                <el-button
                  slot="trigger"
                  type="primary"
                  style="width: 80%"
                  :disabled="dialogPaiConfirmed == 1"
                  >Seleziona File</el-button
                >
              </el-upload>
            </el-form-item>
            <el-form-item prop="file">
              <el-button
                @click="submitUpload"
                type="success"
                style="width: 80%"
                :loading="loading"
                :disabled="dialogPaiConfirmed == 1"
                >Carica</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <md-dialog-actions>
        <md-button class="md-primary" @click="onDialogClosed()"
          >Chiudi</md-button
        >
      </md-dialog-actions>
    </md-dialog>

    <el-row v-loading="loading" :gutter="20" class="no-margin-row">
      <el-col :span="24" v-if="showSection == 'tab'">
        <el-col :span="24" style="background-color: white">
          <span
            v-if="persistentDraft"
            class="float-right warning-text"
            style="font-weight: bolder; padding-top: 5px"
            >Da Completare</span
          >
        </el-col>
        <el-table
          :data="tableData"
          style="width: 100%"
          height="500"
          :cell-class-name="colClass"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            label="Data"
            prop="clean_date_time"
            width="150"
          ></el-table-column>
          <el-table-column label="Tipo" prop="clean_date_time" width="180">
            <template slot-scope="scope">{{
              computePaiType(scope.row.pai_type)
            }}</template>
          </el-table-column>
          <el-table-column label="Da Rivalutare" prop="clean_revaluation_at">
            <template slot="header">
              <span>Da Rivalutare</span>
            </template>
          </el-table-column>
          <el-table-column align="center" width="200">
            <template slot="header">
              <el-button
                class="w-100"
                type="primary"
                plain
                @click="showForm(lastPai, 'new')"
                >Nuovo</el-button
              >
            </template>
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="showForm(scope.row, 'show')"
                style="text-decoration: none"
                >Visualizza</el-button
              >
              <el-divider direction="vertical"></el-divider>
              <el-button
                type="text"
                size="small"
                @click="openModal(scope.row.id, scope.row.confirmed)"
                style="text-decoration: none"
                >{{
                  scope.row.confirmed ? "PDF firmato" : "PDF per firme"
                }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :span="24" v-else-if="showSection == 'form'">
        <pai-form
          :key="formKey"
          :pai="selectedPai"
          :loading.sync="loading"
          :paiOperation="paiOperation"
          :patient="patient"
          @resetForm="resetForm()"
          @showList="backFromForm()"
          :persistentDraft.sync="persistentDraft"
        />
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pai from "@/js/api/services/Pai";
import PaiForm from "@/js/pages/Patients/components/Pai/PaiForm";

export default {
  name: "Pai",
  props: ["profile_id", "patient"],
  components: {
    PaiForm,
  },
  data() {
    return {
      formKey: 0,
      loading: true,
      showSection: "tab",
      paiOperation: null,
      selectedPai: null,
      lastPai: null,
      tableData: [],
      showDialog: false,
      dialogPaiId: null,
      dialogPaiConfirmed: null,
      paiModalForm: {},
      isDownloading: false,
      uploadHeaders: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        Authorization: `Bearer ${localStorage.getItem("arkicare_session")}`,
      },
      uploadAction: "",
      hasUpload: false,
      confirm_rules: {
        file: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
      },
      persistentDraft: null,
    };
  },
  methods: {
    async fetch() {
      this.loading = true;
      let params = {
        patient_id: this.profile_id,
      };
      this.persistentDraft = await Pai.findDraft(this.profile_id);
      const response = await Pai.fetch(params);
      this.tableData = _.get(response, ["data", "pai"], []);
      let latestFirst = _.orderBy(
        this.tableData,
        ["enabled", "created_at"],
        ["desc", "desc"]
      );
      this.lastPai = _.head(latestFirst);
      if (_.get(this.lastPai, "enabled") == 0) {
        this.lastPai = [];
      }
      this.loading = false;
    },
    onDialogClosed() {
      this.showDialog = false;
    },
    showForm(pai, paiOperation) {
      this.selectedPai = pai;
      this.paiOperation = paiOperation;
      this.showSection = "form";
    },
    backFromForm() {
      this.fetch();
      this.showSection = "tab";
    },
    computePaiType(type) {
      switch (type) {
        case 1:
          return "30gg da ingresso";
          break;
        case 2:
          return "Semestrale";
          break;
        case 3:
          return "Rivalutazione al bisogno";
        default:
          return "";
          break;
      }
    },
    resetForm() {
      this.persistentDraft = null;
      this.formKey += 1;
    },
    colClass({ row, column, rowIndex, columnIndex }) {
      if (_.get(row, "enabled") == 0 && columnIndex != 3) {
        return "deleted-row";
      }
      return "";
    },
    async openModal(paiId, paiConfirmed) {
      this.dialogPaiId = paiId;
      this.uploadAction = this.dialogPaiId
        ? `/api/pai/${this.dialogPaiId}/upload`
        : "";
      this.dialogPaiConfirmed = paiConfirmed;
      this.showDialog = true;
    },
    exportPdf() {
      this.isDownloading = true;
      const pName = this.patient.full_name;
      const filename = `PAI_${pName.replace(/\s+/g, "_")}.pdf`;
      Pai.export(this.dialogPaiId, this.patient.id)
        .then((res) => this.file(res, filename))
        .finally(() => (this.isDownloading = false));
    },
    onUploadSuccess() {
      this.$message(`PAI caricato correttamente`);
      this.dialogPaiConfirmed = 1;
      this.fetch();
    },
    onUploadError() {
      this.$notify({
        type: "error",
        message: "Import del file fallito",
      });
    },
    async submitUpload() {
      const files = _.get(this.$refs, ["uploader", "uploadFiles", "length"]);
      if (files > 0) {
        this.$refs.uploader.submit();
      } else {
        this.$message(
          `Nessun PAI caricato. Premi 'Seleziona File' per aggiungere.`
        );
      }
    },
    beforeUpload(file) {
      const isPDF = file.type === "application/pdf";
      if (!isPDF) {
        this.$message.error("Il formato del file non è PDF");
      }
    },
    handleFileChange(file) {
      if (file) {
        this.confirm_rules.file[0].required = false;
      }
    },
    async downloadPdf() {
      const response = await Pai.download(this.dialogPaiId);
      let title = `PAI_confirmed_${this.$store.state.patientName}.pdf`;
      this.file(response, title);
    },
    file(response, title) {
      let fileURL = window.URL.createObjectURL(new Blob([response.data]));
      let fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", title);
      document.body.appendChild(fileLink);
      fileLink.click();

      this.downloadLoading = false;
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.confirmed == 0) {
        return "warning-row";
      }
      return "";
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style lang="scss" scoped>
.el-table .warning-row {
  background: oldlace;
}
</style>