<script>
import CdiData from './CdiData.vue';
import CdiDataForm from './CdiDataForm.vue';
import CdiEvaluation from './CdiEvaluation.vue';
import CdiEvaluationForm from './CdiEvaluationForm.vue';
import SosiaCdiService from '@/js/api/services/SosiaCdi';
import CdiEvaluationsTable from './CdiEvaluationsTable.vue';
import ArkFile from '@/js/helpers/file';
import moment from 'moment';
import Patient from "@/js/api/services/Patient";

export default {
  name: 'CdiPatient',
  components: { CdiDataForm, CdiData, CdiEvaluationForm, CdiEvaluation, CdiEvaluationsTable },
  data() {
    return {
      loading: true,
      patientId: this.$route.params.id,
      patient: {},
      config: {},
      record: null,
      showNewDataForm: false,
      showNewEvaluationForm: false,
      nextPage: false,
      prevPage: false,
      printing: false,
      edit: false,
      create: false,
      pickerOptions: {
        disabledDate(date) {
          return date > new Date();
        }
      }
    };
  },
  computed: {
    patientName() {
      return _.get(this.patient, 'full_name');
    },
    deletedRecord() {
      if (!this.record) return false;
      return Boolean(this.record.deleted_at);
    },
    hasEntryEvaluation() {
      if (this.create || !this.record) return false;
      return this.record.valutazioni.some((v) => v.tipo_valutazione == 1 && v.deleted_at == null);
    },
    hasExitEvaluation() {
      if (this.create || !this.record) return false;
      return this.record.valutazioni.some((v) => v.tipo_valutazione == 4 && v.deleted_at == null);
    },
    valutazioni() {
      if (!this.record || !this.record.valutazioni) return [];
      return _.orderBy(this.record.valutazioni, (v) => moment(v.data_valutazione).format('YYYYMMDD'), ['desc']);
    },
    hasDismissal() {
      if (!this.record) return false;
      return this.record.data_dimissione != null;
    },
    isClosed() {
      if (!this.record) return false;
      return this.record.deleted_at != null;
    },
    trattamento() {
      if (!this.record) return '';
      return `${this.record.anno_ricovero}${String(this.record.num_pratica).padStart(6, '0')}`;
    }
  },
  methods: {
    async fetchPatientCDI(page = 1) {
      this.loading = true;
      SosiaCdiService.findByPatient(this.patientId, { page })
        .then(({ data }) => {
          Object.assign(this, {
            config: data.config,
            record: data.data,
            nextPage: data.next,
            prevPage: data.prev
          });
        })
        .finally(() => (this.loading = false));
    },
    async setupPatient() {
      // need a fresh API call ti avoid department problems
      const response = await Patient.profile(this.patientId);
      this.patient = response.data.patient;
      this.$store.commit('setPatientName', this.patientName);
    },
    deleteData(id = this.record.id) {
      this.$confirm(`Sicuro di voler cancellare?`, 'Attenzione', {
        type: 'warning'
      })
        .then(() => {
          SosiaCdiService.deleteData(id).then(() => {
            this.fetchPatientCDI();
            this.$notify({
              type: 'success',
              message: 'Cancellazione eseguita'
            });
          });
        })
        .catch(() => {});
    },
    showEvaluationForm(create) {
      if (create) {
        this.edit = null;
      }
      this.create = create;
      this.showNewEvaluationForm = true;
    },
    showDataForm(create) {
      if (create) {
        this.edit = null;
      }
      this.create = create;
      this.showNewDataForm = true;
    },
    editEvaluation(record) {
      this.edit = record;
      this.showEvaluationForm();
    },
    editData(record) {
      this.edit = record;
      this.showDataForm();
    },
    printPdf() {
      this.printing = true;
      ArkFile.downloadFromRequest(SosiaCdiService.printPdf(this.record.id), 'sosia_cdi.pdf').finally(
        () => (this.printing = false)
      );
    }
  },
  created() {
    this.$root.$on('cdiFetchPatients', this.fetchPatientCDI);
    this.$root.$on('cdiEditEvaluation', this.editEvaluation);
  },
  beforeDestroy() {
    this.$root.$off('cdiFetchPatients', this.fetchPatientCDI);
    this.$root.$off('cdiEditEvaluation', this.editEvaluation);
  },
  mounted() {
    this.setupPatient();
    this.fetchPatientCDI();
  }
};
</script>

<template>
  <el-container class="container" direction="vertical">
    <router-link to="/cdi">
      <el-button type="text" icon="el-icon-back" class="back-btn">Ritorna alla lista</el-button>
    </router-link>
    <el-header class="header">
      <el-row>
        <el-col :span="24">
          <el-col :span="6">
            <el-button class="float-left" round :disabled="!prevPage" @click="fetchPatientCDI(prevPage)"
              ><font-awesome-icon icon="long-arrow-alt-left" /> Indietro</el-button
            >
          </el-col>
          <el-col :span="12" class="text-center">
            <el-button-group>
              <el-button v-if="record" type="success" :loading="printing" @click="printPdf" icon="el-icon-printer"
                >Stampa PDF</el-button
              >
              <el-button
                type="primary"
                @click="showDataForm(true)"
                v-show="(hasDismissal || !record || isClosed) && !nextPage"
                >Nuova Pratica <i class="el-icon-document-add"
              /></el-button>
            </el-button-group>
          </el-col>
          <el-col :span="6">
            <el-button class="float-right" round :disabled="!nextPage" @click="fetchPatientCDI(nextPage)">
              Avanti
              <font-awesome-icon icon="long-arrow-alt-right" />
            </el-button>
          </el-col>
        </el-col>
      </el-row>
    </el-header>

    <el-main class="main">
      <el-row>
        <el-col>
          <el-card :class="['box-card', deletedRecord && 'greyed-out']">
            <div slot="header" v-if="record">
              <el-row>
                <el-col :span="12">
                  <h1 style="margin: 0 0.5em" :class="deletedRecord && 'deleted'">
                    Pratica
                    <span v-if="record" style="text-size: smaller">{{ trattamento }}</span>
                  </h1>
                </el-col>
                <el-col :span="12" style="text-align: right" v-if="!deletedRecord">
                  <div style="display: inline-block">
                    <el-button type="warning" icon="el-icon-delete" @click="deleteData(record.id)">Cancella</el-button>
                    <el-button type="primary" @click="editData(record)">Modifica <i class="el-icon-edit" /></el-button>
                  </div>
                </el-col>
                <el-col :span="6" style="text-align: right" v-if="deletedRecord">
                  <div style="display: inline-block">
                    <span
                      >Data Cancellazione <strong style="color: red">{{ record.deleted_at }}</strong></span
                    >
                  </div>
                </el-col>
              </el-row>
            </div>
            <div v-if="record">
              <cdi-data :data="record" :config="config" :patient="this.patient" />
              <el-divider></el-divider>

              <el-row>
                <el-col :span="18">
                  <h1 style="margin: 0 0.5em">Valutazioni</h1>
                </el-col>
                <el-col :span="6" style="text-align: right" v-if="!deletedRecord">
                  <div style="display: inline-block">
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      @click="showEvaluationForm(true)"
                      v-show="!hasDismissal"
                      >Nuova Valutazione</el-button
                    >
                  </div>
                </el-col>
              </el-row>
              <el-divider></el-divider>
              <cdi-evaluations-table
                v-if="record.valutazioni"
                :hasDismissal="hasDismissal"
                :list="valutazioni"
                :config="config"
                :onEvaluationChage="fetchPatientCDI"
              />
              <div v-else>
                <el-alert title="Nessuna valutazione presente" type="info" center :closable="false" />
              </div>
            </div>
            <div v-else>
              <el-alert title="Nessuna pratica presente" type="info" center :closable="false" />
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-main>
    <cdi-data-form
      v-if="showNewDataForm"
      :record="record"
      :visible.sync="showNewDataForm"
      :key="'new-data-form-' + (edit ? edit.id : null)"
      :patient="this.patient"
      :config="config"
      :edit="edit"
      :create="create"
      :hasDismissal="hasDismissal"
      :hasEntryEvaluation="hasEntryEvaluation"
      :hasExitEvaluation="hasExitEvaluation"
      @stored="fetchPatientCDI"
    />
    <cdi-evaluation-form
      v-if="showNewEvaluationForm"
      :visible.sync="showNewEvaluationForm"
      :key="'new-evaluation-form-' + (edit ? edit.id : null)"
      :patient="this.patient"
      :config="config"
      :cdi1="record"
      :edit="edit"
      :create="create"
      @stored="fetchPatientCDI"
    />
  </el-container>
</template>

<style lang="scss" scoped>
.greyed-out {
  background-color: #e0e0e0;
}
.deleted {
  text-decoration: line-through;
}
.container {
  height: 100%;
  margin: 5px;
}
.main {
  overflow-y: auto;
  padding: 0;
}
.header {
  text-align: center;
  padding: 0.5rem;
}
.back-btn {
  z-index: 2;
  position: fixed;
  left: 50%;
  top: 93px;
  transform: translateX(-50%);
  background: transparent !important;
  color: #fff;
  font-weight: bolder;
}
</style>
