<template>
  <!-- card  -->
  <el-card
    class="box-card meal_card"
    :class="!meal[foodValue] ? 'no_monitorated' : ''"
  >
    <!-- card header -->
    <div slot="header" class="clearfix">
      <div class="title_row">
        <span class="title">{{ foodValue }}</span>
        <span class="counter">
          <el-progress
            type="circle"
            :percentage="
              verification && verification.pasto_nulla ? 100 : eatPercentage
            "
            :width="50"
            :stroke-width="3"
            :status="
              verification && verification.pasto_nulla ? 'exception' : 'success'
            "
            class="eat-icon"
            :class="eatPercentage == 0 && extraEat > 0 ? 'extra-eat' : null"
            :color="setColor()"
          ></el-progress>
          <span v-if="extraEat > 0"> {{ "+" + extraEat }}</span>
          <el-progress
            type="circle"
            :percentage="
              verification && verification.pasto_nulla ? 100 : drinkPercentage
            "
            :width="50"
            :stroke-width="3"
            :status="
              verification && verification.pasto_nulla ? 'exception' : 'success'
            "
            class="drink-icon"
            :color="setColor()"
          ></el-progress>
        </span>
        <span class="expand">
          <el-button
            type="primary"
            icon="el-icon-view"
            circle
            size="mini"
            :disabled="!meal[foodValue]"
            @click="
              () => {
                openedCards = openedCards ? false : true;
              }
            "
          ></el-button
        ></span>
      </div>
      <div class="note_row">
        <b>Note:</b> {{ verification ? verification.verification_note : null }}
      </div>
    </div>
    <!-- card body -->
    <div v-show="openedCards">
      <div class="text item" v-if="verification">
        <div v-if="verification.pasto_nulla === 1">Non ha mangiato</div>
        <div v-else>
          <el-table
            :data="removeEmptyRows(fields[foodValue])"
            style="width: 100%"
            :set="(v = verification)"
            class="meal_table"
            :lazy="true"
          >
            <el-table-column prop="portata" label="PORTATA" :width="120">
              <template slot-scope="scope">
                {{ scope.row.label }}
              </template>
            </el-table-column>
            <el-table-column label="Piatto Servito">
              <template slot-scope="scope">
                <span v-if="[1, 2].includes(foodKey)">{{
                  v.food[scope.row.key + "_piatto"]
                }}</span>

                <span v-else>
                  <span v-if="/^altro_\d+$/.test(scope.row.key)"
                    >{{ v.food[scope.row.key] }}
                  </span>
                  <span v-else>
                    {{ v.food[scope.row.key + "_servito"] ? "Si" : "No" }}</span
                  >
                </span>
              </template>
            </el-table-column>
            <el-table-column label="Qualità" v-if="[1, 2].includes(foodKey)">
              <template slot-scope="scope">
                {{ v.food[scope.row.key + "_quality"] }}
              </template>
            </el-table-column>
            <el-table-column
              label="Q.tà"
              column-key="qta"
              :width="70"
              align="center"
            >
              <template slot-scope="scope">
                <el-progress
                  type="circle"
                  :percentage="
                    Math.round(
                      (v.food[scope.row.key + '_qta'] * 100) / scope.row.qta_max
                    )
                  "
                  :width="40"
                  :stroke-width="3"
                  v-if="scope.row.qta_max"
                ></el-progress>
                <span v-else>{{ v.food[scope.row.key + "_qta"] }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-else-if="meal[foodValue]">Pasto non verificato</div>
      <div v-else-if="!meal[foodValue]">Monitoraggio non richiesto</div>
    </div>
  </el-card>
</template>
<script>
export default {
  name: "MealVerificationSummaryCard",
  props: {
    meal: Object,
    foodValue: String,
    foodKey: Number,
    verification: Object,
  },
  data() {
    return {
      openedCards: false,
      fields: {
        pranzo: [
          {
            key: "primo",
            label: "Primo",
            qta_max: 4,
            type: "eat",
          },
          {
            key: "secondo",
            label: "Secondo",
            qta_max: 4,
            type: "eat",
          },
          {
            key: "contorno",
            label: "Contorno",
            qta_max: 4,
            type: "eat",
          },
          {
            key: "frullati",
            label: "Frullati Vari",
            qta_max: 4,
            type: "eat",
          },
          {
            key: "dessert",
            label: "Frutta/Dessert",
            qta_max: 4,
            type: "eat",
          },
          {
            key: "liquidi",
            label: "Liquidi",
            qta_max: 6,
            type: "drink",
          },
        ],
        cena: [
          {
            key: "primo",
            label: "Primo",
            qta_max: 4,
            type: "eat",
          },
          {
            key: "secondo",
            label: "Secondo",
            qta_max: 4,
            type: "eat",
          },
          {
            key: "contorno",
            label: "Contorno",
            qta_max: 4,
            type: "eat",
          },
          {
            key: "frullati",
            label: "Frullati Vari",
            qta_max: 4,
            type: "eat",
          },
          {
            key: "dessert",
            label: "Frutta/Dessert",
            qta_max: 4,
            type: "eat",
          },
          {
            key: "liquidi",
            label: "Liquidi",
            qta_max: 6,
            type: "drink",
          },
        ],
        colazione: [
          {
            key: "latte",
            label: "Latte",
            qta_max: 4,
            type: "drink",
          },
          {
            key: "caffelatte",
            label: "Caffelatte",
            qta_max: 4,
            type: "drink",
          },
          {
            key: "orzo",
            label: "Orzo",
            qta_max: 4,
            type: "drink",
          },
          {
            key: "the",
            label: "Thè",
            qta_max: 4,
            type: "drink",
          },
          {
            key: "biscotti",
            label: "Biscotti",
            type: "eat",
          },
          {
            key: "fette_biscottate",
            label: "Fette Biscottate",
            type: "eat",
          },
          {
            key: "marmellata",
            label: "Marmellata",
            type: "eat",
          },
          {
            key: "yogurt",
            label: "Yogurt",
            qta_max: 4,
            type: "eat",
          },
          {
            key: "altro_1",
            label: "Altro",
            type: "eat",
          },
        ],
        spuntino: [
          {
            key: "orzo",
            label: "Orzo",
            qta_max: 4,
            type: "drink",
          },
          {
            key: "the",
            label: "Thè",
            qta_max: 4,
            type: "drink",
          },
          {
            key: "succo",
            label: "Succo di Frutta",
            qta_max: 4,
            type: "drink",
          },
          {
            key: "biscotti",
            label: "Biscotti",
            type: "eat",
          },
          {
            key: "fette_biscottate",
            label: "Fette Biscottate",
            type: "eat",
          },
          {
            key: "altro_1",
            label: "Altro",
            type: "eat",
          },
          {
            key: "altro_2",
            label: "Altro",
            type: "eat",
          },
          {
            key: "altro_3",
            label: "Altro",
            type: "eat",
          },
        ],
        merenda: [
          {
            key: "the",
            label: "Thè",
            qta_max: 4,
            type: "drink",
          },
          {
            key: "orzo",
            label: "Orzo",
            qta_max: 4,
            type: "drink",
          },
          {
            key: "bevanda",
            label: "Bevanda",
            qta_max: 4,
            type: "drink",
          },
          {
            key: "biscotti",
            label: "Biscotti",
            type: "eat",
          },
          {
            key: "fette_biscottate",
            label: "Fette Biscottate",
            type: "eat",
          },
          {
            key: "succo",
            label: "Succo di Frutta",
            qta_max: 4,
            type: "drink",
          },
          {
            key: "yogurt",
            label: "Yogurt",
            qta_max: 4,
            type: "eat",
          },
          {
            key: "altro_1",
            label: "Altro",
            type: "eat",
          },
        ],
      },
      colors: [
        { color: "#F56C6C", percentage: 33 },
        { color: "#E6A23C", percentage: 66 },
        { color: "#67C23A", percentage: 100 },
      ],
      eatPercentage: 0,
      drinkPercentage: 0,
      extraEat: 0,
    };
  },
  watch: {
    verification() {
      this.initCounters();
    },
  },
  methods: {
    initCounters() {
      var valueEat = 0;
      var maxEat = 0;
      var extraEat = 0;
      var valueDrink = 0;
      var maxDrink = 0;
      this.eatPercentage = 0;
      this.drinkPercentage = 0;
      this.extraEat = 0;
      if (this.verification && this.verification.food) {
        var self = this;
        _.forEach(this.fields[this.foodValue], function (mealFields) {
          if (
            mealFields.type == "eat" &&
            (self.verification.food[mealFields.key + "_servito"] ||
              self.verification.food[mealFields.key + "_piatto"] ||
              self.verification.food["altro_1"] ||
              self.verification.food["altro_2"] ||
              self.verification.food["altro_3"])
          ) {
            if (mealFields.qta_max) {
              valueEat += parseInt(
                self.verification.food[mealFields.key + "_qta"]
              );
              maxEat += mealFields.qta_max;
            } else {
              extraEat += self.verification.food[mealFields.key + "_qta"];
            }
          } else if (
            mealFields.type == "drink" &&
            mealFields.qta_max &&
            (self.verification.food[mealFields.key + "_servito"] ||
              self.verification.food[mealFields.key + "_piatto"])
          ) {
            valueDrink += parseInt(
              self.verification.food[mealFields.key + "_qta"]
            );
            maxDrink += mealFields.qta_max;
          }
        });

        this.eatPercentage = maxEat ? Math.round((valueEat * 100) / maxEat) : 0;
        this.drinkPercentage = maxDrink
          ? Math.round((valueDrink * 100) / maxDrink)
          : 0;
        this.extraEat = extraEat;
      }
    },
    setColor() {
      if (this.verification && this.verification.pasto_nulla) {
        return "#F56C6C";
      }
      return this.colors;
    },
    removeEmptyRows(data) {
      const foodKey = this.foodKey;
      const food = this.verification.food;
      const newData = _.filter(data, function (item) {
        // Check for "piatto" key if foodKey is 1 or 2
        if ([1, 2].includes(foodKey) && food[item.key + "_piatto"]) {
          return true;
        }
        // Check for "altro_" keys
        if (/^altro_\d+$/.test(item.key) && food[item.key]) {
          return true;
        }
        // Check for "_servito" key
        if (food[item.key + "_servito"]) {
          return true;
        }
        return false;
      });
      return newData;
    },
  },
  mounted() {
    this.initCounters();
  },
};
</script>
<style lang="scss">
.meal_card {
  .el-card__header {
    padding: 5px 10px !important;
    .el-progress__text {
      font-size: 30px !important;
    }
    .drink-icon i.el-icon-check::before {
      content: "\e6b4";
    }
    .eat-icon i.el-icon-check::before {
      content: "\e6c4";
    }
    .el-progress.is-success .el-progress__text {
      color: #000000;
    }
    .el-progress.is-success.extra-eat .el-progress__text {
      color: #409eff;
    }
  }
  .el-card__body {
    padding: 0 !important;
    .el-progress__text {
      font-size: 11px !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.titleCol {
  padding-top: 9px;
}
.title {
  font-size: 20px;
}
.no_monitorated {
  background-color: #f3f7fa;
}
.meal_card {
  .el-card__header {
    padding: 10px !important;
    .clearfix {
      .title_row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-bottom: 1px solid #dcdfe6;
        padding-bottom: 5px;
        span {
          &.title {
            text-align: left;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            min-width: 73px;
          }
          &.counter {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            div {
              margin: 0 5px;
            }
          }
          &.expand {
            width: 30px;
            text-align: right;
          }
        }
      }
      .note_row {
        text-align: left;
        margin-top: 3px;
      }
    }
  }
  .el-card__body {
    padding: 0px;
  }
}
.meal_table th,
.meal_table td {
  padding: 2px 0px;
}
</style>