<script>
import SosiaService from '@/js/api/services/Sosia2';

export default {
  name: 'SosiaTeamForm',
  props: ['visible', 'record', 'config', 'patientId', 'treatmentInfo'],
  data() {
    return {
      loading: false,
      form: {
        cognome: null,
        nome: null,
        nazione: null,
        numero_id_personale: null,
        numero_id_istituzione: null,
        nome_id_istituzione: null,
        numero_id_ts: null,
        data_scadenza_ts: null
      },
      editMode: Boolean(_.get(this.record, 'id')),
      pickerOptions: {
        disabledDate(date) {
          return date > new Date();
        }
      }
    };
  },
  computed: {
    rules() {
      return {
        cognome: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        nome: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        nazione: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        numero_id_personale: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        numero_id_istituzione: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        nome_id_istituzione: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        numero_id_ts: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        data_scadenza_ts: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }]
      };
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid, errors) => {
        if (!valid) {
          this.$message({
            type: 'error',
            message: `Campi non validi`
          });
          return false;
        }
        this.editMode ? this.update() : this.create();
      });
    },
    create() {
      const payload = { ...this.form, patient_id: this.patientId, trattamento_id: this.treatmentInfo.id };
      SosiaService.storeTeam(payload)
        .then(() => this.$root.$emit('fetchPatient'))
        .then(() => this.close());
    },
    update() {
      const payload = { ...this.form, patient_id: this.record.patient_id, trattamento_id: this.treatmentInfo.id };
      SosiaService.updateTeam(this.record.id, payload)
        .then(() => this.$root.$emit('fetchPatient'))
        .then(() => this.close());
    },
    close() {
      this.$emit('update:visible', false);
    }
  },
  created() {
    if (this.editMode) {
      const fields = _.pick(this.record, [
        'nazione',
        'cognome',
        'nome',
        'numero_id_personale',
        'numero_id_istituzione',
        'nome_id_istituzione',
        'numero_id_ts',
        'data_scadenza_ts'
      ]);
      Object.assign(this.form, fields);
    }
  }
};
</script>

<template>
  <el-dialog
    :visible.sync="visible"
    title="Tessera Team"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :show-close="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    width="70%"
    center
  >
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
          labelPosition="top"
          size="mini"
          :rules="rules"
          v-loading="loading"
          :validate-on-rule-change="false"
        >
          <el-row :gutter="10">
            <el-col :sm="24">
              <el-card>
                <el-row :gutter="10">
                  <el-col :span="24" :md="12">
                    <el-form-item label="Cognome" prop="cognome">
                      <el-input v-model="form.cognome" maxlength="40" show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12">
                    <el-form-item label="Nome" prop="nome">
                      <el-input v-model="form.nome" maxlength="35" show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12">
                    <el-form-item label="Nazione" prop="nazione">
                      <el-input v-model="form.nazione" maxlength="2" minlength="2" show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12">
                    <el-form-item label="Nome Istituzione Competante" prop="nome_id_istituzione">
                      <el-input
                        v-model="form.nome_id_istituzione"
                        maxlength="21"
                        minlength="21"
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12">
                    <el-form-item label="Numero Istituzione Competante" prop="numero_id_istituzione">
                      <el-input
                        v-model="form.numero_id_istituzione"
                        maxlength="10"
                        minlength="10"
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12">
                    <el-form-item label="Numero Identificazione Personale" prop="numero_id_personale">
                      <el-input
                        v-model="form.numero_id_personale"
                        maxlength="20"
                        minlength="20"
                        show-word-limit
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12">
                    <el-form-item label="Numero Identificazione Tessera Sanitaria" prop="numero_id_ts">
                      <el-input v-model="form.numero_id_ts" maxlength="20" minlength="20" show-word-limit></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12">
                    <el-form-item label="Data di Scadenza Tessera Sanitaria" prop="data_scadenza_ts">
                      <el-date-picker
                        v-model="form.data_scadenza_ts"
                        type="date"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" justify="center" style="margin-top: 20px">
            <el-button @click="$emit('update:visible', false)" class="modal-btn">Annulla</el-button>
            <el-button type="primary" @click="onSubmit" class="modal-btn">Salva</el-button>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0.6rem;
  .el-form-item__label {
    padding: 0 !important;
  }
}
.el-divider__text {
  font-weight: bold;
}
</style>