<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-divider content-position="center">Memoria</el-divider>
        <el-row>
          <el-col :offset="1" :span="24">
            <el-row>
              <el-form-item prop="q1_result">
                <el-radio-group v-model="result.q1_result" style="width: 90%">
                  <el-row>
                    <el-col :span="18">
                      <el-form-item>Memoria adeguata o smemoratezza occasionale</el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item>
                        <el-radio :label="0">Normale</el-radio>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="18">
                      <el-form-item>Lieve smemoratezza permanente; parziale rievocazione di eventi</el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item>
                        <el-radio :label="0.5">Demenza dubbia</el-radio>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Perdita di memoria modesta per eventi recenti; interferenza con attività quotidiane</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="1">Demenza lieve</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Perdita di memoria severa; materiale nuovo perso rapidamente</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="2">Demenza moderata</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Perdita di memoria grave; rimangono alcuni frammenti</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="3">Demenza grave</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Il paziente presenta severo deficit del linguaggio o della comprensione, problemi nel riconoscere i famigliari, incapacità a deambulare in modo autonomo, problemi ad alimentarsi da solo e nel controllare la funzione intestinale o vescicale.</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="4">Demenza molto grave</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Il paziente richiede assistenza totale perché completamente incapace di comunicare, in stato vegetativo, allettato, incontinente.</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="5">Demenza terminale</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </el-form-item>
            </el-row>
          </el-col>
        </el-row>
      </el-row>

      <el-row :gutter="20" class="section">
        <el-divider content-position="center">Orientamento</el-divider>
        <el-row>
          <el-col :offset="1" :span="24">
            <el-row>
              <el-form-item prop="q2_result">
                <el-radio-group v-model="result.q2_result" style="width: 90%">
                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Perfettamente orientato</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="0">Normale</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Alcune difficoltà nel tempo; possibile disorientamento topografico</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="1">Demenza lieve</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Usualmente disorientamento temporale, spesso spaziale</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="2">Demenza moderata</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Orientamento solo personale</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="3">Demenza grave</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Il paziente presenta severo deficit del linguaggio o della comprensione, problemi nel riconoscere i famigliari, incapacità a deambulare in modo autonomo, problemi ad alimentarsi da solo e nel controllare la funzione intestinale o vescicale.</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="4">Demenza molto grave</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Il paziente richiede assistenza totale perché completamente incapace di comunicare, in stato vegetativo, allettato, incontinente.</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="5">Demenza terminale</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </el-form-item>
            </el-row>
          </el-col>
        </el-row>
      </el-row>

      <el-row :gutter="20" class="section">
        <el-divider content-position="center">Giudizio e soluzione di problemi</el-divider>
        <el-row>
          <el-col :offset="1" :span="24">
            <el-row>
              <el-form-item prop="q3_result">
                <el-radio-group v-model="result.q3_result" style="width: 90%">
                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Risolve bene i problemi giornalieri; giudizio adeguato rispetto al passato</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="0">Normale</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Dubbia compromissione nella soluzione di problemi; analogie e differenze (prove di ragionamento)</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="0.5">Demenza dubbia</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Difficoltà moderata; esecuzione di problemi complessi; giudizio sociale adeguato</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="1">Demenza lieve</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Difficoltà severa nell’esecuzione di problemi complessi; giudizio sociale compromesso</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="2">Demenza moderata</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Incapace di dare giudizi o di risolvere problemi</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="3">Demenza grave</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Il paziente presenta severo deficit del linguaggio o della comprensione, problemi nel riconoscere i famigliari, incapacità a deambulare in modo autonomo, problemi ad alimentarsi da solo e nel controllare la funzione intestinale o vescicale.</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="4">Demenza molto grave</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Il paziente richiede assistenza totale perché completamente incapace di comunicare, in stato vegetativo, allettato, incontinente.</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="5">Demenza terminale</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </el-form-item>
            </el-row>
          </el-col>
        </el-row>
      </el-row>

      <el-row :gutter="20" class="section">
        <el-divider content-position="center">Attività sociali</el-divider>
        <el-row>
          <el-col :offset="1" :span="24">
            <el-row>
              <el-form-item prop="q4_result">
                <el-radio-group v-model="result.q4_result" style="width: 90%">
                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Attività indipendente e livelli usuali in lavoro, acquisti, pratiche burocratiche</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="0">Normale</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Solo dubbia compromissione nelle attività descritte</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="0.5">Demenza dubbia</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Incapace di compiere indipendentemente le attività, a esclusione di attività facili</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="1">Demenza lieve</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Nessuna pretesa di attività indipendente fuori casa; in grado di essere portato fuori casa</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="2">Demenza moderata</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Nessuna pretesa di attività indipendente fuori casa; non in grado di uscire</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="3">Demenza grave</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Il paziente presenta severo deficit del linguaggio o della comprensione, problemi nel riconoscere i famigliari, incapacità a deambulare in modo autonomo, problemi ad alimentarsi da solo e nel controllare la funzione intestinale o vescicale.</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="4">Demenza molto grave</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Il paziente richiede assistenza totale perché completamente incapace di comunicare, in stato vegetativo, allettato, incontinente.</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="5">Demenza terminale</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </el-form-item>
            </el-row>
          </el-col>
        </el-row>
      </el-row>

      <el-row :gutter="20" class="section">
        <el-divider content-position="center">Casa e tempo libero</el-divider>
        <el-row>
          <el-col :offset="1" :span="24">
            <el-row>
              <el-form-item prop="q5_result">
                <el-radio-group v-model="result.q5_result" style="width: 90%">
                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Vita domestica e interessi intellettuali conservati</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="0">Normale</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Vita domestica e interessi attuali lievemente compromessi</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="0.5">Demenza dubbia</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Lieve ma sensibile compromissione della vita domestica; abbandono hobby e interessi</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="1">Demenza lieve</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Interessi ridotti, non sostenuti, vita domestica ridotta a funzioni semplici</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="2">Demenza moderata</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Nessuna funzionalità fuori dalla propria camera</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="3">Demenza grave</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Il paziente presenta severo deficit del linguaggio o della comprensione, problemi nel riconoscere i famigliari, incapacità a deambulare in modo autonomo, problemi ad alimentarsi da solo e nel controllare la funzione intestinale o vescicale.</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="4">Demenza molto grave</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Il paziente richiede assistenza totale perché completamente incapace di comunicare, in stato vegetativo, allettato, incontinente.</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="5">Demenza terminale</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </el-form-item>
            </el-row>
          </el-col>
        </el-row>
      </el-row>

      <el-row :gutter="20" class="section">
        <el-divider content-position="center">Cura personale</el-divider>
        <el-row>
          <el-col :offset="1" :span="24">
            <el-row>
              <el-form-item prop="q6_result">
                <el-radio-group v-model="result.q6_result" style="width: 90%">
                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Interamente capace di curarsi della propria persona</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="0">Normale</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Richiede facilitazione</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="0.5">Demenza dubbia</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Richiede aiuto per vestirsi, igiene, utilizzazione di effetti personali</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="1">Demenza lieve</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Richiede molta assistenza per cura personale; non incontinenza urinaria</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="2">Demenza moderata</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Richiede molta assistenza per cura personale; incontinenza urinaria</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="3">Demenza grave</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Il paziente presenta severo deficit del linguaggio o della comprensione, problemi nel riconoscere i famigliari, incapacità a deambulare in modo autonomo, problemi ad alimentarsi da solo e nel controllare la funzione intestinale o vescicale.</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="4">Demenza molto grave</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />

                  <el-row>
                    <el-col :span="24">
                      <el-col :span="18">
                        <el-form-item>Il paziente richiede assistenza totale perché completamente incapace di comunicare, in stato vegetativo, allettato, incontinente.</el-form-item>
                      </el-col>
                      <el-col :span="6">
                        <el-form-item>
                          <el-radio :label="5">Demenza terminale</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-row>
                </el-radio-group>
              </el-form-item>
            </el-row>
          </el-col>
        </el-row>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="20">
            <el-col :span="10">
              <el-form-item label="Totale">
                <el-input v-model="form.total" :disabled="true">
                  <template slot="append">/ {{form.total_quest}}</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Cdrs",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 30
      },
      result: {},
      testRules: rules(6)
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$emit("submitTest", this.form, this.result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
    totalDescription() {
      let condMap = _.cond([
        [_.matches({ total: 0 }), _.constant("Basso")],
        [_.conforms({ total: n => _.gt(n, 18) }), _.constant("Basso")],
        [_.conforms({ total: n => _.inRange(n, 14, 18) }), _.constant("Medio")],
        [_.conforms({ total: n => _.inRange(n, 10, 14) }), _.constant("Alto")],
        [
          _.conforms({ total: n => _.inRange(n, 1, 10) }),
          _.constant("Altissimo")
        ]
      ]);
      return condMap(this.form);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.section {
  margin-top: 3rem;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>