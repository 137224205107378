<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24" v-for="(category, idx) in categories" :key="idx + '-cat'">
          <el-divider content-position="center" class='text-center'>{{category.name }}</el-divider>
          <el-form-item :prop="category.result">
            <el-col :span="24" v-for="(option, idx2) in category.options" :key="idx2 + '-opt'">
              <el-col :span="16">
                <el-form-item :label="option.label" />
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col :offset="12">
                    <el-form-item>
                      <el-radio :label="option.points" v-model="result[category.result]" border />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-col>
          </el-form-item>
          <el-col :span="24" v-show="result[category.result] == 100 && category.result == 'q2_result'">
            <el-form-item size="mini">
                <div style="padding-left: 35px;">
                    <div>
                        <el-checkbox
                        v-model="result.sub_result_of_q2"
                        label="Patologie"
                        style="display: flex; align-items: center;"
                        >A) anziani con patologie croniche in labile compenso con elevata necessità di tutela sanitaria <br> (come ad esempio: cure mediche e/o infermieristiche quotidiane, trattamenti di recupero funzionale, <br> somministrazione di terapie e. v., etc.)</el-checkbox>
                    </div>
                    <div>
                        <el-checkbox
                        v-model="result.sub_result_of_q2"
                        label="Trattamenti"
                        >B) trattamenti specialistici</el-checkbox>
                    </div>
                </div>
            </el-form-item>
            <el-col :span="24" v-show="result.sub_result_of_q2.includes('Trattamenti')">
                <el-form-item size="mini">
                    <div style="padding-left: 35px;">
                        <div>
                            <el-checkbox
                            v-model="result.sub_result_of_q2_3"
                            label="Alimentazione"
                            style="display: flex; align-items: center;"
                            >Alimentazione parentale o idratazione mediante catetere venoso centrale (CVC).<br>Alimentazione parentale o idratazione per periodi superiori <br> a 15 giorni mediante catetere venoso periferico (CVP)</el-checkbox>
                        </div>
                        <div>
                            <el-checkbox
                            v-model="result.sub_result_of_q2_3"
                            label="SNG/PEG"
                            >SNG/PEG</el-checkbox>
                        </div>
                        <div>
                            <el-checkbox
                            v-model="result.sub_result_of_q2_3"
                            label="Tracheostomia"
                            >Tracheostomia</el-checkbox>
                        </div>
                        <div>
                            <el-checkbox
                            v-model="result.sub_result_of_q2_3"
                            label="Respiratore"
                            >Respiratore/ventilazione assistita</el-checkbox>
                        </div>
                        <div>
                            <el-checkbox
                            v-model="result.sub_result_of_q2_3"
                            label="Ossigenoterapia"
                            >Ossigenoterapia ad intervalli definiti o continuativi, non in acuzie</el-checkbox>
                        </div>
                        <div>
                            <el-checkbox
                            v-model="result.sub_result_of_q2_3"
                            label="Dialisi"
                            >Dialisi</el-checkbox>
                        </div>
                        <div>
                            <el-checkbox
                            v-model="result.sub_result_of_q2_3"
                            label="Trasfusioni"
                            >Trasfusioni eseguite in struttura</el-checkbox>
                        </div>
                        <div>
                            <el-checkbox
                            v-model="result.sub_result_of_q2_3"
                            label="Dolore"
                            >Controllo dolore per via parenterale o sottocutanea tramite infusori elastomerici o pompe</el-checkbox>
                        </div>
                        <div>
                            <el-checkbox
                            v-model="result.sub_result_of_q2_3"
                            label="Altri"
                            >Altri trattamenti specialistici</el-checkbox>
                        </div>
                    </div>
                </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24" v-show="result[category.result] == 100 && category.result == 'q9_result'">
            <el-form-item size="mini">
                <div style="padding-left: 35px;">
                    <div>
                        <el-checkbox
                        v-model="result.sub_result_of_q9"
                        label="Non in grado"
                            >Non è in grado di svolgere</el-checkbox>
                    </div>
                    <div>
                        <el-checkbox
                        v-model="result.sub_result_of_q9"
                        label="Rifiuta"
                        >Si rifiuta di svolgere</el-checkbox>
                    </div>
                </div>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template>
<script>
import { BINA } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Bina",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 1000
      },
      result: {
          sub_result_of_q2: [],
          sub_result_of_q2_3: [],
          sub_result_of_q9: []
      },
      testRules: BINA.rules(10),
      categories: BINA.categories
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let sub_q2 = this.result.sub_result_of_q2.join(',');
          let sub_q2_3 = this.result.sub_result_of_q2_3.join(',');
          let sub_q9 = this.result.sub_result_of_q9.join(',');
          this.result.sub_result_of_q2 = sub_q2;
          this.result.sub_result_of_q2_3 = sub_q2_3;
          this.result.sub_result_of_q9 = sub_q9;
          this.$emit("submitTest", this.form, this.result);
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(BINA.filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.bottom-space {
  margin-bottom: 50px;
}
.text-center {
    text-align: center;
}
</style>
