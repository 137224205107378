<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-col :span="6">
      <el-button
        class="float-left"
        round
        v-if="data.date_times_previous"
        @click="fetch(data.date_times_previous)"
        ><font-awesome-icon icon="long-arrow-alt-left" /> Indietro</el-button
      >
      <el-button class="float-left" round v-else disabled
        ><font-awesome-icon icon="long-arrow-alt-left" /> Indietro</el-button
      >
    </el-col>
    <el-col :span="12" class="text-center titleCol">
      <b class="title" v-if="data.date_time_current">{{
        data.date_time_current
      }}</b
      ><br /><small v-if="data.items">{{ data.items.full_operator }}</small>
    </el-col>
    <el-col :span="6">
      <el-button
        class="float-right"
        type="primary"
        round
        v-if="data.date_times_next"
        @click="fetch(data.date_times_next)"
        >Avanti <font-awesome-icon icon="long-arrow-alt-right"
      /></el-button>
      <el-button
        class="float-right"
        type="success"
        round
        v-else
        @click="submit()"
        :loading="submitLoading"
        >Salva</el-button
      >
    </el-col>
    <el-col :span="24" class="margin-top">
      <el-form label-position="top" label-width="100px" :model="form">
        <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
          <el-form-item label="Data di Nascita">
            <el-input
              v-model="patient.full_birthday"
              readonly
              disabled
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
          <el-form-item label="Provincia di Nascita">
            <el-input
              v-model="patient.birthplace_provincia"
              readonly
              disabled
              maxlength="2"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="Comune di Nascita">
            <el-input v-model="patient.birthplace" readonly disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Provincia di Residenza">
            <el-input
              v-model="form.residency_provincia"
              maxlength="2"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Comune di Residenza">
            <el-input v-model="form.residency"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Indirizzo">
            <el-input v-model="form.residency_address"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="CAP di residenza">
            <el-input
              v-model="form.residency_cap"
              maxlength="5"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="Stato Civile">
            <el-select v-model="form.marital_status" clearable>
              <el-option
                v-for="(item, index) in config.stato_civile"
                :key="index"
                :label="item"
                :value="index"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="Istruzione">
            <el-input v-model="form.education"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="Anni di scolarizzazione">
            <el-input-number
              v-model="form.education_years"
              :min="0"
              :max="20"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Gruppo Sanguigno">
            <el-input v-model="form.gruppo_sanguigno"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Motivo del Ricovero">
            <el-input v-model="form.motivation"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item>
            <el-checkbox
              v-model="form.handicap_grave"
              label="Handicap Grave"
              border
            ></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <el-input
              v-model="form.handicap_grave_dal"
              :disabled="form.handicap_grave"
            >
              <template slot="prepend">Domanda dal</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24"></el-col>
        <el-col :span="14">
          <el-form-item>
            <el-checkbox
              v-model="form.indennita_accompagnamento"
              label="Indennità Accompagnamento"
              border
            ></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <el-input
              v-model="form.indennita_accompagnamento_dal"
              :disabled="form.indennita_accompagnamento"
            >
              <template slot="prepend">Domanda dal</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24"></el-col>
        <el-col :span="7">
          <el-form-item>
            <el-checkbox
              v-model="form.invalidita_civile"
              label="Invalidità Civile"
              border
            ></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item>
            <el-input
              placeholder="Invalidità"
              v-model.number="form.invalidita_civile_percent"
              :disabled="!form.invalidita_civile"
              type="number"
            >
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item>
            <el-input
              v-model="form.invalidita_civile_dal"
              :disabled="form.invalidita_civile"
            >
              <template slot="prepend">Domanda dal</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Esenzioni">
            <el-input v-model="form.esenzioni" type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
import moment from "moment";
import Patient from "@/js/api/services/Patient";

export default {
  name: "Registry",
  props: ["profile_id"],
  data() {
    return {
      data: {},
      registry: {},
      patient: {},
      loading: true,
      submitLoading: false,
      form: {},
    };
  },
  computed: {
    config() {
      return this.$store.state.config.anagrafica;
    },
  },
  methods: {
    async fetch(dateTime) {
      this.loading = true;
      let params = {
        date_time: dateTime,
      };
      const response = await Patient.registry(this.profile_id, params);
      this.data = response.data;
      this.registry = response.data.items;
      this.patient = response.data.patient;
      this.form = this.registry;
      this.form.handicap_grave = this.form.handicap_grave == 1;
      this.form.indennita_accompagnamento =
        this.form.indennita_accompagnamento == 1;
      this.form.invalidita_civile = this.form.invalidita_civile == 1;

      this.loading = false;
    },
    submit() {
      this.$confirm("Salvare le modifiche?", "Attenzione", {
        confirmButtonText: "Sì",
        cancelButtonText: "Annulla",
        type: "success",
      })
        .then(() => {
          this.submitLoading = true;
          this.store();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        });
    },
    async store() {
      try {
        await Patient.patch(this.registry.id, this.form);
        this.success();
      } catch (error) {
        this.submitLoading = false;
      }
    },
    success() {
      this.fetch();
      this.$emit("anamnesiUpdate");
      this.submitLoading = false;
    },
  },
  mounted() {
    this.fetch(null);
  },
};
</script>
<style lang="scss" scoped>
.titleCol {
  padding-top: 9px;
}
.title {
  font-size: 20px;
}
.el-checkbox {
  width: 100%;
}
.el-input-number {
  width: 100%;
}
.margin-top {
  margin-top: 40px;
}
</style>
