<template>
    <el-form label-position="top" label-width="80px" :model="form" :rules="rules" ref="form">
        <el-row class="form-header" :gutter="5">
            <el-col :span="8">
                <el-form-item label="Data" prop="date">
                    <el-date-picker
                        v-model="form.date"
                        :editable="false"
                        type="date"
                        placeholder="Scegli data"
                        :picker-options="pickerConfirmTaskOptions"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="Orario" prop="time">
                    <el-time-picker
                        v-model="form.time"
                        :editable="false"
                        format="HH:mm"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :picker-options="{
                            selectableRange: selectableTime
                        }"
                        placeholder="Seleziona Orario">
                    </el-time-picker>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="Ospite" prop="patient_id">
                    <el-select v-model="form.patient_id" placeholder="Seleziona Ospite" filterable clearable>
                        <el-option
                            v-for="item in patients"
                            :key="item.id"
                            :label="item.full_name"
                            :value="item.id"
                            >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <div class="block with-form-header" v-loading="loading">
            <el-row :gutter="20">
                <span v-if="showAbsentPatientAlert" style="float: right; margin-right: 15px;">
                  <font-awesome-icon icon="exclamation" class="danger-text" />
                  <font style="color: red"
                    >Attenzione: l'ospite è attualmente assente</font
                  >
                </span>
                <span v-if="showOldAbsencePatientAlert" style="float: right; margin-right: 15px;">
                    <font-awesome-icon icon="exclamation" class="danger-text" />
                    <font style="color: red"
                        >Attenzione: l'ospite era assente in questo periodo</font
                    >
                </span>
                <el-col :span="12">
                    <el-form-item label="Orientamento">
                        <el-radio-group v-model="form.orientamento" size="large">
                            <el-radio-button :label="1">Sì</el-radio-button>
                            <el-radio-button :label="2">Parziale</el-radio-button>
                            <el-radio-button :label="3">No</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Attenzione / Concentrazione">
                        <el-radio-group v-model="form.attenzione_concentrazione" size="large">
                            <el-radio-button :label="1">Sì</el-radio-button>
                            <el-radio-button :label="2">Parziale</el-radio-button>
                            <el-radio-button :label="3">No</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Motivazione">
                        <el-radio-group v-model="form.motivazione" size="large">
                            <el-radio-button :label="1">Buona</el-radio-button>
                            <el-radio-button :label="2">Scarsa</el-radio-button>
                            <el-radio-button :label="3">Assente</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Collaborazione">
                        <el-radio-group v-model="form.collaborazione" size="large">
                            <el-radio-button :label="1">Buona</el-radio-button>
                            <el-radio-button :label="2">Scarsa</el-radio-button>
                            <el-radio-button :label="3">Assente</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24"><h3 class="text-center">Esame Obiettivo</h3></el-col>
                <el-col :span="24"><h4>A Letto</h4></el-col>
                <el-col :span="12">
                    <el-form-item label="Testa" >
                        <el-input type="textarea" v-model="form.letto_testa"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Tronco" >
                        <el-input type="textarea" v-model="form.letto_tronco"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Arti Inferiori" >
                        <el-input type="textarea" v-model="form.letto_arti_inferiori"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Arti Superiori" >
                        <el-input type="textarea" v-model="form.letto_arti_superiori"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24"><h4>Da seduto</h4></el-col>
                <el-col :span="12">
                    <el-form-item label="Testa" >
                        <el-input type="textarea" v-model="form.seduto_testa"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Tronco" >
                        <el-input type="textarea" v-model="form.seduto_tronco"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Arti Inferiori" >
                        <el-input type="textarea" v-model="form.seduto_arti_inferiori"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Arti Superiori" >
                        <el-input type="textarea" v-model="form.seduto_arti_superiori"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24"><h4>In stazione eretta</h4></el-col>
                <el-col :span="12">
                    <el-form-item label="Testa" >
                        <el-input type="textarea" v-model="form.eretto_testa"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Tronco" >
                        <el-input type="textarea" v-model="form.eretto_tronco"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Arti Inferiori" >
                        <el-input type="textarea" v-model="form.eretto_arti_inferiori"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Arti Superiori" >
                        <el-input type="textarea" v-model="form.eretto_arti_superiori"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24"><h3 class="text-center">Fine Esame Obiettivo</h3></el-col>
                <el-col :span="12">
                    <el-form-item label="Tono Muscolare" >
                        <el-input type="textarea" v-model="form.tono_muscolare"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Trofismo Muscolare" >
                        <el-input type="textarea" v-model="form.trofismo_muscolare"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Escursione Articolare" >
                        <el-input type="textarea" v-model="form.escursione_articolare"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Test Tinetti" >
                        <el-input-number type="number" v-model.number="form.test_tinetti" :min="0" :max="28" :step="1" autocomplete="off"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="24"><h3 class="text-center">Mobilizzazione</h3></el-col>
                <el-col :span="12">
                    <el-form-item label="Deambulazione">
                        <el-radio-group v-model="form.deambulazione" size="large">
                            <el-radio-button :label="1">Tratti Lunghi</el-radio-button>
                            <el-radio-button :label="2">Tratti Brevi</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Schema del cammino" >
                        <el-input type="textarea" v-model="form.cammino"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Scale">
                        <el-radio-group v-model="form.scale" size="large">
                            <el-radio-button :label="1">Sì</el-radio-button>
                            <el-radio-button :label="2">Con Aiuto</el-radio-button>
                            <el-radio-button :label="3">No</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Coordinazione" >
                        <el-input type="textarea" v-model="form.coordinazione"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Sensibilità propriocettiva">
                        <el-radio-group v-model="form.sensibilita_propriocettiva" size="large">
                            <el-radio-button :label="1">Sì</el-radio-button>
                            <el-radio-button :label="2">Alterata</el-radio-button>
                            <el-radio-button :label="3">No</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Sensibilità esterocettiva">
                        <el-radio-group v-model="form.sensibilita_esterocettiva" size="large">
                            <el-radio-button :label="1">Sì</el-radio-button>
                            <el-radio-button :label="2">Alterata</el-radio-button>
                            <el-radio-button :label="3">No</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Prese e pinze di precisione" >
                        <el-input type="textarea" v-model="form.prese_pinze_precisione"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Altri problemi" >
                        <el-input type="textarea" v-model="form.altri_problemi"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="24"><h3 class="text-center">Autonomia negli Spostamenti sul Letto</h3></el-col>
                <el-col :span="12">
                    <el-form-item label="Supino/Fianco e viceversa">
                        <el-radio-group v-model="form.spostamenti_letto_supino_fianco" size="large">
                            <el-radio-button :label="1">Sì</el-radio-button>
                            <el-radio-button :label="2">Con Aiuto</el-radio-button>
                            <el-radio-button :label="3">No</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Supino/Seduto e viceversa">
                        <el-radio-group v-model="form.spostamenti_letto_supino_seduto" size="large">
                            <el-radio-button :label="1">Sì</el-radio-button>
                            <el-radio-button :label="2">Con Aiuto</el-radio-button>
                            <el-radio-button :label="3">No</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24"><h3 class="text-center">Trasferimenti</h3></el-col>
                <el-col :span="12">
                    <el-form-item label="Letto/Carrozzina e viceversa">
                        <el-radio-group v-model="form.trasferimenti_letto_carrozzina" size="large">
                            <el-radio-button :label="1">Sì</el-radio-button>
                            <el-radio-button :label="2">Con Aiuto</el-radio-button>
                            <el-radio-button :label="3">No</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Seduto/Eretto e viceversa">
                        <el-radio-group v-model="form.trasferimenti_seduto_eretto" size="large">
                            <el-radio-button :label="1">Sì</el-radio-button>
                            <el-radio-button :label="2">Con Aiuto</el-radio-button>
                            <el-radio-button :label="3">No</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="Altre patologie" >
                        <el-input type="textarea" v-model="form.patologia_problemi"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="Ipotesi di trattamento" >
                        <el-input type="textarea" v-model="form.ipotesi_trattamento"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item label="Note">
                        <el-input type="textarea" v-model="form.note_altro"></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="24" class="margin-bottom">
                    <el-button type="success" @click="submit('form')" class="float-right fixed-confirm-button" plain :loading="submitLoading">Conferma Valutazione</el-button>
                </el-col>
            </el-row>
        </div>
    </el-form>
</template>
<script>
import moment from 'moment';
import Physiotherapy from '@/js/api/services/Physiotherapy';
export default {
    name: 'CreatePhysiotherapy',
    data(){
        return{
             //Inizializzazione variabili
            patient_id: null,
            loading: false,
            submitLoading: false,

            form: {},
            rules: {
                time: [ { required: true, message: 'Inserire Orario', trigger: 'blur' } ],
                date: [ { required: true, message: 'Inserire Data', trigger: 'blur' } ],
                patient_id: [ { required: true, message: 'Selezionare Ospite', trigger: 'blur' } ]
            },
            pickerConfirmTaskOptions:{
                firstDayOfWeek: 1,
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            showAbsentPatientAlert: false,
            showOldAbsencePatientAlert: false,
            patient: null,
        }
    },
    computed:{
        patients() { return this.$store.state.patients },
        selectableTime(){
            var endTime = new moment();
            var startTime = moment(endTime).add(-4,'hours').format('HH:mm:ss');
            return startTime+' - '+endTime.format('HH:mm:ss');
        },
    },
    methods:{
        async store(){
            let params = {
                date: moment(this.form.date).format('YYYY-MM-DD'),
                time: moment(this.form.time).format('HH:mm:ss'),
                patient_id: this.form.patient_id,
                physiotherapy: this.form
            }
            try{
                await Physiotherapy.store(params);
                this.$router.push({name: 'Fisioterapie'})
            }
            catch(error){
                this.submitLoading = false;
            }
        },
        submit(formName){
            this.$confirm('Confermare valutazione?', 'Attenzione', {
                confirmButtonText: 'Sì',
                cancelButtonText: 'Annulla',
                type: 'success'
            }).then(() => {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.submitLoading = true;
                        this.store();
                    } else {
                        return false;
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Operazione annullata'
                });
            });
        }
    },
    watch: {
    form: {
      deep: true,
      handler(data) {
        if(this.form.patient_id){
            this.patient = _.filter(this.patients, { id: this.form.patient_id })[0];
            this.showAbsentPatientAlert = this.patient.absence_info.absence_status;
            this.showOldAbsencePatientAlert = false;
            if (!this.showAbsentPatientAlert && this.patient.absence_info.last_absence){
                var date = `${moment(this.form.date).format("YYYY-MM-DD")} ${moment(this.form.time).format("HH:mm:ss")}`;
                if (date > this.patient.absence_info.last_absence['confirmation_date'] && date < this.patient.absence_info.last_absence['verification_date']) {
                    this.showOldAbsencePatientAlert = true;
                }
            }
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
    .el-radio{
        margin-right: 0px!important;
    }

    .margin-bottom{
        margin-bottom: 5px;
    }
</style>
