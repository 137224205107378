import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Log extends ArkiBaseService{

    static endpoint = 'logs';

    static store(params) {
        return Api.post(this.endpoint, params);
    }

}