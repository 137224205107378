<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24" v-for="idx in 12" :key="'q-' + idx">
          <el-row type="flex" class="row-bg" justify="center">
            <el-card class="box-card" shadow="never">
              <div slot="header" class="clearfix">
                <span>
                  <i class="el-icon-info" />
                  {{ info(idx) }}
                </span>
              </div>

              <el-form-item :prop="`q${idx}_result`">
                <el-col :span="24">
                  <el-col :span="16">
                    <el-form-item :label="question(idx)" required />
                  </el-col>
                  <el-col :span="8">
                    <el-radio-group v-model="result[`q${idx}_result`]">
                      <el-radio :label="1" border>Si</el-radio>
                      <el-radio :label="0" border @change="changed(idx)">NO</el-radio>
                    </el-radio-group>
                  </el-col>
                </el-col>
              </el-form-item>
              <el-col :span="24">
                <transition name="el-zoom-in-top">
                  <el-row :gutter="20" v-if="result[`q${idx}_result`]">
                    <el-col :span="24">
                      <el-divider />
                      <el-col :span="24" v-for="metric in questions.metrics" :key="metric.name">
                        <el-form-item
                          :prop="`q${idx}_${metric.slug}_result`"
                          :inline-message="true"
                        >
                          <el-col :span="10">
                            <el-form-item>{{metric.name}}</el-form-item>
                          </el-col>

                          <el-col :span="14">
                            <el-row>
                              <el-col>
                                <div>
                                  <el-radio-group v-model="result[`q${idx}_${metric.slug}_result`]">
                                    <el-radio-button
                                      v-for="opt in metric.range"
                                      :key="opt + '-opt'"
                                      :label="opt"
                                    />
                                  </el-radio-group>
                                </div>
                              </el-col>
                            </el-row>
                          </el-col>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-col :span="10">
                          <el-form-item>a x b</el-form-item>
                        </el-col>
                        <el-col :span="14">
                          <el-row>
                            <el-col>
                              <el-form-item>
                                <div>
                                  <el-radio-group disabled>
                                    <el-radio-button :label="axb(idx)"></el-radio-button>
                                  </el-radio-group>
                                </div>
                              </el-form-item>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-col>
                    </el-col>
                  </el-row>
                </transition>
              </el-col>
            </el-card>
          </el-row>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Totale (Frequenza x Gravitá)">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template>
<script>
import { rules, filterResults, createResults, totalOf } from "../constants";
import TestActions from "../components/TestActions";

const generateKeys = idx => [
  `q${idx}_freq_result`,
  `q${idx}_grav_result`,
  `q${idx}_stress_result`
];
const custom = _.flattenDeep(_.range(1, 13).map(generateKeys));

export default {
  name: "Npi",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 144,
      },
      result: {},
      testRules: rules(12, custom),
      questions: {
        metrics: [
          { name: "Frequenza (a)", slug: "freq", range: [1, 2, 3, 4] },
          { name: "Gravitá (b)", slug: "grav", range: [1, 2, 3] },
          { name: "Distress", slug: "stress", range: [0, 1, 2, 3, 4, 5] }
        ],
        "1": {
          info:
            "Il paziente crede cose che non sono vere? Per esempio, insiste sul fatto che qualcuno sta cercando di fargli del male o di rubargli qualcosa. Dice che i componenti della famiglia non sono chi dicono di essere, o che la casa non è la sua? Non mi riferisco a semplice sospettosità; siamo interessati a sapere se il paziente sia convinto che queste cose gli stiano realmente accadendo.",
          question: "Deliri"
        },
        "2": {
          info:
            "Il paziente ha allucinazioni, cioè vede o sente cose che non esistono? Sembra vedere, sentire o provare cose non presenti? Con questa domanda non intendiamo solamente convinzioni sbagliate, cioè affermare che una persona morta sia ancora viva; piuttosto vogliamo sapere se il paziente presenta la percezione non normale di suoni o di visioni.",
          question: "Allucinazioni"
        },
        "3": {
          info:
            "Il paziente ha periodi durante i quali rifiuta di collaborare o durante i quali non si lascia aiutare dagli altri? È difficile da gestire?",
          question: "Agitazione"
        },
        "4": {
          info:
            "Il paziente sembra essere triste o depresso? Dice di sentirsi triste o depresso?",
          question: "Depressione/disforia"
        },
        "5": {
          info:
            "Il paziente è molto nervoso, allarmato, spaventato senza veri motivi? Sembra molto teso o agitato? È impaurito dal rimanere lontano da voi?",
          question: "Ansia"
        },
        "6": {
          info:
            " Il paziente è eccessivamente felice o allegro senza motivo? Non si intende la normale felicità mostrata alla vista di amici, quando si ricevono regali, o quando si sta coi parenti. Vi sto chiedendo se il paziente ha un persistente ed anormale stato di euforia o se trova ridicole cose che gli altri non trovano divertenti.",
          question: "Euforia/esaltazione"
        },
        "7": {
          info:
            "Il paziente ha interesse verso il mondo che lo circonda? Ha perso interesse nel fare le cose o è meno motivato ad iniziare cose nuove? Il paziente ha difficoltà nell'introdursi in conversazioni o nelle faccende di casa? Il paziente è apatico o indifferente?",
          question: "Apatia/indifferenza"
        },
        "8": {
          info:
            " Il paziente sembra agire impulsivamente senza pensarci? Fa o dice cose che di solito non dice o non fa in pubblico? Fa cose imbarazzanti per voi o per altri?",
          question: "Disinibizione"
        },
        "9": {
          info:
            "Il paziente si irrita o si arrabbia con facilità? Il suo umore è molto variabile? È impaziente in modo anormale? Non intendiamo frustrazione per le difficoltà di memoria o l'incapacità di compiere semplici operazioni; vogliamo sapere se il paziente presenta un'irritabilità anomala, impazienza o rapidi cambiamenti di umore che si differenziano dal solito.",
          question: "Irritabilità/labilità"
        },
        "10": {
          info:
            " Il paziente continua a camminare, continua a fare e rifare le stesse cose come aprire gli armadi o i cassetti, oppure sposta in continuazione gli oggetti o attorciglia le stringhe od i lacci?",
          question: "Attività motoria"
        },
        "11": {
          info:
            " Il paziente presenta disturbi del sonno? Sta alzato, vaga per la casa durante la notte, si veste e si sveste, disturba il sonno dei familiari? [Non è da considerare se il paziente si alza due o tre volte per notte, per andare in bagno e poi torna a letto e si addormenta immediatamente]",
          question: "Sonno"
        },
        "12": {
          info:
            "Il paziente presenta disturbi dell'alimentazione come alterazioni dell'appetito, alterazioni delle abitudini o delle preferenze alimentari. Ha subito variazioni di peso? [Se è incapace ad alimentarsi rispondere NA]",
          question: "Disturbi dell'appetito e dell'alimentazione"
        }
      }
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$emit("submitTest", this.form, this.result);
        } else {
          return false;
        }
      });
    },
    info(idx) {
      return _.get(this.questions, [idx, "info"], "no info");
    },
    question(idx) {
      return _.get(this.questions, [idx, "question"], "no question");
    },
    axb(idx) {
      const freq = `q${idx}_freq_result`;
      const grav = `q${idx}_grav_result`;
      let vals = [this.result[freq], this.result[grav]];
      let missing = vals.some(_.isNil);
      return missing ? 0 : _.multiply(...vals);
    },
    changed(idx) {
      let keys = [
        `q${idx}_freq_result`,
        `q${idx}_grav_result`,
        `q${idx}_stress_result`
      ];

      let fields = keys.reduce((t, k) => {
        // I need that undefined
        t[k] = this.result[k];
        return t;
      }, {});

      let allNil = _.every(_.values(fields), _.isNil);
      if (allNil) return;
      keys.map(k => this.$delete(this.result, k));
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        const total = _.sum(_.range(1, 13).map(idx => {
                    const freq = this.result[`q${idx}_freq_result`] || 0;
                    const grav = this.result[`q${idx}_grav_result`] || 0;
                    return freq * grav;
                }))
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>


<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 98%;
}

.el-row {
  margin-bottom: 20px;
}

.collapse-header {
  font-size: 16px;
}
.head {
  padding: 4px;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>
