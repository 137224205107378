import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Contact extends ArkiBaseService {
    
    static endpoint = 'contacts';

    static deleteConnection(id){
        return Api.delete(`${this.endpoint}/${id}/connection`);
    } 
}