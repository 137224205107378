<template>
  <div class="block no-filters">
    <el-row v-loading="loading" :gutter="20">
      <el-col :span="24">
        <el-button
          class="float-left"
          round
          @click="$router.push('/superadmin/operators')"
        >
          <font-awesome-icon icon="long-arrow-alt-left" />Indietro
        </el-button>
      </el-col>
      <el-col :span="24" class="margin-top">
        <el-form
          :model="form"
          label-position="top"
          label-width="100px"
          :rules="rules"
          ref="form"
        >
          <div class="md-title" style="text-align: center; padding: 10px 0">
            {{ operator.surname + " " + operator.name }}
          </div>

          <el-col :span="12">
            <el-form-item label="Ruolo" prop="role_id">
              <el-select
                v-model="form.user_role"
                placeholder="Selezionare ruolo"
              >
                <el-option
                  v-for="(item, index) in roles"
                  :key="index"
                  :label="item"
                  :value="Number(index)"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Professione" prop="profession_id">
              <el-select
                v-model="form.user_profession"
                placeholder="Selezionare professione"
              >
                <el-option
                  v-for="(item, index) in professions"
                  :key="index"
                  :label="item.label"
                  :value="Number(index)"
                  :disabled="!item.enabled"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="14" class="checkbox-title">
            Strutture Associate
          </el-col>
          <el-col :span="10" align="right">
            <el-checkbox @change="handleCheckAll" v-model="allSelected"
              >Seleziona Tutto</el-checkbox
            >
          </el-col>

          <el-col :span="24">
            <el-divider></el-divider>
          </el-col>

          <el-checkbox-group v-model="form.user_structures">
            <el-col
              :span="12"
              :gutter="25"
              v-for="(item, index) in structures"
              :key="index"
            >
              <el-checkbox
                :label="index"
                :checked="user_structures_loaded.includes(Number(index))"
                @change="checkClicked"
              >
                {{ item }}
              </el-checkbox>
            </el-col>
          </el-checkbox-group>

          <el-col :span="24">
            <el-divider></el-divider>
            <el-col :span="12" :gutter="0">
              <el-button
                size="large"
                type="warning"
                @click="$router.push('/superadmin/operators')"
              >
                Annulla
              </el-button>
            </el-col>

            <el-col :span="12" :gutter="0" align="right">
              <el-button
                size="large"
                type="success"
                @click.native.prevent="submit()"
                :loading="loading"
              >
                Aggiorna Operatore
              </el-button>
            </el-col>
          </el-col>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SuperadminOperatorsService from "@/js/api/services/superadmin/Operators";

export default {
  name: "SuperadminOperatorsEdit",
  data() {
    return {
      loading: true,
      roles: [],
      professions: [],
      structures: [],
      operator: [],
      user_structures_loaded: [],
      form: {
        user_role: null,
        user_profession: null,
        user_structures: [],
      },
      rules: {
        user_role: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        user_profession: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
      },
      allSelected: false,
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 205;
    },
  },
  methods: {
    async fetch() {
      const response = await SuperadminOperatorsService.getOperator(
        this.$route.params.id
      );
      this.form.user_role = _.get(response, "data.operator.role_id");
      this.form.user_profession = _.get(
        response,
        "data.operator.profession_id"
      );
      this.user_structures_loaded = response.data.operator.structures_array;
      this.roles = response.data.roles;
      this.professions = this.$store.state.professions;
      this.structures = response.data.structures;
      this.operator = response.data.operator;
      this.loading = false;
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.store();
        } else {
          return false;
        }
      });
    },
    async store() {
      const response = await SuperadminOperatorsService.updateOperator(
        this.operator.id,
        this.form
      );
      this.$router.push({ path: "/superadmin/operators" });
    },
    handleCheckAll() {
      this.form.user_structures = [];

      if (this.allSelected) {
        for (let index in this.structures) {
          this.form.user_structures.push(index);
        }
      }
    },
    checkClicked() {
      this.allSelected = false;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>


<style lang="scss" scoped>
.checkbox-title {
  color: #606266;
}
.md-app-content {
  height: auto !important;
}
</style>

