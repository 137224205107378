<template>
  <div>
    <arki-filters
      ref="filters"
      @filtered="fetch"
      target="protections"
      :dateRangeFilter="true"
      :optionsFilter="true"
    />
    <arki-create-dialog
      title="CONTENZIONE IN EMERGENZA"
      dateOptions="past"
      :rules="rules"
      action="/protections"
      :options="protection_types"
      @submit="update"
      v-if="!selected"
    />
    <arki-check-dialog
      @success="update"
      class="fixed-check-button"
      buttonType="primary"
      icon="check"
      :title="'Conferma Verifica Contenzioni'"
      :action="'protectionVerifications'"
      :rules="checkRules"
      option="protectionsVerification"
      method="patch"
      v-else-if="selected"
      :selection="selection"
    />
    <arki-broadcast
      ref="broadcast"
      listen=".protection.created"
      @update="update"
    />
    <div class="block with-filters">
      <el-table
        :class="paginateTable"
        :data="protections.data"
        :default-sort="{ prop: 'time', order: 'descending' }"
        style="width: 100%"
        :row-class-name="rowClass"
        :max-height="maxHeight"
        v-loading="loading"
        @selection-change="handleSelection"
      >
        <el-table-column
          type="selection"
          width="50"
          :selectable="isSelectable"
        ></el-table-column>
        <el-table-column type="expand" width="30">
          <template slot-scope="scope">
            <el-col :span="12">
              <b>Inserimento:</b> {{ scope.row.full_creator }} -
              {{ scope.row.created_at_time }}
            </el-col>
            <el-col :span="12" v-if="scope.row.protection.description">
              <b>Note:</b> {{ scope.row.protection.description }}
            </el-col>
            <el-col :span="12" v-if="scope.row.full_verification_operator">
              <b v-if="scope.row.enabled == 1">Verifica:</b>
              <b v-else-if="scope.row.enabled == 0">Annullamento:</b>
              {{ scope.row.full_verification_operator }} -
              {{ scope.row.clean_verification_time }}
              {{ scope.row.clean_verification_date }}
              {{ scope.row.removed_at_time }}
            </el-col>
            <el-col :span="12" v-if="scope.row.verification_notes">
              <b>Note Verifica:</b> {{ scope.row.verification_notes }}
            </el-col>
          </template>
        </el-table-column>
        <el-table-column
          prop="clean_date_time"
          label="Orario"
          sortable
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="full_patient"
          label="Ospite"
          width="230"
        ></el-table-column>
        <el-table-column
          prop="category_description"
          label="Categoria"
          min-width="230"
        ></el-table-column>
        <el-table-column
          prop="type_description"
          label="Controllo"
          min-width="190"
        ></el-table-column>
        <el-table-column
          prop="protection.alternative_practices"
          label="Pratiche alternative testate"
          min-width="250"
        ></el-table-column>
        <el-table-column
          prop="description"
          label="Osservazioni"
          min-width="250"
        ></el-table-column>
        <el-table-column
          prop="clean_postura_corretta"
          label="Postura corretta"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clean_integrita_cutanea"
          label="Integrità cutanea"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clean_tolleranza_del_mezzo"
          label="Mezzo tollerato"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        class="paginate"
        background
        layout="prev, pager, next"
        :page-size="protections.per_page"
        :total="protections.total"
        :hide-on-single-page="true"
        :current-page="current_page"
        @current-change="changePage"
        :disabled="!pagination"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import ArkiFilters from "@/js/components/ArkiFilters";
import ArkiCreateDialog from "@/js/components/ArkiCreateDialog";
import ArkiCheckDialog from "@/js/components/ArkiCheckDialog";
import ArkiBroadcast from "@/js/components/ArkiBroadcast";

import Protection from "@/js/api/services/Protection";
export default {
  name: "Protections",
  components: {
    ArkiFilters,
    ArkiCreateDialog,
    ArkiCheckDialog,
    ArkiBroadcast,
  },
  data() {
    return {
      loading: true,
      protections: {
        data: [],
        per_page: null,
        total: null,
      },
      protection_types: [],
      current_page: 1,
      pagination: true,
      selected: false,
      selection: [],

      rules: {
        patient_id: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        category: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
        date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
        option: [
          { required: true, message: "Selezionare Categoria", trigger: "blur" },
        ],
        description: [
          { required: false, message: "Campo Obbligatorio", trigger: "blur" },
        ],
      },
      checkRules: {
        time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
        date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
        description: [
          { required: false, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        verification_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
      },

      paginateTable: null,
      // first fetch is from filter
      unchangedFilter: false,
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 235;
    },
  },
  watch: {
    protections: function () {
      this.protections.total / this.protections.per_page > 1
        ? (this.paginateTable = "paginate-table")
        : (this.paginateTable = null);
    },
  },
  methods: {
    async init() {
      const response = await Protection.settings();
      this.protection_types = response.data;
    },
    update() {
      this.unchangedFilter = true;
      if (this.$refs.filters) {
        this.$refs.filters.emit();
        this.$refs.broadcast.reset();
      }
    },
    async fetch(filters) {
      if (filters.patient_id != this.$route.query.patient_id) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            patient_id: filters.patient_id || undefined,
            t: new Date().getTime(),
          },
        });
        return;
      }
      if (!this.unchangedFilter) {
        this.current_page = 1;
      }
      this.loading = true;
      let params = {
        date_from: moment(filters.dateRange[0]).format("DD/MM/YYYY"),
        date_to: moment(filters.dateRange[1]).format("DD/MM/YYYY"),
        patient_id: filters.patient_id,
        protection_filters: filters.option,
        page: this.current_page,
      };
      const response = await Protection.verifications(params);
      this.protections = response.data.verifications;
      this.loading = false;
      // reset the checked flag
      this.unchangedFilter = false;
    },
    changePage(page) {
      this.loading = true;
      this.current_page = page;
      this.update();
    },
    handleSelection(val) {
      this.selected = val.length > 0;
      this.selection = val;
    },
    rowClass({ row, rowIndex }) {
      if (row.enabled == 0) {
        return "deleted-row";
      } else if (row.verification_result == 1) {
        return "primary-row";
      } else if (row.verification_result == 2) {
        return "warning-row";
      } else if (row.verification_result == 3) {
        return "success-row";
      }
      return "";
    },
    isSelectable(row, rowIndex) {
      return row.verification_result == 0;
    },
  },
  mounted() {
    this.init();
  },
};
</script>
