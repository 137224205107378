var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('arki-filters',{ref:"filters",attrs:{"target":"tasks","dateRangeFilter":true,"optionsFilter":false,"options":_vm.task_types_arkifilters,"professionsFilter":true,"optionSpan":8},on:{"filtered":_vm.fetch}}),_vm._v(" "),_c('arki-create-dialog',{attrs:{"title":"Consegna","dateOptions":"future","rules":_vm.rules,"action":"/tasks","options":_vm.task_types},on:{"submit":_vm.update}}),_vm._v(" "),_c('arki-broadcast',{ref:"broadcast",attrs:{"listen":".task.created"},on:{"update":_vm.update}}),_vm._v(" "),_c('div',{staticClass:"block with-filters"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],class:_vm.paginateTable,staticStyle:{"width":"100%"},attrs:{"data":_vm.tasks.data,"default-sort":{ prop: 'time', order: 'descending' },"max-height":_vm.maxHeight,"row-class-name":_vm.rowClass}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-col',{attrs:{"span":24}},[_c('b',[_vm._v("Inserimento:")]),_vm._v(" "+_vm._s(scope.row.full_creator)+" ("+_vm._s(_vm.professions[scope.row.profession].label)+") -\n            "+_vm._s(scope.row.created_at_time)+"\n          ")]),_vm._v(" "),(scope.row.full_verification_operator)?_c('el-col',{attrs:{"span":24}},[(scope.row.enabled == 1)?_c('b',[_vm._v("Verifica:")]):(scope.row.enabled == 0)?_c('b',[_vm._v("Annullamento:")]):_vm._e(),_vm._v("\n            "+_vm._s(scope.row.full_verification_operator)+"\n            "+_vm._s(scope.row.updater_profession
                ? "(" + _vm.professions[scope.row.updater_profession].label + ")"
                : "")+"\n            -\n            "+_vm._s(scope.row.clean_verification_time)+"\n            "+_vm._s(scope.row.clean_verification_date)+"\n            "+_vm._s(scope.row.removed_at_time)+"\n          ")]):_vm._e(),_vm._v(" "),(scope.row.verification_notes)?_c('el-col',{attrs:{"span":24}},[_c('b',[_vm._v("Note:")]),_vm._v(" "+_vm._s(scope.row.verification_notes)+"\n          ")]):_vm._e()]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"type","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{attrs:{"content":scope.row.type_description,"placement":"bottom","effect":"light"}},[_c('el-button',{style:({
                backgroundColor: _vm.professions[scope.row.profession].color,
              }),attrs:{"circle":"","size":"mini"}},[(_vm.taskIcons[scope.row.type])?_c('font-awesome-icon',{staticStyle:{"width":"1.4em !important","font":"1.4em !important","height":"1.4em !important"},attrs:{"icon":_vm.taskIcons[scope.row.type]}}):_c('div',{staticStyle:{"width":"1.4em !important","font":"1.4em !important","height":"1.4em !important"}})],1)],1)]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"clean_date_time","label":"Data e Ora","sortable":"","align":"center","width":"150"}}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"full_patient","label":"Ospite"}}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"description","label":"Descrizione"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{domProps:{"innerHTML":_vm._s(scope.row.description)}})]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Esito","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              scope.row.enabled === 1 && scope.row.verification_result === 0
            )?_c('arki-check-dialog',{staticClass:"float-right margin-left",attrs:{"buttonType":"success","icon":"check","title":("Conferma: " + (scope.row.description)),"action":("tasks/" + (scope.row.id)),"isDeleteble":scope.row.enabled === 1 &&
              scope.row.verification_result === 0 &&
              scope.row.creator_id == _vm.user.id,"rules":_vm.rules,"patient_id":scope.row.patient_id,"option":"tasks","method":"patch","row":scope.row},on:{"success":function($event){return _vm.update()},"update:patient_id":function($event){return _vm.$set(scope.row, "patient_id", $event)}}}):_vm._e()]}}])})],1),_vm._v(" "),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.tasks.per_page,"total":_vm.tasks.total,"hide-on-single-page":true,"current-page":_vm.current_page},on:{"current-change":_vm.changePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }