<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_bina)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

         <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_bina.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_bina.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_bina)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
     <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_bina" :test_model="test_model_bina" @showparent="show" />
      </el-row>
      <br />
      <br />


      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>

          <!-- DATA -->
          <el-row :gutter="20">
            <el-col :span="24" v-for="(category, idx) in categories" :key="idx + '-cat'">
              <el-divider content-position="center" class='text-center'>{{category.name }}</el-divider>
              <el-form-item :prop="category.result">
                <el-col :span="24" v-for="(option, idx2) in category.options" :key="idx2 + '-opt'">
                  <el-col :span="16">
                    <el-form-item :label="option.label" />
                  </el-col>
                  <el-col :span="8">
                    <el-row>
                      <el-col :offset="12">
                        <el-form-item>
                          <el-radio
                            :label="option.points"
                            v-model="test_bina[category.result]"
                            border
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-col>
              </el-form-item>
              <el-col :span="24" v-show="test_bina[category.result] == 100 && category.result == 'q2_result'">
                <el-form-item size="mini">
                    <div style="padding-left: 35px;">
                        <div>
                            <el-checkbox
                            v-model="test_bina.sub_result_of_q2"
                            label="Patologie"
                            style="display: flex; align-items: center;"
                             >A) anziani con patologie croniche in labile compenso con elevata necessità di tutela sanitaria <br> (come ad esempio: cure mediche e/o infermieristiche quotidiane, trattamenti di recupero funzionale, <br> somministrazione di terapie e. v., etc.)</el-checkbox>
                        </div>
                        <div>
                            <el-checkbox
                            v-model="test_bina.sub_result_of_q2"
                            label="Trattamenti"
                            >B) trattamenti specialistici</el-checkbox>
                        </div>
                    </div>
                </el-form-item>
                <el-col :span="24" >
                    <el-form-item size="mini">
                        <div style="padding-left: 35px;">
                            <div>
                                <el-checkbox
                                v-model="test_bina.sub_result_of_q2_3"
                                label="Alimentazione"
                                style="display: flex; align-items: center;"
                                >Alimentazione parentale o idratazione mediante catetere venoso centrale (CVC).<br>Alimentazione parentale o idratazione per periodi superiori a <br> 15 giorni mediante catetere venoso periferico (CVP)</el-checkbox>
                            </div>
                            <div>
                                <el-checkbox
                                v-model="test_bina.sub_result_of_q2_3"
                                label="SNG/PEG"
                                >SNG/PEG</el-checkbox>
                            </div>
                            <div>
                                <el-checkbox
                                v-model="test_bina.sub_result_of_q2_3"
                                label="Tracheostomia"
                                >Tracheostomia</el-checkbox>
                            </div>
                            <div>
                                <el-checkbox
                                v-model="test_bina.sub_result_of_q2_3"
                                label="Respiratore"
                                >Respiratore/ventilazione assistita</el-checkbox>
                            </div>
                            <div>
                                <el-checkbox
                                v-model="test_bina.sub_result_of_q2_3"
                                label="Ossigenoterapia"
                                >Ossigenoterapia ad intervalli definiti o continuativi, non in acuzie</el-checkbox>
                            </div>
                            <div>
                                <el-checkbox
                                v-model="test_bina.sub_result_of_q2_3"
                                label="Dialisi"
                                >Dialisi</el-checkbox>
                            </div>
                            <div>
                                <el-checkbox
                                v-model="test_bina.sub_result_of_q2_3"
                                label="Trasfusioni"
                                >Trasfusioni eseguite in struttura</el-checkbox>
                            </div>
                            <div>
                                <el-checkbox
                                v-model="test_bina.sub_result_of_q2_3"
                                label="Dolore"
                                >Controllo dolore per via parenterale o sottocutanea tramite infusori elastomerici o pompe</el-checkbox>
                            </div>
                            <div>
                                <el-checkbox
                                v-model="test_bina.sub_result_of_q2_3"
                                label="Altri"
                                >Altri trattamenti specialistici</el-checkbox>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
              </el-col>
              <el-col :span="24" v-show="test_bina[category.result] == 100 && category.result == 'q9_result'">
                    <el-form-item size="mini">
                        <div style="padding-left: 35px;">
                            <div>
                                <el-checkbox
                                v-model="test_bina.sub_result_of_q9"
                                label="Non in grado"
                                 >Non è in grado di svolgere</el-checkbox>
                            </div>
                            <div>
                                <el-checkbox
                                v-model="test_bina.sub_result_of_q9"
                                label="Rifiuta"
                                >Si rifiuta di svolgere</el-checkbox>
                            </div>
                        </div>
                    </el-form-item>
              </el-col>
            </el-col>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_bina.total_string}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import { BINA } from "@/js/pages/Tests/constants";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "bina",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_bina: this.test,
      test_prev_bina: this.test_prev,
      test_next_bina: this.test_next,
      test_name_bina: this.test_name,
      test_model_bina: this.test_model,
      test_type_bina: this.test_type,
      patient_id_bina: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
      categories: BINA.categories
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_bina,
        test_model: this.test_model_bina,
        test_id: test_id,
        type_id: this.test_type_bina
      };

      const response = await Test.show(params);
      this.test_bina = response.data.test;
      this.test_name_bina = response.data.test_name;
      this.test_prev_bina = response.data.test_prev;
      this.test_next_bina = response.data.test_next;
      if(this.test_bina.sub_result_of_q2 !== null){
          let sub_q2 = this.test_bina.sub_result_of_q2.split(',');
          this.test_bina.sub_result_of_q2 = sub_q2;
      }
      if(this.test_bina.sub_result_of_q2_3 !== null){
          let sub_q2_3 = this.test_bina.sub_result_of_q2_3.split(',');
          this.test_bina.sub_result_of_q2_3 = sub_q2_3;
      }
      if(this.test_bina.sub_result_of_q9 !== null){
          let sub_q9 = this.test_bina.sub_result_of_q9.split(',');
          this.test_bina.sub_result_of_q9 = sub_q9;
      }
      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_bina === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_bina === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
    this.show(this.test.id);
  }
};
</script>
<style scoped>

.text-center {
    text-align: center;
}
</style>
