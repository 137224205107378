<script>
import CdiPatientsTable from './CdiPatientsTable.vue';
import CdiPatientsToolbar from './CdiPatientsToolbar.vue';
import SosiaCdiService from '@/js/api/services/SosiaCdi';
import ArkFile from '@/js/helpers/file';
import CdiPatientsExportModal from './CdiPatientsExportModal.vue';

export default {
  name: 'CdiPatients',
  components: { CdiPatientsToolbar, CdiPatientsTable, CdiPatientsExportModal },
  data() {
    return {
      loading: true,
      search: '',
      params: {},
      patients: [],
      field: null,
      form: {},
      period: {},
      exportLoading: false
    };
  },
  computed: {
    structureId() {
      return this.$store.state.structureId;
    },
    dataTable() {
      const q = this.search ? this.search.trim().toLowerCase() : null;
      if (!q || !this.field) return this.patients;
      return this.patients.filter((p) => {
        if (this.field == 'nome') {
          return _.get(p, 'full_name', '').toLowerCase().includes(q);
        } else if (this.field == 'codiceFiscale') {
          return p.cf && p.cf.toLowerCase().includes(q);
        }
      });
    }
  },
  methods: {
    fetchPatients() {
      this.loading = true;
      const params = {
        ...this.params,
        ...this.period,
        structureId: this.structureId
      };
      SosiaCdiService.patients(params)
        .then(({ data }) => {
          this.patients = data.patients;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changePeriod(period) {
      this.period = period;
      this.fetchPatients();
    },
    exportSosia() {
      this.loading = true;
      const params = {
        ...this.period,
        structureId: this.structureId
      };
      ArkFile.downloadFromRequest(SosiaCdiService.export(params), 'sosia_cdi.zip')
        .catch((e) => {
          if (e.response.status == 406) {
            this.$message({
              type: 'info',
              message: 'Nessun ospite ha il SOSIA per la data inserita'
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    exportPdf() {
      this.loading = true;
      const params = {
        ...this.period,
        structureId: this.structureId
      };
      ArkFile.downloadFromRequest(SosiaCdiService.exportPdf(params), 'pdf_sosia_cdi.zip').finally(() => {
        this.loading = false;
      });
    },
    doSearch({ field, value }) {
      if (!value || value.trim() == '') return;
      if (field == 'pratica') {
        this.field = field;
        this.params = {
          [field]: value.trim()
        };
        this.fetchPatients();
      } else {
        this.field = field;
        this.search = value;
      }
    },
    async clearSearch() {
      if (this.field == 'pratica') {
        this.params = {};
        this.fetchPatients();
      } else {
        this.params = {};
        this.search = '';
      }
      this.field = null;
    }
  },
  created() {
    this.fetchPatients();
  }
};
</script>

<template>
  <el-container class="container" direction="vertical">
    <el-button
      size="mini"
      class="add-button float-left export-btn"
      type="success"
      plain
      :disabled="loading"
      :loading="exportLoading"
      @click="exportPdf"
      >EXPORT PDF</el-button
    >
    <el-button
      size="mini"
      class="add-button"
      type="success"
      plain
      :disabled="loading"
      :loading="exportLoading"
      @click="exportSosia"
      >EXPORT TXT</el-button
    >
    <cdi-patients-toolbar
      @doSearch="doSearch"
      :loading="loading"
      @changePeriod="changePeriod"
      @clearSearch="clearSearch"
    />
    <el-main style="padding-top: 5px !important">
      <cdi-patients-table :list="dataTable" :loading="loading" />
    </el-main>
  </el-container>
</template>

<style scss scoped>
.container {
  height: 100%;
  margin: 5px;
}
.export-btn {
  right: 130px;
}
</style>