import ArkiBaseService from './ArkiBaseService';

export default class Note extends ArkiBaseService {

    static endpoint = 'diary';

    static needsExport(currentFilters) {
        return axios({
            url: 'pdf/noteneeds',
            method: 'GET',
            params: currentFilters,
            responseType: 'blob',
        });
    }
}
