<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button
            class="float-left"
            round
            :disabled="disablePrev"
            @click="show(test_prev_adico)"
          >
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_adico.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_adico.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_adico)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
        <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_adico" :test_model="test_model_adico" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item label="A" />
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :offset="1" :span="24">
                        <el-row>
                          <el-col>
                            <div>
                              <el-form-item size="mini">
                                <el-checkbox
                                  :checked="test_adico.q1_result > 0"
                                >Assenza o lievi disturbi cognitivi e del comportamento</el-checkbox>
                              </el-form-item>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>
            <div v-if="!aChecked">
              <el-col :span="24">
                <el-col :span="24">
                  <el-col :span="24">
                    <el-form-item>
                      <el-row>
                        <el-col :span="24">
                          <el-form-item label="B" />
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :offset="1" :span="24">
                          <el-row>
                            <el-col>
                              <div>
                                <el-form-item size="mini">
                                  <el-checkbox
                                    :checked="test_adico.q2_result > 0"
                                  >1. Aggressività verbale</el-checkbox>
                                </el-form-item>
                                <el-form-item size="mini">
                                  <el-checkbox
                                    :checked="test_adico.q3_result > 0"
                                  >2. Pone domande ripetitivamente</el-checkbox>
                                </el-form-item>
                                <el-form-item size="mini">
                                  <el-checkbox
                                    :checked="test_adico.q4_result > 0"
                                  >3. Si sente ansioso, agitato, preoccupato, costantemente inquieto</el-checkbox>
                                </el-form-item>
                                <el-form-item size="mini">
                                  <el-checkbox
                                    :checked="test_adico.q5_result > 0"
                                  >4. Allucinazioni/deliri</el-checkbox>
                                </el-form-item>
                              </div>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-form-item>
                    <el-divider />
                  </el-col>
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-col :span="24">
                    <el-form-item>
                      <el-row>
                        <el-col :span="24">
                          <el-form-item label="C" />
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :offset="1" :span="24">
                          <el-row>
                            <el-col>
                              <div>
                                <el-form-item size="mini">
                                  <el-checkbox
                                    :checked="test_adico.q6_result > 0"
                                  >5.Attività motoria afinalistica (wandering, fughe)</el-checkbox>
                                </el-form-item>
                                <el-form-item size="mini">
                                  <el-checkbox
                                    :checked="test_adico.q7_result > 0"
                                  >6. Aggressività fisica</el-checkbox>
                                </el-form-item>
                                <el-form-item size="mini">
                                  <el-checkbox
                                    :checked="test_adico.q8_result > 0"
                                  >7. Comportamento socialmente inadeguato/disinibizione</el-checkbox>
                                </el-form-item>
                                <el-form-item size="mini">
                                  <el-checkbox
                                    :checked="test_adico.q9_result > 0"
                                  >8.Alterazione severa del ritmo sonno veglia/insonnia grave</el-checkbox>
                                </el-form-item>
                                <el-form-item size="mini">
                                  <el-checkbox
                                    :checked="test_adico.q10_result > 0"
                                  >9. Rifiuto dell’assistenza</el-checkbox>
                                </el-form-item>
                                <el-form-item size="mini">
                                  <el-checkbox
                                    :checked="test_adico.q11_result > 0"
                                  >10. Comportamento alimentare gravemente alterato</el-checkbox>
                                </el-form-item>
                              </div>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-form-item>
                  </el-col>
                </el-col>
              </el-col>
            </div>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_adico.total_string}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Adico",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_adico: this.test,
      test_prev_adico: this.test_prev,
      test_next_adico: this.test_next,
      test_name_adico: this.test_name,
      test_model_adico: this.test_model,
      test_type_adico: this.test_type,
      patient_id_adico: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_adico,
        test_model: this.test_model_adico,
        test_id: test_id,
        type_id: this.test_type_adico
      };

      const response = await Test.show(params);
      this.test_adico = response.data.test;
      this.test_name_adico = response.data.test_name;
      this.test_prev_adico = response.data.test_prev;
      this.test_next_adico = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_adico === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_adico === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  computed: {
    aChecked() {
      return this.test_adico.q1_result === 1;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
