import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class SosiaCdiService extends ArkiBaseService {

    static endpoint = 'sosiaCdi';

    static config() {
        return Api.get(`${this.endpoint}/config`);
    }

    static patients(params) {
        return Api.get(`${this.endpoint}/patients`, { params });
    }

    static findByPatient(id, params) {
        return Api.get(`${this.endpoint}/patient/${id}`, { params });
    }

    static storeAnagrafica(params){
        return Api.post(`${this.endpoint}/cdi1` , params);
    }

    static storeValutazione(params){
        return Api.post(`${this.endpoint}/cdi2` , params);
    }

    static deleteEvaluation(id){
        return Api.delete(`${this.endpoint}/cdi2/${id}`);
    }

    static deleteData(id){
        return Api.delete(`${this.endpoint}/cdi1/${id}`);
    }

    static updateEvaluation(id, payload){
        return Api.patch(`${this.endpoint}/cdi2/${id}`, payload);
    }

    static updateData(id, payload){
        return Api.patch(`${this.endpoint}/cdi1/${id}`, payload);
    }

    static lastDataRecord(id){
        return Api.get(`${this.endpoint}/patient/${id}/last-cdi1`);
    }

    static lastEvaluationRecord(id){
        return Api.get(`${this.endpoint}/patient/${id}/last-cdi2`);
    }

    static printPdf(id){
        return Api.get(`${this.endpoint}/print/${id}`, { responseType: 'blob', Accept: 'application/pdf'});
    }

    static export(params) {
        return Api.get(`${this.endpoint}/export`, { params, responseType: 'blob' })
    }

    static exportPdf(params) {
        return Api.get(`${this.endpoint}/exportPdf`, { params, responseType: 'blob' })
    }

    static generatePracticeNumber(payload) {
        return Api.post(`${this.endpoint}/generate/practice`, payload);
    }

}