<template>
    <div>
        <arki-filters ref="filters" @filtered="fetch" target="physiotherapy" :dateRangeFilter="true" :optionsFilter="false" :patientSpan="12"/>
        <router-link to="/physiotherapies/create"><el-button class="add-button" type="success" icon="el-icon-plus" round></el-button></router-link>
        <div class="block with-search-margin">
            <el-table :class="paginateTable" :data="physiotherapies.data" :default-sort = "{prop: 'time', order: 'descending'}" style="width: 100%" :max-height="maxHeight" :row-class-name="rowClass" v-loading="loading">
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-row>
                            <el-col :span="12" v-if="scope.row.orientamento">
                                <b>Orientamento:</b><br> <p>{{options_1[scope.row.orientamento]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.attenzione_concentrazione">
                                <b>Attenzione / Concentrazione:</b><br> <p>{{options_1[scope.row.attenzione_concentrazione]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.motivazione">
                                <b>Motivazione:</b><br> <p>{{options_1[scope.row.motivazione]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.collaborazione">
                                <b>Collaborazione:</b><br> <p>{{options_1[scope.row.collaborazione]}}</p>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24" v-if="scope.row.has_esame_obiettivo">
                                <h3 class="text-center">Esame Obiettivo</h3>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_esame_obiettivo_letto">
                                <h4>A Letto</h4>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.letto_testa">
                                <b>Testa:</b><br> <p>{{scope.row.letto_testa}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.letto_tronco">
                                <b>Tronco:</b><br> <p>{{scope.row.letto_tronco}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.letto_arti_inferiori">
                                <b>Arti Inferiori:</b><br> <p>{{scope.row.letto_arti_inferiori}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.letto_arti_superiori">
                                <b>Arti Superiori:</b><br> <p>{{scope.row.letto_arti_superiori}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_esame_obiettivo_seduto">
                                <h4>Da seduto</h4>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.seduto_testa">
                                <b>Testa:</b><br> <p>{{scope.row.seduto_testa}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.seduto_tronco">
                                <b>Tronco:</b><br> <p>{{scope.row.seduto_tronco}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.seduto_arti_inferiori">
                                <b>Arti Inferiori:</b><br> <p>{{scope.row.seduto_arti_inferiori}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.seduto_arti_superiori">
                                <b>Arti Superiori:</b><br> <p>{{scope.row.seduto_arti_superiori}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_esame_obiettivo_eretto">
                                <h4>In stazione eretta</h4>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.eretto_testa">
                                <b>Testa:</b><br> <p>{{scope.row.eretto_testa}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.eretto_tronco">
                                <b>Tronco:</b><br> <p>{{scope.row.eretto_tronco}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.eretto_arti_inferiori">
                                <b>Arti Inferiori:</b><br> <p>{{scope.row.eretto_arti_inferiori}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.eretto_arti_superiori">
                                <b>Arti Superiori:</b><br> <p>{{scope.row.eretto_arti_superiori}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_esame_obiettivo">
                                <h3 class="text-center">Fine Esame Obiettivo</h3>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.tono_muscolare">
                                <b>Tono Muscolare:</b><br> <p>{{scope.row.tono_muscolare}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.trofismo_muscolare">
                                <b>Trofismo Muscolare:</b><br> <p>{{scope.row.trofismo_muscolare}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.escursione_articolare">
                                <b>Escursione Articolare:</b><br> <p>{{scope.row.escursione_articolare}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.test_tinetti">
                                <b>Test Tinetti:</b><br> <p>{{scope.row.test_tinetti}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_mobilizzazione">
                                <h3 class="text-center">Mobilizzazione</h3>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.deambulazione">
                                <b>Deambulazione:</b><br> <p>{{options_3[scope.row.deambulazione]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.cammino">
                                <b>Schema del Cammino:</b><br> <p>{{scope.row.cammino}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.scale">
                                <b>Scale:</b><br> <p>{{options_4[scope.row.scale]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.coordinazione">
                                <b>Coordinazione:</b><br> <p>{{scope.row.coordinazione}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.sensibilita_propriocettiva">
                                <b>Sensibilità propriocettiva:</b><br> <p>{{options_5[scope.row.sensibilita_propriocettiva]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.sensibilita_esterocettiva">
                                <b>Sensibilità esterocettiva:</b><br> <p>{{options_5[scope.row.sensibilita_esterocettiva]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.prese_pinze_precisione">
                                <b>Prese e pinze:</b><br> <p>{{scope.row.prese_pinze_precisione}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.altri_problemi">
                                <b>Altri Problemi:</b><br> <p>{{scope.row.altri_problemi}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_spostamenti">
                                <h3 class="text-center">Autonomia negli spostamenti</h3>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.spostamenti_letto_supino_fianco">
                                <b>Supino/Fianco:</b><br> <p>{{options_4[scope.row.spostamenti_letto_supino_fianco]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.spostamenti_letto_supino_seduto">
                                <b>Supino/Seduto:</b><br> <p>{{options_4[scope.row.spostamenti_letto_supino_seduto]}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_trasferimenti">
                                <h3 class="text-center">Trasferimenti</h3>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.trasferimenti_letto_carrozzina">
                                <b>Letto/Carrozzina:</b><br> <p>{{options_4[scope.row.trasferimenti_letto_carrozzina]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.trasferimenti_seduto_eretto">
                                <b>Seduto/Eretto:</b><br> <p>{{options_4[scope.row.trasferimenti_seduto_eretto]}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.patologia_problemi">
                                <b>Altre Patologie:</b><br> <p>{{scope.row.patologia_problemi}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.ipotesi_trattamento">
                                <b>Ipotesi Trattamento:</b><br> <p>{{scope.row.ipotesi_trattamento}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.note_altro">
                                <b>Note:</b><br> <p>{{scope.row.note_altro}}</p>
                            </el-col>
                        </el-row>
                    </template>
                </el-table-column>
                <el-table-column prop="clean_date_time" label="Data e Ora" sortable width="180" align="center" ></el-table-column>
                <el-table-column prop="full_patient" label="Ospite" ></el-table-column>
                <el-table-column label="Fisioterapista" align="right" prop="full_creator" ></el-table-column>
                <el-table-column align="right">
                    <template slot-scope="scope">
                        <el-button
                        size="large"
                        type="danger"
                        plain
                        @click="destroy(scope.row.id, scope.row.full_patient)"
                        v-if="scope.row.enabled === 1 && scope.row.creator_id == user.id">
                            <i class="el-icon-delete"></i>
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                background
                layout="prev, pager, next"
                :page-size="physiotherapies.per_page"
                :total="physiotherapies.total"
                :hide-on-single-page="true"
                :current-page="currentPage"
                @current-change="changePage">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import ArkiFilters from '@/js/components/ArkiFilters';
import Physiotherapy from '@/js/api/services/Physiotherapy';

export default {
    name: 'Physiotherapy',
    components:{
        ArkiFilters
    },
    data(){
        return{
            loading: true,

            physiotherapies: {
                data: [],
                size: 1,
                total: null,
            },
            patient_id: null,
            currentPage: 1,

            options_1:['', 'Sì', 'Parziale', 'No'],
            options_2:['', 'Buona', 'Scarsa', 'Assente'],
            options_3:['', 'Tratti Lunghi', 'Tratti Brevi'],
            options_4:['', 'Sì', 'Con Aiuto', 'No'],
            options_5:['', 'Sì', 'Alterata', 'No'],

            paginateTable: null,
            // first fetch is from filter
            unchangedFilter: false,
        }
    },
    computed:{
        user(){ return this.$store.state.user },
        maxHeight() { return window.innerHeight - 235; },
    },
    watch:{
        physiotherapies: function(){
            (this.physiotherapies.total / this.physiotherapies.per_page > 1) ? this.paginateTable = 'paginate-table' : this.paginateTable = null;
        }
    },
    methods:{
        async fetch(filters){
            this.loading = true;
            // need to reset page in case filter change
            if (!this.unchangedFilter) {
                this.currentPage = 1;
            }
            let params = {
                date_from: moment(filters.dateRange[0]).format('DD/MM/YYYY'),
                date_to: moment(filters.dateRange[1]).format('DD/MM/YYYY'),
                patient_id: filters.patient_id,
                page: this.currentPage,
            }

            const response = await Physiotherapy.fetch(params);
            this.physiotherapies = response.data.physiotherapies;
            this.loading = false;
            // reset the checked flag
            this.unchangedFilter = false;
        },
        destroy(id, patient){
            this.$confirm(`Annullare Valutazione di ${patient} ?`, 'Attenzione', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Annulla',
                type: 'warning'
            }).then(() => {
                this.delete(id);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Operazione annullata'
                });
            });
        },
        async delete(id){
            await Physiotherapy.destroy(id);
            this.$refs.filters.emit();
        },
        update() {
            this.unchangedFilter = true;
            if (this.$refs.filters) {
                this.$refs.filters.emit();
            }
        },
        changePage(page){
            this.loading = true;
            this.currentPage = page;
            this.update();
        },
        rowClass({row, rowIndex}) {
            if(row.enabled == 0){
                return 'deleted-row';
            }
            return '';
        },
    }
}
</script>
