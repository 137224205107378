<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_cmai)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_cmai.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_cmai.clean_date_time_test }}</small>
          </span>
        </el-col>


        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_cmai)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_cmai" :test_model="test_model_cmai" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider content-position="center"></el-divider>

              <el-col :span="24">
                <el-col :span="6">
                  <el-form-item label="  " />
                </el-col>
                <el-col :span="18">
                  <el-row type="flex" class="row-bg" justify="center">
                    <el-col>
                      <center class="head">Mai</center>
                    </el-col>
                    <el-col>
                      <center class="head">Meno di una volta alla settimana</center>
                    </el-col>
                    <el-col>
                      <center class="head">Una o due volte alla settimana</center>
                    </el-col>
                    <el-col>
                      <center class="head">Parecchie volte alla settimana</center>
                    </el-col>

                    <el-col>
                      <center class="head">Una o due volte al giorno</center>
                    </el-col>
                    <el-col>
                      <center class="head">Parecchie volte al giorno</center>
                    </el-col>
                    <el-col>
                      <center class="head">Parecchie volte in un ora</center>
                    </el-col>
                  </el-row>
                  <el-divider content-position="center"></el-divider>
                </el-col>
              </el-col>

              <el-col :span="24" v-for="(item, idx) in comportamenti" :key="idx">
                <el-form-item :prop="`q${++idx}_result`">
                  <el-col :span="6">
                    <el-form-item :label="item" required />
                  </el-col>
                  <el-col :span="18">
                    <el-row type="flex" justify="center">
                      <el-col v-for="opt in 7" :key="opt + '-opt'">
                        <el-col>
                          <center>
                            <el-radio :label="opt" v-model="test_cmai[`q${idx}_result`]" />
                          </center>
                        </el-col>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-form-item>
                <el-divider content-position="center"></el-divider>
              </el-col>
            </el-col>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_cmai.total_string}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Cmai",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_cmai: this.test,
      test_prev_cmai: this.test_prev,
      test_next_cmai: this.test_next,
      test_name_cmai: this.test_name,
      test_model_cmai: this.test_model,
      test_type_cmai: this.test_type,
      patient_id_cmai: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
      comportamenti: [
        "Colpire (se stessi)",
        "Calciare",
        "Aggrapparsi alle persone",
        "Spingere",
        "Gettare delle cose",
        "Mordere",
        "Graffiare",
        "Sputare",
        "Ferisce se stesso o altri",
        "Rompere/Distruggere cose proprie",
        "Fare avance fisiche sessuali",
        "Vagare senza meta",
        "Abiti inappropriati o spogliarsi",
        "Cercare di andare in posti diversi",
        "Cadere intenzionalmente",
        "Mangiare/Bere sostanze inappropriate",
        "Gestire cose in modo inappropriato",
        "Nascondere cose",
        "Accumulare delle cose",
        "Eseguire manierismi ripetitivi",
        "Irrequietezza generale",
        "Urlare",
        "Fare advance verbali sessuali",
        "Bestemmiare/Aggressivitá verbale",
        "Frasi e domande ripetitive",
        "Strani rumori (risate o pianti)",
        "Lamentarsi",
        "Negativismo",
        "Costante richiesta di aiuto o attenzione"
      ]
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_cmai,
        test_model: this.test_model_cmai,
        test_id: test_id,
        type_id: this.test_type_cmai
      };

      const response = await Test.show(params);
      this.test_cmai = response.data.test;
      this.test_name_cmai = response.data.test_name;
      this.test_prev_cmai = response.data.test_prev;
      this.test_next_cmai = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_cmai === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_cmai === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
