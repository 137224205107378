<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <!-- 1 -->
        <el-col :span="24">
          <el-col :span="16">1. È fondamentalmente soddisfatto della sua vita?</el-col>
          <el-col :span="8">
            <el-form-item prop="q1_result">
              <el-radio-group v-model="result.q1_result">
                <el-radio :label="0" border>Si</el-radio>
                <el-radio :label="1" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 2 -->
        <el-col :span="24">
          <el-col :span="16">2. Si annoia spesso?</el-col>
          <el-col :span="8">
            <el-form-item prop="q2_result">
              <el-radio-group v-model="result.q2_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 3 -->
        <el-col :span="24">
          <el-col :span="16">3. Si sente spesso abbandonato?</el-col>
          <el-col :span="8">
            <el-form-item prop="q3_result">
              <el-radio-group v-model="result.q3_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 4 -->
        <el-col :span="24">
          <el-col :span="16">4. Preferisce stare a casa anzichè uscire a fare cose nuove?</el-col>
          <el-col :span="8">
            <el-form-item prop="q4_result">
              <el-radio-group v-model="result.q4_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <!-- 5 -->
        <el-col :span="24">
          <el-col :span="16">5. Si sente un po' inutile così com'è oggi?</el-col>
          <el-col :span="8">
            <el-form-item prop="q5_result">
              <el-radio-group v-model="result.q5_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Gravitá della depressione">
              <el-input v-model="totalDescription" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template>
<script>
import TestActions from "../components/TestActions";

export default {
  name: "Braden",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 5
      },
      result: {},
      testRules: {
        q1_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q2_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q3_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q4_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q5_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ]
      }
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_description: this.totalDescription
          };
          this.$emit("submitTest", this.form, result);
        } else {
          return false;
        }
      });
    },
    filterResults() {
      return _.pickBy(this.result, (_, key) => key.endsWith("result"));
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
    totalDescription() {
      return _.cond([
        [
          _.conforms({ total: n => _.inRange(n, 0, 2) }),
          _.constant("Normale")
        ],
        [
          _.conforms({ total: n => _.gte(n, 2) }),
          _.constant("Depressione")
        ]
      ])(this.form);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(this.filterResults());
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.bottom-space {
  margin-bottom: 50px;
}
</style>
