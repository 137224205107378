<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q1_result">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="Apertura degli occhi" required />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="24">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q1_result">
                            <el-form-item size="mini">
                              <el-radio :label="4">Apre gli occhi spontaneamente</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="3">Apre gli occhi alla chiamata</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="2">Apre gli occhi allo stimolo doloroso</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="1">Non apre gli occhi</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q2_result">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="Risposta verbale" required />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="24">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q2_result">
                            <el-form-item size="mini">
                              <el-radio :label="5">Risponde in modo appropriato</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="4">Risponde in modo confuso</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="3">Risponde con parole senza senso</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="2">Emette solo suoni inarticolati</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="1">Non risponde</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q3_result">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="Risposta motoria" required />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="24">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q3_result">
                            <el-form-item size="mini">
                              <el-radio :label="6">Esegue ordini semplici</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="5">Localizza uno stimolo doloroso</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="4">Rifugge dallo stimolo doloroso (retrae)</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="3">Flette gli arti allo stimolo doloroso</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="2">Estende gli arti allo stimolo doloroso</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="1">Non reagisce</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Risultato">
              <el-input v-model="totalDescription" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Glasgow",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 15
      },
      result: {},
      testRules: rules(3)
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_description: this.totalDescription
          };
          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    totalDescription() {
      return _.cond([
        [_.conforms({ total: n => _.lt(n, 8) }), _.constant("Traumi severi")],
        [
          _.conforms({ total: n => _.inRange(n, 8, 12) }),
          _.constant("Traumi maggiori")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 12, 16) }),
          _.constant("Paziente normale")
        ]
      ])(this.form);
    },
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.radio-label {
  white-space: normal;
}
.radio-label_text {
  margin-left: 20px;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>