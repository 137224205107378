<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_adl)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_adl.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_adl.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button class="float-right" round :disabled="disableNext" @click="show(test_next_adl)">
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>

      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_adl" :test_model="test_model_adl" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q1_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Alimentazione" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q1_result">
                          <el-form-item>
                            <el-radio :label="0">deve essere alimentato</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="5">ha bisogno di aiuto</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="10">indipendente</el-radio>
                          </el-form-item>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q2_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Bagno" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q2_result">
                          <el-form-item>
                            <el-radio :label="0">possibile solo se aiutato</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="5">possibile senza aiuto</el-radio>
                          </el-form-item>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q3_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Abbigliamento" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q3_result">
                          <el-form-item>
                            <el-radio :label="0">completamente dipendente</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="5">solo con aiuto</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="10">indipendente</el-radio>
                          </el-form-item>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q4_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Controllo intestino" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q4_result">
                          <el-form-item>
                            <el-radio :label="0">evacuazione involontaria</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio
                              :label="5"
                            >occasionalmente evacuazione involontaria, ha bisogno di aiuto</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="10">autonomo</el-radio>
                          </el-form-item>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q5_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Controllo vescica" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q5_result">
                          <el-form-item>
                            <el-radio :label="0">incontinenza o catetere a permanenza</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio
                              :label="5"
                            >occasionalmente minzione involontaria, ha bisogno di aiuto</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="10">autonomo</el-radio>
                          </el-form-item>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q6_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Utilizzo della toilette" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q6_result">
                          <el-form-item>
                            <el-radio :label="0">allettato, completamente dipendente</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="5">richiede aiuto</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="10">autonomo</el-radio>
                          </el-form-item>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q7_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Trasferimento sedia – letto" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q7_result">
                          <el-form-item>
                            <el-radio :label="0">allettato</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="5">forte assistenza</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="10">assistenza minima</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="15">autonomo</el-radio>
                          </el-form-item>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q8_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Deambulazione in piano" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q8_result">
                          <el-form-item>
                            <el-radio
                              :label="0"
                            >non riesce a muoversi autonomamente con la sedia a rotelle</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="5">riesce a muoversi con la sedia a rotelle</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="10">può fare 50 passi solo con aiuto</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="15">può fare 50 passi anche senza aiuto</el-radio>
                          </el-form-item>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q9_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Salire le scale" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q9_result">
                          <el-form-item>
                            <el-radio :label="0">impossibile</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="5">possibile con aiuto</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="10">autonomo</el-radio>
                          </el-form-item>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-divider content-position="center">Reazioni psicologiche ed emotive all’ingresso</el-divider>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q10_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Accettazione" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q10_result" class="radio-group">
                            <el-row type="flex" justify="center">
                          <el-form-item>
                            <el-radio :label="0">si</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="5">no</el-radio>
                          </el-form-item>
                            </el-row>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q11_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Sopportazione" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q11_result" class="radio-group">
                            <el-row type="flex" justify="center">
                          <el-form-item>
                            <el-radio :label="0">si</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="5">no</el-radio>
                          </el-form-item>
                            </el-row>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q12_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Indifferenza" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q12_result" class="radio-group">
                            <el-row type="flex" justify="center">
                          <el-form-item>
                            <el-radio :label="0">si</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="5">no</el-radio>
                          </el-form-item>
                            </el-row>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-divider content-position="center">Problemi associati all’ingresso</el-divider>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q13_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Vigilanza" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q13_result" class="radio-group">
                            <el-row type="flex" justify="center">
                          <el-form-item>
                            <el-radio :label="1">si</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="0">no</el-radio>
                          </el-form-item>
                            </el-row>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q14_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Attenzione" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q14_result" class="radio-group">
                            <el-row type="flex" justify="center">
                          <el-form-item>
                            <el-radio :label="1">si</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="0">no</el-radio>
                          </el-form-item>
                            </el-row>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q15_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Memoria BT" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q15_result" class="radio-group">
                            <el-row type="flex" justify="center">
                          <el-form-item>
                            <el-radio :label="1">si</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="0">no</el-radio>
                          </el-form-item>
                            </el-row>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q16_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Memoria LT" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q16_result" class="radio-group">
                            <el-row type="flex" justify="center">
                          <el-form-item>
                            <el-radio :label="1">si</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="0">no</el-radio>
                          </el-form-item>
                            </el-row>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q17_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Comprensione" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q17_result" class="radio-group">
                            <el-row type="flex" justify="center">
                          <el-form-item>
                            <el-radio :label="1">si</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="0">no</el-radio>
                          </el-form-item>
                            </el-row>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q18_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Aprassia" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q18_result" class="radio-group">
                            <el-row type="flex" justify="center">
                          <el-form-item>
                            <el-radio :label="1">si</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="0">no</el-radio>
                          </el-form-item>
                            </el-row>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q19_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Anosognosia" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q19_result" class="radio-group">
                            <el-row type="flex" justify="center">
                          <el-form-item>
                            <el-radio :label="1">si</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="0">no</el-radio>
                          </el-form-item>
                            </el-row>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q20_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Linguaggio" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q20_result" class="radio-group">
                            <el-row type="flex" justify="center">
                          <el-form-item>
                            <el-radio :label="1">si</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="0">no</el-radio>
                          </el-form-item>
                            </el-row>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q21_orientation_result">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Orientamento T/S/P" required />
                      </el-col>
                      <el-col :span="16">
                        <el-radio-group v-model="test_adl.q21_orientation_result" class="radio-group">
                            <el-row type="flex" justify="center">
                          <el-form-item>
                            <el-radio :label="0">T</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="1">S</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="2">P</el-radio>
                          </el-form-item>
                            </el-row>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q22_mood">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Umore" required />
                      </el-col>
                      <el-col :span="16">
                        <el-form-item>
                          <el-input v-model="test_adl.q22_mood" type="textarea" />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q23_other">
                    <el-row>
                      <el-col :span="8">
                        <el-form-item label="Altro" required />
                      </el-col>
                      <el-col :span="16">
                        <el-form-item>
                          <el-input v-model="test_adl.q23_other" type="textarea" />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_adl.total_string}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "adl",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_adl: this.test,
      test_prev_adl: this.test_prev,
      test_next_adl: this.test_next,
      test_name_adl: this.test_name,
      test_model_adl: this.test_model,
      test_type_adl: this.test_type,
      patient_id_adl: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_adl,
        test_model: this.test_model_adl,
        test_id: test_id,
        type_id: this.test_type_adl
      };

      const response = await Test.show(params);
      this.test_adl = response.data.test;
      this.test_name_adl = response.data.test_name;
      this.test_prev_adl = response.data.test_prev;
      this.test_next_adl = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_adl === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_adl === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>

<style scoped>
.radio-group {
  width: 100%;
}
</style>
