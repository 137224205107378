import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Meal extends ArkiBaseService {

    static endpoint = 'meals';

    static departmentMeals(departmentId) {
        return Api.get(`${this.endpoint}/department/${departmentId}`);
    }
}