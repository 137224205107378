<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-divider content-position="center">Ashworth scale</el-divider>
            <el-col :span="24">
              <el-form-item prop="q1_result">
                <el-radio-group v-model="result.q1_result">
                  <el-col :span="24">
                    <el-col :span="20">
                      <el-form-item label="Nessun aumento del tono muscolare" label-width="20px" />
                    </el-col>
                    <el-col :span="4">
                      <el-form-item>
                        <el-radio :label="0" v-model="result.q1_result" border></el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>

                  <el-col :span="24">
                    <el-col :span="20">
                      <el-form-item
                        label="Leggero aumento del tono muscolare eni movimenti di flessione ed estensione passiva dell’arto"
                        label-width="20px"
                      />
                    </el-col>
                    <el-col :span="4">
                      <el-form-item>
                        <el-radio :label="1" v-model="result.q1_result" border></el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>

                  <el-col :span="24">
                    <el-col :span="20">
                      <el-form-item
                        label="Marcato aumento del tono muscolare che comunque non impedisce la flessione/estensione passiva dell’arto"
                        label-width="20px"
                      />
                    </el-col>
                    <el-col :span="4">
                      <el-form-item>
                        <el-radio :label="2" v-model="result.q1_result" border></el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>

                  <el-col :span="24">
                    <el-col :span="20">
                      <el-form-item
                        label="Considerevole aumento del tono muscolare, tale da ostacolare i movimenti passivi"
                        label-width="20px"
                      />
                    </el-col>
                    <el-col :span="4">
                      <el-form-item>
                        <el-radio :label="3" v-model="result.q1_result" border></el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>

                  <el-col :span="24">
                    <el-col :span="20">
                      <el-form-item
                        label="Arto rigido in flessione o estensione, impossibile la mobilizzazione passiva"
                        label-width="20px"
                      />
                    </el-col>
                    <el-col :span="4">
                      <el-form-item>
                        <el-radio :label="4" v-model="result.q1_result" border></el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Risultato">
              <el-input v-model="totalDescription" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Ashworth",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 4
      },
      result: {},
      testRules: rules(1)
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_description: this.totalDescription
          };
          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    totalDescription() {
      return _.cond([
        [_.matches({ total: 0 }), _.constant("Grado 0")],
        [_.matches({ total: 1 }), _.constant("Grado 1")],
        [_.matches({ total: 2 }), _.constant("Grado 2")],
        [_.matches({ total: 3 }), _.constant("Grado 3")],
        [_.matches({ total: 4 }), _.constant("Grado 4")]
      ])(this.form);
    },
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.radio-label {
  white-space: normal;
}
.radio-label_text {
  margin-left: 20px;
}
</style>