<template>
    <div>
        <md-dialog :md-active.sync="showDialog">
            <md-dialog-title>{{ dialogTitle }}</md-dialog-title>
            <el-form label-position="right" label-width="80px" :model="form" :rules="confirm_rules" ref="form">
                <el-row :gutter="20">
                    <el-col :span="24" v-if="hasUpload">
                        <el-form-item prop="file">
                            <el-upload class="upload-demo" ref="uploader" :action="uploadAction" accept="application/pdf" :auto-upload="false" :before-upload="beforeUpload" :on-change="handleFileChange" :limit="1" :with-credentials="true" :headers="uploadHeaders">
                                <el-button slot="trigger" type="primary">Seleziona File</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Data" prop="date">
                            <el-date-picker v-model="form.date" type="date" placeholder="Scegli data" :editable="false" :picker-options="{
            firstDayOfWeek: 1,
            disabledDate(time) {
                return time.getTime() > Date.now();
            },
        }" format="dd/MM/yyyy" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Orario" prop="time">
                            <el-time-picker v-model="form.time" :editable="false" format="HH:mm" value-format="HH:mm:ss" :picker-options="{
            selectableRange: selectableTime,
        }" placeholder="Seleziona Orario">
                            </el-time-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="Note" prop="verification_notes">
                            <el-input type="textarea" :rows="2" placeholder="" v-model="form.verification_notes">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <md-dialog-actions>
                <el-button type="info" plain @click="showDialog = !showDialog">Annulla</el-button>
                <el-button style="margin-left: 10px" plain type="success" @click="store('form')" :loading="exitLoading">Conferma</el-button>
            </md-dialog-actions>
        </md-dialog>

        <arki-create-dialog title="Assenza" dateOptions="future" :rules="rules" :options="absence_type" action="/absences" @submit="update" />
        <arki-broadcast ref="broadcast" listen=".absence.created" @update="update" />
        <arki-filters ref="filters" @filtered="fetch" target="absences" :dateMonthRangeFilter="true" :optionsFilter="true" />
        <div class="block with-filters">
            <el-table :class="paginateTable" :data="absences.data" :default-sort="{ prop: 'date_time', order: 'ascending' }" style="width: 100%" :row-class-name="rowClass" v-loading="loading" :max-height="maxHeight">
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-col :span="24">
                            <b>Inserimento:</b> {{ scope.row.full_creator }} -
                            {{ scope.row.created_at_time }}
                        </el-col>
                        <el-col :span="24" v-if="scope.row.description">
                            <b>Info:</b> {{ scope.row.description }}
                        </el-col>
                        <el-col :span="24" v-if="scope.row.confirmation == 1">
                            <b>Verifica Uscita:</b>
                            {{ scope.row.full_verification_operator }} -
                            {{ scope.row.confirmed_date_time }}
                        </el-col>
                        <el-col :span="24" v-if="scope.row.confirmation_notes">
                            <b>Note Uscita:</b> {{ scope.row.confirmation_notes }}
                        </el-col>
                        <el-col :span="24" v-if="scope.row.verification_result">
                            <b>Verifica Rientro:</b> {{ scope.row.full_uploader }} -
                            {{ scope.row.clean_verification_date_time }}
                        </el-col>
                        <el-col :span="24" v-if="scope.row.verification_notes">
                            <b>Note Rientro:</b> {{ scope.row.verification_notes }}
                        </el-col>
                    </template>
                </el-table-column>
                <el-table-column prop="date_time" label="Data" width="200" sortable>
                    <template slot-scope="scope">
                        Uscita:
                        <b v-if="scope.row.confirmed_date_time" class="success-text">{{
            scope.row.confirmed_date_time
        }}</b>
                        <b v-else>{{ scope.row.clean_date_time }}</b><br />
                        Rientro:
                        <b v-if="scope.row.verification_result != 0" class="success-text">{{
            scope.row.clean_verification_date_time
        }}</b>
                        <b v-else>{{ scope.row.clean_return_date_time }}</b>
                    </template>
                </el-table-column>
                <el-table-column prop="full_patient" label="Ospite" width="230"></el-table-column>
                <el-table-column prop="type_description" label="Tipologia" min-width="170"></el-table-column>
                <el-table-column min-width="150">
                    <template slot-scope="scope">
                        <div v-if="scope.row.enabled">
                            <b v-if="scope.row.confirmation != 1">Conferma l'uscita !!</b>
                            <br />
                            <el-button-group>
                                <el-tooltip class="item" effect="light" content="Scarica modulo uscita" placement="bottom" v-if="scope.row.type == 1 || scope.row.type == 4">
                                    <el-button size="small" :type="uploadButtonType(scope.row.has_file)" icon="el-icon-download" :loading="downloadLoading" @click="
            preDownload(
                scope.row.id,
                scope.row.full_patient,
                scope.row.date_time,
                scope.row.has_file
            )
            "></el-button>
                                </el-tooltip>
                                <el-tooltip class="item" effect="light" content="Conferma Uscita" placement="bottom" v-if="scope.row.verification_result != 1">
                                    <el-button size="small" :type="uploadButtonType(scope.row.has_file)" icon="el-icon-upload2" @click="
            preUpload(
                scope.row.id,
                scope.row.full_patient,
                scope.row.type
            )
            " v-if="scope.row.confirmation != 1"></el-button>
                                </el-tooltip>
                                <el-tooltip class="item" effect="light" content="Conferma Rientro" placement="bottom" v-if="scope.row.confirmation && scope.row.verification_result != 1
            ">
                                    <el-button size="small" :type="uploadButtonType(scope.row.verification_result)" icon="el-icon-circle-check" @click="openReturn(scope.row.id, scope.row.full_patient)"></el-button>
                                </el-tooltip>
                                <el-button size="small" type="danger" icon="el-icon-delete" @click="destroy(scope.row.id, scope.row.full_patient)" v-if="!scope.row.has_file && !scope.row.confirmation" :loading="destroyLoading"></el-button>
                            </el-button-group>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="paginate" background layout="prev, pager, next" :page-size="absences.per_page" :total="absences.total" :hide-on-single-page="true" @current-change="changePage" :disabled="!pagination">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import moment from "moment";

import Absence from "@/js/api/services/Absence";
import ArkiFilters from "@/js/components/ArkiFilters";
import ArkiCreateDialog from "@/js/components/ArkiCreateDialog";
import ArkiBroadcast from "@/js/components/ArkiBroadcast";

export default {
    name: "Absences",
    components: {
        ArkiFilters,
        ArkiCreateDialog,
        ArkiBroadcast,
    },
    data() {
        return {
            loading: true,
            downloadLoading: false,
            exitLoading: false,
            destroyLoading: false,

            absences: {
                data: [],
                per_page: 1,
                total: null,
            },
            pagination: true,
            current_page: 1,
            absence_type: [],
            rules: {
                time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
                date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
                return_time: [
                    { required: true, message: "Inserire Orario", trigger: "blur" },
                ],
                return_date: [
                    { required: true, message: "Inserire Data", trigger: "blur" },
                ],
                patient_id: [
                    { required: true, message: "Campo Obbligatorio", trigger: "blur" },
                ],
                option: [
                    { required: true, message: "Campo Obbligatorio", trigger: "blur" },
                ],
            },

            form: {
                date: null,
                time: null,
            },
            confirm_rules: {
                file: [
                    { required: true, message: "Campo Obbligatorio", trigger: "blur" },
                ],
                date: [
                    { required: true, message: "Campo Obbligatorio", trigger: "blur" },
                ],
                time: [
                    { required: true, message: "Campo Obbligatorio", trigger: "blur" },
                ],
            },
            showDialog: false,
            dialogTitle: "",
            uploadAction: "",
            hasUpload: false,
            absenceId: null,

            isReturn: false,

            uploadHeaders: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
                Authorization: `Bearer ${localStorage.getItem("arkicare_session")}`,
            },

            paginateTable: null,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        patients() {
            return this.$store.state.patients;
        },
        selectableTime() {
            var endTime = new moment();
            var startTime = moment(endTime).add(-4, "hours").format("HH:mm:ss");
            return startTime + " - " + endTime.format("HH:mm:ss");
        },
        maxHeight() {
            return window.innerHeight - 235;
        },
    },
    watch: {
        absences: function () {
            this.absences.total / this.absences.per_page > 1
                ? (this.paginateTable = "paginate-table")
                : (this.paginateTable = null);
        },
    },
    methods: {
        async init() {
            const response = await Absence.config();
            this.absence_type = response.data;
        },
        async fetch(filters) {
            this.loading = true;
            this.currentFilters = filters;
            let params = {
                date_from: moment(filters.dateRange[0]).format("DD/MM/YYYY"),
                date_to: moment(filters.dateRange[1]).format("DD/MM/YYYY"),
                type: filters.option,
                patient_id: filters.patient_id,
                page: this.current_page,
            };
            const response = await Absence.fetch(params);
            this.absences.data = response.data.absences.data;
            this.loading = false;
        },
        update() {
            if (this.$refs.filters) {
                this.$refs.filters.emit();
                this.$refs.broadcast.reset();
            }
        },
        changePage(page) {
            this.loading = true;
            this.current_page = page;
            this.$refs.filters.emit();
        },
        store(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.exitLoading = true;
                    if (!this.isReturn) {
                        this.exit(this.absenceId, this.form);
                    } else {
                        this.return(this.absenceId, this.form);
                    }
                } else {
                    return false;
                }
            });
        },
        async exit(id, form) {
            await Absence.exit(id, form)
                .then(() => {
                    if (this.hasUpload) this.upload();
                    this.success();
                })
                .catch(() => {
                    this.error();
                });
            //   if (this.hasUpload) this.upload();
            //   if (this.$store.state.success) this.success();
            //   else this.error();
        },
        upload() {
            this.$refs.uploader.submit();
            this.confirm_rules.file[0].required = true;
        },
        async return(id, form) {
            await Absence.return(id, form)
                .then(() => {
                    this.success();
                })
                .catch(() => {
                    this.error();
                });
            //   if (this.$store.state.success) this.success();
            //   else this.error();
        },
        preDownload(id, full_patient, date, has_file) {
            this.downloadLoading = true;
            if (has_file) {
                this.download(id, date, full_patient);
            } else {
                this.pdf(id, date, full_patient);
            }
        },
        async download(id, date, full_patient) {
            const response = await Absence.download(id);
            let title = `A99_${date}_${full_patient}.pdf`;
            this.file(response, title);
        },
        async pdf(id, date, full_patient) {
            const response = await Absence.pdf(id);
            let title = `A99_PROVVISORIO_${date}_${full_patient}.pdf`;
            this.file(response, title);
        },
        file(response, title) {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", title);
            document.body.appendChild(fileLink);
            fileLink.click();

            this.downloadLoading = false;
        },

        destroy(id, full_name) {
            this.$confirm(`Rimuovere assenza di '${full_name}' ?`, "Attenzione", {
                confirmButtonText: "Sì",
                cancelButtonText: "Annulla",
                type: "warning",
            })
                .then(() => {
                    this.delete(id);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "Operazione annullata",
                    });
                });
        },
        async delete(id) {
            this.destroyLoading = true;
            await Absence.destroy(id);
            if (this.$store.state.success) {
                this.success();
            }
            else {
                this.error();
            }
            this.destroyLoading = false;
        },

        preUpload(id, full_name, type) {
            this.isReturn = false;
            this.uploadAction = `api/absences/${id}/upload`;
            this.hasUpload = type == 1 || type == 4;
            this.showDialog = true;
            this.dialogTitle = `Conferma Uscita di ${full_name}`;
            this.absenceId = id;
            this.form = {};
        },
        beforeUpload(file) {
            const isPDF = file.type === "application/pdf";
            if (!isPDF) {
                this.$message.error("Il formato del file non è PDF");
            }
        },

        handleFileChange(file) {
            if (file) {
                this.confirm_rules.file[0].required = false;
            }
        },

        tableRowClassName({ row, rowIndex }) {
            if (row.enabled == 0) {
                return "deleted-row";
            }
        },
        uploadButtonType(file) {
            if (file) {
                return "success";
            }
            return "info";
        },
        openReturn(id, full_name) {
            this.isReturn = true;
            this.hasUpload = false;
            this.showDialog = true;
            this.dialogTitle = `Conferma Rientro di ${full_name}`;
            this.absenceId = id;
            this.form = {};
        },
        success() {
            this.update();
            this.exitLoading = false;
            this.showDialog = false;
            this.form = {};
        },
        error(error) {
            this.exitLoading = false;
        },
        rowClass({ row, rowIndex }) {
            if (row.enabled == 0) {
                return "deleted-row";
            } else if (row.verification_result == 1) {
                return "primary-row";
            } else if (row.verification_result == 2) {
                return "warning-row";
            } else if (row.verification_result == 3) {
                return "success-row";
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
