import FileSaver from 'file-saver';
import mime from 'mime/lite';
import { sleep } from '.';

/**
 * File utilities
 */
export default class ArkFile {

  /**
   * Download a file from an http request
   *
   * @example
   * ArkFile.download(axios.get('someEndpoint'), 'defaultFilename.txt')
   *
   * @param {Promise} request An http request
   * @param {string} fileName The default file name.
   * @param {string} type The mime type. If not provided, tries to deduce it from the fileName extension
   * @param {boolean} download If false, just return the loaded file, dont' pass it to the browser for download
   */
  static downloadFromRequest(request, fileName, type = mime.getType(fileName.split('.')[1]) ?? 'text/plain', download = true) {
    return request.then(res => {
      let contentDisposition = res.headers['content-disposition'];
      let firstTry = contentDisposition.match(/filename="(.*)"/);
      let sndTry = contentDisposition.match(/filename=(.*)/);
      const nameMatch = firstTry || sndTry;
      fileName = contentDisposition ? nameMatch[1] : fileName;
      let file = new File([res.data], fileName, { type });
      if (download) FileSaver.saveAs(file);
      return file;
    });
  }

  /**
   * Download a file with the provided content
   *
   * @example
   * ArkFile.downloadContent('someTextContent', 'defaultFilename.txt')
   *
   * @param {string} content The file content.
   * @param {string} fileName The file name.
   */
  static async downloadContent(content, fileName = 'defaultFilename.txt') {
    const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, fileName);
    await sleep(1000);
  }
}
