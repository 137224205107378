<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q1_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Condizione Fisica" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q1_result">
                            <el-form-item>
                              <el-radio :label="4">Buona</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">Discreta</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Cattiva</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Pessima</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q2_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Condizione Mentale" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q2_result">
                            <el-form-item>
                              <el-radio :label="4">Sveglio, cosciente</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">Apatico</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Confuso</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Stupore, incoscienza</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q3_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Attività Fisica" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q3_result">
                            <el-form-item>
                              <el-radio :label="4">Cammina liberamente</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">Cammina solo con qualche aiuto</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Costretto su sedia a rotelle</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Costretto a letto</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q4_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Mobilità" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q4_result">
                            <el-form-item>
                              <el-radio :label="4">Completa</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">Limitata</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Molto limitata</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Immobile</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q5_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Incontinenza" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q5_result">
                            <el-form-item>
                              <el-radio :label="4">Nessuna</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">Occasionale</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Abituale Urinaria</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Abituale Urinaria e fecale</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Norton Rating">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Risultato">
              <el-input :disabled="true" v-model="totalDescription" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Norton",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 20
      },
      result: {},
      testRules: rules(5)
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_description: this.totalDescription
          };
          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
    totalDescription() {
      let condMap = _.cond([
        [_.matches({ total: 0 }), _.constant("Rischio basso")],
        [_.conforms({ total: n => _.gt(n, 18) }), _.constant("Rischio basso")],
        [
          _.conforms({ total: n => _.inRange(n, 14, 18) }),
          _.constant("Rischio medio")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 10, 14) }),
          _.constant("Rischio alto")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 1, 10) }),
          _.constant("Rischio altissimo")
        ]
      ]);
      return condMap(this.form);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.bottom-space {
  margin-bottom: 50px;
}
</style>