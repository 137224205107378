<script>
import CdiEvaluation from './CdiEvaluation.vue';
import SosiaCdiService from '@/js/api/services/SosiaCdi';
import CdiEvaluationForm from './CdiEvaluationForm';

export default {
  name: 'CdiEvaluationsTable',
  props: ['loading', 'list', 'config', 'hasDismissal'],
  components: { CdiEvaluation, CdiEvaluationForm },
  computed: {
    maxHeight() {
      return window.innerHeight - 195;
    },
    expandedRows() {
      if (this.list.length > 0) return [this.list[0].id];
      else return [];
    }
  },
  methods: {
    editEvalutation(row) {
      this.$root.$emit('cdiEditEvaluation', row);
    },
    deleteEvaluation(id) {
      this.$confirm(`Sicuro di voler cancellare?`, 'Attenzione', {
        type: 'warning'
      })
        .then(() => {
          SosiaCdiService.deleteEvaluation(id).then(() => {
            this.$notify({ type: 'success', message: 'Cancellazione eseguita' });
            this.$root.$emit('cdiFetchPatients');
          });
        })
        .catch(() => {});
    },
    colClass({ row }) {
      if (row.deleted_at) return 'deleted-row';
      else return;
    },
    onExpand(row, expanded) {
      const active = document.getElementsByClassName('el-table__expand-icon--expanded');
      if (active.length > 0 && expanded.length > 0) {
        active[0].click();
      }
    }
  }
};
</script>

<template>
  <el-table
    :data="list"
    ref="evaluationsTable"
    style="width: 100%; font-size: 12px"
    highlight-current-row
    row-key="id"
    :expand-row-keys="expandedRows"
    :cell-class-name="colClass"
    @expand-change="onExpand"
  >
    <el-table-column type="expand">
      <template slot-scope="props">
        <cdi-evaluation :data="props.row" :config="config" />
      </template>
    </el-table-column>
    <el-table-column label="Inizio" prop="data_valutazione" min-width="40">
      <template slot-scope="scope">
        {{ scope.row.data_valutazione | moment('DD/MM/YYYY') }}
      </template>
    </el-table-column>
    <el-table-column label="Tipologia" min-width="60">
      <template slot-scope="scope">
        {{ config['tipologia_valutazione'][scope.row.tipo_valutazione] }}
      </template>
    </el-table-column>
    <el-table-column label="Fine" prop="data_fine_valutazione" min-width="40">
      <template slot-scope="scope" v-if="scope.row.data_fine_valutazione">
        {{ scope.row.data_fine_valutazione | moment('DD/MM/YYYY') }}
      </template>
    </el-table-column>
    <el-table-column label="Cancellazione" prop="deleted_at" min-width="40">
      <template slot-scope="scope" v-if="scope.row.deleted_at">
        {{ scope.row.deleted_at | moment('DD/MM/YYYY') }}
      </template>
    </el-table-column>
    <el-table-column label="Azioni" align="right" v-if="!hasDismissal" min-width="65">
      <template slot-scope="scope">
        <el-button
          size="mini"
          :disabled="Boolean(scope.row.deleted_at)"
          @click="editEvalutation(scope.row)"
          class="s-button"
          >Modifica</el-button
        >
        <el-button
          size="mini"
          type="danger"
          @click="deleteEvaluation(scope.row.id)"
          :disabled="Boolean(scope.row.deleted_at)"
          class="s-button"
          >Cancella</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>