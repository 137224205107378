<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-form
          label-position="top"
          label-width="80px"
          :model="result"
          ref="form"
          :rules="testRules"
        >
          <!-- tab 1 -->
          <el-row :gutter="20" style="padding: 20px 20px 0">
            <el-col :span="24">
              <el-row :gutter="20">
                <!-- 1 -->
                <el-col :span="24">
                  <el-row type="flex" justify="space-between" align="middle">
                    <el-col :span="16"
                      >1. Il paziente è stato inserito in seguito ad una caduta,
                      oppure è caduto durante la degenza? (esame della
                      documentazione)</el-col
                    >
                    <el-col :span="8">
                      <el-form-item prop="q1_result">
                        <el-radio-group
                          v-model="result.q1_result"
                          style="display: flex; justify-content: center"
                        >
                          <el-radio :label="1" border class="no-margin"
                            >Si</el-radio
                          >
                          <el-radio :label="0" border class="no-margin"
                            >No</el-radio
                          >
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <!-- BREAK Submit -->
                <el-col :span="24">
                  <el-divider>Ritieni che l'ospite:</el-divider>
                </el-col>
                <!-- 2 -->
                <el-col :span="24">
                  <el-row type="flex" justify="space-between" align="middle">
                    <el-col :span="16"
                      >2. Sia agitato? (definizione: eccessiva attività motoria,
                      solitamente non finalizzata ed associato ad agitazione
                      interiore. Es: incapacità a stare seduto fermo, si muove
                      con irrequietezza, si tira i vestiti, ecc.)</el-col
                    >
                    <el-col :span="8">
                      <el-form-item prop="q2_result">
                        <el-radio-group
                          v-model="result.q2_result"
                          style="display: flex; justify-content: center"
                        >
                          <el-radio :label="1" border class="no-margin"
                            >Si</el-radio
                          >
                          <el-radio :label="0" border class="no-margin"
                            >No</el-radio
                          >
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <!-- 3 -->
                <el-col :span="24">
                  <el-row type="flex" justify="space-between" align="middle">
                    <el-col :span="16"
                      >3. Abbia un calo della vista tale da compromettere tutte
                      le altre funzioni quotidiane?</el-col
                    >
                    <el-col :span="8">
                      <el-form-item prop="q3_result">
                        <el-radio-group
                          v-model="result.q3_result"
                          style="display: flex; justify-content: center"
                        >
                          <el-radio :label="1" border class="no-margin"
                            >Si</el-radio
                          >
                          <el-radio :label="0" border class="no-margin"
                            >No</el-radio
                          >
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <!-- 4 -->
                <el-col :span="24">
                  <el-row type="flex" justify="space-between" align="middle">
                    <el-col :span="16"
                      >4. Necessiti di andare in bagnocon paritcolare frequenza?
                      ({{ "<3ore" }})
                    </el-col>
                    <el-col :span="8">
                      <el-form-item prop="q4_result">
                        <el-radio-group
                          v-model="result.q4_result"
                          style="display: flex; justify-content: center"
                        >
                          <el-radio :label="1" border class="no-margin"
                            >Si</el-radio
                          >
                          <el-radio :label="0" border class="no-margin"
                            >No</el-radio
                          >
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
                <!-- 5 -->
                <el-col :span="24">
                  <el-row type="flex" justify="space-between" align="middle">
                    <el-col :span="16"
                      >5. L'ospite ha un punteggio di mobilità corrispondente a
                      3 o a 4? (vedi schema di calcolo sottostante)</el-col
                    >
                    <el-col :span="8">
                      <el-form-item prop="q5_result">
                        <el-radio-group
                          v-model="result.q5_result"
                          style="display: flex; justify-content: center"
                        >
                          <el-radio disabled :label="1" border class="no-margin"
                            >Si</el-radio
                          >
                          <el-radio disabled :label="0" border class="no-margin"
                            >No</el-radio
                          >
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="bottom-space">
                <!-- BREAK Submit -->
                <el-col :span="24">
                  <el-divider></el-divider>
                </el-col>
                <!-- submit -->
                <el-col :span="24">
                  <el-col :span="12">
                    <el-form-item label="Totale">
                      <el-input v-model="form.total" :disabled="true">
                        <template slot="append"
                          >/ {{ form.total_quest }}</template
                        >
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <!-- tab 2 -->
          <el-col :span="24">
            <el-divider
              >Schema per il calcolo del punteggio di mobilità</el-divider
            >
          </el-col>
          <el-row :gutter="20" style="padding: 0 20px">
            <!-- intestazione -->
            <el-col :span="24" class="border-bottom">
              <el-row
                :span="24"
                type="flex"
                justify="space-around"
                align="middle"
                style="font-weight: 800"
              >
                <el-col :span="8">
                  <el-col> Il paziente è ingrado di: </el-col>
                </el-col>
                <el-col :span="4">
                  <el-col>
                    <center>NO</center>
                  </el-col>
                </el-col>
                <el-col :span="4">
                  <el-col>
                    <center>Con aiuto maggiore</center>
                  </el-col>
                </el-col>
                <el-col :span="4">
                  <el-col>
                    <center>Con aiuto minore</center>
                  </el-col>
                </el-col>
                <el-col :span="4">
                  <el-col>
                    <center>Indipendente</center>
                  </el-col>
                </el-col>
              </el-row>
            </el-col>
            <!-- 1 -->
            <el-col :span="24" class="border-bottom">
              <el-row :span="24" type="flex" justify="center" align="middle">
                <el-col :span="8">
                  Spostarsi dalla sedia al letto e ritornare (include il sedersi
                  sul letto)
                </el-col>
                <el-col :span="4" v-for="el in 4" :key="el">
                  <el-col>
                    <center>
                      <el-radio
                        v-model="result.m1_result"
                        :label="el - 1"
                      ></el-radio>
                    </center>
                  </el-col>
                </el-col>
              </el-row>
            </el-col>
            <!-- 2 -->
            <el-col :span="24" class="border-bottom">
              <el-row :span="24" type="flex" justify="center" align="middle">
                <el-col :span="8">
                  Camminare sul piano (spingere la sedia a rotelle se non
                  deambula)
                </el-col>
                <el-col :span="4" v-for="el in 4" :key="el">
                  <el-col>
                    <center>
                      <el-radio
                        v-model="result.m2_result"
                        :label="el - 1"
                      ></el-radio>
                    </center>
                  </el-col>
                </el-col>
              </el-row>
            </el-col>
            <!-- totale mobilità -->
            <el-col :span="24" class="border-bottom">
              <el-row :span="24" type="flex" justify="end" align="middle">
                <el-col
                  :span="24"
                  style="
                    display: flex;
                    justify-content: end;
                    align-items: center;
                    font-weight: 800;
                  "
                >
                  TOTALE MOBILITA'
                  <el-input
                    v-model="form.total_m"
                    :disabled="true"
                    style="max-width: 100px; margin-left: 10px"
                  >
                    <template slot="append"
                      >/ {{ form.total_quest_m }}</template
                    >
                  </el-input>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
    <TestActions
      @reset="resetForm"
      @save="submit"
      :loading="loading"
      :show-actions="showActions"
    />
  </div>
</template>
<script>
import TestActions from "../components/TestActions";
export default {
  name: "Stratify",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 5,
        total_m: 0,
        total_quest_m: 6,
      },
      result: {},
      testRules: {
        q1_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        q2_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        q3_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        q4_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        // q5_result: [
        //   { required: true, message: "Campo Obbligatorio", trigger: "change" },
        // ],
      },
    };
  },
  watch: {
    result: {
      handler: function () {
        let total_m =
          (this.result.m1_result ?? 0) + (this.result.m2_result ?? 0);

        if (total_m == 3 || total_m == 4) {
          this.result.q5_result = 1;
        } else {
          this.result.q5_result = 0;
        }

        let results = _.values(this.filterResults());
        let total = results.reduce(_.add, 0);
        this.form.total = total - total_m;
        this.form.total_m = total_m;
      },
      deep: true,
    },
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
  },
  methods: {
    async submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let result = {
            ...this.result,
            total_m: this.form.total_m,
            total_quest_m: this.form.total_quest_m,
          };
          this.$emit("submitTest", this.form, result);
        } else {
          return false;
        }
      });
    },
    filterResults() {
      return _.pickBy(this.result, (_, key) => key.endsWith("result"));
    },
    resetForm() {
      this.$refs["form"].resetFields();
    },
  },
};
</script>
<style scoped>
.border-bottom {
  padding: 5px 0;
  border-bottom: 1px solid #dcdfe6;
}
.bottom-space {
  margin-bottom: 10px;
}

.no-margin {
  margin: 0;
}
</style>

