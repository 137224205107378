<script>
import SosiaService from '@/js/api/services/Sosia2';

export default {
  name: 'SosiaAbsenceForm',
  props: ['visible', 'record', 'config', 'patientId', 'treatmentInfo'],
  data() {
    return {
      loading: false,
      form: {
        causa_assenza: null,
        data_inizio_assenza: null,
        data_fine_assenza: null
      },
      editMode: Boolean(_.get(this.record, 'id')),
      pickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
        shortcuts: [
          {
            text: 'Annulla',
            onClick(picker) {
              picker.$emit('pick', null);
            }
          }
        ]
      },
    };
  },
  computed: {
    rules() {
      return {
        causa_assenza: [
          { required: this.form.data_inizio_assenza != null, message: 'Campo Obbligatorio', trigger: 'change' }
        ]
      };
    }
  },
  methods: {
    onSubmit() {
      const isEmpty = Object.values(this.form).every((x) => x === null || x === '');
      if (isEmpty) return this.close();
      this.$refs.form.validate((valid, errors) => {
        if (!valid) {
          this.$message({
            type: 'error',
            message: `Campi non validi`
          });
          return false;
        }
        this.editMode ? this.update() : this.create();
      });
    },
    create() {
      const payload = { ...this.form, patient_id: this.patientId, trattamento_id: this.treatmentInfo.id };
      SosiaService.storeAbsence(payload)
        .then(() => this.$root.$emit('fetchPatient'))
        .then(() => this.close());
    },
    update() {
      const payload = { ...this.form, patient_id: this.record.patient_id, trattamento_id: this.treatmentInfo.id };
      SosiaService.updateAbsence(this.record.id, payload)
        .then(() => this.$root.$emit('fetchPatient'))
        .then(() => this.close());
    },
    close() {
      this.$emit('update:visible', false);
    }
  },
  created() {
    if (this.editMode) {
      const fields = _.pick(this.record, ['data_inizio_assenza', 'data_fine_assenza', 'causa_assenza']);
      Object.assign(this.form, fields);
    }
  }
};
</script>

<template>
  <el-dialog
    :visible.sync="visible"
    title="Assenza"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :show-close="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    width="80%"
    center
  >
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
          labelPosition="top"
          size="mini"
          :rules="rules"
          v-loading="loading"
          :validate-on-rule-change="false"
        >
          <el-row :gutter="10">
            <el-col :sm="24">
              <el-card>
                <el-row :gutter="10">
                  <el-col :span="12">
                    <el-form-item label="Inizio assenza temporanea" prop="data_inizio_assenza">
                      <el-date-picker
                        v-model="form.data_inizio_assenza"
                        type="date"
                        placeholder="Selezionare Data"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Fine assenza temporanea" prop="data_fine_assenza">
                      <el-date-picker
                        v-model="form.data_fine_assenza"
                        type="date"
                        placeholder="Selezionare Data"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :sm="12" :md="12">
                    <el-form-item label="Causale Assenza Temporanea" prop="causa_assenza">
                      <el-select v-model="form.causa_assenza" clearable>
                        <el-option
                          v-for="(item, k) in config.causale_assenza_temporanea"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" justify="center" style="margin-top: 20px">
            <el-button @click="$emit('update:visible', false)" class="modal-btn">Annulla</el-button>
            <el-button type="primary" @click="onSubmit" class="modal-btn">Salva</el-button>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0.6rem;
  .el-form-item__label {
    padding: 0 !important;
  }
}
.el-divider__text {
  font-weight: bold;
}
</style>