<script>
import SosiaService from '@/js/api/services/Sosia2';
import SosiaRateForm from './SosiaRateForm.vue';

export default {
  name: 'SosiaRateTable',
  props: ['loading', 'list', 'config', 'hasDismissal', 'patientId', 'treatmentInfo'],
  components: { SosiaRateForm },
  data() {
    return {
      rateFormVisible: false,
      record: null
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 195;
    }
  },
  methods: {
    editRecord(row) {
      this.record = row;
      this.rateFormVisible = true;
    },
    createRecord() {
      this.record = null;
      this.rateFormVisible = true;
    },
    deleteRecord(id) {
      this.$confirm(`Sicuro di voler cancellare?`, 'Attenzione', {
        type: 'warning'
      })
        .then(() => {
          SosiaService.deleteRate(id).then(() => {
            this.$notify({ type: 'success', message: 'Cancellazione eseguita' });
            this.$root.$emit('fetchPatient');
          });
        })
        .catch(() => {});
    },
    colClass({ row }) {
      if (row.deleted_at) return 'deleted-row';
      else return;
    }
  }
};
</script>

<template>
  <div>
    <el-table
      :data="list"
      ref="rateTable"
      style="width: 100%; font-size: 12px"
      highlight-current-row
      row-key="id"
      :cell-class-name="colClass"
    >
      <el-table-column label="Inizio" prop="clean_inizio" min-width="30"></el-table-column>
      <el-table-column label="Fine" prop="clean_fine" min-width="30"></el-table-column>
      <el-table-column label="SSR" prop="retta_ssr" min-width="20"></el-table-column>
      <el-table-column label="Comune" prop="retta_comune" min-width="20"></el-table-column>
      <el-table-column label="Ospite" prop="retta_ospite" min-width="20"></el-table-column>
      <el-table-column label="Altri Enti" prop="retta_enti" min-width="20"></el-table-column>
      <el-table-column align="right" v-if="!hasDismissal" :min-width="50">
        <template slot="header" slot-scope="scope">
          <el-button type="primary" icon="el-icon-plus" size="mini" @click="createRecord()" v-show="!hasDismissal"
            >Nuova Retta</el-button
          >
        </template>
        <template slot-scope="scope">
          <el-button
            size="mini"
            :disabled="Boolean(scope.row.deleted_at)"
            @click="editRecord(scope.row)"
            class="s-button"
            >Modifica</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="deleteRecord(scope.row.id)"
            :disabled="Boolean(scope.row.deleted_at)"
            class="s-button"
            >Cancella</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <sosia-rate-form
      v-if="rateFormVisible"
      :patient-id="patientId"
      :config="config"
      :visible.sync="rateFormVisible"
      :record="record"
      :treatmentInfo="treatmentInfo"
    />
  </div>
</template>