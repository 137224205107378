<template>
  <div v-if="!loading">
    <el-row :gutter="20" v-if="!registry.hasEducation">
      <el-divider />
      <center
        class="warning"
      >Dato mancante nella scheda del paziente e necessario per la compilazione del test</center>
      <el-divider />
      <el-col :span="8">
        <center>
          <el-form-item label="Anni di scolarizzazione" />
        </center>
      </el-col>
      <el-col :span="8">
        <el-input-number v-model="education_years" :min="0" :max="20" :disabled="serverError" />
      </el-col>
      <el-col :span="8">
        <center>
          <el-button
            type="primary"
            @click="updatePatient"
            :disabled="serverError"
            :loading="updating"
          >Aggiorna Paziente</el-button>
        </center>
      </el-col>
    </el-row>

    <el-form
      label-position="top"
      label-width="80px"
      :model="result"
      ref="form"
      :rules="testRules"
      v-if="registry.hasEducation"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-divider></el-divider>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="feasibility" label="Test somministrabile?">
              <el-radio-group v-model="result.feasibility" @change="activateForm()">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="12" v-if="showMotivation">
            <el-form-item prop="feasibility_reason" label="Motivazioni">
              <el-input v-model="result.feasibility_reason"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24" v-if="showForm">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="q1_result" label="1. Che giorno è oggi (giorno, mese, anno)?">
                <el-radio-group v-model="result.q1_result">
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="q2_result" label="2. Che giorno è della settimana?">
                <el-radio-group v-model="result.q2_result">
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="q3_result" label="3. Qual è il nome di questo posto?">
                <el-radio-group v-model="result.q3_result">
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="q4_result"
                label="4. Qual è il suo numero di telefono ? (o indirizzo se non ha il telefono)"
              >
                <el-radio-group v-model="result.q4_result">
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="q5_result" label="5. Quanti anni ha?">
                <el-radio-group v-model="result.q5_result">
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item prop="q6_result" label="6. Quando è nato?">
                <el-radio-group v-model="result.q6_result">
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="q7_result"
                label="7. Chi è l’attuale Papa? (o Presidente della Repubblica)"
              >
                <el-radio-group v-model="result.q7_result">
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="q8_result"
                label="8. Chi era il Papa precedente? (o Presidente della Repubblica)"
              >
                <el-radio-group v-model="result.q8_result">
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="q9_result"
                label="9. Qual era il nome di sua madre prima di sposarsi?"
              >
                <el-radio-group v-model="result.q9_result">
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                prop="q10_result"
                label="10. Qual era il nome di sua madre prima di sposarsi?"
              >
                <el-radio-group v-model="result.q10_result">
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Punteggio corretto">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Numero totale di errori">
              <el-input v-model="totalErrors" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Punteggio">
              <el-input v-model="totalDescription.points" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Risultato">
              <el-input v-model="totalDescription.status" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template>

<script>
import TestActions from "../components/TestActions";
import Patient from "@/js/api/services/Patient";

export default {
  name: "Spmsq",
  props: ["loading", "patientRegistry", "patientId"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 10
      },
      result: {
        feasibility: null
      },
      registry: {},
      showMotivation: false,
      showForm: false,
      education_years: null,
      serverError: false,
      updating: false,
      testRules: {
        feasibility: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        feasibility_reason: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q1_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q2_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q3_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q4_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q5_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q6_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q7_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q8_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q9_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q10_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            factor: this.factor,
            education_years: this.registry.educationYears,
            total_description: this.totalDescription.status,
            total_points: this.totalDescription.points,
            total_errors: this.totalErrors
          }
          this.$emit("submitTest", this.form, result);
        } else {
          return false;
        }
      });
    },
    filterResults() {
      return _.pickBy(this.result, (_, key) => key.endsWith("result"));
    },
    activateForm() {
      if (this.result.feasibility) {
        this.showMotivation = false;
        this.showForm = true;
        this.result = {
          feasibility: 1,
          feasibility_reason: null
        };
      } else {
        this.showMotivation = true;
        this.showForm = false;
        this.form.total = 0;
        this.result = {
          feasibility: 0
        };
      }
    },
    resetForm() {
      this.showMotivation = false;
      this.showForm = false;
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    },
    updatePatient() {
      let payload = { education_years: this.education_years };
      this.updating = true;
      Patient.patch(this.registry.id, payload)
        .then(this.fetchLastRegistry)
        .catch(this.onUpdateFailed)
        .finally(() => (this.updating = false));
    },
    processRegistry(response) {
      const yearsPath = ["data", "items", "education_years"];
      const idPath = ["data", "items", "id"];
      const agePath = ["data", "patient", "age"];

      let educationYears = _.get(response, yearsPath);
      let id = _.get(response, idPath);
      let age = _.get(response, agePath);
      this.registry = {
        age,
        educationYears,
        id,
        hasEducation: !_.isNil(educationYears)
      };
      this.serverError = false;
    },
    onLastRegistryFailed(error) {
      this.serverError = true;
      let { exception, message, file } = _.get(error, ["response", "data"]);
      message = JSON.stringify({ file, message }, null, 2);
      if (isDev()) {
        this.$alert(message, "DEV", {
          confirmButtonText: "OK",
          center: true,
          type: "error"
        });
      }
    },
    hideLoader() {
      this.$emit("update:loading", false);
    },
    showLoader() {
      this.$emit("update:loading", true);
    },
    fetchLastRegistry() {
      this.showLoader();
      Patient.registry(this.patientId)
        .then(this.processRegistry)
        .catch(this.onLastRegistryFailed)
        .finally(this.hideLoader);
    },
    onUpdateFailed(error) {
      this.$notify({
        title: "Errore",
        message: "Errore durante l'aggiornamento dei dati del paziente",
        type: "error"
      });
    },
    errors() {
      let results = _.values(this.filterResults());
      return _.filter(results, x => x == 0);
    },
    computeFactor() {
      const { educationYears } = this.registry;
      let factor = '0';
      if(_.size(this.errors()) == 0) return factor;
      if (educationYears < 8) factor = '-1';
      if (educationYears > 8) factor = '+1';
      return factor;
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
    totalErrors() {
      return _.size(this.errors())
    },
    totalDescription() {
      return _.cond([
        [
          _.conforms({ length: n => _.inRange(n, 0, 5) }),
          _.constant({
            status: "Deterioramento assente - lieve",
            points: 0
          })
        ],
        [
          _.conforms({ length: n => _.inRange(n, 5, 8) }),
          _.constant({
            status: "Deterioramento moderato",
            points: 1
          })
        ],
        [
          _.conforms({ length: n => _.inRange(n, 8, 11) }),
          _.constant({
            status: "Deterioramento grave",
            points: 2
          })
        ]
      ])(this.errors());
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(this.filterResults());
        let factor = parseInt(this.computeFactor());
        this.factor = factor;
        let total = results.reduce(_.add, factor);
        this.form.total = total;
      },
      deep: true
    }
  },
  created() {
    this.fetchLastRegistry();
  }
};
</script>
<style scoped>
.bottom-space {
  margin-bottom: 50px;
}
.warning {
  color: red;
  font-weight: bold;
}
</style>
