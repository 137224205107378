<script>
import SosiaService from '@/js/api/services/Sosia2';
import SosiaAbsenceForm from './SosiaAbsenceForm.vue';

export default {
  name: 'SosiaAbsencesTable',
  props: ['loading', 'list', 'config', 'hasDismissal', 'patientId', 'treatmentInfo'],
  components: { SosiaAbsenceForm },
  data() {
    return {
      absenceFormVisible: false,
      record: null
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 195;
    }
  },
  methods: {
    editRecord(row) {
      this.record = row;
      this.absenceFormVisible = true;
    },
    createRecord() {
      this.record = null;
      this.absenceFormVisible = true;
    },
    deleteRecord(id) {
      this.$confirm(`Sicuro di voler cancellare?`, 'Attenzione', {
        type: 'warning'
      })
        .then(() => {
          SosiaService.deleteAbsence(id).then(() => {
            this.$notify({ type: 'success', message: 'Cancellazione eseguita' });
            this.$root.$emit('fetchPatient');
          });
        })
        .catch(() => {});
    },
    colClass({ row }) {
      if (row.deleted_at) return 'deleted-row';
      else return;
    }
  }
};
</script>

<template>
  <div>
    <el-table
      :data="list"
      ref="absencesTable"
      style="width: 100%; font-size: 12px"
      highlight-current-row
      row-key="id"
      :cell-class-name="colClass"
    >
      <el-table-column label="Inizio" prop="clean_inizio" min-width="40"></el-table-column>
      <el-table-column label="Fine" prop="clean_fine" min-width="40"></el-table-column>
      <el-table-column label="Causa">
        <template slot-scope="scope">
          {{ config['causale_assenza_temporanea'][scope.row.causa_assenza] }}
        </template>
      </el-table-column>
      <el-table-column align="right" v-if="!hasDismissal" :min-width="60">
        <template slot="header" slot-scope="scope">
          <el-button type="primary" size="mini" icon="el-icon-plus" @click="createRecord()" v-show="!hasDismissal"
            >Nuova Assenza</el-button
          >
        </template>
        <template slot-scope="scope">
          <el-button
            size="mini"
            :disabled="Boolean(scope.row.deleted_at)"
            @click="editRecord(scope.row)"
            class="s-button"
            >Modifica</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="deleteRecord(scope.row.id)"
            :disabled="Boolean(scope.row.deleted_at)"
            class="s-button"
            >Cancella</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <sosia-absence-form
      v-if="absenceFormVisible"
      :patient-id="patientId"
      :config="config"
      :visible.sync="absenceFormVisible"
      :record="record"
      :treatmentInfo="treatmentInfo"
    />
  </div>
</template>