<template>
  <div v-show="!loading">
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row>
        <transition name="el-fade-in-linear">
          <el-col :span="24" v-show="invalidMmse && !loading">
            <h3>
              <center class="warning">
                <i class="el-icon-warning-outline"></i> Totale MMSE maggiore o uguale 19 o nessun MMSE eseguito
              </center>
            </h3>
          </el-col>
        </transition>
        <el-col :span="24">
          <el-image style="width: 100%" :src="image_url"></el-image>
        </el-col>
        <el-form-item prop="q1_result" class="test_vas">
          <el-col :span="4">
            <el-radio v-model="result.q1_result" :label="0">0</el-radio>
            <br />Nessun dolore
          </el-col>
          <el-col :span="4">
            <el-radio v-model="result.q1_result" :label="1">1</el-radio>
            <br />Dolore lieve
          </el-col>
          <el-col :span="4">
            <el-radio v-model="result.q1_result" :label="2">2</el-radio>
            <br />Dolore moderato
          </el-col>
          <el-col :span="4">
            <el-radio v-model="result.q1_result" :label="3">3</el-radio>
            <br />Dolore forte
          </el-col>
          <el-col :span="4">
            <el-radio v-model="result.q1_result" :label="4">4</el-radio>
            <br />Dolore molto forte
          </el-col>
          <el-col :span="4">
            <el-radio v-model="result.q1_result" :label="5">5</el-radio>
            <br />Il più forte dolore immaginabile
          </el-col>
          <!-- </el-radio-group> -->
        </el-form-item>
      </el-row>
      <el-row :gutter="20">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template>
<script>
import { rules, filterResults } from "../constants";
import TestActions from "../components/TestActions";
import Tests from "@/js/api/services/Test";

export default {
  name: "Vas",
  props: ["loading", "patientId"],
  components: { TestActions },
  created() {
    this.showLoader();
    this.fetchLastMmse();
  },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 5
      },
      lastMmse: null,
      result: {},
      testRules: rules(1),
      image_url: "/images/test_vas.png"
    };
  },
  methods: {
    async fetchLastMmse() {
      let params = {
        patient_id: this.patientId
      };
      const response = await Tests.fetch(params);
      let tests = _.get(response, ["data", "scales"]);
      this.lastMmse = tests.find(test => test.test_model == "Mmse");
      this.hideLoader();
    },
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$emit("submitTest", this.form, this.result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    },
    hideLoader() {
      this.$emit("update:loading", false);
    },
    showLoader() {
      this.$emit("update:loading", true);
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
    invalidMmse() {
      return !this.lastMmse || !this.allowedToContinue;
    },
    allowedToContinue() {
      let total = _.get(this.lastMmse, ["test_data", "total"]);
      return total >= 19;
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>
<style lang="scss" scoped>
.test_vas .el-col {
  text-align: center;
  line-height: 20px;
}
.warning {
  color: red;
}
</style>