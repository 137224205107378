import _ from 'lodash';

const rules = (numberOfQuestions) => {
    let defaultRule = { required: true, message: "Campo Obbligatorio", trigger: "change" };
    return _.range(numberOfQuestions)
        .reduce((acc, next, idx) => {
            idx++;
            acc[`q${idx}_result`] = defaultRule;
            return acc;
        }, {})
}

const filterResults = (data, specific) => {
    if (specific) {
        return _.pickBy(data, (_, key) => specific.includes(key));
    }
    return _.pickBy(data, (_, key) => key.endsWith("result"));
}

const categories = [
    {
        name: 'Equilibrio da seduto ',
        checks: {
            0: {
                result: 'q1_result',
                options: [
                    {
                        label: 'Si inclina, scivola dalla sedia',
                        points: 0
                    },
                    {
                        label: 'E’ stabile e sicuro',
                        points: 1
                    }
                ]
            }
        }
    },
    {
        name: 'Alzarsi dalla sedia',
        checks: {
            0: {
                result: 'q2_result',
                options: [
                    {
                        label: 'E’ incapace senza aiuto',
                        points: 0
                    },
                    {
                        label: 'Deve aiutarsi con le braccia',
                        points: 1
                    },
                    {
                        label: 'Si alza senza aiuto ',
                        points: 2
                    },
                ]
            }
        }
    },
    {
        name: 'Tentativo di alzarsi',
        checks: {
            0: {
                result: 'q3_result',
                options: [
                    {
                        label: 'E’ incapace senza aiuto',
                        points: 0
                    },
                    {
                        label: 'Capace ma richiede più di un tentativo',
                        points: 1
                    },
                    {
                        label: 'Capace al primo tentativo',
                        points: 2
                    },
                ]
            }
        }
    },
    {
        name: 'Equilibrio nella stazione eretta',
        checks: {
            0: {
                result: 'q4_result',
                options: [
                    {
                        label: 'Instabile (vacilla, muove i piedi, marcata oscillazione del tronco)',
                        points: 0
                    },
                    {
                        label: 'Stabile grazie all’uso di bastoni o ausili',
                        points: 1
                    },
                    {
                        label: 'Stabile senza ausili',
                        points: 2
                    },
                ]
            }
        }
    },
    {
        name: 'Equilibrio nella stazione eretta prolungata',
        checks: {
            0: {
                result: 'q5_result',
                options: [
                    {
                        label: 'Instabile (vacilla, muove i piedi, marcata oscillazione del tronco)',
                        points: 0
                    },
                    {
                        label: 'Stabile ma a base larga (i malleoli mediali distano > 10cm)',
                        points: 1
                    },
                    {
                        label: 'Stabile a base stretta, senza supporti',
                        points: 2
                    },
                ]
            }
        }
    },
    {
        name: 'Romberg',
        checks: {
            0: {
                result: 'q6_result',
                options: [
                    {
                        label: 'Instabile',
                        points: 0
                    },
                    {
                        label: 'Stabile',
                        points: 1
                    },
                ]
            }
        }
    },
    {
        name: 'Romberg sensibilizzato',
        checks: {
            0: {
                result: 'q7_result',
                options: [
                    {
                        label: 'Comincia a cadere',
                        points: 0
                    },
                    {
                        label: 'Oscilla ma si riprende da solo',
                        points: 1
                    },
                    {
                        label: 'Stabile',
                        points: 2
                    },
                ]
            }
        }
    },
    {
        name: 'Girarsi di 360 gradi',
        checks: {
            0: {
                result: 'q8_result',
                options: [
                    {
                        label: 'A passi discontinui',
                        points: 0
                    },
                    {
                        label: 'A passi continui',
                        points: 1
                    },
                ]
            },
            1: {
                result: 'q9_result',
                options: [
                    {
                        label: 'Instabile',
                        points: 0
                    },
                    {
                        label: 'Stabile',
                        points: 1
                    },
                ]
            }
        }
    },
    {
        name: 'Sedersi',
        checks: {
            0: {
                result: 'q10_result',
                options: [
                    {
                        label: 'Insicuro (sbaglia la distanza, cade sulla sedia)',
                        points: 0
                    },
                    {
                        label: 'Usa le braccia o ha un movimento discontinuo',
                        points: 1
                    },
                    {
                        label: 'Sicuro con movimento continuo',
                        points: 2
                    },
                ]
            }
        }
    },
    {
        name: 'Inizio della deambulazione',
        checks: {
            0: {
                result: 'q11_result',
                options: [
                    {
                        label: 'Una certa esitazione, più tentativi',
                        points: 0
                    },
                    {
                        label: 'Nessuna esitazione',
                        points: 1
                    }
                ]
            }
        }
    },
    {
        name: 'Lunghezza ed altezza del passo del Piede dx',
        checks: {
            0: {
                result: 'q12_result',
                options: [
                    {
                        label: 'Il piede dx non supera il sn',
                        points: 0
                    },
                    {
                        label: 'Il piede dx supera il sn',
                        points: 1
                    }
                ]
            },
            1: {
                result: 'q13_result',
                options: [
                    {
                        label: 'Il piede dx non si alza completamente dal pavimento ',
                        points: 0
                    },
                    {
                        label: 'Il piede dx si alza completamente dal pavimento',
                        points: 1
                    }
                ]
            }
        }
    },
    {
        name: 'Lunghezza ed altezza del passo del Piede sn',
        checks: {
            0: {
                result: 'q14_result',
                options: [
                    {
                        label: 'Il piede sn non supera il dx',
                        points: 0
                    },
                    {
                        label: 'Il piede sn supera il dx',
                        points: 1
                    }
                ]
            },
            1: {
                result: 'q15_result',
                options: [
                    {
                        label: 'Il piede sn non si alza completamente dal pavimento ',
                        points: 0
                    },
                    {
                        label: 'Il piede dx sn alza completamente dal pavimento',
                        points: 1
                    }
                ]
            }
        }
    },
    {
        name: 'Simmetria del passo',
        checks: {
            0: {
                result: 'q16_result',
                options: [
                    {
                        label: 'Il passo dx e sn non sembrano uguali',
                        points: 0
                    },
                    {
                        label: 'Il passo dx e sn sembrano uguali',
                        points: 1
                    }
                ]
            }
        }
    },
    {
        name: 'Continuità del passo',
        checks: {
            0: {
                result: 'q17_result',
                options: [
                    {
                        label: 'Passo interrotto o discontinuo',
                        points: 0
                    },
                    {
                        label: 'Passo continuo',
                        points: 1
                    }
                ]
            }
        }
    },
    {
        name: 'Traiettoria',
        checks: {
            0: {
                result: 'q18_result',
                options: [
                    {
                        label: 'Deviazione marcata',
                        points: 0
                    },
                    {
                        label: 'Deviazione lieve o moderato o uso di ausili',
                        points: 1
                    },
                    {
                        label: 'Assenza di deviazioni e di uso di ausili',
                        points: 2
                    }
                ]
            },
            1: {
                result: 'q19_result',
                options: [
                    {
                        label: 'Marcata oscillazione o uso di ausili',
                        points: 0
                    },
                    {
                        label: 'Flessione di ginocchia o schiena o allargamento delle braccia',
                        points: 1
                    },
                    {
                        label: 'Nessuna oscillazione, flessione, uso delle braccia',
                        points: 2
                    }
                ]
            }
        }
    },
    {
        name: 'Cammino',
        checks: {
            0: {
                result: 'q20_result',
                options: [
                    {
                        label: 'I talloni sono separati',
                        points: 0
                    },
                    {
                        label: 'I talloni quasi si toccano',
                        points: 1
                    }
                ]
            }
        }
    },

]

const totalDescription = (data) => {
    return _.cond([
        [_.matches({ total_eq: 0 }), _.constant("Nessuna Autonomia")],
        
      [
        _.conforms({ total_eq: n => _.inRange(n, 1, 8) }),
        _.constant(
          "Riduzione severa dell'autonomia motoria"
        )
      ],
      [
        _.conforms({ total_eq: n => _.inRange(n, 8, 12) }),
        _.constant(
          "Riduzione moderata dell'autonomia motoria"
        )
      ],
      [
        _.conforms({ total_eq: n => _.inRange(n, 12, 15) }),
        _.constant(
          "Riduzione lieve dell'autonomia motoria"
        )
      ],
      [
        _.conforms({ total_eq: n => _.inRange(n, 15, 17) }),
        _.constant(
          "Completa autonomia"
        )
      ],
    ])(data);
  }


export default { rules, filterResults, categories, totalDescription };
