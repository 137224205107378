<template>
  <div style="height:0">
    <el-button type="info" class="menu-trigger" @click="show()">
      <font-awesome-icon icon="bars" />
    </el-button>
    <el-drawer :visible.sync="isCollapse" direction="ltr" :modal="false">
      <el-menu class="el-menu-vertical-demo" elevation="2" :collapse="false" router>
        <el-menu-item index="/superadmin/dashboard" @click="this.show">Dashboard</el-menu-item>
        <el-menu-item index="/superadmin/dropdowns" @click="this.show">Gestione Tendine</el-menu-item>
        <el-menu-item index="/superadmin/operators" @click="this.show">Gestione Operatori</el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>
<script>
    export default {
        name: "ArkiSidebarSuperadmin",
        data() {
            return {
            isCollapse: false
            };
        },
        methods: {
            show() {
                this.isCollapse = !this.isCollapse;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .md-menu-content-container {
        .md-list {
            padding: 0 15px;
        }
    }
    .bottomItem {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .logo-horizontal-container {
        position: absolute;
        top: 0;
        background: white;
        width: 100%;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 5px;
        img {
            width: 100%;
            height: auto;
        }
    }

    .icon-container {
        float: left;
        width: 50px;
        text-align: center;
        svg {
            color: grey;
        }
    }

    a:not(.md-button):hover {
        text-decoration: none;
    }
</style>
