<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">

        <el-col :span="24" v-for="(category, idx) in categories" :key="idx + '-cat'">
          <el-divider content-position="center">{{category.name }}</el-divider>
          <el-form-item :prop="category.result">
            <el-col :span="24" v-for="(option, idx2) in category.options" :key="idx2 + '-opt'">
              <el-col :span="16">
                <el-form-item :label="option.label" />
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col :offset="12">
                    <el-form-item>
                      <el-radio :label="option.points" v-model="result[category.result]" border />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-col>
          </el-form-item>
        </el-col>
        <!-- </el-radio-group> -->
      </el-row>
      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template>
<script>
import { VRS } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Vrs",
  props: ["loading", "patientId"],
  components: { TestActions },
  created() {
    
  },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 6
      },
      result: {},
      testRules: VRS.rules(6),
      categories: VRS.categories,
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$emit("submitTest", this.form, this.result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    },
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(VRS.filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>
<style lang="scss" scoped>
.bottom-space {
  margin-bottom: 50px;
}
</style>