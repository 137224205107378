<template>
    <el-row v-loading="loading" :gutter="20" class="no-margin-row">
        <el-card class="box-card">
             <div slot="header" class="clearfix">
                <span>{{name}}</span>
            </div>
            <canvas id="test-chart" height="90"></canvas>
        </el-card>
    </el-row>
</template>

<script>
    import moment from "moment";
    import Chart from 'chart.js';
    import Test from "@/js/api/services/Test";
    import Tests from "@/js/pages/Patients/components/Tests";

    export default {
        name: "TestsChart",
        props: [
            "chart_data",
        ],
        data() {
            return {
                loading: true,
                name: this.chart_data.test_name,
                testChartData: {
                    type: 'line',
                    data: {
                        labels: this.chart_data.labels,
                        datasets: [{
                            label: 'Punteggio Totale',
                            backgroundColor: '#e6a23c',
                            borderColor: '#e6a23c',
                            data: this.chart_data.datasets,
                            fill: false,
                        }]
                    },
                    options: {
                        responsive: true,
                        title: {
                            display: false
                        },
                        tooltips: {
                            mode: 'index',
                            intersect: false,
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: true
                        },
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                display: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Data di esecuzione'
                                }
                            }],
                            yAxes: [{
                                display: true,
                                scaleLabel: {
                                    display: true,
                                    labelString: 'Punteggio Totale'
                                },
                                ticks: {
                                    beginAtZero: true,
                                    max: this.chart_data.suggested_max,
                                },
                            }]
                        }
                    }
                }
            };
        },
        methods: {
            renderChart(chartId, chartData) {
                const ctx = document.getElementById(chartId);
                const myChart = new Chart(ctx, {
                    type: chartData.type,
                    data: chartData.data,
                    options: chartData.options,
                });
                this.loading = false;
            }
        },
        // watch: {
        //     testChartData: {
        //         handler(newDatasets) {
        //             console.log('newDatasets: ', newDatasets);
        //             console.log('chart_data: ', chart_data);
        //             this.chart.data.labels = this.chart_data.rangeDates;
        //             this.chart.data.datasets = newDatasets.dataset;
        //             this.chart.update();
        //         },
        //     },
        // },
        mounted() {
            this.renderChart('test-chart', this.testChartData);
        }
    };
</script>
