<template>
  <div>
    <arki-filters
      ref="filters"
      @filtered="fetch"
      target="tasks"
      :dateRangeFilter="true"
      :optionsFilter="false"
      :options="task_types_arkifilters"
      :professionsFilter="true"
      :optionSpan="8"
    />
    <arki-create-dialog
      title="Consegna"
      dateOptions="future"
      :rules="rules"
      action="/tasks"
      :options="task_types"
      @submit="update"
    />
    <arki-broadcast ref="broadcast" listen=".task.created" @update="update" />
    <div class="block with-filters">
      <el-table
        :class="paginateTable"
        :data="tasks.data"
        :default-sort="{ prop: 'time', order: 'descending' }"
        style="width: 100%"
        :max-height="maxHeight"
        :row-class-name="rowClass"
        v-loading="loading"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-col :span="24">
              <b>Inserimento:</b> {{ scope.row.full_creator }} ({{
                professions[scope.row.profession].label
              }}) -
              {{ scope.row.created_at_time }}
            </el-col>
            <el-col :span="24" v-if="scope.row.full_verification_operator">
              <b v-if="scope.row.enabled == 1">Verifica:</b>
              <b v-else-if="scope.row.enabled == 0">Annullamento:</b>
              {{ scope.row.full_verification_operator }}
              {{
                scope.row.updater_profession
                  ? "(" + professions[scope.row.updater_profession].label + ")"
                  : ""
              }}
              -
              {{ scope.row.clean_verification_time }}
              {{ scope.row.clean_verification_date }}
              {{ scope.row.removed_at_time }}
            </el-col>
            <el-col :span="24" v-if="scope.row.verification_notes">
              <b>Note:</b> {{ scope.row.verification_notes }}
            </el-col>
          </template>
        </el-table-column>
        <el-table-column prop="type" width="60">
          <template slot-scope="scope">
            <el-tooltip
              :content="scope.row.type_description"
              placement="bottom"
              effect="light"
            >
              <el-button
                circle
                size="mini"
                :style="{
                  backgroundColor: professions[scope.row.profession].color,
                }"
              >
                <font-awesome-icon
                  v-if="taskIcons[scope.row.type]"
                  :icon="taskIcons[scope.row.type]"
                  style="
                    width: 1.4em !important;
                    font: 1.4em !important;
                    height: 1.4em !important;
                  "
                />
                <div
                  v-else
                  style="
                    width: 1.4em !important;
                    font: 1.4em !important;
                    height: 1.4em !important;
                  "
                />
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="clean_date_time"
          label="Data e Ora"
          sortable
          align="center"
          width="150"
        ></el-table-column>
        <el-table-column prop="full_patient" label="Ospite"></el-table-column>
        <el-table-column prop="description" label="Descrizione">
          <template slot-scope="scope">
            <div v-html="scope.row.description"></div>
          </template>
        </el-table-column>
        <el-table-column label="Esito" align="right">
          <template slot-scope="scope">
            <arki-check-dialog
              @success="update()"
              class="float-right margin-left"
              buttonType="success"
              icon="check"
              :title="`Conferma: ${scope.row.description}`"
              :action="`tasks/${scope.row.id}`"
              :isDeleteble="
                scope.row.enabled === 1 &&
                scope.row.verification_result === 0 &&
                scope.row.creator_id == user.id
              "
              :rules="rules"
              :patient_id.sync="scope.row.patient_id"
              option="tasks"
              method="patch"
              :row="scope.row"
              v-if="
                scope.row.enabled === 1 && scope.row.verification_result === 0
              "
            />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="tasks.per_page"
        :total="tasks.total"
        :hide-on-single-page="true"
        :current-page="current_page"
        @current-change="changePage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Task from "@/js/api/services/Task";

import ArkiFilters from "@/js/components/ArkiFilters";
import ArkiCreateDialog from "@/js/components/ArkiCreateDialog";
import ArkiCheckDialog from "@/js/components/ArkiCheckDialog";
import ArkiBroadcast from "@/js/components/ArkiBroadcast";
import Settings from "@/js/api/services/Settings";

export default {
  name: "Tasks",
  components: {
    ArkiFilters,
    ArkiCreateDialog,
    ArkiCheckDialog,
    ArkiBroadcast,
  },
  data() {
    return {
      loading: true,
      tasks: {
        data: [],
        per_page: 1,
        total: null,
      },
      current_page: 1,
      task_types: [],
      task_types_arkifilters: [],
      task_filters: [],
      taskIcons: [
        "",
        "clipboard-check",
        "puzzle-piece",
        "chalkboard-teacher",
        "dumbbell",
      ],
      rules: {
        time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
        date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
        option: [
          { required: true, message: "Selezionare Categoria", trigger: "blur" },
        ],
        description: [
          { required: true, message: "Inserire Descrizione", trigger: "blur" },
        ],
        verification_result: [
          { required: true, message: "Selezionare esito", trigger: "blur" },
        ],
      },
      paginateTable: null,
      // first fetch is from filter
      unchangedFilter: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    maxHeight() {
      return window.innerHeight - 235;
    },
    professions() {
      return this.$store.state.professions;
    },
  },
  watch: {
    tasks: function () {
      this.tasks.total / this.tasks.per_page > 1
        ? (this.paginateTable = "paginate-table")
        : (this.paginateTable = null);
    },
  },
  methods: {
    async init() {
      const response = await Task.settings();
      this.task_types = response.data.task_types;
      const task_types_arkifilters = await Settings.fetch("tasks");
      this.task_types_arkifilters = task_types_arkifilters.data;
    },
    update() {
      this.unchangedFilter = true;
      if (this.$refs.filters) {
        this.$refs.filters.emit();
        this.$refs.broadcast.reset();
      }
    },
    async fetch(filters) {
      if (filters.patient_id != this.$route.query.patient_id) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            patient_id: filters.patient_id || undefined,
            t: new Date().getTime(),
          },
        });
        return;
      }
      this.loading = true;
      if (!this.unchangedFilter) {
        this.current_page = 1;
      }
      let params = {
        date_from: moment(filters.dateRange[0]).format("DD/MM/YYYY"),
        date_to: moment(filters.dateRange[1]).format("DD/MM/YYYY"),
        patient_id: filters.patient_id,
        task_filters: filters.option,
        profession: filters.profession,
        page: this.current_page,
      };
      const response = await Task.fetch(params);
      this.tasks = response.data.tasks;
      this.loading = false;
      // reset the checked flag
      this.unchangedFilter = false;
    },
    changePage(page) {
      this.current_page = page;
      this.update();
    },
    rowClass({ row, rowIndex }) {
      if (row.enabled == 0) {
        return "deleted-row";
      } else if (row.verification_result == 2) {
        return "warning-row";
      } else if (row.verification_result == 1) {
        return "success-row";
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
@import "~vue-material/dist/theme/engine";

.margin-left {
  margin-left: 10px;
}

.el-date-editor {
  width: 98%;
}

.el-timeline-item i {
  color: #fff;
  font-size: 20px !important;
}

.el-date-editor.el-input {
  width: 100%;
}
</style>
