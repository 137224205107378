<template>
    <div>
        <el-row v-loading="loading">
            <el-row class="filters" :gutter="5">
                <el-form :model="form" ref="form">
                    <el-col :span="12">
                        <el-select v-model="form.currentYear" placeholder="Anno" @change="changePeriod()">
                            <el-option
                                v-for="year in years"
                                :key="year"
                                :label="year"
                                :value="year"
                            >
                            </el-option>
                        </el-select>
                    </el-col>

                    <el-col :span="12">
                        <el-select v-model="form.currentMonth" placeholder="Mese" @change="changePeriod()">
                            <el-option
                                v-for="(item, index) in months"
                                :key="index"
                                :label="item"
                                :value="index"
                            >
                            </el-option>
                        </el-select>
                    </el-col>
                </el-form>

                <el-col :span="24">
                    <el-button icon="el-icon-download" type="success" style="width: 100%; margin:10px 0;" @click="download()" :loading="downloadLoading">
                        Scarica Report assenze {{months[form.currentMonth]}} {{form.currentYear}}
                    </el-button>
                </el-col>


            </el-row>

            <el-row class="block with-filters">
                <el-table
                    class="absence-table"
                    :data="tableData"
                    style="width: 100%"
                    :fit="true"
                    :max-height="maxHeight"
                >
                    <el-table-column fixed prop="full_name" label="Ospite" width="250" align="center"></el-table-column>
                    <el-table-column label="1" prop="1" align="center" v-if="daysarray.includes(1)"></el-table-column>
                    <el-table-column label="2" prop="2" align="center" v-if="daysarray.includes(2)"></el-table-column>
                    <el-table-column label="3" prop="3" align="center" v-if="daysarray.includes(3)"></el-table-column>
                    <el-table-column label="4" prop="4" align="center" v-if="daysarray.includes(4)"></el-table-column>
                    <el-table-column label="5" prop="5" align="center" v-if="daysarray.includes(5)"></el-table-column>
                    <el-table-column label="6" prop="6" align="center" v-if="daysarray.includes(6)"></el-table-column>
                    <el-table-column label="7" prop="7" align="center" v-if="daysarray.includes(7)"></el-table-column>
                    <el-table-column label="8" prop="8" align="center" v-if="daysarray.includes(8)"></el-table-column>
                    <el-table-column label="9" prop="9" align="center" v-if="daysarray.includes(9)"></el-table-column>
                    <el-table-column label="10" prop="10" align="center" v-if="daysarray.includes(10)"></el-table-column>
                    <el-table-column label="11" prop="11" align="center" v-if="daysarray.includes(11)"></el-table-column>
                    <el-table-column label="12" prop="12" align="center" v-if="daysarray.includes(12)"></el-table-column>
                    <el-table-column label="13" prop="13" align="center" v-if="daysarray.includes(13)"></el-table-column>
                    <el-table-column label="14" prop="14" align="center" v-if="daysarray.includes(14)"></el-table-column>
                    <el-table-column label="15" prop="15" align="center" v-if="daysarray.includes(15)"></el-table-column>
                    <el-table-column label="16" prop="16" align="center" v-if="daysarray.includes(16)"></el-table-column>
                    <el-table-column label="17" prop="17" align="center" v-if="daysarray.includes(17)"></el-table-column>
                    <el-table-column label="18" prop="18" align="center" v-if="daysarray.includes(18)"></el-table-column>
                    <el-table-column label="19" prop="19" align="center" v-if="daysarray.includes(19)"></el-table-column>
                    <el-table-column label="20" prop="20" align="center" v-if="daysarray.includes(20)"></el-table-column>
                    <el-table-column label="21" prop="21" align="center" v-if="daysarray.includes(21)"></el-table-column>
                    <el-table-column label="22" prop="22" align="center" v-if="daysarray.includes(22)"></el-table-column>
                    <el-table-column label="23" prop="23" align="center" v-if="daysarray.includes(23)"></el-table-column>
                    <el-table-column label="24" prop="24" align="center" v-if="daysarray.includes(24)"></el-table-column>
                    <el-table-column label="25" prop="25" align="center" v-if="daysarray.includes(25)"></el-table-column>
                    <el-table-column label="26" prop="26" align="center" v-if="daysarray.includes(26)"></el-table-column>
                    <el-table-column label="27" prop="27" align="center" v-if="daysarray.includes(27)"></el-table-column>
                    <el-table-column label="28" prop="28" align="center" v-if="daysarray.includes(28)"></el-table-column>
                    <el-table-column label="29" prop="29" align="center" v-if="daysarray.includes(29)"></el-table-column>
                    <el-table-column label="30" prop="30" align="center" v-if="daysarray.includes(30)"></el-table-column>
                    <el-table-column label="31" prop="31" align="center" v-if="daysarray.includes(31)"></el-table-column>
                    <el-table-column fixed="right" prop="total" label="Assenze" width="100" align="center"></el-table-column>
                    <el-table-column fixed="right" prop="presences" label="Presenze" width="100" align="center"></el-table-column>
                </el-table>
            </el-row>
        </el-row>
    </div>

</template>
<script>
import Absence from '@/js/api/services/Absence';
import moment from 'moment';
export default {
    name:'Absences',
     data(){
        return{
            loading: true,
            downloadLoading: false,
            form: {
                currentYear: null,
                currentMonth: null,
            },
            response: [],
            tableData: [],
            daysarray: [],
            years: [],
            months: [],
        }
    },
    computed:{
        maxHeight() { return window.innerHeight - 235 },
    },
    methods:{
        async fetch(){
            this.loading = true;

            let params = {
                structure: this.$store.state.structureId,
            }

            const response = await Absence.adminFetch(params);
            this.response = response.data;
            this.tableData = response.data.tableData;
            this.daysarray = response.data.daysarray;
            this.years = response.data.years;
            this.months = response.data.months;
            this.form.currentYear = response.data.currentYear;
            this.form.currentMonth = response.data.currentMonth.toString();

            this.loading = false;
        },

        async changePeriod(){
            this.loading = true;

            let params = {
                structure: this.$store.state.structureId,
                month: this.form.currentMonth,
                year: this.form.currentYear
            }

            const response = await Absence.adminFetch(params);
            this.response = response.data;
            this.tableData = response.data.tableData;
            this.daysarray = response.data.daysarray;
            this.years = response.data.years;
            this.months = response.data.months;
            this.form.currentYear = response.data.currentYear;
            this.form.currentMonth = response.data.currentMonth.toString();

            this.loading = false;
        },

        async download(){
            this.downloadLoading = true;

            let params = {
                structure: this.$store.state.structureId,
                month: this.form.currentMonth,
                year: this.form.currentYear
            }

            const response = await Absence.adminExport(params);
            let title = `Report_assenze_${this.months[this.form.currentMonth]}_${this.form.currentYear}.csv`;

            this.file(response, title)
        },

        file(response, title){
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', title);
            document.body.appendChild(fileLink);
            fileLink.click();

            this.downloadLoading = false;
        },
    },
    mounted(){
        this.fetch();
    }
}
</script>
