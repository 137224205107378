<template>
  <el-row style="margin-top: 0px; font-size: 12px">
    <h4 class="title">CITTADINO TEAM</h4>
    <el-col :span="12">
      <el-row>
        <el-col><span class="key">Nazione</span>: <span>{{ _.get(data[0], 'nazione') }}</span></el-col>
      </el-row>
    </el-col>

    <el-col :span="24">
      <el-col
        ><span class="key"
          >Identification number of the card (Tessera TEAM)</span
        >: <span>{{ _.get(data[0], 'numero_id_ts') }}</span
      ></el-col>
      <el-col
        ><span class="key">Identification number of the istitution</span>:
        <span>{{ _.get(data[0], 'numero_id_istituzione') }}</span></el-col
      >
      <el-col
        ><span class="key">Personal identification number</span>: <span>{{ _.get(data[0], 'numero_id_personale') }}</span
      ></el-col>
      <span class="key">Data scadenza</span>: <span>{{ _.get(data[0], 'clean_data_scadenza') }}</span>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "TesseraTeam",
  props: ["data"],
  components: {},
  data() {
    return {}
  },
};
</script>

<style lang="scss" scoped>
.title {
  border: 0.5px solid #9c9fa5;
  padding: 2px;
  border-radius: 2px;
}
.key {
  font-weight: bold;
}
.value {
}
</style>