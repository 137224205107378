<script>
import KeyValueRow from './KeyValueRow.vue';
export default {
  name: 'CdiEvaluation',
  props: ['data', 'config'],
  components: { KeyValueRow },
  methods: {}
};
</script>


<template>
  <el-container direction="vertical">
    <el-row class="wrapper">
      <el-col :span="12">
        <el-divider>
          <p class="divider-text">BISOGNI</p>
        </el-divider>
        <el-row>
          <key-value-row label="Ossigenoterapia" :value="data.ossigenoterapia == 1 ? 'SI' : 'NO'"></key-value-row>
          <key-value-row label="Tracheostomia" :value="data.tracheostomia == 1 ? 'SI' : 'NO'" />
          <key-value-row label="Ventilazione Assistita" :value="data.vent_assistita == 1 ? 'SI' : 'NO'" />
          <key-value-row label="Insufficienza Renale" :value="data.insuff_renale == 1 ? 'SI' : 'NO'" />
        </el-row>
      </el-col>

      <el-col :span="12">
        <el-divider> <p class="divider-text">BISOGNI ASSISTENZIALI</p></el-divider>
        <el-row>
          <key-value-row label="Lesioni Da Decubito" :value="config['lesione_decubito'][data.les_decubito]" />
          <key-value-row label="Lesioni Cutanee" :value="config['lesione_cutanea'][data.les_cutanee]" />
          <key-value-row
            label="Gestione Incontinenza Urinaria"
            :value="config['incontinenza_urinaria'][data.incont_urinaria]"
          />
          <key-value-row
            label="Gestione Incontinenza Fecale"
            :value="config['incontinenza_fecale'][data.incont_fecale]"
          />
          <key-value-row label="Ausili Per Movimento" :value="config['ausili_movimento'][data.aus_movimento]" />
          <key-value-row label="Ausili In Dotazione" :value="config['ausili_dotazione'][data.aus_dotazione]" />
          <key-value-row
            label="Nutrizione Artificiale"
            :value="config['nutrizione_artificiale'][data.nut_artificiale]"
          />
          <key-value-row label="Regime Alimentare" :value="data.reg_alimentare == 1 ? 'SI' : 'NO'" />
        </el-row>
      </el-col>
    </el-row>
    <el-row class="wrapper">
      <el-col :span="12">
        <el-divider><p class="divider-text">ATTIVITA' DELLA VITA QUOTIDIANA</p></el-divider>
        <el-row>
          <key-value-row label="Fare Il Bagno" :value="config['bagno'][data.bagno]" />
          <key-value-row label="Vestirsi" :value="config['vestirsi'][data.vestirsi]" />
          <key-value-row label="Toilette" :value="config['toilette'][data.toilette]" />
          <key-value-row label="Spostarsi" :value="config['spostarsi'][data.spostarsi]" />
          <key-value-row label="Servizi Continenza Di Feci Ed Urine" :value="config['continenza'][data.continenza]" />
          <key-value-row label="Alimentazione" :value="config['alimentazione'][data.alimentazione]" />
          <key-value-row label="Indicatore Adl" :value="config['indicatore_adl'][data.ind_adl]" />
        </el-row>
      </el-col>

      <el-col :span="12">
        <el-divider><p class="divider-text">AREA SOCIALE</p></el-divider>
        <el-row>
          <key-value-row label="Composizione Nucleo Famigliare" :value="config['nucleo_familiare'][data.nucleo_fam]" />
          <key-value-row label="Assistenza Formale" :value="data.ass_form == 1 ? 'SI' : 'NO'" />
          <key-value-row label="Assistenza Informale" :value="data.ass_inform == 1 ? 'SI' : 'NO'" />
          <key-value-row label="Provvidenze Economiche" :value="data.provv_economiche == 1 ? 'SI' : 'NO'" />
          <key-value-row
            label="Situazione Pensionistica"
            :value="config['situazione_pensionistica'][data.situazione_pensionistica]"
          />
          <key-value-row label="Assegno Di Accompagnamento" :value="data.assegno_accom == 1 ? 'SI' : 'NO'" />
        </el-row>
      </el-col>
    </el-row>

    <el-row class="wrapper">
      <el-col :span="12">
        <el-divider><p class="divider-text">DIAGNOSI</p></el-divider>
        <el-row>
          <key-value-row label="Diagnosi" :value="data.diagn_p" />
          <key-value-row label="Diagnosi Secondaria" :value="data.diagn_s1" />
          <key-value-row label="Diagnosi Secondaria" :value="data.diagn_s2" />
          <key-value-row label="Diagnosi Secondaria" :value="data.diagn_s3" />
          <key-value-row label="Diagnosi Secondaria" :value="data.diagn_s4" />
          <key-value-row label="Diagnosi Secondaria" :value="data.diagn_s5" />
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-divider><p class="divider-text">Altro</p></el-divider>
        <el-row>
          <key-value-row label="Disturbi Comportamentali" :value="data.disturbi_comp == 1 ? 'SI' : 'NO'" />
          <key-value-row label="Stato Cognitivo" :value="config['stato_cognitivo'][data.stato_cognitivo]" />
          <key-value-row label="Indice Di Indipendenza" :value="config['deambulazione'][data.deambulazione]" />
          <key-value-row label="Indicatore Iadl" :value="config['indicatore_iadl'][data.ind_iadl]" />
        </el-row>
      </el-col>
    </el-row>
  </el-container>
</template>

<style lang="scss" scoped>
.wrapper {
  margin-top: 0px;
  font-size: 12px;
}
.el-form-item {
  margin-bottom: 0.6rem;
  .el-form-item__label {
    padding: 0 !important;
  }
}
.el-divider__text {
  font-weight: bold;
}
.title {
  border: 0.5px solid #9c9fa5;
  padding: 2px;
  border-radius: 2px;
}
.divider-text {
  font-size: 12px;
}
</style>