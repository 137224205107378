import _ from 'lodash';

const initialState = () => ({
  hasPaiFormDraft: false,
  hasTestFormDraft: false,
  hasAreaFormDraft: false,
  hasDraft: false,
  testForm: {},
  areaForm: {},
  paiForm: {},
})

export default {
  namespaced: true,

  state() {
    return {
      draft: initialState()
    }
  },

  getters: {
    draft: state => state.draft,
    hasDraft: state => {
      const { hasPaiFormDraft, hasTestFormDraft, hasAreaFormDraft } = state.draft;
      return [hasPaiFormDraft, hasTestFormDraft, hasAreaFormDraft].some(_.identity)
    }
  },

  mutations: {

    draft(state, value) {
      state.draft = value
    },

    updateDraft(state, value) {
      Object.assign(state.draft, value)
    },

    updateTestForm(state, value) {
      Object.assign(state.draft.testForm, value)
      state.draft.hasTestFormDraft = true;
    },

    updateAreaForm(state, value) {
      Object.assign(state.draft.areaForm, value)
      state.draft.hasAreaFormDraft = true;
    },

    updatePaiForm(state, value) {
      Object.assign(state.draft.paiForm, value)
      state.draft.hasPaiFormDraft = true;
    }
  },

  actions: {

    clearDraft({ commit }) {
      commit('draft', initialState())
    },

    updateDraft({ dispatch, commit, getters }, draft) {
      commit('updateDraft', draft)
    },

    updateTestForm({ dispatch, commit, getters }, testForm) {
      commit('updateTestForm', testForm)
    },

    updateAreaForm({ dispatch, commit, getters }, areaForm) {
      commit('updateAreaForm', areaForm)
    },

    updatePaiForm({ dispatch, commit, getters }, paiForm) {
      commit('updatePaiForm', paiForm)
    },
  },
}
