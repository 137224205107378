<template>
  <div>
    <router-link to="/superadmin/operators/create"
      ><el-button class="float-right add-button no-broadcast" type="success"
        ><font-awesome-icon icon="plus" /> INSERISCI</el-button
      ></router-link
    >
    <el-row v-loading="loading">
      <el-row :gutter="20">
        <el-col :span="20" :offset="2" class="input-container">
          <el-input
            v-model="search"
            size="large"
            prefix-icon="el-icon-search"
            placeholder="Cerca utente"
            clearable
          />
        </el-col>
      </el-row>

      <el-row>
        <el-table
          :data="
            this.operators.filter(
              (data) =>
                !search ||
                data.full_name.toLowerCase().includes(search.toLowerCase()) ||
                data.username.toLowerCase().includes(search.toLowerCase())
            )
          "
          :fit="true"
          :max-height="maxHeight"
          :row-class-name="tableRowClassName"
          empty-text="Digitare almeno un carattere per iniziare la ricerca"
        >
          <el-table-column
            fixed
            prop="full_name"
            label="Utente"
            min-width="230"
            sortable
          ></el-table-column>
          <el-table-column prop="role_id" label="Ruolo" width="200">
            <template slot-scope="scope">
              <p v-if="roles && scope.row.role_id">
                {{ roles[scope.row.role_id] }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="profession_id" label="Professione" width="200">
            <template slot-scope="scope">
              <p v-if="professions && scope.row.profession_id">
                {{ professions[scope.row.profession_id] }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            prop="username"
            label="Username"
            min-width="200"
          ></el-table-column>
          <el-table-column
            prop="email"
            label="Email"
            min-width="300"
          ></el-table-column>
          <el-table-column fixed="right" label="Azioni" width="150">
            <template slot-scope="scope">
              <span class="prevent-deleted">
                <el-button
                  type="success"
                  size="small"
                  plain
                  @click="
                    $router.push(
                      '/superadmin/operators/' + scope.row.id + '/edit'
                    )
                  "
                  v-if="scope.row.enabled"
                  ><i class="el-icon-edit"></i
                ></el-button>
                <el-button
                  :loading="buttonLoading"
                  type="danger"
                  size="small"
                  plain
                  @click="confirm(scope.row.id, scope.row.full_name, 'delete')"
                  v-if="scope.row.enabled"
                  ><i class="el-icon-delete"></i
                ></el-button>
                <el-button
                  :loading="buttonLoading"
                  type="success"
                  size="small"
                  plain
                  @click="confirm(scope.row.id, scope.row.full_name, 'restore')"
                  v-if="!scope.row.enabled"
                  ><i class="el-icon-refresh"></i
                ></el-button>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import SuperadminOperatorsService from "@/js/api/services/superadmin/Operators";

export default {
  name: "SuperadminOperators",
  data() {
    return {
      loading: false,
      search: "",
      operators: [],
      roles: [],
      professions: [],
      buttonLoading: false,
      timer: null,
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 210;
    },
  },
  watch: {
    search: {
      handler() {
        if (this.search != "") {
          if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
          }
          this.timer = setTimeout(() => {
            this.fetch();
          }, 300);
        } else {
          this.operators = [];
        }
      },
    },
  },
  methods: {
    async fetch() {
      if (this.search == "") {
        this.operators = [];
        return;
      }
      this.loading = true;
      const response = await SuperadminOperatorsService.getOperators({
        searchString: this.search,
      });
      this.operators = response.data.operators;
      this.roles = response.data.roles;
      this.professions = response.data.professions;
      this.loading = false;
    },
    confirm(id, full_name, action) {
      if (action === "delete") {
        this.$confirm(`Eliminare ${full_name} ?`, "Attenzione", {
          confirmButtonText: "Sì",
          cancelButtonText: "Annulla",
          type: "warning",
        })
          .then(() => {
            this.buttonLoading = true;
            this.delete(id);
          })
          .catch(() => {
            this.abort();
          });
      } else {
        this.$confirm(`Ripristinare ${full_name} ?`, "Attenzione", {
          confirmButtonText: "Sì",
          cancelButtonText: "Annulla",
          type: "warning",
        })
          .then(() => {
            this.buttonLoading = true;
            this.restore(id);
          })
          .catch(() => {
            this.abort();
          });
      }
    },
    async delete(id) {
      await SuperadminOperatorsService.destroyOperator(id);
      this.success();
    },
    async restore(id) {
      await SuperadminOperatorsService.restoreOperator(id);
      this.success();
    },
    success() {
      this.$notify({
        title: "OK",
        message: "Operazione completata",
        type: "success",
      });
      this.fetch();
      this.buttonLoading = false;
    },
    abort() {
      this.$message({
        type: "info",
        message: "Operazione annullata",
      });
      this.loading = false;
      this.buttonLoading = false;
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.enabled == 0) {
        return "deleted-row";
      }
    },
  },
  mounted() {
    // this.fetch();
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  padding: 20px 0;
}
.prevent-deleted * {
  text-decoration: none !important;
}
</style>
