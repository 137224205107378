import _ from 'lodash';

const rules = (numberOfQuestions) => {
    let defaultRule = { required: true, message: "Campo Obbligatorio", trigger: "change" };
    return _.range(numberOfQuestions)
        .reduce((acc, next, idx) => {
            idx++;
            acc[`q${idx}_result`] = defaultRule;
            return acc;
        }, {})
}

const filterResults = (data, specific) => {
    if (specific) {
        return _.pickBy(data, (_, key) => specific.includes(key));
    }
    return _.pickBy(data, (_, key) => key.endsWith("result"));
}


const categories = [
    {
        name: 'CONFUSIONE',
        result: 'q1_result',
        options: [
            {
                label: 'È completamente confuso, sono compromesse la comunicazione e le attività cognitive; la personalità è completamente destrutturata',
                points: 1
            },
            {
                label: 'È chiaramente confuso e, in determinate situazioni, non si comporta come dovrebbe',
                points: 2
            },
            {
                label: 'Appare incerto e dubbioso nonostante sia orientato nel tempo e nello spazio',
                points: 3
            },
            {
                label: 'È in grado di pensare chiaramente, ed ha contatti non alterati con l\'ambiente',
                points: 4
            },
        ]
    },
    {
        name: 'IRRITABILITÀ',
        result: 'q2_result',
        options: [
            {
                label: 'Qualsiasi contatto è causa di irritabilità',
                points: 1
            },
            {
                label: 'Uno stimolo esterno, che non dovrebbe essere provocatorio, produce spesso irritabilità che non sempre è in grado di controllare.',
                points: 2
            },
            {
                label: 'Mostra ogni tanto segni di irritabilità, specie se gli vengono rivolte domande indiscrete',
                points: 3
            },
            {
                label: 'Non mostra segni di irritabilità ed è calmo',
                points: 4
            },
        ]
    },
    {
        name: 'IRREQUIETEZZA',
        result: 'q3_result',
        options: [
            {
                label: 'Cammina incessantemente avanti-indietro ed è incapace , anche per brevi periodi, di stare fermo',
                points: 1
            },
            {
                label: 'È irrequieto, non riesce a star seduto e si muove continuamente; durante la conversazione contorce le mani toccando continuamente gli oggetti a portata di mano',
                points: 2
            },
            {
                label: 'Mostra segni di irrequietezza, si agita e gesticola durante la conversazione; ha difficoltà di mantenere fermi le mani ed i piedi e tocca continuamente vari oggetti',
                points: 3
            },
            {
                label: 'Non mostra nessun segno di irrequietezza motoria, è normalmente attivo con periodi di tranquillità',
                points: 4
            },
        ]
    },
]

export default { rules, filterResults, categories };
