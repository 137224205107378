<script>
export default {
  name: "SelectPatient",
  inheritAttrs: false,
  props: ["value", "defaultValue"],
  data() {
    return {
      loadingSelect: false,
    };
  },
  methods: {
    onChange(x) {
      this.$emit("input", x);
    },
    onClear() {
      this.$emit("input", null);
    },
  },
  computed: {
    patients() {
      return this.$store.state.patients;
    },
  },
};
</script>

<template>
  <div>
    <span>Ospite</span>
    <el-select
      v-bind="$attrs"
      :value="value"
      @change="onChange"
      @clear="onClear"
      clearable
      filterable
      :loading="loadingSelect"
      size="small"
    >
      <el-option
        v-for="item in patients"
        :key="item.id"
        :label="item.surname + ' ' + item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
  </div>
</template>

<style lang="scss" scoped>
</style>