<template>
  <el-dropdown class="arki-notifications" trigger="click">
    <span class="el-dropdown-link">
      <el-badge :value="criticalsCount" class="item" type="warning">
        <el-button
          class="notification-button"
          type="warning"
          icon="el-icon-bell"
          round
        ></el-button>
      </el-badge>
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item>
        <arki-critical-badge
          type="warning"
          action="tasks"
          title="Consegne"
          icon="check"
          event=".task.created"
          @criticals="counter"
        />
      </el-dropdown-item>
      <el-dropdown-item>
        <arki-critical-badge
          type="warning"
          action="exams"
          title="Rilevamenti"
          icon="thermometer-half"
          event=".exam.created"
          @criticals="counter"
        />
      </el-dropdown-item>
      <el-dropdown-item>
        <arki-critical-badge
          type="success"
          action="purges"
          title="Evacuazioni"
          icon="toilet"
          event=".purge.created"
          @criticals="counter"
        />
      </el-dropdown-item>
      <el-dropdown-item>
        <arki-critical-badge
          type="primary"
          action="protections"
          title="Contenzioni"
          icon="shield-alt"
          event=".protection.created"
          @criticals="counter"
        />
      </el-dropdown-item>
      <el-dropdown-item>
        <arki-critical-badge
          type="danger"
          action="medications"
          title="Medicazioni"
          icon="user-injured"
          event=".medication.created"
          @criticals="counter"
        />
      </el-dropdown-item>
      <el-dropdown-item
        v-if="
          user.is_admin ||
          ((user.is_nurse || user.is_doctor) && !user.is_chief_nurse)
        "
      >
        <arki-critical-badge
          type="info"
          action="therapies"
          title="Riv. Prescrizioni"
          icon="prescription-bottle-alt"
          event=".therapy.created"
          @criticals="counter"
        />
      </el-dropdown-item>
      <el-dropdown-item
        v-if="
          user.is_admin ||
          ((user.is_nurse || user.is_doctor) && !user.is_chief_nurse)
        "
      >
        <arki-critical-badge
          type="info"
          action="subministrations"
          title="Somministrazioni"
          icon="prescription-bottle-alt"
          event=".subministration.created"
          @criticals="counter"
        />
      </el-dropdown-item>
      <el-dropdown-item>
        <arki-critical-badge
          type="warning"
          action="pai"
          title="PI/PAI"
          icon="file-alt"
          event=".pai.deadline"
          @criticals="counter"
        />
      </el-dropdown-item>
      <el-dropdown-item>
        <arki-critical-badge
          type="warning"
          action="meals"
          title="Verifica Pasti"
          icon="utensils"
          event=".meals.created"
          @criticals="counter"
        />
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
import ArkiCriticalBadge from "@/js/components/ArkiCriticalBadge";
export default {
  name: "ArkiNotifications",
  data() {
    return {
      criticals: {
        exams: null,
        purges: null,
        protections: null,
        medications: null,
        therapies: null,
        tasks: null,
        pai: null,
        subministrations: null,
      },
    };
  },
  components: {
    ArkiCriticalBadge,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    criticalsCount() {
      if (
        this.criticals.tasks +
          this.criticals.exams +
          this.criticals.purges +
          this.criticals.protections +
          this.criticals.medications +
          this.criticals.therapies +
          this.criticals.pai ==
        0
      )
        return null;
      return (
        this.criticals.tasks +
        this.criticals.exams +
        this.criticals.purges +
        this.criticals.protections +
        this.criticals.medications +
        this.criticals.therapies +
        this.criticals.pai +
        this.criticals.subministrations
      );
    },
  },
  methods: {
    counter(action, value) {
      switch (action) {
        case "tasks":
          this.criticals.tasks = value;
          break;
        case "exams":
          this.criticals.exams = value;
          break;
        case "purges":
          this.criticals.purges = value;
          break;
        case "protections":
          this.criticals.protections = value;
          break;
        case "medications":
          this.criticals.medications = value;
          break;
        case "therapies":
          this.criticals.therapies = value;
          break;
        case "pai":
          this.criticals.pai = value;
          break;
        case "subministrations":
          this.criticals.subministrations = value;
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.arki-notifications {
  position: fixed;
  left: 15px;
  color: #e6a23c !important;
  top: 20px;
}
</style>
