<script>
import { Activity } from "@/js/api/services/activity";
import { v4 as uuid } from "uuid";

export default {
  name: "SelectActivity",
  inheritAttrs: false,
  props: ["label"],
  data() {
    return {
      loading: false,
      options: [],
      selected: null,
    };
  },
  methods: {
    onChange(value) {
      if (value && typeof value === "string") {
        this.handleNewOption(value);
        return;
      }
      this.$emit("input", value);
      this.selected = value;
    },
    handleNewOption(value) {
      if (!this.$attrs.allowCreate) return;
      const id = uuid();
      const newOption = {
        id,
        code: value,
        label: value,
        description: value,
      };
      this.options = [newOption, ...this.options];
      this.$emit("input", newOption);
      this.selected = value;
    },
    onClear() {
      this.$emit("input", null);
    },
    onError(err) {
      this.$message({
        type: "error",
        message: _.get(err, "message", "Failed to retrieve options."),
      });
    },
    getOptions() {
      this.loading = true;
      const params = {
        structure_id: this.$store.state.structureId,
      };
      Activity.list(params)
        .then(({ data }) => (this.options = data))
        .catch((err) => this.onError(err))
        .finally(() => (this.loading = false));
    },
  },
  beforeDestroy() {
    this.options.length = 0;
  },
  created() {
    this.getOptions();
  },
};
</script>

<template>
  <div style="text-align: left">
    <span v-if="label">{{ label }}</span>
    <el-select
      v-bind="$attrs"
      :value="selected"
      @clear="onClear"
      @input="onChange"
      clearable
      filterable
      :loading="loading"
      loading-text="Loading..."
      value-key="id"
      default-first-option
      size="small"
    >
      <el-option
        v-for="item in options"
        :key="`${item.id}`"
        :label="item.label"
        :value="item"
      />
    </el-select>
  </div>
</template>

<style lang="scss" scoped>
</style>