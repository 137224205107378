import Api from "@/js/api/Api";

export default class PatientActivity {
    static endpoint = "patientActivities";

    static list(params) {
        return Api.get(this.endpoint, { params }).then(({ data }) => data);
    }

    static get(id) {
        return Api.get(`${this.endpoint}/${id}`);
    }

    static save(payload) {
        return Api.post(this.endpoint, payload);
    }

    static delete(id) {
        return Api.delete(`${this.endpoint}/${id}`);
    }

    static enable(id) {
        return Api.post(`${this.endpoint}/${id}/enable`);
    }
    
    static replicate(payload) {
        return Api.post(`${this.endpoint}/replicate`, payload);
    }

    static createSpecification(payload) {
        return Api.post("activitySpecifications", payload).then(
            ({ data }) => data
        );
    }

    static exportPdf(params) {
        return Api.get("activitiesExporter/pdf", {
            params,
            responseType: "blob"
        });
    }
}
