import _ from 'lodash';

const rules = (numberOfQuestions) => {
  let defaultRule = { required: true, message: "Campo Obbligatorio", trigger: "change" };
  return _.range(numberOfQuestions)
    .reduce((acc, next, idx) => {
      idx++;
      acc[`q${idx}_result`] = defaultRule;
      return acc;
    }, {})
}

const createResultWithDefaults = (min, max) => {
  return _.range(min, max).reduce((prev, _, idx) => {
    prev[`q${++idx}_result`] = 0;
    return prev;
  }, {})
}

const createResults = (min, max) => {
  return _.range(min, max).map(idx => `q${idx}_result`)
}

const allEmpty = (result) => _.every(_.values(result), x => x == 0);

const filterResults = (data, specific) => {
  if (specific) {
    return _.pickBy(data, (_, key) => specific.includes(key));
  }
  return _.pickBy(data, (_, key) => key.endsWith("result"));
}

const totalOf = (specific, data) => {
  let results = _.values(filterResults(data, specific));
  return results.reduce(_.add, 0);
}

const totals = () => ({
  '0': {done: 0, max: 36},
  '1': {done: 0, max: 30},
  '2': {done: 0, max: 12},
})

const options = [0, 1, 3, 6];

const categories = [
  {
    name: `Attivitá all'interno della struttura`,
    questions: [
      {
        label: 'Lettura',
        result: 'q1_result',
        options
      },
      {
        label: 'Lavori a mano',
        result: 'q2_result',
        options
      },
      {
        label: 'Attivitá e animazione indotte',
        result: 'q3_result',
        options
      },
      {
        label: 'Fa o riceve telefonate',
        result: 'q4_result',
        options
      },
      {
        label: 'Gioca a carte o altri giochi di societá',
        result: 'q5_result',
        options
      },
      {
        label: 'Segue la radio e/o la televisione',
        result: 'q6_result',
        options
      },
    ],
    totalMax: 36
  },
  {
    name: `Contatti sociali all'interno ed all'esterno`,
    questions: [
      {
        label: 'Riceve visite dai familiari',
        result: 'q7_result',
        options
      },
      {
        label: 'Riceve visite da amici e/o conoscenti',
        result: 'q8_result',
        options
      },
      {
        label: 'Conversa con altre persone nella struttura',
        result: 'q9_result',
        options
      },
      {
        label: `Se c'é qualcuno in struttura con cui ha legato gli ha parlato durante lo scorso mese`,
        result: 'q10_result',
        options
      },
      {
        label: `Se c'é qualcuno fuori della struttura con cui ha legato gli ha parlato durante lo scorso mese`,
        result: 'q11_result',
        options
      },
    ],
    totalMax: 30
  },
  {
    name: `Attivitá sociali all'esterno dell'ente`,
    questions: [
      {
        label: 'É uscito a pranzo o cena o per visite',
        result: 'q12_result',
        options
      },
      {
        label: 'É stato ospitato a casa di amici e/o parenti',
        result: 'q13_result',
        options
      },
    ],
    totalMax: 12
  },
]


export default { rules, filterResults, categories, createResultWithDefaults, allEmpty, totalOf, createResults, totals };
