<template>
    <div class="block no-filters">
        <el-row :gutter="20">
            <el-col :span="24" class="margin-top">
                <el-form label-position="top" label-width="100px" :model="form" :rules="rules" ref="form">
                    <el-col :xs="24" :sm="24" :md="6" :lg="6">
                        <el-form-item label="Ruolo" prop="role_id">
                            <el-select v-model="form.role_id" placeholder="Selezionare ruolo">
                                <el-option
                                    v-for="item in roles"
                                    :key="item.id"
                                    :label="item.description"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="6" :lg="6">
                        <el-form-item label="Professione" prop="profession_id">
                            <el-select v-model="form.profession_id" placeholder="Selezionare professione">
                                <el-option
                                    v-for="(item, index) in professions"
                                    :key="index"
                                    :label="item.label"
                                    :value="index"
                                    :disabled="!item.enabled"
                                    >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="6" :lg="6">
                        <el-form-item label="Username LDAP" prop="username">
                            <el-input type="username" v-model="form.username"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="6" :lg="6" >
                         <el-form-item :label="'\u00a0'">
                        <el-button size="large" type="success" @click.native.prevent="submit()" plain :loading="loading"><font-awesome-icon icon="plus" /> Conferma Inserimento Utente</el-button>
                         </el-form-item>
                    </el-col>
                </el-form>
            </el-col>
        </el-row>

    </div>
</template>
<script>
import User from '@/js/api/services/User';
export default {
    name: 'CreateUser',
    data(){
        return{
            loading: false,
            form: {
                structure_id: this.$store.state.structureId
            },
            roles: [],
            professions: [],
            rules: {
                role_id: [ { required: true, message: 'Campo Obbligatorio', trigger: 'blur' } ],
                profession_id: [ { required: true, message: 'Campo Obbligatorio', trigger: 'blur' } ],
                username: [ { required: true, message: 'Campo Obbligatorio', trigger: 'blur' } ],
            },
        }
    },
    computed:{
        response () {
            return this.$store.state.response;
        },
    },
    watch:{
        response(newSucces, oldSuccess){
            this.loading = false;
        }
    },
    methods:{
        init(){
            this.professions = this.$store.state.professions;
            this.roles = this.$store.state.roles;
        },

        submit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.store();
                } else {
                    return false;
                }
            });
        },
        async store(){
            await User.store(this.form);
            this.$emit('usersUpdate');
            this.$router.push({path: '/settings/users'})
        },
    },
    mounted(){
        this.init();
    }
}
</script>
