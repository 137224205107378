<template>
    <div v-loading="loadingData">
        <div v-if="Object.keys(meal).length">
            <el-row :gutter="10" style="margin-bottom: 10px" type="flex" align="middle" justify="space-between">
                <el-col :span="6">
                    <el-button class="float-left" round :disabled="!prevMeal" @click="changeMonitoringMeal('prev')">
                        <font-awesome-icon icon="long-arrow-alt-left" />Indietro
                    </el-button>
                </el-col>
                <el-col :span="12" class="text-center titleCol" :class="{ 'deleted-row': meal.enabled == 0 }">
                    <b class="title">{{ meal.clean_monitoring_start }} -
                        <span v-if="meal.monitoring_end">
                            {{ meal.clean_monitoring_end }}
                        </span>
                        <span v-else style="font-size: 28px"> &#8734; </span>
                    </b>
                </el-col>
                <el-col :span="6">
                    <el-button class="float-right" round :disabled="!nextMeal" @click="changeMonitoringMeal('next')">
                        Avanti
                        <font-awesome-icon icon="long-arrow-alt-right" />
                    </el-button>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-table :data="mealVerification">
                        <el-table-column label="Data" prop="clean_date" width="150" column-key="data" align="center" sortable>
                            <template slot-scope="scope">
                                {{ scope.row.clean_date }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Pasti" column-key="meals" align="center">
                            <template slot="header"> Pasti </template>
                            <template slot-scope="scope">
                                <el-row :gutter="5">
                                    <!-- pranzo e cena -->
                                    <el-col v-for="(foodValue, foodKey) in _.pick(
        config.meal_types,
        [1, 2]
    )" :key="foodKey" :span="24" :md="foodKey == 1 || foodKey == 2 ? 12 : 8" :set="(verification = _.find(scope.row.verification, [
        'meal_type',
        parseInt(foodKey),
    ]))
        " style="margin-bottom: 5px">
                                        <!-- card  -->
                                        <meal-verification-summary-card :meal="meal" :foodValue="foodValue" :foodKey="parseInt(foodKey)" :verification="verification">
                                        </meal-verification-summary-card>
                                    </el-col>
                                    <!-- clearfix -->
                                    <el-col :span="24"></el-col>
                                    <!-- colazione, spuntino, merenda -->
                                    <el-col v-for="(foodValue, foodKey) in _.pick(
        config.meal_types,
        [3, 4, 5]
    )" :key="foodKey" :span="24" :md="8" :set="(verification = _.find(scope.row.verification, [
        'meal_type',
        parseInt(foodKey),
    ]))
        " style="margin-bottom: 5px">
                                        <!-- card  -->
                                        <meal-verification-summary-card :meal="meal" :foodValue="foodValue" :foodKey="parseInt(foodKey)" :verification="verification">
                                        </meal-verification-summary-card>
                                    </el-col>
                                </el-row>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
            </el-row>
        </div>
        <div v-else>
            <el-row :gutter="10" style="margin: 10px 0">
                <el-col :span="24" align="center">
                    - Nessun monitoraggio pasti attivo -
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import MealVerification from "@/js/api/services/MealVerification";
import MealVerificationSummaryCard from "./MealVerificationSummaryCard.vue";
export default {
    name: "MealVerificationSummary",
    props: ["profile_id"],
    components: {
        MealVerificationSummaryCard,
    },
    data() {
        return {
            meal: {},
            mealVerification: [],
            prevMeal: null,
            nextMeal: null,
            loadingData: false,
        };
    },
    computed: {
        config() {
            return this.$store.state.config.meals;
        },
    },
    methods: {
        async fetch(id = null) {
            this.loadingData = true;
            let params = {
                id: id,
            };
            const response = await MealVerification.summary(this.profile_id, params);
            this.meal = response.data.meal ?? {};
            this.mealVerification = response.data.mealVerification ?? [];
            this.prevMeal = response.data.meal_prev ?? null;
            this.nextMeal = response.data.meal_next ?? null;
            this.loadingData = false;
        },
        changeMonitoringMeal(action) {
            if (action == "prev") {
                this.fetch(this.prevMeal);
            } else if (action == "next") {
                this.fetch(this.nextMeal);
            }
        },
    },
    mounted() {
        this.fetch();
    },
};
</script>
<style lang="scss" scoped>
.titleCol {
    padding-top: 9px;
}

.title {
    font-size: 20px;
}
</style>