<template>
  <el-badge :value="badgeCount" class="item" :type="type">
    <el-popover
      placement="right"
      width="520"
      trigger="click"
      ref="popRef"
      :default-sort="{ prop: 'full_patient', order: 'ascending' }"
    >
      <el-table
        :data="patients"
        :fit="true"
        @row-click="rowClick"
        style="cursor: pointer"
      >
        <el-table-column
          width="200"
          prop="full_name"
          label="Ospite"
          v-if="action !== 'protections' && action !== 'medications'"
          sortable
        ></el-table-column>
        <el-table-column
          width="195"
          prop="full_patient"
          label="Ospite"
          v-else
          sortable
        ></el-table-column>

        <!-- TASKS COLUMN -->
        <el-table-column
          width="100"
          prop="type_description"
          label="Tipologia"
          v-if="action === 'tasks'"
        >
        </el-table-column>
        <el-table-column
          width="100"
          prop="description"
          label="Descrizione"
          v-if="action === 'tasks'"
        >
        </el-table-column>
        <el-table-column
          prop="date"
          label="Data"
          width="100"
          v-if="action === 'tasks'"
        >
        </el-table-column>

        <!-- SUBMINISTRATIONS COLUMN -->

        <el-table-column
          width="100"
          prop="therapy_type"
          label="Tipologia"
          v-if="action === 'subministrations'"
        >
        </el-table-column>
        <el-table-column
          prop="orario"
          label="Orario"
          v-if="action === 'subministrations'"
        ></el-table-column>

        <!-- <el-table-column
          width="100"
          prop="subministration_date_time"
          label="Data"
          v-if="action === 'subministrations'"
        >
          <template slot-scope="scope">
            <p v-if="scope.row.subministration_date_time">{{scope.row.subministration_date_time | moment("DD/MM/YYYY")}}</p>
          </template>
</el-table-column> -->

        <!-- EXAMS COLUMN -->
        <el-table-column
          width="270"
          prop="critical"
          label="Criticità"
          v-if="action === 'exams'"
        >
          <template slot-scope="scope">
            <p v-for="item in scope.row.criticals" :key="item.id">
              {{ item.full_date }}
              <br />
              <font-awesome-icon :class="`${type}-text`" :icon="icon" />
              <b>{{ item.type_description }}</b>
              {{ item.value }} {{ item.unity }}
            </p>
          </template>
        </el-table-column>

        <!-- PURGES COLUMN -->
        <el-table-column
          width="270"
          prop="needs_purge_therapy"
          label="Stato Evacuazione"
          v-else-if="action === 'purges'"
        >
          <template slot-scope="scope">
            <p v-if="scope.row.is_purged">
              <b>Terapia Lassativa Somministrata</b>
            </p>
            <p
              v-if="
                scope.row.needs_purge_therapy &&
                scope.row.days_since_last_purge == 'Mai'
              "
            >
              Non ha mai evacuato
            </p>
            <p v-else-if="scope.row.needs_purge_therapy">
              <b>Non evacua da</b>
              {{ scope.row.days_since_last_purge }} giorni
            </p>
          </template>
        </el-table-column>

        <!-- PROTECTION COLUMN -->
        <el-table-column
          prop="category_description"
          label="Contenzione"
          v-else-if="action === 'protections'"
        ></el-table-column>
        <el-table-column
          prop="to_be_verified"
          label="Da Verificare"
          v-if="action === 'protections'"
        >
          <template slot-scope="scope">{{
            scope.row.protection.to_be_verified
          }}</template>
        </el-table-column>

        <!-- MEDICATION COLUMN -->
        <el-table-column
          width="270"
          prop="wound_description"
          label="Lesione"
          v-else-if="action === 'medications'"
        ></el-table-column>
        <el-table-column
          min-width="180"
          prop="clean_date_time"
          label="Da Verificare"
          v-if="action === 'medications'"
        ></el-table-column>

        <!-- PAI COLUMN -->
        <el-table-column
          width="140"
          prop="pai_type"
          label="Tipo"
          v-else-if="action === 'pai'"
        ></el-table-column>
        <el-table-column
          width="140"
          prop="revaluation_at"
          label="In Scadenza"
          v-if="action === 'pai'"
        ></el-table-column>

        <!-- MEALS COLUMN -->
        <el-table-column
          width="140"
          prop="meals_type"
          label="Pasto"
          v-else-if="action === 'meals'"
        ></el-table-column>
        <el-table-column
          prop="orario"
          label="Orario"
          v-if="action === 'meals'"
        ></el-table-column>

        <el-table-column label="Da Verificare" v-if="action === 'therapies'"
          >Prescrizioni in scadenza o scadute</el-table-column
        >
      </el-table>
      <el-divider v-if="action === 'tasks'"></el-divider>
      <div
        v-if="action === 'tasks' && this.oldCriticals > 0"
        style="text-align: center; margin: 10px; font-size: larger"
      >
        <span>
          Ulteriori <b>{{ this.oldCriticals }}</b> consegne da evadere più
          vecchie di 30 giorni!
        </span>
      </div>
      <el-button
        slot="reference"
        class="arki-notification-button"
        type="text"
        @click="dialogVisible = true"
      >
        <font-awesome-icon :icon="icon" />
        {{ title }}
      </el-button>
    </el-popover>
  </el-badge>
</template>
<script>
import Patient from "@/js/api/services/Patient";
import Task from "@/js/api/services/Task";
import Pai from "@/js/api/services/Pai";

export default {
  name: "ActiveCritical",
  props: ["type", "action", "title", "icon", "event"],
  data() {
    return {
      patients: [],
      criticals: null,
      totalCriticals: null,
      oldCriticals: null,
      interval: undefined,
    };
  },
  computed: {
    badgeCount() {
      if (this.action == "tasks") {
        if (this.criticals === null && this.oldCriticals > 0) {
          return "...";
        } else if (this.criticals > 0 && this.oldCriticals) {
          return this.oldCriticals + " ...";
        }
      }
      return this.criticals;
    },
  },
  methods: {
    async fetch() {
      switch (this.action) {
        case "tasks":
          var response = await Task.count();
          var totalResponse = await Task.totalCount();
          this.totalCriticals = totalResponse.data.count;
          break;
        case "exams":
          var response = await Patient.critical();
          break;
        case "purges":
          var response = await Patient.purged();
          break;
        case "protections":
          const xs = this.$store.state.patients || [];
          const patients = [...new Set(xs.map((x) => x.id))];
          var response = await Patient.protection({ patients });
          break;
        case "medications":
          var response = await Patient.wound();
          break;
        case "therapies":
        case "subministrations":
          var response =
            this.action == "therapies"
              ? await Patient.therapy()
              : await Patient.subministrations();
          break;
        case "pai":
          var response = await Pai.deadline();
          break;
        case "meals":
          var response = await Patient.meals();
      }
      this.patients = response.data;
      this.criticals = this.patients.length;
      if (this.totalCriticals > 0) {
        this.oldCriticals = this.totalCriticals - this.criticals;
      }
      if (this.criticals == 0) {
        this.criticals = null;
      }
      this.$emit("criticals", this.action, this.criticals);
    },

    getActionPath(row) {
      return _.cond([
        [
          _.matches({ action: "subministrations" }),
          _.constant("/subministrations"),
        ],
        [_.matches({ action: "tasks" }), _.constant("/tasks")],
        [_.matches({ action: "exams" }), _.constant("/exams")],
        [_.matches({ action: "purges" }), _.constant("/purges")],
        [_.matches({ action: "protections" }), _.constant("/protections")],
        [
          _.matches({ action: "pai" }),
          _.constant(`/patients/${row.patient_id}`),
        ],
        [_.matches({ action: "therapies" }), _.constant(`/patients/${row.id}`)],
        [_.matches({ action: "medications" }), _.constant("/medications")],
        [_.matches({ action: "meals" }), _.constant("/meals/verification")],
      ])(this);
    },
    getActionQuery(row) {
      return _.cond([
        [
          _.matches({ action: "subministrations" }),
          _.constant({
            patient_id: Number(row.patient_id),
            therapy_id: row.therapy_id,
            time: row.orario,
            t: new Date().getTime(),
          }),
        ],
        [
          _.matches({ action: "tasks" }),
          _.constant({
            patient_id: row.patient_id,
            date: row.date_time,
            task_id: row.id,
            option: [null, 0],
            t: new Date().getTime(),
          }),
        ],
        [
          _.matches({ action: "exams" }),
          _.constant({
            patient_id: row.id,
            date: _.get(_.head(row.criticals), "date_time"),
            exam_id: _.get(_.head(row.criticals), "exam_id"),
            option: 1,
            t: new Date().getTime(),
          }),
        ],
        [
          _.matches({ action: "purges" }),
          _.constant({
            patient_id: row.id,
            patient_name: row.full_name,
            t: new Date().getTime(),
          }),
        ],
        [
          _.matches({ action: "medications" }),
          _.constant({
            patient_id: row.patient_id,
            medication_id: row.id,
            date: row.date_time,
            t: new Date().getTime(),
          }),
        ],
        [
          _.matches({ action: "protections" }),
          _.constant({
            patient_id: row.patient_id,
            protection_id: row.protection_id,
            verification_id: row.id,
            date: row.date_time,
            option: 0,
            t: new Date().getTime(),
          }),
        ],
        [
          _.matches({ action: "pai" }),
          _.constant({
            t: new Date().getTime(),
            section: "pai",
          }),
        ],
        [
          _.matches({ action: "therapies" }),
          _.constant({
            t: new Date().getTime(),
            section: "terapie",
          }),
        ],
        [
          _.matches({ action: "meals" }),
          _.constant({
            t: new Date().getTime(),
            mealId: row.meal_id,
            mealType: row.meals_type_id,
            patientId: row.patient_id,
          }),
        ],
      ])(this);
    },
    rowClick(row) {
      let params = {
        path: this.getActionPath(row),
        query: this.getActionQuery(row),
      };
      if (params.path) {
        this.$router.push({ ...params });
      }
      this.$refs.popRef && this.$refs.popRef.doClose();
    },
    broadcast() {
      this.$echo
        .channel(`arkicare-activity.${this.$store.state.departmentId}`)
        .listen(this.event, (e) => {
          this.$log("[Ark Event]", e);
          this.fetch();
        });
    },
  },
  created() {
    this.fetch();
    this.broadcast();
  },
};
</script>

<style lang="scss" scoped>
.arki-notification-button {
  color: #1f2f3d !important;
  width: 100%;
  text-align: left;

  svg {
    margin-right: 5px;
  }
}
</style>
