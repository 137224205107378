<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20" type="flex" justify="center" align="center">
        <el-col :span="24">
          <el-row type="flex" justify="center">
            <el-collapse accordion class="collapse">
              <el-card class="box-card" shadow="never">
                <el-form-item prop="q1_result">
                  <el-collapse-item name="1" title="1. Parametro Uditivo">
                    <el-divider />

                    <el-form-item>
                      <el-col :span="24">
                        <el-col :span="6">
                          <div>
                            <span class="subhead">Stimolo</span>
                          </div>
                          <span>Suono di campana per 5 secondi.Ripetere con 10 secondi di intervallo</span>
                        </el-col>
                        <el-col :span="4">
                          <div>
                            <center class="subhead">Ripetizioni</center>
                          </div>
                          <center>3</center>
                        </el-col>

                        <el-col :span="6">
                          <div>
                            <span class="subhead">Risposta Misurata</span>
                          </div>
                          <span>Apertura degli occhi o orientamento verso il suono</span>
                        </el-col>

                        <el-col :span="8">
                          <div>
                            <span class="subhead">Punteggio e criteri:</span>
                          </div>
                          <el-col :span="16">
                            <el-radio-group v-model="result.q1_result" size="medium">
                              <el-form-item>
                                <el-radio :label="0">&ge; 3 volte</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="2">1 o 2 volte</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="4">Nessuna risposta</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-form-item>
                  </el-collapse-item>
                </el-form-item>
              </el-card>

              <el-card class="box-card" shadow="never">
                <el-form-item prop="q2_result">
                  <el-collapse-item name="2" title="2. Risposta su comando co9n preparazione (*)">
                    <el-divider />

                    <el-form-item>
                      <el-col :span="24">
                        <el-col :span="6">
                          <div>
                            <span class="subhead">Stimolo</span>
                          </div>
                          <span>Chiedere di aprire o chiudere gli occhi, la bocca, muovere le dita o le mani</span>
                        </el-col>
                        <el-col :span="4">
                          <div>
                            <center class="subhead">Ripetizioni</center>
                          </div>
                          <center>3</center>
                        </el-col>

                        <el-col :span="6">
                          <div>
                            <span class="subhead">Risposta Misurata</span>
                          </div>
                          <span>Risposta a comando</span>
                        </el-col>

                        <el-col :span="8">
                          <div>
                            <span class="subhead">Punteggio e criteri:</span>
                          </div>
                          <el-col :span="16">
                            <el-radio-group v-model="result.q2_result" size="medium">
                              <el-form-item>
                                <el-radio :label="0">Risposta 2 o 3 volte</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="2">Risposta inconsistente</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="4">Nessuna risposta</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-form-item>
                  </el-collapse-item>
                </el-form-item>
              </el-card>

              <el-card class="box-card" shadow="never">
                <el-form-item prop="q3_result">
                  <el-collapse-item name="3" title="3. Risposta visiva con preparazione (**) A">
                    <el-divider />
                    <el-form-item
                      label="La persona deve essere capace dì aprire gli occhi. Se non è in grado di aprire gli occhi, assegnare un punteggio di 4 agli item 3-4-5, segnalando qui questa osservazione:"
                    >
                      <el-input placeholder="Osservazione" type="textarea" v-model="result.q3_oss"></el-input>
                    </el-form-item>
                    <el-divider />

                    <el-form-item>
                      <el-col :span="24">
                        <el-col :span="6">
                          <div>
                            <span class="subhead">Stimolo</span>
                          </div>
                          <span>Lampo di luce verso il viso. 1 secondo per 5 volte. Spostare a destra, sinistra, in alto e in basso ad ogni tentativo.</span>
                        </el-col>
                        <el-col :span="3">
                          <div>
                            <center class="subhead">Ripetizioni</center>
                          </div>
                          <center>5</center>
                        </el-col>

                        <el-col :span="5">
                          <div>
                            <span class="subhead">Risposta Misurata</span>
                          </div>
                          <span>Fissazione sostenuta o evitamento</span>
                        </el-col>

                        <el-col :span="10">
                          <div>
                            <span class="subhead">Punteggio e criteri:</span>
                          </div>
                          <el-col :span="16">
                            <el-radio-group v-model="result.q3_result" size="medium">
                              <el-form-item>
                                <el-radio :label="0">Fissazione sostenuta o evitamento per 3 volte</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="2">Fissazione parziale per 1 o 2 volte</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="4">Nessuna risposta</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-form-item>
                  </el-collapse-item>
                </el-form-item>
              </el-card>

              <el-card class="box-card" shadow="never">
                <el-form-item prop="q4_result">
                  <el-collapse-item name="4" title="4. Risposta visiva con preparazione B">
                    <el-divider />

                    <el-form-item>
                      <el-col :span="24">
                        <el-col :span="6">
                          <div>
                            <span class="subhead">Stimolo</span>
                          </div>
                          <span>Dire al paziente: "Guardami negli occhi". Muovere il viso di 20° da un Iato all'altro.</span>
                        </el-col>
                        <el-col :span="3">
                          <div>
                            <center class="subhead">Ripetizioni</center>
                          </div>
                          <center>5</center>
                        </el-col>

                        <el-col :span="5">
                          <div>
                            <span class="subhead">Risposta Misurata</span>
                          </div>
                          <span>Fissazione o inseguimento</span>
                        </el-col>

                        <el-col :span="8">
                          <div>
                            <span class="subhead">Punteggio e criteri:</span>
                          </div>
                          <el-col :span="16">
                            <el-radio-group v-model="result.q4_result" size="medium">
                              <el-form-item>
                                <el-radio :label="0">Inseguimento sostenuto per 3 volte</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="2">Inseguimento parziale per 1/2 volte</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="4">Nessun Inseguimento</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-form-item>
                  </el-collapse-item>
                </el-form-item>
              </el-card>

              <el-card class="box-card" shadow="never">
                <el-form-item prop="q5_result">
                  <el-collapse-item name="5" title="5. Risposta alla minaccia">
                    <el-divider />

                    <el-form-item>
                      <el-col :span="24">
                        <el-col :span="6">
                          <div>
                            <span class="subhead">Stimolo</span>
                          </div>
                          <span>Muovere rapidamente le mani verso gli occhi, fino a una distanza di 1-3 cm</span>
                        </el-col>
                        <el-col :span="4">
                          <div>
                            <center class="subhead">Ripetizioni</center>
                          </div>
                          <center>3</center>
                        </el-col>

                        <el-col :span="6">
                          <div>
                            <span class="subhead">Risposta Misurata</span>
                          </div>
                          <span>Ammiccamento</span>
                        </el-col>

                        <el-col :span="8">
                          <div>
                            <span class="subhead">Punteggio e criteri:</span>
                          </div>
                          <el-col :span="16">
                            <el-radio-group v-model="result.q5_result" size="medium">
                              <el-form-item>
                                <el-radio :label="0">Chiude gli occhi per 3 volte</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="2">Chiude gli occhi per 1 o 2 volte</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="4">Non chiude gli occhi</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-form-item>
                  </el-collapse-item>
                </el-form-item>
              </el-card>

              <el-card class="box-card" shadow="never">
                <el-form-item prop="q6_result">
                  <el-collapse-item name="6" title="6. Risposta olfattiva">
                    <el-divider />
                    <el-form-item>
                      <el-col :span="24">
                        <el-col :span="5">
                          <div>
                            <span class="subhead">Stimolo</span>
                          </div>
                          <span>Capsula o bottiglia di ammoniaca sotto il naso per 2 secondi</span>
                        </el-col>
                        <el-col :span="4">
                          <div>
                            <center class="subhead">Ripetizioni</center>
                          </div>
                          <center>3</center>
                        </el-col>

                        <el-col :span="6">
                          <div>
                            <span class="subhead">Risposta Misurata</span>
                          </div>
                          <span>Allontanamento o altre risposte collegate allo stimolo</span>
                        </el-col>

                        <el-col :span="9">
                          <div>
                            <span class="subhead">Punteggio e criteri:</span>
                          </div>
                          <el-col :span="16">
                            <el-radio-group v-model="result.q6_result" size="medium">
                              <el-form-item>
                                <el-radio :label="0">
                                  Risponde 2 o 3 volte prontamente
                                  <br />(entro 3 secondi dallo stimolo)
                                </el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="2">
                                  Allontanamento lento o parziale
                                  <br />o smorfia per 1 volta
                                </el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="4">Nessuna smorfia o allontanamento</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-form-item>
                  </el-collapse-item>
                </el-form-item>
              </el-card>

              <el-card class="box-card" shadow="never">
                <el-form-item prop="q7_result">
                  <el-collapse-item name="7" title="7. Risposta tattile A">
                    <el-divider />

                    <el-form-item>
                      <el-col :span="24">
                        <el-col :span="6">
                          <div>
                            <span class="subhead">Stimolo</span>
                          </div>
                          <span>Toccare o picchiettare rapidamente la spalla per 3 volte senza parlare. Ripetere da entrambi i Iati.</span>
                        </el-col>
                        <el-col :span="4">
                          <div>
                            <center class="subhead">Ripetizioni</center>
                          </div>
                          <center>3</center>
                        </el-col>

                        <el-col :span="6">
                          <div>
                            <span class="subhead">Risposta Misurata</span>
                          </div>
                          <span>Orientamento delia testa o degli occhi o movimento della spalla successivo al tocco.</span>
                        </el-col>

                        <el-col :span="8">
                          <div>
                            <span class="subhead">Punteggio e criteri:</span>
                          </div>
                          <el-col :span="16">
                            <el-radio-group v-model="result.q7_result" size="medium">
                              <el-form-item>
                                <el-radio :label="0">
                                  Si orienta verso il punto di
                                  <br />confatto per 2 o 3 volte
                                </el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="2">
                                  Si orienta parzialmente
                                  <br />per 1 volta
                                </el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="4">
                                  Nessuna risposta
                                  <br />o orientamento
                                </el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-form-item>
                  </el-collapse-item>
                </el-form-item>
              </el-card>

              <el-card class="box-card" shadow="never">
                <el-form-item prop="q8_result">
                  <el-collapse-item name="8" title="8. Risposta tattile B">
                    <el-divider />

                    <el-form-item>
                      <el-col :span="24">
                        <el-col :span="6">
                          <div>
                            <span class="subhead">Stimolo</span>
                          </div>
                          <span>Toccare entrambe le narici con un tampone nasale (solo l'Imbocco, non penetrare in profondità)</span>
                        </el-col>
                        <el-col :span="4">
                          <div>
                            <center class="subhead">Ripetizioni</center>
                          </div>
                          <center>3</center>
                        </el-col>

                        <el-col :span="6">
                          <div>
                            <span class="subhead">Risposta Misurata</span>
                          </div>
                          <span>Allentamento, ammiccamento o contrazione della bocca</span>
                        </el-col>

                        <el-col :span="8">
                          <div>
                            <span class="subhead">Punteggio e criteri:</span>
                          </div>
                          <el-col :span="16">
                            <el-radio-group v-model="result.q8_result" size="medium">
                              <el-form-item>
                                <el-radio :label="0">
                                  Risposta chiara e rapida
                                  <br />(entro 2 secondi) per 2 o 3 volte
                                </el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="2">Risposta parziale o ritardata per 1 volta</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="4">Nessuna risposta</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-form-item>
                  </el-collapse-item>
                </el-form-item>
              </el-card>

              <el-card class="box-card" shadow="never">
                <el-form-item prop="q9_result">
                  <el-collapse-item name="9" title="9. Risposta al dolore A">
                    <el-divider />

                    <el-form-item
                      label="Attendere 10 secondi per la risposta. Se esistono lesioni spinali segnalarlo qui e andare allo stimolo n. 10."
                    >
                      <el-input placeholder="Osservazione" type="textarea" v-model="result.q9_oss"></el-input>
                    </el-form-item>
                    <el-divider />

                    <el-form-item>
                      <el-col :span="24">
                        <el-col :span="6">
                          <div>
                            <span class="subhead">Stimolo</span>
                          </div>
                          <span>Stringere con forza la punta di un dito; premere con una matita di legno l'unghia. Ripetere da entrambi I lati.</span>
                        </el-col>
                        <el-col :span="4">
                          <div>
                            <center class="subhead">Ripetizioni</center>
                          </div>
                          <center>3</center>
                        </el-col>

                        <el-col :span="6">
                          <div>
                            <span class="subhead">Risposta Misurata</span>
                          </div>
                          <span>Vedi criteri di punteggio</span>
                        </el-col>

                        <el-col :span="8">
                          <div>
                            <span class="subhead">Punteggio e criteri:</span>
                          </div>
                          <el-col :span="16">
                            <el-radio-group v-model="result.q9_result" size="medium">
                              <el-form-item>
                                <el-radio :label="0">Retrazione per 2 o 3 volte</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="2">
                                  Agitazione generale o
                                  <br />movimenti aspecifici per 1 volta
                                </el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="4">Nessuna risposta</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-form-item>
                  </el-collapse-item>
                </el-form-item>
              </el-card>

              <el-card class="box-card" shadow="never">
                <el-form-item prop="q10_result">
                  <el-collapse-item name="10" title="10. Risposta al dolore B">
                    <el-divider />

                    <el-form-item>
                      <el-col :span="24">
                        <el-col :span="6">
                          <div>
                            <span class="subhead">Stimolo</span>
                          </div>
                          <span>Pizzicare o tirare con forza il lobo dell'orecchio. Ripetere 3 volte da ogni lato.</span>
                        </el-col>
                        <el-col :span="4">
                          <div>
                            <center class="subhead">Ripetizioni</center>
                          </div>
                          <center>3</center>
                        </el-col>

                        <el-col :span="6">
                          <div>
                            <span class="subhead">Risposta Misurata</span>
                          </div>
                          <span>Retrazione o altre risposte collegate allo stimolo</span>
                        </el-col>

                        <el-col :span="8">
                          <div>
                            <span class="subhead">Punteggio e criteri:</span>
                          </div>
                          <el-col :span="16">
                            <el-radio-group v-model="result.q10_result" size="medium">
                              <el-form-item>
                                <el-radio :label="0">Risponde per 2 o 3 volte</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="2">
                                  Agitazione generale o
                                  <br />movimenti aspecifici per 1 volta
                                </el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="4">Nessuna risposta</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-form-item>
                  </el-collapse-item>
                </el-form-item>
              </el-card>

              <el-card class="box-card" shadow="never">
                <el-form-item prop="q11_result">
                  <el-collapse-item name="11" title="11. Vocalizzazione">
                    <el-divider />

                    <el-form-item>
                      <el-col :span="24">
                        <el-col :span="6">
                          <div>
                            <span class="subhead">Stimolo</span>
                          </div>
                          <span>Nessuno (assegnare il punteggio alla riposta migliore)</span>
                        </el-col>
                        <el-col :span="4">
                          <div>
                            <center class="subhead">Ripetizioni</center>
                          </div>
                          <center>-</center>
                        </el-col>

                        <el-col :span="6">
                          <div>
                            <span class="subhead">Risposta Misurata</span>
                          </div>
                          <span>Vedi criteri di punteggio</span>
                        </el-col>

                        <el-col :span="8">
                          <div>
                            <span class="subhead">Punteggio e criteri:</span>
                          </div>
                          <el-col :span="16">
                            <el-radio-group v-model="result.q11_result" size="medium">
                              <el-form-item>
                                <el-radio :label="0">Parole spontanee</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="2">Vocalizzazioni non verbali</el-radio>
                              </el-form-item>

                              <el-form-item>
                                <el-radio :label="4">Nessun suono</el-radio>
                              </el-form-item>
                            </el-radio-group>
                          </el-col>
                        </el-col>
                      </el-col>
                    </el-form-item>
                  </el-collapse-item>
                </el-form-item>
              </el-card>

              <el-form-item
                label="Includere cambiamenti importanti nelle condizioni fisiche, come infezioni, idrocefalo, convulsioni, traumi ulteriori..."
              >
                <el-input placeholder="Commenti" type="textarea" v-model="result.note"></el-input>
              </el-form-item>
            </el-collapse>
          </el-row>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item label="Stato">
                <el-input :disabled="true" v-model="totalDescription.status" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Punteggio totale CNC">
                <el-input v-model="form.total" :disabled="true" />
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="Numero di stimoli registrati (B)">
                <el-input :disabled="true" v-model="stimoliRegistrati" />
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="Media del punteggio CNC (A/B)">
                <el-input :disabled="true" v-model="totalDescription.average" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Livello CNC">
                <el-input :disabled="true" v-model="totalDescription.level" />
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults, createResults } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Rncs",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 44
      },
      result: {},
      // testRules: rules(11),
      testRules: {},
      stimoliRegistrati: 0
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let { status, level, average, description } = this.totalDescription;
          let result = {
            ...this.result,
            total_status: status,
            total_level: level,
            total_average: average,
            total_description: description
          };
          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
    totalDescription() {
      let calc = { total: 0 };
      if (this.stimoliRegistrati !== 0) {
        calc.total = _.divide(this.form.total, this.stimoliRegistrati);
      }

      return _.cond([
        [
          _.conforms({ total: n => _.inRange(n, 0.0, 0.9) }),
          _.constant({
            level: "0",
            status: "NON COMA",
            average: calc.total,
            description: "Livello 0 | Stato NON COMA"
          })
        ],
        [
          _.conforms({ total: n => _.inRange(n, 0.9, 2.01) }),
          _.constant({
            level: "1",
            status: "VICINO AL COMA",
            average: calc.total,
            description: "Livello 1 | Stato VICINO AL COMA"
          })
        ],
        [
          _.conforms({ total: n => _.inRange(n, 2.01, 2.9) }),
          _.constant({
            level: "2-",
            status: "COMA MODERATO",
            average: calc.total,
            description: "Livello 2 - | Stato COMA MODERATO"
          })
        ],
        [
          _.conforms({ total: n => _.inRange(n, 2.9, 3.5) }),
          _.constant({
            level: "3",
            status: "COMA MARCATO",
            average: calc.total,
            description: "Livello 3 | Stato COMA MARCATO"
          })
        ],
        [
          _.conforms({ total: n => _.inRange(n, 3.5, 4.01) }),
          _.constant({
            level: "4",
            status: "COMA ESTREMO",
            average: calc.total,
            description: "Livello 4 | Stato COMA ESTREMO"
          })
        ]
      ])(calc);
    }
  },
  watch: {
    result: {
      handler: function() {
        this.$refs["form"].clearValidate();
        let results = _.values(filterResults(this.result));
        this.stimoliRegistrati = _.size(results);
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
  margin-bottom: 15px;
}

.el-row {
  margin-bottom: 20px;
}

.collapse-header {
  font-size: 16px;
}
.head {
  padding: 4px;
}
.collapse {
  width: 98%;
}
.subhead {
  font-size: 0.8rem;
  font-weight: bold;
  margin-right: 0.2rem;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>