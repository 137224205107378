<template>
    <div>
        <el-input v-model="search" size="large" placeholder="Cerca ospite" class="search-input"/>
        <div class="block with-search" >
            <el-table
                :data="patients.filter(data => !search || data.full_name.toLowerCase().includes(search.toLowerCase()))"
                :max-height="maxHeight"
                :row-class-name="rowClass"
                :fit="true"
                :show-header="false"
            >
                <el-table-column width="80">
                    <template slot-scope="scope">
                        <el-avatar size="large" shape="square" v-if="!scope.row.registry.image_path"><font-awesome-icon icon="camera-retro" size="2x"/></el-avatar>
                        <el-avatar shape="square" size="large" fit="fill" :src="`/app/public/${scope.row.registry.image_path}`" v-else></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column label="Ospite" prop="full_name" sortable>
                    <template slot-scope="scope">
                        {{scope.row.full_name}}
                        <el-tooltip content="Anagrafica Mancante" placement="bottom" effect="light" v-if="scope.row.has_missing_info">
                            <font-awesome-icon icon="exclamation" class="danger-text" size="2x" />
                        </el-tooltip>
                        <el-tooltip placement="right" v-if="scope.row.has_purge_critical">
                            <div slot="content">
                                <p v-if="scope.row.is_purged">Terapia Lassativa</p>
                                <p v-if="scope.row.purge_limit">Limite Evacuazione</p>
                                <p v-if="scope.row.needs_purge_therapy && scope.row.days_since_last_purge == 'Mai'">Non ha mai evacuato</p>
                                <p v-else-if="scope.row.needs_purge_therapy"><b>Non evacua da</b> {{scope.row.days_since_last_purge}} giorni</p>
                            </div>
                            <font-awesome-icon class="success-text tooltip-icon" icon="toilet" size="2x"/>
                        </el-tooltip>
                        <el-tooltip content="Rilevamento Critico" placement="bottom" effect="light" v-if="scope.row.critical.length">
                            <font-awesome-icon icon="thermometer-half" class="warning-text tooltip-icon" size="2x"/>
                        </el-tooltip>
                        <el-tooltip content="Ospite Assente" placement="bottom" effect="light" v-if="scope.row.absence_info.absence_status">
                            <font-awesome-icon icon="calendar-times" class="danger-text tooltip-icon" size="2x"/>
                        </el-tooltip>
                        <el-tooltip content="Terapie in scadenza" placement="bottom" effect="light" v-if="scope.row.has_expired_therapy">
                            <font-awesome-icon icon="pills" class="danger-text tooltip-icon" size="2x"/>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column align="right">
                    <template slot-scope="scope">
                        <el-button size="large" type="primary" plain @click="scheda(scope.row.id)">Scheda</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import Patient from '@/js/api/services/Patient';
export default {
    name: 'Patients',
    data(){
        return{
            search: '',
            patients: this.$store.state.patients,
        }
    },
    computed:{
        maxHeight() { return window.innerHeight - 200; },
    },
    methods:{
        rowClass({row, rowIndex}) {
            if(row.critical.length){
                return 'warning-row'
            }
        },
        scheda(id){
            this.loading = true;
            this.$router.push(`/patients/${id}`);
        }
    }
}
</script>
<style lang="scss" scoped>
    .el-avatar{
        width: 60px;
        height: 60px;
        svg{
            margin-top: 15px;
        }
    }

    .search-input{
        padding: 10px;
    }

    .tooltip-icon{
        margin: 0px 5px 0px 5px;
    }

    .cell{
        svg{
            margin-right: 10px;
            margin-left: 10px;
        }
    }
</style>
