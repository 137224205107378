<template>

  <el-row style="margin-top: 0px;font-size: 12px">
    <h4 class="title">DIAGNOSI</h4>
    <el-col :span="6">
      <el-row>
        <el-col><span class="key">n.1</span>: <span>{{_.get(data, 'diagnosi1')}}</span></el-col>
        <el-col><span class="key">n.2</span>: <span>{{_.get(data, 'diagnosi2')}}</span></el-col>
      </el-row>
    </el-col>
    <el-col :span="6">
      <el-row>
        <el-col><span class="key">n.3</span>: <span>{{_.get(data, 'diagnosi3')}}</span></el-col>
        <el-col><span class="key">n.4</span>: <span>{{_.get(data, 'diagnosi4')}}</span></el-col>
      </el-row>
    </el-col>
    <el-col :span="6">
      <el-row>
        <el-col><span class="key">n.5</span>: <span>{{_.get(data, 'diagnosi5')}}</span></el-col>
        <el-col><span class="key">n.6</span>: <span>{{_.get(data, 'diagnosi6')}}</span></el-col>
      </el-row>
    </el-col>
    <el-col :span="6">
      <el-row>
        <el-col><span class="key">n.7</span>: <span>{{_.get(data, 'diagnosi7')}}</span></el-col>
        <el-col><span class="key">n.8</span>: <span>{{_.get(data, 'diagnosi8')}}</span></el-col>
      </el-row>
    </el-col>
  </el-row>

</template>
<script>

export default {
  name: "Diagnosi",
  props: ["data"],
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.title {
  border: 0.5px solid #9C9FA5; 
  padding: 2px;
  border-radius: 2px;
}
.key {
  font-weight: bold;
}
</style>