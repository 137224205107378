<template>
  <md-toolbar class="md-primary md-large md-primary-dark">
    <div class="arki-department-header">
        <el-button type="primary" @click="admin" size="small" icon="el-icon-school">Strutture</el-button>
        <el-button class="button-toolbar" size="small" icon="el-icon-user">{{user.surname+' '+user.name}}</el-button>
        <el-button type="danger" @click="logout" size="small" icon="el-icon-switch-button">Esci</el-button>
    </div>

    <div class="md-toolbar-row">
      <div class="md-title">{{$route.name}}</div>
    </div>
  </md-toolbar>
</template>
<script>
    import Auth from '@/js/api/services/Auth';

    export default {
        name: "ArkiToolbarSuperadmin",
        data(){
            return{
                user: [],
            }
        },
        methods: {
            async fetch(){
                const response = await Auth.getUser();
                this.user = response.data.data;
            },
            logout() {
                this.$confirm("Confermi di voler uscire?", "Attenzione", {
                    confirmButtonText: "Sì",
                    cancelButtonText: "Annulla",
                    type: "success"
                })
                .then(() => {
                    this.$auth.logout();
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "Operazione annullata"
                    });
                });
            },
            admin() {
                this.$confirm("Vuoi proseguire la navigazione in una struttura?", "Attenzione", {
                    confirmButtonText: "Sì",
                    cancelButtonText: "Annulla",
                    type: "success"
                })
                .then(() => {
                    this.$router.push({ name: "Login" });
                })
                .catch(() => {
                    this.$message({
                    type: "info",
                    message: "Operazione annullata"
                    });
                });
            }
        },
        mounted(){
            this.fetch();
        }
    };
</script>

<style lang="scss">
    .arkiexit {
        position: fixed;
        right: 15px;
        padding: 0 !important;
        height: 45px;
        width: 45px;
        background: transparent;
        border: none;
        text-align: center;
        color: #e6a23c !important;
        margin-right: 6px;
    }
    .arki-department-header {
        z-index: 2;
        position: fixed;
        font-weight: bold;
        color: white;
        background: #123;
        padding: 6px;
        height: 50px;
        line-height: 41px;
        text-align: center;
        width: 100%;
        top: 0px;
        left: 0px;

        .username-toolbar {
            color: #fff !important;
            background: transparent;
            border-color: #fff !important;
        }
    }

    .page-title {
        margin: 0 auto;
        font-size: 20px;
    }
    .user-button:hover,
    .user-button:focus {
        background: rgba(255, 255, 255, 0.1) !important;
        border: 1px solid #dcdfe6 !important;
        color: #606266 !important;
    }

    .department-select {
        width: auto !important;
        .el-input {
            input {
            color: white;
            background-color: transparent !important;
            padding-right: 0px;
            }
            .el-input__suffix {
            display: none;
            }
        }
    }
    .el-button+.el-button {
        margin-left: 0;
    }
</style>
