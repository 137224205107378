<template>
  <el-form
    label-position="top"
    label-width="80px"
    :model="form"
    :rules="rules"
    ref="form"
  >
    <el-row class="form-header" :gutter="5">
      <el-col :span="8">
        <el-form-item label="Data" prop="date">
          <el-date-picker
            v-model="form.date"
            :editable="false"
            type="date"
            placeholder="Scegli data"
            :picker-options="pickerOptions"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Orario" prop="time">
          <el-time-picker
            v-model="form.time"
            :editable="false"
            format="HH:mm"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="Seleziona Orario"
          ></el-time-picker>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Ospite" prop="patient_id">
          <el-select
            v-model="form.patient_id"
            placeholder="Seleziona Ospite"
            filterable
            clearable
          >
            <el-option
              v-for="item in patients"
              :key="item.id"
              :label="item.full_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <div class="block with-form-header" v-loading="loading">
        <el-row :gutter="20" v-if="form.patient_id">
            <el-col :span="24">
            <span v-if="showAbsentPatientAlert" style="float: right">
                <font-awesome-icon icon="exclamation" class="danger-text" />
                <font style="color: red"
                >Attenzione: l'ospite è attualmente assente</font
                >
            </span>
            <span v-if="showOldAbsencePatientAlert" style="float: right">
                <font-awesome-icon icon="exclamation" class="danger-text" />
                <font style="color: red"
                    >Attenzione: l'ospite era assente in questo periodo</font
                >
            </span>
          <el-upload
            ref="uploader"
            drag
            :action="`/api/medications/${form.patient_id}/upload`"
            :auto-upload="false"
            :on-change="handleUpload"
            multiple
            :limit="5"
            :with-credentials="true"
            :headers="uploadHeaders"
            list-type="picture"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Foto della Lesione</div>
          </el-upload>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Diagnosi" prop="diagnosi_etiologica">
            <el-select v-model="form.diagnosi_etiologica" filterable clearable>
              <el-option
                v-for="item in woundTypes"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Sede Lesione" prop="sede_lesione">
            <el-select
              v-model="form.sede_lesione"
              filterable
              clearable
              allow-create
            >
              <el-option
                v-for="(item, index) in config.sedi_lesione"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-divider content-position="right">Medicazione</el-divider>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Stadio (NPUAP)" prop="stadio_npuap">
            <el-select v-model="form.stadio_npuap" filterable clearable>
              <el-option
                v-for="(item, index) in config.stadio"
                :key="index"
                :label="item"
                :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Cute Perilesionale" prop="cute_perilesionale">
            <el-select v-model="form.cute_perilesionale" filterable clearable>
              <el-option
                v-for="(item, index) in config.cute_perilesionale"
                :key="index"
                :label="item"
                :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-divider content-position="right">Esami Ematochimici</el-divider>
        </el-col>
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
          <el-form-item label="RBC (g/dL)" prop="rbc">
            <el-input-number
              v-model.number="form.rbc"
              :min="0"
              :step="1"
              type="number"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
          <el-form-item label="Albumina  (g/dL)" prop="albumina">
            <el-input-number
              v-model.number="form.albumina"
              :min="0"
              :step="1"
              type="number"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
          <el-form-item label="HGB (g/dL)" prop="hgb">
            <el-input-number
              v-model.number="form.hgb"
              :min="0"
              :step="1"
              type="number"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
          <el-form-item label="Protidemia  (g/dL)" prop="protidemia">
            <el-input-number
              v-model.number="form.protidemia"
              :min="0"
              :step="1"
              type="number"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
          <el-form-item label="WBC (g/dL)" prop="wbc">
            <el-input-number
              v-model.number="form.wbc"
              :min="0"
              :step="1"
              type="number"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
          <el-form-item label="Sideremia (g/dL)" prop="sideremia">
            <el-input-number
              v-model.number="form.sideremia"
              :min="0"
              :step="1"
              type="number"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-divider content-position="right">Analisi della Ferita</el-divider>
        </el-col>
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
          <el-form-item label="Altezza (cm)" prop="ferita_altezza">
            <el-input-number
              v-model.number="form.ferita_altezza"
              :min="0"
              :step="0.1"
              type="number"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
          <el-form-item label="Larghezza (cm)" prop="ferita_larghezza">
            <el-input-number
              v-model.number="form.ferita_larghezza"
              :min="0"
              :step="0.1"
              type="number"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
          <el-form-item label="Profondità (cm)" prop="ferita_profondita">
            <el-input-number
              v-model.number="form.ferita_profondita"
              :min="0"
              :step="0.1"
              type="number"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
          <el-form-item label="Tessuto" prop="tessuto">
            <el-select v-model="form.tessuto" filterable clearable>
              <el-option
                v-for="(item, index) in config.tessuto"
                :key="index"
                :label="item"
                :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
          <el-form-item label="Essudato" prop="essudato">
            <el-select v-model="form.essudato" filterable clearable>
              <el-option
                v-for="(item, index) in config.essudato"
                :key="index"
                :label="item"
                :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
          <el-form-item label="Dolore (NRS)" prop="ferita_profondita">
            <el-input-number
              v-model.number="form.dolore"
              :min="0"
              :step="1"
              :max="10"
              type="number"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-divider content-position="right">Medicazione</el-divider>
        </el-col>
        <el-col :span="24">
          <el-form-item
            label="Medicazione Primaria"
            prop="medicazione_primaria"
          >
            <el-input
              type="textarea"
              v-model="form.medicazione_primaria"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Medicazione Secondaria">
            <el-input
              type="textarea"
              v-model="form.medicazione_secondaria"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Terapia Sistemica">
            <el-input
              type="textarea"
              v-model="form.terapia_sistemica"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="text-center">
          <el-form-item label="Esito" prop="medication_next">
            <el-radio
              v-model="form.medication_next"
              label="1"
              value="1"
              border
              size="large"
              @change="handleRenewChange()"
              >Rieseguire</el-radio
            >
            <el-radio
              v-model="form.medication_next"
              label="2"
              value="2"
              border
              size="large"
              @change="handleRenewChange()"
              >Guarita</el-radio
            >
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="form.medication_next == 1">
          <el-form-item label="Data" prop="date_next">
            <el-date-picker
              v-model="form.date_next"
              :editable="false"
              type="date"
              :picker-options="pickerRenewOptions"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="form.medication_next == 1">
          <el-form-item label="Orario" prop="time_next">
            <el-time-picker
              v-model="form.time_next"
              format="HH:mm"
              value-format="yyyy-MM-dd HH:mm:ss"
              :editable="false"
            ></el-time-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Note Medicazione">
            <el-input
              type="textarea"
              v-model="form.verification_note"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="margin-top">
          <el-button
            size="large"
            type="success"
            @click="submit()"
            class="float-right fixed-confirm-button"
            plain
            :loading="submitLoading"
            >Conferma Lesione</el-button
          >
        </el-col>
      </el-row>
    </div>
  </el-form>
</template>
<script>
import moment from "moment";
import Settings from "@/js/api/services/Settings";
import Wound from "@/js/api/services/Wound";

export default {
  name: "CreateMedication",
  data() {
    var checkSede = (rule, value, callback) => {
        if (!this.form.sede_lesione && this.form.diagnosi_etiologica === 2) {
          callback(new Error('Campo obbligatorio'));
        } else {
          callback();
        }
      };
    return {
      patient_id: null,
      loading: false,
      submitLoading: false,

      woundTypes: [],

      upload: false,

      form: {
        date: new Date(),
        time: new Date(),
      },
      rules: {
        time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
        date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
        patient_id: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        diagnosi_etiologica: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        sede_lesione: [
          { validator: checkSede, trigger: "blur" },
        ],
        stadio_npuap: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        date_next: [
          { required: false, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        time_next: [
          { required: false, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        medication_next: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        showAbsentPatientAlert: false,
        showOldAbsencePatientAlert: false,
        patient: null,
      },
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pickerRenewOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() < new Date(Date.now() - 86400000);
        },
      },
      uploadHeaders: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        Authorization: `Bearer ${localStorage.getItem("arkicare_session")}`,
      },
    };
  },
  computed: {
    patients() {
      return this.$store.state.patients;
    },
    config() {
      return this.$store.state.config.wounds;
    },
    selectableTime() {
      var endTime = new moment();
      var startTime = moment(endTime).add(-4, "hours").format("HH:mm:ss");
      return startTime + " - " + endTime.format("HH:mm:ss");
    },
  },
  methods: {
    async init() {
      const response = await Settings.fetch("wound");
      this.woundTypes = response.data;
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$confirm("Confermare valutazione?", "Attenzione", {
            confirmButtonText: "Sì",
            cancelButtonText: "Annulla",
            type: "success",
          })
            .then(() => {
              this.submitLoading = true;
              this.store();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "Operazione annullata",
              });
            });
        } else {
          return false;
        }
      });
    },
    async store() {
      let params = {
        date: moment(this.form.date).format("YYYY-MM-DD"),
        time: moment(this.form.time).format("HH:mm:ss"),
        medication_next: this.form.medication_next,
        date_next: moment(this.form.date_next).format("YYYY-MM-DD"),
        time_next: moment(this.form.time_next).format("HH:mm:ss"),
        data: this.form,
      };
      try {
        await Wound.store(params);
        if (this.upload) {
          this.$refs.uploader.submit();
        }
        this.$router.push({ name: "Medicazioni" });
      } catch (error) {
        this.submitLoading = false;
      }
    },
    handleRenewChange() {
      if (this.form.medication_next == 1) {
        this.rules.date_next[0].required = true;
        this.rules.time_next[0].required = true;
      } else {
        this.rules.date_next[0].required = false;
        this.rules.time_next[0].required = false;
      }
    },
    handleUpload(file, fileList) {
      if (fileList.length > 5) {
        for (let i = fileList.length; i > 5; i--) {
          fileList.pop();
        }
      }
      this.upload = file.status === "ready";
    },
  },
  watch: {
    form: {
      deep: true,
      handler(data) {
        if(this.form.patient_id){
            this.patient = _.filter(this.patients, { id: this.form.patient_id })[0];
            this.showAbsentPatientAlert = this.patient.absence_info.absence_status;
            this.showOldAbsencePatientAlert = false;
            if (!this.showAbsentPatientAlert && this.patient.absence_info.last_absence){
                var date = `${moment(this.form.date).format("YYYY-MM-DD")} ${moment(this.form.time).format("HH:mm:ss")}`;
                if (date > this.patient.absence_info.last_absence['confirmation_date'] && date < this.patient.absence_info.last_absence['verification_date']) {
                    this.showOldAbsencePatientAlert = true;
                }
            }
        }
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.margin-top {
  margin-top: 15px;
}
</style>
