<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button
            class="float-left"
            round
            :disabled="disablePrev"
            @click="show(test_prev_tinetti)"
          >
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_tinetti.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_tinetti.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_tinetti)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_tinetti" :test_model="test_model_tinetti" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <!-- DATA -->
          <el-row :gutter="20">
            <el-col :span="24" v-for="(category, idx) in categories" :key="idx + '-cat'">
              <el-divider content-position="center">{{category.name }}</el-divider>

              <el-col :span="24" v-for="(checks, idx2) in category.checks" :key="idx2 + '-opt'">
                <el-form-item :prop="checks.result">
                  <el-col :span="24" v-for="(option, idx3) in checks.options" :key="idx3 + '-opt'">
                    <el-col :span="16">
                      <el-form-item :label="option.label" />
                    </el-col>

                    <el-col :span="8">
                      <el-row>
                        <el-col :offset="12">
                          <el-form-item>
                            <el-radio
                              :label="option.points"
                              v-model="test_tinetti[checks.result]"
                              border
                            />
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-col>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_tinetti.total_string}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Autonomia motoria">{{test_tinetti.total_description}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>

          <el-col :span="24">
            <el-form-item label="Note">
              <el-input type="textarea" v-model="test_tinetti['note']"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import { TINETTI } from "@/js/pages/Tests/constants";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Tinetti",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_tinetti: this.test,
      test_prev_tinetti: this.test_prev,
      test_next_tinetti: this.test_next,
      test_name_tinetti: this.test_name,
      test_model_tinetti: this.test_model,
      test_type_tinetti: this.test_type,
      patient_id_tinetti: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
      categories: TINETTI.categories
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_tinetti,
        test_model: this.test_model_tinetti,
        test_id: test_id,
        type_id: this.test_type_tinetti
      };

      const response = await Test.show(params);
      this.test_tinetti = response.data.test;
      this.test_name_tinetti = response.data.test_name;
      this.test_prev_tinetti = response.data.test_prev;
      this.test_next_tinetti = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_tinetti === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_tinetti === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
