<script>
export default {
    name: "SelectDiagnosis",
    inheritAttrs: false,
    props: ["value", "defaultValue", "parent"],
    data() {
        return {
            loadingSelect: false,
            items: []
        };
    },
    methods: {
        fetchDiagnosis(query) {
            const valid = query !== "" && query.length > 2;
            if (!valid) return;
            this.loadingSelect = true;
            let parent;
            if(this.parent) {
                parent = this.parent
            }
            let params = { string: query, parent};
            axios
                .get("/config/diagnosi", { params })
                .then(({ data }) => {
                    if (parent === "SosiaTreatmentForm") {
                        this.items = data.filter(diagnosi => {
                            const code = diagnosi.codice.replace(/\b0+/g, "");
                            if (code.length >= 4) {
                                return diagnosi;
                            }
                        });
                    } else {
                        this.items = data;
                    }
                })
                .finally(() => (this.loadingSelect = false));
        },
        onChange(x) {
            this.$emit("input", x);
        },
        onClear() {
            this.$emit("input", null);
            this.items = [];
        }
    },
    watch: {
        defaultValue: {
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal && oldVal === undefined) {
                    this.items = [newVal];
                    this.$emit("input", newVal.codice);
                }
            }
        }
    }
};
</script>

<template>
    <el-select
        v-bind="$attrs"
        :value="value"
        @change="onChange"
        @clear="onClear"
        clearable
        filterable
        remote
        :remote-method="fetchDiagnosis"
        :loading="loadingSelect"
    >
        <el-option
            v-for="item in items"
            :key="`diagnosis-${item.id}`"
            :label="item.codice + ' - ' + item.descrizione"
            :value="item.codice"
        >
        </el-option>
    </el-select>
</template>

<style lang="scss" scoped></style>
