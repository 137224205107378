<template>
    <div>
        <md-dialog :md-active.sync="visible" :md-click-outside-to-close="false">
            <md-dialog-title>{{ title }}</md-dialog-title>
            <md-dialog-content>
                <el-form label-position="top" label-width="80px" :model="form" :rules="rules" ref="form">
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="Data" prop="date">
                                <el-date-picker v-model="form.date" type="date" placeholder="Scegli data"
                                    :editable="false" :picker-options="{
            firstDayOfWeek: 1,
            disabledDate(time) {
                return time.getTime() > Date.now();
            }
        }" format="dd/MM/yyyy" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="Orario" prop="time">
                                <el-time-picker v-model="form.time" :editable="false" format="HH:mm"
                                    value-format="yyyy-MM-dd HH:mm:ss" placeholder="Seleziona Orario">
                                </el-time-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" class="text-center">
                            <el-form-item label="Evacuazione" prop="type" v-if="option === 'purge'">
                                <el-radio v-model="form.type" label="1" border>Parziale</el-radio>
                                <el-radio v-model="form.type" label="2" border>Completa</el-radio>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24" class="text-center">
                            <el-form-item label="Eseguito" prop="verification_result" v-if="option === 'tasks' ||
            option === 'protectionsVerification'
            ">
                                <el-radio-group v-model="form.verification_result" @change="handleResult()">
                                    <el-radio-button label="1">Sì</el-radio-button>
                                    <el-radio-button label="2">No</el-radio-button>
                                    <el-radio-button label="3" value="3"
                                        v-if="option === 'protectionsVerification'">Rimossa</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="option === 'protectionsVerification'">
                            <el-form-item label="Postura Corretta" prop="postura_corretta" class="text-center">
                                <el-radio-group v-model="form.postura_corretta" :disabled="selection.length > 1"
                                    @change="handleResult()">
                                    <el-radio-button label="1">Si</el-radio-button>
                                    <el-radio-button label="2">No</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="option === 'protectionsVerification'">
                            <el-form-item label="Integrità Cutanea" prop="integrita_cutanea" class="text-center">
                                <el-radio-group v-model="form.integrita_cutanea" :disabled="selection.length > 1"
                                    @change="handleResult()">
                                    <el-radio-button label="1">Si</el-radio-button>
                                    <el-radio-button label="2">No</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8" v-if="option === 'protectionsVerification'">
                            <el-form-item label="Tolleranza del Mezzo" prop="tolleranza_del_mezzo" class="text-center">
                                <el-radio-group v-model="form.tolleranza_del_mezzo" :disabled="selection.length > 1"
                                    @change="handleResult()">
                                    <el-radio-button label="1">Si</el-radio-button>
                                    <el-radio-button label="2">No</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="Osservazioni" prop="description">
                                <el-input type="textarea" :rows="2" placeholder="Descrizione"
                                    v-model="form.description">
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <span v-if="showAbsentPatientAlert">
                            <font-awesome-icon icon="exclamation" class="danger-text" />
                            <font style="color: red">Attenzione: l'ospite è attualmente assente</font>
                        </span>
                        <span v-if="showOldAbsencePatientAlert">
                            <font-awesome-icon icon="exclamation" class="danger-text" />
                            <font style="color: red">Attenzione: l'ospite era assente in questo periodo</font>
                        </span>
                    </el-row>
                </el-form>
            </md-dialog-content>
            <md-dialog-actions>
                <el-button type="info" plain @click="close()">Annulla</el-button>
                <el-button type="success" plain @click="submit()" :loading="loading">Salva</el-button>
            </md-dialog-actions>
        </md-dialog>
        <el-dialog title="Attenzione" :visible.sync="deleteTaskVisible" append-to-body width="50%" center>
            <p style="margin-bottom: 20px; text-align: center">
                Cancellare solo questa conferma o tutte del gruppo?
            </p>
            <div style="text-align: center">
                <el-radio v-model="deleteTaskType" label="single" border>Solo questa</el-radio>
                <el-radio v-model="deleteTaskType" label="all" border>Tutte le prossime del gruppo</el-radio>
            </div>
            <span slot="footer">
                <el-button @click="deleteTaskVisible = false">Annulla</el-button>
                <el-button type="primary" @click="onTaskDelete">Cancella</el-button>
            </span>
        </el-dialog>
        <el-button-group>
            <el-tooltip v-if="option === 'comunications' && isDeleteble" content="Modifica Comunicazione" placement="bottom"
                effect="light">
                <el-button type="primary" size="small" plain @click="editComunication(comunication.id, comunication)">
                    <i class="el-icon-edit"></i></el-button>
            </el-tooltip>
            <el-button size="small" plain type="danger" v-if="isDeleteble" @click="destroy()"
                icon="el-icon-delete"></el-button>
            <el-button v-if="option !== 'comunications'" size="small" plain :type="buttonType" @click="show()"
                icon="el-icon-check"></el-button>
        </el-button-group>
        <el-dialog title="Modifica Comunicazione" :visible.sync="comunicationsEdit" append-to-body width="50%" center>
            <el-form label-position="top" label-width="120px" :model="form" :rules="rules" ref="form">
                <el-form-item label="Titolo" prop="title">
                    <el-input placeholder="Titolo" v-model="form.title">
                    </el-input>
                </el-form-item>
                <el-form-item label="Messaggio" prop="message">
                    <el-input type="textarea" :rows="6" placeholder="Messaggio" v-model="form.message">
                    </el-input>
                </el-form-item>
            </el-form>
            <el-col :span="24">
                <span>
                    <font-awesome-icon icon="exclamation" class="danger-text" />
                    <font style="color: red">La modifica della comunicazione comporterà l’azzeramento dell’elenco di lettura attuale.</font>
                </span>
            </el-col>
            <span slot="footer">
                <el-button @click="comunicationsEdit = false">Annulla</el-button>
                <el-button type="primary" @click="store()">Salva</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import moment from "moment";

export default {
    name: "ArkiCheckDialog",
    props: {
        title: String,
        rules: Object,
        action: String,
        comunication: Object,
        icon: String,
        buttonType: String,
        buttonTitle: String,
        option: String,
        method: String,
        selection: Array,
        isDeleteble: Boolean,
        row: Object,
        patient_id: Number,
    },
    data() {
        return {
            visible: false,
            loading: false,
            deleteTaskType: "single",
            deleteTaskVisible: false,
            form: {},
            comunicationsEdit: false,
            showAbsentPatientAlert: false,
            showOldAbsencePatientAlert: false,
            patient: null,
        };
    },
    mounted() {
        this.setFormDefaultValues();
    },
    computed: {
        patients() {
            return this.$store.state.patients;
        }
    },
    methods: {
        show() {
            this.visible = true;
        },
        submit() {
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.store();
                } else {
                    return false;
                }
            });
        },
        store() {
            this.loading = true;
            let params = {
                date: moment(this.form.date).format("YYYY-MM-DD"),
                time: moment(this.form.time).format("HH:mm:ss"),
                description: this.form.description,
                type: this.form.type,
                verification_result: this.form.verification_result,
                selection: this.selection,
                title: this.form.title,
                message: this.form.message
            };
            if (this.option == 'protectionsVerification') {
                params.postura_corretta = this.form.postura_corretta;
                params.integrita_cutanea = this.form.integrita_cutanea;
                params.tolleranza_del_mezzo = this.form.tolleranza_del_mezzo;
            }
            if (this.method !== "patch") {
                axios
                    .post(this.action, params)
                    .then(res => {
                        this.success();
                    })
                    .catch(error => {
                        this.error(error);
                    });
                if (this.option == 'purge') {
                    this.setHasPurgeCritical(params.type);
                }
            } else {
                axios
                    .patch(this.action, params)
                    .then(res => {
                        this.success();
                    })
                    .catch(error => {
                        this.error(error);
                    });
            }
        },
        destroyTask() {
            if (this.row.is_recurring || this.row.parent_task_id) {
                this.deleteTaskVisible = true;
                return;
            }
            this.doDestroy();
        },
        destroy() {
            if (_.includes(this.action, "tasks")) {
                return this.destroyTask();
            }
            this.doDestroy();
        },
        doDestroy() {
            this.$confirm(`Annullare ${this.title} ?`, "Attenzione", {
                confirmButtonText: "OK",
                cancelButtonText: "Annulla",
                type: "warning"
            })
                .then(() => {
                    this.loading = true;
                    axios
                        .delete(this.action)
                        .then(res => {
                            this.success();
                        })
                        .catch(error => {
                            this.error(error);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "Operazione annullata"
                    });
                });
        },
        onTaskDelete() {
            const payload = { deleteType: this.deleteTaskType };
            this.loading = true;
            axios
                .delete(this.action, {
                    data: payload
                })
                .then(res => {
                    this.success();
                    this.deleteTaskVisible = false;
                    this.deleteTaskType = "single";
                })
                .catch(error => {
                    this.error(error);
                })
                .finally(() => (this.loading = false));
        },
        editComunication(id, comunication) {
            this.form = { ...this.form, title: comunication.title, message: comunication.message };
            this.comunicationsEdit = true;
        },
        success() {
            this.$emit("success");
            this.close();
        },
        error(error) {
            this.loading = false;
        },
        close() {
            this.setFormDefaultValues();
            this.loading = false;
            this.visible = false;
            this.comunicationsEdit = false;
            this.showAbsentPatientAlert = false;
            this.showOldAbsencePatientAlert = false;
        },
        setFormDefaultValues() {
            // we need to override the entire form object cause the el-form model is not deep reactive
            const tmpForm = {
                date: new Date(),
                time: new Date(),
            }
            if (this.option == 'protectionsVerification') {
                tmpForm.postura_corretta = 1;
                tmpForm.integrita_cutanea = 1;
                tmpForm.tolleranza_del_mezzo = 1;
            }
            this.form = tmpForm;
        },
        handleResult() {
            if (this.form.verification_result == 1) {
                this.rules.description[0].required = false;
            } else if (this.form.verification_result == 2) {
                this.rules.description[0].required = true;
            } else if (this.form.verification_result == 3) {
                this.rules.description[0].required = false;
            }
            if (this.form.postura_corretta == 2 || this.form.tolleranza_del_mezzo == 2 || this.form.integrita_cutanea == 2) {
                this.rules.description[0].required = true;
            }
        },
        setHasPurgeCritical(type) {
            if (type == 2) {
                this.$store.dispatch('fetchPatients');
            }
        },
    },
    watch: {
        form: {
            deep: true,
            handler(data) {
                if (this.patient_id) {
                    this.patient = _.filter(this.patients, { id: this.patient_id })[0];
                    this.showAbsentPatientAlert = this.patient.absence_info.absence_status;
                    this.showOldAbsencePatientAlert = false;
                    if (!this.showAbsentPatientAlert && this.patient.absence_info.last_absence) {
                        var date = `${moment(data.date).format("YYYY-MM-DD")} ${moment(data.time).format("HH:mm:ss")}`;
                        if (date > this.patient.absence_info.last_absence['confirmation_date'] && date < this.patient.absence_info.last_absence['verification_date']) {
                            this.showOldAbsencePatientAlert = true;
                        }
                    }
                }
            }
        },
        patient_id: {
            deep: true,
            handler(data) {
                if (this.patient_id) {
                    this.patient = _.filter(this.patients, { id: this.patient_id })[0];
                    this.showAbsentPatientAlert = this.patient.absence_info.absence_status;
                }
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.margin-bottom {
    margin-bottom: 15px;
}
</style>
