<template>
    <div>
        <el-table
            :data="table.data"
            style="width: 100%; margin-bottom: 25px"
            v-loading="loading"
            :row-class-name="rowClass">
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-row v-if="action === 'physiotherapy'">
                        <el-row>
                            <el-col :span="12" v-if="scope.row.orientamento">
                                <b>Orientamento:</b><br> <p>{{options_1[scope.row.orientamento]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.attenzione_concentrazione">
                                <b>Attenzione / Concentrazione:</b><br> <p>{{options_1[scope.row.attenzione_concentrazione]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.motivazione">
                                <b>Motivazione:</b><br> <p>{{options_1[scope.row.motivazione]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.collaborazione">
                                <b>Collaborazione:</b><br> <p>{{options_1[scope.row.collaborazione]}}</p>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24" v-if="scope.row.has_esame_obiettivo">
                                <h3 class="text-center">Esame Obiettivo</h3>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_esame_obiettivo_letto">
                                <h4>A Letto</h4>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.letto_testa">
                                <b>Testa:</b><br> <p>{{scope.row.letto_testa}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.letto_tronco">
                                <b>Tronco:</b><br> <p>{{scope.row.letto_tronco}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.letto_arti_inferiori">
                                <b>Arti Inferiori:</b><br> <p>{{scope.row.letto_arti_inferiori}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.letto_arti_superiori">
                                <b>Arti Superiori:</b><br> <p>{{scope.row.letto_arti_superiori}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_esame_obiettivo_seduto">
                                <h4>Da seduto</h4>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.seduto_testa">
                                <b>Testa:</b><br> <p>{{scope.row.seduto_testa}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.seduto_tronco">
                                <b>Tronco:</b><br> <p>{{scope.row.seduto_tronco}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.seduto_arti_inferiori">
                                <b>Arti Inferiori:</b><br> <p>{{scope.row.seduto_arti_inferiori}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.seduto_arti_superiori">
                                <b>Arti Superiori:</b><br> <p>{{scope.row.seduto_arti_superiori}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_esame_obiettivo_eretto">
                                <h4>In stazione eretta</h4>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.eretto_testa">
                                <b>Testa:</b><br> <p>{{scope.row.eretto_testa}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.eretto_tronco">
                                <b>Tronco:</b><br> <p>{{scope.row.eretto_tronco}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.eretto_arti_inferiori">
                                <b>Arti Inferiori:</b><br> <p>{{scope.row.eretto_arti_inferiori}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.eretto_arti_superiori">
                                <b>Arti Superiori:</b><br> <p>{{scope.row.eretto_arti_superiori}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_esame_obiettivo">
                                <h3 class="text-center">Fine Esame Obiettivo</h3>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.tono_muscolare">
                                <b>Tono Muscolare:</b><br> <p>{{scope.row.tono_muscolare}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.trofismo_muscolare">
                                <b>Trofismo Muscolare:</b><br> <p>{{scope.row.trofismo_muscolare}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.escursione_articolare">
                                <b>Escursione Articolare:</b><br> <p>{{scope.row.escursione_articolare}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.test_tinetti">
                                <b>Test Tinetti:</b><br> <p>{{scope.row.test_tinetti}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_mobilizzazione">
                                <h3 class="text-center">Mobilizzazione</h3>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.deambulazione">
                                <b>Deambulazione:</b><br> <p>{{options_3[scope.row.deambulazione]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.cammino">
                                <b>Schema del Cammino:</b><br> <p>{{scope.row.cammino}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.scale">
                                <b>Scale:</b><br> <p>{{options_4[scope.row.scale]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.coordinazione">
                                <b>Coordinazione:</b><br> <p>{{scope.row.coordinazione}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.sensibilita_propriocettiva">
                                <b>Sensibilità propriocettiva:</b><br> <p>{{options_5[scope.row.sensibilita_propriocettiva]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.sensibilita_esterocettiva">
                                <b>Sensibilità esterocettiva:</b><br> <p>{{options_5[scope.row.sensibilita_esterocettiva]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.prese_pinze_precisione">
                                <b>Prese e pinze:</b><br> <p>{{scope.row.prese_pinze_precisione}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.altri_problemi">
                                <b>Altri Problemi:</b><br> <p>{{scope.row.altri_problemi}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_spostamenti">
                                <h3 class="text-center">Autonomia negli spostamenti</h3>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.spostamenti_letto_supino_fianco">
                                <b>Supino/Fianco:</b><br> <p>{{options_4[scope.row.spostamenti_letto_supino_fianco]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.spostamenti_letto_supino_seduto">
                                <b>Supino/Seduto:</b><br> <p>{{options_4[scope.row.spostamenti_letto_supino_seduto]}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.has_trasferimenti">
                                <h3 class="text-center">Trasferimenti</h3>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.trasferimenti_letto_carrozzina">
                                <b>Letto/Carrozzina:</b><br> <p>{{options_4[scope.row.trasferimenti_letto_carrozzina]}}</p>
                            </el-col>
                            <el-col :span="12" v-if="scope.row.trasferimenti_seduto_eretto">
                                <b>Seduto/Eretto:</b><br> <p>{{options_4[scope.row.trasferimenti_seduto_eretto]}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.patologia_problemi">
                                <b>Altre Patologie:</b><br> <p>{{scope.row.patologia_problemi}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.ipotesi_trattamento">
                                <b>Ipotesi Trattamento:</b><br> <p>{{scope.row.ipotesi_trattamento}}</p>
                            </el-col>
                            <el-col :span="24" v-if="scope.row.note_altro">
                                <b>Note:</b><br> <p>{{scope.row.note_altro}}</p>
                            </el-col>
                        </el-row>
                    </el-row>
                    <el-col :span="24">
                        <b>Inserimento:</b> {{scope.row.full_creator}} - {{scope.row.created_at_time}}
                    </el-col>
                    <el-col :span="24" v-if="scope.row.verification_note">
                        <b>Nota:</b> {{scope.row.verification_note}}
                    </el-col>
                    <el-col :span="24" v-if="scope.row.description && extendedNotes">
                        <b>Nota:</b> {{scope.row.description}}
                    </el-col>
                    <el-col :span="24" v-if="action == 'protection' && scope.row.clean_last_verification">
                        <b>Rimozione:</b> {{scope.row.full_verification_operator}} - {{scope.row.clean_last_verification}}
                    </el-col>

                    <el-col :span="24" v-if="scope.row.removed_at_time">
                        <b>Annullamento:</b> {{scope.row.full_remover}} - {{scope.row.clean_verification_date}} {{scope.row.removed_at_time}}
                    </el-col>

                    <div v-if="action == 'protection' && scope.row.verification_result == 1">
                        <el-col :span="24" >
                            <b>Termine:</b> {{scope.row.full_verification_operator}} - {{scope.row.clean_verification_time}} {{scope.row.clean_verification_date}}
                        </el-col>
                        <el-col :span="12" v-if="scope.row.verification_notes">
                            <b>Nota Verifica:</b> {{scope.row.verification_notes}}
                        </el-col>
                    </div>
                    <div  v-else-if="hasVerification && scope.row.verification_result !== 0">
                        <el-col :span="12">
                            <b>Verifica:</b> {{scope.row.clean_verification_date}} {{scope.row.clean_verification_time}}
                        </el-col>
                        <el-col :span="12">
                            <b>Nota Verifica:</b> {{scope.row.verification_notes}}
                        </el-col>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="date" label="Data" width="100" sortable></el-table-column>
            <el-table-column prop="time" label="Orario" width="100" sortable> </el-table-column>
            <el-table-column prop="type_description" label="Tipologia" v-if="hasInfo" ></el-table-column>
            <el-table-column prop="type_name" label="Categoria" v-if="action === 'protection'" > </el-table-column>
            <el-table-column prop="farmaco" label="Terapia" v-if="action === 'subministration'"> </el-table-column>
            <el-table-column prop="ipotesi_trattamento" label="Ipotesi di trattamento" v-if="action === 'physiotherapy'"> </el-table-column>
            <el-table-column prop="description" label="Note" v-if="!extendedNotes && action !== 'wound' && action !== 'physiotherapy'"> </el-table-column>
            <el-table-column prop="description" label="Descrizione" v-else-if="!extendedNotes && action !== 'physiotherapy'"> </el-table-column>
            <el-table-column prop="note_altro" label="Note" v-if="action === 'physiotherapy'"> </el-table-column>
        </el-table>
        <el-pagination
            background
            layout="prev, pager, next"
            :page-size="table.per_page"
            :total="table.total"
            :hide-on-single-page="true"
            @current-change="changePage">
        </el-pagination>
    </div>
</template>
<script>
import Patient from '@/js/api/services/Patient';

export default {
    name: 'ArkiTable',
    props: {
        profile_id: String,
        action: String,
        hasInfo: Boolean,
        hasVerification: Boolean,
        extendedNotes: Boolean,
        noAction: Boolean
    },
    data(){
        return{
            loading: true,
            table: {
                data: [],
                total: null,
                per_page: 1,
            } ,
            current_page: 1,
             options_1:['', 'Sì', 'Parziale', 'No'],
            options_2:['', 'Buona', 'Scarsa', 'Assente'],
            options_3:['', 'Tratti Lunghi', 'Tratti Brevi'],
            options_4:['', 'Sì', 'Con Aiuto', 'No'],
            options_5:['', 'Sì', 'Alterata', 'No'],
        }
    },
    computed:{
        user(){ return this.$store.state.user }
    },
    methods:{
        async fetch(){
            let params = {
                page: this.current_page
            }
            const response = await Patient.section(this.profile_id, this.action, params);
            this.table = response.data;
            this.loading = false;
        },
        changePage(page){
            this.loading = true;
            this.current_page = page;
            this.fetch();
        },
        rowClass({row, rowIndex}) {
            if(row.enabled == 0){
                return 'deleted-row';
            } else if (row.verification_result == 2) {
                return 'warning-row';
            } else if(row.verification_result == 1) {
                return 'success-row';
            }
            return '';
        },
        description(value, date){
            switch(this.action){
                case 'bath':
                    return `Bagno del ${date}`;
                break;
                case 'purge':
                    return `Evacuazione del ${date}`;
                break;
                case 'protection':
                    return `Contenzione del ${date}`;
                break;
                case 'purgeSubministration':
                    return `Terapia lassativa del ${date}`;
                break;
                case 'physiotherapy':
                    return `Fisioterapia del ${date}`;
                break;
            }
        },
        destroy(id, description){
            this.$confirm(`Annullare "${description}" ?`, 'Attenzione', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Annulla',
                type: 'warning'
            }).then(() => {
                this.delete(id);
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Operazione annullata'
                });
            });
        },
        async delete(id){
            this.loading = true;
            try{
                await Patient.delete(this.action, id);
                this.fetch();
            } catch(error){
                this.fetch();
            }
        }
    },
    mounted(){
        this.fetch();
    }
}
</script>
<style lang="scss" scoped>
 .el-pagination{
     margin-top: 15px;
 }
</style>
