<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-row type="flex" class="row-bg" justify="center">
            <el-card class="box-card">
              <el-collapse>
                <el-collapse-item>
                  <template slot="title">
                    <span class="collapse-header">
                      <i class="el-icon-info"></i> Legenda
                    </span>
                  </template>
                  <div>0 - Mai</div>
                  <div>1 - Raramente (poche volte/mese)</div>
                  <div>3 - Occasionalmente (almeno 2 volte/settimana)</div>
                  <div>6 - Spesso (tutti i giorni)</div>
                </el-collapse-item>
              </el-collapse>
            </el-card>
          </el-row>
        </el-col>
        <el-col :span="24" v-for="(category, idx) in categories" :key="idx + '-cat'">
          <el-divider content-position="center">{{category.name }}</el-divider>

          <el-col :span="24" v-for="(question, idx2) in category.questions" :key="idx2 + '-q'">
            <el-col :span="16">
              <el-form-item :label="question.label" />
            </el-col>
            <el-col :span="8">
              <el-row>
                <el-col>
                  <el-form-item :prop="question.result">
                    <div>
                      <el-radio-group v-model="result[question.result]">
                        <el-radio-button
                          v-for="(opt, idx3) in question.options"
                          :key="idx3 + '-opt'"
                          :label="opt"
                        />
                      </el-radio-group>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-col>
          <el-col :span="24">
            <el-row type="flex" justify="end">
              <div>
                <p>
                  Punteggio totale {{totalLetter(idx)}}
                  <span
                    class="total"
                  >{{totals[idx].done}} / {{totals[idx].max}}</span>
                </p>
              </div>
            </el-row>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-divider />
            <el-form-item label="Note">
              <el-input type="textarea" v-model="result.note"></el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { KANE } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Kane",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 78
      },
      result: {},
      testRules: KANE.rules(13),
      categories: KANE.categories,
      aResults: KANE.createResults(1, 7),
      bResults: KANE.createResults(7, 12),
      cResults: KANE.createResults(12, 14),
      totals: KANE.totals()
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$emit("submitTest", this.form, this.result);
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    },
    totalLetter(idx) {
      let map = {
        "0": "A",
        "1": "B",
        "2": "C"
      };
      return map[idx];
    },
    computePartialResults() {
      let points = [
        KANE.totalOf(this.aResults, this.result),
        KANE.totalOf(this.bResults, this.result),
        KANE.totalOf(this.cResults, this.result)
      ];
      const totalsUpdated = _.range(0, 3).reduce((tot, _, idx) => {
        tot[idx].done = points[idx];
        return tot;
      }, this.totals);
      this.totals = totalsUpdated;
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        this.computePartialResults();
        let results = _.values(KANE.filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}

.total {
  font-weight: bold;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>