<template>
  <div>
    <el-row :gutter="10" class="block no-filters">
      <el-col
        :span="24"
        v-loading="loading"
        style="margin-top: 10px; padding: 0 10px"
      >
        <el-table
          :data="
            activities.filter(
              (data) =>
                !search ||
                data.label.toLowerCase().includes(search.toLowerCase())
            )
          "
          style="width: 100%; margin: auto"
          :loading="loading"
        >
          <el-table-column
            prop="label"
            label="Label"
            sortable
            :sort-method="orderByLabel"
          >
          </el-table-column>
          <el-table-column
            prop="enabled"
            label="Stato"
            width="180"
            sortable
            align="center"
            :sort-method="orderByStatus"
          >
            <template slot-scope="scope">
              <el-button
                v-if="!scope.row.disabled_id"
                type="success"
                size="mini"
                circle
                disabled
              ></el-button>
              <el-button
                v-else
                type="danger"
                size="mini"
                circle
                disabled
              ></el-button>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="Operations"
            width="200"
            align="center"
          >
            <template slot="header" slot-scope="scope">
              <el-input v-model="search" placeholder="Search" clearable />
            </template>
            <template slot-scope="scope">
              <el-button
                @click="disableActivity(scope.row.label)"
                type="text"
                v-if="!scope.row.disabled_id"
                >Disabilita</el-button
              >
              <el-button
                @click="restoreActivity(scope.row.label)"
                type="text"
                v-else
                >Ripristina</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Activity } from "@/js/api/services/activity";
export default {
  name: "ActivitiesList",
  data() {
    return {
      loading: false,
      activities: [],
      search: "",
    };
  },
  methods: {
    async fetch() {
      this.loading = true;
      const params = {
        structure_id: this.$store.state.structureId,
      };
      const resp = await Activity.adminList(params);
      this.activities = resp.data;
      this.loading = false;
    },
    disableActivity(label) {
      var params = {
        structure_id: this.$store.state.structureId,
        label: label,
      };
      Activity.adminDisableActivity(params).then(() => this.fetch());
    },
    restoreActivity(label) {
      var params = {
        structure_id: this.$store.state.structureId,
        label: label,
      };
      Activity.adminRestoreActivity(params).then(() => this.fetch());
    },
    orderByLabel(a, b) {
      return a.label < b.label;
    },
    orderByStatus(a, b) {
      return (a.disabled_id ? 0 : 1) - (b.disabled_id ? 0 : 1);
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>

<style>
</style>