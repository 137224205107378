<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button
            class="float-left"
            round
            :disabled="disablePrev"
            @click="show(test_prev_norton)"
          >
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_norton.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_norton.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_norton)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_norton" :test_model="test_model_norton" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q1_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Condizione Fisica" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_norton.q1_result">
                                <el-form-item>
                                  <el-radio :label="4">Buona</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">Discreta</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Cattiva</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Pessima</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q2_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Condizione Mentale" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_norton.q2_result">
                                <el-form-item>
                                  <el-radio :label="4">Sveglio, cosciente</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">Apatico</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Confuso</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Stupore, incoscienza</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q3_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Attività Fisica" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_norton.q3_result">
                                <el-form-item>
                                  <el-radio :label="4">Cammina liberamente</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">Cammina solo con qualche aiuto</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Costretto su sedia a rotelle</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Costretto a letto</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q4_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Mobilità" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_norton.q4_result">
                                <el-form-item>
                                  <el-radio :label="4">Completa</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">Limitata</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Molto limitata</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Immobile</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q5_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Incontinenza" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_norton.q5_result">
                                <el-form-item>
                                  <el-radio :label="4">Nessuna</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">Occasionale</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Abituale Urinaria</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Abituale Urinaria e fecale</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_norton.total_string}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Risultato">{{test_norton.total_description}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Norton",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_norton: this.test,
      test_prev_norton: this.test_prev,
      test_next_norton: this.test_next,
      test_name_norton: this.test_name,
      test_model_norton: this.test_model,
      test_type_norton: this.test_type,
      patient_id_norton: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_norton,
        test_model: this.test_model_norton,
        test_id: test_id,
        type_id: this.test_type_norton
      };

      const response = await Test.show(params);
      this.test_norton = response.data.test;
      this.test_name_norton = response.data.test_name;
      this.test_prev_norton = response.data.test_prev;
      this.test_next_norton = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_norton === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_norton === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
