import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';
import axios from 'axios';

export default class Absence extends ArkiBaseService {

    static endpoint = 'absences';

    static exit(id, params){
        return Api.patch(`${this.endpoint}/${id}/upload`, params);
    }

    static return(id, params){
        return Api.post(`${this.endpoint}/${id}/upload`, params);
    }

    static download(id){
        return axios({
            url: `absences/${id}/download`,
            method: 'GET',
            responseType: 'blob',
        });
    }

    static adminFetch(params) {
        return Api.get(this.endpoint + '/admin', {
            params: params
        });
    }

    static adminExport(params) {
        return axios({
            url: `absences/admin/export`,
            method: 'GET',
            responseType: 'blob',
            params: {
                params: params
            }
        });
    }
}
