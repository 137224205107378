<template>
  <el-row class="filters" :gutter="5">
    <el-col :span="filterSpan" v-if="dateRangeFilter">
      <el-date-picker
        v-model="filters.dateRange"
        type="daterange"
        range-separator="-"
        start-placeholder="Data inizio"
        end-placeholder="Data fine"
        format="dd MMMM"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        :editable="false"
        :clearable="false"
        @change="dateRangeChange"
      >
      </el-date-picker>
    </el-col>

    <el-col
      :span="filterSpan"
      v-if="
        !dateRangeFilter &&
        !dateMonthRangeFilter &&
        target !== 'subministrations' &&
        target !== 'prescriptedProtections'
      "
    >
      <el-date-picker
        v-model="filters.date"
        placeholder="Data"
        format="dd MMMM"
        value-format="yyyy-MM-dd"
        :pickerOptions="pickerOptions"
        :clearable="false"
        :editable="false"
        @change="emit()"
      >
      </el-date-picker>
    </el-col>
    <el-col :span="filterSpan" v-if="dateMonthRangeFilter">
      <el-date-picker
        class="date-filter"
        :editable="false"
        v-model="filters.dateRange"
        type="monthrange"
        align="right"
        format="MMMM yyyy"
        unlink-panels
        range-separator="-"
        start-placeholder="Inizio"
        end-placeholder="Fine"
        @change="emit()"
        :picker-options="monthPickerOptions"
      >
      </el-date-picker>
    </el-col>

    <el-col :span="filterSpan" v-if="optionsFilter">
      <el-select
        v-model="filters.option"
        placeholder="Opzioni"
        clearable
        @change="emit('manual')"
      >
        <el-option
          v-for="item in selects"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>

    <el-col :span="6" v-if="target === 'exams'">
      <el-select
        v-model="filters.option"
        multiple
        collapse-tags
        clearable
        placeholder="Seleziona"
        @change="checkFiltersOption"
      >
        <el-option
          :disabled="
            item.value !== 0 && filters.option.includes(0) ? true : false
          "
          v-for="item in selects"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="6" v-if="target === 'exams'">
      <el-select
        clearable
        v-model="filters.criticals"
        placeholder="Seleziona"
        @change="emit('manual')"
      >
        <el-option
          v-for="item in criticalSelects"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-col>
    <!-- End Exams filter -->

    <!-- Task filter -->

    <el-col :span="8" v-if="target === 'tasks'">
      <el-cascader
        v-model="filters.option"
        :options="options"
        @change="emit('manual')"
      >
      </el-cascader>
    </el-col>
    <!-- End Task filter -->

    <!-- Diary filter -->

    <el-col :span="filterSpan" v-if="target === 'diary'">
      <el-cascader
        clearable
        v-model="filters.option"
        :options="options"
        @change="emit('manual')"
      >
      </el-cascader>
    </el-col>
    <!-- End Diary filter -->

    <!-- Reports filter -->
    <el-col
      :span="subTarget === 'bathsReport' ? 16 : 8"
      v-if="target === 'reports'"
    >
      <el-select
        v-model="filters.piano"
        placeholder="Piano"
        clearable
        @change="emit('manual')"
      >
        <el-option
          v-for="(item, index) in pianoSelects"
          :key="index"
          :label="item.desc_pianoreparto"
          :value="item.id_pianoreparto"
        >
        </el-option>
      </el-select>
    </el-col>

    <!-- End Reports filter -->

    <el-col :span="8" v-if="professionsFilter">
      <el-select
        v-model="filters.profession"
        placeholder="Professione"
        clearable
        @change="emit('manual')"
      >
        <el-option
          v-for="(item, index) in professionSelects"
          :key="index"
          :label="item.label"
          :value="index"
        >
        </el-option>
      </el-select>
    </el-col>

    <el-col :span="patientsSpan">
      <el-select
        v-model="filters.patient_id"
        placeholder="Ospite"
        @change="emit('manual')"
        filterable
        clearable
        @clear="clearPatient"
      >
        <el-option
          v-for="item in patients"
          :key="item.id"
          :label="item.surname + ' ' + item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>
  </el-row>
</template>
<script>
import Settings from "@/js/api/services/Settings";
import Patient from "@/js/api/services/Patient";
import moment from "moment";

export default {
  name: "ArkiFilters",
  props: {
    target: String,
    subTarget: {
      type: String,
      default: null,
      required: false,
    },
    dateRangeFilter: Boolean,
    dateMonthRangeFilter: Boolean,
    optionsFilter: Boolean,
    professionsFilter: Boolean,
    optionSpan: Number,
    patientSpan: Number,
    routeParams: Object,
    options: Array,
  },
  data() {
    return {
      filters: {
        dateRange: [new Date(), new Date()],
        date: new Date(),
        option: [],
        patient_id: null,
        profession: null,
        criticals: null,
        piano: null,
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      monthPickerOptions: {
        shortcuts: [
          {
            text: "Questo Mese",
            onClick(picker) {
              picker.$emit("pick", [new Date(), new Date()]);
            },
          },
          {
            text: "Quest'anno",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Ultimi 6 mesi",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      selects: [],
      actions: "",
      structurePatients: [],
    };
  },
  computed: {
    patients() {
      if (this.target == "reports") {
        if (this.filters.piano !== null && this.filters.piano !== "") {
          return this.structurePatients.filter((item) => {
            return item.department == this.filters.piano;
          });
        } else {
          return this.structurePatients;
        }
      } else {
        return this.$store.state.patients;
      }
    },
    filterSpan() {
      return this.optionSpan || 12;
    },
    professionSelects() {
      return Object.fromEntries(
        Object.entries(this.$store.state.professions).filter(
          ([id, profession]) => profession.enabled === 1
        )
      );
    },
    criticalSelects() {
      return this.$store.state.config.exam_type_filters;
    },
    patientsSpan() {
      return this.patientSpan || 24;
    },
    pianoSelects() {
      return this.$store.state.departmentList;
    },
  },
  methods: {
    async init() {
      if (
        this.target &&
        this.target !== "subministrations" &&
        this.target !== "tasks" &&
        this.target !== "diary" &&
        this.target !== "prescriptedMeals"
      ) {
        let target = this.target;
        if (this.target === "reports" && this.subTarget) {
          target = this.subTarget;
        }
        const response = await Settings.fetch(target);
        this.selects = response.data;
      }
      this.emit();
    },
    clearPatient() {
      this.filters.dateRange = [new Date(), new Date()];
      this.filters.option = [];
      this.filters.criticals = null;
      this.filters.profession = null;
      this.filters.patient_id = null;
      this.emit("manual");
    },
    dateRangeQueryParam() {
      const date = this.$route.query.date;
      if (!date) return;
      return [
        moment(date).startOf("day").toDate(),
        moment(date).endOf("day").toDate(),
      ];
    },
    emit(type) {
      if (type != "manual") {
        const dateRange = this.dateRangeQueryParam();
        const patient_id =
          this.filters.patient_id || this.$route.query.patient_id;
        this.filters.patient_id = patient_id;
        dateRange && (this.filters.dateRange = dateRange);
      }
      this.$emit("filtered", { ...this.filters, type });
    },
    dateRangeChange() {
      this.emit("manual");
    },
    checkFiltersOption() {
      if (this.filters.option.includes(0)) {
        this.filters.option.splice(0);
        this.filters.option[0] = 0;
      }
      this.emit("manual");
    },
  },
  mounted() {
    if (this.target !== "physiotherapy") {
      this.init();
    } else {
      this.emit();
    }
  },
  async created() {
    this.filters.patient_id =
      this.filters.patient_id || this.$route.query.patient_id;
    this.filters.option = this.$route.query.option || [];
    this.filters.criticals = this.$route.query.crtiticals || null;
    this.filters.dateRange = this.$route.query.dateRange || [
      new Date(),
      new Date(),
    ];

    if (this.target == "reports") {
      let patientResponse = await Patient.structureList(
        this.$store.state.structureId
      );
      this.structurePatients = patientResponse.data;
    }

    this.$watch(
      () => this.$route.query,
      (toParams, fromParams) => {
        if (toParams.patient_id == fromParams.patient_id) return;
        this.filters.patient_id = toParams.patient_id;
        this.filters.dateRange = toParams.dateRange || [new Date(), new Date()];
        this.filters.option = this.$route.query.option || [];
        this.filters.criticals = this.$route.query.criticals || null;
        this.emit();
      }
    );
  },
};
</script>
