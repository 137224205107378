<template>
   <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">

      <el-row :gutter="20">
        <el-col :span="6">
            <el-button
                class="float-left"
                round
                :disabled="disablePrev"
                @click="show(test_prev_gds)"
            >
                <font-awesome-icon icon="long-arrow-alt-left" />
                Indietro
            </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_gds.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_gds.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_gds)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_gds" :test_model="test_model_gds" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-col :span="24">
        <el-divider content-position="left"></el-divider>
      </el-col>

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px">

        <!-- 1 -->
        <el-col :span="24">
            <el-col :span="16">1. È soddisfatto della sua vita?</el-col>
            <el-col :span="8">
                <el-form-item>
                    <el-radio-group v-model="test_gds.q1_result" disabled>
                        <el-radio :label="0" border>Si</el-radio>
                        <el-radio :label="1" border>No</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
        </el-col>
        <!-- 2 -->
        <el-col :span="24">
            <el-col :span="16">2. Ha abbandonato molte delle sue attività e dei suoi interessi?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q2_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 3 -->
        <el-col :span="24">
            <el-col :span="16">3. Ritiene che la sua vita sia vuota?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q3_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 4 -->
        <el-col :span="24">
            <el-col :span="16">4. Si annoia spesso?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q4_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 5 -->
        <el-col :span="24">
            <el-col :span="16">5. Ha speranza nel futuro?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q5_result" disabled>
                    <el-radio :label="0" border>Si</el-radio>
                    <el-radio :label="1" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 6 -->
        <el-col :span="24">
            <el-col :span="16">6. È tormentato da pensieri ce non riesce a togliersi dalla testa?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q6_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 7 -->
        <el-col :span="24">
            <el-col :span="16">7. È di buon umore per la maggior parte del tempo?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q7_result" disabled>
                    <el-radio :label="0" border>Si</el-radio>
                    <el-radio :label="1" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 8 -->
        <el-col :span="24">
            <el-col :span="16">8. Teme che le stia per capitare qualcosa di brutto?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q8_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 9 -->
        <el-col :span="24">
            <el-col :span="16">9. Si sente felice per la maggior parte del tempo?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q9_result" disabled>
                    <el-radio :label="0" border>Si</el-radio>
                    <el-radio :label="1" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 10 -->
        <el-col :span="24">
            <el-col :span="16">10. Si sente spesso indifeso?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q10_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 11 -->
        <el-col :span="24">
            <el-col :span="16">11. Le capita spesso di essere irrequieto e nervoso?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q11_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 12 -->
        <el-col :span="24">
            <el-col :span="16">12. Preferisce stare a casa, piuttosto che uscire a fare cose nuove?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q12_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 13 -->
        <el-col :span="24">
            <el-col :span="16">13. Si preoccupa frequentemente per il futuro?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q13_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 14 -->
        <el-col :span="24">
            <el-col
            :span="16"
            >14. Pensa di avere più problemi di memoria ella maggior parte delle persone?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q14_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 15 -->
        <el-col :span="24">
            <el-col :span="16">15. Ha speranza nel futuro?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q15_result" disabled>
                    <el-radio :label="0" border>Si</el-radio>
                    <el-radio :label="1" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 16 -->
        <el-col :span="24">
            <el-col :span="16">16. Si sente spesso abbattuto e triste, adesso?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q16_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 17 -->
        <el-col :span="24">
            <el-col :span="16">17. Trova che la sua condizione attuale sia degna di essere vissuta?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q17_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 18 -->
        <el-col :span="24">
            <el-col :span="16">18. Si tormenta molto pensando al passato?</el-col>
            <el-col :span="8">
            <el-form-item>
               <el-radio-group v-model="test_gds.q18_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 19 -->
        <el-col :span="24">
            <el-col :span="16">19. Trova che la vita sia molto eccitante?</el-col>
            <el-col :span="8">
            <el-form-item>
               <el-radio-group v-model="test_gds.q19_result" disabled>
                    <el-radio :label="0" border>Si</el-radio>
                    <el-radio :label="1" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 20 -->
        <el-col :span="24">
            <el-col :span="16">20. Si sente spesso indifeso?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q20_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 21 -->
        <el-col :span="24">
            <el-col :span="16">21. Si sente pieno di energia?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q21_result" disabled>
                    <el-radio :label="0" border>Si</el-radio>
                    <el-radio :label="1" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 22 -->
        <el-col :span="24">
            <el-col :span="16">22. Pensa di essere in una situazione priva di speranza?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q22_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 23 -->
        <el-col :span="24">
            <el-col
            :span="16"
            >23. Pensa che la maggior parte delle persone sia in una condizione migliore della sua?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q23_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 24 -->
        <el-col :span="24">
            <el-col :span="16">24. Le capita spesso di turbarsi per cose poco importanti?</el-col>
            <el-col :span="8">
            <el-form-item>
               <el-radio-group v-model="test_gds.q24_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 25 -->
        <el-col :span="24">
            <el-col :span="16">25. Ha frequentemente voglia di piangere?</el-col>
            <el-col :span="8">
            <el-form-item>
               <el-radio-group v-model="test_gds.q25_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 26 -->
        <el-col :span="24">
            <el-col :span="16">26. Ha difficoltà a concentrarsi?</el-col>
            <el-col :span="8">
            <el-form-item>
               <el-radio-group v-model="test_gds.q26_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 27 -->
        <el-col :span="24">
            <el-col :span="16">27. Si alza con piacere la mattina?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q27_result" disabled>
                    <el-radio :label="0" border>Si</el-radio>
                    <el-radio :label="1" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 28 -->
        <el-col :span="24">
            <el-col :span="16">28. Preferisce evitare gli incontri sociali</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q28_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 29 -->
        <el-col :span="24">
            <el-col :span="16">29. Le riesce facile prendere delle decisioni?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q29_result" disabled>
                    <el-radio :label="0" border>Si</el-radio>
                    <el-radio :label="1" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 30 -->
        <el-col :span="24">
            <el-col :span="16">30. Ha la mente lucida come prima?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds.q30_result" disabled>
                    <el-radio :label="0" border>Si</el-radio>
                    <el-radio :label="1" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>

        <el-row :gutter="20">
            <!-- BREAK Results -->
            <el-col :span="24">
                <el-divider></el-divider>
            </el-col>
            <!-- Results -->
            <el-col :span="24">
                <el-col :span="12">
                    <el-form-item label="Totale">
                        {{test_gds.total_string}}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Gravitá della depressione">
                        {{test_gds.total_description}}
                    </el-form-item>
                </el-col>
            </el-col>
        </el-row>

        </el-form>
      </el-row>

    </el-card>
  </el-row>
</template>

<script>
    import moment from "moment";
    import Test from "@/js/api/services/Test";
    import Tests from "@/js/pages/Patients/components/Tests";
    import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

    export default {
        name: "Gds",
        components: {
            TestsDisable,
        },
        props: [
            "test",
            "test_prev",
            "test_next",
            "test_name",
            "test_model",
            "test_type"
        ],
        data() {
            return {
                test_gds: this.test,
                test_prev_gds: this.test_prev,
                test_next_gds: this.test_next,
                test_name_gds: this.test_name,
                test_model_gds: this.test_model,
                test_type_gds: this.test_type,
                patient_id_gds: this.test.patient_id,
                loading: true,
                disablePrev: false,
                disableNext: false,
            };
        },
        methods: {
            async show(test_id) {
                this.loading = true;

                let params = {
                    patient_id: this.patient_id_gds,
                    test_model: this.test_model_gds,
                    test_id: test_id,
                    type_id: this.test_type_gds
                };

                const response = await Test.show(params);

                this.test_gds = response.data.test;
                this.test_name_gds = response.data.test_name;
                this.test_prev_gds = response.data.test_prev;
                this.test_next_gds = response.data.test_next;

                this.checkPrevNext();
            },
            checkPrevNext(){
                if(this.test_prev_gds === null)
                    this.disablePrev = true
                else
                    this.disablePrev = false

                if(this.test_next_gds === null)
                    this.disableNext = true
                else
                    this.disableNext = false

                this.loading = false
            }
        },
        beforeMount() {
            this.checkPrevNext();
        }
    };
</script>
