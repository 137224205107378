<template>
  <el-row :gutter="20" class="no-margin-row">
    <div>
      <el-table
        :data="
          patients.filter(
            (data) =>
              !search ||
              data.full_name.toLowerCase().includes(search.toLowerCase())
          )
        "
        :default-sort="{ prop: 'time', order: 'descending' }"
        style="width: 100%"
        v-loading="loading"
        :max-height="maxHeight"
      >
        <el-table-column label="Ospite" sortable prop="full_name">
          <!-- <template slot-scope="scope">
            {{ scope.row.full_name }}
          </template> -->
        </el-table-column>
        <el-table-column
          width="140"
          prop="clean_last_bath"
          label="Ultima volta"
        ></el-table-column>
        <el-table-column
          width="140"
          prop="days_since_last_bath"
          label="Giorni dall'ultimo bagno"
          align="center"
        >
        </el-table-column>
      </el-table>
    </div>
  </el-row>
</template>
<script>
import Bath from "@/js/api/services/Bath";

export default {
  name: "Baths",
  props: ["filters"],
  data() {
    return {
      loading: true,
      labelPosition: "right",
      search: "",
      patients: [],
      current_page: 1,
    };
  },
  methods: {
    async fetch() {
      this.loading = true;
      let params = this.filters;
      const response = await Bath.fetch(params);
      this.patients = response.data;
      this.loading = false;
    },
  },
  watch: {
    filters: {
      handler: function () {
        this.fetch();
      },
      deep: true,
    },
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 300;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style lang="scss" scoped>
.search-input {
  padding: 10px;
}
.paginate {
  padding-top: 15px;
}
</style>
