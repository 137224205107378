var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user.is_chief_nurse || _vm.user.is_doctor)?_c('arki-create-dialog',{ref:"dialog",attrs:{"title":"Comunicazione","dateOptions":"future","rules":_vm.rules,"action":"/comunications"},on:{"submit":_vm.update}}):_vm._e(),_vm._v(" "),_c('arki-broadcast',{ref:"broadcast",attrs:{"listen":".comunication.created"},on:{"update":_vm.update}}),_vm._v(" "),_c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],class:_vm.paginateTable,staticStyle:{"width":"100%"},attrs:{"data":_vm.comunications.data,"default-sort":{ prop: 'time', order: 'descending' },"max-height":_vm.maxHeight,"row-class-name":_vm.rowClass}},[_c('el-table-column',{attrs:{"type":"expand"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-col',{attrs:{"span":24}},[_c('b',[_vm._v("Inserimento:")]),_vm._v(" "+_vm._s(scope.row.full_creator)+" ("+_vm._s(_vm.professions[scope.row.profession].label)+") - "+_vm._s(scope.row.created_at_time)+"\n          ")]),_vm._v(" "),(scope.row.enabled == 0)?_c('el-col',{attrs:{"span":24}},[_c('b',[_vm._v("Annullamento:")]),_vm._v("\n            "+_vm._s(scope.row.full_remover)+"\n            "+_vm._s(scope.row.updater_profession
                ? "(" + _vm.professions[scope.row.updater_profession].label + ")"
                : "")+"\n            -\n            "+_vm._s(scope.row.removed_at_time)+"\n          ")]):_vm._e(),_vm._v(" "),(scope.row.enabled == 1 && scope.row.updater_profession)?_c('el-col',{attrs:{"span":24}},[_c('b',[_vm._v("Modifica:")]),_vm._v("\n            "+_vm._s(scope.row.full_creator)+"\n            "+_vm._s(scope.row.updater_profession
                ? "(" + _vm.professions[scope.row.updater_profession].label + ")"
                : "")+"\n            -\n            "+_vm._s(scope.row.updated_at_time)+"\n          ")]):_vm._e()]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"type","width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{style:({
              backgroundColor: _vm.professions[scope.row.profession].color,
            }),attrs:{"circle":"","size":"mini","icon":"el-icon-chat-line-round"}})]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"clean_date_time","label":"Data e Ora","sortable":"","align":"center","width":"150"}}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"title","label":"Titolo","width":"170px"}}),_vm._v(" "),_c('el-table-column',{attrs:{"prop":"message","label":"Messaggio"}}),_vm._v(" "),(_vm.user.is_chief_nurse || _vm.user.is_doctor)?_c('el-table-column',{attrs:{"prop":"full_names","label":"Elenco visualizzazioni","align":"center","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('arki-comunication-modal',{attrs:{"parentScope":scope.row,"user":_vm.user,"users":_vm.users}})]}}],null,false,3602786886)}):_vm._e(),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Azioni","align":"center","width":"108px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row.enabled == 1)?[(scope.row.creator_id == _vm.user.id && scope.row.enabled === 1)?_c('arki-check-dialog',{staticClass:"float-right margin-left",attrs:{"title":("" + (scope.row.title)),"comunication":scope.row,"action":("comunications/" + (scope.row.id)),"isDeleteble":scope.row.creator_id == _vm.user.id,"rules":_vm.rules,"option":"comunications","method":"patch","row":scope.row},on:{"success":function($event){return _vm.update()}}}):(_vm.comunicationsUnread.length > 0 &&
              _vm.comunicationsUnread.data.includes(scope.row.id) &&
              scope.row.creator_id != _vm.user.id
              )?_c('button',{on:{"click":function($event){return _vm.toggleMessage(scope.row)}}},[_c('em',{staticClass:"el-icon-view"})]):_c('div',[_c('i',{staticClass:"el-icon-check"})])]:undefined}}],null,true)})],1),_vm._v(" "),_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":_vm.comunications.per_page,"total":_vm.comunications.total,"hide-on-single-page":true,"current-page":_vm.current_page},on:{"current-change":_vm.changePage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }