<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_dmi)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_dmi.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_dmi.clean_date_time_test }}</small>
          </span>
        </el-col>


        <el-col :span="6">
          <el-button class="float-right" round :disabled="disableNext" @click="show(test_next_dmi)">
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_dmi" :test_model="test_model_dmi" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-col :span="24">
                <el-divider content-position="center">CRITERI ASSOLUTI</el-divider>
                <el-col :span="24">
                  <el-form-item>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          label="È motivo di appartenenza alla categoria dei non autosufficienti per 'ragioni sanitarie' il riscontro di almeno una delle seguenti situazioni:"
                        />
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :offset="1" :span="24">
                        <el-row>
                          <el-col>
                            <div>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q1_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Grave deficit di forza e/o di movimento ad almeno due arti</el-checkbox>
                              </el-form-item>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q2_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Doppia incontinenza</el-checkbox>
                              </el-form-item>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q3_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Piaghe da decubito</el-checkbox>
                              </el-form-item>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q4_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Gravi disturbi del linguaggio e della comunicazione</el-checkbox>
                              </el-form-item>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q5_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Marcata compromissione delle capacità visive e/o uditive non correggibili con protesi</el-checkbox>
                              </el-form-item>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q6_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Malattie in fase terminale (quando si prevede il decesso entro 3-6 mesi)</el-checkbox>
                              </el-form-item>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q7_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Necessità di terapie multiple e complesse</el-checkbox>
                              </el-form-item>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-divider content-position="center">CRITERI RELATIVI</el-divider>
                  <el-form-item>
                    <el-row>
                      <el-col :span="24">
                        <el-form-item
                          label="È motivo di appartenenza alla categoria dei non autosufficienti per 'ragioni sanitarie' il riscontro di tre o più delle seguenti situazioni:"
                        />
                      </el-col>
                    </el-row>
                    <el-row>
                      <el-col :offset="1" :span="24">
                        <el-row>
                          <el-col>
                            <div>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q8_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Modesto deficit di forza e/o di movimento ad almeno due arti</el-checkbox>
                              </el-form-item>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q9_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Incontinenza saltuaria</el-checkbox>
                              </el-form-item>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q10_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Confusione temporo-spaziale episodica</el-checkbox>
                              </el-form-item>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q11_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Disturbi del linguaggio e della comunicazione moderati</el-checkbox>
                              </el-form-item>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q12_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Deficit sensoriali visivi e/o uditivi solo parzialmente correggibili</el-checkbox>
                              </el-form-item>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q13_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Turbe vertiginose con tendenza alle cadute</el-checkbox>
                              </el-form-item>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q14_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Patologie croniche di rilievo in compenso labile</el-checkbox>
                              </el-form-item>
                              <el-form-item size="mini">
                                <el-checkbox
                                  v-model="test_dmi.q15_result"
                                  :true-label="1"
                                  :false-label="0"
                                >Uso obbligato ma autonomo della carrozzella</el-checkbox>
                              </el-form-item>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_dmi.total_string}}</el-form-item>
              </el-col>
               <el-col :span="12">
                  <el-form-item label="Risultato">{{test_dmi.total_description}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Dmi",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_dmi: this.test,
      test_prev_dmi: this.test_prev,
      test_next_dmi: this.test_next,
      test_name_dmi: this.test_name,
      test_model_dmi: this.test_model,
      test_type_dmi: this.test_type,
      patient_id_dmi: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
      descriptionMap: [
        "Non dipendente",
        "Parzialmente dipendente",
        "Dipendente per motivi sanitari"
      ]
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_dmi,
        test_model: this.test_model_dmi,
        test_id: test_id,
        type_id: this.test_type_dmi
      };

      const response = await Test.show(params);
      this.test_dmi = response.data.test;
      this.test_name_dmi = response.data.test_name;
      this.test_prev_dmi = response.data.test_prev;
      this.test_next_dmi = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_dmi === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_dmi === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  computed: {
    totalDescription() {
      let desc = "Non dipendente";
      let tot = _.parseInt(this.test_dmi.total);
      return this.descriptionMap[tot];
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
