<template>
  <div>
    <el-form label-position="top" :rules="rules" ref="form" :model="form">
      <el-row>
        <el-col :span="12" style="text-align: left">
          <el-form-item label="Ha mangiato?">
            <el-switch
              style="display: block"
              v-model="form.pasto_nulla"
              active-color="#ff4949"
              inactive-color="#13ce66"
              active-text="No"
              inactive-text="Si"
              :active-value="1"
              :inactive-value="0"
              :disabled="mealVerificated()"
            >
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="text-align: right" v-if="!mealVerificated()">
          <el-button type="primary" round @click="resetForm">Reset</el-button>
          <el-button type="success" round @click="saveForm">Salva</el-button>
        </el-col>
        <el-col :span="24" style="margin-top: 20px">
          <el-table :data="fields[mealTypeDesc]" style="width: 100%">
            <el-table-column prop="portata" label="PORTATA">
              <template slot-scope="scope">
                {{ scope.row.label }}
              </template>
            </el-table-column>
            <el-table-column label="Piatto Servito">
              <template slot-scope="scope"
                ><el-input
                  v-model="form[scope.row.key + '_piatto']"
                  :disabled="Boolean(form.pasto_nulla) || mealVerificated()"
                  clearable
                  v-if="mealTypeDesc == 'pranzo' || mealTypeDesc == 'cena'"
                ></el-input>
                <span v-else>
                  <el-input
                    v-if="
                      ['altro_1', 'altro_2', 'altro_3'].includes(scope.row.key)
                    "
                    v-model="form[scope.row.key]"
                    :disabled="Boolean(form.pasto_nulla) || mealVerificated()"
                    clearable
                  ></el-input>
                  <el-checkbox
                    v-model="form[scope.row.key + '_servito']"
                    v-else
                  ></el-checkbox>
                </span>
              </template>
            </el-table-column>
            <el-table-column
              label="Qualità"
              v-if="mealTypeDesc == 'pranzo' || mealTypeDesc == 'cena'"
            >
              <template slot-scope="scope"
                ><el-input
                  v-model="form[scope.row.key + '_quality']"
                  :disabled="
                    Boolean(form.pasto_nulla) ||
                    mealVerificated() ||
                    mealSelected(scope.row.key)
                  "
                  clearable
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="Quantità">
              <template slot-scope="scope">
                <el-slider
                  v-model="form[scope.row.key + '_qta']"
                  :step="1"
                  show-stops
                  :max="scope.row.qta_max"
                  :disabled="
                    Boolean(form.pasto_nulla) ||
                    mealVerificated() ||
                    mealSelected(scope.row.key)
                  "
                  v-if="scope.row.qta_max"
                  :format-tooltip="
                    (value) => {
                      return value + '/' + scope.row.qta_max;
                    }
                  "
                >
                </el-slider>
                <el-input-number
                  v-model="form[scope.row.key + '_qta']"
                  :min="0"
                  :disabled="
                    Boolean(form.pasto_nulla) ||
                    mealVerificated() ||
                    mealSelected(scope.row.key)
                  "
                  v-else
                ></el-input-number>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="24" style="text-align: left">
          <el-form-item label="Note" prop="note">
            <el-input
              type="textarea"
              v-model="form.note"
              :disabled="mealVerificated()"
              autosize
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import MealVerification from "@/js/api/services/MealVerification";
export default {
  name: "pasto",
  props: {
    mealId: Number,
    mealTypeDesc: String,
    mealTypeId: Number,
    patientId: Number,
    mealVerification: Object,
  },

  data() {
    return {
      fields: {
        pranzo: [
          {
            key: "primo",
            label: "Primo",
            qta_max: 4,
          },
          {
            key: "secondo",
            label: "Secondo",
            qta_max: 4,
          },
          {
            key: "contorno",
            label: "Contorno",
            qta_max: 4,
          },
          {
            key: "frullati",
            label: "Frullati Vari",
            qta_max: 4,
          },
          {
            key: "dessert",
            label: "Frutta/Dessert",
            qta_max: 4,
          },
          {
            key: "liquidi",
            label: "Liquidi (indicare il tipo di bevanda)",
            qta_max: 6,
          },
        ],
        cena: [
          {
            key: "primo",
            label: "Primo",
            qta_max: 4,
          },
          {
            key: "secondo",
            label: "Secondo",
            qta_max: 4,
          },
          {
            key: "contorno",
            label: "Contorno",
            qta_max: 4,
          },
          {
            key: "frullati",
            label: "Frullati Vari",
            qta_max: 4,
          },
          {
            key: "dessert",
            label: "Frutta/Dessert",
            qta_max: 4,
          },
          {
            key: "liquidi",
            label: "Liquidi (indicare il tipo di bevanda)",
            qta_max: 6,
          },
        ],
        colazione: [
          {
            key: "latte",
            label: "Latte",
            qta_max: 4,
          },
          {
            key: "caffelatte",
            label: "Caffelatte",
            qta_max: 4,
          },
          {
            key: "orzo",
            label: "Orzo",
            qta_max: 4,
          },
          {
            key: "the",
            label: "Thè",
            qta_max: 4,
          },
          {
            key: "biscotti",
            label: "Biscotti",
          },
          {
            key: "fette_biscottate",
            label: "Fette Biscottate",
          },
          {
            key: "marmellata",
            label: "Marmellata",
          },
          {
            key: "yogurt",
            label: "Yogurt",
            qta_max: 4,
          },
          {
            key: "altro_1",
            label: "Altro",
          },
        ],
        spuntino: [
          {
            key: "orzo",
            label: "Orzo",
            qta_max: 4,
          },
          {
            key: "the",
            label: "Thè",
            qta_max: 4,
          },
          {
            key: "succo",
            label: "Succo di Frutta",
            qta_max: 4,
          },
          {
            key: "biscotti",
            label: "Biscotti",
          },
          {
            key: "fette_biscottate",
            label: "Fette Biscottate",
          },
          {
            key: "altro_1",
            label: "Altro",
          },
          {
            key: "altro_2",
            label: "Altro",
          },
          {
            key: "altro_3",
            label: "Altro",
          },
        ],
        merenda: [
          {
            key: "the",
            label: "Thè",
            qta_max: 4,
          },
          {
            key: "orzo",
            label: "Orzo",
            qta_max: 4,
          },
          {
            key: "bevanda",
            label: "Bevanda",
            qta_max: 4,
          },
          {
            key: "biscotti",
            label: "Biscotti",
          },
          {
            key: "fette_biscottate",
            label: "Fette Biscottate",
          },
          {
            key: "succo",
            label: "Succo di Frutta",
            qta_max: 4,
          },
          {
            key: "yogurt",
            label: "Yogurt",
            qta_max: 4,
          },
          {
            key: "altro_1",
            label: "Altro",
          },
        ],
      },
      form: {},
    };
  },
  watch: {
    mealVerification(value) {
      if (!_.isEmpty(value)) {
        if (!_.isEmpty(value.food)) {
          this.form = value.food;
        }
        this.form.pasto_nulla = value.pasto_nulla;
        this.form.note = value.verification_note;
      } else {
        this.resetForm();
      }
    },
    "form.pasto_nulla"(valueNew, valueOld) {
      if (valueNew === 1 && valueOld === 0) {
        this.rules.note[0].required = true;
        this.resetForm(false);
      } else {
        this.rules.note[0].required = false;
      }
    },
  },
  computed: {
    rules() {
      return {
        note: [
          {
            required: false,
            message: "Note obbligatorie",
            trigger: "blur",
          },
        ],
      };
    },
  },
  methods: {
    resetForm(resetAll = true) {
      if (!_.isEmpty(this.mealVerification)) {
        this.form = this.mealVerification.food;
        this.form.note = this.mealVerification.verification_note;
        this.form.pasto_nulla = this.mealVerification.pasto_nulla;
      } else {
        this.form = {
          primo_qta: 0,
          secondo_qta: 0,
          contorno_qta: 0,
          frullati_qta: 0,
          dessert_qta: 0,
          liquidi_qta: 0,
          latte_servito: false,
          latte_qta: 0,
          caffelatte_servito: false,
          caffelatte_qta: 0,
          orzo_servito: false,
          orzo_qta: 0,
          the_servito: false,
          the_qta: 0,
          succo_servito: false,
          succo_qta: 0,
          bevanda_servito: false,
          bevanda_qta: 0,
          biscotti_servito: false,
          biscotti_qta: 0,
          fette_biscottate_servito: false,
          fette_biscottate_qta: 0,
          marmellata_servito: false,
          marmellata_qta: 0,
          yogurt_servito: false,
          yogurt_qta: 0,
          altro_1_qta: 0,
          altro_2_qta: 0,
          altro_3_qta: 0,
          pasto_nulla: resetAll === false ? this.form.pasto_nulla : 0,
          note: resetAll === false ? this.form.note : null,
        };
      }
    },
    saveForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = {
            mealType: this.mealTypeId,
            mealId: this.mealId,
            patientId: this.patientId,
            form: this.form,
          };
          await MealVerification.store(params);
          this.$emit("savePasto");
        } else {
          return false;
        }
      });
    },
    mealVerificated() {
      if (
        !_.isEmpty(this.mealVerification) ||
        !_.isEmpty(this.mealVerification.food)
      ) {
        return true;
      }

      return false;
    },
    mealSelected(key) {
      if (this.mealTypeDesc == "pranzo" || this.mealTypeDesc == "cena") {
        if (this.form[key + "_piatto"]) {
          return false;
        }
      } else {
        if (
          (["altro_1", "altro_2", "altro_3"].includes(key) && this.form[key]) ||
          this.form[key + "_servito"]
        ) {
          return false;
        }
      }
      return true;
    },
  },
  created() {
    this.resetForm();
  },
};
</script>