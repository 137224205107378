<script>
export default {
  name: 'KeyValueRow',
  props: ['label', 'value', 'right']
};
</script>

<template>
  <el-col>
    <el-row :gutter="5">
      <el-col :span="12" :style="{textAlign: (right ? 'right' : 'left')}">{{ label }}:</el-col>
      <el-col :span="12">
        <strong>{{ value }}</strong>
      </el-col>
    </el-row>
  </el-col>
</template>

<style scss scoped>
.label-right {
  text-align: right;
}
</style>