<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_kane)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_kane.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_kane.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_kane)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_kane" :test_model="test_model_kane" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-row type="flex" class="row-bg" justify="center">
                <el-card class="box-card">
                  <div slot="header" class="clearfix">
                    <span>
                      <i class="el-icon-info" /> Legenda
                    </span>
                  </div>
                  <div>0 - Mai</div>
                  <div>1 - Raramente (poche volte/mese)</div>
                  <div>3 - Occasionalmente (almeno 2 volte/settimana)</div>
                  <div>6 - Spesso (tutti i giorni)</div>
                </el-card>
              </el-row>
            </el-col>
            <el-col :span="24" v-for="(category, idx) in categories" :key="idx + '-cat'">
              <el-divider content-position="center">{{category.name }}</el-divider>

              <el-col :span="24" v-for="(question, idx2) in category.questions" :key="idx2 + '-q'">
                <el-col :span="14">
                  <el-form-item :label="question.label" />
                </el-col>
                <el-col :span="10">
                  <el-row>
                    <el-col>
                      <el-form-item :prop="test_kane.result">
                        <div>
                          <el-radio-group v-model="test_kane[question.result]">
                            <el-radio-button
                              v-for="(opt, idx3) in question.options"
                              :key="idx3 + '-opt'"
                              :label="opt"
                            />
                          </el-radio-group>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
              </el-col>
            </el-col>
          </el-row>
          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_kane.total_string}}</el-form-item>
              </el-col>
            </el-col>
             <el-col :span="24">
               <el-divider />
              <el-form-item label="Note">
                <el-input type="textarea" v-model="test_kane['note']"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import { KANE } from "@/js/pages/Tests/constants";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Kane",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_kane: this.test,
      test_prev_kane: this.test_prev,
      test_next_kane: this.test_next,
      test_name_kane: this.test_name,
      test_model_kane: this.test_model,
      test_type_kane: this.test_type,
      patient_id_kane: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
      categories: KANE.categories
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_kane,
        test_model: this.test_model_kane,
        test_id: test_id,
        type_id: this.test_type_kane
      };

      const response = await Test.show(params);
      this.test_kane = response.data.test;
      this.test_name_kane = response.data.test_name;
      this.test_prev_kane = response.data.test_prev;
      this.test_next_kane = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_kane === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_kane === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
