import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Bath extends ArkiBaseService {

    static endpoint = 'baths';

    static priority(id){
        return Api.get(`${this.endpoint}/priority/${id}`);
    }

    static store(action, params) {
        return Api.post(action, params);
    }
    
}