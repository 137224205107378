<template>
    <div>
        <button v-if="parentScope.creator_id == user.id" class="el-icon-message" @click="() => {visible = true}">
        </button>
        <md-dialog :md-active.sync="visible" :md-clicked-outside="() => {visible = false}"
            style="width: 65vw; max-width: none; padding-right: 20px; top: 50% !important;">
            <md-dialog-title v-if="parentScope.full_names" style="text-align: center">Messaggio letto
                da</md-dialog-title>
            <md-dialog-title v-else style="text-align: center">Ancora nessun utente ha letto la
                comunicazione.</md-dialog-title>
            <div style="position: absolute; right: 0">
                <md-button class="md-icon-button" @click="visible = false">
                    <md-icon>close</md-icon>
                </md-button>
            </div>
            <md-dialog-content v-if="parentScope.full_names">
                <!-- delete the creator from users -->
                <el-table :data="_.filter(users, function(u) {
                    return u.id !== parentScope.user.id && parentScope.full_names.includes(u.full_name)})">
                    <el-table-column label="Operatore" prop="oper">
                        <template slot-scope="scope">
                            {{ scope.row.full_name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Letto" prop="lectures">
                        <template slot-scope="scope">
                            <i v-if="parentScope.full_names.includes(scope.row.full_name)" class="el-icon-check"></i>
                        </template>
                    </el-table-column>
                    <el-table-column label="Data e ora">
                        <template slot-scope="scope">
                            {{ getDateTime(scope.row.id) }}
                        </template>
                    </el-table-column>
                </el-table>
                <el-divider></el-divider>
                <md-dialog-title style="text-align: center; display: flex; justify-content: center">
                    Messaggio ancora da leggere</md-dialog-title>
                <el-table :data="_.filter(users, function(u) {
                    return u.id !== parentScope.user.id && !parentScope.full_names.includes(u.full_name)})">
                    <el-table-column label="Operatore">
                        <template slot-scope="scope">
                            {{ scope.row.full_name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Letto" prop="lectures">
                        <template slot-scope="scope">
                            <i v-if="parentScope.full_names.includes(scope.row.full_name)" class="el-icon-check"></i>
                        </template>
                    </el-table-column>
                    <el-table-column label="Data e ora">
                        <template slot-scope="scope">
                            {{ getDateTime(scope.row.id) }}
                        </template>
                    </el-table-column>
                </el-table>
            </md-dialog-content>
        </md-dialog>
    </div>
</template>

<script>

export default {
  name: "ArkiComunication",
  props: ["parentScope", "user", "users"],
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    getDateTime(user_id){
        if(this.parentScope.seen_by.find(item => item.user_id === user_id)){
            return this.parentScope.seen_by.find(item => item.user_id === user_id).clean_date_time;
        }
    },
  }
};
</script>
