<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <!-- INIT -->
      <el-row :gutter="20">
        <el-col :span="24">
          <el-divider content-position="left">Prova indiretta di deglutizione</el-divider>
        </el-col>
        <el-col :span="24">
          <el-col :span="16">
            <el-form-item
              label="Vigilanza (Il paziente deve essere vigile per almeno 15 minuti)"
              label-width="20px"
              required
            />
          </el-col>
          <el-col :span="8">
            <el-form-item prop="q1_result">
              <el-radio-group v-model="result.q1_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>NO</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="16">
            <el-form-item label="Tossire/o schiarire la voce (tosse volontaria)" required />
          </el-col>
          <el-col :span="8">
            <el-form-item prop="q2_result">
              <el-radio-group v-model="result.q2_result">
                <el-radio :label="1" border>SI</el-radio>
                <el-radio :label="0" border>NO</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="16">
            <el-form-item label="Deglutizione senza difficoltà" required />
          </el-col>
          <el-col :span="8">
            <el-form-item prop="q3_result">
              <el-radio-group v-model="result.q3_result">
                <el-radio :label="1" border>SI</el-radio>
                <el-radio :label="0" border>NO</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="16">
            <el-form-item label="Perdita della saliva" required />
          </el-col>
          <el-col :span="8">
            <el-form-item prop="q4_result">
              <el-radio-group v-model="result.q4_result">
                <el-radio :label="0" border>SI</el-radio>
                <el-radio :label="1" border>NO</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="16">
            <el-form-item
              label="Modifica della voce (rauca, gorgogliante, velata, debole)"
              required
            />
          </el-col>
          <el-col :span="8">
            <el-form-item prop="q5_result">
              <el-radio-group v-model="result.q5_result">
                <el-radio :label="0" border>SI</el-radio>
                <el-radio :label="1" border>NO</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>

      <!-- CREMOSO -->
      <transition name="el-zoom-in-top">
        <el-row :gutter="20" v-if="creamy">
          <el-col :span="24">
            <el-divider content-position="left">Prova diretta di deglutizione</el-divider>
          </el-col>
          <el-col :span="24">
            <el-divider content-position="center">CREMOSO</el-divider>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item label="DEGLUTIZIONE" required />
            </el-col>

            <el-col :span="24">
              <el-form-item prop="q6_result">
                <el-radio-group v-model="result.q6_result">
                  <el-col :span="24">
                    <el-col :span="16">
                      <el-form-item label="Deglutizione non possibile" />
                    </el-col>
                    <el-col :span="8">
                      <el-form-item>
                        <el-radio :label="0" border>Non possibile</el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>

                  <el-col :span="24">
                    <el-col :span="16">
                      <el-form-item label="Deglutizione ritardata ( > 2 sec )" />
                    </el-col>
                    <el-col :span="8">
                      <el-form-item>
                        <el-radio :label="1" border>Ritardata</el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>

                  <el-col :span="24">
                    <el-col :span="16">
                      <el-form-item label="Deglutizione avvenuta correttamente" />
                    </el-col>
                    <el-col :span="8">
                      <el-form-item>
                        <el-radio :label="2" border>Corretta</el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="16">
              <el-form-item
                label="TOSSE (involontaria): prima, durante o dopo la deglutizione – fino a 3 minuti dopo"
                required
              />
            </el-col>
            <el-col :span="8">
              <el-form-item prop="q7_result">
                <el-radio-group v-model="result.q7_result">
                  <el-radio :label="0" border>SI</el-radio>
                  <el-radio :label="1" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="16">
              <el-form-item label="SCIALORREA" required />
            </el-col>
            <el-col :span="8">
              <el-form-item prop="q8_result">
                <el-radio-group v-model="result.q8_result">
                  <el-radio :label="0" border>SI</el-radio>
                  <el-radio :label="1" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="16">
              <el-form-item
                label="MODIFICA DELLA VOCE: (ascoltare la voce prima e dopo la deglutizione – il paziente deve pronunciare 'O')"
                required
              />
            </el-col>
            <el-col :span="8">
              <el-form-item prop="q9_result">
                <el-radio-group v-model="result.q9_result">
                  <el-radio :label="0" border>SI</el-radio>
                  <el-radio :label="1" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>
        </el-row>
      </transition>
      <!-- LIQUIDO -->
      <transition name="el-zoom-in-top">
        <el-row :gutter="20" v-if="liquid">
          <el-col :span="24">
            <el-divider content-position="center">LIQUIDO</el-divider>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item label="DEGLUTIZIONE" required />
            </el-col>

            <el-col :span="24">
              <el-form-item prop="q10_result">
                <el-radio-group v-model="result.q10_result">
                  <el-col :span="24">
                    <el-col :span="16">
                      <el-form-item label="Deglutizione non possibile" />
                    </el-col>
                    <el-col :span="8">
                      <el-form-item>
                        <el-radio :label="0" border>Non possibile</el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>

                  <el-col :span="24">
                    <el-col :span="16">
                      <el-form-item label="Deglutizione ritardata ( > 2 sec )" />
                    </el-col>
                    <el-col :span="8">
                      <el-form-item>
                        <el-radio :label="1" border>Ritardata</el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>

                  <el-col :span="24">
                    <el-col :span="16">
                      <el-form-item label="Deglutizione avvenuta correttamente" />
                    </el-col>
                    <el-col :span="8">
                      <el-form-item>
                        <el-radio :label="2" border>Corretta</el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="16">
              <el-form-item
                label="TOSSE (involontaria): prima, durante o dopo la deglutizione – fino a 3 minuti dopo"
                required
              />
            </el-col>
            <el-col :span="8">
              <el-form-item prop="q11_result">
                <el-radio-group v-model="result.q11_result">
                  <el-radio :label="0" border>SI</el-radio>
                  <el-radio :label="1" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="16">
              <el-form-item label="SCIALORREA" required />
            </el-col>
            <el-col :span="8">
              <el-form-item prop="q12_result">
                <el-radio-group v-model="result.q12_result">
                  <el-radio :label="0" border>SI</el-radio>
                  <el-radio :label="1" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="16">
              <el-form-item
                label="MODIFICA DELLA VOCE: (ascoltare la voce prima e dopo la deglutizione – il paziente deve pronunciare 'O')"
                required
              />
            </el-col>
            <el-col :span="8">
              <el-form-item prop="q13_result">
                <el-radio-group v-model="result.q13_result">
                  <el-radio :label="0" border>SI</el-radio>
                  <el-radio :label="1" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>
        </el-row>
      </transition>

      <!-- SOLIDO -->
      <transition name="el-zoom-in-top">
        <el-row :gutter="20" v-if="solid">
          <el-col :span="24">
            <el-divider content-position="center">SOLIDO</el-divider>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item label="DEGLUTIZIONE" required />
            </el-col>

            <el-col :span="24">
              <el-form-item prop="q14_result">
                <el-radio-group v-model="result.q14_result">
                  <el-col :span="24">
                    <el-col :span="16">
                      <el-form-item label="Deglutizione non possibile" />
                    </el-col>
                    <el-col :span="8">
                      <el-form-item>
                        <el-radio :label="0" border>Non possibile</el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>

                  <el-col :span="24">
                    <el-col :span="16">
                      <el-form-item label="Deglutizione ritardata ( > 10 sec )" />
                    </el-col>
                    <el-col :span="8">
                      <el-form-item>
                        <el-radio :label="1" border>Ritardata</el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>

                  <el-col :span="24">
                    <el-col :span="16">
                      <el-form-item label="Deglutizione avvenuta correttamente" />
                    </el-col>
                    <el-col :span="8">
                      <el-form-item>
                        <el-radio :label="2" border>Corretta</el-radio>
                      </el-form-item>
                    </el-col>
                  </el-col>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="16">
              <el-form-item
                label="TOSSE (involontaria): prima, durante o dopo la deglutizione – fino a 3 minuti dopo"
                required
              />
            </el-col>
            <el-col :span="8">
              <el-form-item prop="q15_result">
                <el-radio-group v-model="result.q15_result">
                  <el-radio :label="0" border>SI</el-radio>
                  <el-radio :label="1" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="16">
              <el-form-item label="SCIALORREA" required />
            </el-col>
            <el-col :span="8">
              <el-form-item prop="q16_result">
                <el-radio-group v-model="result.q16_result">
                  <el-radio :label="0" border>SI</el-radio>
                  <el-radio :label="1" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="16">
              <el-form-item
                label="MODIFICA DELLA VOCE: (ascoltare la voce prima e dopo la deglutizione – il paziente deve pronunciare 'O')"
                required
              />
            </el-col>
            <el-col :span="8">
              <el-form-item prop="q17_result">
                <el-radio-group v-model="result.q17_result">
                  <el-radio :label="0" border>SI</el-radio>
                  <el-radio :label="1" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>
        </el-row>
      </transition>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="Risultato">
              <el-input v-model="totalDescription" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Indicazione">
              <el-input v-model="instruction" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { GUSS } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Guss",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 20
      },
      result: {},
      creamy: false,
      liquid: false,
      solid: false,
      testRules: GUSS.rules(17),
      initResults: GUSS.createResults(2, 6),
      creamyResults: GUSS.createResults(6, 10),
      liquidResults: GUSS.createResults(10, 14),
      solidResults: GUSS.createResults(14, 18)
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_description: this.totalDescription
          };
          this.$emit("submitTest", this.form, result);
        } else {
          return false;
        }
      });
    },
    totals() {
      const q1Selected = !_.isNil(this.result.q1_result);
      let totalInit = GUSS.totalOf(this.initResults, this.result);
      q1Selected && totalInit++;
      let totalCreamy = GUSS.totalOf(this.creamyResults, this.result);
      let totalLiquid = GUSS.totalOf(this.liquidResults, this.result);
      let totalSolid = GUSS.totalOf(this.solidResults, this.result);
      return { totalInit, totalCreamy, totalLiquid, totalSolid };
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    totalDescription() {
      return GUSS.totalDescription(this.form);
    },
    instruction() {
      return GUSS.instruction(this.totals());
    },
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        let { totalInit, totalCreamy, totalLiquid, totalSolid } = this.totals();

        if (totalInit < 5 && totalCreamy > 0) {
          this.result = _.omit(this.result, this.creamyResults);
          this.creamy = false;
        }

        if (totalCreamy < 5 && totalLiquid > 0) {
          this.result = _.omit(this.result, this.liquidResults);
          this.liquid = false;
        }

        if (totalLiquid < 5 && totalSolid > 0) {
          this.result = _.omit(this.result, this.solidResults);
          this.solid = false;
        }

        let results = [totalInit, totalCreamy, totalLiquid, totalSolid];

        let total = results.reduce(_.add, 0);
        this.form.total = total;
        this.creamy = total >= 5 ? (this.creamy = true) : (this.creamy = false);
        this.liquid =
          total >= 10 ? (this.liquid = true) : (this.liquid = false);
        this.solid = total >= 15 ? (this.solid = true) : (this.solid = false);
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.bottom-space {
  margin-bottom: 50px;
}
</style>