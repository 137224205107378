<template>
   <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">

      <el-row :gutter="20">
        <el-col :span="6">
            <el-button
                class="float-left"
                round
                :disabled="disablePrev"
                @click="show(test_prev_braden)"
            >
                <font-awesome-icon icon="long-arrow-alt-left" />
                Indietro
            </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_braden.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_braden.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_braden)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_braden" :test_model="test_model_braden" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px">

        <el-col :span="12">
            <!-- A -->
            <el-col :span="24">
            <el-divider content-position="left">A - Percezione sensoriale</el-divider>
            </el-col>
            <el-col :span="24">
            <el-form-item>
                <el-input v-model="options_A[test_braden.q1_result]" readonly></el-input>
            </el-form-item>
            </el-col>

            <!-- B -->
            <el-col :span="24">
            <el-divider content-position="left">B - Umidità</el-divider>
            </el-col>
            <el-col :span="24">
            <el-form-item>
                <el-input v-model="options_B[test_braden.q2_result]" readonly></el-input>
            </el-form-item>
            </el-col>

            <!-- C -->
            <el-col :span="24">
            <el-divider content-position="left">C - Attività</el-divider>
            </el-col>
            <el-col :span="24">
            <el-form-item>
                <el-input v-model="options_C[test_braden.q3_result]" readonly></el-input>
            </el-form-item>
            </el-col>
        </el-col>

        <el-col :span="12">
            <!-- D -->
            <el-col :span="24">
            <el-divider content-position="left">D - Mobilità</el-divider>
            </el-col>
            <el-col :span="24">
            <el-form-item>
                <el-input v-model="options_D[test_braden.q4_result]" readonly></el-input>
            </el-form-item>
            </el-col>

            <!-- E -->
            <el-col :span="24">
            <el-divider content-position="left">E - Nutrizione</el-divider>
            </el-col>
            <el-col :span="24">
            <el-form-item>
                <el-input v-model="options_E[test_braden.q5_result]" readonly></el-input>
            </el-form-item>
            </el-col>

            <!-- F -->
            <el-col :span="24">
            <el-divider content-position="left">F - Frizione e scivolamento</el-divider>
            </el-col>
            <el-col :span="24">
            <el-form-item>
                <el-input v-model="options_F[test_braden.q6_result]" readonly></el-input>
            </el-form-item>
            </el-col>
        </el-col>

        <el-row :gutter="20">
            <!-- BREAK Results -->
            <el-col :span="24">
                <el-divider></el-divider>
            </el-col>
            <!-- Results -->
            <el-col :span="24">
                <el-col :span="12">
                    <el-form-item label="Totale">
                        {{test_braden.total_string}}
                    </el-form-item>
                </el-col>
            </el-col>
        </el-row>

        </el-form>
      </el-row>

    </el-card>
  </el-row>
</template>

<script>
    import moment from "moment";
    import Test from "@/js/api/services/Test";
    import Tests from "@/js/pages/Patients/components/Tests";
    import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

    export default {
        name: "Braden",
        components: {
            TestsDisable,
        },
        props: [
            "test",
            "test_prev",
            "test_next",
            "test_name",
            "test_model",
            "test_type"
        ],
        data() {
            return {
                test_braden: this.test,
                test_prev_braden: this.test_prev,
                test_next_braden: this.test_next,
                test_name_braden: this.test_name,
                test_model_braden: this.test_model,
                test_type_braden: this.test_type,
                patient_id_braden: this.test.patient_id,
                loading: true,
                disablePrev: false,
                disableNext: false,
                options_A: {
                    1: "Completamente limitata",
                    2: "Molto limitata",
                    3: "Leggermente limitata",
                    4: "Non limitata",
                },
                options_B: {
                    1: "Sempre bagnato",
                    2: "Spesso bagnato",
                    3: "Occasionalmente bagnato",
                    4: "Raramente bagnato",
                },
                options_C: {
                    1: "Allettato",
                    2: "In poltrona",
                    3: "Cammina occasionalmente",
                    4: "Cammina frequentemente",
                },
                options_D: {
                    1: "Immobile",
                    2: "Mobilità molto limitata",
                    3: "Mobilità parzialmente limitata",
                    4: "Limitazioni assenti",
                },
                options_E: {
                    1: "Inadeguata",
                    2: "Probabilmente inadeguata",
                    3: "Adeguata",
                    4: "Eccellente",
                },
                options_F: {
                    1: "Problema",
                    2: "Problema potenziale",
                    3: "Senza problemi",
                },
            };
        },
        methods: {
            async show(test_id) {
                this.loading = true;

                let params = {
                    patient_id: this.patient_id_braden,
                    test_model: this.test_model_braden,
                    test_id: test_id,
                    type_id: this.test_type_braden
                };

                const response = await Test.show(params);

                this.test_braden = response.data.test;
                this.test_name_braden = response.data.test_name;
                this.test_prev_braden = response.data.test_prev;
                this.test_next_braden = response.data.test_next;

                this.checkPrevNext();
            },
            checkPrevNext(){
                if(this.test_prev_braden === null)
                    this.disablePrev = true
                else
                    this.disablePrev = false

                if(this.test_next_braden === null)
                    this.disableNext = true
                else
                    this.disableNext = false

                this.loading = false
            }
        },
        beforeMount() {
            this.checkPrevNext();
        }
    };
</script>
