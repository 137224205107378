<template>
  <md-toolbar class="md-primary md-large md-primary-dark">
    <div class="arki-department-header">
      <el-button
        type="primary"
        @click="superadmin"
        size="small"
        icon="el-icon-lock"
        v-if="this.$store.state.user.role_id == 1"
        >Superadmin</el-button
      >
      <el-button class="button-toolbar" size="small" icon="el-icon-user">{{
        $store.state.user.surname + ' ' + $store.state.user.name
      }}</el-button>

      <el-select v-model="department_id" class="department-select" size="small">
        <el-option
          v-for="item in optionDepartments"
          :key="item.id_pianoreparto"
          :label="item.desc_pianoreparto"
          :value="item.id_pianoreparto"
          :disabled="!item.has_patients"
        ></el-option>
      </el-select>

      <el-button type="danger" @click="logout" size="small" icon="el-icon-switch-button">Esci</el-button>
    </div>
    <div class="md-toolbar-row">
      <arki-notifications />
      <!-- <div class="md-title" v-if="$route.name !== 'Scheda Ospite'">{{$route.name}}</div> -->
      <div class="md-title" v-if="!['Scheda Ospite', 'Patient CDI', 'Patient Sosia'].includes($route.name)">
        {{ $route.name }}
      </div>
      <div class="md-title" v-else>{{ $store.state.patientName }}</div>
    </div>
  </md-toolbar>
</template>
<script>
import ArkiNotifications from '@/js/components/ArkiNotifications';

export default {
  name: 'ArkiToolbar',
  data() {
    return {
      department_id: this.$store.state.department
    };
  },
  components: {
    ArkiNotifications
  },
  computed: {
    structure() {
      return this.$store.state.structure;
    },
    department() {
      return this.$store.state.department;
    },
    username() {
      return this.$store.state.user.surname + ' ' + this.$store.state.user.name;
    },
    optionDepartments() {
      return this.$store.state.departmentList;
    },
    user() {
      return this.$store.state.user;
    }
  },
  watch: {
    department_id: function (val) {
      this.switchDepartment(val);
    }
  },
  methods: {
    switchDepartment(val) {
      this.$router.push({ name: 'Diario' });
      let params = {
        department: val
      };
      axios.post('switch_department', params).then((res) => {
        location.reload();
      });
    },
    logout() {
      this.$confirm('Confermi di voler uscire?', 'Attenzione', {
        confirmButtonText: 'Sì',
        cancelButtonText: 'Annulla',
        type: 'success'
      })
        .then(() => {
          this.$auth.logout();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Operazione annullata'
          });
        });
    },
    superadmin() {
      this.$confirm('Vuoi proseguire la navigazione nella sezione superadmin?', 'Attenzione', {
        confirmButtonText: 'Sì',
        cancelButtonText: 'Annulla',
        type: 'success'
      })
        .then(() => {
          this.$router.push({ name: 'Dashboard' });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Operazione annullata'
          });
        });
    }
  }
};
</script>
<style lang="scss">
.arkiexit {
  position: fixed;
  right: 15px;
  padding: 0 !important;
  height: 45px;
  width: 45px;
  background: transparent;
  border: none;
  text-align: center;
  color: #e6a23c !important;
  margin-right: 6px;
}

.arki-department-header {
  z-index: 2;
  position: fixed;
  font-weight: bold;
  color: white;
  background: #123;
  padding: 4px 10px 4px 10px;
  height: 50px;
  line-height: 41px;
  text-align: center;
  width: 100%;
  top: 0px;
  left: 0px;

  .button-toolbar {
    color: #fff !important;
    background: transparent;
    border-color: #fff !important;
  }
  .el-button--small {
    padding: 8px 12px 7px 12px;
    top: 2px;
    position: relative;
  }
}

.page-title {
  margin: 0 auto;
  font-size: 20px;
}
.user-button:hover,
.user-button:focus {
  background: rgba(255, 255, 255, 0.1) !important;
  border: 1px solid #dcdfe6 !important;
  color: #606266 !important;
}

.department-select {
  width: auto !important;
  .el-input {
    input {
      color: white;
      background-color: transparent !important;
      padding-right: 0px;
    }
    .el-input__suffix {
      display: none;
    }
  }
}
</style>
