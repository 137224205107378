<script>
export default {
  name: "SelectActivityStatus",
  inheritAttrs: false,
  props: ["value", "defaultValue"],
  data() {
    return {
      filter: {
        value: 1,
      },
      options: [
        { label: "Attive", value: 1 },
        { label: "Sospese", value: 0 },
      ],
    };
  },
  methods: {
    updateFilter(data) {
      this.filter.value = data;
      this.$emit("input", data);
    },
  },
};
</script>

<template>
  <div>
    <span>Stato</span>
    <el-select
      v-bind="$attrs"
      v-model="filter.value"
      @input="updateFilter"
      clearable
      filterable
      remote
      size="small"
      placeholder="Stato Attivitá"
    >
      <el-option
        v-for="item in options"
        :key="item.label"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<style lang="scss" scoped>
</style>