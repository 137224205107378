<template>
    <div>
        <arki-filters ref="filters" @filtered="fetch" target="diary" :dateRangeFilter="true" :optionsFilter="false" :options="notes_arkifilters" :professionsFilter="true" :optionSpan="8"/>
        <el-button
            class="add-button"
            type="success"
            style="margin-right: 120px;"
            @click="needsExport(currentFilters)"
        >STAMPA DIARIO</el-button>
        <arki-broadcast ref="broadcast" listen=".note.created" @update="update"/>
        <arki-create-dialog title="Nota" dateOptions="past" :rules="rules" action="/diary" @submit="update"/>
        <div class="block with-filters " v-loading="loading">
            <arki-timeline action="Note" v-show="!loading" :items="notes" :hasIcon="true" confirmDescription="Annullare nota" @update="update"/>
            <div class="show-more text-center">
                <el-button icon="el-icon-arrow-down" circle @click="load" :disabled="disabled" :loading="moreLoading"></el-button>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';

import ArkiFilters from '@/js/components/ArkiFilters';
import ArkiCreateDialog from '@/js/components/ArkiCreateDialog';
import ArkiTimeline from '@/js/components/ArkiTimeline';
import ArkiBroadcast from '@/js/components/ArkiBroadcast';
import ArkFile from "@/js/helpers/file";
import Settings from "@/js/api/services/Settings";

import Note from '@/js/api/services/Note'

export default {
    name: 'Diary',
    components: {
        ArkiFilters,
        ArkiCreateDialog,
        ArkiTimeline,
        ArkiBroadcast
    },
    data(){
        return{
            loading: true,
            moreLoading: false,
            notes: [],
            lastPage: null,
            currentPage: 1,
            currentFilters: {},
            notes_arkifilters: [],
            rules: {
                date: [ { required: true, message: 'Inserire data', trigger: 'blur' } ],
                time: [ { required: true, message: 'Inserire orario', trigger: 'blur' } ],
                patient_id: [ { required: true, message: 'Inserire paziente', trigger: 'blur' } ],
                description: [ { required: true, message: 'Inserire descrizione', trigger: 'blur' } ]
            },
        }
    },
    computed: {
        noMore() { return this.currentPage == this.lastPage; },
        disabled() { return this.noMore },
    },
    methods: {
        async init() {
            const notes_arkifilters = await Settings.fetch('diary');
            this.notes_arkifilters = notes_arkifilters.data;
        },
        async fetch(filters){
            this.loading = true;
            this.currentFilters = filters;
            let params = {
                date_from: moment(filters.dateRange[0]).format('DD/MM/YYYY'),
                date_to: moment(filters.dateRange[1]).format('DD/MM/YYYY'),
                note_category: filters.option,
                patient_id: filters.patient_id,
                profession: filters.profession,
                page: 1
            }
            const response = await Note.fetch(params);
            this.notes = response.data.notes.data;
            this.currentPage = 1;
            this.lastPage = response.data.notes.last_page;
            this.loading = false;
        },
        update(){
            if(this.$refs.filters){
                this.loading = true;
                this.notes = [];
                this.lastPage = null;
                this.currentPage = 1;
                this.$refs.filters.emit();
                this.$refs.broadcast.reset();
            }
        },
        async load(){
            this.moreLoading = true;
            this.currentPage++;
            let filters = this.currentFilters;
            let params = {
                date_from: moment(filters.dateRange[0]).format('DD/MM/YYYY'),
                date_to: moment(filters.dateRange[1]).format('DD/MM/YYYY'),
                note_category: filters.option,
                patient_id: filters.patient_id,
                page: this.currentPage,
                profession: filters.profession
            }
            const response = await Note.fetch(params);
            this.notes = this.notes.concat(response.data.notes.data);
            this.moreLoading = false;
        },
        async needsExport(currentFilters) {
            this.downloadLoading = true;
            ArkFile.downloadFromRequest(
                Note.needsExport(currentFilters),
                ""
            ).finally(() => {
                this.downloadLoading = false;
            });
        },
    },
    mounted() {
        this.init();
    },
}
</script>
