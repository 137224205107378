<script>
import SosiaService from "@/js/api/services/Sosia2";
import DatiAnagrafici from "./components/DatiAnagrafici";
import Ausili from "./components/Ausili";
import Criticita from "./components/Criticita";
import DatiTrattamento from "./components/DatiTrattamento";
import Diagnosi from "./components/Diagnosi";
import RetteTariffe from "./components/RetteTariffe";
import SospensioniTrattamento from "./components/SospensioniTrattamento";
import IndicatoriSosia from "./components/IndicatoriSosia";
import TesseraTeam from "./components/TesseraTeam";

import ArkFile from "@/js/helpers/file";
import SosiaAbsencesTable from "./components/SosiaAbsencesTable.vue";
import SosiaTeamTable from "./components/SosiaTeamTable.vue";
import SosiaRateTable from "./components/SosiaRateTable.vue";
import SosiaTreatmentForm from "./components/SosiaTreatmentForm.vue";
import SosiaEvaluationsTable from "./components/SosiaEvaluationsTable.vue";

export default {
  name: "SosiaPatient",
  components: {
    DatiAnagrafici,
    DatiTrattamento,
    Ausili,
    Criticita,
    Diagnosi,
    RetteTariffe,
    SospensioniTrattamento,
    IndicatoriSosia,
    TesseraTeam,
    SosiaAbsencesTable,
    SosiaTeamTable,
    SosiaRateTable,
    SosiaTreatmentForm,
    SosiaEvaluationsTable,
  },
  data() {
    return {
      loading: true,
      patientId: this.$route.params.id,
      config: {},
      record: null,
      nextPage: false,
      prevPage: false,
      printing: false,
      formVisible: false,
      newRecord: false,
      pickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
    };
  },
  computed: {
    patient() {
      return this.$store.state.patients.find((x) => x.id == this.patientId);
    },
    patientName() {
      return [
        _.get(this.record, 'cognome'),
        _.get(this.record, 'nome'),
      ].join(' ');
    },
    deletedRecord() {
      if (!this.record) return false;
      return Boolean(this.record.deleted_at);
    },
    hasEntryEvaluation() {
      if (this.create || !this.record) return false;
      return this.record.valutazioni.some(
        (v) => v.tipo_valutazione == 1 && v.deleted_at == null
      );
    },
    hasDismissal() {
      if (!this.record) return false;
      return this.record.data_dimissione != null;
    },
    trattamento() {
      if (!this.record) return "";
      return `${this.record.anno_ricovero}${String(
        this.record.numero_pratica
      ).padStart(6, "0")}`;
    },
    treatmentInfo() {
      return _.pick(this.record, [
        "id",
        "tipo_ospite",
        "data_ammissione",
        "data_dimissione",
        "tipo_assistenza",
        "tipo_posto_letto",
        "classificazione_ospite",
      ]);
    },
  },
  methods: {
    async fetchPatientSosia(page = 1) {
      this.newRecord = false;
      this.loading = true;
      SosiaService.findByPatient(this.patientId, { page })
        .then(({ data }) => {
          Object.assign(this, {
            config: data.config,
            record: data.data,
            nextPage: data.next,
            prevPage: data.prev,
          });
          this.setupPatient();
        })
        .finally(() => (this.loading = false));
    },
    setupPatient() {
      this.$store.commit("setPatientName", this.patientName);
    },
    deleteTreatment(id = this.record.id) {
      this.$confirm(`Sicuro di voler cancellare?`, "Attenzione", {
        type: "warning",
      })
        .then(() => {
          SosiaService.deleteTreatment(id).then(() => {
            this.fetchPatientSosia();
            this.$notify({
              type: "success",
              message: "Cancellazione eseguita",
            });
          });
        })
        .catch(() => {});
    },
    editTreatment() {
      this.edit = this.record;
      this.formVisible = true;
    },
    create() {
      this.newRecord = true;
      this.formVisible = true;
    },
    printPdf() {
      this.printing = true;
      ArkFile.downloadFromRequest(
        SosiaService.printPdf(this.record.id),
        "sosia_cdi.pdf"
      ).finally(() => (this.printing = false));
    },
  },
  beforeDestroy() {
    this.$root.$off("fetchPatient", this.fetchPatientSosia);
  },
  created() {
    this.$root.$on("fetchPatient", this.fetchPatientSosia);
    this.fetchPatientSosia();
  },
};
</script>

<template>
  <el-container class="container" direction="vertical">
    <router-link to="/sosia">
      <el-button type="text" icon="el-icon-back" class="back-btn"
        >Ritorna alla lista</el-button
      >
    </router-link>
    <el-header class="header">
      <el-row>
        <el-col :span="24">
          <el-col :span="6">
            <el-button
              class="float-left"
              round
              :disabled="!prevPage"
              @click="fetchPatientSosia(prevPage)"
              ><font-awesome-icon icon="long-arrow-alt-left" />
              Indietro</el-button
            >
          </el-col>
          <el-col :span="12" class="text-center">
            <el-button-group>
              <el-button
                v-if="record"
                type="success"
                :loading="printing"
                @click="printPdf"
                icon="el-icon-printer"
                >Stampa PDF</el-button
              >
              <el-button
                type="primary"
                @click="create()"
                v-show="(hasDismissal || !record) && !nextPage && !loading"
                >Nuova Pratica <i class="el-icon-document-add"
              /></el-button>
            </el-button-group>
          </el-col>
          <el-col :span="6">
            <el-button
              class="float-right"
              round
              :disabled="!nextPage"
              @click="fetchPatientSosia(nextPage)"
            >
              Avanti
              <font-awesome-icon icon="long-arrow-alt-right" />
            </el-button>
          </el-col>
        </el-col>
      </el-row>
    </el-header>
    <el-main class="main">
      <el-row>
        <el-col>
          <el-card :class="['box-card', deletedRecord && 'greyed-out']">
            <div slot="header" v-if="record">
              <el-row>
                <el-col :span="12" style="display: flex; align-items: center;">
                  <h1
                    style="margin: 0 0.5em"
                    :class="deletedRecord && 'deleted'"
                  >
                    Pratica
                    <span v-if="record" style="text-size: smaller">{{
                      trattamento
                    }}</span>
                  </h1>
                  <el-tooltip content="Complessità assistenziale" v-if="record.ca">
                    <em class="el-icon-warning" style="color: red;" />
                  </el-tooltip>
                </el-col>
                <el-col
                  :span="12"
                  style="text-align: right"
                  v-if="!deletedRecord"
                >
                  <div style="display: inline-block">
                    <el-button
                      type="warning"
                      icon="el-icon-delete"
                      @click="deleteTreatment(record.id)"
                      >Cancella</el-button
                    >
                    <el-button type="primary" @click="editTreatment"
                      >Modifica <i class="el-icon-edit"
                    /></el-button>
                  </div>
                </el-col>
                <el-col
                  :span="6"
                  style="text-align: right"
                  v-if="deletedRecord"
                >
                  <div style="display: inline-block">
                    <span
                      >Data Cancellazione
                      <strong style="color: red">{{
                        record.deleted_at
                      }}</strong></span
                    >
                  </div>
                </el-col>
              </el-row>
            </div>
            <div v-if="record">
              <dati-anagrafici
                :data="record"
                :config="config"
                :patient="patient"
              />
              <tessera-team
                :data="record.team"
                v-if="record.team.length > 0"
                :config="config"
              />
              <dati-trattamento :data="record" :config="config" />
              <indicatori-sosia
                :data="record.valutazioni"
                :config="config"
                :trattamento="record"
              />
              <sospensioni-trattamento
                :data="record.assenze"
                :config="config"
                v-if="record.assenze.length"
              />
              <criticita
                :data="_.head(record.valutazioni)"
                v-if="record.valutazioni.length"
                :config="config"
              />
              <ausili
                :data="_.head(record.valutazioni)"
                v-if="record.valutazioni.length"
                :config="config"
              />
              <diagnosi :data="_.head(record.valutazioni)" :config="config" />
              <rette-tariffe :data="record.rette" :config="config" />
              <el-divider />
              <el-collapse accordion class="collapsed">
                <el-collapse-item title="VALUTAZIONI" name="2">
                  <sosia-evaluations-table
                    :list="record.valutazioni"
                    :config="config"
                    :patientId="patientId"
                    :treatmentInfo="treatmentInfo"
                  />
                </el-collapse-item>

                <el-collapse-item
                  title="SOSPENSIONI / RIATTIVAZIONI TRATTAMENTO"
                  name="3"
                >
                  <sosia-absences-table
                    :list="record.assenze"
                    :config="config"
                    :patientId="patientId"
                    :treatmentInfo="treatmentInfo"
                  />
                </el-collapse-item>

                <el-collapse-item title="TESSERA TEAM" name="4">
                  <sosia-team-table
                    :list="record.team"
                    :config="config"
                    :patientId="patientId"
                    :treatmentInfo="treatmentInfo"
                  />
                </el-collapse-item>

                <el-collapse-item
                  title="RETTE E TARIFFE SUPPLEMENTARE"
                  name="5"
                >
                  <sosia-rate-table
                    :list="record.rette"
                    :config="config"
                    :patientId="patientId"
                    :treatmentInfo="treatmentInfo"
                  />
                </el-collapse-item>
              </el-collapse>
            </div>
            <div v-else>
              <el-alert
                title="Nessuna pratica presente"
                type="info"
                center
                :closable="false"
              />
            </div>
          </el-card>
        </el-col>
      </el-row>
      <sosia-treatment-form
        v-if="formVisible"
        :patientId="patientId"
        :config="config"
        :visible.sync="formVisible"
        :newRecord="newRecord"
        :record="record"
      />
    </el-main>
  </el-container>
</template>

<style scoped>
.greyed-out {
  background-color: #e0e0e0;
}
.deleted {
  text-decoration: line-through;
}
.container {
  height: 100%;
  margin: 5px;
}
.main {
  overflow-y: auto;
  padding: 0;
}
.header {
  text-align: center;
  padding: 0.5rem;
}
.back-btn {
  z-index: 2;
  position: fixed;
  left: 50%;
  top: 93px;
  transform: translateX(-50%);
  background: transparent !important;
  color: #fff;
  font-weight: bolder;
}

.el-collapse >>> .el-collapse-item {
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 8px;
  background-color: #eee !important;
}

.el-collapse >>> .el-collapse-item__header {
  background-color: #eee !important;
}

.el-collapse >>> .el-collapse-item__wrap,
.el-collapse-item,
.el-collapse-item__header {
  background-color: #eee !important;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
}

.el-collapse >>> .is-active {
  background-color: #112233 !important;
  color: #fff;
  font-weight: bold;
  padding-bottom: 8px;
}
</style>
