<template>
  <div>
    <el-input
      v-model="search"
      size="large"
      placeholder="Cerca ospite"
      class="search-input"
      clearable
      :disabled="fetching"
      @clear="onSearchClear"
    />
    <div class="block with-search" v-loading="loading">
      <el-table
        :data="
          patients.filter(
            (data) =>
              !search ||
              data.full_name.toLowerCase().includes(search.toLowerCase())
          )
        "
        style="max-width: 100%"
        height="100%"
        :fit="true"
      >
        <el-table-column
          prop="days_since_last_purge"
          width="70"
          label="Giorni"
          align="center"
        ></el-table-column>
        <el-table-column label="Ospite" align="left" prop="full_name" sortable>
          <template slot-scope="props">
            <font-awesome-icon
              class="success-text"
              icon="toilet"
              v-if="props.row.has_purge_critical"
            />
            {{ props.row.full_name }}
          </template>
        </el-table-column>
        <el-table-column label="Evacua" align="center" width="90">
          <template slot-scope="scope">
            <arki-check-dialog
              buttonType="success"
              icon="toilet"
              :title="`Evacuazione di ${scope.row.full_name}`"
              :action="`purge/${scope.row.id}`"
              :rules="purgeRules"
              :patient_id.sync="scope.row.id"
              option="purge"
              @success="success()"
            />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Purge from "@/js/api/services/Purge";
import _ from "lodash";

import ArkiCheckDialog from "@/js/components/ArkiCheckDialog";

export default {
  name: "Purges",
  components: {
    ArkiCheckDialog,
  },
  data() {
    return {
      loading: true,
      patients: [],
      search: "",
      fetching: false,
      therapyRules: {
        type: [
          {
            required: true,
            message: "Selezionare evacuazione",
            trigger: "blur",
          },
        ],
        time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
        date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
        description: [
          { required: false, message: "Campo obbligatorio", trigger: "blur" },
        ],
      },
      purgeRules: {
        type: [
          {
            required: true,
            message: "Selezionare evacuazione",
            trigger: "blur",
          },
        ],
        time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
        date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
        description: [
          { required: false, message: "Campo obbligatorio", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    async fetch() {
      if (this.fetching) return;
      try {
        this.fetching = true;
        this.applyQuery();
        this.loading = true;
        const response = await Purge.fetch();
        this.patients = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
        this.fetching = false;
      }
    },
    onSearchClear() {
      this.$router.push({
        path: this.$route.path,
        query: {
          t: new Date().getTime(),
        },
      });
    },
    applyQuery() {
      const name = this.$route.query.patient_name;
      if (name) this.search = name;
    },
    async success() {
      if (this.fetching) return;
      this.$emit("purgeUpdate");
      await this.fetch();
    },
  },
  mounted() {
    this.fetch();
  },
  created() {
    this.$watch(
      () => this.$route.query,
      (toParams, fromParams) => {
        if (!toParams.patient_name) return;
        if (toParams.patient_name == fromParams.patient_name) return;
        this.search = toParams.patient_name;
      }
    );
    this.$watch(
      () => this.search,
      (to, from) => {
        const patient = this.$route.query.patient_name;
        if (!patient) return;
        if (patient != this.search) {
          this.onSearchClear();
        }
      }
    );
  },
};
</script>
<style lang="scss" scoped>
.search-input {
  padding: 10px;
}
.paginate {
  padding-top: 15px;
}
</style>
