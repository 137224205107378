<template>
  <el-form
    label-position="top"
    label-width="80px"
    :model="form"
    :rules="rules"
    ref="form"
    :loading="loading"
  >
    <el-row class="form-header" :gutter="5">
      <el-col :span="8" class="text-center">
        <h3>{{ medication.full_patient }}</h3>
      </el-col>
      <el-col :span="16" class="text-center">
        <h3>{{ wound.description }}</h3>
      </el-col>
    </el-row>
    <div class="block with-form-header" v-loading="loading">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="Data" prop="date">
            <el-date-picker
              v-model="form.date"
              :editable="false"
              type="date"
              placeholder="Scegli data"
              :picker-options="pickerOptions"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Orario" prop="time">
            <el-time-picker
              v-model="form.time"
              :editable="false"
              format="HH:mm"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="Seleziona Orario"
            >
            </el-time-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="text-center">
          <el-form-item label="Eseguito" prop="verification_result">
            <el-radio
              v-model="form.verification_result"
              label="1"
              border
              @change="handleResult()"
              >Sì</el-radio
            >
            <el-radio
              v-model="form.verification_result"
              label="2"
              border
              @change="handleResult()"
              >No</el-radio
            >
          </el-form-item>
        </el-col>
        <div v-if="form.verification_result == 1">
          <el-col :span="24">
            <el-upload
              ref="uploader"
              drag
              :action="`/api/medications/${medication.id}/reupload`"
              :auto-upload="false"
              :on-change="handleUpload"
              multiple
              :with-credentials="true"
              :headers="uploadHeaders"
              list-type="picture"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">Foto della Lesione</div>
            </el-upload>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Stadio (NPUAP)" prop="stadio_npuap">
              <el-select v-model="form.stadio_npuap" filterable clearable>
                <el-option
                  v-for="(item, index) in config.stadio"
                  :key="index"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Cute Perilesionale" prop="cute_perilesionale">
              <el-select v-model="form.cute_perilesionale" filterable clearable>
                <el-option
                  v-for="(item, index) in config.cute_perilesionale"
                  :key="index"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-divider content-position="right">Esami Ematochimici</el-divider>
          </el-col>
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
            <el-form-item label="RBC (g/dL)" prop="rbc">
              <el-input-number
                v-model.number="form.rbc"
                :min="0"
                :step="1"
                type="number"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
            <el-form-item label="Albumina  (g/dL)" prop="albumina">
              <el-input-number
                v-model.number="form.albumina"
                :min="0"
                :step="1"
                type="number"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
            <el-form-item label="HGB (g/dL)" prop="hgb">
              <el-input-number
                v-model.number="form.hgb"
                :min="0"
                :step="1"
                type="number"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
            <el-form-item label="Protidemia  (g/dL)" prop="protidemia">
              <el-input-number
                v-model.number="form.protidemia"
                :min="0"
                :step="1"
                type="number"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
            <el-form-item label="WBC (g/dL)" prop="wbc">
              <el-input-number
                v-model.number="form.wbc"
                :min="0"
                :step="1"
                type="number"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
            <el-form-item label="Sideremia (g/dL)" prop="sideremia">
              <el-input-number
                v-model.number="form.sideremia"
                :min="0"
                :step="1"
                type="number"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-divider content-position="right"
              >Analisi della Ferita</el-divider
            >
          </el-col>
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
            <el-form-item label="Altezza (cm)" prop="ferita_altezza">
              <el-input-number
                v-model.number="form.ferita_altezza"
                :min="0"
                :step="0.1"
                type="number"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
            <el-form-item label="Larghezza (cm)" prop="ferita_larghezza">
              <el-input-number
                v-model.number="form.ferita_larghezza"
                :min="0"
                :step="0.1"
                type="number"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
            <el-form-item label="Profondità (cm)" prop="ferita_profondita">
              <el-input-number
                v-model.number="form.ferita_profondita"
                :min="0"
                :step="0.1"
                type="number"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
            <el-form-item label="Tessuto" prop="tessuto">
              <el-select v-model="form.tessuto" filterable clearable>
                <el-option
                  v-for="(item, index) in config.tessuto"
                  :key="index"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
            <el-form-item label="Essudato" prop="essudato">
              <el-select v-model="form.essudato" filterable clearable>
                <el-option
                  v-for="(item, index) in config.essudato"
                  :key="index"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="8" :sm="8" :md="4" :lg="4" :xl="4">
            <el-form-item
              label="Dolore Medicazione (NRS)"
              prop="ferita_profondita"
            >
              <el-input-number
                v-model.number="form.dolore"
                :min="0"
                :step="1"
                :max="10"
                type="number"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-divider content-position="right">Medicazione</el-divider>
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="Medicazione Primaria"
              prop="medicazione_primaria"
            >
              <el-input
                type="textarea"
                v-model="form.medicazione_primaria"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Medicazione Secondaria">
              <el-input
                type="textarea"
                v-model="form.medicazione_secondaria"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Terapia Sistemica">
              <el-input
                type="textarea"
                v-model="form.terapia_sistemica"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Esito" prop="medication_next">
              <el-radio
                v-model="form.medication_next"
                label="1"
                value="1"
                border
                size="large"
                @change="handleRenewChange()"
                >Rieseguire</el-radio
              >
              <el-radio
                v-model="form.medication_next"
                label="2"
                value="2"
                border
                size="large"
                @change="handleRenewChange()"
                >Guarita</el-radio
              >
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="form.medication_next == 1">
            <el-form-item label="Data" prop="date_next">
              <el-date-picker
                v-model="form.date_next"
                :editable="false"
                type="date"
                :picker-options="pickerRenewOptions"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="form.medication_next == 1">
            <el-form-item label="Orario" prop="time_next">
              <el-time-picker
                v-model="form.time_next"
                :editable="false"
                format="HH:mm"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-time-picker>
            </el-form-item>
          </el-col>
        </div>

        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <el-col :span="12" v-if="form.verification_result == 2">
          <el-form-item label="Data di rimedicazione" prop="date_next">
            <el-date-picker
              v-model="form.date_next"
              :editable="false"
              type="date"
              :picker-options="pickerRenewOptions"
              format="dd/MM/yyyy"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="form.verification_result == 2">
          <el-form-item label="Orario di rimedicazione" prop="time_next">
            <el-time-picker
              v-model="form.time_next"
              :editable="false"
              format="HH:mm"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-time-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Note" prop="verification_note">
            <el-input
              type="textarea"
              v-model="form.verification_note"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="margin-top">
          <el-button
            size="large"
            type="success"
            @click="submit()"
            class="float-right fixed-confirm-button"
            plain
            :loading="submitLoading"
            >Conferma</el-button
          >
        </el-col>
      </el-row>
    </div>
  </el-form>
</template>
<script>
import moment from "moment";
import Wound from "@/js/api/services/Wound";
import Medication from "@/js/api/services/Medication";
import Settings from "@/js/api/services/Settings";

export default {
  name: "EditMedication",
  data() {
    return {
      loading: true,
      submitLoading: false,

      medication: {
        wound: {},
      },
      woundTypes: [],
      wound: {},

      upload: false,

      form: {
        stadio_npuap: null,
        cute_perilesionale: null,
        rbc: null,
        albumina: null,
        hgb: null,
        protidemia: null,
        wbc: null,
        sideremia: null,
        ferita_altezza: null,
        ferita_larghezza: null,
        ferita_profondita: null,
        tessuto: null,
        essudato: null,
        dolore: null,
        medicazione_primaria: null,
        medicazione_secondaria: null,
        terapia_sistemica: null,
      },
      rules: {
        time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
        date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
        verification_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        stadio_npuap: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        verification_note: [
          { required: false, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        date_next: [
          { required: false, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        time_next: [
          { required: false, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        medication_next: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
      },
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      pickerRenewOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() < new Date(Date.now() - 86400000);
        },
      },
      uploadHeaders: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        Authorization: `Bearer ${localStorage.getItem("arkicare_session")}`,
      },
    };
  },
  computed: {
    config() {
      return this.$store.state.config.wounds;
    },
    patients() {
      return this.$store.state.patients;
    },
  },
  methods: {
    async init() {
      const response = await Settings.fetch("wound");
      this.woundTypes = response.data;
      this.fetch();
    },
    async fetch() {
      const currentMedication = await Medication.show(this.$route.params.id);
      this.medication = currentMedication.data;

      if (this.medication.verification_result === 0) {
        let previousMedication = await Medication.showPrevious(
          this.$route.params.id
        );
        previousMedication = previousMedication.data;
        this.form.stadio_npuap = previousMedication.stadio_npuap;
        this.form.cute_perilesionale = previousMedication.cute_perilesionale;
        this.form.rbc = previousMedication.rbc;
        this.form.albumina = previousMedication.albumina;
        this.form.hgb = previousMedication.hgb;
        this.form.protidemia = previousMedication.protidemia;
        this.form.wbc = previousMedication.wbc;
        this.form.sideremia = previousMedication.sideremia;
        this.form.ferita_altezza = previousMedication.ferita_altezza;
        this.form.ferita_larghezza = previousMedication.ferita_larghezza;
        this.form.ferita_profondita = previousMedication.ferita_profondita;
        this.form.tessuto = previousMedication.tessuto;
        this.form.essudato = previousMedication.essudato;
        this.form.dolore = previousMedication.dolore;
        this.form.medicazione_primaria =
          previousMedication.medicazione_primaria;
        this.form.medicazione_secondaria =
          previousMedication.medicazione_secondaria;
        this.form.terapia_sistemica = previousMedication.terapia_sistemica;
      }
      this.wound = this.medication.wound;
      this.loading = false;
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$confirm("Confermare valutazione?", "Attenzione", {
            confirmButtonText: "Sì",
            cancelButtonText: "Annulla",
            type: "success",
          })
            .then(() => {
              this.submitLoading = true;
              this.store();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "Operazione annullata",
              });
            });
        } else {
          return false;
        }
      });
    },
    async store() {
      let params = {
        date: moment(this.form.date).format("YYYY-MM-DD"),
        time: moment(this.form.time).format("HH:mm:ss"),
        medication_next: this.form.medication_next,
        date_next: moment(this.form.date_next).format("YYYY-MM-DD"),
        time_next: moment(this.form.time_next).format("HH:mm:ss"),
        data: this.form,
      };
      try {
        await Medication.store(this.$route.params.id, params);
        if (this.upload) {
          this.$refs.uploader.submit();
        }
        this.$router.push({ name: "Medicazioni" });
      } catch (error) {
        this.submitLoading = false;
      }
    },
    handleRenewChange() {
      if (this.form.medication_next == 1) {
        this.rules.date_next[0].required = true;
        this.rules.time_next[0].required = true;
      } else {
        this.rules.date_next[0].required = false;
        this.rules.time_next[0].required = false;
      }
    },
    handleResult() {
      if (this.form.verification_result == 1) {
        this.rules.verification_note[0].required = false;
      } else if (this.form.verification_result == 2) {
        this.rules.verification_note[0].required = true;
        this.rules.date_next[0].required = true;
        this.rules.time_next[0].required = true;
      }
    },
    handleUpload(file, fileList) {
      this.upload = file.status === "ready";
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.margin-top {
  margin-top: 15px;
}
.with-form-header {
  height: calc(100vh - 195px) !important;
}
</style>
