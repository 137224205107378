<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-row justify="center" align="center">
      <el-col :span="24">
        <center>
          <h3>SCALE DI VALUTAZIONE DELL' ÉQUIPE MULTIDISCIPLINARE</h3>
        </center>
      </el-col>
      <el-col :span="24" v-show="paiOperation == 'new'">
        <center>
          <el-button
            type="primary"
            @click="$router.push('/tests/' + patient.id)"
            >Vai alla pagina delle Scale</el-button
          >
        </center>
      </el-col>
    </el-row>
    <el-divider />
    <el-form
      ref="testsForm"
      label-position="top"
      :model="testsForm"
      label-width="80px"
      :disabled="paiOperation == 'show'"
    >
      <el-row
        type="flex"
        :gutter="3"
        align="start"
        justify="center"
        style="width: 100%"
      >
        <el-col :span="1">
          <el-tooltip
            class="item"
            effect="dark"
            content="Selezionare le scale da inserire nel PI/PAI"
            placement="right"
          >
            <i class="el-icon-info"></i>
          </el-tooltip>
        </el-col>
        <el-col :span="8">
          <center>Scala</center>
        </el-col>
        <el-col :span="4">
          <center>PI/PAI precedente</center>
        </el-col>
        <el-col :span="4">
          <center>PI/PAI attuale</center>
        </el-col>
        <el-col :span="5">
          <center>Esito</center>
        </el-col>
      </el-row>
      <el-divider />
      <div
        style="height: 240px; overflow-y: scroll; width: 100%"
        v-if="paiOperation == 'new'"
      >
        <el-row
          type="flex"
          :gutter="3"
          v-for="item in tests"
          :key="item.idModel"
          :class="getRowClass(item.idModel)"
          align="start"
          justify="center"
          style="width: 100%"
        >
          <el-col :span="1">
            <el-checkbox :label="item.idModel" v-model="selectedTests">{{
              "&nbsp;"
            }}</el-checkbox>
          </el-col>
          <el-col :span="8">{{ item.name }}</el-col>
          <el-col :span="4">
            <center>
              <span class="test-date">{{
                computeResult(item.previous)[0]
              }}</span>
              <br />
              <span class="test-result">{{
                computeResult(item.previous)[1]
              }}</span>
            </center>
          </el-col>
          <el-col :span="4">
            <center>
              <span class="test-date">{{ computeResult(item.latest)[0] }}</span>
              <br />
              <span class="test-result">{{
                computeResult(item.latest)[1]
              }}</span>
            </center>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="testsForm[item.idModel]['result']"
              clearable
              placeholder="Esito"
              size="small"
            >
              <el-option
                v-for="outcome in outcomes"
                :key="outcome.value"
                :label="outcome.label"
                :value="outcome.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div
        style="height: 240px; overflow-y: scroll; width: 100%"
        v-if="paiOperation == 'show'"
      >
        <el-row
          type="flex"
          :key="item.name"
          :gutter="3"
          v-for="item in tests"
          class="test-row"
          align="start"
          justify="center"
          style="width: 100%"
        >
          <el-col :span="1">
            <el-checkbox :label="item.model" v-model="selectedTests">{{
              "&nbsp;"
            }}</el-checkbox>
          </el-col>
          <el-col :span="8">{{ item.name }}</el-col>
          <el-col :span="4">
            <span class="test-date">{{
              computeResult(item.pai_prev_test)[0]
            }}</span>
            <br />
            <span class="test-result">{{
              computeResult(item.pai_prev_test)[1]
            }}</span>
          </el-col>

          <el-col :span="4">
            <span class="test-date">{{ computeResult(item.pai_test)[0] }}</span>
            <br />
            <span class="test-result">{{
              computeResult(item.pai_test)[1]
            }}</span>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="item['result']"
              clearable
              placeholder="Esito"
              size="small"
            >
              <el-option
                v-for="outcome in outcomes"
                :key="outcome.value"
                :label="outcome.label"
                :value="outcome.value"
              ></el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </el-row>
</template>

<script>
import { Pais, Tests } from "@/js/api/services";
import moment from "moment";

const outcomes = (xs) =>
  xs.map((x) => ({
    label: x,
    value: x,
  }));
const defOptions = ["migliore", "peggiore", "uguale"];

export default {
  name: "PaiTest",
  props: ["pai", "paiOperation", "patient", "paiTests", "persistentDraft"],
  data() {
    return {
      loading: true,
      tests: [],
      disabledForm: false,
      testsForm: {},
      selectedTests: [],
      outcomes: outcomes(defOptions),
    };
  },
  computed: {
    hasLatestPai() {
      return this.pai != null;
    },
  },
  methods: {
    async setupShow() {
      this.tests = await this.fetchPaiTests(this.pai.id);
      this.tests.forEach((t) => {
        this.selectedTests.push(t.model);
      });

      this.loading = false;
    },
    async setupNew() {
      this.loading = true;
      try {
        let patientTests = await this.fetchPatientTests(this.patient.id);
        let configs = await this.fetchTestsConfig();

        const hasDraft = Boolean(_.get(this.persistentDraft, "id"));

        let paiTests = [];
        if (hasDraft) paiTests = this.persistentDraft.paiTests;
        else if (this.pai) paiTests = await this.fetchPaiTests(this.pai.id);

        let tests = [];
        configs.forEach((c) => {
          let latestFound = patientTests.find((t) => t.name == c.name);
          if (!latestFound) return;
          let plucked = null;

          let testResult = paiTests.find((t) => t.name == c.name);
          let result = null;

          if (testResult && hasDraft) {
            result = testResult.result;
          }

          if (latestFound) {
            plucked = {
              id: _.get(latestFound, ["last_enabled_test_data", "id"]),
              date_time: _.get(latestFound, [
                "last_enabled_test_data",
                "date_time",
              ]),
              total: _.get(latestFound, ["last_enabled_test_data", "total"]),
              total_quest: _.get(latestFound, [
                "last_enabled_test_data",
                "total_quest",
              ]),
              total_string: _.get(latestFound, [
                "last_enabled_test_data",
                "total_string",
              ]),
              total_description: _.get(latestFound, [
                "last_enabled_test_data",
                "total_description",
              ]),
            };
          }
          c.latest = plucked;
          const found = paiTests.find((prevTest) => c.id == prevTest.test_type);
          if (hasDraft) {
            c.previous = _.get(found, "pai_prev_test");
          } else {
            c.previous = _.get(found, "pai_test");
          }

          this.$set(this.testsForm, c.idModel, {
            result: result,
            test_name: c.name,
            test_id: _.get(c.latest, "test_id", null),
            test_type: _.get(c.latest, "test_type", null),
            test_pre_id: _.get(this.pai, "test_pre_id", null),
            test_pre_type: _.get(this.pai, "test_pre_type", null),
          });
          tests.push(c);
        });

        if (!hasDraft && paiTests.length == 0) {
          tests.forEach((t) => {
            this.selectedTests.push(t.idModel);
          });
        } else {
          paiTests.forEach((t) => {
            this.selectedTests.push(t.test_type + "_" + t.model);
          });
        }

        Object.assign(this, {
          tests,
        });

        this.updatePaiTests();
      } catch (e) {
        this.onSetupFailure(e);
      } finally {
        this.loading = false;
      }
    },
    async updateTests() {
      let patientTests = await this.fetchPatientTests(this.patient.id);
      let configs = await this.fetchTestsConfig();
      let paiTests = this.pai ? await this.fetchPaiTests(this.pai.id) : [];

      let updatedTests = configs.map((c) => {
        let latestFound = patientTests.find((t) => t.name == c.name);
        let plucked = null;
        if (latestFound) {
          plucked = {
            id: _.get(latestFound, ["test_id"]),
            date_time: _.get(latestFound, [
              "last_enabled_test_data",
              "date_time",
            ]),
            total: _.get(latestFound, ["last_enabled_test_data", "total"]),
            total_quest: _.get(latestFound, [
              "last_enabled_test_data",
              "total_quest",
            ]),
            total_string: _.get(latestFound, [
              "last_enabled_test_data",
              "total_string",
            ]),
            total_description: _.get(latestFound, [
              "last_enabled_test_data",
              "total_description",
            ]),
          };
        }
        c.latest = plucked;
        const found = paiTests.find((prevTest) => c.id == prevTest.test_type);
        c.previous = _.get(found, "pai_test");
        return c;
      });
      this.tests = _.unionBy(updatedTests, this.tests, "id");
    },
    async validateForm(form) {
      return new Promise((resolve, reject) => {
        form.validate((valid) => resolve(valid));
      });
    },
    invalidFormField(key, field) {
      return _.isNil(this.testsForm[key][field]);
    },

    validate() {
      let errors = [];
      let isValid = true;

      if (this.selectedTests.length == 0) {
        isValid = false;
        errors = ["Selezionare almeno un test"];
      }

      //if (isValid) this.updatePaiTests();
      return [errors, isValid];
    },
    async fetchTestsConfig() {
      const res = await Tests.getTestConfig();
      return _.get(res, ["data", "tests_config"], []);
    },
    async fetchPatientTests(patient_id) {
      const res = await Tests.fetch({
        patient_id
      });
      return _.get(res, ["data", "scales"], []);
    },
    onSetupFailure(e) {
      let message = "Errore durante la richiesta dei test del paziente";
      this.$notify({
        message,
        type: "error",
      });
    },
    testsToSend() {
      let tests = [];
      _.forIn(this.testsForm, (value, key) => {
        if (_.includes(this.selectedTests, key)) {
          const found = this.tests.find((t) => t.idModel == key);
          const { latest, previous, id } = found;
          // omit keys not processed by BE (that would trow error)
          value = _.omit(value, ["test_name"]);
          tests.push({
            ...value,
            test_id: _.get(latest, "id", null),
            test_pre_id: _.get(previous, "id", null),
            test_pre_type: id,
            test_type: id,
          });
        }
      });
      return tests;
    },
    updatePaiTests() {
      this.$emit("update:paiTests", this.testsToSend());
    },
    computeResult(data) {
      if (!data) return ["Valore assente", ""];
      const { date_time, total, total_quest, total_with_factor } = _.pick(
        data,
        ["date_time", "total", "total_quest", "type", "total_with_factor"]
      );
      let date = moment(date_time, "YYYY-MM-DD hh:mm:ss").format("DD/MM/YYYY");
      if (total_with_factor) {
        return [date, `${total_with_factor}`];
      }
      return [date, `${total}/${total_quest}`];
    },
    async fetchPaiTests(paiId) {
      const res = await Pais.tests(paiId);
      return _.get(res, "data", []);
    },
    dataWatcher() {
      if (this.paiOperation == "new") {
        this.updatePaiTests();
      }
    },
    startWatching() {
      this.$watch("$data", this.dataWatcher, {
        deep: true,
      });
    },
    getRowClass(itemId) {
      if (_.includes(this.selectedTests, itemId)) {
        return "checked";
      } else {
        return "unchecked";
      }
    },
  },
  created() {
    if (_.isEqual(this.paiOperation, "show")) {
      return this.setupShow();
    }
    this.setupNew().then(this.startWatching);
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.checked,
.unchecked {
  margin-bottom: 20px;
}

.checked .el-col {
  color: #000;
}

.unchecked .el-col {
  color: #999;
}

.test-date {
  font-size: 12px;
}

.test-result {
  font-size: 14px;
  font-weight: bolder;
  color: #25405d;
}
</style>
