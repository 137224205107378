<template>
  <div class="block no-filters">
    <el-button type="text" icon="el-icon-back" class="back-btn" @click="goBack"
      >Ritorna alla lista</el-button
    >
    <el-row :gutter="20">
      <el-col :span="24" class="margin-top">
        <el-form
          label-position="top"
          label-width="100px"
          :model="form"
          :rules="rules"
          ref="form"
        >
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="Cognome" prop="surname">
              <el-input v-model="form.surname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="Nome" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="Codice Fiscale" prop="cf">
              <el-input
                v-model="form.cf"
                maxlength="16"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
            <el-form-item label="Codice Sanitario" prop="codice_sanitario">
              <el-input
                v-model="form.codice_sanitario"
                maxlength="8"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="6" :xl="6">
            <el-form-item label="Genere" prop="gender">
              <el-radio-group v-model="form.gender">
                <el-radio-button label="F">Donna</el-radio-button>
                <el-radio-button label="M">Uomo</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="6" :md="8" :lg="6" :xl="6">
            <el-form-item label="Data di Nascita" prop="birthday">
              <el-date-picker
                v-model="form.birthday"
                :editable="false"
                type="date"
                placeholder="Selezionare Data"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
                default-value="1930-01-01"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
            <el-form-item
              label="Comune di Nascita / Stato (se estero)"
              prop="birthplace"
            >
              <el-input v-model="form.birthplace"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="6" :md="12" :lg="6" :xl="6">
            <el-form-item
              label="Provincia di Nascita"
              prop="birthplace_provincia"
            >
              <el-input
                v-model="form.birthplace_provincia"
                maxlength="2"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
            <el-form-item
              label="Indirizzo di Residenza"
              prop="residency_address"
            >
              <el-input v-model="form.residency_address"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
            <el-form-item label="Comune di Residenza" prop="residency">
              <el-input v-model="form.residency"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5" :xl="5">
            <el-form-item
              label="Provincia di Residenza"
              prop="residency_provincia"
            >
              <el-input
                v-model="form.residency_provincia"
                maxlength="2"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5" :xl="5"></el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="5" :xl="5">
            <el-form-item label="CAP di Residenza" prop="residency_cap">
              <el-input
                v-model="form.residency_cap"
                maxlength="5"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="Data di Inserimento in Struttura"
              prop="admission_date"
            >
              <el-date-picker
                v-model="form.admission_date"
                :editable="false"
                type="date"
                placeholder="Selezionare Data"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form>
      </el-col>
      <el-col :span="24" class="text-right">
        <el-button
          size="large"
          type="success"
          @click="submit()"
          plain
          :loading="loading"
          >Salva</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>
<script>
import SuperadminPatientsService from "@/js/api/services/superadmin/Patients";
export default {
  name: "EditPatient",
  props: {
    patient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {},
      old: {},
      loading: true,
      rules: {
        name: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        surname: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        cf: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        codice_sanitario: [
          {
            required: this.$store.state.isLombardia,
            message: "Campo Obbligatorio",
            trigger: "blur",
          },
        ],
        gender: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        birthday: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        birthplace: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        birthplace_provincia: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        birthplace_cap: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        admission_date: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    optionDepartments() {
      return this.$store.state.departmentList;
    }
  },
  methods: {
    async setupForm() {
      const data = {
        name: this.patient.name,
        surname: this.patient.surname,
        cf: this.patient.cf,
        codice_sanitario: this.patient.registry.codice_sanitario,
        gender: this.patient.gender,
        birthday: this.patient.birthday,
        birthplace: this.patient.birthplace,
        birthplace_provincia: this.patient.birthplace_provincia,
        birthplace_cap: this.patient.birthplace_cap,
        admission_date: this.patient.admission_date,
        residency: this.patient.registry.residency,
        residency_address: this.patient.registry.residency_address,
        residency_provincia: this.patient.registry.residency_provincia,
        residency_cap: this.patient.registry.residency_cap,
      };
      this.old = data;
      this.form = data;
      this.loading = false;
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (!valid) return false;
        this.updatePatient();
      });
    },
    async updatePatient() {
      this.loading = true;
      try {
        await SuperadminPatientsService.update(this.patient.id, this.form);
        this.success();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    success() {
      this.loading = false;
      this.$router.go(-1);
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (!this.patient) {
      return this.$router.go(-1);
    }
    this.setupForm();
  },
};
</script>
<style lang="scss" scoped>
.el-input-number {
  width: 100%;
}
.back-btn {
  z-index: 2;
  position: fixed;
  left: 50%;
  top: 95px;
  transform: translateX(-50%);
  background: transparent !important;
  color: #fff;
  font-weight: bolder;
}
</style>