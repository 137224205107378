<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_rss)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_rss.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_rss.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button class="float-right" round :disabled="disableNext" @click="show(test_next_rss)">
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_rss" :test_model="test_model_rss" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
                <el-row :gutter="20">
        <el-col :span="24" class="relative">
          <el-form-item label="Cognome e Nome Familiare" required>
            <el-col :span="12">
              <el-form-item>
                <el-input v-model.trim="test_rss.patient_relative_lastname" placeholder="Cognome" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <el-input v-model.trim="test_rss.patient_relative_firstname" placeholder="Nome" />
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>

        <el-col :span="24" v-for="idx in 15" :key="'q-' + idx">
          <el-form-item :prop="`q${idx}_result`">
            <el-row>
              <el-col :span="17">
                <el-form-item :label="questions[idx]" required />
              </el-col>
              <el-col :span="7">
                <el-row>
                  <el-col>
                    <div>
                      <el-radio-group v-model="test_rss[`q${idx}_result`]">
                        <el-form-item v-for="opt in grades" :key="'optt-' + opt.points">
                          <el-radio :label="opt.points">{{opt.desc}}</el-radio>
                        </el-form-item>
                      </el-radio-group>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
          <el-divider />
        </el-col>
      </el-row>
          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_rss.total_string}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Grado di STRESS">{{test_rss.total_description}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Rss",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type",
    "patientName",
  ],
  data() {
    return {
      test_rss: this.test,
      test_prev_rss: this.test_prev,
      test_next_rss: this.test_next,
      test_name_rss: this.test_name,
      test_model_rss: this.test_model,
      test_type_rss: this.test_type,
      patient_id_rss: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
      questions: {
        "1": "Pensate mai di non riuscire a fronteggiare la situazione?",
        "2": "Pensate mai di aver bisogno di una pausa?",
        "3": "Vi capita di sentirvi depressi dalla situazione?",
        "4": "La vostra salute ha in qualche modo sofferto?",
        "5": `Vi preoccupate che succedano incidenti a ${this.patientName}?`,
        "6": "Pensate mai che il problema non abbia vie di uscite?",
        "7": "Avete difficoltà ad andare in vacanza?",
        "8": "Quanto è stata modificata la vostra vita sociale?",
        "9": "Quanto è turbato il vostro 'menage' familiare?",
        "10": `Il vostro sonno viene interrotto da ${this.patientName}?`,
        "11": "Si è abbassato il vostro tenore di vita?",
        "12": `Vi sentite imbarazzati da ${this.patientName}?`,
        "13": `Siete nell' assoluta impossibilità di ricevere visite?`,
        "14": `Vi capita mai di essere contrariati o arrabbiati con ${this.patientName}?`,
        "15": `Vi capita a volte di sentirvi frustrati a causa di ${this.patientName}?`
      },
      grades: [
        { points: 0, desc: "Mai-assolutamente" },
        { points: 1, desc: "Di rado-un pó" },
        { points: 2, desc: "A volte-moderatamente" },
        { points: 3, desc: "Frequentemente-molto" },
        { points: 4, desc: "Sempre-moltissimo" }
      ]
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_rss,
        test_model: this.test_model_rss,
        test_id: test_id,
        type_id: this.test_type_rss
      };

      const response = await Test.show(params);
      this.test_rss = response.data.test;
      this.test_name_rss = response.data.test_name;
      this.test_prev_rss = response.data.test_prev;
      this.test_next_rss = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_rss === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_rss === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
