<script>
export default {
  name: 'CdiPatientsTable',
  props: ['loading', 'list'],
  computed: {
    maxHeight() {
      return window.innerHeight - 195;
    }
  }
};
</script>

<template>
  <el-table class="patient-table" :fit="true" :max-height="maxHeight" v-loading="loading" :data="list">
    <el-table-column fixed prop="full_name" label="Ospite" width="275" sortable />
    <el-table-column prop="sosia_status" label="Status" align="center">
      <template slot-scope="scope">
        <el-tooltip content="Sosia OK" placement="bottom" effect="light" v-if="scope.row.sosiaCdiStatus.has_sosia">
          <font-awesome-icon icon="check-circle" size="2x" class="success-text" />
        </el-tooltip>
        <el-tooltip content="Sosia mancante" placement="bottom" effect="light" v-else>
          <font-awesome-icon icon="times-circle" size="2x" class="danger-text" />
        </el-tooltip>
      </template>
    </el-table-column>

    <el-table-column prop="sosiaCdiStatus.trattamento" label="ID Trattamento" min-width="200" align="center" />
    <el-table-column prop="sosiaCdiStatus.data_inizio_trattamento" label="Inizio" align="center" min-width="200" />
    <el-table-column label="Convalida" align="center" min-width="200">
      <template slot-scope="scope">
        <span :class="[!scope.row.sosiaCdiStatus.has_sosia && 'invalid']">{{
          scope.row.sosiaCdiStatus.data_convalida_trattamento
        }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Dettaglio" align="center" fixed="right">
      <template slot-scope="scope">
        <router-link :to="`/cdi/${scope.row.id}`">
          <em class="el-icon-view" />
        </router-link>
      </template>
    </el-table-column>
  </el-table>
</template>

<style scss scoped>
.invalid {
  font-weight: bolder;
  color: crimson;
}
</style>