<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button
            class="float-left"
            round
            :disabled="disablePrev"
            @click="show(test_prev_ashworth)"
          >
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_ashworth.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_ashworth.clean_date_time_test }}</small>
          </span>
        </el-col>
        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_ashworth)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_ashworth" :test_model="test_model_ashworth" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-col :span="24">
                <el-divider content-position="center">Ashworth scale</el-divider>
                <el-col :span="24">
                  <el-form-item prop="q1_result">
                    <el-radio-group v-model="test_ashworth.q1_result">
                      <el-col :span="24">
                        <el-col :span="20">
                          <el-form-item
                            label="Nessun aumento del tono muscolare"
                            label-width="20px"
                          />
                        </el-col>
                        <el-col :span="4">
                          <el-form-item>
                            <el-radio :label="0" v-model="test_ashworth.q1_result" border></el-radio>
                          </el-form-item>
                        </el-col>
                      </el-col>

                      <el-col :span="24">
                        <el-col :span="20">
                          <el-form-item
                            label="Leggero aumento del tono muscolare eni movimenti di flessione ed estensione passiva dell’arto"
                            label-width="20px"
                          />
                        </el-col>
                        <el-col :span="4">
                          <el-form-item>
                            <el-radio :label="1" v-model="test_ashworth.q1_result" border></el-radio>
                          </el-form-item>
                        </el-col>
                      </el-col>

                      <el-col :span="24">
                        <el-col :span="20">
                          <el-form-item
                            label="Marcato aumento del tono muscolare che comunque non impedisce la flessione/estensione passiva dell’arto"
                            label-width="20px"
                          />
                        </el-col>
                        <el-col :span="4">
                          <el-form-item>
                            <el-radio :label="2" v-model="test_ashworth.q1_result" border></el-radio>
                          </el-form-item>
                        </el-col>
                      </el-col>

                      <el-col :span="24">
                        <el-col :span="20">
                          <el-form-item
                            label="Considerevole aumento del tono muscolare, tale da ostacolare i movimenti passivi"
                            label-width="20px"
                          />
                        </el-col>
                        <el-col :span="4">
                          <el-form-item>
                            <el-radio :label="3" v-model="test_ashworth.q1_result" border></el-radio>
                          </el-form-item>
                        </el-col>
                      </el-col>

                      <el-col :span="24">
                        <el-col :span="20">
                          <el-form-item
                            label="Arto rigido in flessione o estensione, impossibile la mobilizzazione passiva"
                            label-width="20px"
                          />
                        </el-col>
                        <el-col :span="4">
                          <el-form-item>
                            <el-radio :label="4" v-model="test_ashworth.q1_result" border></el-radio>
                          </el-form-item>
                        </el-col>
                      </el-col>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>
          </el-row>
          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_ashworth.total_string}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Risultato">{{test_ashworth.total_description}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Ashworth",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_ashworth: this.test,
      test_prev_ashworth: this.test_prev,
      test_next_ashworth: this.test_next,
      test_name_ashworth: this.test_name,
      test_model_ashworth: this.test_model,
      test_type_ashworth: this.test_type,
      patient_id_ashworth: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_ashworth,
        test_model: this.test_model_ashworth,
        test_id: test_id,
        type_id: this.test_type_ashworth
      };

      const response = await Test.show(params);
      this.test_ashworth = response.data.test;
      this.test_name_ashworth = response.data.test_name;
      this.test_prev_ashworth = response.data.test_prev;
      this.test_next_ashworth = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_ashworth === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_ashworth === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
