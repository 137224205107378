<script>
import SosiaService from "@/js/api/services/Sosia2";
import moment from "moment";
import _ from "lodash";

export default {
  name: "SosiaValidationForm",
  props: [
    "visible",
    "record",
    "config",
    "patientId",
    "treatmentInfo",
    "parent",
  ],
  components: {},
  data() {
    return {
      loading: false,
      form: {
        data_convalida: null,
        lesioni_da_decubito: null,
        catetere_urinario: null,
        cadute: null,
        numero_cadute: null,
        contenzione_permanente: null,
        ausili_per_movimento: null,
        gestione_incontinenza: null,
        gestione_diabete: null,
        alimentazione_artificiale: null,
        insufficienza_respiratoria: null,
        dialisi: null,
        data_evento_indice: null,
      },
      calcolaLoading: false,
      editMode: Boolean(_.get(this.record, "id")),
      pickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
        shortcuts: [
          {
            text: "Annulla",
            onClick(picker) {
              picker.$emit("pick", null);
            },
          },
        ],
      },
    };
  },
  computed: {
    options() {
      return this.$store.state.config.sosia;
    },
    rules() {
      return {
        data_convalida: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        lesioni_da_decubito: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        catetere_urinario: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        cadute: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        numero_cadute: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        contenzione_permanente: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        ausili_per_movimento: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        gestione_incontinenza: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        gestione_diabete: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        alimentazione_artificiale: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        insufficienza_respiratoria: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        dialisi: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        data_evento_indice: [
          {
            required: [8, "C", "D"].some(
              (x) => x == this.treatmentInfo.tipo_ospite
            ),
            message: "Campo Obbligatorio",
            trigger: "change",
          },
        ],
      };
    },
    statoVegetativo() {
      return [8, "C", "D"].some((x) => x == this.treatmentInfo.tipo_ospite);
    },
    dateOptions() {
      const options = {};
      let data_inizio_condizione = this.parent.data_inizio_condizione;
      let data_fine_condizione = this.parent.data_fine_condizione;
      const currentIdx = this.parent.convalide.findIndex(
        (x) => x.id == _.get(this.record, "id")
      );
      if (currentIdx) {
        const nextDate = _.get(
          this.parent.convalide[currentIdx - 1],
          "data_inizio_condizione"
        );
        if (nextDate) {
          data_fine_condizione = nextDate;
        }
      }

      const disabledDate = (date) => {
        const future = date > new Date();
        let before = moment(date).isSameOrBefore(data_inizio_condizione, "day");
        let after = false;
        if (data_fine_condizione)
          after = moment(date).isSameOrAfter(data_fine_condizione, "day");
        return future || before || after;
      };
      options.disabledDate = disabledDate;
      options.shortcuts = [
        {
          text: "Annulla",
          onClick(picker) {
            picker.$emit("pick", null);
          },
        },
      ];
      return options;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid, errors) => {
        if (!valid) {
          this.$message({
            type: "error",
            message: `Campi non validi`,
          });
          return false;
        }
        this.editMode ? this.update() : this.create();
      });
    },
    save() {
      this.editMode ? this.update() : this.create();
    },
    create() {
      const payload = {
        ...this.form,
        patient_id: this.patientId,
        trattamento_id: this.treatmentInfo.id,
        valutazione_id: this.parent.id,
      };
      SosiaService.storeValidation(this.parent.id, payload)
        .then(() => this.$root.$emit("fetchPatient"))
        .then(() => this.close());
    },
    update() {
      const payload = {
        ...this.form,
        patient_id: this.record.patient_id,
        trattamento_id: this.treatmentInfo.id,
        valutazione_id: this.record.valutazione_id,
      };
      SosiaService.updateValidation(this.record.id, payload)
        .then(() => this.$root.$emit("fetchPatient"))
        .then(() => this.close());
    },
    close() {
      this.$emit("update:visible", false);
    },
    updateNumber(value) {
      if (value == 2) {
        this.form.numero_cadute = 0;
      }
    },
    latestFirst(xs) {
      return [...xs].sort(
        (dateA, dateB) =>
          new moment(dateB.data_convalida).format("YYYYMMDD") -
          new moment(dateA.data_convalida).format("YYYYMMDD")
      );
    },
    latestValidation(row) {
      const omitted = ["data_convalida"];
      const convalide = _.get(row, "convalide", []);
      const latest = _.head(this.latestFirst(convalide));
      if (!latest) return null;
      return _.omit(latest, omitted);
    },
    dateUpdateHandler(date) {
      const convalide = _.get(this.parent, "convalide", []);
      let latest = _.head(this.latestFirst(convalide));

      let dateProp = 'data_convalida';

      if(!latest && this.parent.tipologia_di_verifica == 'S') {
        dateProp = 'data_inizio_condizione';
        latest = {...this.parent};
      }

      const q1 = new moment(date);
      const q2 = new moment(_.get(latest, dateProp));

      const samePeriod = [
        q1.year() === q2.year(),
        q1.quarter() === q2.quarter(),
      ].every(Boolean);

      if (!date || samePeriod) {
        this.form.cadute = _.get(latest, "cadute", 2);
        this.form.numero_cadute = _.get(latest, "numero_cadute");
        return;
      }
      if (!samePeriod) this.form.cadute = 2;
    },
  },
  created() {
    const fields = [
      "lesioni_da_decubito",
      "catetere_urinario",
      "cadute",
      "numero_cadute",
      "contenzione_permanente",
      "ausili_per_movimento",
      "gestione_incontinenza",
      "gestione_diabete",
      "alimentazione_artificiale",
      "insufficienza_respiratoria",
      "dialisi",
      "data_evento_indice",
      "data_convalida",
    ];
    const latest = this.latestValidation(this.parent) || this.parent;
    const from = this.editMode ? this.record : latest;
    Object.assign(this.form, _.pick(from, fields));
  },
};
</script>
<template>
  <el-dialog
    :visible.sync="visible"
    title="Convalida"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :show-close="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    center
    width="80%"
  >
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
          labelPosition="top"
          size="mini"
          :rules="rules"
          v-loading="loading"
          :validate-on-rule-change="false"
        >
          <el-row :gutter="10">
            <el-col :span="24">
              <el-card shadow="hover">
                <el-row :gutter="20" class="no-margin-row">
                  <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8">
                    <el-form-item prop="data_convalida">
                      <span slot="label">Data convalida</span>
                      <el-date-picker
                        v-model="form.data_convalida"
                        type="date"
                        placeholder="Selezionare Data"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="dateOptions"
                        @change="dateUpdateHandler"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col
                    :xs="12"
                    :sm="12"
                    :md="8"
                    :lg="8"
                    :xl="8"
                    v-if="statoVegetativo"
                  >
                    <el-tooltip placement="bottom" effect="light">
                      <div slot="content">
                        Obbligatorio se l'ospite è classificato "Stato
                        Vegetativo".
                      </div>
                      <el-form-item
                        label="Data Evento Indice"
                        prop="data_evento_indice"
                      >
                        <el-date-picker
                          v-model="form.data_evento_indice"
                          type="date"
                          placeholder="Selezionare Data"
                          format="dd/MM/yyyy"
                          value-format="yyyy-MM-dd"
                          :picker-options="dateOptions"
                        ></el-date-picker>
                      </el-form-item>
                    </el-tooltip>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="no-margin-row">
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Lesioni da Decubito"
                      prop="lesioni_da_decubito"
                    >
                      <el-select v-model="form.lesioni_da_decubito" clearable>
                        <el-option
                          v-for="(item, index) in options.valori_assoluti"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Catetere Urinario"
                      prop="catetere_urinario"
                    >
                      <el-select v-model="form.catetere_urinario" clearable>
                        <el-option
                          v-for="(item, index) in options.valori_assoluti"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item label="Cadute" prop="cadute">
                      <el-select
                        v-model="form.cadute"
                        clearable
                        @change="updateNumber"
                      >
                        <el-option
                          v-for="(item, index) in options.valori_assoluti"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col
                    :xs="24"
                    :sm="12"
                    :md="8"
                    :lg="6"
                    :xl="6"
                    v-if="form.cadute == 1"
                  >
                    <el-form-item label="Numero di Cadute" prop="numero_cadute">
                      <el-input-number
                        v-model="form.numero_cadute"
                        controls-position="right"
                        :min="0"
                        :max="999"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Contenzione Permanente"
                      prop="contenzione_permanente"
                    >
                      <el-select
                        v-model="form.contenzione_permanente"
                        clearable
                      >
                        <el-option
                          v-for="(item, index) in options.valori_assoluti"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Ausili per Movimento"
                      prop="ausili_per_movimento"
                    >
                      <el-select v-model="form.ausili_per_movimento" clearable>
                        <el-option
                          v-for="(item, index) in options.ausili_per_movimento"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Gestione Incontinenza"
                      prop="gestione_incontinenza"
                    >
                      <el-select v-model="form.gestione_incontinenza" clearable>
                        <el-option
                          v-for="(item, index) in options.gestione_incontinenza"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Gestione Diabete"
                      prop="gestione_diabete"
                    >
                      <el-select v-model="form.gestione_diabete" clearable>
                        <el-option
                          v-for="(item, index) in options.gestione_diabete"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Alimentazione Artificiale"
                      prop="alimentazione_artificiale"
                    >
                      <el-select
                        v-model="form.alimentazione_artificiale"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.alimentazione_artificiale"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item
                      label="Insufficienza Respiratoria"
                      prop="insufficienza_respiratoria"
                    >
                      <el-select
                        v-model="form.insufficienza_respiratoria"
                        clearable
                      >
                        <el-option
                          v-for="(
                            item, index
                          ) in options.insufficienza_respiratoria"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                    <el-form-item label="Dialisi" prop="dialisi">
                      <el-select v-model="form.dialisi" clearable>
                        <el-option
                          v-for="(item, index) in options.dialisi"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" justify="center">
            <el-button class="modal-btn" @click="$emit('update:visible', false)"
              >Annulla</el-button
            >
            <el-button class="modal-btn" type="primary" @click="onSubmit"
              >Salva</el-button
            >
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<style lang="scss">
.el-form-item {
  margin-bottom: 0.6rem;
  .el-form-item__label {
    padding: 0 !important;
  }
}
.el-divider__text {
  font-weight: bold;
}
.modal-btn {
  width: 15%;
  margin: 10px;
}
</style>