<script>
import SelectParticipation from "../filters/SelectParticipation.vue";
import { ActivityEntry } from "@/js/api/services/activity";
import CreateActivityEntry from "./CreateActivityEntry.vue";

export default {
  name: "ShowActivityEntries",
  components: { SelectParticipation, CreateActivityEntry },
  props: ["visible", "selected"],
  data() {
    return {
      loading: false,
      err: null,
      form: {},
      showEdit: false,
      selectedEntry: null,
      showCreateEntryDialog: false,
      editForm: {
        participation: null,
        note: null,
      },
      loadingCancel: false,
    };
  },
  computed: {
    entries() {
      if (!this.selected) return [];
      const { row, day } = this.selected || {};
      const xs = (row.entries || []).filter((x) => x.day == day);
      return xs;
    },
    noteRequired() {
      if (
        !this.editForm.participation ||
        this.editForm.participation.code != "altro"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    getParticipation(row) {
      const found = (row.tags || []).find(
        (t) => t.lookup.code == "tipo-partecipazione"
      );
      return found ? found.label : "";
    },
    handleEdit(idx, row) {
      if (!this.selected) return;
      const part = (row.tags || []).find(
        (tag) => tag.lookup.code == "tipo-partecipazione"
      );
      this.editForm.participation = part;
      this.editForm.note = row.note;
      this.selectedEntry = row;
      this.showEdit = true;
    },
    async handleDelete(idx, row) {
      if (!this.selected) return;

      this.$refs["cancel_" + idx].loading = true;
      console.log("messo true", this.loadingCancel);
      await ActivityEntry.delete(row.id)
        .then((resp) => this.toggleEnabled(row))
        .catch((err) => this.onError(err))
        .then();
      this.$refs["cancel_" + idx].loading = false;
    },
    toggleEnabled(row) {
      if (!this.selected) return;
      const xs = _.get(this.selected, "row.entries", []);
      xs.map((x) => {
        if (x.id == row.id) {
          x.enabled = false;
        }
        return x;
      });
    },
    onEditClose() {
      this.selectedEntry = null;
      this.showEdit = false;
      this.editForm = { participation: null, note: null };
    },
    onEditDone(data) {
      _.set(this.selectedEntry, "note", _.get(data, "data.note"));
      _.set(this.selectedEntry, "tags", _.get(data, "data.tags"));
      this.onEditClose();
    },
    getPatientId() {
      return _.get(this.selected, "row.patient_id");
    },
    onEditSave() {
      const { id } = this.selectedEntry;
      const { participation, note } = this.editForm;
      const tags = [];
      if (participation) tags.push(participation);
      const data = {
        type: "activityEntry",
        attributes: { tags, note },
      };
      ActivityEntry.update(id, { data })
        .then((resp) => this.onEditDone(resp))
        .catch((err) => this.onError(err));
    },
    onError(err) {
      this.$message({
        type: "error",
        message: err.message,
      });
    },
    getRowStyle({ row, rowIndex }) {
      if (!row.enabled) {
        return { "text-decoration": "line-through" };
      }
    },
    onAddActivityEntry() {
      this.showCreateEntryDialog = true;
    },
    entrySubmitted(params) {
      this.$emit("entrySubmitted", params);
      this.showCreateEntryDialog = false;
    },
  },
};
</script>

<template>
  <el-dialog
    title=""
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
    :show-close="false"
    :destroy-on-close="true"
    width="95%"
    center
  >
    <el-dialog
      width="40%"
      title="Edit"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :destroy-on-close="true"
      :visible.sync="showEdit"
      center
      append-to-body
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <div style="margin: 10px 0">Tipo Partecipazione</div>
          <select-participation
            v-model="editForm.participation"
          ></select-participation>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="24">
          <div style="margin: 10px 0">
            <span v-if="noteRequired">*</span>Note
          </div>
          <el-input
            type="textarea"
            v-model="editForm.note"
            :rows="3"
            :required="true"
          ></el-input>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="onEditClose" round>Chiudi</el-button>
        <el-button
          @click="onEditSave"
          type="primary"
          round
          :disabled="noteRequired && (!editForm.note || editForm.note == '')"
          >Salva</el-button
        >
      </span>
    </el-dialog>

    <create-activity-entry
      :params="selected"
      :patientId="getPatientId()"
      @entrySubmitted="entrySubmitted"
      :visible.sync="showCreateEntryDialog"
    ></create-activity-entry>

    <div v-if="err">
      <el-alert :title="err.message" type="error" @close="err = null">
        <pre>{{ err.errorsMap }}</pre>
      </el-alert>
    </div>
    <el-table :data="entries" :row-style="getRowStyle">
      <el-table-column
        property="performed_at"
        label="Data"
        width="200"
      ></el-table-column>
      <el-table-column property="name" label="Tipo Partecipazione" width="300">
        <template slot-scope="scope">
          <span>{{ getParticipation(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column property="note" label="Note"></el-table-column>
      <el-table-column label="Azioni" width="200">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            round
            :disabled="!scope.row.enabled"
            @click="handleDelete(scope.$index, scope.row)"
            :loading="loadingCancel"
            :ref="'cancel_' + scope.$index"
            >Annulla</el-button
          >
          <el-button
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
            :disabled="!scope.row.enabled"
            round
            >Edit</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="onAddActivityEntry()" round
        >Aggiungi</el-button
      >
      <el-button @click="$emit('onClose')" round>Chiudi</el-button>
    </span>
  </el-dialog>
</template>
