<template>
  <el-row style="margin-top: 0px; font-size: 12px; margin: 0">
    <h4 class="title">SOSPENSIONI/RIATTIVAZIONI TRATTAMENTO</h4>
    <el-col :span="24">
      <el-row>
        <el-col :span="8"><p class="key">Tipo</p> </el-col>
        <el-col :span="8"><p class="key">Data</p> </el-col>
        <el-col :span="8"><p class="key">Causale</p></el-col>
      </el-row>
    </el-col>
    <el-col :span="24" v-for="item of formattedData" :key="item.id">
      <el-row>
        <el-col :span="8"
          ><p class="value">{{ _.get(item, "tipo") }}</p>
        </el-col>
        <el-col :span="8"
          ><p class="value">{{ _.get(item, "data") }}</p>
        </el-col>
        <el-col :span="8"
          ><p class="value">{{ _.get(item, "causale") }}</p></el-col
        >
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "SospensioniTrattamento",
  props: ["data", "config"],
  components: {},
  data() {
    return {};
  },
  computed: {
    formattedData() {
      const formatted = [];
      const data = this.data || [];
      data.forEach((x) => {
        if (x.data_inizio_assenza) {
          formatted.push(this.sospensione(x));
        }
        if (x.data_fine_assenza) {
          formatted.push(this.riattivazione(x));
        }
      });
      return formatted;
    },
  },
  methods: {
    sospensione(x) {
      return {
        id: `${x.id}-sos`,
        tipo: "sospensione",
        data: x.clean_inizio,
        causale: this.config['causale_assenza_temporanea'][x.causa_assenza],
      };
    },
    riattivazione(x) {
      return {
        id: `${x.id}-ria`,
        tipo: "riattivazione",
        data: x.clean_fine,
        causale: "",
      };
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.title {
  border: 0.5px solid #9c9fa5;
  padding: 2px;
  border-radius: 2px;
}
.key {
  font-weight: bold;
  margin: 0;
}
.value {
  margin: 0;
}
</style>