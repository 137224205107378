<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_npi)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_npi.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_npi.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button class="float-right" round :disabled="disableNext" @click="show(test_next_npi)">
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_npi" :test_model="test_model_npi" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-row :gutter="20">
            <el-col :span="24" v-for="idx in 12" :key="'q-' + idx">
              <el-row type="flex" class="row-bg" justify="center">
                <el-card class="box-card" shadow="never">
                  <div slot="header" class="clearfix">
                    <span>
                      <i class="el-icon-info" />
                      {{ info(idx) }}
                    </span>
                  </div>

                  <el-form-item :prop="`q${idx}_result`">
                    <el-col :span="24">
                      <el-col :span="16">
                        <el-form-item :label="question(idx)" required />
                      </el-col>
                      <el-col :span="8">
                        <el-radio-group v-model="test_npi[`q${idx}_result`]">
                          <el-radio :label="1" border>Si</el-radio>
                          <el-radio :label="0" border @change="changed(idx)">NO</el-radio>
                        </el-radio-group>
                      </el-col>
                    </el-col>
                  </el-form-item>
                  <el-col :span="24">
                    <transition name="el-zoom-in-top">
                      <el-row :gutter="20" v-if="test_npi[`q${idx}_result`]">
                        <el-col :span="24">
                          <el-divider />
                          <el-col :span="24" v-for="metric in questions.metrics" :key="metric.name">
                            <el-form-item
                              :prop="`q${idx}_${metric.slug}_result`"
                              :inline-message="true"
                            >
                              <el-col :span="10">
                                <el-form-item>{{metric.name}}</el-form-item>
                              </el-col>

                              <el-col :span="14">
                                <el-row>
                                  <el-col>
                                    <div>
                                      <el-radio-group
                                        v-model="test_npi[`q${idx}_${metric.slug}_result`]"
                                      >
                                        <el-radio-button
                                          v-for="opt in metric.range"
                                          :key="opt + '-opt'"
                                          :label="opt"
                                        />
                                      </el-radio-group>
                                    </div>
                                  </el-col>
                                </el-row>
                              </el-col>
                            </el-form-item>
                          </el-col>
                          <el-col :span="24">
                            <el-col :span="10">
                              <el-form-item>a x b</el-form-item>
                            </el-col>
                            <el-col :span="14">
                              <el-row>
                                <el-col>
                                  <el-form-item>
                                    <div>
                                      <el-radio-group disabled>
                                        <el-radio-button :label="axb(idx)"></el-radio-button>
                                      </el-radio-group>
                                    </div>
                                  </el-form-item>
                                </el-col>
                              </el-row>
                            </el-col>
                          </el-col>
                        </el-col>
                      </el-row>
                    </transition>
                  </el-col>
                </el-card>
              </el-row>
            </el-col>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_npi.total_string}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Npi",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_npi: this.test,
      test_prev_npi: this.test_prev,
      test_next_npi: this.test_next,
      test_name_npi: this.test_name,
      test_model_npi: this.test_model,
      test_type_npi: this.test_type,
      patient_id_npi: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
      questions: {
        metrics: [
          { name: "Frequenza (a)", slug: "freq", range: [1, 2, 3, 4] },
          { name: "Gravitá (b)", slug: "grav", range: [1, 2, 3] },
          { name: "Distress", slug: "stress", range: [0, 1, 2, 3, 4, 5] }
        ],
        "1": {
          info:
            "Il paziente crede cose che non sono vere? Per esempio, insiste sul fatto che qualcuno sta cercando di fargli del male o di rubargli qualcosa. Dice che i componenti della famiglia non sono chi dicono di essere, o che la casa non è la sua? Non mi riferisco a semplice sospettosità; siamo interessati a sapere se il paziente sia convinto che queste cose gli stiano realmente accadendo.",
          question: "Deliri"
        },
        "2": {
          info:
            "Il paziente ha allucinazioni, cioè vede o sente cose che non esistono? Sembra vedere, sentire o provare cose non presenti? Con questa domanda non intendiamo solamente convinzioni sbagliate, cioè affermare che una persona morta sia ancora viva; piuttosto vogliamo sapere se il paziente presenta la percezione non normale di suoni o di visioni.",
          question: "Allucinazioni"
        },
        "3": {
          info:
            "Il paziente ha periodi durante i quali rifiuta di collaborare o durante i quali non si lascia aiutare dagli altri? È difficile da gestire?",
          question: "Agitazione"
        },
        "4": {
          info:
            "Il paziente sembra essere triste o depresso? Dice di sentirsi triste o depresso?",
          question: "Depressione/disforia"
        },
        "5": {
          info:
            "Il paziente è molto nervoso, allarmato, spaventato senza veri motivi? Sembra molto teso o agitato? È impaurito dal rimanere lontano da voi?",
          question: "Ansia"
        },
        "6": {
          info:
            " Il paziente è eccessivamente felice o allegro senza motivo? Non si intende la normale felicità mostrata alla vista di amici, quando si ricevono regali, o quando si sta coi parenti. Vi sto chiedendo se il paziente ha un persistente ed anormale stato di euforia o se trova ridicole cose che gli altri non trovano divertenti.",
          question: "Euforia/esaltazione"
        },
        "7": {
          info:
            "Il paziente ha interesse verso il mondo che lo circonda? Ha perso interesse nel fare le cose o è meno motivato ad iniziare cose nuove? Il paziente ha difficoltà nell'introdursi in conversazioni o nelle faccende di casa? Il paziente è apatico o indifferente?",
          question: "Apatia/indifferenza"
        },
        "8": {
          info:
            " Il paziente sembra agire impulsivamente senza pensarci? Fa o dice cose che di solito non dice o non fa in pubblico? Fa cose imbarazzanti per voi o per altri?",
          question: "Disinibizione"
        },
        "9": {
          info:
            "Il paziente si irrita o si arrabbia con facilità? Il suo umore è molto variabile? È impaziente in modo anormale? Non intendiamo frustrazione per le difficoltà di memoria o l'incapacità di compiere semplici operazioni; vogliamo sapere se il paziente presenta un'irritabilità anomala, impazienza o rapidi cambiamenti di umore che si differenziano dal solito.",
          question: "Irritabilità/labilità"
        },
        "10": {
          info:
            " Il paziente continua a camminare, continua a fare e rifare le stesse cose come aprire gli armadi o i cassetti, oppure sposta in continuazione gli oggetti o attorciglia le stringhe od i lacci?",
          question: "Attività motoria"
        },
        "11": {
          info:
            " Il paziente presenta disturbi del sonno? Sta alzato, vaga per la casa durante la notte, si veste e si sveste, disturba il sonno dei familiari? [Non è da considerare se il paziente si alza due o tre volte per notte, per andare in bagno e poi torna a letto e si addormenta immediatamente]",
          question: "Sonno"
        },
        "12": {
          info:
            "Il paziente presenta disturbi dell'alimentazione come alterazioni dell'appetito, alterazioni delle abitudini o delle preferenze alimentari. Ha subito variazioni di peso? [Se è incapace ad alimentarsi rispondere NA]",
          question: "Disturbi dell'appetito e dell'alimentazione"
        }
      }
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_npi,
        test_model: this.test_model_npi,
        test_id: test_id,
        type_id: this.test_type_npi
      };

      const response = await Test.show(params);
      this.test_npi = response.data.test;
      this.test_name_npi = response.data.test_name;
      this.test_prev_npi = response.data.test_prev;
      this.test_next_npi = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_npi === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_npi === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    },
    info(idx) {
      return _.get(this.questions, [idx, "info"], "no info");
    },
    question(idx) {
      return _.get(this.questions, [idx, "question"], "no question");
    },
    axb(idx) {
      const freq = `q${idx}_freq_result`;
      const grav = `q${idx}_grav_result`;
      let vals = [this.test_npi[freq], this.test_npi[grav]];
      let missing = vals.some(_.isNil);
      return missing ? 0 : _.multiply(...vals);
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>


<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 98%;
}

.el-row {
  margin-bottom: 20px;
}

.collapse-header {
  font-size: 16px;
}
.head {
  padding: 4px;
}
</style>
