import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Protection extends ArkiBaseService {
    
    static endpoint = 'protections';

    static fetchSubministrated(params){
        return Api.get(`${this.endpoint}/subministrated`, { params: params });
    }

    static verifications(params){
        return Api.get(`${this.endpoint}/verifications`, { params: params });
    }
}