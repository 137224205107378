<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_nrs)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_nrs.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_nrs.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button class="float-right" round :disabled="disableNext" @click="show(test_next_nrs)">
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>

      <el-col :span="24">
        <el-divider content-position="left"></el-divider>
      </el-col>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_nrs" :test_model="test_model_nrs" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-form-item prop="q1_result">
            <el-row :gutter="20" justify="center">
              <el-col :span="24">
                <el-col :span="6" :offset="6">Assenza dolore</el-col>
                <el-col :span="12">
                  <el-radio v-model="test_nrs.q1_result" :label="0" value="0" border>0</el-radio>
                </el-col>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-col :span="6" :offset="6">Dolore leggero</el-col>
                <el-col :span="12">
                  <el-radio
                    v-model="test_nrs.q1_result"
                    :label="1"
                    value="1"
                    border
                    prop="q1_result"
                  >1</el-radio>
                </el-col>
                <el-col :span="12" :offset="12">
                  <el-radio
                    v-model="test_nrs.q1_result"
                    :label="2"
                    value="2"
                    border
                    prop="q1_result"
                  >2</el-radio>
                </el-col>
                <el-col :span="12" :offset="12">
                  <el-radio
                    v-model="test_nrs.q1_result"
                    :label="3"
                    value="3"
                    border
                    prop="q1_result"
                  >3</el-radio>
                </el-col>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-col :span="6" :offset="6">Dolore moderato</el-col>
                <el-col :span="12">
                  <el-radio
                    v-model="test_nrs.q1_result"
                    :label="4"
                    value="4"
                    border
                    prop="q1_result"
                  >4</el-radio>
                </el-col>
                <el-col :span="12" :offset="12">
                  <el-radio
                    v-model="test_nrs.q1_result"
                    :label="5"
                    value="5"
                    border
                    prop="q1_result"
                  >5</el-radio>
                </el-col>
                <el-col :span="12" :offset="12">
                  <el-radio
                    v-model="test_nrs.q1_result"
                    :label="6"
                    value="6"
                    border
                    prop="q1_result"
                  >6</el-radio>
                </el-col>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-col :span="6" :offset="6">Dolore severo</el-col>
                <el-col :span="12">
                  <el-radio
                    v-model="test_nrs.q1_result"
                    :label="7"
                    value="7"
                    border
                    prop="q1_result"
                  >7</el-radio>
                </el-col>
                <el-col :span="12" :offset="12">
                  <el-radio
                    v-model="test_nrs.q1_result"
                    :label="8"
                    value="8"
                    border
                    prop="q1_result"
                  >8</el-radio>
                </el-col>
                <el-col :span="12" :offset="12">
                  <el-radio
                    v-model="test_nrs.q1_result"
                    :label="9"
                    value="9"
                    border
                    prop="q1_result"
                  >9</el-radio>
                </el-col>
              </el-col>
            </el-row>
            <el-divider></el-divider>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-col :span="6" :offset="6">Peggior dolore possibile</el-col>
                <el-col :span="12">
                  <el-radio
                    v-model="test_nrs.q1_result"
                    :label="10"
                    value="10"
                    border
                    prop="q1_result"
                  >10</el-radio>
                </el-col>
              </el-col>
            </el-row>
          </el-form-item>
          <el-row :gutter="20">
            <!-- BREAK Submit -->
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <!-- submit -->
            <el-col :span="24">
              <el-col :span="8">
                <el-form-item label="Totale">
                  <el-input v-model="test_nrs.total">
                    <template slot="append">/ {{test_nrs.total_quest}}</template>
                  </el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-divider></el-divider>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Note">
                  <el-input type="textarea" v-model="test_nrs['note']"></el-input>
                </el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Nrs",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_nrs: this.test,
      test_prev_nrs: this.test_prev,
      test_next_nrs: this.test_next,
      test_name_nrs: this.test_name,
      test_model_nrs: this.test_model,
      test_type_nrs: this.test_type,
      patient_id_nrs: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_nrs,
        test_model: this.test_model_nrs,
        test_id: test_id,
        type_id: this.test_type_nrs
      };

      const response = await Test.show(params);

      this.test_nrs = response.data.test;
      this.test_name_nrs = response.data.test_name;
      this.test_prev_nrs = response.data.test_prev;
      this.test_next_nrs = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_nrs === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_nrs === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
