<template>
  <md-app md-mode="fixed" v-loading="!isReady">
    <md-app-content v-if="isReady">
      <arki-toolbar-superadmin ref="toolbar" />
      <arki-sidenav-superadmin />
      <router-view :roles="this.roles" />
    </md-app-content>
  </md-app>
</template>

<script>
import ArkiSidenavSuperadmin from "@/js/components/ArkiSidenavSuperadmin";
import ArkiToolbarSuperadmin from "@/js/components/ArkiToolbarSuperadmin";
import Log from "@/js/api/services/Log";
import createPersistedState from "vuex-persistedstate";

import Auth from "@/js/api/services/Auth";

export default {
  name: "Superadmin",
  data() {
    return {
      roles: [],
      isReady: false,
      timeoutinms: 7200000, // 120 minutes -> 120 * 60 * 1000
      timeoutid: null,
    };
  },
  components: {
    ArkiSidenavSuperadmin,
    ArkiToolbarSuperadmin,
  },
  computed: {
    response() {
      return this.$store.state.response;
    },
  },
  watch: {
    response(newResponse, oldResponse) {
      if (axios.isCancel(this.$store.state.response)) {
        return;
      }
      if (this.$store.state.error) {
        this.error();
      } else if (this.$store.state.success) {
        this.success();
      }
    },
  },
  methods: {
    async init() {
      this.isReady = true;
    },

    handleinactive() {
      // here you want to logout a user and/or ping your token
      this.$auth.logout();
    },

    starttimer() {
      // settimeout returns an id (can be used to start or clear a timer)
      this.timeoutid = setTimeout(this.handleinactive, this.timeoutinms);
    },

    resettimer() {
      clearTimeout(this.timeoutid);
      this.starttimer();
    },

    setuptimers() {
      this.$el.addEventListener("keypress", this.resettimer, false);
      this.$el.addEventListener("mousemove", this.resettimer, false);
      this.$el.addEventListener("mousedown", this.resettimer, false);
      this.$el.addEventListener("touchmove", this.resettimer, false);

      this.starttimer();
    },
    success() {
      if (this.$store.state.response.data.message) {
        this.$notify({
          title: "Ok!",
          message: this.$store.state.response.data.message,
          type: "success",
        });
      }
    },
    error() {
      const errCodes = [406, 500];
      const response = this.$store.state.response.response;
      let message = response.statusText;

      if (errCodes.includes(response.status)) {
        message = response.data.message;
      }
      this.$notify({
        title: "Errore",
        message: message,
        type: "error",
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
