export { default as GUSS } from './guss';
export { default as BINA } from './bina';
export { default as KANE } from './kane';
export { default as TINETTI } from './tinetti';
export { default as PAINAD } from './painad';
export { default as MMSE } from './mmse';
export { default as VRS } from './vrs';
export { default as VCC } from './vcc';


export const rules = (numberOfQuestions, custom = []) => {
    let defaultRule = { required: true, message: "Campo Obbligatorio", trigger: "change" };

    let r = _.range(numberOfQuestions)
        .reduce((acc, next, idx) => {
            idx++;
            acc[`q${idx}_result`] = defaultRule;
            return acc;
        }, {})
    if (custom.length) {
        custom.reduce((acc, val) => {
            acc[val] = defaultRule
            return acc;
        }, r)
    }
    return r;
}

export const createResults = (min, max) => {
    return _.range(min, max).map(idx => `q${idx}_result`)
}

export const filterResults = (data, specific, skip) => {
    if (specific) {
        return _.pickBy(data, (_, key) => specific.includes(key));
    }
    if (skip) {
        return _.pickBy(
            data,
            (_, key) => key.endsWith("result") && !skip.includes(key)
        );
    }
    return _.pickBy(data, (_, key) => key.endsWith("result"));
}

export const totalOf = (specific, data) => {
    let results = _.values(filterResults(data, specific));
    return results.reduce(_.add, 0);
}
