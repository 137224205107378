<template>
   <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">

      <el-row :gutter="20">
        <el-col :span="6">
            <el-button
                class="float-left"
                round
                :disabled="disablePrev"
                @click="show(test_prev_mmse)"
            >
                <font-awesome-icon icon="long-arrow-alt-left" />
                Indietro
            </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_mmse.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_mmse.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_mmse)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_mmse" :test_model="test_model_mmse" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
            <!-- BREAK Orientamento Temporale -->
            <el-col :span="24">
                <el-divider content-position="left">Orientamento Temporale</el-divider>
            </el-col>

            <!-- In che anno siamo? -->
            <el-col :span="24">
                <el-col :span="12">
                    <el-form-item label="In che anno siamo?">
                        <el-input v-model="test_mmse.q1_answer" readonly></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="Esito">
                        <el-radio v-model="test_mmse.q1_result" label="1" border>Corretto</el-radio>
                        <el-radio v-model="test_mmse.q1_result" label="0" border>Errato</el-radio>
                    </el-form-item>
                </el-col>
            </el-col>

            <!-- Di che stagione? -->
            <el-col :span="24">
                <el-col :span="12">
                    <el-form-item label="Di che stagione?">
                        <el-input v-model="test_mmse.q2_answer" readonly></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="Esito">
                        <el-radio v-model="test_mmse.q2_result" label="1" border>Corretto</el-radio>
                        <el-radio v-model="test_mmse.q2_result" label="0" border>Errato</el-radio>
                    </el-form-item>
                </el-col>
            </el-col>

            <!-- In che mese siamo? -->
            <el-col :span="24">
                <el-col :span="12">
                    <el-form-item label="In che mese siamo?">
                        <el-input v-model="test_mmse.q3_answer" readonly></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="Esito">
                        <el-radio v-model="test_mmse.q3_result" label="1" border>Corretto</el-radio>
                        <el-radio v-model="test_mmse.q3_result" label="0" border>Errato</el-radio>
                    </el-form-item>
                </el-col>
            </el-col>

            <!-- In che giorno del mese siamo, oggi? -->
            <el-col :span="24">
                <el-col :span="12">
                    <el-form-item label="In che giorno del mese siamo, oggi?">
                        <el-input v-model="test_mmse.q4_answer" readonly></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="Esito">
                        <el-radio v-model="test_mmse.q4_result" label="1" border>Corretto</el-radio>
                        <el-radio v-model="test_mmse.q4_result" label="0" border>Errato</el-radio>
                    </el-form-item>
                </el-col>
            </el-col>

            <!-- Che giorno della settimana è, oggi? -->
            <el-col :span="24">
                <el-col :span="12">
                    <el-form-item label="Che giorno della settimana è, oggi?">
                        <el-input v-model="test_mmse.q5_answer" readonly></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="Esito">
                        <el-radio v-model="test_mmse.q5_result" label="1" border>Corretto</el-radio>
                        <el-radio v-model="test_mmse.q5_result" label="0" border>Errato</el-radio>
                    </el-form-item>
                </el-col>
            </el-col>

            <!-- BREAK Orientamento Spaziale -->
            <el-col :span="24">
                <el-divider content-position="left">Orientamento Spaziale</el-divider>
            </el-col>

            <!-- In che nazione siamo? -->
            <el-col :span="24">
                <el-col :span="12">
                    <el-form-item label="In che nazione siamo?">
                        <el-input v-model="test_mmse.q6_answer" readonly></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="Esito">
                        <el-radio v-model="test_mmse.q6_result" label="1" border>Corretto</el-radio>
                        <el-radio v-model="test_mmse.q6_result" label="0" border>Errato</el-radio>
                    </el-form-item>
                </el-col>
            </el-col>

            <!-- In quale regione? -->
            <el-col :span="24">
                <el-col :span="12">
                    <el-form-item label="In quale regione?">
                        <el-input v-model="test_mmse.q7_answer" readonly></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="Esito">
                        <el-radio v-model="test_mmse.q7_result" label="1" border>Corretto</el-radio>
                        <el-radio v-model="test_mmse.q7_result" label="0" border>Errato</el-radio>
                    </el-form-item>
                </el-col>
            </el-col>

            <!-- In quale città? -->
            <el-col :span="24">
                <el-col :span="12">
                    <el-form-item label="In quale città?">
                        <el-input v-model="test_mmse.q8_answer" readonly></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="Esito">
                        <el-radio v-model="test_mmse.q8_result" label="1" border>Corretto</el-radio>
                        <el-radio v-model="test_mmse.q8_result" label="0" border>Errato</el-radio>
                    </el-form-item>
                </el-col>
            </el-col>

            <!-- Dove ci troviamo ora? -->
            <el-col :span="24">
                <el-col :span="12">
                    <el-form-item label="Dove ci troviamo ora?">
                        <el-input v-model="test_mmse.q9_answer" readonly></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="Esito">
                        <el-radio v-model="test_mmse.q9_result" label="1" border>Corretto</el-radio>
                        <el-radio v-model="test_mmse.q9_result" label="0" border>Errato</el-radio>
                    </el-form-item>
                </el-col>
            </el-col>

            <!-- A che piano? -->
            <el-col :span="24">
                <el-col :span="12">
                    <el-form-item label="A che piano?">
                        <el-input v-model="test_mmse.q10_answer" readonly></el-input>
                    </el-form-item>
                </el-col>

                <el-col :span="12">
                    <el-form-item label="Esito">
                        <el-radio v-model="test_mmse.q10_result" label="1" border>Corretto</el-radio>
                        <el-radio v-model="test_mmse.q10_result" label="0" border>Errato</el-radio>
                    </el-form-item>
                </el-col>
            </el-col>

            <!-- BREAK Memoria di Fissazione -->
            <el-col :span="24">
                <el-divider content-position="left">Memoria di Fissazione</el-divider>
            </el-col>

            <!-- Riperete tre parole: Pane Casa Gatto -->
            <el-col :span="24">
                <el-form-item label="Riperete tre parole: Pane Casa Gatto">
                    <el-slider
                        v-model="test_mmse.q11_result"
                        :step="1"
                        :max="3"
                        show-stops
                        :marks="marks_0_3"
                        :show-tooltip="false"
                    />
                </el-form-item>
            </el-col>

            <!-- BREAK Calcolo -->
            <el-col :span="24">
                <el-divider content-position="left">Calcolo</el-divider>
            </el-col>

            <!-- Togliere 7 da 100 per 5 volte: 93 86 79 72 65 / Ripetere la parola MONDO al contrario: ODNOM -->
            <el-col :span="24">
                <el-form-item label="Togliere 7 da 100 per 5 volte: 93 86 79 72 65 / Ripetere la parola MONDO al contrario: ODNOM">
                    <el-slider
                    v-model="test_mmse.q12_result"
                    :step="1"
                    :max="5"
                    show-stops
                    :marks="marks_0_5"
                    :show-tooltip="false"
                    ></el-slider>
                </el-form-item>
            </el-col>

            <!-- BREAK Memoria di Rievocazione -->
            <el-col :span="24">
                <el-divider content-position="left">Memoria di Rievocazione</el-divider>
            </el-col>

            <!-- Ripetere le tre parole: Pane Casa Gatto -->
            <el-col :span="24">
                <el-form-item label="Ripetere le tre parole: Pane Casa Gatto">
                    <el-slider
                    v-model="test_mmse.q13_result"
                    :step="1"
                    :max="3"
                    show-stops
                    :marks="marks_0_3"
                    :show-tooltip="false"
                    ></el-slider>
                </el-form-item>
            </el-col>

            <!-- Linguaggio -->
            <el-col :span="24">
                <el-divider content-position="left">Linguaggio</el-divider>
            </el-col>

            <!-- Far nominare una penna ed un orologio indicandoli al pz -->
            <el-col :span="24">
                <el-form-item label="Far nominare una penna ed un orologio indicandoli al pz">
                    <el-slider
                    v-model="test_mmse.q14_result"
                    :step="1"
                    :max="3"
                    show-stops
                    :marks="marks_0_2"
                    :show-tooltip="false"
                    ></el-slider>
                </el-form-item>
            </el-col>

            <!-- Eseguire: "Prenda un foglio con la mano dx, lo pieghi a metà e lo butti via" -->
            <el-col :span="24">
                <el-form-item label="Eseguire: Prenda un foglio con la mano dx, lo pieghi a metà e lo butti via">
                    <el-slider
                    v-model="test_mmse.q15_result"
                    :step="1"
                    :max="3"
                    show-stops
                    :marks="marks_0_2"
                    :show-tooltip="false"
                    ></el-slider>
                </el-form-item>
            </el-col>

            <!-- Ripetere: "Non c'è ne sè ne ma che tenga" -->
            <el-col :span="12">
                <el-form-item label="Ripetere: Non c&#39;è ne sè ne ma che tenga">
                    <el-radio v-model="test_mmse.q16_result" label="1" border>Corretto</el-radio>
                    <el-radio v-model="test_mmse.q16_result" label="0" border>Errato</el-radio>
                </el-form-item>
            </el-col>

            <!-- Far leggere ed eseguire: Chiuda gli occhi -->
            <el-col :span="12">
                <el-form-item label="Far leggere ed eseguire: Chiuda gli occhi">
                    <el-radio v-model="test_mmse.q17_result" label="1" border>Corretto</el-radio>
                    <el-radio v-model="test_mmse.q17_result" label="0" border>Errato</el-radio>
                </el-form-item>
            </el-col>

            <!-- Frase di senso compiuto -->
            <el-col :span="12">
                <el-form-item label="Frase di senso compiuto">
                    <el-radio v-model="test_mmse.q18_result" label="1" border>Corretto</el-radio>
                    <el-radio v-model="test_mmse.q18_result" label="0" border>Errato</el-radio>
                </el-form-item>
            </el-col>

            <!-- Coppia di pentagoni intrecciati -->
            <el-col :span="12">
                <el-form-item label="Coppia di pentagoni intrecciati">
                    <el-radio v-model="test_mmse.q19_result" label="1" border>Corretto</el-radio>
                    <el-radio v-model="test_mmse.q19_result" label="0" border>Errato</el-radio>
                </el-form-item>
            </el-col>

            <el-row :gutter="20">
                <!-- BREAK Results -->
                <el-col :span="24">
                    <el-divider></el-divider>
                </el-col>
                <!-- Results -->
                <el-col :span="24">
                    <el-col :span="7">
                        <el-form-item label="Totale">
                            {{test_mmse.total_string}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="7">
                        <el-form-item label="Corrette">
                        {{test_mmse.correct_string}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="10">
                        <el-form-item :label="`Totale con Coefficiente (${test_mmse.factor})`">
                            {{test_mmse.total_with_factor}}
                        </el-form-item>
                    </el-col>
                </el-col>

                <el-col :span="24">
                    <el-divider></el-divider>
                </el-col>
                <!-- Factors -->
                <el-col :span="24">
                    <el-col :span="12">
                        <el-form-item label="Anni di scolarizzazione">
                            {{test_mmse.education_years}}
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="Intervallo di etá">
                        {{test_mmse.age_interval}}
                        </el-form-item>
                    </el-col>
                </el-col>
            </el-row>

        </el-form>
      </el-row>

    </el-card>
  </el-row>
</template>

<script>
    import moment from "moment";
    import Test from "@/js/api/services/Test";
    import Tests from "@/js/pages/Patients/components/Tests";
    import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

    export default {
        name: "Mmse",
        components: {
            TestsDisable,
        },
        props: [
            "test",
            "test_prev",
            "test_next",
            "test_name",
            "test_model",
            "test_type"
        ],
        data() {
            return {
                test_mmse: this.test,
                test_prev_mmse: this.test_prev,
                test_next_mmse: this.test_next,
                test_name_mmse: this.test_name,
                test_model_mmse: this.test_model,
                test_type_mmse: this.test_type,
                patient_id_mmse: this.test.patient_id,
                loading: true,
                disablePrev: false,
                disableNext: false,
                marks_0_2: {
                    0: "0",
                    1: "1",
                    2: "2"
                },
                marks_0_3: {
                    0: "0",
                    1: "1",
                    2: "2",
                    3: "3"
                },
                marks_0_5: {
                    0: "0",
                    1: "1",
                    2: "2",
                    3: "3",
                    4: "4",
                    5: "5"
                },
            };
        },
        methods: {
            async show(test_id) {
                this.loading = true;

                let params = {
                    patient_id: this.patient_id_mmse,
                    test_model: this.test_model_mmse,
                    test_id: test_id,
                    type_id: this.test_type_mmse
                };

                const response = await Test.show(params);

                this.test_mmse = response.data.test;
                this.test_name_mmse = response.data.test_name;
                this.test_prev_mmse = response.data.test_prev;
                this.test_next_mmse = response.data.test_next;

                this.checkPrevNext();
            },
            checkPrevNext(){
                if(this.test_prev_mmse === null)
                    this.disablePrev = true
                else
                    this.disablePrev = false

                if(this.test_next_mmse === null)
                    this.disableNext = true
                else
                    this.disableNext = false

                this.loading = false
            }
        },
        beforeMount() {
            this.checkPrevNext();
        }
    };
</script>
