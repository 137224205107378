<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item />

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q1_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Alimentazione" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q1_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="2" />
                            <el-radio-button :label="5" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="10" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q2_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Farsi il bagno / la doccia (lavarsi)" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q2_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="1" />
                            <el-radio-button :label="3" />
                            <el-radio-button :label="4" />
                            <el-radio-button :label="5" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q3_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Igiene personale" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q3_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="1" />
                            <el-radio-button :label="3" />
                            <el-radio-button :label="4" />
                            <el-radio-button :label="5" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q4_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Vestirsi (Abbigliamento)" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q4_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="2" />
                            <el-radio-button :label="5" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="10" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q5_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Continenza intestinale" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q5_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="2" />
                            <el-radio-button :label="5" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="10" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q6_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Continenza urinaria" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q6_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="2" />
                            <el-radio-button :label="5" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="10" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q7_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Uso dei servizi (Toilette)" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q7_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="2" />
                            <el-radio-button :label="5" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="10" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q8_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Trasferimento letto/sedia" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q8_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="3" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="12" />
                            <el-radio-button :label="15" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q9_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Deambulazione" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q9_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="3" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="12" />
                            <el-radio-button :label="15" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q10_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Salire le scale" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q10_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="2" />
                            <el-radio-button :label="5" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="10" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24" v-if="!disabled">
            <el-col :span="24">
              <el-form-item prop="q11_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Uso carrozzina" required />
                  </el-col>
                  <el-col :span="12">
                    <el-col :span="24">
                      <el-radio-group v-model="result.q11_result">
                        <el-radio-button :label="0" />
                        <el-radio-button :label="1" />
                        <el-radio-button :label="3" />
                        <el-radio-button :label="4" />
                        <el-radio-button :label="5" />
                      </el-radio-group>
                    </el-col>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Valutazione del punteggio">
              <el-input v-model="totalDescription" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template>
<script>
import { rules, filterResults } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Barthel",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 100
      },
      disabled: true,
      result: {},
      testRules: rules(11)
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_description: this.totalDescription
          };
          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
    totalDescription() {
      /*
      Codificare solo se il punteggio nell’item deambulazione è 1
        e se il paziente è addestrato all’uso della carrozzina.
      */
    //   let deambulazione = this.result.q9_result;
    //   let carrozzina = this.result.q11_result;
    //   if (!deambulazione > 0 && !carrozzina > 0) return "";

      return _.cond([
        [
          _.conforms({ total: n => _.lt(n, 24) }),
          _.constant("Dipendenza completa")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 24, 50) }),
          _.constant("Dipendenza grave")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 50, 75) }),
          _.constant("Dipendenza moderata")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 75, 91) }),
          _.constant("Dipendenza lieve")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 91, 101) }),
          _.constant("Quasi autosufficienza")
        ]
      ])(this.form);
    }
  },
  watch: {
    result: {
      handler: function() {
        if(this.result.q9_result !== 0){
            this.disabled = true;
            if(this.result.q11_result > 0)
                this.result.q11_result = 0;
        } else{
            this.disabled = false;
        }
        let results = _.values(filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.bottom-space {
  margin-bottom: 50px;
}
</style>
