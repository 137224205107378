<template>
  <div>
    <el-form
      label-position="top"
      label-width="80px"
      :model="result"
      ref="form"
      :rules="testRules"
    >
      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-divider content-position="left"
              >Precedenti cadute (domande al
              paziente/caregiver/infermiere)</el-divider
            >
          </el-col>
          <el-col :span="24">
            <el-form-item
              label="E' caduto nel corso degli ultimi tre mesi?"
              prop="q1_result"
            >
              <el-radio-group v-model="result.q1_result">
                <el-radio :label="2" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item
              label="Ha mai avuto vertigini o capogiri? (negli ultimi tre mesi)"
              prop="q2_result"
            >
              <el-radio-group v-model="result.q2_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item
              label="Le è mai capitato di perdere urine o feci mentre si recava in bagno? (negli ultimi tre mesi)"
              prop="q3_result"
            >
              <el-radio-group v-model="result.q3_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-divider content-position="left"
              >Deterioramento cognitivo (osservazione
              infermieristica/fkt)</el-divider
            >
          </el-col>

          <el-col :span="24">
            <el-form-item
              label="Compromissione della marcia, passo strisciante, ampia base di appoggio, marcia instabile."
              prop="q4_result"
            >
              <el-radio-group v-model="result.q4_result">
                <el-radio :label="1" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item
              label="Agitato (definizione: eccessiva attività motoria, solitamente non finalizzata ed associata ad agitazione interiore. Es: incapacità di stare seduto fermo, si muove con irrequietezza, si tira i vestiti, ecc.)"
              prop="q5_result"
            >
              <el-radio-group v-model="result.q5_result">
                <el-radio :label="2" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item
              label="Deterioramento delle capacità di giudizio/mancanza del senso di pericolo"
              prop="q6_result"
            >
              <el-radio-group v-model="result.q6_result">
                <el-radio :label="3" border>Si</el-radio>
                <el-radio :label="0" border>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{ form.total_quest }}</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions
      @reset="resetForm"
      @save="submit"
      :loading="loading"
      :show-actions="showActions"
    />
  </div>
</template>
<script>
import TestActions from "../components/TestActions";

export default {
  name: "Conley",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 10,
      },
      result: {},
      testRules: {
        q1_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        q2_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        q3_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        q4_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        q5_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        q6_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$emit("submitTest", this.form, this.result);
        } else {
          return false;
        }
      });
    },
    filterResults() {
      return _.pickBy(this.result, (_, key) => key.endsWith("result"));
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map((k) => this.$delete(this.result, k));
    },
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
  },
  watch: {
    result: {
      handler: function () {
        let results = _.values(this.filterResults());
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.bottom-space {
  margin-bottom: 50px;
}
</style>