<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button
            class="float-left"
            round
            :disabled="disablePrev"
            @click="show(test_prev_vcc)"
          >
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_vcc.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_vcc.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_vcc)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable
          :test="test_vcc"
          :test_model="test_model_vcc"
          @showparent="show"
        />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <!-- DATA -->
          <el-row :gutter="20">
            <el-col
              :span="24"
              v-for="(category, idx) in categories"
              :key="idx + '-cat'"
            >
              <el-divider content-position="center">{{
                category.name
              }}</el-divider>
              <el-form-item :prop="category.test_vcc">
                <el-col
                  :span="24"
                  v-for="(option, idx2) in category.options"
                  :key="idx2 + '-opt'"
                >
                  <el-col :span="16">
                    <el-form-item :label="option.label" />
                  </el-col>
                  <el-col :span="8">
                    <el-row>
                      <el-col :offset="12">
                        <el-form-item>
                          <el-radio
                            :label="option.points"
                            v-model="test_vcc[category.result]"
                            border
                          />
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{
                  test_vcc.total_string
                }}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import { VCC } from "@/js/pages/Tests/constants";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "vcc",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type",
  ],
  data() {
    return {
      test_vcc: this.test,
      test_prev_vcc: this.test_prev,
      test_next_vcc: this.test_next,
      test_name_vcc: this.test_name,
      test_model_vcc: this.test_model,
      test_type_vcc: this.test_type,
      patient_id_vcc: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
      categories: VCC.categories,
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_vcc,
        test_model: this.test_model_vcc,
        test_id: test_id,
        type_id: this.test_type_vcc,
      };

      const response = await Test.show(params);
      this.test_vcc = response.data.test;
      this.test_name_vcc = response.data.test_name;
      this.test_prev_vcc = response.data.test_prev;
      this.test_next_vcc = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_vcc === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_vcc === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    },
  },
  beforeMount() {
    this.checkPrevNext();
  },
};
</script>
