<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button
            class="float-left"
            round
            :disabled="disablePrev"
            @click="show(test_prev_spmsq)"
          >
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_spmsq.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_spmsq.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_spmsq)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_spmsq" :test_model="test_model_spmsq" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px">
          <el-col :span="24">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>

            <el-col :span="12">
              <el-form-item label="Test somministrabile?">
                <el-radio-group v-model="test_spmsq.feasibility" disabled>
                  <el-radio :label="1" border>Si</el-radio>
                  <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="Motivazioni">
                <el-input v-model="test_spmsq.feasibility_reason" disabled></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>

            <el-col :span="12">
              <el-form-item label="1. Che giorno è oggi (giorno, mese, anno)?">
                <el-radio-group v-model="test_spmsq.q1_result" disabled>
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="2. Che giorno è della settimana?">
                <el-radio-group v-model="test_spmsq.q2_result" disabled>
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="3. Qual è il nome di questo posto?">
                <el-radio-group v-model="test_spmsq.q3_result" disabled>
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                label="4. Qual è il suo numero di telefono ? (o indirizzo se non ha il telefono)"
              >
                <el-radio-group v-model="test_spmsq.q4_result" disabled>
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="5. Quanti anni ha?">
                <el-radio-group v-model="test_spmsq.q5_result" disabled>
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="6. Quando è nato?">
                <el-radio-group v-model="test_spmsq.q6_result" disabled>
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="7. Chi è l’attuale Papa? (o Presidente della Repubblica)">
                <el-radio-group v-model="test_spmsq.q7_result" disabled>
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="8. Chi era il Papa precedente? (o Presidente della Repubblica)">
                <el-radio-group v-model="test_spmsq.q8_result" disabled>
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="9. Qual era il nome di sua madre prima di sposarsi?">
                <el-radio-group v-model="test_spmsq.q9_result" disabled>
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="10. Qual era il nome di sua madre prima di sposarsi?">
                <el-radio-group v-model="test_spmsq.q10_result" disabled>
                  <el-radio :label="1" border>Corretto</el-radio>
                  <el-radio :label="0" border>Errato</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>

          <el-row :gutter="20">
            <!-- BREAK Results -->
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <!-- Results -->

            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Punteggio corretto">
                  <el-input v-model="test_spmsq.total" :disabled="true" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Numero totale di errori">
                  <el-input v-model="test_spmsq.total_errors" :disabled="true" />
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Anni di scolarizzazione">
                  <el-input v-model="test_spmsq.education_years" :disabled="true" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Coefficiente">
                  <el-input v-model="test_spmsq.factor" :disabled="true" />
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="Punteggio">
                  <el-input v-model="test_spmsq.total_points" :disabled="true" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Risultato">
                  <el-input v-model="test_spmsq.total_description" :disabled="true" />
                </el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Spmsq",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_spmsq: this.test,
      test_prev_spmsq: this.test_prev,
      test_next_spmsq: this.test_next,
      test_name_spmsq: this.test_name,
      test_model_spmsq: this.test_model,
      test_type_spmsq: this.test_type,
      patient_id_spmsq: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_spmsq,
        test_model: this.test_model_spmsq,
        test_id: test_id,
        type_id: this.test_type_spmsq
      };

      const response = await Test.show(params);

      this.test_spmsq = response.data.test;
      this.test_name_spmsq = response.data.test_name;
      this.test_prev_spmsq = response.data.test_prev;
      this.test_next_spmsq = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_spmsq === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_spmsq === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
