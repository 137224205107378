<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_must)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_must.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_must.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_must)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_must" :test_model="test_model_must" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider content-position="center">BMI (KG/M2)</el-divider>
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q1_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Punteggio BMI" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_must.q1_result">
                                <el-form-item>
                                  <el-radio :label="2">&lt; 18.5 (Sottopeso)</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">18.5-20 (Sottopeso)</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="0">20-25 (Peso Desiderabile)</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">25-30 (Sovrappeso)</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="4">&gt; 30 (Obeso)</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-divider content-position="center">PERDITA DI PESO</el-divider>
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q2_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item
                          label="Non programmata perdita di peso negli ultimi 3 mesi"
                          required
                        />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_must.q2_result">
                                <el-form-item>
                                  <el-radio :label="2">&gt; 10%</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">5-10%</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="0">&lt; 5%</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-divider content-position="center">MALATTIE ACUTE FISICHE/PSICOLOGICHE</el-divider>
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q3_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item
                          label="Presenza di malattie acute quando il paziente non si nutre per più di 5 gg."
                          required
                        />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_must.q3_result">
                                <el-form-item>
                                  <el-radio :label="2">Malattia Presente</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="0">Malattia Non Presente</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>
          </el-row>
          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_must.total_string}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Rischio">{{test_must.total_description}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "must",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_must: this.test,
      test_prev_must: this.test_prev,
      test_next_must: this.test_next,
      test_name_must: this.test_name,
      test_model_must: this.test_model,
      test_type_must: this.test_type,
      patient_id_must: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
      tableData: [
        { idx: "0", rischio: "Basso rischio" },
        { idx: "1", rischio: "Medio Rischio" },
        { idx: "> 2", rischio: "Alto rischio" }
      ]
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_must,
        test_model: this.test_model_must,
        test_id: test_id,
        type_id: this.test_type_must
      };

      const response = await Test.show(params);
      this.test_must = response.data.test;
      this.test_name_must = response.data.test_name;
      this.test_prev_must = response.data.test_prev;
      this.test_next_must = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_must === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_must === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
