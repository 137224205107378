<template>
  <el-row style="margin-top: 0px; font-size: 12px">
    <h4 class="title">AUSILI</h4>
    <el-col :span="12">
      <el-row>
        <el-col
          ><span class="key">Ausili per il Movimento</span>:
          <span>{{ config['ausili_per_movimento'][_.get(record, "ausili_per_movimento")] }}</span></el-col
        >
        <el-col
          ><span class="key">Gestione Diabete</span>:
          <span>{{ config['gestione_diabete'][_.get(record, "gestione_diabete")] }}</span></el-col
        >
        <el-col
          ><span class="key">Insufficienza Respiratoria</span>:
          <span>{{ config['insufficienza_respiratoria'][_.get(record, "insufficienza_respiratoria")] }}</span></el-col
        >
      </el-row>
    </el-col>
    <el-col :span="12">
      <el-row>
        <el-col
          ><span class="key">Incontinenza</span>:
          <span>{{ config['gestione_incontinenza'][_.get(record, "gestione_incontinenza")] }}</span></el-col
        >
        <el-col
          ><span class="key">Alimentazione Artificiale</span>:
          <span>{{ config['alimentazione_artificiale'][_.get(record, "alimentazione_artificiale")] }}</span></el-col
        >
        <el-col
          ><span class="key">Dialisi</span>:
          <span>{{ config['dialisi'][_.get(record, "dialisi")] }}</span></el-col
        >
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "Ausili",
  props: ["data", "config"],
  data() {
    return {};
  },
  computed: {
    record() {
      return _.head(this.data.convalide) || this.data
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  border: 0.5px solid #9c9fa5;
  padding: 2px;
  border-radius: 2px;
}
.key {
  font-weight: bold;
}
.value {
}
</style>