<script>
import moment from 'moment';

export default {
  name: 'CdiPatientsToolbar',
  props: ['loading'],
  data() {
    return {
      select: 'nome',
      searchValue: null,
      periodForm: { year: new Date(), quarter: moment().quarter() },
      optionsTrimestre: {
        1: 'I Trimestre',
        2: 'II Trimestre',
        3: 'III Trimestre',
        4: 'IV Trimestre'
      },
      pickerOptions: {
        disabledDate(date) {
          return date > new Date();
        }
      },
      rules: {
        year: [{ required: true, message: 'Campo Obbligatorio', trigger: ['change'] }],
        quarter: [{ required: true, message: 'Campo Obbligatorio', trigger: ['change'] }]
      }
    };
  },
  methods: {
    clearSearch() {
      this.searchValue = null;
      this.$emit('clearSearch');
    },
    onPeriodChange() {
      const { year, quarter } = this.periodForm;
      this.$emit('changePeriod', { year: this.validatedYear(year), quarter });
    },
    validatedYear(value) {
      if (value instanceof Date) {
        return moment(value).year();
      } else {
        return moment(value, 'YYYY').year();
      }
    }
  }
};
</script>

<template>
  <el-header class="header">
    <el-form
      ref="sosiaForm"
      :model="periodForm"
      label-width="120px"
      size="mini"
      labelPosition="top"
      :rules="rules"
      :validate-on-rule-change="false"
      :disabled="loading"
    >
      <el-row :gutter="5" type="flex">
        <el-col :span="12">
          <el-form-item prop="year">
            <el-date-picker
              v-model="periodForm.year"
              type="year"
              placeholder="Anno"
              format="yyyy"
              value-format="yyyy"
              size="small"
              @change="onPeriodChange"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop="quarter">
            <el-select v-model="periodForm.quarter" placeholder="Trimestre" size="small" @change="onPeriodChange">
              <el-option v-for="(item, index) in optionsTrimestre" :key="index" :label="item" :value="Number(index)">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="5" type="flex" style="margin-top: -10px">
        <el-col :span="24">
          <el-form-item size="small">
            <el-input
              placeholder=""
              v-model="searchValue"
              class="input-with-select"
              clearable
              clear
              :disabled="loading"
              @clear="clearSearch"
            >
              <el-select
                v-model="select"
                slot="prepend"
                placeholder="Select"
                style="width: 140px"
                @change="clearSearch"
              >
                <el-option label="Nome" value="nome"></el-option>
                <el-option label="Codice Fiscale" value="codiceFiscale"></el-option>
                <el-option label="Pratica" value="pratica"></el-option>
              </el-select>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="$emit('doSearch', { field: select, value: searchValue })"
                >Cerca</el-button
              >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-header>
</template>

<style scss scoped>
.el-select .el-input {
  width: 110px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.header {
  text-align: center;
  min-height: 75px;
}
</style>