<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_mna)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_mna.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_mna.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button class="float-right" round :disabled="disableNext" @click="show(test_next_mna)">
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_mna" :test_model="test_model_mna" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider content-position="center">Valutazione antropometrica</el-divider>
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q1_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Indice di massa corporea" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q1_result">
                                <el-form-item>
                                  <el-radio :label="0">BMI &lt; 19</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">BMI 19-21</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">BMI 21-23</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">BMI &gt; 23</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q2_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Circonferenza metà braccio" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q2_result">
                                <el-form-item>
                                  <el-radio :label="0">MAC &lt; 21</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="0.5">MAC 21-22</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">MAC &gt; 22</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q3_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Circonferenza polpaccio" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q3_result">
                                <el-form-item>
                                  <el-radio :label="0">CC &lt; 31</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">MAC &gt;= 31</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q4_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Perdita peso recente" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q4_result">
                                <el-form-item>
                                  <el-radio :label="0">&gt; 3Kg</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Non so</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">1-3 Kg</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">Nessuna</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-divider content-position="center">Valutazione generale</el-divider>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q5_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Vive indipendentemente" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q5_result">
                                <el-form-item>
                                  <el-radio :label="0">No</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Si</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q6_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Assume piú di tre farmaci/die" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q6_result">
                                <el-form-item>
                                  <el-radio :label="1">No</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="0">Si</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q7_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Stress o malattie acute" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q7_result">
                                <el-form-item>
                                  <el-radio :label="2">No</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Si</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q8_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Mobilitá" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q8_result">
                                <el-form-item>
                                  <el-radio :label="0">Letto-carrozzina</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Si alza</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Nessun problema</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q9_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Problemi neuropsicologici" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q9_result">
                                <el-form-item>
                                  <el-radio :label="0">Demenza-depressione gravi</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Demenza-depressione lievi</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">No</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q10_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Piaghe da decubito" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q10_result">
                                <el-form-item>
                                  <el-radio :label="0">Si</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">No</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-divider content-position="center">Valutazione alimentare</el-divider>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q11_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Quanti pasti consuma al giorno" />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q11_result">
                                <el-form-item>
                                  <el-radio :label="0">1 pasto</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">2 pasti</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">3 pasti</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="Consuma:" />
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-col :span="20" :offset="1">
                        <el-row>
                          <el-col :span="14">
                            <el-form-item
                              label="Almeno 1 pasto al giorno a base di latticini"
                              required
                            />
                          </el-col>
                          <el-col :span="10">
                            <el-row>
                              <el-col>
                                <div>
                                  <el-form-item prop="q12_a_result">
                                    <el-radio-group v-model="test_mna.q12_a_result">
                                      <el-form-item>
                                        <el-radio :label="1">Si</el-radio>
                                        <el-radio :label="0">No</el-radio>
                                      </el-form-item>
                                    </el-radio-group>
                                  </el-form-item>
                                </div>
                              </el-col>
                            </el-row>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-col>

                    <el-col :span="24">
                      <el-col :span="20" :offset="1">
                        <el-row>
                          <el-col :span="14">
                            <el-form-item
                              label="Una o due volte la settimana uova o legumi"
                              required
                            />
                          </el-col>
                          <el-col :span="10">
                            <el-row>
                              <el-col>
                                <div>
                                  <el-form-item prop="q12_b_result">
                                    <el-radio-group v-model="test_mna.q12_b_result">
                                      <el-form-item>
                                        <el-radio :label="1">Si</el-radio>
                                        <el-radio :label="0">No</el-radio>
                                      </el-form-item>
                                    </el-radio-group>
                                  </el-form-item>
                                </div>
                              </el-col>
                            </el-row>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-col>

                    <el-col :span="24">
                      <el-col :span="20" :offset="1">
                        <el-row>
                          <el-col :span="14">
                            <el-form-item label="Tutti i giorni carne o pesce" required />
                          </el-col>
                          <el-col :span="10">
                            <el-row>
                              <el-col>
                                <div>
                                  <el-form-item prop="q12_c_result">
                                    <el-radio-group v-model="test_mna.q12_c_result">
                                      <el-form-item>
                                        <el-radio :label="1">Si</el-radio>
                                        <el-radio :label="2">No</el-radio>
                                      </el-form-item>
                                    </el-radio-group>
                                  </el-form-item>
                                </div>
                              </el-col>
                            </el-row>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-col>
                  </el-row>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q13_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Consuma 2v/dí frutta/verdura" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q13_result">
                                <el-form-item>
                                  <el-radio :label="0">NO</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">SI</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q14_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Appetito ridotto ultimi 3 mesi" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q14_result">
                                <el-form-item>
                                  <el-radio :label="0">Notevolmente</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Moderatamente</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">No</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q15_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Liquidi al giorno" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q15_result">
                                <el-form-item>
                                  <el-radio :label="0">&lt; 5 bicc</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="0.5">5-9 bicc</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">9 bicc</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q16_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Come mangia" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q16_result">
                                <el-form-item>
                                  <el-radio :label="0">Imboccato</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Con aiuto</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Da solo</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-divider content-position="center">Autovalutazione</el-divider>

              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q17_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Ritiene di avere problemi" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q17_result">
                                <el-form-item>
                                  <el-radio :label="0">Si</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Forse, non sa</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Nessun problema</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q18_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Salute in confronto coetanei" />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mna.q18_result">
                                <el-form-item>
                                  <el-radio :label="0">Meno buona</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Non sa</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Migliore</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>
          </el-row>
          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_mna.total_string}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Risultato (Cut off = 17)">{{test_mna.total_description}}</el-form-item>
              </el-col>
            </el-col>
            <el-col :span="24">
              <el-divider />
              <el-form-item label="Note">
                <el-input type="textarea" v-model="test_mna.note"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Mna",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_mna: this.test,
      test_prev_mna: this.test_prev,
      test_next_mna: this.test_next,
      test_name_mna: this.test_name,
      test_model_mna: this.test_model,
      test_type_mna: this.test_type,
      patient_id_mna: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_mna,
        test_model: this.test_model_mna,
        test_id: test_id,
        type_id: this.test_type_mna
      };

      const response = await Test.show(params);
      this.test_mna = response.data.test;
      this.test_name_mna = response.data.test_name;
      this.test_prev_mna = response.data.test_prev;
      this.test_next_mna = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_mna === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_mna === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
