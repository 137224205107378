<template>
  <el-row style="margin-top: 0px; font-size: 12px">
    <h4 class="title">CRITICITÁ</h4>
    <el-col :span="12">
      <el-row>
        <el-col
          ><span class="key">Lesioni Decubito</span>:
          <span>{{
            _.get(actualData, "lesioni_da_decubito") == 1 ? "SI" : "NO"
          }}</span></el-col
        >
        <el-col
          ><span class="key">Cadute</span>:
          <span>{{
            _.get(actualData, "cadute") == 1 ? "SI" : "NO"
          }}</span></el-col
        >
        <el-col
          ><span class="key">Contenzione Permanente</span>:
          <span>{{
            _.get(actualData, "contenzione_permanente") == 1 ? "SI" : "NO"
          }}</span></el-col
        >
      </el-row>
    </el-col>
    <el-col :span="12">
      <el-row>
        <el-col
          ><span class="key">Catetere Urinario</span>:
          <span>{{
            _.get(actualData, "catetere_urinario") == 1 ? "SI" : "NO"
          }}</span></el-col
        >
        <el-col
          ><span class="key">Numero Cadute</span>:
          <span>{{ _.get(actualData, "numero_cadute") }}</span></el-col
        >
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "Criticita",
  props: ["data"],
  data() {
    return {};
  },
  computed: {
    config() {
      return this.$store.state.config.sosia;
    },
    actualData() {
      return _.head(this.data.convalide) || this.data;
    },
  }
};
</script>

<style lang="scss" scoped>
.title {
  border: 0.5px solid #9c9fa5;
  padding: 2px;
  border-radius: 2px;
}
.key {
  font-weight: bold;
}
</style>