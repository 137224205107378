<template>
  <div>
    <arki-filters
      ref="filters"
      @filtered="fetch"
      :dateRangeFilter="true"
      target="criticalEvents"
      :optionsFilter="true"
    />

    <router-link to="/critical-events/create">
      <el-button
        class="add-button"
        type="success"
        icon="el-icon-plus"
        round
      ></el-button>
    </router-link>
    <arki-broadcast
      ref="broadcast"
      listen=".critical-events.created"
      @update="update"
    />
    <div class="block with-filters">
      <el-table
        :class="paginateTable"
        :data="criticalEvents.data"
        :default-sort="{ prop: 'time', order: 'descending' }"
        style="width: 100%"
        :row-class-name="rowClass"
        v-loading="loading"
        :max-height="maxHeight"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-col :xl="12" :lg="12" :md="24">
              <el-col :span="24">
                <b>Inserimento:</b>
                {{ scope.row.full_creator }} - {{ scope.row.created_at_time }}
              </el-col>
              <!-- <el-col :span="24" v-if="scope.row.full_verification_operator">
                                <b>Verifica:</b>
                                {{ scope.row.full_verification_operator }} -
                                {{ scope.row.resolution_date }}
                            </el-col> -->
            </el-col>
          </template>
        </el-table-column>
        <el-table-column prop="full_patient" label="Ospite" min-width="100">
        </el-table-column>
        <el-table-column label="Tipo" min-width="100">
          <template slot-scope="scope">{{
            scope.row.event_type_label
          }}</template>
        </el-table-column>
        <el-table-column label="Descrizione" min-width="100">
          <template slot-scope="scope">{{ scope.row.description }}</template>
        </el-table-column>
        <el-table-column
          prop="clean_date_time"
          label="Data e Ora"
          sortable
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column
          prop="clean_resolution_date"
          label="Risoluzione"
          sortable
          align="center"
          width="150"
        >
        </el-table-column>
        <el-table-column align="right" min-width="50">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-delete"
              type="danger"
              plain
              @click="destroy(scope.row.id, scope.row.description)"
              v-if="scope.row.creator_id == user.id && scope.row.enabled == 1"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="criticalEvents.per_page"
        :total="criticalEvents.total"
        :hide-on-single-page="true"
        :current-page="current_page"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import ArkiFilters from "@/js/components/ArkiFilters";
import CriticalEvent from "@/js/api/services/CriticalEvent";
import ArkiBroadcast from "@/js/components/ArkiBroadcast";

export default {
  name: "CriticalEvents",
  components: {
    ArkiFilters,
    ArkiBroadcast,
  },
  data() {
    return {
      loading: true,
      submitLoading: false,
      criticalEvents: {
        data: [],
        per_page: null,
        total: null,
      },
      current_page: 1,
      patient_id: null,
      paginateTable: null,
      unchangedFilter: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    maxHeight() {
      return window.innerHeight - 235;
    },
  },
  watch: {
    criticalEvents: {
      handler: function () {
        this.criticalEvents.total / this.criticalEvents.per_page > 1
          ? (this.paginateTable = "paginate-table")
          : (this.paginateTable = null);
      },
      deep: true,
    },
  },
  methods: {
    update() {
      this.unchangedFilter = true;
      if (this.$refs.filters) {
        this.$refs.filters.emit();
        this.$refs.broadcast.reset();
      }
    },
    async fetch(filters) {
      if (filters.patient_id != this.$route.query.patient_id) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            patient_id: filters.patient_id || undefined,
            t: new Date().getTime(),
          },
        });
        return;
      }
      // need to reset page in case filter change
      if (!this.unchangedFilter) {
        this.current_page = 1;
      }
      this.loading = true;
      let params = {
        date_from: moment(filters.dateRange[0]).format("DD/MM/YYYY"),
        date_to: moment(filters.dateRange[1]).format("DD/MM/YYYY"),
        patient_id: filters.patient_id,
        event_type: filters.option,
        page: this.current_page,
      };
      const response = await CriticalEvent.fetch(params);
      this.criticalEvents.data = response.data.criticalEvents.data;
      this.criticalEvents.total = response.data.criticalEvents.total;
      this.criticalEvents.per_page = response.data.criticalEvents.per_page;
      this.loading = false;
      // reset the checked flag
      this.unchangedFilter = false;
    },
    changePage(page) {
      this.loading = true;
      this.current_page = page;
      this.update();
    },
    destroy(id, description) {
      this.$confirm(`Annullare Evento Critico ${description} ?`, "Attenzione", {
        confirmButtonText: "OK",
        cancelButtonText: "Annulla",
        type: "warning",
      })
        .then(() => {
          this.delete(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        });
    },
    async delete(id) {
      await CriticalEvent.destroy(id);
      this.update();
    },
    rowClass({ row, rowIndex }) {
      if (row.enabled == 0) {
        return "deleted-row";
      } else if (row.verification_result == 2) {
        return "warning-row";
      } else if (row.verification_result == 1) {
        return "success-row";
      }
    },
  },
};
</script>
