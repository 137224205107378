<template>
  <el-row class="content">
        <h4 class="title">RETTE E TARIFFE SUPPLEMENTARE</h4>
        <h4 class="subtitle">RETTA A CARICO DI:</h4>
         <el-col :span="24">
            <el-row> 
             <el-col :span="3"><p class="key">Inizio</p> </el-col>
             <el-col :span="3"><p class="key">Fine</p> </el-col>
             <el-col :span="3"><p class="key">ATS</p></el-col>
             <el-col :span="3"><p class="key">Comune</p></el-col>
             <el-col :span="3"><p class="key">Ospite</p></el-col>
             <el-col :span="4"><p class="key">Ente o Istituzione</p></el-col>
             <el-col :span="4"><p class="key">Assegno di Accompagnamento</p></el-col>
            </el-row>
         </el-col>
         <el-col :span="24" v-for="item of data" :key="item.id">
          <el-row> 
             <el-col :span="3"><p class="value">{{item.clean_inizio || '&nbsp;'}}</p> </el-col>
             <el-col :span="3"><p class="value">{{item.clean_fine || '&nbsp;'}}</p> </el-col>
             <el-col :span="3"><p class="value">{{formattedCurrency(item, 'retta_ssr')}}</p></el-col>
             <el-col :span="3"><p class="value">{{formattedCurrency(item, 'retta_comune')}}</p></el-col>
             <el-col :span="3"><p class="value">{{formattedCurrency(item, 'retta_ospite')}}</p></el-col>
             <el-col :span="4"><p class="value">{{formattedCurrency(item, 'retta_enti')}}</p></el-col>
             <el-col :span="4"><p class="value">{{item.assegno == 1 ? 'SI' : 'NO'}}</p></el-col>
            </el-row>
         </el-col>
  </el-row>
</template>
<script>

export default {
  name: "RetteTariffe",
  props: ["data"],
  data() {
    return {};
  },
  computed: {
    config() {
      return this.$store.state.config.sosia;
    },
  },
  methods: {
    formattedCurrency(item, path) {
      let value = _.get(item, path, '')
      const currency = { style: 'currency', currency: 'EUR' };
      return new Intl.NumberFormat('it-IT', currency ).format(value)
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 0px;
  font-size: 12px; 
  margin: 0;
}

.title {
  border: 0.5px solid #9C9FA5; 
  padding: 2px;
}

.subtitle {
  text-align: center;
}

.key {
  font-weight: bold;
  margin: 0;
}

.value {
  margin: 0;
}
</style>