<template>
    <el-row v-loading="loading" :gutter="20" class="no-margin-row">
        <md-dialog :md-active.sync="showDialog">
            <md-dialog-title>{{
                (editMode ? "Modifica" : "Nuovo") + " Contatto"
            }}</md-dialog-title>
            <el-form
                label-position="right"
                label-width="80px"
                :model="form"
                :rules="rules"
                ref="form"
            >
                <el-row :gutter="20">
                    <el-col :span="16">
                        <el-form-item label="Rapporto" prop="type">
                            <el-select v-model="form.type" filterable>
                                <el-option
                                    v-for="(item, index) in contact_types"
                                    :key="index"
                                    :label="item"
                                    :value="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-checkbox
                            v-model="form.riferimento"
                            label="Riferimento"
                            border
                        ></el-checkbox>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Cognome" prop="surname">
                            <el-input v-model="form.surname"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Nome" prop="name">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <md-dialog-actions>
                <el-button type="info" plain @click="close">Annulla</el-button>
                <el-button
                    type="success"
                    plain
                    @click="submit('form')"
                    :loading="submitLoading"
                    >Salva</el-button
                >
            </md-dialog-actions>
        </md-dialog>

        <md-dialog :md-active.sync="showConfirmDialog">
            <md-dialog-title>Aggiungi Recapito</md-dialog-title>
            <el-form
                label-position="right"
                label-width="80px"
                :model="formConfirm"
                :rules="rulesConfirm"
                ref="formConfirm"
            >
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="Tipologia" prop="type">
                            <el-select v-model="formConfirm.type" filterable>
                                <el-option
                                    v-for="(item, index) in connection_types"
                                    :key="index"
                                    :label="item"
                                    :value="index"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Recapito" prop="value">
                            <el-input v-model="formConfirm.value"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <md-dialog-actions>
                <el-button type="info" plain @click="close">Annulla</el-button>
                <el-button
                    type="success"
                    plain
                    @click="submit('formConfirm')"
                    :loading="submitLoading"
                    >Salva</el-button
                >
            </md-dialog-actions>
        </md-dialog>

        <el-table
            :data="contacts"
            style="width: 100%"
            :row-class-name="rowClass"
        >
            <el-table-column
                fixed
                prop="connection_type_description"
                label="Rapporto"
                width="280"
            >
                <template slot-scope="scope">
                    <el-tooltip
                        class="item"
                        effect="light"
                        content="Contatto di Riferimento"
                        placement="bottom"
                    >
                        <i
                            class="el-icon-circle-check success-text"
                            v-if="scope.row.riferimento"
                        ></i>
                    </el-tooltip>
                    {{ scope.row.connection_type_description }}
                </template>
            </el-table-column>
            <el-table-column
                prop="surname"
                label="Cognome"
                width="200"
            ></el-table-column>
            <el-table-column
                prop="name"
                label="Nome"
                width="200"
            ></el-table-column>
            <el-table-column prop="phone" label="Telefono" width="150">
                <template slot-scope="scope">
                    <p v-for="item in scope.row.phone" v-bind:key="item.id">
                        {{ item.value }}
                        <i
                            class="el-icon-delete danger-text"
                            @click="destroyConnection(item.id, item.value)"
                        ></i>
                    </p>
                </template>
            </el-table-column>
            <el-table-column prop="email" label="Email" width="250">
                <template slot-scope="scope">
                    <p v-for="item in scope.row.email" v-bind:key="item.id">
                        {{ item.value }}
                        <i
                            class="el-icon-delete danger-text"
                            @click="destroyConnection(item.id, item.value)"
                        ></i>
                    </p>
                </template>
            </el-table-column>

            <el-table-column fixed="right" label="Azioni" width="150">
                <template slot="header">
                    <el-button
                        class="w-100"
                        type="primary"
                        plain
                        @click="showDialog = true"
                        >Nuovo</el-button
                    >
                </template>
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        size="mini"
                        @click="editContact(scope.row)"
                        >Modifica</el-button
                    >
                    <span> | </span>
                    <el-button
                        type="text"
                        class="danger-text"
                        @click="destroy(scope.row.id, scope.row.full_name)"
                        size="mini"
                        >Elimina</el-button
                    >
                    <el-button
                        size="mini"
                        type="text"
                        @click="confirm(scope.row.id)"
                        >Aggiungi Recapito</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
    </el-row>
</template>
<script>
import Contact from "@/js/api/services/Contact";

export default {
    name: "Contatti",
    props: ["profile_id"],
    data() {
        return {
            loading: true,
            submitLoading: false,
            showDialog: false,
            showConfirmDialog: false,

            contacts: [],
            contact_types: [],
            connection_types: [],

            contactId: null,

            editMode: false,

            form: {},
            rules: {
                type: [
                    {
                        required: true,
                        message: "Campo Obbligatorio",
                        trigger: "blur"
                    }
                ],
                name: [
                    {
                        required: true,
                        message: "Campo Obbligatorio",
                        trigger: "blur"
                    }
                ],
                surname: [
                    {
                        required: true,
                        message: "Campo Obbligatorio",
                        trigger: "blur"
                    }
                ]
            },

            formConfirm: {},
            rulesConfirm: {
                type: [
                    {
                        required: true,
                        message: "Campo Obbligatorio",
                        trigger: "blur"
                    }
                ],
                value: [
                    {
                        required: true,
                        message: "Campo Obbligatorio",
                        trigger: "blur"
                    }
                ]
            }
        };
    },
    methods: {
        async fetch() {
            this.loading = true;
            let params = {
                patient_id: this.profile_id
            };
            const response = await Contact.fetch(params);
            this.contact_types = response.data.contact_types;
            this.connection_types = response.data.connection_types;
            this.contacts = response.data.contacts;
            this.loading = false;
        },
        submit(formName) {
            this.$refs[formName].validate(valid => {
                if (!valid) return false;
                if (this.editMode) this.updateContact();
                else if (formName === "form") this.insert();
                else this.update();
            });
        },
        async insert() {
            this.submitLoading = true;
            let params = {
                patient_id: this.profile_id,
                data: this.form
            };
            await Contact.store(params);
            this.close();
            this.fetch();
        },
        confirm(id) {
            this.contactId = id;
            this.showConfirmDialog = true;
        },
        async updateContact() {
            this.submitLoading = true;
            let params = { contact: this.form };
            try {
                await Contact.update(this.contactId, params);
                this.close();
                this.fetch();
            } finally {
                this.submitLoading = false;
            }
        },
        editContact(entity) {
            if (!entity) return;
            this.contactId = entity.id;
            this.editMode = true;
            this.form = {
                name: entity.name,
                surname: entity.surname,
                type: String(entity.type),
                riferimento: Boolean(entity.riferimento)
            };
            this.showDialog = true;
        },
        async update() {
            this.submitLoading = true;
            let params = {
                type: this.formConfirm.type,
                value: this.formConfirm.value
            };
            await Contact.update(this.contactId, params);
            this.close();
            this.fetch();
        },
        destroy(id, description) {
            this.$confirm(`Annullare Contatto ${description} ?`, "Attenzione", {
                confirmButtonText: "OK",
                cancelButtonText: "Annulla",
                type: "warning"
            })
                .then(() => {
                    this.loading = true;
                    this.delete(id);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "Operazione annullata"
                    });
                });
        },
        destroyConnection(id, description) {
            this.$confirm(`Annullare Recapito ${description} ?`, "Attenzione", {
                confirmButtonText: "OK",
                cancelButtonText: "Annulla",
                type: "warning"
            })
                .then(() => {
                    this.loading = true;
                    this.deleteConnection(id);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "Operazione annullata"
                    });
                });
        },
        async delete(id) {
            await Contact.destroy(id);
            this.fetch();
        },
        async deleteConnection(id) {
            await Contact.deleteConnection(id);
            this.fetch();
        },
        rowClass({ row, rowIndex }) {
            if (row.enabled == 0) {
                return "deleted-row";
            }
            return "";
        },
        close() {
            this.showConfirmDialog = false;
            this.showDialog = false;
            this.submitLoading = false;
            this.editMode = false;

            this.form = {};
            this.formConfirm = {};
        }
    },
    mounted() {
        this.fetch();
    }
};
</script>
