<template>
   <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">

      <el-row :gutter="20">
        <el-col :span="6">
            <el-button
                class="float-left"
                round
                :disabled="disablePrev"
                @click="show(test_prev_gds5)"
            >
                <font-awesome-icon icon="long-arrow-alt-left" />
                Indietro
            </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_gds5.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_gds5.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_gds5)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_gds5" :test_model="test_model_gds5" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-col :span="24">
        <el-divider content-position="left"></el-divider>
      </el-col>

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px">

        <!-- 1 -->
        <el-col :span="24">
            <el-col :span="16">1. È fondamentalmente soddisfatto della sua vita?</el-col>
            <el-col :span="8">
                <el-form-item>
                    <el-radio-group v-model="test_gds5.q1_result" disabled>
                        <el-radio :label="0" border>Si</el-radio>
                        <el-radio :label="1" border>No</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
        </el-col>
        <!-- 2 -->
        <el-col :span="24">
            <el-col :span="16">2. Si annoia spesso?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds5.q2_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 3 -->
        <el-col :span="24">
            <el-col :span="16">3. Si sente spesso abbandonato?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds5.q3_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 4 -->
        <el-col :span="24">
            <el-col :span="16">4. Preferisce stare a casa anzichè uscire a fare cose nuove?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds5.q4_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>
        <!-- 5 -->
        <el-col :span="24">
            <el-col :span="16">5. Si sente un po' inutile così com'è oggi?</el-col>
            <el-col :span="8">
            <el-form-item>
                <el-radio-group v-model="test_gds5.q5_result" disabled>
                    <el-radio :label="1" border>Si</el-radio>
                    <el-radio :label="0" border>No</el-radio>
                </el-radio-group>
            </el-form-item>
            </el-col>
        </el-col>

        <el-row :gutter="20">
            <!-- BREAK Results -->
            <el-col :span="24">
                <el-divider></el-divider>
            </el-col>
            <!-- Results -->
            <el-col :span="24">
                <el-col :span="12">
                    <el-form-item label="Totale">
                        {{test_gds5.total_string}}
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="Gravitá della depressione">
                        {{test_gds5.total_description}}
                    </el-form-item>
                </el-col>
            </el-col>
        </el-row>

        </el-form>
      </el-row>

    </el-card>
  </el-row>
</template>

<script>
    import Test from "@/js/api/services/Test";
    import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

    export default {
        name: "Gds5",
        components: {
            TestsDisable,
        },
        props: [
            "test",
            "test_prev",
            "test_next",
            "test_name",
            "test_model",
            "test_type"
        ],
        data() {
            return {
                test_gds5: this.test,
                test_prev_gds5: this.test_prev,
                test_next_gds5: this.test_next,
                test_name_gds5: this.test_name,
                test_model_gds5: this.test_model,
                test_type_gds5: this.test_type,
                patient_id_gds5: this.test.patient_id,
                loading: true,
                disablePrev: false,
                disableNext: false,
            };
        },
        methods: {
            async show(test_id) {
                this.loading = true;

                let params = {
                    patient_id: this.patient_id_gds5,
                    test_model: this.test_model_gds5,
                    test_id: test_id,
                    type_id: this.test_type_gds5
                };

                const response = await Test.show(params);

                this.test_gds5 = response.data.test;
                this.test_name_gds5 = response.data.test_name;
                this.test_prev_gds5 = response.data.test_prev;
                this.test_next_gds5 = response.data.test_next;

                this.checkPrevNext();
            },
            checkPrevNext(){
                if(this.test_prev_gds5 === null)
                    this.disablePrev = true
                else
                    this.disablePrev = false

                if(this.test_next_gds5 === null)
                    this.disableNext = true
                else
                    this.disableNext = false

                this.loading = false
            }
        },
        beforeMount() {
            this.checkPrevNext();
        }
    };
</script>
