<template>
  <el-row style="margin-top: 0px; font-size: 12px; margin: 0">
    <h4 class="title">INDICATORI SOSIA</h4>
    <el-row v-for="item of data" :key="item.id">
      <el-col :span="24">
        <el-row>
          <el-col :span="3"
            ><p style="font-weight: bold; margin: 0">Inizio</p>
            <p style="margin: 0">
              {{ _.get(item, "clean_inizio") }}
            </p></el-col
          >
          <el-col :span="3"
            ><p style="font-weight: bold; margin: 0">Fine</p>
            <p style="margin: 0">
              {{ _.get(item, "clean_fine") }}
            </p></el-col
          >
          <el-col :span="3"
            ><p style="font-weight: bold; margin: 0">Mobilità</p>
            <p style="margin: 0">{{ indicatoriMobilita(item) }}</p></el-col
          >
          <el-col :span="3"
            ><p style="font-weight: bold; margin: 0">Cognitività</p>
            <p style="margin: 0">{{ indicatoriCognitivita(item) }}</p></el-col
          >
          <el-col :span="9"
            ><p style="font-weight: bold; margin: 0">Comorbilità</p>
            <p style="margin: 0">{{ indicatoriComorbilita(item) }}</p></el-col
          >
          <el-col :span="3"
            ><p style="font-weight: bold; margin: 0">Cl.Att.</p>
            <p style="margin: 0">{{ _.get(item, 'classe') }}</p></el-col
          >
        </el-row>
      </el-col>
      <el-col :span="24">
        <el-row>
          <el-col :span="12"
            ><p style="font-weight: bold; margin: 0">Evento Ind.</p>
            <p style="margin: 0">{{ _.get(item, 'clean_evento_indice') }}</p
          ></el-col>
          <el-col :span="12"
            ><p style="font-weight: bold; margin: 0">Tipologia Ospite</p>
            <p style="margin: 0">{{ config['tipologia_ospite'][_.get(trattamento, 'tipo_ospite')] }}</p></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="12"
            ><p style="font-weight: bold; margin: 0">Tipologia Posto Letto</p>
            <p style="margin: 0">{{config['tipologia_posto_letto'][_.get(trattamento, 'tipo_posto_letto')] }}</p></el-col
          >
          <el-col :span="12"
            ><p style="font-weight: bold; margin: 0">Classificazione Ospite</p>
            <p style="margin: 0"> {{ config['classificazione_ospite'][_.get(trattamento, 'classificazione_ospite')] || `NON CI SONO CLASSIFICAZIONI DISPONIBILI PER QUESTA TIPOLOGIA D'OSPITE` }}
            </p></el-col
          >
        </el-row>
        <el-row>
          <el-col :span="24" v-if="ultimaConvalida(item)"
            ><p style="font-weight: bold; margin: 0">Data di ultima convalida</p>
            <p style="margin: 0">{{ ultimaConvalida(item) }}</p></el-col
          >
        </el-row>
        <md-divider> </md-divider>
      </el-col>
    </el-row>
  </el-row>
</template>
<script>
export default {
  name: "IndicatoriSosia",
  props: ["data", "trattamento", "config"],
  components: {},
  data() {
    return {};
  },
  computed: {
  },
  methods: {
    indicatoriMobilita(sosia2) {
      const fields = [
        "indicatore_mobilita_trasferimento_letto_sedia",
        "indicatore_mobilita_deambulazione",
        "indicatore_mobilita_locomozione_su_sedia_a_rotelle",
        "indicatore_mobilita_igiene_personale",
        "indicatore_mobilita_alimentazione",
      ];
     return _.values(_.pick(sosia2, fields)).join(",");
    },
    indicatoriCognitivita(sosia2) {
      const fields = [
        "indicatore_cognitivita_e_comportamento_confusione",
        "indicatore_cognitivita_e_comportamento_irritabilita",
        "indicatore_cognitivita_e_comportamento_irrequietezza",
      ];
     return _.values(_.pick(sosia2, fields)).join(",");
    },
    indicatoriComorbilita(sosia2) {
      const fields = [
        "indicatore_comorbilita_cardiaca",
        "indicatore_comorbilita_ipertensione",
        "indicatore_comorbilita_vascolari",
        "indicatore_comorbilita_respiratorie",
        "indicatore_comorbilita_oongl",
        "indicatore_comorbilita_apparato_gastro_superiore",
        "indicatore_comorbilita_apparato_gastro_inferiore",
        "indicatore_comorbilita_epatiche",
        "indicatore_comorbilita_renali",
        "indicatore_comorbilita_altre_patologie",
        "indicatore_comorbilita_muscolo_scheletrico",
        "indicatore_comorbilita_sistema_nervoso",
        "indicatore_comorbilita_endocrine",
        "indicatore_comorbilita_psichiatrico",
      ];
     return _.values(_.pick(sosia2, fields)).join(",");
    },
    ultimaConvalida(valutazione) {
      return _.get(_.head(valutazione.convalide), 'clean_inizio');
    }
  },
};
</script>

<style lang="scss" scoped>
.title {
  border: 0.5px solid #9c9fa5;
  padding: 2px;
  border-radius: 2px;
}
.key {
  font-weight: bold;
}
.value {
}
</style>