<template>
  <div v-if="showActions" class="container">
    <el-button 
      @click="onReset"
      type="warning" 
      class="fab-bottom-left fixed">Azzera</el-button>

    <el-button 
      @click="onSave" 
      type="success" 
      :loading="loading" 
      class="fab-bottom-right fixed">Conferma</el-button>
  </div>
</template>


<script>
export default {
  name: "TestActions",
  props: {
    showActions: Boolean,
    loading: Boolean
  },
  data() {
    return {};
  },
  methods: {
    onSave() {
      this.$emit("save");
    },
    onReset() {
      this.$emit("reset");
    }
  }
};
</script>

<style scoped>
.fab-bottom-right {
  top: auto;
  right: 20px;
  bottom: 10px;
  width: 15%;
  left: auto;
  z-index: 5;
}

.fab-bottom-left {
  top: auto;
  left: 20px;
  bottom: 10px;
  right: auto;
  width: 15%;
  z-index: 5;
}

.fixed {
  position: fixed;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>