<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_mnae)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_mnae.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_mnae.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_mnae)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_mnae" :test_model="test_model_mnae" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-divider content-position="center">Screening</el-divider>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q1_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item
                          label="A. Presenta una perdita dell' appetito? Ha mangiato meno negli ultimi 3 mesi? (perdita d'appetito, problemi digestivi, difficoltà di masticazione o deglutizione)"
                          required
                        />
                      </el-col>

                      <el-col :span="12">
                        <el-radio-group v-model="test_mnae.q1_result">
                          <el-form-item>
                            <el-radio :label="0">grave riduzione dell'assunzione di cibo</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="1">moderata riduzione dell'assunzione di cibo</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="2">nessuna riduzione dell'assunzione di cibo</el-radio>
                          </el-form-item>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q2_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="B. Perdita di peso recente (<3 mesi)" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q2_result">
                                <el-form-item>
                                  <el-radio :label="0">perdita di peso &gt; 3 kg</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">non sa</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">perdita di peso tra 1 e 3 kg</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">nessuna perdita di peso</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q3_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="C. Motricità" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q3_result">
                                <el-form-item>
                                  <el-radio :label="0">dal letto alla poltrona</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">autonomo a domicilio</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">esce di casa</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q4_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item
                          label="D. Nell’ arco degli ultimi 3 mesi: malattie acute o stress psicologici?"
                          required
                        />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q4_result">
                                <el-form-item>
                                  <el-radio :label="0">sì</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">no</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q5_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="E. Problemi neuropsicologici" required />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q5_result">
                                <el-form-item>
                                  <el-radio :label="0">demenza o depressione grave</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">demenza moderata</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">nessun problema psicologico</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q6_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item
                          label="F. Indice di massa corporea (IMC = peso / (altezza)2 in kg/ m2)"
                          required
                        />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q6_result">
                                <el-form-item>
                                  <el-radio :label="0">IMC &lt; 19</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">19 &le; IMC &lt; 21</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">21 &le; IMC &lt; 23</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">IMC &ge; 23</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-row type="flex" justify="end" align="center">
                    <p>Valutazione di screening</p>
                    <span class="partial">{{ test_mnae.total_screening }}</span>
                  </el-row>
                </el-col>
              </el-col>
            </el-col>
          </el-row>

          <el-divider content-position="center">Valutazione globale</el-divider>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q7_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item
                          label="G. Il paziente vive autonomamente a domicilio?" />
                      </el-col>

                      <el-col :span="12">
                        <el-radio-group v-model="test_mnae.q7_result">
                          <el-form-item>
                            <el-radio :label="1">sí</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="0">no</el-radio>
                          </el-form-item>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q8_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="H. Prende più di 3 medicinali al giorno?" />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q8_result">
                                <el-form-item>
                                  <el-radio :label="0">sí</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">no</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q9_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="I. Presenza di decubiti, ulcere cutanee?" />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q9_result">
                                <el-form-item>
                                  <el-radio :label="0">sí</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">no</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q10_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="J. Quanti pasti completi prende al giorno?" />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q10_result">
                                <el-form-item>
                                  <el-radio :label="0">1 pasto</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">2 pasti</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">3 pasti</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="K. Consuma ?" />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <el-col :span="20" :offset="1">
                      <el-row>
                        <el-col :span="14">
                          <el-form-item
                            label="Almeno una volta al giorno dei prodotti lattiero-caseari?" />
                        </el-col>
                        <el-col :span="10">
                          <el-row>
                            <el-col>
                              <div>
                                <el-form-item prop="q11_a_result">
                                  <el-radio-group v-model="test_mnae.q11_a_result">
                                    <el-form-item>
                                      <el-radio :label="1">Si</el-radio>
                                      <el-radio :label="0">No</el-radio>
                                    </el-form-item>
                                  </el-radio-group>
                                </el-form-item>
                              </div>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-col>

                  <el-col :span="24">
                    <el-col :span="20" :offset="1">
                      <el-row>
                        <el-col :span="14">
                          <el-form-item
                            label="Una o due volte la settimana uova o legumi?" />
                        </el-col>
                        <el-col :span="10">
                          <el-row>
                            <el-col>
                              <div>
                                <el-form-item prop="q11_b_result">
                                  <el-radio-group v-model="test_mnae.q11_b_result">
                                    <el-form-item>
                                      <el-radio :label="1">Si</el-radio>
                                      <el-radio :label="0">No</el-radio>
                                    </el-form-item>
                                  </el-radio-group>
                                </el-form-item>
                              </div>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-col>

                  <el-col :span="24">
                    <el-col :span="20" :offset="1">
                      <el-row>
                        <el-col :span="14">
                          <el-form-item
                            label="Oni giorne della carne, del pesce o del pollame?" />
                        </el-col>
                        <el-col :span="10">
                          <el-row>
                            <el-col>
                              <div>
                                <el-form-item prop="q11_c_result">
                                  <el-radio-group v-model="test_mnae.q11_c_result">
                                    <el-form-item>
                                      <el-radio :label="1">Si</el-radio>
                                      <el-radio :label="2">No</el-radio>
                                    </el-form-item>
                                  </el-radio-group>
                                </el-form-item>
                              </div>
                            </el-col>
                          </el-row>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-col>
                </el-row>
                <el-divider />
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q12_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item
                          label="L. Consuma almeno due volte al giorno frutta o verdura?" />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q12_result">
                                <el-form-item>
                                  <el-radio :label="0">no</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">sí</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q13_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item
                          label="M. Quanti bicchieri beve al giorno? (acqua, succhi, caffé, té, latte…)" />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q13_result">
                                <el-form-item>
                                  <el-radio :label="0.0">meno di 3 bicchieri</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="0.5">da 3 a 5 bicchieri</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1.0">più di 5 bicchieri</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q14_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="N. Come si nutre?" />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q14_result">
                                <el-form-item>
                                  <el-radio :label="0">necessita di assistenza</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">autonomamente con difficoltà</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">autonomamente senza difficoltà</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q15_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item
                          label="O. Il paziente si considera ben nutrito? (ha dei problemi nutrizionali)" />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q15_result">
                                <el-form-item>
                                  <el-radio :label="0">malnutrizione grave</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">malnutrizione moderata o non sa</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">nessun problema nutrizionale</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q16_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item
                          label="P. Il paziente considera il suo stato di salute migliore o peggiore di altre persone della sua età?" />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q16_result">
                                <el-form-item>
                                  <el-radio :label="0.0">meno buono</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="0.5">non sa</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1.0">uguale</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2.0">migliore</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q17_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Q. Circonferenza brachiale (CB, cm)" />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q17_result">
                                <el-form-item>
                                  <el-radio :label="0.0">CB &lt; 21</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="0.5">CB &le; 21 CB &le; 22</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1.0">CB &gt; 22</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q18_result">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="R. Circonferenza del polpaccio (CP in cm)" />
                      </el-col>
                      <el-col :span="12">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_mnae.q18_result">
                                <el-form-item>
                                  <el-radio :label="0">CP &lt; 31</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">CP &ge; 31</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-row type="flex" justify="end" align="center">
                    <p>Valutazione globale</p>
                    <span class="partial">{{ test_mnae.total_global }}</span>
                  </el-row>
                </el-col>
              </el-col>
            </el-col>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_mnae.total_string}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Risultato">{{test_mnae.total_description}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Mnae",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_mnae: this.test,
      test_prev_mnae: this.test_prev,
      test_next_mnae: this.test_next,
      test_name_mnae: this.test_name,
      test_model_mnae: this.test_model,
      test_type_mnae: this.test_type,
      patient_id_mnae: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
      tableData: [
        {
          points: "24-30 da 24 a 30 punti",
          state: "stato nutrizionale normale"
        },
        {
          points: "17-23.5 da 17 a 23,5 punti",
          state: "rischio di malnutrizione"
        },
        {
          points: "meno 17 punti",
          state: "cattivo stato nutrizionale"
        }
      ]
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_mnae,
        test_model: this.test_model_mnae,
        test_id: test_id,
        type_id: this.test_type_mnae
      };

      const response = await Test.show(params);
      this.test_mnae = response.data.test;
      this.test_name_mnae = response.data.test_name;
      this.test_prev_mnae = response.data.test_prev;
      this.test_next_mnae = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_mnae === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_mnae === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>

<style scoped>
.radio-label {
  white-space: normal;
}
.radio-label_text {
  margin-left: 20px;
}
.partial {
  font-weight: bold;
  margin-left: 10px;
}
</style>
