<template>
    <el-row v-loading="loading" :gutter="20" class="no-margin-row">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>{{name}}</span>
            </div>

            <div v-for="(chart, index) in chart_data.dataset" :key="index">
                <h3>{{chart.name}}</h3>
                <canvas key="index" :id="'test-chart-' + index" height="90"></canvas>
            </div>
        </el-card>
    </el-row>
</template>

<script>
    import moment from "moment";
    import Chart from 'chart.js';
    import Test from "@/js/api/services/Test";
    import Tests from "@/js/pages/Patients/components/Tests";

    export default {
        name: "TestsChartRss",
        props: [
            "chart_data",
        ],
        data() {
            return {
                loading: true,
                name: this.chart_data.test_name,
            };
        },
        methods: {
            renderChart(chartId, chartData) {
                const ctx = document.getElementById(chartId);
                const myChart = new Chart(ctx, {
                    type: chartData.type,
                    data: chartData.data,
                    options: chartData.options,
                });
                this.loading = false;
            }
        },
        mounted() {
            Object.entries(this.chart_data.dataset).forEach(([key, chart_data_patient]) => {
                var testChartData =  {
                    loading: true,
                    testChartData: {
                        type: 'line',
                        data: {
                            labels: Object.values(chart_data_patient.label),
                            datasets: [{
                                label: 'Punteggio Totale',
                                backgroundColor: '#e6a23c',
                                borderColor: '#e6a23c',
                                data: Object.values(chart_data_patient.data),
                                fill: false,
                            }]
                        },
                        options: {
                            responsive: true,
                            title: {
                                display: false
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false,
                            },
                            hover: {
                                mode: 'nearest',
                                intersect: true
                            },
                            legend: {
                                display: false
                            },
                            scales: {
                                xAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Data di esecuzione'
                                    }
                                }],
                                yAxes: [{
                                    display: true,
                                    scaleLabel: {
                                        display: true,
                                        labelString: 'Punteggio Totale'
                                    },
                                    ticks: {
                                        beginAtZero: true,
                                        max: chart_data_patient.suggested_max,
                                    },
                                }]
                            }
                        }
                    }
                };
                this.renderChart('test-chart-' + key, testChartData.testChartData);
            });
        }
    };
</script>
