import Api from "@/js/api/Api";

export default class Activity {
    static endpoint = "activities";

    static list(params) {
        return Api.get(this.endpoint, { params }).then(({ data }) => data);
    }

    static get(id) {
        return Api.get(`${this.endpoint}/${id}`);
    }

    static save(payload) {
        return Api.post(this.endpoint, payload);
    }

    static delete(id) {
        return Api.delete(`${this.endpoint}/${id}`);
    }
    static adminList(params) {
        return Api.get(`${this.endpoint}/admin/list`, { params });
    }
    static adminDisableActivity(params) {
        return Api.post(`${this.endpoint}/admin/disabled`, params);
    }
    static adminRestoreActivity(params) {
        return Api.post(`${this.endpoint}/admin/restore`, params);
    }
    static adminSearchDisabledActivity(params) {
        return Api.get(`${this.endpoint}/admin/search`, { params });
    }
}
