<script>
export default {
  name: "SosiaPatientsTable",
  props: ["loading", "list", "config"],
  computed: {
    maxHeight() {
      return window.innerHeight - 195;
    },
  },
};
</script>

<template>
    <el-table class="patient-table" :fit="true" :max-height="maxHeight" v-loading="loading" :data="list">
        <el-table-column fixed label="Ospite" width="240" sortable>
            <template slot-scope="scope">
                {{ scope.row.full_name }}
                <el-tooltip content="Complessità assistenziale"
                    v-if="scope.row.sosiaStatus.ca">
                    <em class="el-icon-warning" style="color: red; margin-left: 5px;" />
                </el-tooltip>
            </template>
        </el-table-column>
        <el-table-column fixed prop="sosiaStatus.codice_trattamento" label="Codice Trattamento" />
        <el-table-column fixed prop="sosiaStatus.tipologia_ospite" label="Tipologia Ospite" />
        <el-table-column fixed prop="sosiaStatus.tipologia_posto_letto" label="Tipologia Posto Letto" />
        <el-table-column prop="sosiaStatus.data_ammissione" label="Data Ammissione" />
        <el-table-column prop="sosiaStatus.data_ultima_attivita" label="Data Ultima Attivitá" />
        <el-table-column prop="sosiaStatus.classe" label="Classe" />

        <el-table-column prop="sosiaStatus" label="Status">
            <template slot-scope="scope">
                <el-popover title="Sosia Status" placement="top" width="500" trigger="click" :content="`${
            scope.row.sosiaStatus.valid ? 'OK' : scope.row.sosiaStatus.causa
          }`">
                    <el-button slot="reference" size="small">
                        <font-awesome-icon icon="check-circle" size="2x" class="success-text"
                            v-if="scope.row.sosiaStatus.valid" />
                        <font-awesome-icon icon="times-circle" size="2x" class="danger-text" v-else />
                    </el-button>
                </el-popover>
            </template>
        </el-table-column>
        <el-table-column label="Dettaglio" align="center" fixed="right">
            <template slot-scope="scope">
                <router-link :to="`/sosia/${scope.row.id}`">
                    <em class="el-icon-view" />
                </router-link>
            </template>
        </el-table-column>
    </el-table>
</template>

<style scss scoped>
.invalid {
  font-weight: bolder;
  color: crimson;
}
</style>
