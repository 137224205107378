<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-divider content-position="center">Valutazione antropometrica</el-divider>
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q1_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Indice di massa corporea" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q1_result">
                            <el-form-item>
                              <el-radio :label="0">BMI &lt; 19</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">BMI 19-21</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">BMI 21-23</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">BMI &gt; 23</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q2_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Circonferenza metà braccio" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q2_result">
                            <el-form-item>
                              <el-radio :label="0">MAC &lt; 21</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="0.5">MAC 21-22</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">MAC &gt; 22</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q3_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Circonferenza polpaccio" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q3_result">
                            <el-form-item>
                              <el-radio :label="0">CC &lt; 31</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">MAC &gt;= 31</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q4_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Perdita peso recente" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q4_result">
                            <el-form-item>
                              <el-radio :label="0">&gt; 3Kg</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Non so</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">1-3 Kg</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">Nessuna</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-divider content-position="center">Valutazione generale</el-divider>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q5_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Vive indipendentemente" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q5_result">
                            <el-form-item>
                              <el-radio :label="0">No</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Si</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q6_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Assume piú di tre farmaci/die" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q6_result">
                            <el-form-item>
                              <el-radio :label="1">No</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="0">Si</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q7_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Stress o malattie acute" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q7_result">
                            <el-form-item>
                              <el-radio :label="2">No</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Si</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q8_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Mobilitá" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q8_result">
                            <el-form-item>
                              <el-radio :label="0">Letto-carrozzina</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Si alza</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Nessun problema</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q9_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Problemi neuropsicologici" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q9_result">
                            <el-form-item>
                              <el-radio :label="0">Demenza-depressione gravi</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Demenza-depressione lievi</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">No</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q10_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Piaghe da decubito" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q10_result">
                            <el-form-item>
                              <el-radio :label="0">Si</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">No</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-divider content-position="center">Valutazione alimentare</el-divider>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q11_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Quanti pasti consuma al giorno" />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q11_result">
                            <el-form-item>
                              <el-radio :label="0">1 pasto</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">2 pasti</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">3 pasti</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="Consuma:" />
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-col :span="20" :offset="1">
                    <el-row>
                      <el-col :span="14">
                        <el-form-item
                          label="Almeno 1 pasto al giorno a base di latticini"
                          required
                        />
                      </el-col>
                      <el-col :span="10">
                        <el-row>
                          <el-col>
                            <div>
                              <el-form-item prop="q12_a_result">
                                <el-radio-group v-model="result.q12_a_result">
                                  <el-form-item>
                                    <el-radio :label="1">Si</el-radio>
                                    <el-radio :label="0">No</el-radio>
                                  </el-form-item>
                                </el-radio-group>
                              </el-form-item>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-col>

                <el-col :span="24">
                  <el-col :span="20" :offset="1">
                    <el-row>
                      <el-col :span="14">
                        <el-form-item label="Una o due volte la settimana uova o legumi" required />
                      </el-col>
                      <el-col :span="10">
                        <el-row>
                          <el-col>
                            <div>
                              <el-form-item prop="q12_b_result">
                                <el-radio-group v-model="result.q12_b_result">
                                  <el-form-item>
                                    <el-radio :label="1">Si</el-radio>
                                    <el-radio :label="0">No</el-radio>
                                  </el-form-item>
                                </el-radio-group>
                              </el-form-item>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-col>

                <el-col :span="24">
                  <el-col :span="20" :offset="1">
                    <el-row>
                      <el-col :span="14">
                        <el-form-item label="Tutti i giorni carne o pesce" required />
                      </el-col>
                      <el-col :span="10">
                        <el-row>
                          <el-col>
                            <div>
                              <el-form-item prop="q12_c_result">
                                <el-radio-group v-model="result.q12_c_result">
                                  <el-form-item>
                                    <el-radio :label="1">Si</el-radio>
                                    <el-radio :label="2">No</el-radio>
                                  </el-form-item>
                                </el-radio-group>
                              </el-form-item>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-col>
              </el-row>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q13_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Consuma 2v/dí frutta/verdura" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q13_result">
                            <el-form-item>
                              <el-radio :label="0">No</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Si</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q14_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Appetito ridotto ultimi 3 mesi" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q14_result">
                            <el-form-item>
                              <el-radio :label="0">Notevolmente</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Moderatamente</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">No</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q15_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Liquidi al giorno" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q15_result">
                            <el-form-item>
                              <el-radio :label="0">&lt; 5 bicc</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="0.5">5-9 bicc</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">9 bicc</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q16_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Come mangia" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q16_result">
                            <el-form-item>
                              <el-radio :label="0">Imboccato</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Con aiuto</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Da solo</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-divider content-position="center">Autovalutazione</el-divider>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q17_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Ritiene di avere problemi" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q17_result">
                            <el-form-item>
                              <el-radio :label="0">Si</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Forse, non sa</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Nessun problema</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q18_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Salute in confronto coetanei" />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q18_result">
                            <el-form-item>
                              <el-radio :label="0">Meno buona</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Non sa</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Migliore</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="Risultato (Cut off = 17)">
              <el-input v-model="totalDescription" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-divider />
            <el-form-item label="Note">
              <el-input type="textarea" v-model="result.note"></el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults } from "../constants";
import TestActions from "../components/TestActions";

const custom = ["q12_a_result", "q12_b_result", "q12_c_result"];

export default {
  name: "Mna",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 30
      },
      result: {},
      testRules: rules(18, custom)
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_description: this.totalDescription
          };
          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    computeQ12() {
      let groupValues = _.values(filterResults(this.result, custom));
      if(_.isEmpty(groupValues)) return;
      return groupValues.reduce(_.add, 0);
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    totalDescription() {
      return _.cond([
        [
          _.conforms({ total: n => _.inRange(n, 12, 18) }),
          _.constant("Rischio di malnutrizione")
        ],
        [
          _.conforms({ total: n => _.lt(n, 12) }),
          _.constant("Malnutrizione in atto o rischio molto elevato")
        ],
        [_.conforms({ total: n => _.gt(n, 17) }), _.constant("Nella norma")]
      ])(this.form);
    },
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        this.result.q12_result = this.computeQ12();
        let results = _.values(filterResults(this.result, null, custom));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.bottom-space {
  margin-bottom: 50px;
}
</style>