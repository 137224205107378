<template>
  <div>
    <el-button
      class="add-button"
      type="success"
      style="margin-right: 120px"
      @click="showDialog = true"
      >IMPORT FILE</el-button
    >
    <router-link to="/settings/users/create"
      ><el-button class="float-right add-button no-broadcast" type="success"
        ><font-awesome-icon icon="plus" /> INSERISCI</el-button
      ></router-link
    >

    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Import utenti da file</md-dialog-title>
      <el-form label-position="top" ref="form">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-upload
              :drag="!this.disabled"
              :action="`/api/operators/checkFile`"
              :auto-upload="true"
              :before-upload="beforeUpload"
              :on-success="saveFile"
              :on-error="warningFile"
              :on-remove="resumeDrag"
              multiple
              :limit="1"
              :show-file-list="true"
              :with-credentials="true"
              :headers="uploadHeaders"
              style="margin-bottom: 30px"
            >
              <i v-if="!this.disabled" class="el-icon-upload"></i>
              <div v-if="!this.disabled" class="el-upload__text">
                Trascina i files oppure <em>clicca</em>
              </div>
            </el-upload>
          </el-col>
        </el-row>
      </el-form>
      <md-dialog-actions class="noPositionFixed">
        <div>
          <el-button
            id="buttonDownloadExample"
            type="info"
            plain
            @click="exampleExport('xls')"
            :loading="downloadLoading"
            >Scarica esempio excel</el-button
          >
          <el-button
            id="buttonDownloadExample"
            type="info"
            plain
            @click="exampleExport('csv')"
            :loading="downloadLoading"
            >Scarica esempio CSV</el-button
          >
        </div>
        <div>
          <el-button
            id="buttonDownloadExample"
            type="info"
            plain
            @click="showDialog = false"
            :disabled="downloadLoading"
            >Annulla</el-button
          >
          <el-button
            id="buttonDownloadExample"
            type="success"
            plain
            @click="importFile()"
            :loading="downloadLoading"
            >Importa</el-button
          >
        </div>
      </md-dialog-actions>
    </md-dialog>

    <el-input
      v-model="search"
      size="large"
      placeholder="Cerca utente"
      class="search-input p-10"
      clearable
    />
    <el-table
      class="patient-table"
      :data="
        this.users
          .concat(this.otherUsers)
          .filter(
            (data) =>
              !search ||
              data.full_name.toLowerCase().includes(search.toLowerCase()) ||
              data.username.toLowerCase().includes(search.toLowerCase())
          )
      "
      style="width: 100%"
      :fit="true"
      :max-height="maxHeight"
      v-loading="loading"
    >
      <el-table-column
        fixed
        prop="full_name"
        label="Utente"
        min-width="230"
        sortable
      ></el-table-column>
      <el-table-column prop="role_id" label="Ruolo" width="200">
        <template slot-scope="scope">
          <p v-if="roles && scope.row.role_id">
            {{ roles[scope.row.role_id] }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        prop="username"
        label="Username"
        min-width="300"
      ></el-table-column>
      <el-table-column
        prop="email"
        label="Email"
        min-width="300"
      ></el-table-column>
      <el-table-column fixed="right" label="Azioni" align="rights" width="120">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            plain
            @click="$router.push('/settings/users/' + scope.row.id + '/edit')"
            v-if="
              scope.row.enabled &&
              scope.row.structures_array.includes($store.state.structureId)
            "
            ><i class="el-icon-edit"></i
          ></el-button>
          <el-button
            :loading="buttonLoading"
            type="danger"
            size="small"
            plain
            @click="confirm(scope.row.id, scope.row.full_name, 'delete')"
            v-if="scope.row.structures_array.includes($store.state.structureId)"
            ><i class="el-icon-delete"></i
          ></el-button>
          <el-button
            :loading="buttonLoading"
            type="success"
            size="small"
            plain
            @click="confirm(scope.row.id, scope.row.full_name, 'restore')"
            v-if="
              !scope.row.structures_array.includes($store.state.structureId)
            "
            ><i class="el-icon-refresh"></i
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import User from "@/js/api/services/User";
import ArkFile from "@/js/helpers/file";
export default {
  name: "UsersAdmin",
  data() {
    return {
      users: [],
      otherUsers: [],
      search: "",
      roles: [],
      professions: [],
      loading: true,
      buttonLoading: false,
      showDialog: false,
      downloadLoading: false,
      file: null,
      disabled: false,
      uploadHeaders: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        Authorization: `Bearer ${localStorage.getItem("arkicare_session")}`,
      },
      typeList: ["text/csv", "application/vnd.ms-excel"],
      loadingOperator: false,
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 210;
    },
  },
  watch: {
    search: {
      handler() {
        if (this.search != "") {
          if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
          }
          this.timer = setTimeout(() => {
            this.searchOtherOperator();
          }, 300);
        } else {
          this.otherUsers = [];
        }
      },
    },
  },
  methods: {
    async fetch() {
      this.loading = true;
      const response = await User.fetch({
        structureId: this.$store.state.structureId,
      });
      this.users = response.data.operators;
      this.loading = false;
    },
    async fetchRoles() {
      const response = await User.roles();
      this.roles = response.data;
    },
    confirm(id, full_name, action) {
      if (action === "delete") {
        this.$confirm(`Eliminare ${full_name} ?`, "Attenzione", {
          confirmButtonText: "Sì",
          cancelButtonText: "Annulla",
          type: "warning",
        })
          .then(() => {
            this.buttonLoading = true;
            this.delete(id);
          })
          .catch(() => {
            this.abort();
          });
      } else {
        this.$confirm(`Ripristinare ${full_name} ?`, "Attenzione", {
          confirmButtonText: "Sì",
          cancelButtonText: "Annulla",
          type: "warning",
        })
          .then(() => {
            this.buttonLoading = true;
            this.restore(id);
          })
          .catch(() => {
            this.abort();
          });
      }
    },
    async delete(id) {
      await User.disassociate(this.$store.state.structureId, id);
      this.success();
    },
    async restore(id) {
      await User.restore(this.$store.state.structureId, id);
      this.success();
    },
    success() {
      this.$notify({
        title: "OK",
        message: "Operazione completata",
        type: "success",
      });
      this.search = "";
      this.user = [];
      this.otherUsers = [];

      this.fetch();
      this.buttonLoading = false;
    },
    abort() {
      this.$message({
        type: "info",
        message: "Operazione annullata",
      });
      this.loading = false;
      this.buttonLoading = false;
    },
    beforeUpload(file) {
      const accettable = this.typeList.includes(file.type);
      const isLt50M = file.size / 1024 / 1024 < 50;

      if (!accettable) {
        this.$notify({
          title: "Error",
          message: "Formato del File non supportato!",
          type: "error",
        });
      }
      if (!isLt50M) {
        this.$notify({
          title: "Error",
          message: "La dimensione del file supera il limite di 50MB!",
          type: "error",
        });
      }
      return accettable && isLt50M;
    },
    warningFile(error) {
      const obj = JSON.parse(error.message);
      this.$notify({
        title: "Errore",
        message: obj.message,
        type: "error",
      });
    },
    saveFile(file) {
      this.file = file;
      this.disabled = true;
    },
    resumeDrag() {
      this.disabled = false;
    },
    async exampleExport(fileType) {
      this.downloadLoading = true;
      const params = { fileType: fileType };
      ArkFile.downloadFromRequest(User.exampleExport(params), "")
        .catch(() => {
          this.downloadLoading = false;
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },
    importFile() {
      this.downloadLoading = true;
      const params = {
        file: this.file,
        structureId: this.$store.state.structureId,
      };
      User.importFile(params)
        .then((response) => {
          if (response.data.status == "warning") {
            this.$notify({
              title: "Warning",
              message: response.data.mess,
              type: "warning",
            });
          }
        })
        .catch((error) => {
          this.warningFile(error);
        })
        .finally(() => {
          this.fetch();
          this.downloadLoading = false;
          this.showDialog = false;
          this.file = null;
          this.disabled = false;
        });
    },
    async searchOtherOperator() {
      if (this.search == "") {
        this.otherUsers = [];
        return;
      }
      this.loading = true;
      const response = await User.fetch({
        searchString: this.search,
      });
      this.otherUsers = response.data.operators.filter(
        (data) => !data.structures_array.includes(this.$store.state.structureId)
      );
      this.loading = false;
    },
  },
  mounted() {
    this.fetchRoles();
    this.professions = this.$store.state.professions;
    this.fetch();
  },
};
</script>

<style lang="scss" scoped>
.margin-top-bottom {
  height: 50px;
  font-size: 20px;
}
.p-10 {
  padding: 10px;
}

button#buttonDownloadExample.el-button.is-loading {
  position: relative !important;
}

.noPositionFixed {
  justify-content: space-between;
  padding: 8px;
}
</style>
