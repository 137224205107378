<template>
    <div id="dimissione">
        <el-row v-loading="loading" :gutter="20" class="no-margin-row">
            <el-col :span="12">
                <el-button class="float-left" type="primary" round @click="pdfExport()" :loading="submitLoading" :disabled="!exportEnabled">Esporta</el-button>
            </el-col>
            <el-col :span="12">
                <el-button class="float-right" type="success" round @click="submit()" :loading="submitLoading">Salva</el-button>
            </el-col>
            <el-col :span="24" class="margin-top">
                <el-form>
                    <div class="trigger-intercept"></div>
                    <el-tabs class="is-sticky" tab-position="right" style="margin-top: 20px">
                        <el-tab-pane label="Mobilizzazione">
                            <el-row class="no-margin-row">
                                <el-col :span="12">
                                    <el-form-item label="Mobilizzazione">
                                        <el-select v-model="form.mobilizzazione" clearable>
                                            <el-option v-for="item in variables.mobilizzazioni" :key="item.id" :label="item.value" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="form.mobilizzazione == 3">
                                    <el-form-item label="Ausilio">
                                        <el-select v-model="form.ausili" clearable>
                                            <el-option v-for="item in variables.ausili" :key="item.id" :label="item.value" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12" v-if="form.mobilizzazione == 6">
                                    <el-form-item label="Rischio caduta">
                                        <el-select v-model="form.rischio_caduta" clearable>
                                            <el-option v-for="item in variables.rischio" :key="item.id" :label="item.value" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24" v-if="form.mobilizzazione == 7">
                                    <el-form-item label="Note">
                                        <el-input type="textarea" :rows="4" v-model="form.note"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <!-- <el-checkbox-group> -->
                                    <el-form-item v-model="form.autonomia" label="Autonomia negli atti della vita quotidiana">
                                        <el-checkbox style="display: block" v-model="form.autonomia" label="autosufficiente">Autosufficiente</el-checkbox>
                                        <el-checkbox style="display: block" v-model="form.autonomia" label="lavarsi">Ha bisogno di aiuto per lavarsi</el-checkbox>
                                        <el-checkbox style="display: block" v-model="form.autonomia" label="vestirsi">Ha bisogno di aiuto per vestirsi</el-checkbox>
                                        <el-checkbox style="display: block" v-model="form.autonomia" label="dipendente">Completamente dipendente</el-checkbox>
                                        <el-checkbox style="display: block" v-model="form.autonomia" label="bagno">Ultimo bagno completo effettuato il
                                            <el-date-picker v-model="form.autonimia_data_bagno" v-if="form.autonomia.includes('bagno')" type="date" placeholder="Scegli data" style="width: 150px !important;" :editable="false" :picker-options="{
            firstDayOfWeek: 1,
            disabledDate(time) {
                return time.getTime() > Date.now();
            }
        }" format="dd/MM/yyyy" value-format="yyyy-MM-dd">
                                            </el-date-picker>
                                        </el-checkbox>
                                        <el-checkbox style="display: block;" v-model="form.autonomia" label="note">Altro/Note</el-checkbox>
                                        <el-form-item v-if="form.autonomia.includes('note')">
                                            <el-input type="textarea" :rows="4" v-model="form.autonomia_note"></el-input>
                                        </el-form-item>
                                    </el-form-item>
                                    <!-- </el-checkbox-group> -->
                                </el-col>
                                <el-col :span="24">
                                    <hr>
                                    <el-form-item style="display: block" label="rischio di lesioni da decubito (valutato secondo la scheda di Braden)">
                                        <el-radio style="display: block; margin-top: 5px;" v-model="form.lesioni.braden" label="basso">Basso rischio (valori > a 20)</el-radio>
                                        <el-radio style="display: block; margin-top: 5px;" v-model="form.lesioni.braden" label="medio">Medio rischio (valori compresi tra 16 e 19)</el-radio>
                                        <el-radio style="display: block; margin-top: 5px;" v-model="form.lesioni.braden" label="alto">Alto rischio (valori compresi tra 11 e 15)</el-radio>
                                        <el-radio style="display: block; margin-top: 5px;" v-model="form.lesioni.braden" label="altissimo">Altissimo rischio (valori &lt; 10)</el-radio>
                                    </el-form-item>
                                    <el-form-item label="Si consigliano mobilizzazioni con frequenza ogni">
                                        <el-input type="textarea" :rows="4" v-model="form.lesioni.braden_mobilizzazioni"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <hr>
                                    <el-row class="no-margin-row">
                                        <el-col :span="24">
                                            Rischio di lesioni da decubito (valutato secondo altra scala)
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="Scala">
                                                <el-input v-model="form.nome_altro"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-form-item>
                                        <div style=" margin-top: 10px">
                                            <el-radio style="display: block; height: 40px; line-height: 40px" v-model="form.lesioni.altro" label="basso">Basso rischio
                                                <el-input v-model="form.altro_valori" v-if="form.lesioni.altro == 'basso'" placeholder="Valori"></el-input>
                                            </el-radio>
                                            <el-radio style="display: block; height: 40px; line-height: 40px" v-model="form.lesioni.altro" label="medio">Medio rischio
                                                <el-input v-model="form.altro_valori" v-if="form.lesioni.altro == 'medio'" placeholder="Valori"></el-input>
                                            </el-radio>
                                            <el-radio style="display: block; height: 40px; line-height: 40px" v-model="form.lesioni.altro" label="alto">Alto rischio
                                                <el-input v-model="form.altro_valori" v-if="form.lesioni.altro == 'alto'" placeholder="Valori"></el-input>
                                            </el-radio>
                                            <el-radio style="display: block; height: 40px; line-height: 40px" v-model="form.lesioni.altro" label="altissimo">Altissimo rischio
                                                <el-input v-model="form.altro_valori" v-if="form.lesioni.altro == 'altissimo'" placeholder="Valori"></el-input>
                                            </el-radio>
                                        </div>
                                    </el-form-item>

                                    <el-form-item label="Si consigliano mobilizzazioni con frequenza ogni">
                                        <el-input type="textarea" :rows="4" v-model="form.lesioni.altro_mobilizzazioni"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item v-model="form.presidi" label="Presidi e ausili consigliati">
                                        <hr>
                                        <el-checkbox v-model="form.presidi" label="postura">Cuscini per postura</el-checkbox><br>
                                        <el-checkbox v-model="form.presidi" label="spondine">Spondine</el-checkbox><br>
                                        <el-checkbox v-model="form.presidi" label="archetto">Archetto alzacoperte</el-checkbox><br>
                                        <el-checkbox v-model="form.presidi" label="trapezio">Trapezio</el-checkbox><br>
                                        <el-checkbox v-model="form.presidi" label="letto">Letto articolato</el-checkbox><br>
                                        <el-checkbox v-model="form.presidi" label="materasso">Materasso antidecubito</el-checkbox><br>
                                        <el-checkbox v-model="form.presidi" label="sedia">Sedia a rotelle</el-checkbox><br>
                                        <el-checkbox v-model="form.presidi" label="antidecubito">Cuscino antidecubito</el-checkbox><br>
                                        <el-checkbox v-model="form.presidi" label="note">Altro/Note</el-checkbox>
                                        <el-form-item v-if="form.presidi.includes('note')">
                                            <el-input type="textarea" :rows="4" v-model="form.presidi_note"></el-input>
                                        </el-form-item>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="Lesioni cutanee">
                            <el-row class="no-margin-row">
                                <el-col :span="24">
                                    <el-form-item label="Descrizione lesioni (tipo/dimensioni/grado)">
                                        <el-input type="textarea" :rows="4" v-model="form.lesioni_lesioni"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <el-form-item label="Medicazioni in corso (indicare prodotti impiegati e frequenza delle medicazioni di ogni ferita)" id="medicazioni" class="long-label">
                                        <el-input type="textarea" :rows="4" v-model="form.medicazioni"></el-input>

                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="Eliminazione urinaria">
                            <el-row class="no-margin-row">
                                <el-col :span="24">
                                    <el-checkbox v-model="form.eliminazione_urinaria" label="continente">Continente</el-checkbox><br>
                                    <el-checkbox v-model="form.eliminazione_urinaria" label="incontinente_giorno_e_notte"> Iincontinente di giorno e di notte</el-checkbox><br>
                                    <el-checkbox v-model="form.eliminazione_urinaria" label="incontinente_notte"> Incontinente solo di notte</el-checkbox><br>
                                    <el-checkbox v-model="form.eliminazione_urinaria" label="incontinente_occasionale"> Incontinente occasionalmente</el-checkbox><br>
                                    <el-checkbox v-model="form.eliminazione_urinaria" label="incontinente_urgenza"> Incontinente da urgenza</el-checkbox><br>
                                    <el-form-item label="si consiglia" v-if="form.eliminazione_urinaria.includes('incontinente_urgenza')">
                                        <el-input type="textarea" :rows="4" v-model="form.eliminazione_urinaria_consigli" v-if="form.eliminazione_urinaria.includes('incontinente_urgenza')"></el-input>
                                    </el-form-item>
                                    <el-checkbox v-model="form.eliminazione_urinaria" label="urocontrol"> Usa l’Urocontrol</el-checkbox><br>
                                    <el-checkbox v-model="form.eliminazione_urinaria" label="pannolone"> Usa il pannolone</el-checkbox><br>
                                    <el-checkbox v-model="form.eliminazione_urinaria" label="catetere"> Porta il catetere vescicale</el-checkbox><br>
                                    <el-form-item v-if="form.eliminazione_urinaria.includes('catetere')" label="Calibro (Ch)">
                                        <el-input v-model="form.eliminazione_urinaria_calibo"></el-input>
                                    </el-form-item>
                                    <el-form-item label="Ultima sostituzione effettuata il " v-if="form.eliminazione_urinaria.includes('catetere')">
                                        <el-date-picker v-model="form.eliminazione_urinaria_data" type="date" placeholder="Scegli data" style="width: 150px !important;" :editable="false" :picker-options="{
            firstDayOfWeek: 1,
            disabledDate(time) {
                return time.getTime() > Date.now();
            }
        }" format="dd/MM/yyyy" value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-checkbox v-model="form.eliminazione_urinaria_posizinamento" label="posizionamento" v-if="form.eliminazione_urinaria.includes('catetere')">Presenta difficoltà di posizionamento</el-checkbox><br>
                                    <el-form-item label="Ha presentato complicanze (quali)" v-if="form.eliminazione_urinaria_posizinamento">
                                        <el-input type="textarea" :rows="4" v-model="form.eliminazione_urinaria_complicanze"></el-input>
                                    </el-form-item>
                                    <el-checkbox v-model="form.eliminazione_urinaria" label="stomia"> Portatore di stomia</el-checkbox><br>
                                    <el-form-item label="Altro/Note">
                                        <el-input type="textarea" :rows="4" v-model="form.eliminazione_urinaria_note"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="Eliminazione Intestinale">
                            <el-row class="no-margin-row">
                                <el-col :span="24">
                                    <el-checkbox v-model="form.eliminazione_intestinale" label="regolare">Regolare e spontanea</el-checkbox><br>
                                    <el-checkbox v-model="form.eliminazione_intestinale" label="diarrea">Diarrea</el-checkbox><br>
                                    <el-checkbox v-model="form.eliminazione_intestinale" label="stipsi"> Stipsi</el-checkbox><br>
                                    <el-checkbox v-model="form.eliminazione_intestinale" label="lassativi"> Usa lassativi</el-checkbox><br>
                                    <el-form-item label="(quali e con quale frequenza)" v-if="form.eliminazione_intestinale.includes('lassativi')">
                                        <el-input type="textarea" :rows="4" v-model="form.eliminazione_intestinale_lassativi"></el-input>
                                    </el-form-item>
                                    <el-checkbox v-model="form.eliminazione_intestinale" label="incontinente">Incontinente</el-checkbox><br>
                                    <el-checkbox v-model="form.eliminazione_intestinale" label="clisteri">Necessita di clisteri</el-checkbox><br>
                                    <el-form-item v-if="form.eliminazione_intestinale.includes('clisteri')" label="(quali e con quale frequenza)">
                                        <el-input type="textarea" :rows="4" v-model="form.eliminazione_intestinale_clisteri"></el-input>
                                    </el-form-item>
                                    <el-checkbox v-model="form.eliminazione_intestinale" label="stomia">Presenza di stomia</el-checkbox><br>
                                    <el-form-item v-if="form.eliminazione_intestinale.includes('stomia')" label="(sede)">
                                        <el-input type="textarea" :rows="4" v-model="form.eliminazione_intestinale_stomia_sede"></el-input>
                                    </el-form-item>
                                    <el-form-item v-if="form.eliminazione_intestinale.includes('stomia')" label="(Sistema placche e sacchetti usati)">
                                        <el-input type="textarea" :rows="4" v-model="form.eliminazione_intestinale_stomia_sistema"></el-input>
                                    </el-form-item>
                                    <el-form-item v-if="form.eliminazione_intestinale.includes('stomia')" label="(Grado di autonomia nella gestione della stomia)">
                                        <el-input type="textarea" :rows="4" v-model="form.eliminazione_intestinale_stomia_autonomia"></el-input>
                                    </el-form-item>
                                    <el-checkbox v-model="form.eliminazione_intestinale" label="evacuazione">Ultima evacuazione il

                                        <el-date-picker v-model="form.eliminazione_intestinale_data" v-if="form.eliminazione_intestinale.includes('evacuazione')" type="date" placeholder="Scegli data" style="width: 150px !important;" :editable="false" :picker-options="{
            firstDayOfWeek: 1,
            disabledDate(time) {
                return time.getTime() > Date.now();
            }
        }" format="dd/MM/yyyy" value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                    </el-checkbox><br>
                                    <el-form-item label="Altro/Note">
                                        <el-input type="textarea" :rows="4" v-model="form.eliminazione_intestinale_note"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="Situazione cognitivo-relazionale">
                            <el-row class="no-margin-row">
                                <el-col :span="24">
                                    <el-checkbox v-model="form.cognitivo_relazionale" label="normale">Normale</el-checkbox><br>
                                    <el-checkbox v-model="form.cognitivo_relazionale" label="udito">Presenta difetti dell'udito</el-checkbox><br>
                                    <el-form-item v-if="form.cognitivo_relazionale.includes('udito')" label="indicare di che tipo">
                                        <el-input type="textarea" :rows="4" v-model="form.cognitivo_relazionale_difficolta_udito"></el-input>
                                    </el-form-item>

                                    <el-checkbox v-model="form.cognitivo_relazionale" label="visivi">Presenta difetti visivi</el-checkbox>
                                    <el-form-item v-if="form.cognitivo_relazionale.includes('visivi')" label="indicare di che tipo">
                                        <el-input type="textarea" :rows="4" v-model="form.cognitivo_relazionale_difficolta_visive"></el-input>
                                    </el-form-item>

                                    <el-checkbox v-model="form.cognitivo_relazionale_difficolta_visive_portatore" style="margin-left: 20px; display:block" v-if="form.cognitivo_relazionale.includes('visivi')" label="occhiali">Portatore di occhiali</el-checkbox>
                                    <el-checkbox v-model="form.cognitivo_relazionale_difficolta_visive_portatore" style="margin-left: 20px; display:block" v-if="form.cognitivo_relazionale.includes('visivi')" label="protesi">Portatore di protesi oculare</el-checkbox>
                                    <el-checkbox v-model="form.cognitivo_relazionale_difficolta_visive_portatore_sx_dx" style="margin-left: 40px; display:block" v-if="form.cognitivo_relazionale.includes('visivi') && form.cognitivo_relazionale_difficolta_visive_portatore.includes('protesi')" label="destra">destra</el-checkbox>
                                    <el-checkbox v-model="form.cognitivo_relazionale_difficolta_visive_portatore_sx_dx" style="margin-left: 40px; display:block" v-if="form.cognitivo_relazionale.includes('visivi') && form.cognitivo_relazionale_difficolta_visive_portatore.includes('protesi')" label="sinistra">sinistra</el-checkbox>
                                    <el-checkbox v-model="form.cognitivo_relazionale" style="display:block" label="linguaggio">Presenta deficit del linguaggio</el-checkbox>
                                    <el-form-item v-if="form.cognitivo_relazionale.includes('linguaggio')" label="indicare di che tipo">
                                        <el-input type="textarea" :rows="4" v-model="form.cognitivo_relazionale_linguaggio"></el-input>
                                    </el-form-item>
                                    <el-checkbox v-model="form.cognitivo_relazionale" label="memoria">Presenta deficit di memoria</el-checkbox><br>
                                    <el-checkbox v-model="form.cognitivo_relazionale" label="comportamento">Presenta disturbi del comportamento</el-checkbox><br>
                                    <el-form-item v-if="form.cognitivo_relazionale.includes('comportamento')" label="indicare di che tipo">
                                        <el-input type="textarea" :rows="4" v-model="form.cognitivo_relazionale_comportamento"></el-input>
                                    </el-form-item>
                                    <el-checkbox v-model="form.cognitivo_relazionale" label="passatempi">Passatempo preferiti</el-checkbox><br>
                                    <el-form-item v-if="form.cognitivo_relazionale.includes('passatempi')">
                                        <el-input type="textarea" :rows="4" v-model="form.cognitivo_relazionale_passatempi"></el-input>
                                    </el-form-item>
                                    <el-checkbox v-model="form.cognitivo_relazionale" label="note">Altro/Note</el-checkbox>
                                    <el-form-item v-if="form.cognitivo_relazionale.includes('note')">
                                        <el-input type="textarea" :rows="4" v-model="form.cognitivo_relazionale_note"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="Sonno">
                            <el-row class="no-margin-row">
                                <el-col :span="24">
                                    <el-checkbox v-model="form.sonno" style="display:block" label="regolare">Regolare e spontaneo</el-checkbox>
                                    <el-checkbox v-model="form.sonno" style="display:block" label="farmaci_saltuari"> Uso saltuario di farmaci (si vedano le indicazioni nella lettera di dimissione del medico)</el-checkbox>
                                    <el-checkbox v-model="form.sonno" style="display:block" label="farmaci_abituali"> Dorme solo con uso di farmaci (si vedano le indicazioni nella lettera di dimissione del medico)</el-checkbox>
                                    <el-checkbox v-model="form.sonno" style="display:block" label="rischio_caduta">Rischio di caduta dal letto</el-checkbox>
                                    <el-checkbox v-model="form.sonno" label="note">Altro/Note</el-checkbox>
                                    <el-form-item v-if="form.sonno.includes('note')">
                                        <el-input type="textarea" :rows="4" v-model="form.sonno_note"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="Respirazione">
                            <el-row class="no-margin-row">
                                <el-col :span="24">
                                    <el-checkbox v-model="form.respirazione" style="display:block" label="regolare">Regolare</el-checkbox>
                                    <el-checkbox v-model="form.respirazione" style="display:block" label="tosse">Presenza di tosse/secrezioni</el-checkbox>
                                    <el-checkbox v-model="form.respirazione" style="display:block" label="tracheostomia">Presenza di tracheostomia</el-checkbox>
                                    <el-form-item v-if="form.respirazione.includes('tracheostomia')" label="Tipo canula">
                                        <el-input style="display:block" type="textarea" :rows="4" v-model="form.respirazione_tipo_tracheostomia"></el-input>
                                    </el-form-item>
                                    <el-checkbox v-model="form.respirazione" label="difficolta_respiratorie">Presenza di difficoltà respiratorie</el-checkbox>
                                    <el-checkbox v-if="form.respirazione.includes('difficolta_respiratorie')" v-model="form.respirazione_difficolta_respiratorie" style="display:block;margin-left:20px" label="sforzo">Dopo sforzo</el-checkbox>
                                    <el-checkbox v-if="form.respirazione.includes('difficolta_respiratorie')" v-model="form.respirazione_difficolta_respiratorie" style="display:block;margin-left:20px" label="riposo">A riposo</el-checkbox>
                                    <el-checkbox v-model="form.respirazione" style="display:block" label="ossigeno_terapia">Ossigeno terapia (vedi lettera di dimissione)</el-checkbox>
                                    <el-checkbox v-model="form.respirazione" style="display:block" label="respiratore_automatico">Respiratore automatico</el-checkbox>
                                    <el-checkbox v-model="form.respirazione" style="display:block" label="aspiratore">Necessita aspiratore</el-checkbox>
                                    <el-checkbox style="display: block;" v-model="form.respirazione" label="note">Altro/Note</el-checkbox>
                                    <el-form-item v-if="form.respirazione.includes('note')">
                                        <el-input type="textarea" :rows="4" v-model="form.respirazione_note"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="Alimentazione">
                            <el-row class="no-margin-row">
                                <el-col :span="24">
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="autonomo">Si alimenta autonomamente</el-checkbox>
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="aiuto">Necessita di aiuto nel tagliare il cibo</el-checkbox>
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="imboccato">Dev’essre imboccato</el-checkbox>
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="protesi">Portatore di protesi</el-checkbox>
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="difficolta_masticazione">Presenta difficoltà alla masticazione</el-checkbox>
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="difficolta_deglutizione">Presenta difficoltà alla deglutizione</el-checkbox>
                                    <el-checkbox style="display:block;margin-left:20px" v-model="form.difficolta_deglutizione" v-if="form.alimentazione.includes('difficolta_deglutizione')" label="solidi">Dei solidi</el-checkbox>
                                    <el-checkbox style="display:block;margin-left:20px" v-model="form.difficolta_deglutizione" v-if="form.alimentazione.includes('difficolta_deglutizione')" label="liquidi">Dei liquidi</el-checkbox>
                                    Idratazione
                                    <el-radio style="display:block;margin-left:20px" v-model="form.idratazione" label="liquidi_sufficienti">Introduce (beve) liquidi in quantità sufficienti (1,5 litri al giorno)</el-radio>
                                    <el-radio style="display:block;margin-left:20px" v-model="form.idratazione" label="liquidi_insufficienti">Introduce (beve) pochi liquidi</el-radio>
                                    <el-form-item v-if="form.idratazione.includes('liquidi_insufficienti')" label="Si consiglia">
                                        <el-input style="display:block" type="textarea" :rows="4" v-model="form.liquidi_consigli"></el-input>
                                    </el-form-item>
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="tpn">TPN (si vedano le indicazioni specifiche nella lettera di dimissione medica)</el-checkbox>
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="peg">PEG (si vedano le indicazioni specifiche nella lettera di dimissione medica)</el-checkbox>
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="libera">Dieta libera</el-checkbox>
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="semisolida">Dieta semisolida</el-checkbox>
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="frullata">Dieta frullata</el-checkbox>
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="particolare">Necessita di una dieta particolare, consigli dietetici</el-checkbox>
                                    <el-form-item v-if="form.alimentazione.includes('particolare')" label="specificare">
                                        <el-input type="textarea" :rows="4" v-model="form.alimentazione_particolare"></el-input>
                                    </el-form-item>
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="specialistica">Ha una prescrizione specialistica</el-checkbox>
                                    <el-checkbox style="display:block" v-model="form.alimentazione" label="integratori">Utilizza integratori alimentari</el-checkbox>
                                    <el-form-item v-if="form.alimentazione.includes('integratori')">
                                        Quali<el-input type="textarea" :rows="4" v-model="form.integratori"></el-input>
                                    </el-form-item>
                                    <el-checkbox v-model="form.alimentazione" style="display:block" label="evitare">Cibi da evitare</el-checkbox>
                                    <el-form-item v-if="form.alimentazione.includes('evitare')">
                                        <el-input type="textarea" :rows="4" v-model="form.cibi_evitare"></el-input>
                                    </el-form-item>
                                    <el-checkbox v-model="form.alimentazione" style="display:block" label="note">Altro/Note</el-checkbox>
                                    <el-form-item v-if="form.alimentazione.includes('note')">
                                        <el-input type="textarea" :rows="4" v-model="form.alimentazione_note"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="Terapie">
                            <el-row class="no-margin-row">
                                <el-col :span="24">
                                    <el-checkbox style="display:block" v-model="form.terapie" label="autonomo">Assume autonomamente la/i farmaci prescritti</el-checkbox>
                                    <el-checkbox style="display:block" v-model="form.terapie" label="aiuto">Deve essere aiutato ad assumere i farmaci</el-checkbox>
                                    <el-form-item v-if="form.terapie.includes('aiuto')" label="consigli">
                                        <el-input type="textarea" :rows="4" v-model="form.terapie_consigli"></el-input>
                                    </el-form-item>
                                    <el-checkbox style="display:block" v-model="form.terapie" label="terapia_dolore">Terapia del dolore (si vedano le indicazione nella lettera di dimissione del medico)</el-checkbox>
                                    <el-form-item style="display:block;" v-if="form.terapie.includes('terapia_dolore')" label="Tipo di pompa infusionale">
                                        <el-input type="textarea" :rows="4" v-model="form.terapia_infusionale"></el-input>
                                    </el-form-item>
                                    <el-form-item style="display:block;" v-if="form.terapie.includes('terapia_dolore')" label="Ultimo riempimento eseguito il">
                                        <el-date-picker v-model="form.terapie_data_bagno" type="date" placeholder="Scegli data" style="width: 150px !important;" :editable="false" :picker-options="{
            firstDayOfWeek: 1,
            disabledDate(time) {
                return time.getTime() > Date.now();
            }
        }" format="dd/MM/yyyy" value-format="yyyy-MM-dd">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-checkbox style="display:block" v-model="form.terapie" label="note">Altro/Note</el-checkbox>
                                    <el-form-item v-if="form.terapie.includes('note')">
                                        <el-input type="textarea" :rows="4" v-model="form.terapie_note"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="Continuità assistenziale">
                            <el-row class="no-margin-row">
                                <el-col :span="24">
                                    <el-form-item style="display:block" label="Sono stati attivati i seguenti service">
                                        <el-input type="textarea" :rows="4" v-model="form.continuita.service"></el-input>
                                    </el-form-item>

                                    <el-form-item style="display:block" label="Sono in appuntamento le seguenti visite/indagini diagnostiche">
                                        <el-input type="textarea" :rows="4" v-model="form.continuita.visite"></el-input>
                                    </el-form-item>

                                    <el-form-item style="display:block" label="Sono in dotazione i seguenti ausili asl">
                                        <el-input type="textarea" :rows="4" v-model="form.continuita.ausili"></el-input>
                                    </el-form-item>
                                    <el-form-item style="display:block" label="Il Medico curante è informato attraverso: ">
                                        <el-checkbox v-model="form.continuita.medico" label="lettera">lettera</el-checkbox>
                                        <el-checkbox v-model="form.continuita.medico" label="telefono">contatti telefonici</el-checkbox>

                                    </el-form-item>
                                    <el-form-item style="display:block" label="Altre informazioni importanti">
                                        <el-input type="textarea" :rows="4" v-model="form.continuita.informazioni"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-tab-pane>

                    </el-tabs>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Patient from "@/js/api/services/Patient";
import Dismission from "@/js/api/services/Dismission";

export default {
    name: "Dimissions",
    props: ["profile_id"],
    data() {
        return {
            submitLoading: false,
            loading: false,
            mobilizzazioni: this.$store,
            exportEnabled: false,
            form: {
                destinatario: null,
                note: '',
                autonomia: [],
                autonimia_data_bagno: null,
                autonomia_note: '',
                lesioni: {
                    braden: [],
                    altro: [],
                    braden_mobilizzazioni: null,
                    altro_mobilizzazioni: null,
                },
                mobilizzazione: '',
                ausili: '',
                rischio_caduta: '',
                nome_altro: '',
                altro_valori: '',
                presidi: [],
                presidi_note: '',
                lesioni_lesioni: '',
                medicazioni: '',
                eliminazione_urinaria: [],
                eliminazione_intestinale: [],
                eliminazione_urinaria_data: '',
                eliminazione_urinaria_calibo: '',
                eliminazione_urinaria_complicanze: '',
                eliminazione_urinaria_posizinamento: 0,
                eliminazione_urinaria_consigli: '',
                eliminazione_urinaria_note: '',
                eliminazione_intestinale_data: '',
                eliminazione_intestinale_note: '',
                eliminazione_intestinale_lassativi: '',
                eliminazione_intestinale_clisteri: '',
                eliminazione_intestinale_stomia_sede: '',
                eliminazione_intestinale_stomia_sistema: '',
                eliminazione_intestinale_stomia_autonomia: '',
                cognitivo_relazionale: [],
                cognitivo_relazionale_difficolta_udito: '',
                cognitivo_relazionale_difficolta_visive: '',
                cognitivo_relazionale_difficolta_visive_portatore: [],
                cognitivo_relazionale_difficolta_visive_portatore_sx_dx: [],
                cognitivo_relazionale_passatempi: '',
                cognitivo_relazionale_linguaggio: '',
                cognitivo_relazionale_comportamento: '',
                cognitivo_relazionale_note: '',
                sonno: [],
                sonno_note: '',
                respirazione: [],
                respirazione_note: '',
                respirazione_tipo_tracheostomia: '',
                respirazione_difficolta_respiratorie: [],
                alimentazione: [],
                integratori: '',
                difficolta_deglutizione: [],
                idratazione: '',
                liquidi_consigli: '',
                alimentazione_particolare: '',
                cibi_evitare: '',
                alimentazione_note: '',
                terapie: [],
                terapie_note: '',
                terapie_consigli: '',
                terapia_infusionale: '',
                terapie_data_bagno: '',
                continuita: {
                    ausili: '',
                    medico: [],
                    visite: '',
                    service: '',
                    informazioni: '',
                }

            },
            variables: {
                destinatario: [
                    { id: "1", value: "MEDICO DI MEDICINA GENERALE" },
                    { id: "2", value: "SERVIZIO INFERMIERISTICO DOMICILIARE" },
                    { id: "3", value: "ALTRA STRUTTURA" },
                    { id: "4", value: "FAMILIARI" },
                    { id: "5", value: "ALTRO" },
                ],
                mobilizzazioni: [
                    { id: "1", value: "CAMMINA AUTONOMAMENTE" },
                    { id: "2", value: "CAMMINA E SI MUOVE CON AIUTO" },
                    { id: "3", value: "CAMMINA CON AUSILIO" },
                    { id: "4", value: "PUÒ ESSERE SEDUTO IN POLTRONA" },
                    { id: "5", value: "ALLETTATO" },
                    { id: "6", value: "A RISCHIO CADUTE" },
                    { id: "7", value: "ALTRO/NOTE" },
                ],
                rischio: [
                    { id: "1", value: "SI (Tinetti inferiore a 18)" },
                    { id: "0", value: "NO (Tinetti superiore a 18" },
                ],
                ausili: [
                    { id: "1", value: "BASTONE" },
                    { id: "2", value: "ROLLER" },
                    { id: "3", value: "STAMPELLE" },
                    { id: "4", value: "GIRELLO" },
                    { id: "5", value: "TRIPODE" },
                    { id: "6", value: "SCARPE ANTISCIVOLO" },
                ],
            },
        };
    },
    methods: {
        async fetch() {
            let params = {
                patient_id: this.profile_id,
            };
            this.exportEnabled = false;
            const response = await Dismission.fetch(params);
            let dismission = response.data.dismission;
            if (dismission != null) {
                this.form.destinatario = dismission.destinatario;
                this.form.altro_destinatario = dismission.altro_destinatario;
                this.form.mobilizzazione = dismission.mobilizzazione;
                this.form.note = dismission.note;
                this.form.ausili = dismission.ausili;
                this.form.rischio_caduta = dismission.rischio_caduta;
                this.form.autonomia = (dismission.autonomia != null) ? dismission.autonomia.split(',') : [];
                this.form.autonimia_data_bagno = dismission.autonimia_data_bagno;
                this.form.autonomia_note = dismission.autonomia_note;
                this.form.lesioni.braden = dismission.rischio_lesioni_braden;
                this.form.lesioni.braden_mobilizzazioni = dismission.mobilizzazioni_braden;
                this.form.lesioni.altro = dismission.rischio_lesioni_altro;
                this.form.nome_altro = dismission.nome_altro;
                this.form.altro_valori = dismission.altro_valori;
                this.form.lesioni.altro_mobilizzazioni = dismission.mobilizzazioni_altro;
                this.form.lesioni_lesioni = dismission.lesioni_lesioni;
                this.form.medicazioni = dismission.medicazioni;
                this.form.eliminazione_urinaria = (dismission.eliminazione_urinaria != null) ? dismission.eliminazione_urinaria.split(',') : [];
                this.form.eliminazione_urinaria_consigli = dismission.eliminazione_urinaria_consigli;
                this.form.eliminazione_urinaria_note = dismission.eliminazione_urinaria_note;
                this.form.eliminazione_urinaria_calibo = dismission.eliminazione_urinaria_calibo;
                this.form.eliminazione_urinaria_data = dismission.eliminazione_urinaria_data;
                this.form.eliminazione_urinaria_posizinamento = dismission.eliminazione_urinaria_posizinamento == 1;
                this.form.eliminazione_urinaria_complicanze = dismission.eliminazione_urinaria_complicanze;
                this.form.eliminazione_intestinale = (dismission.eliminazione_intestinale != null) ? dismission.eliminazione_intestinale.split(',') : [];
                this.form.eliminazione_intestinale_note = dismission.eliminazione_intestinale_note;
                this.form.eliminazione_intestinale_data = dismission.eliminazione_intestinale_data;
                this.form.eliminazione_intestinale_lassativi = dismission.eliminazione_intestinale_lassativi;
                this.form.eliminazione_intestinale_clisteri = dismission.eliminazione_intestinale_clisteri;
                this.form.eliminazione_intestinale_stomia_sede = dismission.eliminazione_intestinale_stomia_sede;
                this.form.eliminazione_intestinale_stomia_sistema = dismission.eliminazione_intestinale_stomia_sistema;
                this.form.eliminazione_intestinale_stomia_autonomia = dismission.eliminazione_intestinale_stomia_autonomia;
                this.form.presidi = (dismission.presidi != null) ? dismission.presidi.split(',') : [];
                this.form.presidi_note = dismission.presidi_note;
                this.form.cognitivo_relazionale = (dismission.cognitivo_relazionale != null) ? dismission.cognitivo_relazionale.split(',') : [];
                this.form.sonno = (dismission.sonno != null) ? dismission.sonno.split(',') : [];
                this.form.cognitivo_relazionale_difficolta_udito = dismission.cognitivo_relazionale_difficolta_udito;
                this.form.cognitivo_relazionale_difficolta_visive = dismission.cognitivo_relazionale_difficolta_visive;
                this.form.cognitivo_relazionale_difficolta_visive_portatore =
                    (dismission.cognitivo_relazionale_difficolta_visive_portatore != null)
                        ? dismission.cognitivo_relazionale_difficolta_visive_portatore.split(',') : [];
                this.form.cognitivo_relazionale_difficolta_visive_portatore_sx_dx =
                    (dismission.cognitivo_relazionale_difficolta_visive_portatore_sx_dx != null)
                        ? dismission.cognitivo_relazionale_difficolta_visive_portatore_sx_dx.split(',') : [];
                this.form.cognitivo_relazionale_linguaggio = dismission.cognitivo_relazionale_linguaggio;
                this.form.cognitivo_relazionale_comportamento = dismission.cognitivo_relazionale_comportamento;
                this.form.cognitivo_relazionale_passatempi = dismission.cognitivo_relazionale_passatempi;
                this.form.cognitivo_relazionale_note = dismission.cognitivo_relazionale_note;
                this.form.respirazione = (dismission.respirazione != null) ? dismission.respirazione.split(',') : [];
                this.form.respirazione_note = dismission.respirazione_note;
                this.form.respirazione_tipo_tracheostomia = dismission.respirazione_tipo_tracheostomia;
                this.form.respirazione_difficolta_respiratorie = (dismission.respirazione != null) ? dismission.respirazione_difficolta_respiratorie.split(',') : [];
                this.form.sonno_note = dismission.sonno_note;
                this.form.alimentazione = (dismission.alimentazione != null) ? dismission.alimentazione.split(',') : [];
                this.form.difficolta_deglutizione =
                    (dismission.difficolta_deglutizione != null) ?
                        dismission.difficolta_deglutizione.split(',') : [];
                this.form.idratazione = (dismission.idratazione != null) ? dismission.idratazione : '';
                this.form.integratori = dismission.integratori;
                this.form.liquidi_consigli = dismission.liquidi_consigli;
                this.form.alimentazione_particolare = dismission.alimentazione_particolare;
                this.form.cibi_evitare = dismission.cibi_evitare;
                this.form.terapie = (dismission.terapie != null) ? dismission.terapie.split(',') : [];
                this.form.terapie_consigli = dismission.terapie_consigli;
                this.form.terapia_infusionale = dismission.terapia_infusionale;
                this.form.terapie_note = dismission.terapie_note;
                this.form.alimentazione_note = dismission.alimentazione_note;
                this.form.terapie_data_bagno = dismission.terapie_data_bagno;
                this.form.continuita.ausili = dismission.continuita_ausili;
                this.form.continuita.visite = dismission.continuita_visite;
                this.form.continuita.service = dismission.continuita_service;
                this.form.continuita.informazioni = dismission.continuita_informazioni;
                this.form.continuita.medico = (dismission.continuita_medico != null) ? dismission.continuita_medico.split(',') : [];
                this.exportEnabled = true;
            }
        },
        submit() {
            this.$confirm("Salvare le modifiche?", "Attenzione", {
                confirmButtonText: "Sì",
                cancelButtonText: "Annulla",
                type: "success",
            })
                .then(() => {
                    this.submitLoading = true;
                    this.store();
                    this.fetch();
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "Operazione annullata",
                    });
                });
        },
        pdfExport() {
            this.$confirm("Generare la scheda?", "Attenzione", {
                confirmButtonText: "Sì",
                cancelButtonText: "Annulla",
                type: "success",
            })
                .then(() => {
                    this.submitLoading = true;
                    this.export();
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "Operazione annullata",
                    });
                });
        },
        async store() {
            try {
                await Patient.saveDimissions(this.profile_id, this.form);
                this.success();
            } catch (error) {
                this.submitLoading = false;
            }
        },
        async export() {
            try {
                let response = await Patient.exportDimissions(this.profile_id);
                let title = "Export_scheda_dimissione.pdf";
                this.file(response, title);
            } catch (error) {
                this.submitLoading = false;
            }
        },
        success() {
            this.submitLoading = false;
            this.fetch();
        },
        file(response, title) {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", title);
            document.body.appendChild(fileLink);
            fileLink.click();

            this.submitLoading = false;
        },
    },
    mounted() {
        this.fetch();
        const triggerEl = this.$el.querySelector(".trigger-intercept") ?? null;
        console.log('triggerEl: ', triggerEl);
        const tabsEl = this.$el.querySelector(".el-tabs.is-sticky") ?? null;
        console.log('tabsEl: ', tabsEl);
        const tabContent = tabsEl.querySelector(".el-tabs__content") ?? null;
        console.log('tabContenttabContent: ', tabContent);
        const options = {
            root: null,
            threshold: 1,
            rootMargin: "50px 0px 0px 0px",
        };

        const observer = new IntersectionObserver((entries) => {
            for (const entry of entries) {
                // change value of --tabContentWidth with the width of .el-tabs__content
                $("body")
                    .get(0)
                    .style.setProperty(
                        "--tabContentWidth",
                        tabContent.clientWidth + "px"
                    );
                if (entry.isIntersecting) {
                    tabsEl.classList.remove("active");
                } else {
                    tabsEl.classList.add("active");
                }
            }
        }, options);

        observer.observe(triggerEl);
    },
};
</script>
<style lang="scss" scoped>
.el-form-item__label {
    text-align: left !important;
    float: none;
}
</style>
