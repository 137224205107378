import { render, staticRenderFns } from "./Norton.vue?vue&type=template&id=b926b02c&scoped=true&"
import script from "./Norton.vue?vue&type=script&lang=js&"
export * from "./Norton.vue?vue&type=script&lang=js&"
import style0 from "./Norton.vue?vue&type=style&index=0&id=b926b02c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b926b02c",
  null
  
)

export default component.exports