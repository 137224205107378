var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-form',{ref:"form",attrs:{"label-position":"top","rules":_vm.rules,"model":_vm.form}},[_c('el-row',[_c('el-col',{staticStyle:{"text-align":"left"},attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"Ha mangiato?"}},[_c('el-switch',{staticStyle:{"display":"block"},attrs:{"active-color":"#ff4949","inactive-color":"#13ce66","active-text":"No","inactive-text":"Si","active-value":1,"inactive-value":0,"disabled":_vm.mealVerificated()},model:{value:(_vm.form.pasto_nulla),callback:function ($$v) {_vm.$set(_vm.form, "pasto_nulla", $$v)},expression:"form.pasto_nulla"}})],1)],1),_vm._v(" "),(!_vm.mealVerificated())?_c('el-col',{staticStyle:{"text-align":"right"},attrs:{"span":12}},[_c('el-button',{attrs:{"type":"primary","round":""},on:{"click":_vm.resetForm}},[_vm._v("Reset")]),_vm._v(" "),_c('el-button',{attrs:{"type":"success","round":""},on:{"click":_vm.saveForm}},[_vm._v("Salva")])],1):_vm._e(),_vm._v(" "),_c('el-col',{staticStyle:{"margin-top":"20px"},attrs:{"span":24}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.fields[_vm.mealTypeDesc]}},[_c('el-table-column',{attrs:{"prop":"portata","label":"PORTATA"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v("\n              "+_vm._s(scope.row.label)+"\n            ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Piatto Servito"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.mealTypeDesc == 'pranzo' || _vm.mealTypeDesc == 'cena')?_c('el-input',{attrs:{"disabled":Boolean(_vm.form.pasto_nulla) || _vm.mealVerificated(),"clearable":""},model:{value:(_vm.form[scope.row.key + '_piatto']),callback:function ($$v) {_vm.$set(_vm.form, scope.row.key + '_piatto', $$v)},expression:"form[scope.row.key + '_piatto']"}}):_c('span',[(
                    ['altro_1', 'altro_2', 'altro_3'].includes(scope.row.key)
                  )?_c('el-input',{attrs:{"disabled":Boolean(_vm.form.pasto_nulla) || _vm.mealVerificated(),"clearable":""},model:{value:(_vm.form[scope.row.key]),callback:function ($$v) {_vm.$set(_vm.form, scope.row.key, $$v)},expression:"form[scope.row.key]"}}):_c('el-checkbox',{model:{value:(_vm.form[scope.row.key + '_servito']),callback:function ($$v) {_vm.$set(_vm.form, scope.row.key + '_servito', $$v)},expression:"form[scope.row.key + '_servito']"}})],1)]}}])}),_vm._v(" "),(_vm.mealTypeDesc == 'pranzo' || _vm.mealTypeDesc == 'cena')?_c('el-table-column',{attrs:{"label":"Qualità"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"disabled":Boolean(_vm.form.pasto_nulla) ||
                  _vm.mealVerificated() ||
                  _vm.mealSelected(scope.row.key),"clearable":""},model:{value:(_vm.form[scope.row.key + '_quality']),callback:function ($$v) {_vm.$set(_vm.form, scope.row.key + '_quality', $$v)},expression:"form[scope.row.key + '_quality']"}})]}}],null,false,1283982639)}):_vm._e(),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Quantità"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.qta_max)?_c('el-slider',{attrs:{"step":1,"show-stops":"","max":scope.row.qta_max,"disabled":Boolean(_vm.form.pasto_nulla) ||
                  _vm.mealVerificated() ||
                  _vm.mealSelected(scope.row.key),"format-tooltip":function (value) {
                    return value + '/' + scope.row.qta_max;
                  }},model:{value:(_vm.form[scope.row.key + '_qta']),callback:function ($$v) {_vm.$set(_vm.form, scope.row.key + '_qta', $$v)},expression:"form[scope.row.key + '_qta']"}}):_c('el-input-number',{attrs:{"min":0,"disabled":Boolean(_vm.form.pasto_nulla) ||
                  _vm.mealVerificated() ||
                  _vm.mealSelected(scope.row.key)},model:{value:(_vm.form[scope.row.key + '_qta']),callback:function ($$v) {_vm.$set(_vm.form, scope.row.key + '_qta', $$v)},expression:"form[scope.row.key + '_qta']"}})]}}])})],1)],1),_vm._v(" "),_c('el-col',{staticStyle:{"text-align":"left"},attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"Note","prop":"note"}},[_c('el-input',{attrs:{"type":"textarea","disabled":_vm.mealVerificated(),"autosize":""},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }