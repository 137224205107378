<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-row
      type="flex"
      justify="space-between"
      align="center"
      class="head-actions"
    >
      <el-button round @click="backToPais()" icon="el-icon-back"
        >Indietro</el-button
      >
      <el-button
        round
        type="warning"
        style="align-self: end"
        v-if="paiDeleted == false && paiOperation == 'show'"
        :disabled="pai.enabled == 0"
        @click="destroyPai()"
        >Annulla PI/PAI</el-button
      >
      <el-button
        round
        icon="el-icon-download"
        style="align-self: end"
        v-if="paiOperation == 'show'"
        :disabled="pai.enabled == 0"
        @click="pai.confirmed == 0 ? exportPdf() : downloadPdf()"
        >Esporta</el-button
      >
    </el-row>

    <el-row :gutter="20" style="padding: 10px" v-if="paiOperation == 'show'">
      <el-col :span="12" style="line-height: 40px"
        >Creato da {{ pai.full_creator }} il {{ pai.created_at_time }}</el-col
      >
      <el-col
        :span="12"
        v-if="pai.enabled == 0"
        style="line-height: 40px; text-align: right; color: #f56c6c"
        class="float-right"
        >Annullato da {{ pai.full_remover }} il
        {{ pai.removed_at_time }}</el-col
      >
    </el-row>

    <el-form
      ref="paiForm"
      :model="paiForm"
      label-position="left"
      label-width="150px"
      :disabled="disabledForm"
      :rules="rules"
    >
      <el-card class="box-card">
        <el-row :gutter="20">
          <el-row type="flex" justify="space-between" align="center">
            <el-col :span="12">
              <center>
                <h3>
                  DATA INGRESSO:
                  <span class="pai-dates">{{
                    patient.clean_admission_date
                  }}</span>
                </h3>
              </center>
            </el-col>
            <el-col :span="12">
              <center>
                <h3>
                  ULTIMA REVISIONE:
                  <span class="pai-dates">{{
                    paiOperation == "new" && this.persistentDraft
                      ? lastPaiDatePersistentDraft
                      : lastPaiDate
                  }}</span>
                </h3>
              </center>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row type="flex" justify="center" align="center">
            <el-col :span="24">
              <center>
                <h3>TIPOLOGIA DI PI/PAI:</h3>
              </center>
              <el-form-item
                prop="pai_type"
                :required="true"
                class="custom-form-item"
              >
                <center>
                  <el-radio-group v-model="paiForm.pai_type">
                    <el-radio :label="1">Entro 30 gg dall'ingresso</el-radio>
                    <el-radio :label="2">Semestrale</el-radio>
                    <el-radio :label="3">Rivalutazione bisogni</el-radio>
                  </el-radio-group>
                </center>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider></el-divider>

          <!-- SCALE DI VALUTAZIONE -->
          <pai-test
            ref="paiTest"
            :patient="patient"
            :pai="pai"
            :paiOperation="paiOperation"
            :paiTests.sync="paiForm.paiTests"
            :persistentDraft="persistentDraft"
          />
          <el-divider />

          <!-- scala nutrizionale -->
          <el-row :gutter="20">
            <el-col :span="24">
              <h3>Scheda di Valutazione Nutrizionale</h3>
              <el-row type="flex" align="start" justify="center">
                <el-col :span="1">
                  <el-checkbox
                    v-model="paiForm.nutritional_card"
                    :true-label="1"
                    :false-label="0"
                  ></el-checkbox>
                </el-col>
                <el-col :span="23">
                  {{
                    registry
                      ? "Anamnesi del " + registry.clean_date_time
                      : "Nessuna anamnesi associata"
                  }}
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="23" :offset="1">
                  <el-collapse id="registry-summary">
                    <el-collapse-item
                      title="Visualizza dettagli"
                      name="1"
                      :disabled="!registry"
                    >
                      <nutritional-evaluation-card
                        :registry="registry"
                        :lastSixMonthWeightDiminution="
                          lastSixMonthWeightDiminution
                        "
                        :mustTest="mustTest"
                        v-if="registry"
                      ></nutritional-evaluation-card>
                    </el-collapse-item>
                  </el-collapse>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <!-- <el-divider /> -->

          <!-- esito e osservazioni PAI precedente -->
          <el-row v-if="lastPaiAree != null">
            <el-divider />
            <el-col :span="24">
              <h3>Esito e osservazioni su obiettivi precedente PI/PAI:</h3>
            </el-col>
            <el-col :span="22" :offset="1">
              <el-row type="flex" justify="space-between" :gutter="20">
                <el-col :span="5">
                  <b>Area</b>
                </el-col>
                <el-col :span="12">
                  <b>Precedente Obiettivo</b>
                </el-col>
                <el-col :span="7">
                  <b>Esito Obiettivo</b>
                </el-col>
              </el-row>
              <el-divider />
              <el-row
                :gutter="20"
                type="flex"
                v-for="(val, idx) in lastPaiAree"
                :key="idx"
                class="sig-row"
                align="middle"
                justify="center"
              >
                <el-col :span="5">{{ aree[idx].name }}</el-col>
                <el-col :span="12">{{ val.result }}</el-col>
                <el-col :span="7">
                  <el-radio-group v-model="paiForm.selectedEsitoObAree[idx]">
                    <el-radio :label="1">Raggiunto</el-radio>
                    <el-radio :label="2">Parzialmente Raggiunto</el-radio>
                    <el-radio :label="3">Non Raggiunto</el-radio>
                  </el-radio-group>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24">
              Note
              <el-input
                type="textarea"
                :rows="3"
                v-model="paiForm.note_ob_aree"
              ></el-input>
            </el-col>
          </el-row>
          <br />
          <el-divider v-if="lastPaiAree != null" />

          <!-- Strumenti di tutela e protezione dell'ospite + ausili -->
          <el-row :gutter="20">
            <el-col :span="12">
              <h3>Strumenti di tutela e protezione dell'ospite</h3>
              <el-checkbox-group v-model="paiForm.selectedInstruments">
                <el-checkbox
                  style="display: block"
                  v-for="(inst, idx) in instruments"
                  :key="`inst-${idx}`"
                  :label="inst"
                />
              </el-checkbox-group>
            </el-col>
            <el-col :span="12">
              <h3>Ausili</h3>
              <el-checkbox-group v-model="paiForm.selectedAux">
                <el-checkbox
                  style="display: block"
                  v-for="(aux, idx) in auxiliaries"
                  :key="`aux-${idx}`"
                  :label="aux"
                />
              </el-checkbox-group>
            </el-col>
          </el-row>
          <el-divider />

          <!-- AREE -->
          <pai-aree
            v-if="!loading"
            ref="paiAree"
            :pai="pai"
            :paiOperation="paiOperation"
            :paiAree.sync="paiForm.paiAree"
            :latestPai="latestPai"
            :persistentDraft="persistentDraft"
          />

          <!-- COMMON -->
          <el-row :gutter="20">
            <el-divider />
            <!-- DATA RIVALUTAZIONE -->
            <el-col :span="24">
              <el-form-item label="Da rivalutare il">
                <el-date-picker
                  v-model="paiForm.revaluation_at"
                  type="date"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                  size="small"
                />
              </el-form-item>
            </el-col>
            <!-- FIRME OPERATORI -->
            <el-col :span="24">
              <h3>Firme Operatori</h3>
            </el-col>
            <!-- <el-row :gutter="20"> -->
            <el-col :span="8">
              <el-select
                v-model="paiForm.operators['profession']"
                placeholder="Figura Coinvolta"
                clearable
                @change="chooseProfession($event)"
              >
                <el-option
                  v-for="(profession, id_profession) in professions"
                  :key="id_profession"
                  :label="profession.label"
                  :value="id_profession"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="12">
              <el-select
                v-model="paiForm.operators['user_id']"
                placeholder="Seleziona Operatore"
                clearable
                @change="chooseOperatorToSign($event)"
                filterable
              >
                <el-option
                  v-for="operator in operatorsByProfession"
                  :key="operator.id"
                  :label="operator.fullname"
                  :value="operator.id"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="4">
              <el-button
                size="small"
                @click="addOperator()"
                :disabled="isDisabled()"
                type="success"
                >Aggiungi</el-button
              >
            </el-col>
            <!-- </el-row> -->
            <el-col :span="22" :offset="1">
              <br />
              <el-row type="flex" justify="space-between" :gutter="20">
                <el-col :span="6">
                  <b>Figura</b>
                </el-col>
                <el-col :span="16">
                  <b>Operatore</b>
                </el-col>
                <el-col :span="2"></el-col>
              </el-row>
              <el-divider />
              <el-row
                :gutter="20"
                type="flex"
                v-for="item in operatorSelectedList"
                :key="item.idModel"
                class="sig-row"
                align="middle"
                justify="center"
              >
                <el-col :span="6">{{ item.profession }}</el-col>
                <el-col :span="14">{{ item.user.fullname }}</el-col>
                <el-col :span="4">
                  <el-button
                    type="danger"
                    size="small"
                    plain
                    @click="deleteOperator(item)"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-row>
      </el-card>
    </el-form>
    <el-row
      v-if="paiOperation != 'show'"
      type="flex"
      justify="space-between"
      align="center"
      class="actions"
    >
      <el-col :span="8">
        <center>
          <el-button @click="reset" type="danger">Reset</el-button>
        </center>
      </el-col>
      <el-col :span="8">
        <center>
          <el-button @click="saveDraft" type="info">Salva in bozza</el-button>
        </center>
      </el-col>
      <el-col :span="8">
        <center>
          <el-button @click="submit" type="success" :loading="loading"
            >Salva</el-button
          >
        </center>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import Pai from "@/js/api/services/Pai";
import PaiTest from "@/js/pages/Patients/components/Pai/PaiTest";
import PaiAree from "@/js/pages/Patients/components/Pai/PaiAree";
import NutritionalEvaluationCard from "@/js/pages/Patients/components/NutritionalEvaluationCard";
import Operator from "@/js/api/services/User";
import moment from "moment";
import Patient from "@/js/api/services/Patient";

export default {
  name: "PaiForm",
  props: ["pai", "paiOperation", "patient", "persistentDraft"],
  components: {
    PaiTest,
    PaiAree,
    NutritionalEvaluationCard,
  },
  data() {
    return {
      loading: true,
      disabledForm: false,
      paiForm: {
        pai_type: null,
        department: this.$store.state.departmentId,
        paiTests: [],
        paiAree: [],
        selectedInstruments: [],
        selectedAux: [],
        nutritional_card: 0,
        revaluation_at: moment().add(6, "months").format("YYYY-MM-DD"),
        firma: {},
        operators: [],
        selectedEsitoObAree: [],
        note_ob_aree: null,
      },
      instruments: [
        "Spondine al letto",
        "Segufix",
        "Cintura addominale in carrozzina",
        "Carrozzina con tavolino",
        "Cintura pelvica",
        "Divaricatore (cuneo)",
        "Fascia pettorale",
        "Polsiere",
        "Altro",
      ],
      auxiliaries: [
        "Carrozzina/Bascula",
        "Walker (deambulatore)",
        "Bastone/ Stampella",
        "Materasso antidecubito",
        "Cuscino antidecubito",
        "Altro",
      ],
      rules: {
        pai_type: [
          {
            required: true,
            message: "Campo Obbligatorio",
            trigger: "change",
          },
        ],
      },
      operatorInStructure: null,
      operatorsByProfession: null,
      operatorSelectedList: [],
      paiDeleted: false,
      lastPaiAree: null,
      radio: null,
      latestPai: null,
      registry: {},
      mustTest: {},
      lastSixMonthWeightDiminution: null,
      consumo_alcolici: [],
    };
  },
  computed: {
    professions() {
      return Object.fromEntries(
        Object.entries(this.$store.state.professions).filter(
          ([id, profession]) => profession.enabled === 1 && id != 1
        )
      );
    },
    aree() {
      return this.$store.state.config.pai_aree;
    },
    lastPaiDate() {
      let clean_date_time = _.get(this.pai, "clean_date_time");
      if (clean_date_time) {
        // substring to get format 'd/m/y'
        clean_date_time = clean_date_time.substring(0, 10);
        // if we have already one pai
        return moment(clean_date_time, "DD/MM/YYYY").format("DD/MM/YYYY");
      } else {
        // if this.pai.clean_date_time if NOT filled -> we don't have pai saved
        if (this.persistentDraft.updated_at) {
          // check if this.persistentDraft.updated_at (this is draft) if filled
          // -> that means we have already a draft
          return moment(this.persistentDraft.updated_at).format("DD/MM/YYYY");
        } else {
          // we don't have any
          return "--";
        }
      }
    },
    lastPaiDatePersistentDraft() {
      return moment(this.persistentDraft.updated_at).format("DD/MM/YYYY");
    },
    lastPai() {
      return _.get(this.pai);
    },
    config() {
      return this.$store.state.config.anagrafica;
    },
  },
  methods: {
    async get() {
      this.loading = true;
      this.disabledForm = this.paiOperation == "show" || false;
      if (this.paiOperation == "show") {
        const selectedAux = _.split(this.pai.selectedAux, ",");
        const selectedInstruments = _.split(this.pai.selectedInstruments, ",");
        const selectedEsitoObAree = _.split(
          this.pai.selectedEsitoObAree,
          ","
        ).map((x) => parseInt(x));
        selectedEsitoObAree.unshift(".");

        let paiUsers = _.get(this.pai, "users", []);
        let formatted = paiUsers.map((u) => {
          const profession = _.get(u, ["profession", "label"], "ND");
          const idModel = _.get(u, "id");
          const user = {
            fullname: _.join([u.name, u.surname], " "),
          };
          return {
            profession,
            idModel,
            user,
          };
        });
        this.operatorSelectedList = formatted;
        this.paiForm = {
          ...this.paiForm,
          selectedAux,
          selectedInstruments,
          pai_type: this.pai.pai_type,
          revaluation_at: this.pai.revaluation_at,
          selectedEsitoObAree,
          note_ob_aree: this.pai.note_ob_aree,
          //   nutritional_card: this.pai.nutritional_card == 1 ? true : false,
          nutritional_card: this.pai.nutritional_card,
        };
      }

      this.paiOperation == "new" && (await this.applyPreviousAndDraft());

      // recupero gli utenti della struttura
      let structureId = this.$store.state.structureId;
      const response = await Operator.inStructure(structureId);
      this.operatorInStructure = _.get(response, ["data", "operators"]);

      // esiti obiettivi del precedente PAI
      let prevPaiId = _.get(this.pai, "prevPaiId");

      if (this.paiOperation == "show" && !_.isNil(prevPaiId)) {
        const res = await Pai.aree(prevPaiId);
        this.lastPaiAree = _.get(res, "data", null);
      } else if (
        this.paiOperation == "new" &&
        !_.isNil(_.get(this.pai, "id"))
      ) {
        const res = await Pai.aree(this.pai.id);
        this.lastPaiAree = _.get(res, "data", []);
      }

      // recupero ultima registry
      if (this.paiOperation == "new") {
        const registryResp = await Patient.registry(this.patient.id);
        this.registry = registryResp.data.items;
        this.mustTest = registryResp.data.must;
        this.lastSixMonthWeightDiminution =
          registryResp.data.lastSixMonthWeightDiminution;
      } else if (this.paiOperation == "show") {
        this.registry = this.pai.registry;
        this.mustTest = this.pai.must_test;
        this.lastSixMonthWeightDiminution = this.pai.weigth_lose;
      }

      if (this.registry) {
        this.registry.fumo_sig_die > 0
          ? this.consumo_alcolici.push(
              "Fumo (al giorno: " + this.registry.fumo_sig_die + ")"
            )
          : null;
        this.registry.fumo == 2 && this.registry.fumo_sig_die > 0
          ? this.consumo_alcolici.push(
              "Ex Fumo (al giorno: " + this.registry.fumo_sig_die + ")"
            )
          : null;
        this.registry.vino_die > 0
          ? this.consumo_alcolici.push(
              "Vino (al giorno: " + this.registry.vino_die + ")"
            )
          : null;
        this.registry.birra_die > 0
          ? this.consumo_alcolici.push(
              "Birra (al giorno: " + this.registry.birra_die + ")"
            )
          : null;
        this.registry.liquori_die > 0
          ? this.consumo_alcolici.push(
              "Liquori (al giorno: " + this.registry.liquori_die + ")"
            )
          : null;
      }

      this.loading = false;
    },
    async saveDraft() {
      let [errorsArea, okArea] = this.$refs.paiAree.validate();
      if (!okArea) return this.onPaiAreeErrors(errorsArea);

      let isValid = await this.validateForm(this.$refs.paiForm);
      if (!isValid) {
        return this.$notify({
          type: "error",
          message: "Invalid Form",
        });
      }
      let selectedAux = this.formatSelectedAux();
      let selectedInstruments = this.formatSelectedInst();
      let selectedEsitoObAree = this.formatSelectedEsito();
      let paiUsers = this.operatorSelectedList.map((op) => op.user);
      let patient_id = this.patient.id;
      let paiAree = this.paiForm.paiAree.filter((area) => {
        return ![area.problems, area.results].some(_.isNil);
      });
      let prevPaiId = _.get(this.pai, "id") || null;
      const data = _.pick(this.paiForm, [
        "pai_type",
        "department",
        "paiTests",
        "revaluation_at",
        "note_ob_aree",
        "nutritional_card",
      ]);

      const payload = {
        ...data,
        paiUsers,
        paiAree,
        patient_id,
        selectedAux,
        selectedInstruments,
        selectedEsitoObAree,
        prevPaiId,
      };

      let [errors, ok] = this.$refs.paiTest.validate();
      if (!ok) {
        let confirmMessage =
          "Nessuna scala selezionata, siete sicuri di voler procedere?";
        this.$confirm(confirmMessage, "Attenzione", {
          confirmButtonText: "OK",
          cancelButtonText: "Annulla",
          type: "warning",
        })
          .then(async () => {
            if (!_.get(this.persistentDraft, "id"))
              await Pai.storeDraft(payload);
            else await Pai.updateDraft(this.persistentDraft.id, payload);

            this.backToPais();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Operazione annullata",
            });
          });
      } else {
        if (!_.get(this.persistentDraft, "id")) await Pai.storeDraft(payload);
        else await Pai.updateDraft(this.persistentDraft.id, payload);

        this.backToPais();
      }
    },
    formatUsers(users) {
      return (users || []).map((u) => {
        const profession = _.get(u, ["profession", "label"], "ND");
        const idModel = _.get(u, "id");
        const user = {
          fullname: _.join([u.name, u.surname], " "),
          id: u.id,
        };
        return {
          profession,
          idModel,
          user,
        };
      });
    },
    async applyPreviousAndDraft() {
      const draft = {
        ...this.persistentDraft,
      };
      let latestPai = await Pai.latestByPatient(this.patient.id);
      this.latestPai = latestPai;

      if (_.get(draft, "id")) {
        this.applyPersistentDraft(draft);
        return;
      }

      if (!latestPai) return;

      const selectedAux = _.split(latestPai.selectedAux, ",");
      const selectedInstruments = _.split(latestPai.selectedInstruments, ",");

      this.paiForm = {
        ...this.paiForm,
        selectedAux,
        selectedInstruments,
        nutritional_card: latestPai.nutritional_card,
      };

      let paiUsers = _.get(latestPai, "users", []);
      this.operatorSelectedList = this.formatUsers(paiUsers);
    },
    applyPersistentDraft(draft) {
      const selectedAux = _.split(draft.selectedAux, ",");
      const selectedInstruments = _.split(draft.selectedInstruments, ",");
      const selectedEsitoObAree = _.split(draft.selectedEsitoObAree, ",").map(
        (x) => parseInt(x)
      );
      selectedEsitoObAree.unshift(".");
      this.paiForm = {
        ...this.paiForm,
        pai_type: draft.pai_type,
        revaluation_at: moment(this.paiForm.revaluation_at).format(
          "YYYY-MM-DD"
        ),
        selectedAux,
        selectedInstruments,
        selectedEsitoObAree,
        note_ob_aree: draft.note_ob_aree,
        nutritional_card: draft.nutritional_card,
      };
      let paiUsers = _.get(draft, "users", []);
      this.operatorSelectedList = this.formatUsers(paiUsers);
    },
    async validateForm(form) {
      return new Promise((resolve, reject) => {
        form.validate((valid) => resolve(valid));
      });
    },
    // getLatestPai() {
    //   return this.lastPai;
    // },
    async storePai() {
      let selectedAux = this.formatSelectedAux();
      let selectedInstruments = this.formatSelectedInst();
      let selectedEsitoObAree = this.formatSelectedEsito();
      let paiUsers = this.operatorSelectedList.map((op) => op.user);
      let patient_id = this.patient.id;
      let paiAree = this.paiForm.paiAree.filter((area) => {
        return ![area.problems, area.results].some(_.isNil);
      });
      let prevPaiId = _.get(this.pai, "id") || null;

      const data = _.pick(this.paiForm, [
        "pai_type",
        "department",
        "paiTests",
        "revaluation_at",
        "note_ob_aree",
        "nutritional_card",
      ]);
      const payload = {
        ...data,
        paiUsers,
        paiAree,
        patient_id,
        selectedAux,
        selectedInstruments,
        selectedEsitoObAree,
        prevPaiId,
      };

      if (_.get(this.persistentDraft, "id")) {
        await Pai.confirmDraft(this.persistentDraft.id, payload);
      } else {
        await Pai.store(payload);
      }
      this.backToPais();
    },
    onPaiTestErrors(errors) {
      let es = _.join(errors, ", ");
      this.$notify({
        type: "error",
        message: `Invalid Tests: ${es}`,
      });
    },
    onPaiAreeErrors(errorsArea) {
      let es = _.join(errorsArea, ", ");
      this.$notify({
        type: "error",
        message: `Invalid Area: ${es}`,
      });
    },
    async submit() {
      let [errorsArea, okArea] = this.$refs.paiAree.validate();
      if (!okArea) return this.onPaiAreeErrors(errorsArea);

      let isValid = await this.validateForm(this.$refs.paiForm);
      if (isValid) {
        let [errors, ok] = this.$refs.paiTest.validate();
        if (!ok) {
          let confirmMessage =
            "Nessuna scala selezionata, siete sicuri di voler procedere?";
          this.$confirm(confirmMessage, "Attenzione", {
            confirmButtonText: "OK",
            cancelButtonText: "Annulla",
            type: "warning",
          })
            .then(() => {
              this.storePai();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "Operazione annullata",
              });
            });
        } else {
          this.storePai();
        }
      } else {
        this.$notify({
          type: "error",
          message: "Invalid Form",
        });
      }
    },
    formatSelectedAux() {
      const aux = _.join(this.paiForm.selectedAux, ",");
      return _.isEmpty(aux) ? null : aux;
    },
    formatSelectedInst() {
      const inst = _.join(this.paiForm.selectedInstruments, ",");
      return _.isEmpty(inst) ? null : inst;
    },
    formatSelectedEsito() {
      let values = [];
      _.forIn(this.aree, (value, key) => {
        if (this.paiForm.selectedEsitoObAree[key]) {
          values.push(this.paiForm.selectedEsitoObAree[key]);
        } else {
          values.push(0);
        }
      });
      return _.join(values, ",");
    },
    backToPais() {
      this.$emit("showList");
    },
    chooseProfession(val) {
      this.operatorsByProfession = this.operatorInStructure[val];
      this.paiForm.firma["profession"] = this.professions[val].label;
    },
    chooseOperatorToSign(operator) {
      this.paiForm.firma["user"] = this.operatorsByProfession[operator];
    },
    isDisabled() {
      const { profession, user } = this.paiForm.firma;
      return [profession, user].some(_.isNil);
    },
    addOperator() {
      this.operatorSelectedList.push(this.paiForm.firma);
      this.paiForm.firma = {};
      this.paiForm.operators["profession"] = undefined;
      this.paiForm.operators["user_id"] = undefined;
      this.$message(`Operatore aggiunto`);
    },
    deleteOperator(item) {
      const byUserId = (os) => os.user.id !== item.user.id;
      const filtered = this.operatorSelectedList.filter(byUserId);
      this.operatorSelectedList = filtered;
    },
    exportPdf() {
      Pai.export(this.pai.id, this.patient.id)
        .then((res) => this.file(res, "pai.pdf"))
        .then(this.onExportSuccess)
        .catch(this.onExportFailure);
    },
    onExportFailure() {
      this.$notify({
        type: "error",
        message: "Export fallito",
      });
    },
    onExportSuccess() {
      this.$notify({
        type: "success",
        message: "Export eseguito",
      });
    },
    file(response, title) {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", title);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async reset() {
      if (_.get(this.persistentDraft, "id")) {
        await Pai.deleteDraft(this.persistentDraft.id);
      }
      this.$emit("resetForm");
    },
    dataWatcher() {
      const fields = [
        "selectedAux",
        "selectedInstruments",
        "pai_type",
        "selectedEsitoObAree",
        "note_ob_aree",
        // "nutritional_card",
      ];
      if (this.paiOperation == "new") {
        let data = _.pick(this.paiForm, fields);
        data.operatorSelectedList = this.operatorSelectedList;
      }
    },
    startWatching() {
      this.$watch("$data", this.dataWatcher, {
        deep: true,
      });
    },
    destroyPai() {
      this.$confirm("Confermi annullamento PI/PAI?", "Attenzione", {
        confirmButtonText: "OK",
        cancelButtonText: "Annulla",
        type: "warning",
      })
        .then(async () => {
          await this.deletePai();
          this.backToPais();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        });
    },
    async deletePai() {
      await Pai.destroy(this.pai.id);
    },
    async downloadPdf() {
      const response = await Pai.download(this.pai.id);
      let title = `PAI_confirmed_${this.$store.state.patientName}.pdf`;
      this.file(response, title);
    },
    file(response, title) {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", title);
      document.body.appendChild(fileLink);
      fileLink.click();

      this.downloadLoading = false;
    },
  },
  mounted() {
    this.get().then(this.startWatching);
  },
};
</script>

<style lang="scss">
.actions {
  margin-top: 20px;
}

.sig-row {
  margin-top: 20px;
}

.head-actions {
  margin-bottom: 20px;
}

.custom-form-item .el-form-item__content {
  margin-left: 0 !important;
}

.pai-dates {
  color: #31557c;
}

#registry-summary {
  &.el-collapse,
  & .el-collapse-item__header,
  & .el-collapse-item__wrap {
    border: none;
  }
  & .el-collapse-item__content {
    background-color: #ecf5ff;
    padding: 10px;
  }
}
</style>
