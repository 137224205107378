<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q1_result">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="A) Capacitá di usare il telefono" required />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="24">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q1_result">
                            <el-form-item size="mini">
                              <el-radio
                                :label="3"
                              >Usa il telefono di propria iniziativa, alza il microfono e compone il numero, ecc.</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="2">Compone solo alcuni numeri ben conosciuti</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio
                                :label="1"
                              >E’ in grado di rispondere al telefono, ma non di chiamare</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="0">Non è capace di usare il telefono</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q2_result">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="B) Movimenti per acquisti nei negozi" required />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="24">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q2_result">
                            <el-form-item size="mini">
                              <el-radio
                                :label="3"
                              >Si prende autonomamente cura di tutte le necessità di acquisti nei negozi</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio
                                :label="2"
                              >E’ in grado di effettuare piccoli acquisti nei negozi</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio
                                :label="1"
                              >Necessita di essere accompagnato per qualsiasi giro di acquisti nei negozi</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="0">E’ del tutto incapace di fare acquisti nei negozi</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q3_result">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="C) Mezzi di trasporto" required />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="24">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q3_result">
                            <el-form-item size="mini">
                              <el-radio
                                :label="4"
                              >Si sposta da solo sui mezzi pubblici o guida la propria auto</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio
                                :label="3"
                              >Organizza i propri spostamenti con taxi, ma non usa i mezzi pubblici.</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="2">Usa i mezzi pubblici se accompagnato da qualcuno.</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio
                                :label="1"
                              >Può spostarsi soltanto con taxi o auto con ’assistenza di altri.</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="0">Non si sposta per niente.</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q4_result">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="D) Responsabilità nell’uso dei medicinali" required />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="24">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q4_result">
                            <el-form-item size="mini">
                              <el-radio
                                :label="2"
                              >E’ in grado di assumere i medicinali nelle giuste dosi al giusto tempo.</el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio
                                :label="1"
                              >E’ in grado di assumere i medicinali se preparati in anticipo in dosi separate.</el-radio>
                            </el-form-item size="mini">
                            <el-form-item>
                              <el-radio :label="0">Non è in grado di assumere le medicine da solo.</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q5_result">
                <el-row>
                  <el-col :span="24">
                    <el-form-item label="E) Capacità di gestire le proprie finanze" required />
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :offset="1" :span="20">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q5_result">
                            <el-form-item size="mini">
                              <el-radio :label="2" class="radio-label">
                                Gestisce le operazioni finanziarie indipendentemente
                                <p
                                  class="radio-label_text"
                                >(bilanci, scrive assegni, paga affitti, conti, va in banca), raccoglie e tiene nota delle entrate.</p>
                              </el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="1" class="radio-label">
                                Gestisce gli acquisti quotidiani, ma necessita di aiuto per le operazioni bancarie,
                                <p class="radio-label_text">gli acquisti maggiori, ecc…</p>
                              </el-radio>
                            </el-form-item>
                            <el-form-item size="mini">
                              <el-radio :label="0">E’ incapace di maneggiare il denaro</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Risultato">
              <el-input v-model="totalDescription" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Iadl",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 14
      },
      result: {},
      testRules: rules(5)
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_description: this.totalDescription
          };
          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    totalDescription() {
      return _.cond([
        [
          _.conforms({ total: n => _.inRange(n, 0, 5) }),
          _.constant("Non autonomo")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 5, 10) }),
          _.constant("Autonomo parzialmente")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 10, 15) }),
          _.constant("Autonomo")
        ]
      ])(this.form);
    },
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.radio-label {
  white-space: normal;
}
.radio-label_text {
  margin-left: 20px;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>