<template>
    <div>
        <el-form label-position="top" label-width="80px" :model="form" :rules="rules" ref="form">
            <el-row class="form-header" :gutter="5">
                <el-col :span="8">
                    <el-form-item label="Data" prop="date">
                        <el-date-picker v-model="form.date" :editable="false" type="date" placeholder="Scegli data" :picker-options="pickerOptions" format="dd/MM/yyyy" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Orario" prop="time">
                        <el-time-picker v-model="form.time" :editable="false" format="HH:mm" value-format="yyyy-MM-dd HH:mm:ss" placeholder="Seleziona Orario">
                        </el-time-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Ospite" prop="patient_id">
                        <el-select v-model="form.patient_id" placeholder="Seleziona Ospite" filterable clearable @change="getLastExam()">
                            <el-option v-for="item in patients" :key="item.id" :label="item.full_name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="block with-form-header" v-loading="loading" v-if="patientSelected && !patientLoader">
                <el-row :gutter="10">
                    <!-- exam types: {{ examTypes }} -->
                    <el-col :xs="24" :sm="12" :md="8">
                        <el-row :gutter="20" type="flex" align="middle" justify="center">
                            <el-col :span="6" class="checkbox-padding critical-checkbox">
                                <el-checkbox v-model="form.crit[examTypes[0].id]" border size="large" class="critical-checkbox">
                                    <font-awesome-icon class="warning-text" icon="exclamation-triangle" />
                                </el-checkbox>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item :label="examTypes[0].label" :prop="examTypes[0].description">
                                    <el-input-number v-model.number="form.value[examTypes[0].id]" :min="examTypes[0].min" :max="examTypes[0].max" :step="examTypes[0].step" @change="checkInput(examTypes[0].id)" type="number"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8">
                        <el-row :gutter="20" type="flex" align="middle" justify="center">
                            <el-col :span="6" class="checkbox-padding critical-checkbox">
                                <el-checkbox v-model="form.crit[examTypes[1].id]" border size="large" class="critical-checkbox">
                                    <font-awesome-icon class="warning-text" icon="exclamation-triangle" />
                                </el-checkbox>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item :label="examTypes[1].label" :prop="examTypes[1].description">
                                    <el-input-number v-model.number="form.value[examTypes[1].id]" :min="examTypes[1].min" :max="examTypes[1].max" :step="examTypes[1].step" @change="checkInput(examTypes[1].id)" type="number"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8">
                        <el-row :gutter="20" type="flex" align="middle" justify="center">
                            <el-col :span="6" class="checkbox-padding critical-checkbox">
                                <el-checkbox v-model="form.crit[examTypes[4].id]" border size="large" class="critical-checkbox">
                                    <font-awesome-icon class="warning-text" icon="exclamation-triangle" />
                                </el-checkbox>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item :label="examTypes[4].label" :prop="examTypes[4].description">
                                    <el-input-number v-model.number="form.value[examTypes[4].id]" :min="examTypes[4].min" :max="examTypes[4].max" :step="examTypes[4].step" @change="checkInput(examTypes[4].id)" type="number"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8">
                        <el-row :gutter="20" type="flex" align="middle" justify="center">
                            <el-col :span="6" class="checkbox-padding critical-checkbox">
                                <el-checkbox v-model="form.crit[examTypes[2].id]" border size="large" class="critical-checkbox">
                                    <font-awesome-icon class="warning-text" icon="exclamation-triangle" />
                                </el-checkbox>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item :label="examTypes[2].label" :prop="examTypes[2].description">
                                    <el-input-number v-model.number="form.value[examTypes[2].id]" :min="examTypes[2].min" :max="examTypes[2].max" :step="examTypes[2].step" @change="checkInput(examTypes[2].id)" type="number"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8">
                        <el-row :gutter="20" type="flex" align="middle" justify="center">
                            <el-col :span="6" class="checkbox-padding critical-checkbox">
                                <el-checkbox v-model="form.crit[examTypes[3].id]" border size="large" class="critical-checkbox">
                                    <font-awesome-icon class="warning-text" icon="exclamation-triangle" />
                                </el-checkbox>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item :label="examTypes[3].label" :prop="examTypes[3].description">
                                    <el-input-number v-model.number="form.value[examTypes[3].id]" :min="examTypes[3].min" :max="examTypes[3].max" :step="examTypes[3].step" @change="checkInput(examTypes[3].id)" type="number"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8">
                        <el-row :gutter="20" type="flex" align="middle" justify="center">
                            <el-col :span="6" class="checkbox-padding critical-checkbox">
                                <el-checkbox v-model="form.crit[examTypes[5].id]" border size="large" class="critical-checkbox">
                                    <font-awesome-icon class="warning-text" icon="exclamation-triangle" />
                                </el-checkbox>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item :label="examTypes[5].label" :prop="examTypes[5].description">
                                    <el-input-number v-model.number="form.value[examTypes[5].id]" :min="examTypes[5].min" :max="examTypes[5].max" :step="examTypes[5].step" @change="checkInput(examTypes[5].id)" type="number"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8">
                        <el-row :gutter="20" type="flex" align="middle" justify="center">
                            <el-col :span="6" class="checkbox-padding critical-checkbox">
                                <el-checkbox v-model="form.crit[examTypes[6].id]" border size="large" class="critical-checkbox">
                                    <font-awesome-icon class="warning-text" icon="exclamation-triangle" />
                                </el-checkbox>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item :label="examTypes[6].label" :prop="examTypes[6].description">
                                    <el-input-number v-model.number="form.value[examTypes[6].id]" :min="examTypes[6].min" :max="examTypes[6].max" :step="examTypes[6].step" @change="checkInput(examTypes[6].id)" type="number"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8">
                        <el-row :gutter="20" type="flex" align="middle" justify="center">
                            <el-col :span="6" class="checkbox-padding critical-checkbox">
                                <el-checkbox v-model="form.crit[examTypes[7].id]" border size="large" class="critical-checkbox">
                                    <font-awesome-icon class="warning-text" icon="exclamation-triangle" />
                                </el-checkbox>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item :label="examTypes[7].label + ' - Max 6 mesi: ' + peso" :prop="examTypes[7].description">
                                    <el-input-number v-model.number="form.value[examTypes[7].id]" :min="examTypes[7].min" :max="examTypes[7].max" :step="examTypes[7].step" @change="checkInput(examTypes[7].id)" type="number"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8">
                        <el-row :gutter="20" type="flex" align="middle" justify="center">
                            <el-col :span="6" class="checkbox-padding critical-checkbox">
                                <el-checkbox v-model="form.crit[examTypes[8].id]" border size="large" class="critical-checkbox">
                                    <font-awesome-icon class="warning-text" icon="exclamation-triangle" />
                                </el-checkbox>
                            </el-col>
                            <el-col :span="18">
                                <el-form-item :label="examTypes[8].label" :prop="examTypes[8].description">
                                    <el-input-number v-model.number="form.value[examTypes[8].id]" :min="examTypes[8].min" :max="examTypes[8].max" :step="examTypes[8].step" @change="checkInput(examTypes[8].id)" type="number"></el-input-number>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>

                    <!-- note -->
                    <el-col :span="24" class="margin-bottom">
                        <el-form-item label="Note" prop="verification_notes">
                            <el-input type="textarea" :rows="2" placeholder="Note" v-model="form.verification_notes" :required="true">
                            </el-input>
                        </el-form-item>
                        <span v-if="showAbsentPatientAlert">
                            <font-awesome-icon icon="exclamation" class="danger-text" />
                            <font style="color: red"
                                >Attenzione: l'ospite è attualmente assente</font
                            >
                        </span>
                        <span v-if="showOldAbsencePatientAlert">
                            <font-awesome-icon icon="exclamation" class="danger-text" />
                            <font style="color: red"
                                >Attenzione: l'ospite era assente in questo periodo</font
                            >
                        </span>
                    </el-col>
                    <el-col :span="24">
                        <el-button size="large" type="success" @click="insert()" class="float-right fixed-confirm-button" plain :loading="submitLoading">Conferma Rilevamenti</el-button>
                    </el-col>
                </el-row>
            </div>
            <div v-else class="block with-form-header" v-loading="patientLoader"></div>
        </el-form>
    </div>
</template>
<script>
import moment from "moment";

import Exam from "@/js/api/services/Exam";
import Patient from "@/js/api/services/Patient";

export default {
    name: "ExamsCreate",
    data() {
        return {
            loading: true,
            submitLoading: false,
            patientLoader: false,

            examTypes: [],
            patient_id: null,
            patientRegistry: {},

            form: {
                value: [],
                crit: [],
                category: [],
            },
            rules: {
                time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
                date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
                patient_id: [
                    { required: true, message: "Selezionare Ospite", trigger: "blur" },
                ],
                verification_notes: [
                    { required: false, message: "Inserire Note", trigger: "blur" },
                ],
            },
            pickerOptions: {
                firstDayOfWeek: 1,
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            },
            options: [
                {
                    value: "AA",
                    label: "AA",
                },
                {
                    value: "02Tx",
                    label: "02Tx",
                },
            ],
            optionsSecond: [
                {
                    value: "Disperse",
                    label: "Disperse",
                },
                {
                    value: "Ematurie",
                    label: "Ematurie",
                },
            ],
            patientSelected: false,
            controlValues: {},
            showAbsentPatientAlert: false,
            showOldAbsencePatientAlert: false,
            patient: null,
        };
    },
    computed: {
        patients() {
            return this.$store.state.patients;
        },
        peso() {
            return this.controlValues.lastSixMonthMaxWeight
                ? this.controlValues.lastSixMonthMaxWeight + "Kg"
                : "-";
        },
    },
    watch: {
        "form.value": function () {
            if (this.form.value[8]) {
                // (peso inserito - pesoMax6Mesi) / pesoMax6Mesi * 100 -> alert se <= -5
                let weightLimit =
                    ((parseFloat(this.form.value[8]) -
                        parseFloat(this.controlValues.lastSixMonthMaxWeight)) /
                        parseFloat(this.controlValues.lastSixMonthMaxWeight)) *
                    100;
                if (weightLimit <= -5) {
                    this.form.crit[8] = true;
                    this.rules.verification_notes[0].required = true;
                } else {
                    this.form.crit[8] = false;
                    this.rules.verification_notes[0].required = false;
                }
            }
        },
        form: {
            deep: true,
            handler(data) {
                if (this.form.patient_id) {
                    this.patient = _.filter(this.patients, { id: this.form.patient_id })[0];
                    this.showAbsentPatientAlert = this.patient.absence_info.absence_status;
                    this.showOldAbsencePatientAlert = false;
                    if (!this.showAbsentPatientAlert && this.patient.absence_info.last_absence) {
                        var date = `${moment(this.form.date).format("YYYY-MM-DD")} ${moment(this.form.time).format("HH:mm:ss")}`;
                        if (date > this.patient.absence_info.last_absence['confirmation_date'] && date < this.patient.absence_info.last_absence['verification_date']) {
                            this.showOldAbsencePatientAlert = true;
                        }
                    }
                }
            }
        },
    },
    methods: {
        async init() {
            const response = await Exam.settings();
            this.examTypes = response.data.examTypes;
            this.loading = false;
        },
        insert() {
            var id = this.form.id;
            this.$confirm("Confermare rilevamenti?", "Attenzione", {
                confirmButtonText: "Sì",
                cancelButtonText: "Annulla",
                type: "success",
            }).then(() => {
                this.submitLoading = true;
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.store();
                    } else {
                        this.submitLoading = false;
                        return false;
                    }
                });
            });
        },
        async store() {
            let params = {
                date: moment(this.form.date).format("YYYY-MM-DD"),
                time: moment(this.form.time).format("HH:mm:ss"),
                patient_id: this.form.patient_id,
                verification_notes: this.form.verification_notes,
                exam: this.form,
            };
            try {
                await Exam.store(params);
                this.success();
            } catch (error) {
                this.submitLoading = false;
            }
        },
        success() {
            this.$router.push({ name: "Rilevamenti" });
        },
        checkInput(input) {
            switch (input) {
                case 3:
                    if (this.form.value[3] > 140 || this.form.pressioneArteriosaSis < 90)
                        this.form.crit[3] = true;
                    else this.form.crit[3] = false;
                    break;
                case 4:
                    if (this.form.value[4] > 90 || this.form.value[4] < 50)
                        this.form.crit[4] = true;
                    else this.form.crit[4] = false;
                    break;
                case 1:
                    if (this.form.value[1] >= 37.5) this.form.crit[1] = true;
                    else this.form.crit[1] = false;
                    break;
                case 2:
                    if (this.form.value[2] > 120) this.form.crit[2] = true;
                    else this.form.crit[2] = false;
                    break;
                case 5:
                    if (this.form.value[5] > 20 || this.form.value[5] < 12)
                        this.form.crit[5] = true;
                    else this.form.crit[5] = false;
                    break;
                case 6:
                    if (this.form.value[6] < 93) this.form.crit[6] = true;
                    else this.form.crit[6] = false;
                    break;
                case 7:
                    if (this.form.value[7] > 150 || this.form.value[7] < 70)
                        this.form.crit[7] = true;
                    else this.form.crit[7] = false;
                    break;
            }
        },
        async getLastExam() {
            if (this.form.patient_id) {
                this.patientLoader = true;
                const resp = await Exam.controlValues(this.form.patient_id);
                this.controlValues = resp.data;
                this.patientSelected = true;
                this.patientLoader = false;
            } else {
                this.patientRegistry = {};
                this.patientSelected = false;
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
<style lang="scss" scoped>
@import "~vue-material/dist/theme/engine";

.checkbox-padding {
    //   padding-top: 50px;
}

.el-input-number {
    width: 100%;
}

.no-margin {
    margin: 0 !important;
}

.margin-bottom {
    margin-bottom: 20px;
}

.margin-top {
    margin-top: 10px;
}

.critical-checkbox.el-checkbox.is-bordered.is-checked {
    border-color: #e6a23c !important;

    .el-checkbox__input .is-checked {
        .el-checkbox__inner {
            background-color: #e6a23c !important;
            border-color: #e6a23c !important;
        }
    }
}
</style>
