<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_drs)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_drs.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_drs.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button class="float-right" round :disabled="disableNext" @click="show(test_next_drs)">
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_drs" :test_model="test_model_drs" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-divider content-position="center">1. Vigilanza, consapevolezza e responsività</el-divider>

          <el-row :gutter="20">
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q1_result">
                    <el-row>
                      <el-col :span="10">
                        <el-form-item label="A. Apertura degli occhi" required />
                      </el-col>

                      <el-col :span="14">
                        <el-radio-group v-model="test_drs.q1_result">
                          <el-form-item>
                            <el-radio :label="0">Spontanea</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="1">Alla parola</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="2">Al dolore</el-radio>
                          </el-form-item>
                          <el-form-item>
                            <el-radio :label="3">Nessuna</el-radio>
                          </el-form-item>
                        </el-radio-group>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q2_result">
                    <el-row>
                      <el-col :span="10">
                        <el-form-item label="B. Comunicazione" required />
                      </el-col>
                      <el-col :span="14">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_drs.q2_result">
                                <el-form-item>
                                  <el-radio :label="0">Orientata</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Confusa</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Inappropriata</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">Incomprensibile</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="4">Nessuna</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="5">Tracheotomia</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="6">Disartria, diafonia, afasia</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q3_result">
                    <el-row>
                      <el-col :span="10">
                        <el-form-item label="C. Miglior risposta motoria" required />
                      </el-col>
                      <el-col :span="14">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_drs.q3_result">
                                <el-form-item>
                                  <el-radio :label="0">Su ordine</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Localizzazione</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Retrazione</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">In flessione</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="4">In estensione</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="5">Nessuna</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>
          </el-row>

          <el-divider
            content-position="center"
          >2. Abilità cognitiva per le attività della cura di sé (Non considerare la disabilità motoria)</el-divider>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q4_result">
                    <el-row>
                      <el-col :span="10">
                        <el-form-item label="D. Nutrirsi" required />
                      </el-col>
                      <el-col :span="14">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_drs.q4_result">
                                <el-form-item>
                                  <el-radio :label="0">Completa</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Parziale</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Minima</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">Nessuna</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q5_result">
                    <el-row>
                      <el-col :span="10">
                        <el-form-item label="E. Igiene e controllo degli sfinteri" required />
                      </el-col>
                      <el-col :span="14">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_drs.q5_result">
                                <el-form-item>
                                  <el-radio :label="0">Completa</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Parziale</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Minima</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">Nessuna</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q6_result">
                    <el-row>
                      <el-col :span="10">
                        <el-form-item label="F. Rassettarsi" required />
                      </el-col>
                      <el-col :span="14">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_drs.q6_result">
                                <el-form-item>
                                  <el-radio :label="0">Completa</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Parziale</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Minima</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">Nessuna</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>
          </el-row>

          <el-divider content-position="center">3. Dipendenza da altri e adattabilità psico-sociale</el-divider>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q7_result">
                    <el-row>
                      <el-col :span="10">
                        <el-form-item label="G. Livello funzionale" required />
                      </el-col>
                      <el-col :span="14">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_drs.q7_result">
                                <el-form-item>
                                  <el-radio :label="0">Completa indipendenza</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio
                                    :label="1"
                                  >Indipendente con particolari necessità ambientali</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Dipendenza lieve</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">Dipendenza moderata</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="4">Dipendenza marcata</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="5">Dipendenza totale</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-divider />
                </el-col>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-col :span="24">
                  <el-form-item prop="q8_result">
                    <el-row>
                      <el-col :span="10">
                        <el-form-item label="H. Impiegabili lavorativa e sociale" required />
                      </el-col>
                      <el-col :span="14">
                        <el-row>
                          <el-col>
                            <div>
                              <el-radio-group v-model="test_drs.q8_result">
                                <el-form-item>
                                  <el-radio :label="0">Non ristretta</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="1">Impieghi selezionati (competitivi)</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="2">Lavoro protetto (non competitivo)</el-radio>
                                </el-form-item>
                                <el-form-item>
                                  <el-radio :label="3">Non impiegabile</el-radio>
                                </el-form-item>
                              </el-radio-group>
                            </div>
                          </el-col>
                        </el-row>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </el-col>
              </el-col>
            </el-col>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_drs.total_string}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Categoria di disabilità">{{test_drs.total_description}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "drs",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_drs: this.test,
      test_prev_drs: this.test_prev,
      test_next_drs: this.test_next,
      test_name_drs: this.test_name,
      test_model_drs: this.test_model,
      test_type_drs: this.test_type,
      patient_id_drs: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_drs,
        test_model: this.test_model_drs,
        test_id: test_id,
        type_id: this.test_type_drs
      };

      const response = await Test.show(params);
      this.test_drs = response.data.test;
      this.test_name_drs = response.data.test_name;
      this.test_prev_drs = response.data.test_prev;
      this.test_next_drs = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_drs === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_drs === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
