<script>
import SosiaService from '@/js/api/services/Sosia2';

export default {
  name: 'SosiaRateForm',
  props: ['visible', 'record', 'config', 'patientId', 'treatmentInfo'],
  data() {
    return {
      loading: false,
      form: {
        data_inizio_retta: null,
        retta_ssr: null,
        retta_comune: null,
        retta_ospite: null,
        retta_enti: null,
        assegno: null
      },
      editMode: Boolean(_.get(this.record, 'id')),
      pickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
        shortcuts: [
          {
            text: 'Annulla',
            onClick(picker) {
              picker.$emit('pick', null);
            }
          }
        ]
      }
    };
  },
  computed: {
    rules() {
      return {
        data_inizio_retta: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        retta_ssr: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        retta_comune: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        retta_ospite: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        retta_enti: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        assegno: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
      };
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid, errors) => {
        if (!valid) {
          this.$message({
            type: 'error',
            message: `Campi non validi`
          });
          return false;
        }
        this.editMode ? this.update() : this.create();
      });
    },
    create() {
      const payload = { ...this.form, patient_id: this.patientId, trattamento_id: this.treatmentInfo.id  };
      SosiaService.storeRate(payload)
        .then(() => this.$root.$emit('fetchPatient'))
        .then(() => this.close());
    },
    update() {
      const payload = { ...this.form, patient_id: this.record.patient_id, trattamento_id: this.treatmentInfo.id  };
      SosiaService.updateRate(this.record.id, payload)
        .then(() => this.$root.$emit('fetchPatient'))
        .then(() => this.close());
    },
    close() {
      this.$emit('update:visible', false);
    }
  },
  created() {
    if (this.editMode) {
      const fields = _.pick(this.record, [
        'data_inizio_retta',
        'retta_ssr',
        'retta_comune',
        'retta_ospite',
        'retta_enti',
        'assegno'
      ]);
      Object.assign(this.form, fields);
    }
  }
};
</script>

<template>
  <el-dialog
    :visible.sync="visible"
    title="Rette e Tariffe"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :show-close="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    width="70%"
    center
  >
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
          labelPosition="top"
          size="mini"
          :rules="rules"
          v-loading="loading"
          :validate-on-rule-change="false"
        >
          <el-row :gutter="10">
            <el-col :sm="24">
              <el-card>
                <el-row :gutter="10">
                  <el-col :span="24" :md="12">
                    <el-form-item label="Data inizio retta" prop="data_inizio_retta">
                      <el-date-picker
                        v-model="form.data_inizio_retta"
                        type="date"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12">
                    <el-form-item label="A carico del SSR" prop="retta_ssr">
                      <el-input-number
                        v-model="form.retta_ssr"
                        controls-position="right"
                        :precision="2"
                        :step="0.01"
                        :min="0.0"
                        :max="999.99"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12">
                    <el-form-item label="A carico del Comune" prop="retta_comune">
                      <el-input-number
                        v-model="form.retta_comune"
                        :precision="2"
                        controls-position="right"
                        :step="0.01"
                        :min="0.0"
                        :max="999.99"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12">
                    <el-form-item label="A carico dell'Ospite" prop="retta_ospite">
                      <el-input-number
                        v-model="form.retta_ospite"
                        controls-position="right"
                        :precision="2"
                        :step="0.01"
                        :min="0.0"
                        :max="999.99"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12">
                    <el-form-item label="A carico di altri Enti" prop="retta_enti">
                      <el-input-number
                        v-model="form.retta_enti"
                        controls-position="right"
                        :precision="2"
                        :step="0.01"
                        :min="0.0"
                        :max="999.99"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="24" :md="12">
                    <el-form-item label="Assegno di Accompagnamento" prop="assegno">
                      <el-select v-model="form.assegno" clearable>
                        <el-option
                          v-for="(item, index) in config.valori_assoluti"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" justify="center" style="margin-top: 20px">
            <el-button @click="$emit('update:visible', false)" class="modal-btn">Annulla</el-button>
            <el-button type="primary" @click="onSubmit" class="modal-btn">Salva</el-button>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<style lang="scss" scoped>
.el-form-item {
  margin-bottom: 0.6rem;
  .el-form-item__label {
    padding: 0 !important;
  }
}
.el-divider__text {
  font-weight: bold;
}
</style>