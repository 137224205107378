<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-col v-if="showSection == 'tab'">
      <el-table :data="tests" style="width: 100%" :row-class-name="rowClass">
        <el-table-column
          prop="test_name"
          label="Scala di valutazione"
          fixed
          width="260"
        ></el-table-column>

        <el-table-column
          prop="last_enabled_test_data.clean_date_time_test"
          label="Data e Ora"
          width="200"
        ></el-table-column>
        <el-table-column label="Totale">
          <template slot-scope="scope">
            <span v-if="scope.row.test_type == 1">
              {{ scope.row.last_enabled_test_data.total_with_factor }}
            </span>
            <span v-else>
              {{ scope.row.last_enabled_test_data.total_string }}
            </span>
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="Azioni" width="100">
          <template slot-scope="scope">
            <span class="prevent-deleted">
              <el-button type="text" size="small" @click="show(scope.row)"
                >Visualizza</el-button
              >
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col v-else-if="showSection == 'detail'">
      <el-col :span="24">
        <el-button type="text" size="small" @click="backToTests()">
          <font-awesome-icon icon="long-arrow-alt-left" />Torna all'elenco delle
          schede
        </el-button>
      </el-col>

      <el-col :span="24">
        <component :is="component_chart" :chart_data="chart_data" />
      </el-col>

      <el-col :span="24">
        <component
          :is="activeTest"
          :test="test"
          :test_prev="test_prev"
          :test_next="test_next"
          :test_name="test_name"
          :test_model="activeTest"
          :test_type="type_id"
          :patient-name="patientName"
        />
      </el-col>
    </el-col>
  </el-row>
</template>
<script>
import Tests from "@/js/api/services/Test";
import TestsChart from "@/js/pages/Patients/components/TestsChart";
import TestsChartRss from "@/js/pages/Patients/components/TestsChartRss";
import Mmse from "@/js/pages/Patients/components/Scale/Mmse";
import Braden from "@/js/pages/Patients/components/Scale/Braden";
import Conley from "@/js/pages/Patients/components/Scale/Conley";
import Gds from "@/js/pages/Patients/components/Scale/Gds";
import Gds5 from "@/js/pages/Patients/components/Scale/Gds5";
import Spmsq from "@/js/pages/Patients/components/Scale/Spmsq";
import Nrs from "@/js/pages/Patients/components/Scale/Nrs";
import Vas from "@/js/pages/Patients/components/Scale/Vas";
import Painad from "@/js/pages/Patients/components/Scale/Painad";
import Guss from "@/js/pages/Patients/components/Scale/Guss";
import Bina from "@/js/pages/Patients/components/Scale/Bina";
import Kane from "@/js/pages/Patients/components/Scale/Kane";
import Mna from "@/js/pages/Patients/components/Scale/Mna";
import Barthel from "@/js/pages/Patients/components/Scale/Barthel";
import Tinetti from "@/js/pages/Patients/components/Scale/Tinetti";
import Iadl from "@/js/pages/Patients/components/Scale/Iadl";
import Adico from "@/js/pages/Patients/components/Scale/Adico";
import Dmi from "@/js/pages/Patients/components/Scale/Dmi";
import Glasgow from "@/js/pages/Patients/components/Scale/Glasgow";
import Ashworth from "@/js/pages/Patients/components/Scale/Ashworth";
import Norton from "@/js/pages/Patients/components/Scale/Norton";
import Cdrs from "@/js/pages/Patients/components/Scale/Cdrs";
import Drs from "@/js/pages/Patients/components/Scale/Drs";
import Cmai from "@/js/pages/Patients/components/Scale/Cmai";
import Cirs from "@/js/pages/Patients/components/Scale/Cirs";
import Must from "@/js/pages/Patients/components/Scale/Must";
import Mnae from "@/js/pages/Patients/components/Scale/Mnae";
import Npi from "@/js/pages/Patients/components/Scale/Npi";
import Rss from "@/js/pages/Patients/components/Scale/Rss";
import Adl from "@/js/pages/Patients/components/Scale/Adl";
import Rcns from "@/js/pages/Patients/components/Scale/Rcns";
import Vrs from "@/js/pages/Patients/components/Scale/Vrs";
import Aged from "@/js/pages/Patients/components/Scale/Aged";
import Stratify from "@/js/pages/Patients/components/Scale/Stratify";
import Vcc from "@/js/pages/Patients/components/Scale/Vcc";

export default {
  name: "Tests",
  props: ["profile_id", "patientName"],
  components: {
    TestsChart,
    TestsChartRss,
    Mmse,
    Braden,
    Conley,
    Gds,
    Gds5,
    Spmsq,
    Nrs,
    Vas,
    Painad,
    Guss,
    Bina,
    Kane,
    Mna,
    Barthel,
    Tinetti,
    Iadl,
    Adico,
    Dmi,
    Glasgow,
    Ashworth,
    Norton,
    Cdrs,
    Drs,
    Cmai,
    Cirs,
    Must,
    Mnae,
    Npi,
    Rss,
    Adl,
    Rcns,
    Vrs,
    Aged,
    Stratify,
    Vcc,
  },
  data() {
    return {
      loading: true,
      tests: [],
      showSection: "tab",
      test: null,
      activeTest: null,
      type_id: null,
      test_name: null,
      test_prev: null,
      test_next: null,
      component_chart: null,
      component_disable: null,
      chart_data: [],
    };
  },
  methods: {
    async fetch() {
      this.loading = true;
      let params = {
        patient_id: this.profile_id,
      };
      const response = await Tests.fetch(params);
      this.tests = response.data.scales;
      this.loading = false;
    },
    async show(scope) {
      this.showSection = "detail";

      let params = {
        patient_id: this.profile_id,
        test_model: scope.test_model,
        // test_id: scope.test_id,
        test_id: scope.last_enabled_test_data.id,
        type_id: scope.test_type,
      };

      const response = await Tests.show(params);

      this.test = response.data.test;
      this.activeTest = response.data.test_model;
      this.type_id = response.data.type_id;
      this.test_name = response.data.test_name;
      this.test_prev = response.data.test_prev;
      this.test_next = response.data.test_next;

      if (this.type_id == 28) {
        const response_chart = await Tests.getTestChartDataRss(params);
        this.chart_data = response_chart.data;
        this.component_chart = "TestsChartRss";
      } else {
        const response_chart = await Tests.getTestChartData(params);
        this.chart_data = response_chart.data;
        this.component_chart = "TestsChart";
      }
    },
    backToTests() {
      this.fetch();

      this.showSection = "tab";

      this.test = null;
      this.activeTest = null;
      this.type_id = null;
      this.test_name = null;
      this.test_prev = null;
      this.test_next = null;
      this.component_chart = null;
      this.chart_data = [];
    },
    rowClass({ row, rowIndex }) {
      if (_.get(row, "last_enabled_test_data.enabled") == 0) {
        return "deleted-row";
      }
      return "";
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
