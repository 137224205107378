<template>
  <div>
    <el-row :gutter="20" class="no-margin-row">
      <!-- select pasto -->
      <el-col :span="24">
        <el-select
          v-model="mealTypeForm"
          placeholder="Seleziona Pasto"
          clearable
          ref="mealTypeForm"
          @clear="onMealTypeReset"
        >
          <!-- multiple -->
          <!-- :style="{ color: differenceFourHours(item.label) ? 'red' : '' }" -->
          <el-option
            v-for="(item, index) in config.meal_types"
            :key="index"
            :label="_.capitalize(item)"
            :value="index"
          >
          </el-option>
        </el-select>
      </el-col>

      <!-- pasti x ospite -->
      <el-col
        :span="24"
        style="margin-top: 15px"
        v-if="filteredPatients && filteredPatients.length > 0"
      >
        <el-button
          type="primary"
          :icon="collapseTab ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
          circle
          style="margin: 5px 18px"
          @click="collapseTab ? (collapseTab = false) : (collapseTab = true)"
          size="mini"
        ></el-button>
        <el-tabs
          tab-position="left"
          class="is-sticky verification-meals-tabs"
          @tab-click="changePatient"
          v-model="activePatientTab"
        >
          <el-tab-pane
            v-for="(patient, index) in filteredPatients"
            :key="index"
            :label="patient.full_name + patient.absence_status"
            :value="patient.id"
            v-loading="loading"
            :lazy="true"
            style="margin-bottom: 20px"
          >
            <span slot="label">
              <span v-show="!collapseTab">{{ patient.full_name }}</span>
              <span v-show="collapseTab">{{
                patient.surname.slice(0, 1) + patient.name.slice(0, 1)
              }}</span>
            </span>
            <!-- pasto -->
            <div
              class="mealverificationswrapper"
              v-loading="loading"
              style="padding-bottom: 20px"
            >
              <el-row style="width: 100%">
                <el-col :span="24" style="margin-bottom: 20px">
                  <h2 :class="{ absence: patient.absence_status }">
                    <center>{{ patient.full_name }}</center>
                  </h2>
                  <span class="absence" v-if="patient.absence_status"
                    ><center>Paziente Assente</center></span
                  >
                </el-col>
                <el-col :span="8">
                  <b>Data Inizio:</b>
                  {{ departmentMeals[selectedPatient].clean_monitoring_start }}
                  <br />
                  <b>Data Fine:</b>
                  <span
                    v-if="departmentMeals[selectedPatient].clean_monitoring_end"
                    >{{
                      departmentMeals[selectedPatient].clean_monitoring_end
                    }}</span
                  >
                  <span v-else style="font-size: 20px"> &#8734; </span>
                </el-col>
                <el-col :span="16">
                  <b>Note:</b>
                  {{ departmentMeals[selectedPatient].description }}
                </el-col>
                <el-col :span="24" class="text-center" style="margin-top: 30px">
                  <pasto
                    :mealTypeDesc="config.meal_types[mealTypeForm]"
                    :mealTypeId="parseInt(mealTypeForm)"
                    :mealId="selectedMealId"
                    :mealVerification.sync="selectedMealVerfication"
                    :patientId="selectedPatient"
                    @savePasto="fetch"
                  ></pasto>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Meal from "@/js/api/services/Meal";
import MealVerification from "@/js/api/services/MealVerification";
import Pasto from "./components/pasto";

export default {
  name: "VerificationMeals",
  components: {
    Pasto,
  },
  data() {
    return {
      loading: false,
      mealTypeForm: null,
      filteredPatients: null,
      selectedPatient: null,
      selectedMealVerfication: {},
      selectedMealId: null,
      activePatientTab: 0,
      collapseTab: false,
      cancelRequest: null,
      departmentMeals: {},
      form: {},
    };
  },
  computed: {
    config() {
      return this.$store.state.config.meals;
    },
    user() {
      return this.$store.state.user;
    },
    patients() {
      return this.$store.state.patients;
    },
  },
  watch: {
    async mealTypeForm(value) {
      this.loadingTabs = true;
      this.activePatientTab = "0";
      var descValue = this.config.meal_types[value];

      if (value) {
        let self = this;
        this.filteredPatients = await _.filter(this.patients, function (p) {
          var dMP = self.departmentMeals[p.id];
          return dMP !== undefined && dMP[descValue] === 1;
        });

        if (
          this.$route.query.mealType &&
          this.$route.query.mealId &&
          this.$route.query.patientId
        ) {
          this.selectedPatient = parseInt(this.$route.query.patientId);
          this.selectedMealId = parseInt(this.$route.query.mealId);
          this.activePatientTab = _.toString(
            _.findIndex(this.filteredPatients, ["id", this.selectedPatient])
          );
          this.$router.replace({ query: null });
          this.fetch();
        } else if (this.filteredPatients.length > 0) {
          this.selectedPatient = this.filteredPatients[0].id;
          this.selectedMealId = this.departmentMeals[this.selectedPatient].id;
          this.fetch();
        }
        this.loadingTabs = false;
      }
    },
    "$route.query"() {
      if (this.$route.query.mealType) {
        this.mealTypeForm = _.toString(this.$route.query.mealType);
      }
    },
  },
  methods: {
    onMealTypeReset() {
      this.mealTypeForm = null;
      this.filteredPatients = null;
      this.selectedPatient = null;
      this.activePatientTab = "0";
    },
    async fetch() {
      this.loading = true;

      let params = {
        patient_id: this.selectedPatient,
        meal_type: this.mealTypeForm,
        meal_id: this.selectedMealId,
      };
      this.checkAbsence();

      try {
        const response = await MealVerification.fetch(params);
        this.selectedMealVerfication = response.data.mealVerification ?? {};
      } catch (e) {
        console.error(e);
      } finally {
        this.setHeightTabPane();
        this.loading = false;
      }
    },
    checkAbsence() {
      this.showAbsentPatientAlert = false;
      if (this.selectedPatient) {
        var absenceStatus = this.filteredPatients.filter(
          (x) => x.id == this.selectedPatient
        )[0].absence_status;
        if (absenceStatus) {
          this.showAbsentPatientAlert = true;
        }
      }
    },
    changePatient(value) {
      this.selectedPatient = value.$attrs.value;
      this.selectedMealId = this.departmentMeals[this.selectedPatient].id;
      this.fetch();
      this.collapseTab = true;
    },
    setHeightTabPane() {
      const headerTabPane =
        document.querySelector(".verification-meals-tabs .el-tabs__header") ??
        null;
      const bodyTabPane =
        document.querySelector(".verification-meals-tabs .el-tabs__content") ??
        null;

      const bodyStyles = window.getComputedStyle(bodyTabPane);
      let bodyHeight = parseInt(bodyStyles.getPropertyValue("height"));

      headerTabPane.style.setProperty(
        "height",
        parseInt(bodyHeight - 30) + "px"
      );
    },
  },
  async mounted() {
    const respMeals = await Meal.departmentMeals(
      this.$store.state.departmentId
    );
    this.departmentMeals = respMeals.data;
  },
};
</script>

<style scoped="'css'">
.mealverificationswrapper {
  height: calc(100vh - 230px);
  overflow-y: scroll;
}
</style>