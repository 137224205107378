<template>
  <el-row :gutter="12" type="flex" justify="center" class="container">
    <el-col :span="11">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>Tendine</span>
          <el-button
            @click="$router.push('/superadmin/dropdowns')"
            class="dashboard-button"
            type="text"
            >Vai</el-button
          >
        </div>
        <div class="text item">Vai alla gestione delle tendine</div>
      </el-card>
    </el-col>

    <el-col :span="11">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>Operatori</span>
          <el-button
            @click="$router.push('/superadmin/operators')"
            class="dashboard-button"
            type="text"
            >Vai</el-button
          >
        </div>
        <div class="text item">Vai alla gestione degli Operatori</div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "SuperadminDashboard",
};
</script>

<style>
.container {
  margin-top: 50px;
}
.text {
  font-size: 14px;
}
.item {
  margin-bottom: 18px;
}
.dashboard-button {
  float: right;
  padding: 3px 0;
}
</style>
