<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button
            class="float-left"
            round
            :disabled="disablePrev"
            @click="show(test_prev_conley)"
          >
            <font-awesome-icon icon="long-arrow-alt-left" />
            Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_conley.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_conley.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_conley)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable
          :test="test_conley"
          :test_model="test_model_conley"
          @showparent="show"
        />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px">
          <el-col :span="24">
            <el-divider content-position="left"
              >Precedenti cadute (domande al
              paziente/caregiver/infermiere)</el-divider
            >
          </el-col>

          <el-col :span="24">
            <el-form-item label="E' caduto nel corso degli ultimi tre mesi?">
              <el-radio-group v-model="test_conley.q1_result">
                <el-radio :label="2" border disabled>Si</el-radio>
                <el-radio :label="0" border disabled>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item
              label="Ha mai avuto vertigini o capogiri? (negli ultimi tre mesi)"
            >
              <el-radio-group v-model="test_conley.q2_result">
                <el-radio :label="1" border disabled>Si</el-radio>
                <el-radio :label="0" border disabled>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item
              label="Le è mai capitato di perdere urine o feci mentre si recava in bagno? (negli ultimi tre mesi)"
            >
              <el-radio-group v-model="test_conley.q3_result">
                <el-radio :label="1" border disabled>Si</el-radio>
                <el-radio :label="0" border disabled>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-divider content-position="left"
              >Deterioramento cognitivo (osservazione
              infermieristica/fkt)</el-divider
            >
          </el-col>

          <el-col :span="24">
            <el-form-item
              label="Compromissione della marcia, passo strisciante, ampia base di appoggio, marcia instabile."
            >
              <el-radio-group v-model="test_conley.q4_result">
                <el-radio :label="1" border disabled>Si</el-radio>
                <el-radio :label="0" border disabled>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item
              label="Agitato (definizione: eccessiva attività motoria, solitamente non finalizzata ed associata ad agitazione interiore. Es: incapacità di stare seduto fermo, si muove con irrequietezza, si tira i vestiti, ecc.)"
            >
              <el-radio-group v-model="test_conley.q5_result">
                <el-radio :label="2" border disabled>Si</el-radio>
                <el-radio :label="0" border disabled>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item
              label="Deterioramento delle capacità di giudizio/mancanza del senso di pericolo"
            >
              <el-radio-group v-model="test_conley.q6_result">
                <el-radio :label="3" border disabled>Si</el-radio>
                <el-radio :label="0" border disabled>No</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <el-row :gutter="20">
            <!-- BREAK Results -->
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <!-- Results -->
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">
                  {{ test_conley.total_string }}
                </el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Conley",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type",
  ],
  data() {
    return {
      test_conley: this.test,
      test_prev_conley: this.test_prev,
      test_next_conley: this.test_next,
      test_name_conley: this.test_name,
      test_model_conley: this.test_model,
      test_type_conley: this.test_type,
      patient_id_conley: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_conley,
        test_model: this.test_model_conley,
        test_id: test_id,
        type_id: this.test_type_conley,
      };

      const response = await Test.show(params);

      this.test_conley = response.data.test;
      this.test_name_conley = response.data.test_name;
      this.test_prev_conley = response.data.test_prev;
      this.test_next_conley = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_conley === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_conley === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    },
  },
  beforeMount() {
    this.checkPrevNext();
  },
};
</script>
