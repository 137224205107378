import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class arkicare extends ArkiBaseService {

    static endpoint = 'arkicare';

    static guideExport() {
        return axios({
            url: `${this.endpoint}/guide_export`,
            method: 'POST',
            responseType: 'blob'
        });
    }
}
