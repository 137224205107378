<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-divider content-position="center">1. Vigilanza, consapevolezza e responsività</el-divider>

      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q1_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="A. Apertura degli occhi" required />
                  </el-col>

                  <el-col :span="12">
                    <el-radio-group v-model="result.q1_result">
                      <el-form-item>
                        <el-radio :label="0">Spontanea</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="1">Alla parola</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="2">Al dolore</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="3">Nessuna</el-radio>
                      </el-form-item>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q2_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="B. Comunicazione" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q2_result">
                            <el-form-item>
                              <el-radio :label="0">Orientata</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Confusa</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Inappropriata</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">Incomprensibile</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="4">Nessuna</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="5">Tracheotomia</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="6">Disartria, diafonia, afasia</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q3_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="C. Miglior risposta motoria" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q3_result">
                            <el-form-item>
                              <el-radio :label="0">Su ordine</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Localizzazione</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Retrazione</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">In flessione</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="4">In estensione</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="5">Nessuna</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <el-col :span="24">
                  <el-row type="flex" justify="end" align="center">
                    <p>Punteggio parziale</p>
                    <span class="partial">{{ totalA }}</span>
                  </el-row>
                </el-col>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-divider
        content-position="center"
      >2. Abilità cognitiva per le attività della cura di sé (Non considerare la disabilità motoria)</el-divider>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q4_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="D. Nutrirsi" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q4_result">
                            <el-form-item>
                              <el-radio :label="0">Completa</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Parziale</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Minima</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">Nessuna</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q5_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="E. Igiene e controllo degli sfinteri" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q5_result">
                            <el-form-item>
                              <el-radio :label="0">Completa</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Parziale</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Minima</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">Nessuna</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q6_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="F. Rassettarsi" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q6_result">
                            <el-form-item>
                              <el-radio :label="0">Completa</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Parziale</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Minima</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">Nessuna</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <el-col :span="24">
                  <el-row type="flex" justify="end" align="center">
                    <p>Punteggio parziale</p>
                    <span class="partial">{{ totalB }}</span>
                  </el-row>
                </el-col>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-divider content-position="center">3. Dipendenza da altri e adattabilità psico-sociale</el-divider>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q7_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="G. Livello funzionale" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q7_result">
                            <el-form-item>
                              <el-radio :label="0">Completa indipendenza</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Indipendente con particolari necessità ambientali</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Dipendenza lieve</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">Dipendenza moderata</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="4">Dipendenza marcata</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="5">Dipendenza totale</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q8_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="H. Impiegabili lavorativa e sociale" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q8_result">
                            <el-form-item>
                              <el-radio :label="0">Non ristretta</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">Impieghi selezionati (competitivi)</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="2">Lavoro protetto (non competitivo)</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">Non impiegabile</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <el-col :span="24">
                  <el-row type="flex" justify="end" align="center">
                    <p>Punteggio parziale</p>
                    <span class="partial">{{ totalC }}</span>
                  </el-row>
                </el-col>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Categoria di disabilità">
              <el-input :disabled="true" v-model="totalDescription" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults, createResults, totalOf } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Drs",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 30
      },
      result: {},
      testRules: rules(8),
      aResults: createResults(1, 4),
      bResults: createResults(4, 7),
      cResults: createResults(7, 9),
      totalA: 0,
      totalB: 0,
      totalC: 0
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_description: this.totalDescription
          };
          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    computePartialResults() {
      this.totalA = totalOf(this.aResults, this.result);
      this.totalB = totalOf(this.bResults, this.result);
      this.totalC = totalOf(this.cResults, this.result);
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
    totalDescription() {
      let condMap = _.cond([
        [_.matches({ total: 0 }), _.constant("Nessuna disabilità")],
        [_.matches({ total: 1 }), _.constant("Disabilità lieve")],
        [
          _.conforms({ total: n => _.inRange(n, 2, 4) }),
          _.constant("Disabilità parziale")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 4, 7) }),
          _.constant("Disabilità moderata")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 7, 12) }),
          _.constant("Disabilità moderatamente severa")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 12, 17) }),
          _.constant("Disabilità severa")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 17, 22) }),
          _.constant("Disabilità estremamente severa")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 22, 25) }),
          _.constant("Stato vegetativo")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 25, 30) }),
          _.constant("Stato vegetativo grave")
        ],
        [_.matches({ total: 30 }), _.constant("Morte")]
      ]);
      return condMap(this.form);
    }
  },
  watch: {
    result: {
      handler: function() {
        this.computePartialResults();
        let results = _.values(filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.radio-label {
  white-space: normal;
}
.radio-label_text {
  margin-left: 20px;
}
.partial {
  font-weight: bold;
  margin-left: 10px;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>