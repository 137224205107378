<template>
    <el-row style="margin-top: 0px;font-size: 12px">
        <h4 class="title">DATI ANAGRAFICI</h4> 
        <el-col :span="12">
          <el-row>
            <el-col><span class="key">Trattamento RSA</span>: <span>{{ `${data.cognome} ${data.nome} `}}</span></el-col>
            <el-col><span class="key">Data di Nascita</span>: <span>{{ data.clean_data_nascita }}</span></el-col>
            <el-col><span class="key">Codice Fiscale</span>: <span>{{ data.codice_individuale_ospite }}</span></el-col>
            <el-col><span class="key">Tessera Sanitaria</span>: <span>{{ data.codice_sanitario_ospite }}</span></el-col>
            <el-col><span class="key">Provincia di Nascita</span>: <span>{{ _.get(data, 'clean_luogo_nascita.sigla') }}</span></el-col>
            <el-col><span class="key">Provincia di Residenza</span>: <span>{{ _.get(data, 'clean_comune_residenza.sigla') }}</span></el-col>
            <el-col><span class="key">Stato Civile</span>: <span>{{ config['stato_civile'][data.stato_civile]}}</span></el-col>
            <el-col><span class="key">N. Figli Maschi</span>: <span>{{ data.figli_maschi }}</span></el-col>
          </el-row>
        </el-col>

        <el-col :span="12">
          <el-row>
            <el-col><span class="key">Situazione Pensionistica</span>: <span>{{ config['situazione_pensionistica'][data.situazione_pensionistica] }}</span></el-col>
            <el-col><span class="key">Sesso</span>: <span>{{ config['genere'][data.genere] }}</span></el-col>
            <el-col><span class="key">Cittadinanza</span>: <span>{{ formatIstatLabel(data.clean_cittadinanza) }}</span></el-col>
            <el-col><span class="key">Comune di Nascita</span>: <span>{{ formatIstatLabel(data.clean_luogo_nascita)}}</span></el-col>
            <el-col><span class="key">Comune di Residenza</span>: <span>{{ formatIstatLabel(data.clean_comune_residenza)}}</span></el-col>
            <el-col><span class="key">N. Figlie Femmine</span>: <span>{{ data.figli_femmine }}</span></el-col>
          </el-row>
        </el-col>

        <el-col :span="12">
          <span class="key">Posizione Professione</span>: <span>{{ config['posizione_nella_professione'][data.professione] }}</span>
        </el-col>
        <el-col :span="20">
            <span class="key">Titolo di Studi</span>: <span>{{ config['titolo_di_studio'][data.titolo_studio] }}</span>
        </el-col>
      </el-row>
</template>
<script>

export default {
  name: "DatiAnagrafici",
  props: ["data", "patient", "config"],
  components: {},
  data() {
    return {
     
    };
  },
  computed: {
  },
  methods: {
    formatIstatLabel(value) {
      if(!value) return '';
      const { codice_istat, comune, stato, sigla } = value;
      const sig = sigla ? `(${sigla})` : "";
      return `${codice_istat} - ${comune || stato} ${sig}`;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.title {
  border: 0.5px solid #9C9FA5; 
  padding: 2px;
  border-radius: 2px;
}
.key {
  font-weight: bold;
}
.value {

}
</style>