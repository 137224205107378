import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Exam extends ArkiBaseService {

    static endpoint = 'exams';

    static controlValues(patient) {
        return Api.get(`${this.endpoint}/controlValues/${patient}`);
    }
}