<template>
  <div v-show="!loading">
    <el-form
      label-position="top"
      label-width="80px"
      :model="result"
      ref="form"
      :rules="testRules"
    >
      <el-row :gutter="20">
        <el-col
          :span="24"
          v-for="(category, idx) in categories"
          :key="idx + '-cat'"
        >
          <el-divider content-position="center">{{ category.name }}</el-divider>
          <el-form-item :prop="category.result">
            <el-col
              :span="24"
              v-for="(option, idx2) in category.options"
              :key="idx2 + '-opt'"
            >
              <el-col :span="16">
                <el-form-item :label="option.label" />
              </el-col>
              <el-col :span="8">
                <el-row>
                  <el-col :offset="12">
                    <el-form-item>
                      <el-radio
                        :label="option.points"
                        v-model="result[category.result]"
                        border
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-col>
          </el-form-item>
        </el-col>
        <!-- </el-radio-group> -->
      </el-row>
      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{ form.total_quest }}</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions
      @reset="resetForm"
      @save="submit"
      :loading="loading"
      :show-actions="showActions"
    />
  </div>
</template>
<script>
import { VCC } from "../constants";
import TestActions from "../components/TestActions";
import Tests from "@/js/api/services/Test";

export default {
  name: "Vcc",
  props: ["loading", "patientId"],
  components: { TestActions },
  created() {
    this.showLoader();
    this.fetchLastMmse();
  },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 12,
      },
      result: {},
      lastMmse: null,
      testRules: VCC.rules(3),
      categories: VCC.categories,
    };
  },
  methods: {
    async fetchLastMmse() {
      let params = {
        patient_id: this.patientId,
      };
      const response = await Tests.fetch(params);
      let tests = _.get(response, ["data", "scales"]);
      this.lastMmse = tests.find((test) => test.test_model == "Mmse");
      this.hideLoader();
    },
    async submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$emit("submitTest", this.form, this.result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error",
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map((k) => this.$delete(this.result, k));
    },
    hideLoader() {
      this.$emit("update:loading", false);
    },
    showLoader() {
      this.$emit("update:loading", true);
    },
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
    invalidMmse() {
      return !this.lastMmse || !this.allowedToContinue;
    },
    allowedToContinue() {
      let total = _.get(this.lastMmse, ["test_data", "total"]);
      return total <= 18;
    },
  },
  watch: {
    result: {
      handler: function () {
        let results = _.values(VCC.filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.test_vas .el-col {
  text-align: center;
  line-height: 20px;
}
.warning {
  color: red;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>