import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Task extends ArkiBaseService {

    static endpoint = 'tasks';

    static count() {
        return Api.get(`${this.endpoint}/count`);
    }
    static totalCount() {
        return Api.get(`${this.endpoint}/total-count`);
    }
}