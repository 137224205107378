<template>
    <div>
        <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
            <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                <el-divider content-position="left">COERENZA</el-divider>
                <el-form-item prop="q1_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Coerente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q1_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Parzialmente o saltuariamente incoerente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q1_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Incoerente (bisogno di sorveglianza continua)
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q1_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-divider content-position="left">ORIENTAMENTO</el-divider>
                <el-form-item prop="q2_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Ben orientato
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q2_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Parzialmente o saltuariamente disorientato
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q2_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Disorientamento Temporo-spaziale
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q2_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Sorveglianza incoerente e/o confuso
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q20_result_a" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Sorveglianza incoerente e/o confuso itinerante
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="3" :true-label="3" v-model="result.q20_result_b" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>

            <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                <el-divider content-position="left">INSERIMENTO SOCIALE</el-divider>
                <el-form-item prop="q3_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Nessun probl. di inserimen. nel proprio contesto
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q3_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Ha qualche difficoltâ (rapporti con frequenti contrasti)
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q3_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Gravi problemi (non riesce a mantenere rapporti)
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q3_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Parziale aiuto all'inserimento in comunità
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q21_result_a" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto all'insermiento in comunità
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q21_result_b" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>

            <el-card style="margin: 1rem 4rem" body-style="margin: 20px 40px">
                <el-divider content-position="left">VISTA</el-divider>
                <el-form-item prop="q4_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Vede bene (anche con lenti)
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q4_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            La funzione é parzialmente conservata
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q4_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non vedente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q4_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Impegno assistenziale specifico medio
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="0.5" :true-label="0.5" v-model="result.q22_result_a" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Impegno assistenziale specifico alto
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q22_result_b" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>

            <el-card style="margin: 1rem 4rem" body-style="margin: 20px 40px">
                <el-divider content-position="left">UDITO</el-divider>
                <el-form-item prop="q5_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Sente bene (anche con protesi acustica)
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q5_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            La funzione é parzialmente conservata
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q5_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non udente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q5_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Impegno assistenziale specifico medio
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="0.5" :true-label="0.5" v-model="result.q23_result_a" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Impegno assistenziale specifico alto
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q23_result_b" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>

            <el-card style="margin: 1rem 4rem" body-style="margin: 20px 40px">
                <el-divider content-position="left">PAROLA</el-divider>
                <el-form-item prop="q6_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Parla e comprende normalmente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q6_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Difficoltà espressione verbale (Disartria)
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q6_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Turbe gravi sia dell’espres. che della compr. verb.
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q6_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Afasia con comprensione parziale conservata
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="0.5" :true-label="0.5" v-model="result.q24_result_a" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Afasia grave
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q24_result_b" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>
            <el-card style="margin: 1rem 4rem" body-style="margin: 20px 40px">
                <el-divider content-position="left">IGIENE SUPERIORE</el-divider>
                <el-form-item prop="q7_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q7_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Parzialmente autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q7_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q7_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-divider content-position="left">IGIENE INFERIORE</el-divider>
                <el-form-item prop="q8_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q8_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Parzialmente autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q8_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q8_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto ad autosufficiente parziale
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q25_result_a" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non autosufficiente fisico
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q25_result_b" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non autosufficiente incoerente e\o confuso
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q25_result_c" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non autosufficiente incontinente lucido
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="3" :true-label="3" v-model="result.q25_result_d" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non autosufficiente incoerente e/o confuso incontinente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="4" :true-label="4" v-model="result.q25_result_e" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>
            <el-card style="margin: 1rem 4rem" body-style="margin: 20px 40px">
                <el-divider content-position="left">ABBIGLIAMENTO SUPERIORE</el-divider>
                <el-form-item prop="q9_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q9_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Parzialmente autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q9_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q9_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-divider content-position="left">ABBIGLIAMENTO CENTRALE</el-divider>
                <el-form-item prop="q8_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q10_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Parzialmente autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q10_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q10_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-divider content-position="left">ABBIGLIAMENTO INFERIORE</el-divider>
                <el-form-item prop="q11_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q11_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Parzialmente autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q11_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q11_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto ad autosufficiente parziale
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q26_result_a" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto a non autosufficiente fisico
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q26_result_b" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto a non autosufficiente confuso
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q26_result_c" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto a non autosuff. incont. lucido senza dispos.
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q26_result_d" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non auto. incoer. e/o confuso incont.senza dispositivi
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="3" :true-label="3" v-model="result.q26_result_e" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>

            <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                <el-divider content-position="left">TIPO DI ALIMENTAZIONE</el-divider>
                <el-form-item prop="q12_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Normale (per os)
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q12_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Liquida o semiliquida per os
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q12_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Artificiale (SNG, PEG, NPT)
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q12_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Preparazione alimentazione parenterale o con sonda
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q27_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>

            <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                <el-divider content-position="left">AIUTO ALLA ALIMENTAZIONE</el-divider>
                <el-form-item prop="q13_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Nessun aiuto
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q13_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Adattamento del pasto (aiuto per tagliare i cibi)
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q13_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto totale e sorveglianza continua
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q13_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Adattamento del pasto
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q28_result_a" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto a non autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="6" :true-label="6" v-model="result.q28_result_c" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non autosufficiente SNG o PEG
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="6" :true-label="6" v-model="result.q28_result_b" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>

            <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                <el-divider content-position="left">CONTINENZA URINARIA</el-divider>
                <el-form-item prop="q14_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Continente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q14_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Incontinente saltuario
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q14_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Incontinente frequente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q14_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-divider content-position="left">CONTINENZA FECALE</el-divider>
                <el-form-item prop="q15_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Continente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q15_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Incontinente saltuario
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q15_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Incontinente frequente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q15_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Assist. padella o pappagallo e aiuto all’uso del wc
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q29_result_a" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Assistenza uso assorbente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q29_result_b" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Usa assorbente anche di notte?
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio label="No" v-model="result.q29_result_e" border />
                            <el-radio label="Si" v-model="result.q29_result_e" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Assistenza per catetere vescicale
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q29_result_f" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Assistenza per raccoglitore esterno (tipo Conveyn)
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q29_result_c" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Assistenza per neostoma uretero-cutaneo
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q29_result_g" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Assistenza per neostoma entero-cutaneo
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q29_result_d" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>

            <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                <el-divider content-position="left">MOBILIZZAZIONE DAL LETTO</el-divider>
                <el-form-item prop="q16_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Si alza senza aiuto o non si alza
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q16_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Deve essere aiutato
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q16_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Deve essere alzato Passivamente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q16_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto umano per lasciare il letto
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q30_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>

            <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                <el-divider content-position="left">MOBILITA’ INTERNA</el-divider>
                <el-form-item prop="q17_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Si muove senza limiti nella residenza
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q17_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Si muove solo in camera
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q17_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Non può essere alzato mai dal letto
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q17_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Prevenzione decubiti
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q31_result_a" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Mobilizzazione passiva a letto
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q31_result_b" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>

            <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                <el-divider content-position="left">AIUTO ALLA DEAMBULAZIONE</el-divider>
                <el-form-item prop="q18_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Nessun aiuto
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q18_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto meccanico (bastone,tripode,carrozzella autosuf.)
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q18_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto umano (anche per spingere carrozzella)
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q18_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto umano per spostamento in carrozzella
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q32_result_a" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto umano per deambulazione in reparto/casa
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q32_result_b" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>

            <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                <el-divider content-position="left">IGIENE POSTO LETTO</el-divider>
                <el-form-item prop="q19_result">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Autosufficiente
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="2" v-model="result.q19_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Necessita di aiuto
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="1" v-model="result.q19_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Nessuna attività
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-radio :label="0" v-model="result.q19_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Aiuto igiene posto letto
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="0.5" :true-label="0.5" v-model="result.q33_result_a" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Igiene posto letto a non autosufficiente fisico
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q33_result_c" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Igiene posto letto a incont. lucido senza disp.
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q33_result_d" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Igiene posto letto a incoerente e/o confuso
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q33_result_b" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Igiene p.l. a incoerente e/o confuso senza disp.
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="3" :true-label="3" v-model="result.q33_result_e" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>

            <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                <el-divider content-position="left">NURSING INFERMIERISTICO</el-divider>
                <el-form-item class="form-item-blue">
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Preparazione e distribuz. terapia per os e parenterale
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q34_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Preparazione terapia intensiva EV
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q35_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Medicazione presidi (stomie) e lesioni cutanee
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q36_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Sorveglianza infermieristica a NPT
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="3" :true-label="3" v-model="result.q37_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Sorveglianza infermieristica a terapia EV
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="3" :true-label="3" v-model="result.q38_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Sorv. inferm. ad alim. per stomia entero-cutanea
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q39_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Sorveglianza infermieristica a fleboclisi
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="1" :true-label="1" v-model="result.q40_result" border />
                        </el-col>
                    </el-row>
                    <el-row :gutter="40">
                        <el-col :span="16">
                            Sorveglianza infermieristica a terapia peridurale
                        </el-col>
                        <el-col :span="8" class="text-left">
                            <el-checkbox :label="2" :true-label="2" v-model="result.q41_result" border />
                        </el-col>
                    </el-row>
                </el-form-item>
            </el-card>

            <el-row :gutter="40" class="bottom-space">
                <!-- BREAK Submit -->
                <el-col :span="24">
                    <el-divider></el-divider>
                </el-col>
                <!-- submit -->
                <el-col :span="24">
                    <el-col :span="8">
                        Totale
                        <el-input v-model="form.total" :disabled="true">
                            <!-- <template slot="append">/ {{ form.total_quest }}</template> -->
                        </el-input>
                        </el-form-item>
                    </el-col>
                </el-col>
            </el-row>
        </el-form>
        <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
    </div>
</template>
<script>
import TestActions from "../components/TestActions";
import { rules, filterResults, createResults } from "../constants";

export default {
  name: "Aged",
  props: ["loading", "patientId"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 89,
      },
      result: {},
      aResults: createResults(1, 20),
      testRules: rules(19),
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$emit("submitTest", this.form, this.result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error",
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map((k) => {
          if (this.result[k] == undefined){
              this.$delete(this.result, k);
            } else {
            this.result[k] = null;
        }
    });
    },
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
  },
  watch: {
    result: {
      handler: function () {
        var self = this;
        var result = _.pickBy(this.result, function (value, key) {
            return !self.aResults.includes(key);
        });
        this.form.total = _.reduce(result, function (result = 0, value, key) {
            if(value && _.isNumber(value)){
                return result + value;
            } else {
                return result;
            }
        })
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.radio-label {
  white-space: normal;
}

.radio-label_text {
  margin-left: 20px;
}
.el-form-item {
  padding: 1rem;
  border: 1px solid transparent;
}
.el-form-item.is-error {
  border: 1px solid #f56c6c;
}
.form-item-blue {
  background-color: #f3f7fa;
}
</style>

