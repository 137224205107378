<script>
export default {
  name: "SelectMonth",
  inheritAttrs: false,
  props: ["value", "defaultValue"],
  data() {
    return {
      filter: {},
      pickerOptions: {
        shortcuts: [
          {
            text: "Mese in corso",
            onClick: (picker) => {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "Annulla",
            onClick: (picker) => {
              picker.$emit("pick", null);
            },
          },
        ],
      },
    };
  },
  methods: {
    updateFilter(data) {
      this.filter.value = data;
      this.$emit("input", data);
    },
  },
  created() {
    if (this.defaultValue) {
      this.filter.value = this.defaultValue;
    }
  },
};
</script>

<template>
  <div>
    <span>Mese/Anno</span>
    <el-date-picker
      v-bind="$attrs"
      v-model="filter.value"
      @input="updateFilter"
      size="small"
      type="month"
      format="MM/yyyy"
      value-format="yyyy-MM-dd"
      :picker-options="pickerOptions"
      :default-value="defaultValue"
    >
    </el-date-picker>
  </div>
</template>

<style lang="scss" scoped>
</style>