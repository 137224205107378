<template>
  <md-empty-state
    md-rounded
    md-label="Errore 403"
    md-description="Non hai i permessi per accedere a questa pagina">
    <font-awesome-icon icon="exclamation-triangle" size="2x"/>
  </md-empty-state>
</template>

<script>
  export default {
    name: 'Forbidden',
  }
</script>
<style lang="scss" scoped>
  .md-empty-state{
    margin-top:25px
  }
</style>