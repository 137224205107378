<template>
  <el-row style="margin-top: 0px; font-size: 12px">
    <h4 class="title">DATI TRATTAMENTO</h4>
    <el-col :span="12">
      <el-row>
        <el-col
          ><span style="font-weight: bold">Identificativo Trattamento</span>:
          <span>{{ _.get(data, 'anno_ricovero') }}{{ _.padStart(_.get(data, 'numero_pratica'), 6, '0') }}</span></el-col
        >
        <el-col
          ><span style="font-weight: bold">Data Inizio Trattamento</span>:
          <span>{{ _.get(data, 'clean_ammissione') }}</span></el-col
        >
        <el-col
          ><span style="font-weight: bold">Data Fine Trattamento</span>:
          <span>{{ _.get(data, 'clean_dimissione') }}</span></el-col
        >
        <el-col
          ><span style="font-weight: bold">Tipologia Assistenza</span>:
          <span>{{ config['tipologia_assistenza'][_.get(data, 'tipo_assistenza')] }}</span></el-col
        >
      </el-row>
    </el-col>
    <el-col :span="12">
      <el-row>
        <el-col
          ><span style="font-weight: bold">Codice Regionale Struttura</span>:
          <span>{{ _.get(data, 'clean_ats.cod_asl') + _.get(data, 'codice_struttura') }}</span></el-col
        >
        <el-col
          ><span style="font-weight: bold">Causale Chiusura Trattamento</span>:
          <span>{{ config['causale_dimissione_definitiva'][_.get(data, 'causa_dimissione')] }}</span></el-col
        >
        <el-col
          ><span style="font-weight: bold">Diagnosi Causa Morte</span>:
          <span
            >{{ _.get(data, 'clean_diagnosi_dimissione.codice') }} -
            {{ _.get(data, 'clean_diagnosi_dimissione.descrizione') }}</span
          ></el-col
        >
        <el-col
          ><span style="font-weight: bold">Data Ultima Modifica</span>: <span>{{ ultimaModifica }}</span></el-col
        >
      </el-row>
    </el-col>

    <el-col :span="12">
      <el-col
        ><span style="font-weight: bold">Provenienza Ospite</span>:
        <span>{{ config['provenienza_ospite'][_.get(data, 'prov_ospite')] }}</span></el-col
      >
      <el-col
        ><span style="font-weight: bold">Codice ATS Appartenenza</span>:
        <span>{{ _.get(data, 'clean_ats_residenza.desc_asl') }}</span></el-col
      >
      <el-col
        ><span style="font-weight: bold">Codice ATS Provenienza</span>:
        <span>{{ _.get(data, 'clean_ats_provenienza.desc_asl') }}</span></el-col
      >
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'DatiTrattamento',
  props: ['data'],
  data() {
    return {};
  },
  computed: {
    config() {
      return this.$store.state.config.sosia;
    },
    ultimaModifica() {
      return (
        _.get(_.head(_.get(_.head(this.data.valutazioni), 'convalide')), 'clean_inizio') ||
        _.get(_.head(this.data.valutazioni), 'clean_inizio')
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  border: 0.5px solid #9c9fa5;
  padding: 2px;
  border-radius: 2px;
}
.key {
  font-weight: bold;
}
</style>