<template>
  <div>
    <arki-filters
      ref="filters"
      @filtered="fetch"
      target="prescriptedProtections"
      :dateRangeFilter="false"
      :optionsFilter="true"
      :patientSpan="12"
    />
    <arki-check-dialog
      @success="update"
      class="fixed-check-button"
      buttonType="primary"
      icon="check"
      :title="'Applica Contenzioni'"
      :action="'protections'"
      :rules="rules"
      option="protections"
      method="patch"
      :selection="selection"
      v-if="selected"
    />
    <arki-broadcast
      ref="broadcast"
      listen=".protection.created"
      @update="update"
    />
    <div class="block with-search-margin">
      <el-table
        :class="paginateTable"
        :data="protections.data"
        :default-sort="{ prop: 'full_name', order: 'ascending' }"
        style="width: 100%"
        :row-class-name="rowClass"
        v-loading="loading"
        @selection-change="handleSelection"
        :max-height="maxHeight"
      >
        <el-table-column
          type="selection"
          width="50"
          :selectable="isSelectable"
        ></el-table-column>
        <el-table-column type="expand" width="30">
          <template slot-scope="scope">
            <el-col :span="12">
              <b>Inserimento:</b> {{ scope.row.full_creator }} -
              {{ scope.row.created_at_time }}
            </el-col>
            <el-col :span="12" v-if="scope.row.full_verification_operator">
              <b v-if="scope.row.enabled == 1">Verifica:</b>
              <b v-else-if="scope.row.enabled == 0">Annullamento:</b>
              {{ scope.row.full_verification_operator }} -
              {{ scope.row.clean_verification_time }}
              {{ scope.row.clean_verification_date }}
              {{ scope.row.removed_at_time }}
            </el-col>
            <el-col :span="12" v-if="scope.row.verification_notes">
              <b>Note Verifica:</b> {{ scope.row.verification_notes }}
            </el-col>
          </template>
        </el-table-column>
        <el-table-column
          prop="full_patient"
          label="Ospite"
          width="230"
        ></el-table-column>
        <el-table-column
          prop="indication"
          label="Indicazione"
          sortable
          min-width="230"
        ></el-table-column>
        <el-table-column
          prop="alternative_practices"
          label="Pratiche alternative testate"
          min-width="230"
        ></el-table-column>
        <el-table-column
          prop="type_name"
          label="Categoria"
          min-width="230"
        ></el-table-column>
        <el-table-column
          prop="type_description"
          label="Tipologia"
          min-width="190"
        ></el-table-column>
      </el-table>
      <el-pagination
        class="paginate"
        background
        layout="prev, pager, next"
        :page-size="protections.per_page"
        :total="protections.total"
        :hide-on-single-page="true"
        @current-change="changePage"
        :disabled="!pagination"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import ArkiFilters from "@/js/components/ArkiFilters";
import ArkiCreateDialog from "@/js/components/ArkiCreateDialog";
import ArkiCheckDialog from "@/js/components/ArkiCheckDialog";
import ArkiBroadcast from "@/js/components/ArkiBroadcast";

import Protection from "@/js/api/services/Protection";
export default {
  name: "PrescriptedProtections",
  components: {
    ArkiFilters,
    ArkiCreateDialog,
    ArkiCheckDialog,
    ArkiBroadcast,
  },
  data() {
    return {
      loading: true,
      activeTab: "verifications",
      protections: {
        data: [],
        per_page: null,
        total: null,
      },
      protection_types: [],
      current_page: 1,
      pagination: true,
      selected: false,
      selection: [],
      form: {
        date: new Date(),
        time: new Date(),
      },
      rules: {
        time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
        date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
        description: [
          { required: false, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        verification_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
      },

      paginateTable: null,
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 235;
    },
  },
  watch: {
    protections: function () {
      this.protections.total / this.protections.per_page > 1
        ? (this.paginateTable = "paginate-table")
        : (this.paginateTable = null);
    },
  },
  methods: {
    async init() {
      const response = await Protection.settings();
      this.protection_types = response.data;
    },
    update() {
      if (this.$refs.filters) {
        this.$refs.filters.emit();
        this.$refs.broadcast.reset();
      }
    },
    async fetch(filters) {
      this.loading = true;
      let params = {
        patient_id: filters.patient_id,
        prescripted_protections_filters: filters.option,
        prescripted: 1,
        page: this.current_page,
      };
      const response = await Protection.fetch(params);
      this.protections = response.data.protections;
      this.loading = false;
    },
    changePage(page) {
      this.loading = true;
      this.current_page = page;
      this.update();
    },
    handleSelection(val) {
      this.selected = val.length > 0;
      this.selection = val;
    },
    rowClass({ row, rowIndex }) {
      if (row.has_active_verification) {
        return "primary-row";
      }
      return "";
    },
    selectableTime() {
      var endTime = new moment();
      var startTime = moment(endTime).add(-4, "hours").format("HH:mm:ss");
      return startTime + " - " + endTime.format("HH:mm:ss");
    },
    isSelectable(row, rowIndex) {
      return !row.has_active_verification;
    },
  },
  mounted() {
    this.init();
  },
};
</script>
