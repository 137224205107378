<template>
  <div>
    <el-upload
      drag
      :action="`/api/drops/${profile_id}`"
      :auto-upload="true"
      :before-upload="beforeUpload"
      :on-success="fetch"
      multiple
      :limit="5"
      :show-file-list="false"
      :with-credentials="true"
      :headers="uploadHeaders"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">Trascina i files oppure <em>clicca</em></div>
    </el-upload>
    <el-table
      :data="fileList.data"
      style="width: 100%"
      :fit="true"
      v-loading="loading"
      :row-class-name="rowClass"
    >
      <el-table-column prop="file_name" label="File"> </el-table-column>
      <el-table-column
        prop="exportable"
        label="Export Ospedalizzazione"
        width="140"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-checkbox
            :key="scope.row.id"
            @change="toggleExport($event, scope.row)"
            :checked="Boolean(scope.row.exportable)"
            :disabled="!Boolean(scope.row.enabled)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="120">
        <template slot-scope="scope">
          <el-button-group v-if="scope.row.enabled == 1">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-download"
              plain
              @click="download(scope.row.id)"
              :loading="downloadLoading"
            ></el-button>
            <el-button
              type="danger"
              size="small"
              icon="el-icon-delete"
              plain
              @click="deleteFile(scope.row.id)"
            ></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :page-size="fileList.per_page"
      :total="fileList.total"
      :hide-on-single-page="true"
      @current-change="changePage"
    >
    </el-pagination>
  </div>
</template>
<script>
import Drop from "@/js/api/services/Drop";
export default {
  name: "Drop",
  props: ["profile_id"],
  data() {
    return {
      fileList: {
        data: [],
        per_page: 1,
        total: null,
      },
      current_page: 1,
      typeList: [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/msword",
        "application/gzip",
        "application/vnd.oasis.opendocument.presentation",
        "application/vnd.oasis.opendocument.spreadsheet",
        "application/vnd.oasis.opendocument.text",
        "application/vnd.ms-powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/vnd.rar",
        "application/rtf",
        "application/x-tar",
        "text/plain",
        "application/zip",
        "application/x-7z-compressed",
      ],
      loading: true,
      downloadLoading: false,
      uploadHeaders: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        Authorization: `Bearer ${localStorage.getItem("arkicare_session")}`,
      },
    };
  },
  methods: {
    async fetch() {
      this.loading = true;
      let params = {
        page: this.current_page,
        patient_id: this.profile_id,
      };
      const response = await Drop.fetch(params);
      this.fileList = response.data;
      this.loading = false;
    },
    changePage(page) {
      this.current_page = page;
      this.fetch();
    },
    deleteFile(id) {
      this.$confirm(`Rimuovere il File selezionato?`, "Attenzione", {
        confirmButtonText: "OK",
        cancelButtonText: "Annulla",
        type: "warning",
      })
        .then(() => {
          this.destroy(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        });
    },
    async destroy(id) {
      await Drop.destroy(id);
      this.fetch();
    },
    async download(id) {
      this.downloadLoading = true;
      const response = await Drop.download(id);
      let title = response.headers.name;
      this.file(response, title);
    },
    file(response, title) {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", title);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.downloadLoading = false;
    },
    handleExceed(files) {
      this.$message.warning(
        `The limit is 3, you selected ${files.length} files this time`
      );
    },
    beforeUpload(file) {
      const accettable = this.typeList.includes(file.type);
      const isLt50M = file.size / 1024 / 1024 < 50;

      if (!accettable) {
        this.$message.error("Formato del File non supportato!");
      }
      if (!isLt50M) {
        this.$message.error("La dimensione del file supera il limite di 50MB!");
      }
      return accettable && isLt50M;
    },
    rowClass({ row, rowIndex }) {
      if (row.enabled == 0) {
        return "deleted-row";
      }
    },
    toggleExport(val, row) {
      Drop.toggleExportFlag(row.id, val);
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>