<template>
  <div>
    <md-dialog :md-active.sync="visible">
      <md-dialog-title>Inserisci il codice di verifica</md-dialog-title>
      <md-dialog-content>
        <el-form
          label-position="right"
          label-width="80px"
          :model="form"
          :rules="rules"
          ref="form"
        >
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="Codice" prop="code">
                <el-input
                  placeholder="Codice"
                  v-model="form.code"
                  class="input-with-select"
                >
                  <el-button
                    type="primary"
                    plain
                    slot="append"
                    :disabled="isDisabled"
                    @click.native.prevent="renewCode()"
                    icon="el-icon-refresh"
                    >Rigenera codice</el-button
                  >
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24" :offset="3">
              <md-progress-spinner
                v-if="renewCodeLoader"
                :md-diameter="15"
                :md-stroke="3"
                md-mode="indeterminate"
              ></md-progress-spinner>
              <span v-if="resendConfirm">Nuovo codice inviato!</span>
            </el-col>
          </el-row>
        </el-form>
      </md-dialog-content>
      <md-dialog-actions>
        <el-button type="info" plain @click="close()" style="margin-right: 5px"
          >Annulla</el-button
        >
        <el-button
          type="success"
          plain
          @click.native.prevent="submit()"
          :loading="loading"
          >Verifica</el-button
        >
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import Therapy from "@/js/api/services/Therapy";
import Auth from "@/js/api/services/Auth";
import store from "@/js/core/store";

export default {
  name: "ArkicheckTwoFactor",
  props: {
    prescriptions: Array,
    updates: Array,
    profile_id: String,
  },
  data() {
    return {
      visible: false,
      loading: false,
      renewCodeLoader: false,
      resendConfirm: false,
      renewCodeShow: true,
      isDisabled: false,
      form: {},
      rules: {
        code: [
          { required: true, message: "Inserire un codice", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.store();
        } else {
          return false;
        }
      });
    },
    async store() {
      this.loading = true;
      let params = {
        code: this.form.code,
        toCreate: this.prescriptions,
        toUpdate: this.updates,
        patient_id: this.profile_id,
      };
      const response = await Therapy.upsert(params);
      if (response.data.verification_status == "OK") {
        this.success();
        this.$root.$emit("therapies_upserted");
      } else {
        this.error(response.data);
      }
    },
    success() {
      this.$emit("success");
      this.close();
    },
    /* Add your exceptions */
    errorsMap(error_type) {
      return {
        "Predis\\Connection\\ConnectionException":
          "Errore di connessione Redis",
      }[error_type];
    },
    error({ error_type, error_message }) {
      const message = this.errorsMap(error_type) || error_message;
      this.$store.commit("setResponse", {
        response: {
          status: 400,
          statusText: message,
        },
      });
      this.$store.commit("setError", true);
      this.$store.commit("setSuccess", false);
      this.close();
    },
    close() {
      this.form = {};
      this.loading = false;
      this.visible = false;
    },
    async renewCode() {
      this.isDisabled = true;
      this.renewCodeShow = false;
      this.renewCodeLoader = true;
      await Auth.reGenerateTwoFactorCode()
        .then((res) => {
          this.renewCodeLoader = false;
          this.renewCodeShow = true;
          this.resendConfirm = true;
          this.isDisabled = false;
          setTimeout(() => (this.resendConfirm = false), 30000);
        })
        .catch((error) => {
          this.isDisabled = false;
          this.renewCodeLoader = false;
        });
    },
  },
};
</script>
