import Api from '@/js/api/Api';

export default class SuperadminOperatorsService {

    static endpoint = 'superadmin/operators';

    // static getOperators() {
    //     return Api.get(`${this.endpoint}`);
    // }

    static getOperators(params) {
        return Api.get(this.endpoint, {
            params: params
        });
    }

    static getOperator(id) {
        return Api.get(`${this.endpoint}/${id}`);
    }

    static createOperator() {
        return Api.get(`${this.endpoint}/insert`);
    }

    static updateOperator(id, params) {
        return Api.patch(`${this.endpoint}/update/${id}`, params);
    }

    static destroyOperator(id) {
        return Api.delete(`${this.endpoint}/${id}`);
    }

    static restoreOperator(id) {
        return Api.post(`${this.endpoint}/restore/${id}`);
    }
}
