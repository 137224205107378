import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class CriticalEvent extends ArkiBaseService {

    static endpoint = 'critical-events';

    static getChartData(params) {
        return Api.get(`${this.endpoint}/chart-data`, {
            params: params
        })
    }

    // static show(id){
    //     return Api.get(`${this.endpoint}/${id}`);
    // }

    // static store(id, params){
    //     return Api.patch(`${this.endpoint}/${id}`, params);
    // }
}
