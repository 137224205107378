import _ from 'lodash';

const rules = (numberOfQuestions) => {
    let defaultRule = { required: true, message: "Campo Obbligatorio", trigger: "change" };
    return _.range(numberOfQuestions)
        .reduce((acc, next, idx) => {
            idx++;
            acc[`q${idx}_result`] = defaultRule;
            return acc;
        }, {})
}

const filterResults = (data, specific) => {
    if (specific) {
        return _.pickBy(data, (_, key) => specific.includes(key));
    }
    return _.pickBy(data, (_, key) => key.endsWith("result"));
}


const categories = [
    {
        name: 'Scelga tra questi termini quello che si avvicina di più al suo dolore',
        result: 'result',
        options: [
            {
                label: 'Il più intenso immaginabile',
                points: 6
            },
            {
                label: 'Dolore molto grave',
                points: 5
            },
            {
                label: 'Grave',
                points: 4
            },
            {
                label: 'Moderato',
                points: 3
            },
            {
                label: 'Lieve',
                points: 3
            },
            {
                label: 'Leggero',
                points: 1
            },
            {
                label: 'Nessuno',
                points: 0
            },
        ]
    },
]


export default { rules, filterResults, categories };
