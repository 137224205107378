<template>
  <el-row v-if="!create" :gutter="20" class="no-margin-row">
    <el-col :span="11">
      <el-date-picker
        v-model="filters.dateRange"
        type="daterange"
        range-separator="-"
        start-placeholder="Data inizio"
        end-placeholder="Data fine"
        format="dd MMMM"
        value-format="yyyy-MM-dd"
        :picker-options="pickerOptions"
        :editable="false"
        :clearable="false"
        @change="fetch()"
      >
      </el-date-picker>
    </el-col>

    <el-col :span="11">
      <el-select
        v-model="filters.option"
        placeholder="Opzioni"
        clearable
        @change="fetch()"
      >
        <el-option
          v-for="item in eventSelects"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>

    <el-col :span="2">
      <el-button
        type="success"
        icon="el-icon-plus"
        round
        @click="
          () => {
            this.create = true;
          }
        "
      ></el-button>
    </el-col>

    <div
      class="block with-filters"
      style="margin-top: 50px; overflow-y: hidden"
    >
      <el-table
        :class="paginateTable"
        :data="criticalEvents.data"
        :default-sort="{ prop: 'time', order: 'descending' }"
        style="width: 100%; overflow-y: hidden"
        :row-class-name="rowClass"
        v-loading="loading"
        :max-height="maxHeight"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-col :xl="12" :lg="12" :md="24">
              <el-col :span="24">
                <b>Inserimento:</b>
                {{ scope.row.full_creator }} - {{ scope.row.created_at_time }}
              </el-col>
              <!-- <el-col :span="24" v-if="scope.row.full_verification_operator">
                                <b>Verifica:</b>
                                {{ scope.row.full_verification_operator }} -
                                {{ scope.row.resolution_date }}
                            </el-col> -->
            </el-col>
          </template>
        </el-table-column>
        <el-table-column
          prop="full_patient"
          label="Ospite"
          width="110"
          fixed="left"
        >
        </el-table-column>
        <el-table-column label="Tipo" width="100">
          <template slot-scope="scope">{{
            scope.row.event_type_label
          }}</template>
        </el-table-column>
        <el-table-column label="Descrizione" min-width="250">
          <template slot-scope="scope">{{ scope.row.description }}</template>
        </el-table-column>
        <el-table-column
          prop="clean_date_time"
          label="Data e Ora"
          sortable
          align="center"
          width="170"
        >
        </el-table-column>
        <el-table-column
          prop="clean_resolution_date"
          label="Risoluzione"
          sortable
          align="center"
          width="170"
        >
        </el-table-column>
        <el-table-column align="right" width="80" fixed="right">
          <template slot-scope="scope">
            <el-button
              icon="el-icon-delete"
              type="danger"
              plain
              @click="destroy(scope.row.id, scope.row.description)"
              v-if="scope.row.creator_id == user.id && scope.row.enabled == 1"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="criticalEvents.per_page"
        :total="criticalEvents.total"
        :hide-on-single-page="true"
        :current-page="current_page"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </el-row>
  <el-row v-else-if="create" :gutter="20" class="no-margin-row">
    <el-col :span="24">
      <el-form
        label-position="top"
        label-width="80px"
        :model="form"
        :rules="rules"
        ref="form"
      >
        <el-row class="form-header" :gutter="20">
          <el-col :span="8">
            <el-form-item prop="date">
              <el-date-picker
                v-model="form.date"
                :editable="false"
                type="date"
                placeholder="Scegli data"
                :picker-options="pickerOptions"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="time">
              <el-time-picker
                v-model="form.time"
                :editable="false"
                format="HH:mm"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="Seleziona Orario"
              ></el-time-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="text-align: right">
            <el-button
              icon="el-icon-back"
              round
              @click="
                () => {
                  this.create = false;
                }
              "
              >Indietro</el-button
            >
          </el-col>
        </el-row>
        <div class="block with-form-header" v-loading="loading">
          <el-row :gutter="20" v-if="form.patient_id">
            <el-col :span="12">
              <el-form-item label="Tipo di evento" prop="event_type">
                <el-select v-model="form.event_type" filterable clearable>
                  <el-option
                    v-for="item in eventSelects"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>

            <el-col :span="24">
              <el-form-item label="Descrizione" prop="description">
                <el-input type="textarea" v-model="form.description"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="Suggerimenti">
                <el-input
                  type="textarea"
                  v-model="form.improvements"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>

            <el-col :span="8">
              <el-form-item label="Evento Risolto" prop="resolved">
                <el-radio
                  v-model="form.resolved"
                  :label="1"
                  border
                  size="large"
                  @change="handleResolvedChange()"
                  >Risolto
                </el-radio>
                <el-radio
                  v-model="form.resolved"
                  :label="2"
                  border
                  size="large"
                  @change="handleResolvedChange()"
                  >Non risolto
                </el-radio>
              </el-form-item>
            </el-col>

            <el-col :span="8" v-if="form.resolved == 1">
              <el-form-item label="Data" prop="resolved_date">
                <el-date-picker
                  v-model="form.resolved_date"
                  :editable="false"
                  type="date"
                  :picker-options="pickerOptions"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="form.resolved == 1">
              <el-form-item label="Orario" prop="resolved_time">
                <el-time-picker
                  v-model="form.resolved_time"
                  format="HH:mm"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :editable="false"
                ></el-time-picker>
              </el-form-item>
            </el-col>

            <el-col :span="24" class="margin-top">
              <el-button
                size="large"
                type="success"
                @click="submit()"
                class="float-right fixed-confirm-button"
                plain
                :loading="submitLoading"
                >Conferma</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
import moment from "moment";
import CriticalEvent from "@/js/api/services/CriticalEvent";
import Settings from "@/js/api/services/Settings";

export default {
  name: "CriticalEvents",
  props: ["profile_id"],
  data() {
    return {
      filters: {
        dateRange: [new Date(), new Date()],
        date: new Date(),
        option: [],
        patient_id: null,
        role: null,
        criticals: null,
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      create: false,
      loading: true,
      submitLoading: false,
      showDialog: false,
      showConfirmDialog: false,
      eventSelects: [],

      criticalEvents: {
        data: [],
        per_page: null,
        total: null,
      },
      unchangedFilter: false,
      current_page: 1,
      paginateTable: null,
      form: {
        date: new Date(),
        time: new Date(),
        resolved_date: new Date(),
        resolved_time: new Date(),
      },
      rules: {
        time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
        date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
        event_type: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        description: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        resolved_date: [
          { required: false, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        resolved_time: [
          { required: false, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        resolved: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
      },
      formConfirm: {},
      rulesConfirm: {
        type: [
          {
            required: true,
            message: "Campo Obbligatorio",
            trigger: "blur",
          },
        ],
        value: [
          {
            required: true,
            message: "Campo Obbligatorio",
            trigger: "blur",
          },
        ],
      },
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    maxHeight() {
      return window.innerHeight - 245;
    },
  },
  watch: {
    criticalEvents: {
      handler: function () {
        this.criticalEvents.total / this.criticalEvents.per_page > 1
          ? (this.paginateTable = "paginate-table")
          : (this.paginateTable = null);
      },
      deep: true,
    },
  },
  methods: {
    update() {
      this.unchangedFilter = true;
      this.fetch();
      // this.$refs.broadcast.reset();
    },
    async init() {
      const response = await Settings.fetch("criticalEvents");
      this.eventSelects = response.data;
      this.filters.patient_id = this.profile_id;
      this.form.patient_id = this.profile_id;
      this.fetch();
    },
    async fetch() {
      if (this.filters.patient_id != this.$route.query.patient_id) {
        this.$router.replace({
          path: this.$route.path,
          query: {
            patient_id: this.filters.patient_id || undefined,
            t: new Date().getTime(),
          },
        });
        return;
      }
      // need to reset page in case filter change
      if (!this.unchangedFilter) {
        this.current_page = 1;
      }
      this.loading = true;
      let params = {
        date_from: moment(this.filters.dateRange[0]).format("DD/MM/YYYY"),
        date_to: moment(this.filters.dateRange[1]).format("DD/MM/YYYY"),
        patient_id: this.filters.patient_id,
        event_type: this.filters.option,
        page: this.current_page,
      };
      const response = await CriticalEvent.fetch(params);
      this.criticalEvents.data = response.data.criticalEvents.data;
      this.criticalEvents.total = response.data.criticalEvents.total;
      this.criticalEvents.per_page = response.data.criticalEvents.per_page;
      this.loading = false;
      // reset the checked flag
      this.unchangedFilter = false;
    },
    changePage(page) {
      this.loading = true;
      this.current_page = page;
      this.update();
    },
    destroy(id, description) {
      this.$confirm(`Annullare Evento Critico ${description} ?`, "Attenzione", {
        confirmButtonText: "OK",
        cancelButtonText: "Annulla",
        type: "warning",
      })
        .then(() => {
          this.delete(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        });
    },
    async delete(id) {
      await CriticalEvent.destroy(id);
      this.update();
    },
    rowClass({ row, rowIndex }) {
      if (row.enabled == 0) {
        return "deleted-row";
      } else if (row.verification_result == 2) {
        return "warning-row";
      } else if (row.verification_result == 1) {
        return "success-row";
      }
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$confirm("Confermare valutazione?", "Attenzione", {
            confirmButtonText: "Sì",
            cancelButtonText: "Annulla",
            type: "success",
          })
            .then(() => {
              this.submitLoading = true;
              this.store();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "Operazione annullata",
              });
            });
        } else {
          return false;
        }
      });
    },
    async store() {
      let params = {
        date: moment(this.form.date).format("YYYY-MM-DD"),
        time: moment(this.form.time).format("HH:mm:ss"),
        resolved: this.form.resolved,
        resolved_date: moment(this.form.resolved_date).format("YYYY-MM-DD"),
        resolved_time: moment(this.form.resolved_time).format("HH:mm:ss"),
        data: this.form,
      };
      try {
        await CriticalEvent.store(params);
        if (this.upload) {
          this.$refs.uploader.submit();
        }
        // this.$router.push({ name: "Eventi Critici" });
        this.update();
        this.create = false;
      } catch (error) {
        this.submitLoading = false;
      }
    },
    handleResolvedChange() {
      if (this.form.resolved == 1) {
        this.rules.resolved_date[0].required = true;
        this.rules.resolved_time[0].required = true;
      } else {
        this.rules.resolved_date[0].required = false;
        this.rules.resolved_time[0].required = false;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.el-table__body-wrapper {
  overflow-y: hidden;
}
</style>
