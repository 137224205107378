<template>
	<el-row :gutter="20" style="padding: 10px">
		<el-col :span="11" style="line-height: 40px">
			Creata da {{ test.full_creator_test }} il
			{{ test.created_at_time_test }}
		</el-col>
		<el-col :span="2" v-if="test.enabled == 1">
			<el-button
				@click="print()"
				class="text-center"
				type="success"
				round
			>
				Stampa
			</el-button>
		</el-col>
		<el-col :span="11" v-if="test.enabled == 1">
			<el-button
				@click="destroy()"
				class="float-right"
				type="warning"
				round
				:disabled="test.creator_id != user.id"
			>
				Annulla scala
			</el-button>
		</el-col>
		<el-col
			:span="12"
			v-else-if="test.enabled != 1"
			style="line-height: 40px; text-align: right; color: #f56c6c"
			class="float-right"
		>
			Annullata da {{ test.full_remover_test }} il
			{{ test.removed_at_time_test }}
		</el-col>
	</el-row>
</template>

<script>
import Tests from "@/js/api/services/Test";

export default {
	name: "TestsDisable",
	props: ["test", "test_model"],
	data() {
		return {
			user: this.$store.state.user,
		};
	},
	methods: {
		destroy() {
			this.$confirm("Confermi annullamento scala?", "Attenzione", {
				confirmButtonText: "OK",
				cancelButtonText: "Annulla",
				type: "warning",
			})
				.then(() => {
					this.delete();
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "Operazione annullata",
					});
				});
		},
		print() {
			this.$confirm("Confermi export scala?", "Attenzione", {
				confirmButtonText: "OK",
				cancelButtonText: "Annulla",
				type: "warning",
			})
				.then(() => {
					this.generatePdf();
				})
				.catch(() => {
					this.$message({
						type: "info",
						message: "Operazione annullata",
					});
				});
		},
		async delete() {
			let params = {
				test_id: this.test.id,
				test_model: this.test_model,
			};

			await Tests.delete(params);

			this.$emit("showparent", this.test.id);
		},
		async generatePdf() {
			let params = {
				test_id: this.test.id,
				test_model: this.test_model.toLowerCase(),
				patient: this.$route.params.id,
			};
			let response = await Tests.generatePdf(params);
			let title = `Test_${params.test_model}_${this.$store.state.patientName}.pdf`;
			this.file(response, title);

		},
		file(response, title) {
			var fileURL = window.URL.createObjectURL(new Blob([response.data]));
			var fileLink = document.createElement("a");

			fileLink.href = fileURL;
			fileLink.setAttribute("download", title);
			document.body.appendChild(fileLink);
			fileLink.click();

			this.downloadLoading = false;
		},
	},
};
</script>
