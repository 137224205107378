<script>
import SelectAts from '../SelectAts.vue';
import SelectIstat from '../SelectIstat.vue';
import SelectState from '../SelectState.vue';
import SelectCommune from '../SelectCommune.vue';
import SelectDiagnosis from '../SelectDiagnosis.vue';
import SosiaService from '@/js/api/services/Sosia2';

export default {
  name: 'SosiaTreatmentForm',
  props: ['visible', 'record', 'config', 'patientId', 'newRecord'],
  components: { SelectAts, SelectIstat, SelectState, SelectCommune, SelectDiagnosis },
  data() {
    return {
      loading: false,
      form: {
        codice_ats: null,
        codice_struttura: null,
        anno_ricovero: null,
        numero_pratica: null,
        data_ammissione: null,
        classificazione_ospite: null,
        luogo_nascita: null,
        comune_residenza: null,
        codice_ats_residenza: null,
        stato_civile: null,
        cittadinanza: null,
        prov_ospite: null,
        figli_maschi: null,
        figli_femmine: null,
        codice_ats_provenienza: null,
        professione: null,
        situazione_pensionistica: null,
        titolo_studio: null,
        tipo_assistenza: null,
        tipo_ospite: null,
        tipo_posto_letto: null,
        data_dimissione: null,
        causa_dimissione: null,
        diagnosi_dimissione: null
      },
      editMode: Boolean(_.get(this.record, 'id')) && !this.newRecord,
      pickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
        shortcuts: [
          {
            text: 'Annulla',
            onClick(picker) {
              picker.$emit('pick', null);
            }
          }
        ]
      },
      loadingSelect: false,
    };
  },
  computed: {
    rules() {
      return {
        tipo_ospite: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        tipo_posto_letto: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        cittadinanza: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        luogo_nascita: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        comune_residenza: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        stato_civile: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        codice_ats_residenza: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        codice_ats_provenienza: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        prov_ospite: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        situazione_pensionistica: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        titolo_studio: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        figli_maschi: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        figli_femmine: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        tipo_assistenza: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        professione: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        codice_ats: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        codice_struttura: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        anno_ricovero: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        numero_pratica: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        data_ammissione: [{ required: true, message: 'Campo Obbligatorio', trigger: 'change' }],
        causa_dimissione: [
          { required: !_.isNil(this.form.data_dimissione), message: 'Campo Obbligatorio', trigger: 'change' }
        ],
        diagnosi_dimissione: [
          { required: this.form.causa_dimissione == 4, message: 'Campo Obbligatorio', trigger: 'change' }
        ],
        classificazione_ospite: [
          {
            required: ['7', '8', '9', 'A'].includes(this.form.tipo_ospite),
            message: 'Campo Obbligatorio',
            trigger: 'change'
          }
        ]
      };
    },
    showClassificazioneOspite() {
      this.form.classificazione_ospite = null;
      return ['7', '8', '9', 'A'].includes(this.form.tipo_ospite);
    },
    classificazioneOspiteOptions() {
      const all = this.config.classificazione_ospite;
      return _.cond([
        [_.matches({ tipo_ospite: '7' }), _.constant(_.pick(all, ['A', 'B', 'C']))],
        [_.matches({ tipo_ospite: '8' }), _.constant(_.pick(all, ['I', 'L', 'M', 'N', 'O', 'P', 'Q']))],
        [_.matches({ tipo_ospite: '9' }), _.constant(_.pick(all, ['G', 'H']))],
        [_.matches({ tipo_ospite: 'A' }), _.constant(_.pick(all, ['E', 'F']))]
      ])(this.form);
    },
    dimissioneDisabled() {
      return !this.editMode || _.isEmpty(this.record.valutazioni);
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid, errors) => {
        if (!valid) {
          this.$message({
            type: 'error',
            message: `Campi non validi`
          });
          return false;
        }
        this.editMode ? this.update() : this.create();
      });
    },
    save() {
      this.editMode ? this.update() : this.create();
    },
    create() {
      const payload = { ...this.form, patient_id: this.patientId };
      SosiaService.storeTreatment(payload)
        .then(() => this.$root.$emit('fetchPatient'))
        .then(() => this.close());
    },
    update() {
      const payload = { ...this.form, patient_id: this.record.patient_id };
      SosiaService.updateTreatment(this.record.id, payload)
        .then(() => this.$root.$emit('fetchPatient'))
        .then(() => this.close());
    },
    close() {
      this.$emit('update:visible', false);
    },
    generateNumber() {
      const payload = {
        anno_ricovero: this.form.anno_ricovero,
        codice_struttura: this.form.codice_struttura
      };
      SosiaService.generatePracticeNumber(payload).then((res) => {
        this.form.numero_pratica = res.data.value;
        this.$refs['form'].clearValidate();
      });
    },
    numberOnly($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      const notNumber = (keyCode < 48 || keyCode > 57) && keyCode !== 46;
      if (notNumber) {
        $event.preventDefault();
      }
    },
  },
  created() {
    if (this.editMode) {
      const fields = _.pick(this.record, [
        'codice_ats',
        'codice_struttura',
        'numero_pratica',
        'data_ammissione',
        'classificazione_ospite',
        'luogo_nascita',
        'comune_residenza',
        'codice_ats_residenza',
        'stato_civile',
        'cittadinanza',
        'prov_ospite',
        'figli_maschi',
        'figli_femmine',
        'codice_ats_provenienza',
        'professione',
        'situazione_pensionistica',
        'titolo_studio',
        'tipo_assistenza',
        'tipo_ospite',
        'tipo_posto_letto',
        'data_dimissione',
        'causa_dimissione',
        'diagnosi_dimissione'
      ]);
      fields.anno_ricovero = String(this.record.anno_ricovero);
      Object.assign(this.form, fields);
    } else {

    }
  }
};
</script>
<template>
  <el-dialog
    :visible.sync="visible"
    title="Dati Trattamento"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :show-close="false"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    center
    width="80%"
  >
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
          labelPosition="top"
          size="mini"
          :rules="rules"
          v-loading="loading"
          :validate-on-rule-change="false"
        >
          <el-row :gutter="10">
            <el-col :sm="14">
              <el-card shadow="hover">
                <el-row :gutter="10">
                  <el-divider content-position="left">Tipologia e Classificazione</el-divider>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="Tipologia Ospite" prop="tipo_ospite">
                      <el-select v-model="form.tipo_ospite" clearable>
                        <el-option
                          v-for="(item, index) in config.tipologia_ospite"
                          :key="index"
                          :label="item"
                          :value="index"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8" v-if="showClassificazioneOspite">
                    <el-form-item label="Classificazione Ospite" prop="classificazione_ospite">
                      <el-select v-model="form.classificazione_ospite" clearable>
                        <el-option
                          v-for="(item, index) in classificazioneOspiteOptions"
                          :key="index"
                          :label="item"
                          :value="index"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="Tipologia Posto Letto" prop="tipo_posto_letto">
                      <el-select v-model="form.tipo_posto_letto" clearable>
                        <el-option
                          v-for="(item, index) in config.tipologia_posto_letto"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-divider content-position="left">Informazioni</el-divider>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="Cittadinanza" prop="cittadinanza">
                      <select-state v-model="form.cittadinanza" :defaultValue="_.get(record, 'clean_cittadinanza')" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="Comune di nascita" prop="luogo_nascita">
                      <select-istat v-model="form.luogo_nascita" :defaultValue="_.get(record, 'clean_luogo_nascita')" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="Comune di Residenza" prop="comune_residenza">
                      <select-commune v-model="form.comune_residenza" :defaultValue="_.get(record, 'clean_comune_residenza')" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="Stato Civile" prop="stato_civile">
                      <el-select v-model="form.stato_civile" clearable>
                        <el-option v-for="(item, k) in config.stato_civile" :key="k" :label="item" :value="Number(k)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="ATS di residenza" prop="codice_ats_residenza">
                      <select-ats v-model="form.codice_ats_residenza" :defaultValue="_.get(record, 'clean_ats_residenza')" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="ATS di provenienza" prop="codice_ats_provenienza">
                      <select-ats v-model="form.codice_ats_provenienza" :defaultValue="_.get(record, 'clean_ats_provenienza')" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="Provenienza Ospite" prop="prov_ospite">
                      <el-select v-model="form.prov_ospite" clearable>
                        <el-option
                          v-for="(item, k) in config.provenienza_ospite"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="Situazione Pensionistica" prop="situazione_pensionistica">
                      <el-select v-model="form.situazione_pensionistica" clearable>
                        <el-option
                          v-for="(item, index) in config.situazione_pensionistica"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="Titolo di studio" prop="titolo_studio">
                      <el-select v-model="form.titolo_studio" clearable>
                        <el-option
                          v-for="(item, k) in config.titolo_di_studio"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="N°figli Maschi" prop="figli_maschi">
                      <el-input-number
                        v-model="form.figli_maschi"
                        controls-position="right"
                        :min="0"
                        :max="99"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="N° figli Femmine" prop="figli_femmine">
                      <el-input-number
                        v-model="form.figli_femmine"
                        controls-position="right"
                        :min="0"
                        :max="99"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="Tipologia Assistenza" prop="tipo_assistenza">
                      <el-select v-model="form.tipo_assistenza" clearable>
                        <el-option
                          v-for="(item, index) in config.tipologia_assistenza"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="24" :md="12" :lg="8">
                    <el-form-item label="Professione all'ingresso" prop="professione">
                      <el-select v-model="form.professione" clearable>
                        <el-option
                          v-for="(item, index) in config.posizione_nella_professione"
                          :key="index"
                          :label="item"
                          :value="index"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
            <el-col :sm="10">
              <el-card shadow="hover">
                <el-row :gutter="10">
                  <el-divider content-position="left">CPR</el-divider>

                  <el-col :md="24">
                    <el-form-item prop="codice_ats" :required="true">
                      <span slot="label" class="sosiaKey">Codice ATS/ASST</span>
                      <select-ats
                        v-model="form.codice_ats"
                        type="code"
                        :defaultValue="_.get(record, 'clean_ats')"
                      ></select-ats>
                    </el-form-item>
                    <el-form-item prop="codice_struttura">
                      <span slot="label" class="sosiaKey">Codice Struttura RSA</span>
                      <el-input
                        v-model="form.codice_struttura"
                        maxlength="6"
                        minlength="6"
                        show-word-limit
                      ></el-input>
                    </el-form-item>

                    <el-form-item prop="anno_ricovero">
                      <span slot="label" class="sosiaKey">Anno di Ricovero</span>
                      <el-date-picker
                        v-model="form.anno_ricovero"
                        type="year"
                        placeholder="Selezionare Anno"
                        :picker-options="pickerOptions"
                        value-format="yyyy"
                      ></el-date-picker>
                    </el-form-item>
                    <el-form-item prop="num_pratica">
                      <span slot="label" class="sosiaKey">Numero Pratica</span>
                      <el-input
                        @keypress.native="numberOnly"
                        v-model.number="form.numero_pratica"
                        maxlength="6"
                        show-word-limit
                        ><el-button
                          slot="append"
                          icon="el-icon-refresh-right"
                          @click="generateNumber"
                        ></el-button>
                      </el-input>
                    </el-form-item>
                    <el-form-item prop="data_ammissione">
                      <span slot="label" class="sosiaKey">Data di Inserimento</span>
                      <el-date-picker
                        v-model="form.data_ammissione"
                        type="date"
                        placeholder="Selezionare Data"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-divider content-position="left">Dimissione</el-divider>
                <el-row :gutter="10">
                  <el-col :md="24">
                    <el-form-item label="Data dimissione" prop="data_dimissione">
                      <el-date-picker
                        v-model="form.data_dimissione"
                        type="date"
                        placeholder="Selezionare Data"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        :disabled="dimissioneDisabled"
                      ></el-date-picker>
                    </el-form-item>

                    <el-form-item
                      label="Causale Dimissione Definitiva"
                      prop="causa_dimissione"
                      v-if="form.data_dimissione"
                    >
                      <el-select v-model="form.causa_dimissione" clearable>
                        <el-option
                          v-for="(item, index) in config.causale_dimissione_definitiva"
                          :key="index"
                          :label="item"
                          :value="Number(index)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-tooltip placement="bottom" effect="light" v-if="form.causa_dimissione == 4">
                      <div slot="content">
                        Obbligatorio solo se il campo "Causale dimissioni definitive" del file SOSIA1 è uguale a
                        "Deceduto in Struttura".
                      </div>
                      <el-form-item
                        label="Diagnosi Dimissioni Definitive"
                        prop="diagnosi_dimissione"
                        v-if="form.data_dimissione"
                      >
                        <select-diagnosis
                          v-model="form.diagnosi_dimissione"
                          :defaultValue="_.get(record, 'clean_diagnosi_dimissione')"
                          parent="SosiaTreatmentForm"
                        />
                      </el-form-item>
                    </el-tooltip>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" justify="center">
            <el-button class="modal-btn" @click="$emit('update:visible', false)">Annulla</el-button>
            <el-button class="modal-btn" type="primary" @click="onSubmit">Salva</el-button>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<style lang="scss">
.el-form-item {
  margin-bottom: 0.6rem;
  .el-form-item__label {
    padding: 0 !important;
  }
}
.el-divider__text {
  font-weight: bold;
}
.modal-btn {
  width: 15%;
  margin: 10px;
}
</style>