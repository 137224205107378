<script>
import { ActivityFilters } from "./filters";
import ShowActivityEntries from "./dialogs/ShowActivityEntries.vue";
import { PatientActivity } from "@/js/api/services/activity";
import moment from "moment";
import _ from "lodash";
import CreateActivityEntry from "./dialogs/CreateActivityEntry.vue";

export default {
  components: { ActivityFilters, ShowActivityEntries, CreateActivityEntry },
  props: ["patient", "filterPatient", "profile_id"],
  name: "PatientActivities",
  data() {
    return {
      loading: false,
      monthDays: moment().daysInMonth(),
      tableData: [],
      showEntries: false,
      selected: null,
      showCreateEntryDialog: false,
    };
  },
  methods: {
    onFilterChange(params) {
      this.getList(params);
      this.monthDays = moment(
        params.month + "/" + params.year,
        "MM/YYYY"
      ).daysInMonth();
      this.resetTableLayout();
    },
    entrySubmitted(params) {
      if (params.submitted) this.getList(params);
    },
    patientActivitySubmitted(params) {
      if (params.submitted) this.getList(params);
    },
    getPatientId() {
      if (this.profile_id) return this.profile_id;
      if (this.patient) return this.patient.id;
      if (this.$refs.filters) {
        return _.get(this.$refs.filters, "form.patient_id");
      }
    },
    copyPreviousActivities() {
      const filters = this.getFilters();
      this.loading = true;
      const defaultParams = { patient_id: this.getPatientId() };
      const params = Object.assign(defaultParams, filters);
      PatientActivity.replicate(params).then(() => {
        this.getList();
      });
    },
    getList(filters) {
      filters = this.getFilters();
      this.loading = true;
      const defaultParams = { enabled: 1, patient_id: this.getPatientId() };
      const params = Object.assign(defaultParams, filters);
      PatientActivity.list(params)
        .then((data) => {
          this.tableData = data.data;
          if (this.showEntries) this.handleOpenedEntriesDialog();
        })
        .catch((err) => this.onError(err))
        .finally(() => (this.loading = false));
    },
    resetTableLayout() {
      try {
        this.$nextTick(() => {
          this.$refs.activitiesTable.doLayout();
        });
      } catch (error) {
        console.error("Failed to reset table layout.", error.message);
      }
    },
    handleOpenedEntriesDialog() {
      const row = this.tableData.find((row) => row.id == this.selected.row.id);
      if (!row) return;
      this.selected = { row, day: this.selected.day };
    },
    getDayMark({ column, row }) {
      const day = Number(column.label);
      const entries = row.entries.filter((e) => e.day == day && e.enabled);
      return entries.length > 0 ? "x" : "";
    },
    toggleActivity(row) {
      const disable = row.enabled;
      var message = "";
      var showActions = true;
      if (disable && _.filter(row.entries, ["enabled", true]).length > 0) {
        var message = `Partecipazioni attive per quest'attivatà. Annullarle prima di procedere con la sospensione`;
        showActions = false;
      } else {
        var message = `Questo ${
          disable ? "disabiliterà" : "abiliterà"
        } l'attività per il mese selezionato. Continuare?`;
      }

      this.$confirm(message, "Warning", {
        confirmButtonText: "SI",
        cancelButtonText: "NO",
        type: "warning",
        showCancelButton: showActions,
        showConfirmButton: showActions,
      })
        .then(() => {
          disable ? this.doDisable(row) : this.doEnable(row);
        })
        .catch(() => {});
    },
    getFilters() {
      if (this.$refs.filters) {
        return this.$refs.filters.provideFilters();
      }
      return {};
    },
    doDisable(row) {
      PatientActivity.delete(row.id)
        .then(() => this.getList(this.getFilters()))
        .catch((err) => this.onError(err));
    },
    doEnable(row) {
      PatientActivity.enable(row.id)
        .then(() => this.getList(this.getFilters()))
        .catch((err) => this.onError(err));
    },
    onError(err) {
      this.$message({
        type: "error",
        message: err.message,
      });
    },
    updateSpec({ row, spec }) {
      _.set(row, "specification", spec);
    },
    saveSpec({ monthly_frequency, row }) {
      const data = {
        type: "activitySpecification",
        attributes: {
          monthly_frequency,
          patient_activity_id: row.id,
        },
      };
      PatientActivity.createSpecification({ data })
        .then(({ data }) => this.updateSpec({ row, spec: data }))
        .catch((err) => this.onError(err));
    },
    setFrequency(row) {
      this.$prompt("", "Frequenza Mesile", {
        closeOnPressEscape: false,
        center: true,
        roundButton: true,
        closeOnClickModal: false,
        confirmButtonText: "Salva",
        cancelButtonText: "Annulla",
        inputPattern: /^[0-9][0-9]*$/,
        inputValue: _.get(row, "specification.monthly_frequency"),
        inputErrorMessage: "Valore non valido",
      })
        .then(({ value }) => {
          this.saveSpec({ monthly_frequency: value, row });
        })
        .catch(() => {});
    },
    onCellClick(row, col) {
      if (!row.enabled) return;
      const freqClick = col.property == "specification.monthly_frequency";
      if (freqClick) {
        this.setFrequency(row);
        return;
      }
      const day = parseInt(col.label);
      if (isNaN(day)) {
        return;
      }

      this.selected = { row, day };

      if (!this.hasDayEntry({ row, day })) {
        this.showEntryForm();
        return;
      }
      this.showEntries = true;
    },
    closeEntriesDialog() {
      this.selected = null;
      this.showEntries = false;
    },
    hasDayEntry({ row, day }) {
      return (row.entries || []).find((entry) => entry.day == day) || false;
    },
    showEntryForm() {
      this.showCreateEntryDialog = true;
    },
  },
  created() {
    this.getList();
  },
};
</script>
<template>
  <el-container direction="vertical">
    <activity-filters
      :patient="patient"
      @filterChange="onFilterChange"
      @entrySubmitted="entrySubmitted"
      @patientActivitySubmitted="patientActivitySubmitted"
      @copyPreviousActivities="copyPreviousActivities"
      :filterPatient="filterPatient"
      :disabled="loading"
      ref="filters"
    ></activity-filters>
    <create-activity-entry
      :patient="patient"
      :params="selected"
      :patientId="getPatientId()"
      @entrySubmitted="entrySubmitted"
      :visible.sync="showCreateEntryDialog"
    ></create-activity-entry>
    <show-activity-entries
      :visible="showEntries"
      :selected="selected"
      @onClose="closeEntriesDialog"
      @entrySubmitted="entrySubmitted"
    ></show-activity-entries>
    <el-main>
      <el-table
        :data="tableData"
        v-loading="loading"
        style="width: 100%; cursor: pointer"
        ref="activitiesTable"
        border
        @cell-click="onCellClick"
      >
        <el-table-column
          fixed
          prop="activity.description"
          label="Attivita'"
          width="250"
          style="overflow-wrap: break-word"
        >
          <template slot-scope="scope">
            <el-row type="flex" justify="space-between">
              <el-col :span="20"
                ><span>{{ scope.row.activity.description }}</span></el-col
              >
              <el-col :span="4"
                ><el-button
                  @click="toggleActivity(scope.row)"
                  v-if="scope.row.enabled"
                  type="warning"
                  icon="el-icon-close"
                  size="mini"
                  circle
                ></el-button
                ><el-button
                  @click="toggleActivity(scope.row)"
                  v-if="!scope.row.enabled"
                  type="info"
                  icon="el-icon-refresh-left"
                  size="mini"
                  circle
                ></el-button
              ></el-col>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column
          fixed
          prop="specification.monthly_frequency"
          label="Freq."
          width="60"
        >
        </el-table-column>
        <el-table-column
          v-for="day in monthDays"
          :label="`${day}`"
          :key="`day-${day}`"
          :fixed="day == monthDays && 'right'"
          :min-width="40"
        >
          <template slot-scope="scope">
            <span>{{ getDayMark(scope) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-main>
  </el-container>
</template>

<style lang="scss" >
.el-header {
  background-color: #fff;
  color: #333;
  line-height: 60px;
}
</style>
