<template>
  <div style="height: 0">
    <el-button type="info" class="menu-trigger" @click="show()">
      <font-awesome-icon icon="bars" />
    </el-button>
    <el-drawer :visible.sync="isCollapse" direction="ltr" :modal="false">
      <el-menu
        class="el-menu-vertical-demo"
        elevation="2"
        :collapse="false"
        router
      >
        <el-menu-item index="/patients" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="users" />
          </div>
          <span slot="title">Ospiti</span>
        </el-menu-item>
        <el-menu-item index="/diary" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="clipboard" />
          </div>
          <span slot="title">Diario</span>
        </el-menu-item>
        <el-menu-item index="/tasks" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="tasks" />
          </div>
          <span slot="title">Consegne</span>
        </el-menu-item>
        <el-menu-item index="/baths" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="bath" />
          </div>
          <span slot="title">Bagni</span>
        </el-menu-item>
        <el-menu-item index="/purges" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="toilet" />
          </div>
          <span slot="title">Evacuazioni</span>
        </el-menu-item>
        <el-menu-item index="/exams" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="thermometer-half" />
          </div>
          <span slot="title">Rilevamenti</span>
        </el-menu-item>
        <el-submenu index="1">
          <template slot="title">
            <div class="icon-container">
              <font-awesome-icon icon="shield-alt" />
            </div>
            <span slot="title">Contenzioni</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/protections" @click="this.show"
              >Verifiche Contenzioni</el-menu-item
            >
            <el-menu-item index="/protections/evaluated" @click="this.show"
              >Contenzioni Indicate</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item index="/medications" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="user-injured" />
          </div>
          <span slot="title">Medicazioni</span>
        </el-menu-item>
        <el-menu-item index="/critical-events" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="exclamation-triangle" />
          </div>
          <span slot="title">Eventi Critici</span>
        </el-menu-item>
        <el-menu-item
          index="/subministrations"
          @click="this.show"
          v-if="user.is_nurse || user.is_doctor || user.is_chief_nurse"
        >
          <div class="icon-container">
            <font-awesome-icon icon="pills" />
          </div>
          <span slot="title">Somm. Terapie</span>
        </el-menu-item>
        <el-menu-item index="/tests" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="hard-hat" />
          </div>
          <span slot="title">Scale di Valutazione</span>
        </el-menu-item>
        <el-menu-item index="/physiotherapies" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="dumbbell" />
          </div>
          <span slot="title">Fisioterapie</span>
        </el-menu-item>
        <el-menu-item index="/absences" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="calendar-times" />
          </div>
          <span slot="title">Assenze</span>
        </el-menu-item>
        <el-menu-item index="/diets" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="utensils" />
          </div>
          <span slot="title">Diete</span>
        </el-menu-item>
        <el-menu-item index="/mobilizzazioni" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="hard-hat" />
          </div>
          <span slot="title">Mobilizzazioni</span>
        </el-menu-item>
        <el-menu-item index="/activities" @click="this.show">
          <div class="icon-container">
            <font-awesome-icon icon="clipboard" />
          </div>
          <span slot="title">Attivitá</span>
        </el-menu-item>
        <el-submenu index="2">
          <template slot="title">
            <div class="icon-container">
              <font-awesome-icon icon="utensils" />
            </div>
            <span slot="title">Pasti</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/meals" @click="this.show"
              >Monitoraggio Pasti</el-menu-item
            >
            <el-menu-item index="/meals/verification" @click="this.show"
              >Verifica Pasti</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item
          index="/sosia"
          @click="this.show"
          v-if="isLombardia && (user.is_chief_nurse || user.is_doctor)"
        >
          <div class="icon-container">
            <font-awesome-icon icon="clipboard" />
          </div>
          <span slot="title">Sosia</span>
        </el-menu-item>
        <el-menu-item
          index="/cdi"
          @click="this.show"
          v-if="isLombardia && (user.is_chief_nurse || user.is_doctor)"
        >
          <div class="icon-container">
            <font-awesome-icon icon="clipboard" />
          </div>
          <span slot="title">Sosia CDI</span>
        </el-menu-item>
        <el-menu-item @click="downloadGuide()">
          <div class="icon-container">
            <font-awesome-icon icon="file-download" />
          </div>
          <span slot="title">Scarica Guida</span>
        </el-menu-item>
        <el-menu-item index="/comunications"
          @click="this.show">
          <div class="icon-container">
            <i class="el-icon-chat-line-round"></i>
          </div>
          <span slot="title">Comunicazioni</span>
        </el-menu-item>
        <el-submenu index="2" v-if="user.is_admin">
          <template slot="title">
            <div class="icon-container">
              <font-awesome-icon icon="cogs" />
            </div>
            <span slot="title">Gestione</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/settings/absences" @click="this.show"
              >Assenze</el-menu-item
            >
            <el-menu-item index="/settings/patients" @click="this.show"
              >Ospiti</el-menu-item
            >
            <el-menu-item index="/settings/users" @click="this.show"
              >Utenti</el-menu-item
            >
            <!-- why the hell there is a function anly for Codever user | remove this and the connected functionalities asap -->
            <el-menu-item @click="download()" v-if="user.id === 3"
              >Export Utenti CSV</el-menu-item
            >
            <el-menu-item index="/settings/reports" @click="this.show"
              >Reportistica</el-menu-item
            >
            <el-menu-item index="/settings/activities" @click="this.show"
              >Gestione Attività</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
    </el-drawer>
  </div>
</template>
<script>
import Auth from "@/js/api/services/Auth";
import Arkicare from "@/js/api/services/Arkicare";

export default {
  name: "ArkiSidenav",
  data() {
    return {
      isCollapse: false,
      user: this.$store.state.user,
    };
  },
  methods: {
    show() {
      this.isCollapse = !this.isCollapse;
    },
    async download() {
      if (this.user.id === 3) {
        const response = await Auth.patientsExport();
        let title = "pazienti.csv";
        this.file(response, title);
      }
    },
    async downloadGuide() {
      const response = await Arkicare.guideExport();
      let title = "Arkicare_guida.pdf";
      this.file(response, title);
    },
    file(response, title) {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", title);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
  computed: {
    isLombardia() {
      return this.$store.state.isLombardia;
    },
  },
};
</script>
<style lang="scss" scoped>
.md-menu-content-container {
  .md-list {
    padding: 0 15px;
  }
}
.bottomItem {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.logo-horizontal-container {
  position: absolute;
  top: 0;
  background: white;
  width: 100%;
  height: auto;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  img {
    width: 100%;
    height: auto;
  }
}

.icon-container {
  float: left;
  width: 50px;
  text-align: center;
  svg {
    color: grey;
  }
}

a:not(.md-button):hover {
  text-decoration: none;
}
</style>
