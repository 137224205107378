import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Patient extends ArkiBaseService {

    static endpoint = 'patients';

    static all(structure, option) {

        let params = {
            all: structure,
            option: option
        }

        return Api.get(this.endpoint, {
            params: params
        });
    }

    static check(params) {
        return Api.post(`${this.endpoint}/check`, params);
    }

    static critical() {
        return Api.get(`${this.endpoint}/critical`);
    }

    static dismiss(id, params) {
        return Api.post(`${this.endpoint}/${id}/dismiss`, params);
    }

    static getDropFiles(id, params) {
        return Api.get(`${this.endpoint}/${id}/drop`, {
            params: params
        })
    }

    static hospitalExport(id, description) {
        return axios({
            url: `pdf/${this.endpoint}/hospital/${id}`,
            method: 'GET',
            responseType: 'blob',
            params: {
                description: description
            }
        });
    }
    static globalExport(id) {
        return axios({
            url: `pdf/${this.endpoint}/global/${id}`,
            method: 'GET',
            responseType: 'blob',
        });
    }

    static list() {
        return Api.get(`${this.endpoint}/list`);
    }

    static patch(id, params) {
        return Api.patch(`${this.endpoint}/${id}`, params)
    }

    static profile(id) {
        return Api.get(`${this.endpoint}/${id}/profile`);
    }

    static protection(params) {
        return Api.post(`${this.endpoint}/protection`, params);
    }

    static purged() {
        return Api.get(`${this.endpoint}/purge`);
    }

    static registry(id, params) {
        return Api.get(`${this.endpoint}/${id}/registry`, {
            params: params
        });
    }

    static restore(id) {
        return Api.patch(`${this.endpoint}/${id}/restore`);
    }

    static restoreAdmission(id) {
        return Api.patch(`${this.endpoint}/${id}/restoreAdmission`);
    }

    static section(id, section, params) {
        return Api.get(`${this.endpoint}/${id}/section/${section}`, { params: params });
    }

    static therapy() {
        return Api.get(`${this.endpoint}/therapy`)
    }

    static subministrations() {
        return Api.get(`${this.endpoint}/subministrations`)
    }

    static update(id, params) {
        return Api.post(`${this.endpoint}/${id}/update`, params);
    }

    static wound() {
        return Api.get(`${this.endpoint}/wounds`);
    }
    static exportCategories(id, params) {
        return axios({
            url: `pdf/${this.endpoint}/category/${id}`,
            method: 'GET',
            params: params,
            responseType: 'blob',
        });
    }
    static saveDimissions(id, params) {
        return Api.post(`${this.endpoint}/${id}/save_dimissions`, params);
    }
    static exportDimissions(id) {
        return axios({
            url: `${this.endpoint}/${id}/export_dimissions`,
            method: 'POST',
            responseType: 'blob',
        });
    }

    static exampleExport(params) {
        return Api.get(`${this.endpoint}/exampleExport`, { params, responseType: 'blob' })
    }

    static importFile(params) {
        return Api.post(`${this.endpoint}/importFile`, params)
    }

    static structureList(structureId) {
        return Api.get(`${this.endpoint}/structureList/${structureId}`);
    }

    static registryDestroy(id) {
        return Api.delete(`${this.endpoint}/registry/destroy/${id}`);
    }

    static meals() {
        return Api.get(`${this.endpoint}/meals`)
    }
}
