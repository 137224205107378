<script>
import SosiaCdiService from '@/js/api/services/SosiaCdi';
import { cdi2Rules as rules } from './cdiHelper';
import SelectDiagnosis from '../Sosia/SelectDiagnosis.vue';
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'CdiEvaluationForm',
  props: ['config', 'visible', 'patient', 'item', 'cdi1', 'edit', 'create'],
  components: { SelectDiagnosis },
  data() {
    return {
      rules,
      loadingDiagnosi: false,
      loading: false,
      loadingSelect: false,
      form: {},
      domicilioFlag: false,
      pickerOptions: {
        disabledDate(date) {
          return date > new Date();
        }
      }
    };
  },
  computed: {
    editMode() {
      return Boolean(this.edit && this.edit.id);
    },
    evaluationDateOptions() {
      const options = {};
      const data_ammissione = this.cdi1.data_ammissione;
      const insertVal = this.cdi1.valutazioni.find((v) => v.tipo_valutazione == 1 && v.deleted_at == null);
      const disabledDate = (date) => {
        const future = date > new Date();
        let beforeAdmission = moment(date).isBefore(data_ammissione, 'day');
        let afterInsert = false;
        if (insertVal) afterInsert = moment(date).isSameOrBefore(insertVal.data_valutazione, 'day');
        return future || beforeAdmission || afterInsert;
      };
      options.disabledDate = disabledDate;
      options.shortcuts = [
        {
          text: 'Annulla',
          onClick(picker) {
            picker.$emit('pick', null);
          }
        }
      ];
      return options;
    },
    hasEntryEvaluation() {
      if (!this.cdi1) return false;
      return this.cdi1.valutazioni.some((v) => v.tipo_valutazione == 1 && v.deleted_at == null);
    },
    hasExitEvaluation() {
      if (!this.cdi1) return false;
      return this.cdi1.valutazioni.some((v) => v.tipo_valutazione == 4 && v.deleted_at == null);
    }
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
    onSubmit() {
      this.$refs.form.validate((valid, errors) => {
        if (!valid) {
          this.$message({
            type: 'error',
            message: `Campi non validi`
          });
          return false;
        }
        this.editMode ? this.update() : this.store();
      });
    },
    store() {
      this.form = {
        ...this.form,
        cdi1_id: this.cdi1.id,
        patient_id: this.cdi1.patient_id
      };
      SosiaCdiService.storeValutazione(this.form)
        .then(() => this.$emit('stored'))
        .then(() => this.close());
    },
    fetchLast() {
      const loader = this.$loading({
        lock: true,
        text: 'Caricamento in corso',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7) !important'
      });
      SosiaCdiService.lastEvaluationRecord(this.patient.id)
        .then(({ data }) => {
          if (!data.id) return;
          this.form = {
            ...this.form,
            ..._.omit(data, [
              'id',
              'tipo_valutazione',
              'data_valutazione',
              'created_at',
              'deleted_at',
              'enabled',
              'updated_at'
            ])
          };
        })
        .finally(() => loader.close());
    },
    update() {
      const payload = {
        ..._.omit(this.form, [
          'clean_diagn_p',
          'clean_diagn_s1',
          'clean_diagn_s2',
          'clean_diagn_s3',
          'clean_diagn_s4',
          'clean_diagn_s5'
        ]),
        cdi1_id: this.cdi1_id,
        patient_id: this.cdi1.patient_id
      };
      SosiaCdiService.updateEvaluation(this.edit.id, payload)
        .then(() => this.$emit('stored'))
        .then(() => this.close());
    },
    handleCreate() {
      this.form = {};
      this.fetchLast();
    },
    handleEdit() {
      this.form = {
        ..._.omit(this.edit, [
          'id',
          'created_at',
          'deleted_at',
          'enabled',
          'updated_at',
        ])
      };
    },
    evalutationTypeDisabled(k, v) {
      if (k == 1 && this.hasEntryEvaluation) return true;
      else if (k != 1 && !this.hasEntryEvaluation) return true;
      else if (k == 4 && this.hasExitEvaluation) return true;
      else return false;
    }
  },
  created() {
    if (this.create) this.handleCreate();
    else if (this.edit && this.edit.id) this.handleEdit();
  }
};
</script>

<template>
  <el-dialog
    :visible.sync="visible"
    :title="`${editMode ? 'Modifica Valutazione' : 'Nuova Valutazione'}`"
    :modal-append-to-body="false"
    :close-on-press-escape="false"
    :show-close="false"
    :destroy-on-close="true"
    center
    fullscreen
  >
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form
          ref="form"
          :model="form"
          label-width="120px"
          labelPosition="top"
          size="mini"
          :rules="rules"
          v-loading="loading"
        >
          <el-row :gutter="10">
            <el-col :sm="24">
              <el-card shadow="hover">
                <el-row :gutter="10">
                  <el-col :span="12">
                    <el-form-item label="Tipologia di valutazione" prop="tipo_valutazione">
                      <el-select v-model="form.tipo_valutazione" clearable :disabled="Boolean(editMode)">
                        <el-option
                          v-for="(item, k) in config.tipologia_valutazione"
                          :key="k"
                          :label="item"
                          :disabled="evalutationTypeDisabled(k, item)"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Data valutazione" prop="data_valutazione">
                      <el-date-picker
                        v-model="form.data_valutazione"
                        type="date"
                        format="dd/MM/yyyy"
                        value-format="yyyy-MM-dd"
                        :picker-options="evaluationDateOptions"
                        :disabled="Boolean(editMode)"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-divider content-position="left">Bisogni</el-divider>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Ossigenoterapia" prop="ossigenoterapia">
                      <el-select v-model="form.ossigenoterapia" clearable>
                        <el-option
                          v-for="(item, k) in config.valori_assoluti"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Tracheostomia" prop="tracheostomia">
                      <el-select v-model="form.tracheostomia" clearable>
                        <el-option
                          v-for="(item, k) in config.valori_assoluti"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Ventilazione assistita" prop="vent_assistita">
                      <el-select v-model="form.vent_assistita" clearable>
                        <el-option
                          v-for="(item, k) in config.valori_assoluti"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Insufficienza renale" prop="insuff_renale">
                      <el-select v-model="form.insuff_renale" clearable>
                        <el-option
                          v-for="(item, k) in config.valori_assoluti"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-divider content-position="left">Bisogni essenziali</el-divider>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Lesioni da decubito" prop="les_decubito">
                      <el-select v-model="form.les_decubito" clearable>
                        <el-option
                          v-for="(item, k) in config.lesione_decubito"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Lesione cutanee" prop="les_cutanee">
                      <el-select v-model="form.les_cutanee" clearable>
                        <el-option
                          v-for="(item, k) in config.lesione_cutanea"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Gestione incontinenza urinaria" prop="incont_urinaria">
                      <el-select v-model="form.incont_urinaria" clearable>
                        <el-option
                          v-for="(item, k) in config.incontinenza_urinaria"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Gestione incontinenza fecale" prop="incont_fecale">
                      <el-select v-model="form.incont_fecale" clearable>
                        <el-option
                          v-for="(item, k) in config.incontinenza_fecale"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Ausili per movimento" prop="aus_movimento">
                      <el-select v-model="form.aus_movimento" clearable>
                        <el-option
                          v-for="(item, k) in config.ausili_movimento"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Ausili in dotazione" prop="aus_dotazione">
                      <el-select v-model="form.aus_dotazione" clearable>
                        <el-option
                          v-for="(item, k) in config.ausili_dotazione"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Nutrizione artificiale" prop="nut_artificiale">
                      <el-select v-model="form.nut_artificiale" clearable>
                        <el-option
                          v-for="(item, k) in config.nutrizione_artificiale"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Regime alimentare" prop="reg_alimentare">
                      <el-select v-model="form.reg_alimentare" clearable>
                        <el-option
                          v-for="(item, k) in config.valori_assoluti"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-divider content-position="left">Attività della vita quotidiana</el-divider>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Fare il bagno" prop="bagno">
                      <el-select v-model="form.bagno" clearable>
                        <el-option v-for="(item, k) in config.bagno" :key="k" :label="item" :value="Number(k)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Vestirsi" prop="vestirsi">
                      <el-select v-model="form.vestirsi" clearable>
                        <el-option v-for="(item, k) in config.vestirsi" :key="k" :label="item" :value="Number(k)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Toilette" prop="toilette">
                      <el-select v-model="form.toilette" clearable>
                        <el-option v-for="(item, k) in config.toilette" :key="k" :label="item" :value="Number(k)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Spostarsi" prop="spostarsi">
                      <el-select v-model="form.spostarsi" clearable>
                        <el-option v-for="(item, k) in config.spostarsi" :key="k" :label="item" :value="Number(k)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Continenza di feci ed urine" prop="continenza">
                      <el-select v-model="form.continenza" clearable>
                        <el-option v-for="(item, k) in config.continenza" :key="k" :label="item" :value="Number(k)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Alimentazione" prop="alimentazione">
                      <el-select v-model="form.alimentazione" clearable>
                        <el-option v-for="(item, k) in config.alimentazione" :key="k" :label="item" :value="Number(k)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Indicatore ADL" prop="ind_adl">
                      <el-select v-model="form.ind_adl" clearable>
                        <el-option v-for="(item, k) in config.indicatore_adl" :key="k" :label="item" :value="Number(k)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :sm="12" :md="6">
                    <el-divider content-position="left">Attività strumentale della vita quotidiana</el-divider>
                    <el-form-item label="Indicatore IADL" prop="ind_adl">
                      <el-select v-model="form.ind_iadl" clearable>
                        <el-option
                          v-for="(item, k) in config.indicatore_iadl"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-divider content-position="left">Disturbi comportamentali</el-divider>
                    <el-form-item label="Disturbi comportamentali" prop="disturbi_comp">
                      <el-select v-model="form.disturbi_comp" clearable>
                        <el-option
                          v-for="(item, k) in config.valori_assoluti"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-divider content-position="left">Area cognitiva</el-divider>
                    <el-form-item label="Stato cognitivo" prop="stato_cognitivo">
                      <el-select v-model="form.stato_cognitivo" clearable>
                        <el-option
                          v-for="(item, k) in config.stato_cognitivo"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-divider content-position="left">Deambulazione</el-divider>
                    <el-form-item label="Deambulazione - Indice di indipendenza" prop="deambulazione">
                      <el-select v-model="form.deambulazione" clearable>
                        <el-option v-for="(item, k) in config.deambulazione" :key="k" :label="item" :value="Number(k)">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-divider content-position="left">Area sociale</el-divider>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Composizione nucleo famigliare" prop="nucleo_fam">
                      <el-select v-model="form.nucleo_fam" clearable>
                        <el-option
                          v-for="(item, k) in config.nucleo_familiare"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Assistenza formale" prop="ass_form">
                      <el-select v-model="form.ass_form" clearable>
                        <el-option
                          v-for="(item, k) in config.valori_assoluti"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Assistenza informale" prop="ass_inform">
                      <el-select v-model="form.ass_inform" clearable>
                        <el-option
                          v-for="(item, k) in config.valori_assoluti"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Provvidenze economiche" prop="provv_economiche">
                      <el-select v-model="form.provv_economiche" clearable>
                        <el-option
                          v-for="(item, k) in config.valori_assoluti"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Situazione Pensionistica" prop="situazione_pensionistica">
                      <el-select v-model="form.situazione_pensionistica" clearable>
                        <el-option
                          v-for="(item, k) in config.situazione_pensionistica"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Assegno di accompagnamento" prop="assegno_accom">
                      <el-select v-model="form.assegno_accom" clearable>
                        <el-option
                          v-for="(item, k) in config.valori_assoluti"
                          :key="k"
                          :label="item"
                          :value="Number(k)"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-divider content-position="left">Diagnosi</el-divider>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Diagnosi primaria" prop="diagn_p">
                      <select-diagnosis v-model="form.diagn_p" :defaultValue="_.get(form, 'clean_diagn_p')" />
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Diagnosi secondaria" prop="diagn_s1">
                      <select-diagnosis
                        v-model="form.diagn_s1"
                        :defaultValue="_.get(form, 'clean_diagn_s1')"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Diagnosi secondaria" prop="diagn_s2">
                      <select-diagnosis
                        v-model="form.diagn_s2"
                        :defaultValue="_.get(form, 'clean_diagn_s2')"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Diagnosi secondaria" prop="diagn_s3">
                      <select-diagnosis
                        v-model="form.diagn_s3"
                        :defaultValue="_.get(form, 'clean_diagn_s3')"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Diagnosi secondaria" prop="diagn_s4">
                      <select-diagnosis
                        v-model="form.diagn_s4"
                        :defaultValue="_.get(form, 'clean_diagn_s4')"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :md="6">
                    <el-form-item label="Diagnosi secondaria" prop="diagn_s5">
                      <select-diagnosis
                        v-model="form.diagn_s5"
                        :defaultValue="_.get(form, 'clean_diagn_s5')"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <el-row :gutter="10" type="flex" justify="center">
            <el-button @click="$emit('update:visible', false)" class="modal-btn">Annulla</el-button>
            <el-button type="primary" @click="onSubmit" class="modal-btn">Salva</el-button>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<style lang="scss">
.el-form-item {
  margin-bottom: 0.6rem;
  .el-form-item__label {
    padding: 0 !important;
  }
}
.el-divider__text {
  font-weight: bold;
}
</style>
