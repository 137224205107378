<template>
  <div>
    <md-dialog :md-active.sync="dialog">
      <md-dialog-title>Rivalutazione Prescrizioni</md-dialog-title>
      <el-form
        label-position="top"
        label-width="80px"
        :model="form"
        :rules="rules"
        ref="form"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="Prossima Valutazione"
              prop="verification_date"
              required
            >
              <el-date-picker
                v-model="form.verification_date"
                :editable="false"
                type="date"
                placeholder="Scegli data"
                :picker-options="pickerOptions"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <md-dialog-actions>
        <el-button type="info" plain @click="close">Annulla</el-button>
        <el-button
          type="success"
          plain
          @click="updateRecords"
          :loading="submitLoading"
          >Salva</el-button
        >
      </md-dialog-actions>
    </md-dialog>
    <el-divider />

    <!-- buttons -->
    <el-row
      type="flex"
      justify="space-around"
      :gutter="20"
      style="margin-top: 30px"
    >
      <el-col
        :span="6"
        style="display: flex; justify-content: flex-start; max-width: 180px"
      >
        <el-select v-model="past" v-if="!selected">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="18" style="display: flex; justify-content: flex-end">
        <el-button
          size="medium"
          type="primary"
          round
          style="margin: 0 2.5px"
          @click="showAddDialog"
        >
          <i class="el-icon-plus" /> Aggiungi
        </el-button>
        <el-button
          size="medium"
          type="warning"
          round
          style="margin: 0 2.5px"
          @click="clearDraft"
        >
          <i class="el-icon-delete" /> Annulla
        </el-button>
        <el-button
          size="medium"
          type="success"
          round
          style="margin: 0 2.5px"
          @click="saveDraft"
          :loading="loadingButton"
        >
          <i class="el-icon-check" /> Salva
        </el-button>
      </el-col>
    </el-row>
    <el-divider />

    <!-- THERAPY -->
    <div v-if="action == 'therapy'">
      <!-- TERAPIA ABITUDINALE -->
      <el-row>
        <el-col :span="24">
          <el-row type="flex" align="middle">
            <el-col :span="6">
              <el-button
                :disabled="
                  !(
                    parseInt(
                      selection['abitudinaleTherapies'].length +
                        selection['bisognoTherapies'].length +
                        selection['lassativaTherapies'].length
                    ) >= 1
                  )
                "
                size="medium"
                type="info"
                style="margin: 0 2.5px"
                @click="showUpdateDialog"
              >
                <i class="el-icon-edit-outline" /> Rivaluta
              </el-button>
            </el-col>
            <el-col :span="12" style="padding: 0 10px">
              <h2><center>TERAPIA ABITUDINALE</center></h2></el-col
            >
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-table
            :data="records['abitudinaleTherapies']"
            style="width: 100%"
            :cell-class-name="cellClass"
            :header-cell-class-name="cellHeaderClass"
            :span-method="arraySpanMethod"
            v-loading="loading"
            @selection-change="
              handleSelection([...$event], 'abitudinaleTherapies')
            "
            ref="abitudinaleTherapies"
            :row-class-name="rowClass"
          >
            <el-table-column
              type="selection"
              width="50"
              :selectable="selectable"
              fixed
            >
            </el-table-column>
            <el-table-column
              label="Farmaco"
              prop="farmaco_name"
              width="200"
              column-key="farmaco_name"
              sortable
            >
            </el-table-column>
            <el-table-column
              label="Principio Attivo"
              prop="principio_attivo"
              width="150"
              column-key="principio_attivo"
              sortable
            >
            </el-table-column>
            <el-table-column
              label="Q.tà"
              prop="quantity"
              width="60"
              align="center"
              column-key="quantity"
            >
            </el-table-column>
            <el-table-column
              label="Somm."
              prop="subministration_type.label"
              width="105"
              align="center"
              column-key="subministration_type"
            >
            </el-table-column>
            <el-table-column
              label="Inzio Terapia"
              prop="start_date"
              align="center"
              width="130"
              column-key="start_therapy"
              sortable
            >
            </el-table-column>
            <el-table-column
              prop="clean_end_date"
              width="140"
              label="Da Rivalutare"
              sortable
              align="center"
            >
              <template slot-scope="scope">
                {{ withEndDate(scope.row) ? "" : scope.row.clean_end_date }}
              </template>
            </el-table-column>
            <el-table-column
              prop="clean_therapy_end_date"
              width="130"
              label="Data Fine"
              sortable
              align="center"
            ></el-table-column>
            <el-table-column label="Orario" align="center" column-key="allTime">
              <el-table-column
                :label="value"
                width="50"
                v-for="(value, index) in subministrationTimes"
                :key="index"
                align="center"
                column-key="time"
              >
                <template slot-scope="scope">
                  <i
                    v-if="orarioInArray(value, scope.row.orari)"
                    class="el-icon el-icon-check"
                  ></i>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="Giorno" align="center" column-key="allDate">
              <el-table-column
                :label="index"
                width="40"
                v-for="(value, index) in weekDay"
                :key="index"
                align="center"
                column-key="date"
              >
                <template slot-scope="scope">
                  <span v-if="!scope.row.weekday && scope.row.every > 1"
                    >Ogni {{ scope.row.every }} giorni</span
                  >
                  <i
                    v-else-if="
                      (scope.row.weekday && scope.row.weekday == value) ||
                      !scope.row.weekday
                    "
                    class="el-icon el-icon-circle-check"
                  ></i>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column
              label="Indicazioni"
              prop="description"
              align="center"
              width="250"
            >
            </el-table-column>
            <el-table-column
              width="50"
              label=""
              align="center"
              column-key="info"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'Inserimento: ' + scope.row.evaluation"
                  placement="left"
                >
                  <i class="el-icon-info"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="Azioni"
              width="170"
              align="center"
              column-key="actions"
              v-if="user.is_doctor"
            >
              <template slot-scope="scope">
                <el-button-group v-if="!scope.row.status_update">
                  <!-- termina -->
                  <el-button
                    size="medium"
                    type="danger"
                    icon="el-icon-circle-close"
                    v-if="
                      !isEdited(scope.row) || scope.row.verification_result == 2
                    "
                    :disabled="
                      scope.row.verification_result == 2 || isEdited(scope.row)
                    "
                    @click="
                      updateRecord(
                        scope.row,
                        'terminate',
                        'abitudinaleTherapies'
                      )
                    "
                  >
                  </el-button>
                  <!-- modifica -->
                  <el-button
                    size="medium"
                    type="primary"
                    v-if="
                      scope.row.verification_result == 1 || !isEdited(scope.row)
                    "
                    :disabled="
                      scope.row.verification_result == 1 || isEdited(scope.row)
                    "
                    icon="el-icon-edit-outline"
                    @click="showEditDialog(scope.row, 'abitudinaleTherapies')"
                  >
                  </el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>

      <!-- TERAPIA AL BISOGNO -->
      <el-row style="margin-top: 30px">
        <el-col :span="24" style="padding: 0 10px">
          <h2><center>TERAPIA AL BISOGNO</center></h2></el-col
        >
        <el-col :span="24">
          <el-table
            :data="records['bisognoTherapies']"
            style="width: 100%"
            v-loading="loading"
            border
            @selection-change="handleSelection([...$event], 'bisognoTherapies')"
            ref="bisognoTherapies"
            :row-class-name="rowClass"
            :cell-class-name="cellClass"
          >
            <el-table-column
              type="selection"
              width="50"
              :selectable="selectable"
              fixed
            >
            </el-table-column>
            <el-table-column
              label="Farmaco"
              prop="farmaco_name"
              width="200"
              sortable
            >
            </el-table-column>
            <el-table-column
              label="Principio Attivo"
              prop="principio_attivo"
              width="150"
              column-key="principio_attivo"
              sortable
            >
            </el-table-column>
            <el-table-column
              label="Q.tà"
              prop="quantity"
              width="60"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="Somm."
              prop="subministration_type.label"
              width="105"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="Inzio Terapia"
              prop="start_date"
              align="center"
              width="130"
              column-key="start_therapy"
              sortable
            >
            </el-table-column>
            <el-table-column
              prop="clean_end_date"
              width="140"
              label="Da Rivalutare"
              sortable
              align="center"
            >
              <template slot-scope="scope">
                {{ withEndDate(scope.row) ? "" : scope.row.clean_end_date }}
              </template>
            </el-table-column>
            <el-table-column
              prop="clean_therapy_end_date"
              width="130"
              label="Data Fine"
              sortable
              align="center"
            ></el-table-column>
            <el-table-column
              label="Condizione"
              prop="bisogno"
              align="center"
              min-width="150"
            >
            </el-table-column>
            <el-table-column
              label="Indicazioni"
              prop="description"
              align="center"
              min-width="150"
            >
            </el-table-column>
            <el-table-column
              width="50"
              label=""
              align="center"
              column-key="info"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'Inserimento: ' + scope.row.evaluation"
                  placement="left"
                >
                  <i class="el-icon-info"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="Azioni"
              width="170"
              align="center"
              column-key="actions"
              v-if="user.is_doctor"
            >
              <template slot-scope="scope">
                <el-button-group v-if="!scope.row.status_update">
                  <!-- termina -->
                  <el-button
                    size="medium"
                    type="danger"
                    icon="el-icon-circle-close"
                    v-if="
                      !withEndDate(scope.row) ||
                      (isEdited(scope.row) &&
                        scope.row.verification_result == 2)
                    "
                    :disabled="
                      scope.row.verification_result == 2 || isEdited(scope.row)
                    "
                    @click="
                      updateRecord(scope.row, 'terminate', 'bisognoTherapies')
                    "
                  ></el-button>
                  <!-- modifica -->
                  <el-button
                    size="medium"
                    type="primary"
                    v-if="
                      scope.row.verification_result == 1 || !isEdited(scope.row)
                    "
                    :disabled="
                      scope.row.verification_result == 1 || isEdited(scope.row)
                    "
                    icon="el-icon-edit-outline"
                    @click="showEditDialog(scope.row, 'bisognoTherapies')"
                  ></el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>

      <!-- TERAPIA LASSATIVA -->
      <el-row style="margin: 30px 0">
        <el-col :span="24" style="padding: 0 10px">
          <h2><center>TERAPIA LASSATIVA</center></h2></el-col
        >
        <el-col :span="24">
          <el-table
            :data="records['lassativaTherapies']"
            style="width: 100%"
            v-loading="loading"
            border
            ref="lassativaTherapies"
            :row-class-name="rowClass"
            :cell-class-name="cellClass"
            @selection-change="
              handleSelection([...$event], 'lassativaTherapies')
            "
          >
            <el-table-column
              type="selection"
              width="50"
              :selectable="selectable"
              fixed
            >
            </el-table-column>
            >
            <el-table-column
              label="Farmaco"
              prop="farmaco_name"
              width="200"
              sortable
            >
            </el-table-column>
            <el-table-column
              label="Principio Attivo"
              prop="principio_attivo"
              width="150"
              column-key="principio_attivo"
              sortable
            >
            </el-table-column>
            <el-table-column
              label="Q.tà"
              prop="quantity"
              width="60"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="Somm."
              prop="subministration_type.label"
              width="105"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="Inzio Terapia"
              prop="start_date"
              align="center"
              width="130"
              column-key="start_therapy"
              sortable
            >
            </el-table-column>
            <el-table-column
              prop="clean_end_date"
              width="140"
              label="Da Rivalutare"
              sortable
              align="center"
            >
              <template slot-scope="scope">
                {{ withEndDate(scope.row) ? "" : scope.row.clean_end_date }}
              </template>
            </el-table-column>
            <el-table-column
              prop="clean_therapy_end_date"
              width="130"
              label="Data Fine"
              sortable
              align="center"
            ></el-table-column>
            <el-table-column
              label="Condizione"
              prop="bisogno"
              align="center"
              min-width="150"
            >
              <template slot-scope="scope">
                Se non evacua per {{ scope.row.every }} giorni
              </template>
            </el-table-column>
            <el-table-column
              label="Indicazioni"
              prop="description"
              align="center"
              min-width="150"
            >
            </el-table-column>
            <el-table-column
              width="50"
              label=""
              align="center"
              column-key="info"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'Inserimento: ' + scope.row.evaluation"
                  placement="left"
                >
                  <i class="el-icon-info"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="Azioni"
              width="170"
              align="center"
              column-key="actions"
              v-if="user.is_doctor"
            >
              <template slot-scope="scope">
                <el-button-group v-if="!scope.row.status_update">
                  <!-- termina -->
                  <el-button
                    size="medium"
                    type="danger"
                    icon="el-icon-circle-close"
                    v-if="
                      !withEndDate(scope.row) ||
                      (isEdited(scope.row) &&
                        scope.row.verification_result == 2)
                    "
                    :disabled="
                      scope.row.verification_result == 2 || isEdited(scope.row)
                    "
                    @click="
                      updateRecord(scope.row, 'terminate', 'lassativaTherapies')
                    "
                  ></el-button>
                  <!-- modifica -->
                  <el-button
                    size="medium"
                    type="primary"
                    v-if="
                      scope.row.verification_result == 1 || !isEdited(scope.row)
                    "
                    :disabled="
                      scope.row.verification_result == 1 || isEdited(scope.row)
                    "
                    icon="el-icon-edit-outline"
                    @click="showEditDialog(scope.row, 'lassativaTherapies')"
                  ></el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>

    <!-- PROTECTION  -->
    <div v-if="action == 'protection'">
      <el-row style="margin: 30px 0">
        <el-col :span="24">
          <el-row type="flex" align="middle">
            <el-col :span="6">
              <el-button
                :disabled="
                  !(parseInt(selection['protectionsTherapies'].length) >= 1)
                "
                size="medium"
                type="info"
                style="margin: 0 2.5px"
                @click="showUpdateDialog"
              >
                <i class="el-icon-edit-outline" /> Rivaluta
              </el-button>
            </el-col>
            <el-col :span="12" style="padding: 0 10px">
              <h2><center>CONTENZIONI</center></h2></el-col
            >
          </el-row>
        </el-col>
        <el-col :span="24">
          <el-table
            :data="records['protectionsTherapies']"
            style="width: 100%"
            :row-class-name="rowClass"
            :default-sort="{ prop: 'type_description', order: 'ascending' }"
            :cell-class-name="cellClass"
            @selection-change="
              handleSelection([...$event], 'protectionsTherapies')
            "
            v-loading="loading"
            ref="protectionsTherapies"
          >
            <el-table-column
              type="selection"
              width="50"
              fixed
              :selectable="selectable"
            ></el-table-column>
            <el-table-column
              prop="type_description"
              width="150"
              label="Monitoraggio"
              fixed
            >
            </el-table-column>
            <el-table-column
              prop="type_name"
              min-width="130"
              label="Tipologia"
              v-if="action == 'protection'"
            ></el-table-column>
            <el-table-column
              prop="indication"
              label="Indicazioni"
              min-width="230"
              v-if="action == 'protection'"
            ></el-table-column>
            <el-table-column
              prop="alternative_practices"
              label="Pratiche alternative testate"
              min-width="230"
              v-if="action == 'protection'"
            ></el-table-column>
            <el-table-column
              prop="date"
              width="150"
              label="Data Inizio"
              sortable
            >
            </el-table-column>
            <el-table-column
              prop="clean_end_date"
              width="150"
              label="Da Rivalutare"
              sortable
            >
              <template slot-scope="scope">
                {{ withEndDate(scope.row) ? "" : scope.row.clean_end_date }}
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="clean_therapy_end_date"
              width="150"
              label="Data Fine"
              sortable
            ></el-table-column> -->
            <el-table-column
              prop="indication"
              min-width="80"
              v-if="action == 'protection'"
            >
              <template slot-scope="scope">
                <el-tooltip
                  content="Consenso Informato"
                  placement="bottom"
                  effect="light"
                >
                  <el-button
                    :loading="downloadLoading"
                    type="success"
                    size="small"
                    round
                    plain
                    @click="
                      pdf(
                        scope.row.id,
                        scope.row.type_name,
                        scope.row.full_patient
                      )
                    "
                    v-if="scope.row.enabled"
                  >
                    <i class="el-icon-download"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              width="50"
              label=""
              align="center"
              column-key="info"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="'Inserimento: ' + scope.row.evaluation"
                  placement="left"
                >
                  <i class="el-icon-info"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="Azioni"
              width="170"
              column-key="actions"
              v-if="user.is_doctor"
            >
              <template slot-scope="scope">
                <el-button-group v-if="!scope.row.status_update">
                  <!-- termina -->
                  <el-button
                    size="medium"
                    type="danger"
                    icon="el-icon-circle-close"
                    v-if="
                      !withEndDate(scope.row) ||
                      (isEdited(scope.row) &&
                        scope.row.verification_result == 2)
                    "
                    :disabled="
                      scope.row.verification_result == 2 || isEdited(scope.row)
                    "
                    @click="
                      updateRecord(
                        scope.row,
                        'terminate',
                        'protectionsTherapies'
                      )
                    "
                  ></el-button>
                  <!-- modifica -->
                  <el-button
                    size="medium"
                    type="primary"
                    v-if="
                      scope.row.verification_result == 1 || !isEdited(scope.row)
                    "
                    :disabled="
                      scope.row.verification_result == 1 || isEdited(scope.row)
                    "
                    icon="el-icon-edit-outline"
                    @click="showEditDialog(scope.row, 'protectionsTherapies')"
                  ></el-button>
                </el-button-group>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Protection from "@/js/api/services/Protection";
import Therapy from "@/js/api/services/Therapy";
export default {
  name: "TherapyTable",
  props: [
    "profile_id",
    "action",
    "showDialog",
    "toUpdate",
    "prescriptions",
    "loadingButton",
  ],
  data() {
    var VerificationDateValidator = (rule, value, callback) => {
      if (moment(value).diff(moment(), "years") >= 2) {
        callback(new Error("Massimo 2 anni"));
      } else {
        callback();
      }
    };
    return {
      loading: true,
      downloadLoading: false,
      past: 0,
      selected: false,
      selection: {
        abitudinaleTherapies: [],
        bisognoTherapies: [],
        lassativaTherapies: [],
        protectionsTherapies: [],
      },
      pickerOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        },
      },
      options: [
        {
          value: 0,
          label: "Attive",
        },
        {
          value: 1,
          label: "Terminate",
        },
      ],

      dialog: false,
      submitLoading: false,
      form: {
        verification_result: 1,
      },
      rules: {
        verification_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        verification_date: [
          { required: false, message: "Campo Obbligatorio", trigger: "change" },
          { validator: VerificationDateValidator, trigger: "blur" },
        ],
      },
      weekDay: {
        Lu: "Lunedí",
        Ma: "Martedí",
        Me: "Mercoledí",
        Gi: "Giovedí",
        Ve: "Venerdí",
        Sa: "Sabato",
        Do: "Domenica",
      },
      subministrationTimes: {},
      records: {},
      recordToSave: [],
      therapiesInEditMode: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    past: function (newPast, oldPast) {
      this.fetch();
    },
    prescriptions: function (newPrescription, oldPrescription) {
      let newItems = _.difference(newPrescription, oldPrescription);
      if (newItems.length > 0) {
        let self = this;
        var therapyType = null;
        _.forEach(newItems, function (item) {
          item = {
            ...item,
            status_update: self.therapiesInEditMode.length > 0 ? 2 : 1, //edited : created
            farmaco_name: item.farmacoName
              ? item.farmacoName
              : item.farmaco ?? null,
            subministration_type: {
              id: item.somministrazione,
              label: item.somministrazioneLabel,
            },
            start_date: item.date_time
              ? moment(item.date_time).format("DD/MM/YYYY")
              : moment().format("DD/MM/YYYY"),
            clean_end_date: item.verification_date
              ? moment(item.verification_date).format("DD/MM/YYYY")
              : null,
            clean_therapy_end_date: item.therapy_end_date
              ? moment(item.therapy_end_date).format("DD/MM/YYYY")
              : null,
            orari: _.join(item.orario, " "),
            weekday: item.weekday ?? null,
            every: item.every ?? null,
            principio_attivo: item.principio_attivo,
          };
          if (item.type == 3) item.every = item.days;

          if (item.type == 4) item.start_date = null;
          item.clean_end_date = item.end_date
            ? moment(item.end_date).format("DD/MM/YYYY")
            : null;

          _.forEach(item.orario, function (o) {
            if (!self.subministrationTimes.includes(o)) {
              self.subministrationTimes.push(o);
            }
          });

          _.unset(item, [
            "orario",
            "uuid",
            "somministrazione",
            "somministrazioneLabel",
          ]);

          switch (item.type) {
            case 1: //abitudinale
              self.records["abitudinaleTherapies"].push(item);
              therapyType = "abitudinaleTherapies";
              break;
            case 2: // bisogno
              self.records["bisognoTherapies"].push(item);
              therapyType = "bisognoTherapies";
              break;
            case 3: //lassativa
              self.records["lassativaTherapies"].push(item);
              therapyType = "lassativaTherapies";
              break;
            case 4: // contenzioni
              self.records["protectionsTherapies"].push(item);
              therapyType = "protectionsTherapies";
              break;
          }
        });

        if (this.therapiesInEditMode.length > 0) {
          _.forEach(this.therapiesInEditMode, function (id) {
            let r = _.find(self.records[therapyType], ["id", id]);
            if (r.status_update != 3) {
              self.updateRecord(r, "removed", therapyType);
            }
          });
          this.therapiesInEditMode = [];
        }
      }
    },
    showDialog: function (newShowDialog, oldShowDialog) {
      if (oldShowDialog == true && newShowDialog == false) {
        this.therapiesInEditMode = [];
      }
    },
  },
  methods: {
    showUpdateDialog() {
      this.dialog = true;
    },
    showAddDialog() {
      this.$emit("update:showDialog", true);
    },
    saveDraft() {
      this.$emit("updateSubmit");
    },
    async fetch() {
      this.loading = true;
      var params = {
        patient_id: this.profile_id,
        past: this.past,
      };
      if (this.action == "therapy") {
        const therapy = await Therapy.fetch(params);

        var orari = [];
        _.forEach(therapy.data, function (t) {
          if (t.orari) {
            orari = _.concat(orari, _.split(t.orari, " "));
          }
        });

        this.subministrationTimes = _.sortBy(
          _.remove(_.uniq(orari), (o) => {
            return o != "";
          }),
          (o) => {
            const [hours, minutes] = o.split(":");
            return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
          }
        );

        this.records["abitudinaleTherapies"] = _.filter(therapy.data, [
          "type",
          1,
        ]);
        this.records["bisognoTherapies"] = _.filter(therapy.data, ["type", 2]);
        this.records["lassativaTherapies"] = _.filter(therapy.data, [
          "type",
          3,
        ]);
      } else if (this.action == "protection") {
        const resp = await Protection.fetchSubministrated(params);
        this.records["protectionsTherapies"] = resp.data;
      }
      this.loading = false;
    },
    async pdf(id, description, full_patient) {
      this.downloadLoading = true;
      const response = await Protection.pdf(id);
      let title = `CONSENSO_INFORMATO_${description}_${full_patient}.pdf`;
      this.file(response, title);
    },
    file(response, title) {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", title);
      document.body.appendChild(fileLink);
      fileLink.click();

      this.downloadLoading = false;
    },
    withEndDate(row) {
      return Boolean(row.therapy_end_date);
    },
    selectable(row, idx) {
      return !this.withEndDate(row) && !row.status_update >= 1;
    },
    handleSelection(val, key) {
      this.selection[key] = val;
    },
    handleResult() {
      this.rules.verification_result[0].required =
        this.form.verification_result == 1;
    },
    updateRecords() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          _.forEach(this.selection, (records, therapyType) => {
            _.forEach(records, (record, key) => {
              var action = "revalue";
              var form_date = this.form.verification_date ?? null;
              this.updateRecord(record, action, therapyType, form_date);
            });
          });
          this.resetSelectedRow();
          this.dialog = false;
          this.resetForm();
        } else {
          return false;
        }
      });
    },
    close() {
      this.submitLoading = false;
      this.resetForm();
      this.dialog = false;
    },
    rowClass({ row, rowIndex }) {
      if (!row) {
        return "";
      } else {
        if (row.enabled == 0) {
          return "deleted-row";
        } else if (row.verification_result == 2 || row.is_expired) {
          return "danger-row";
        } else if (row.is_expiring) {
          return "warning-row";
        }
      }
    },
    resetForm() {
      this.$refs["form"].resetFields();
      this.form = {};
    },
    onTherapyCreated(e) {
      this.fetch();
    },
    cellClass({ row, column, rowIndex, columnIndex }) {
      if (column.columnKey == "time") {
        return "timeCell";
      } else if (
        column.columnKey == "actions" &&
        typeof row.status_update &&
        row.status_update >= 1
      ) {
        switch (row.status_update) {
          case 1: //new
            return "updatedRow new";
          case 2: // revaluated
            return "updatedRow revaluated";
          case 3: // terminate
            return "updatedRow terminate";
        }
      }
      if (row.verification_result == 2) return "deleted";
      return "";
    },
    cellHeaderClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 || (rowIndex == 0 && column.columnKey == "info")) {
        return "lowPadding";
      }
      return "";
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (column.columnKey == "date" && !row.weekday && row.every > 1) {
        if (column.label == "Lu") {
          return [1, 7];
        } else {
          return [0, 0];
        }
      }
    },
    orarioInArray(value, orari) {
      if (orari && value) {
        var orariArr = _.split(orari, " ");
        if (orariArr.includes(value)) {
          return true;
        }
      }
      return false;
    },
    updateRecord(record, operation, therapyType, form_date = null) {
      var selectedId = record.id;

      if (therapyType) {
        var key = _.findKey(
          this.records[therapyType],
          (x) => x.id === selectedId
        );

        if (operation == "terminate") {
          //verification_result = 2
          record.verification_result = 2;
          record.verification_date = moment().format("YYYY-MM-DD");
          record.clean_end_date = moment().format("DD/MM/YYYY");
          record.status_update = 3; // terminate
          this.$refs[therapyType].toggleRowSelection(record, false);
        } else if (operation == "revalue") {
          // verification_result = 1
          record.verification_result = 1;
          record.verification_date = moment(form_date).format("YYYY-MM-DD");
          record.clean_end_date = moment(form_date).format("DD/MM/YYYY");
          record.status_update = 2; //revaluated
          this.records[therapyType][key] = record;
        } else if (operation == "removed") {
          // verfication_result = 2 ma provengo dalla modifica di una terapia, di conseguenza devo eliminarla
          record.verification_result = 1;
          record.verification_date = moment().format("YYYY-MM-DD");
          record.clean_end_date = moment().format("DD/MM/YYYY");
          record.status_update = 3; // terminate
          this.$refs[therapyType].toggleRowSelection(record, false);
          this.$delete(this.records[therapyType], key);
        }
        this.toUpdate[record.id] = {
          id: record.id,
          record: record,
          action:
            therapyType == "protectionsTherapies" ? "protection" : "therapy",
          verification_date: record.verification_date,
          verification_result: record.verification_result,
        };

        this.$emit("update:toUpdate", this.toUpdate);
      }
    },
    clearDraft() {
      this.recordToSave = [];
      this.$emit("clearDraft");
      this.fetch();
    },
    isEdited(row) {
      return row.status_update && row.status_update >= 1;
    },
    showEditDialog(row, therapyType) {
      if (
        therapyType != "protectionsTherapies" &&
        row.type === 1 &&
        !_.isNull(row.weekday)
      ) {
        var allTherapy = _.filter(this.records[therapyType], function (t) {
          return (
            t.type === 1 &&
            t.somministrazione == row.somministrazione &&
            (t.farmaco == row.farmaco || t.farmaco_name == row.farmaco) &&
            t.description == row.description
          );
        });
        this.therapiesInEditMode = _.map(allTherapy, (item) => item.id);
        this.$emit("updateModal", _.cloneDeep(allTherapy), therapyType);
      } else {
        this.therapiesInEditMode.push(row.id);
        this.$emit("updateModal", _.cloneDeep(row), therapyType);
      }
    },
    resetSelectedRow() {
      this.$refs.abitudinaleTherapies &&
        this.$refs.abitudinaleTherapies.clearSelection();
      this.$refs.bisognoTherapies &&
        this.$refs.bisognoTherapies.clearSelection();
      this.$refs.lassativaTherapies &&
        this.$refs.lassativaTherapies.clearSelection();
      this.$refs.protectionsTherapies &&
        this.$refs.protectionsTherapies.clearSelection();
    },
  },
  beforeDestroy() {
    this.$root.$off("therapies_upserted", this.fetch);
  },
  created() {
    this.$root.$on("therapies_upserted", this.fetch);
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style lang="css">
.timeCell {
  background-color: #ecf5ff;
}
.lowPadding .cell {
  padding: 0 5px !important;
}
.updatedRow.terminate {
  background-color: #f81414;
}
.updatedRow.revaluated {
  background-color: rgb(20, 146, 248);
}
.updatedRow.new {
  background-color: #14f81f;
}
.deleted {
  text-decoration: line-through;
}
.el-button-group button:only-child {
  border: 1px solid #fff;
  border-left-color: #fff !important;
  border-right-color: #fff !important;
}
</style>
