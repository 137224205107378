<template>
  <div>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
      <md-dialog-title>{{ title }}</md-dialog-title>
      <md-dialog-content>
        <el-form
          label-position="top"
          label-width="120px"
          :model="form"
          :rules="rules"
          ref="form"
        >
          <el-row :gutter="20">
            <el-col :span="12" v-if="action !== '/meals'">
              <el-form-item label="Data" prop="date">
                <el-date-picker
                  v-model="form.date"
                  type="date"
                  placeholder="Scegli data"
                  :editable="false"
                  :picker-options="{
                    firstDayOfWeek: 1,
                    disabledDate(time) {
                      if (dateOptions === 'past')
                        return time.getTime() > Date.now();
                      else {
                        return time.getTime() < Date.now() - 3600 * 1000 * 24;
                      }
                    },
                  }"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="Orario"
                prop="time"
                v-if="action !== '/meals'"
              >
                <el-time-picker
                  v-model="form.time"
                  :editable="false"
                  format="HH:mm"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="Seleziona Orario"
                >
                </el-time-picker>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item
                label="Ospite"
                v-if="action !== '/comunications'"
                prop="patient_id"
              >
                <el-select
                  v-model="form.patient_id"
                  filterable
                  placeholder="Seleziona Ospite"
                  clearable
                >
                  <el-option
                    v-for="item in patients"
                    :key="item.id"
                    :label="item.full_name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <span v-if="showAbsentPatientAlert">
                  <font-awesome-icon icon="exclamation" class="danger-text" />
                  <font style="color: red"
                    >Attenzione: l'ospite è attualmente assente</font
                  >
                </span>
                <span v-if="showOldAbsencePatientAlert">
                    <font-awesome-icon icon="exclamation" class="danger-text" />
                    <font style="color: red"
                        >Attenzione: l'ospite era assente in questo periodo</font
                    >
                </span>
              </el-form-item>
            </el-col>
            <el-col
              :span="24"
              v-if="
                action === '/tasks' ||
                action === '/protections' ||
                action === '/absences'
              "
            >
              <el-form-item label="Categoria" prop="option">
                <el-select
                  v-model="form.option"
                  placeholder="Seleziona Categoria"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="action === '/tasks'">
              <el-col :span="24" class="switcher">
                <el-form-item prop="patternType" label="Ricorrenza">
                  <el-radio-group v-model="patternType" style="width: 100%">
                    <el-radio-button label="single"
                      >Singolo Giorno</el-radio-button
                    >
                    <el-radio-button label="daily">Giornaliera</el-radio-button>
                    <el-radio-button label="weekly"
                      >Settimanale</el-radio-button
                    >
                  </el-radio-group>
                </el-form-item>
              </el-col>

              <el-col :span="12" v-if="patternType == 'daily'">
                <el-form-item label="Ogni n° Giorni" prop="every">
                  <el-input-number
                    v-model="form.pattern.interval"
                    :min="1"
                  ></el-input-number>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="patternType == 'weekly'">
                <el-form-item label="Giorni" prop="daysOfWeek">
                  <el-select
                    v-model="form.pattern.daysOfWeek"
                    multiple
                    placeholder="Seleziona"
                  >
                    <el-option
                      v-for="item in daysOfWeek"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col
                :span="12"
                v-if="patternType == 'daily' || patternType == 'weekly'"
              >
                <el-form-item label="Data fine" prop="end_date">
                  <el-date-picker
                    v-model="form.end_date"
                    type="date"
                    placeholder="Scegli data"
                    :editable="false"
                    :picker-options="{
                      firstDayOfWeek: 1,
                      disabledDate(time) {
                        return time.getTime() < Date.now();
                      },
                    }"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-col>

            <el-col
              :span="12"
              v-if="
                this.action === '/absences' &&
                (this.form.option == 1 || this.form.option == 4)
              "
            >
              <el-form-item label="Rientro" prop="return_date">
                <el-date-picker
                  v-model="form.return_date"
                  type="date"
                  placeholder="Scegli data"
                  :editable="false"
                  :picker-options="{
                    firstDayOfWeek: 1,
                    disabledDate(time) {
                      if (dateOptions === 'past')
                        return time.getTime() > Date.now();
                      else {
                        return time.getTime() < Date.now() - 3600 * 1000 * 24;
                      }
                    },
                  }"
                  format="dd/MM/yyyy"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="
                this.action === '/absences' &&
                (this.form.option == 1 || this.form.option == 4)
              "
            >
              <el-form-item label="Orario" prop="return_time">
                <el-time-picker
                  v-model="form.return_time"
                  :editable="false"
                  format="HH:mm"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="Seleziona Orario"
                >
                </el-time-picker>
              </el-form-item>
            </el-col>

            <el-col :span="24" v-if="action === '/protections'">
              <el-form-item
                label="Tipologia"
                prop="category"
                class="text-center"
              >
                <el-radio-group v-model="form.category">
                  <el-radio-button label="1" border
                    >Monitoraggio 2 Ore</el-radio-button
                  >
                  <el-radio-button label="2" border
                    >Monitoraggio 30 Minuti</el-radio-button
                  >
                  <el-radio-button label="5" border>Al bisogno</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="action === '/protections'">
              <el-form-item
                label="Eventuali pratiche alternative testate prima di decidere di ricorrere allo strumento"
                prop="alternative_practices"
              >
                <el-input
                  type="textarea"
                  placeholder="Pratiche alternative"
                  v-model="form.alternative_practices"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- meals part 1 -->
            <el-col :span="24" v-if="action === '/meals'">
              <el-row :gutter="20">
                <el-col :span="24" align="center">
                  Periodo Monitoraggio
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Data Inizio" prop="monitoring_start">
                    <el-date-picker
                      v-model="form.monitoring_start"
                      type="date"
                      placeholder="Scegli data"
                      :editable="false"
                      :picker-options="{
                        firstDayOfWeek: 1,
                        disabledDate(time) {
                          return time.getTime() < Date.now() - 3600 * 1000 * 24;
                        },
                      }"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Data Fine" prop="monitoring_end">
                    <el-date-picker
                      v-model="form.monitoring_end"
                      type="date"
                      placeholder="Scegli data"
                      :editable="false"
                      :picker-options="{
                        firstDayOfWeek: 1,
                        disabledDate(time) {
                          return time.getTime() < Date.now() - 3600 * 1000 * 24;
                        },
                      }"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="24" align="center">
                  Pasti da monitorare <br
                /></el-col>
                <el-col :span="24">
                  <el-form-item prop="active_meals">
                    <el-checkbox-group v-model="form.active_meals">
                      <el-checkbox label="pranzo"></el-checkbox>
                      <el-checkbox label="cena"></el-checkbox>
                      <el-checkbox label="colazione"></el-checkbox>
                      <el-checkbox label="merenda"></el-checkbox>
                      <el-checkbox label="spuntino"></el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <!-- end meals part 1 -->
            <el-col :span="24" v-if="action !== '/comunications'">
              <el-form-item
                :label="action === '/meals' ? 'Note Monitoraggio' : 'Info'"
                prop="description"
              >
                <div id="app" v-if="action === '/diary'">
                  <vue-editor
                    v-model="form.description"
                    placeholder="Descrizione"
                    :editorToolbar="customToolbar"
                  ></vue-editor>
                </div>
                <el-input
                  v-else
                  type="textarea"
                  :rows="2"
                  placeholder="Descrizione"
                  v-model="form.description"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="action === '/comunications'">
              <el-form-item label="Titolo" prop="title">
                <el-input
                  type="textarea"
                  :rows="1"
                  placeholder="Titolo"
                  v-model="form.title"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="action === '/comunications'">
              <el-form-item label="Messaggio" prop="message">
                <el-input
                  type="textarea"
                  :rows="6"
                  placeholder="Messaggio"
                  v-model="form.message"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <!-- meals part 2 -->
            <el-col :span="24" v-if="action === '/meals'">
              <el-row :gutter="20">
                <el-col :span="24" align="center">Inserisci Consegna</el-col>
                <el-col :span="12">
                  <el-form-item label="Data" prop="task_revaluation_date">
                    <el-date-picker
                      v-model="form.task_revaluation_date"
                      type="date"
                      placeholder="Scegli data"
                      :editable="false"
                      :picker-options="{
                        firstDayOfWeek: 1,
                        disabledDate(time) {
                          return time.getTime() < Date.now() - 3600 * 1000 * 24;
                        },
                      }"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Orario" prop="task_revaluation_time">
                    <el-time-picker
                      v-model="form.task_revaluation_time"
                      :editable="false"
                      format="HH:mm"
                      value-format="HH:mm:ss"
                      placeholder="Seleziona Orario"
                    >
                    </el-time-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="Note Consegna" prop="task_description">
                    <el-input
                      type="textarea"
                      :rows="2"
                      placeholder="Descrizione"
                      v-model="form.task_description"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <!-- end meals part 2 -->
            <el-col :span="6">
              <div class="grid-content bg-purple"></div>
            </el-col>
          </el-row>
        </el-form>
      </md-dialog-content>
      <md-dialog-actions>
        <el-button type="info" plain @click="close">Annulla</el-button>
        <el-button
          type="success"
          plain
          @click="submit('form')"
          :loading="submitLoading"
          >Salva</el-button
        >
      </md-dialog-actions>
    </md-dialog>
    <el-button
      class="add-button"
      @click="showDialog = true"
      type="success"
      icon="el-icon-plus"
      round
    ></el-button>
  </div>
</template>
<script>
import moment from "moment";
import { VueEditor } from "vue2-editor";
export default {
  name: "ArkiCreateDialog",
  props: {
    title: String,
    dateOptions: String,
    rules: Object,
    action: String,
    options: Array,
  },
  components: {
    VueEditor,
  },
  data() {
    return {
      showDialog: false,
      daysOfWeek: [
        {
          value: 1,
          label: "Lunedí",
        },
        {
          value: 2,
          label: "Martedí",
        },
        {
          value: 3,
          label: "Mercoledí",
        },
        {
          value: 4,
          label: "Giovedí",
        },
        {
          value: 5,
          label: "Venerdí",
        },
        {
          value: 6,
          label: "Sabato",
        },
        {
          value: 7,
          label: "Domenica",
        },
      ],
      patternType: "single",
      form: {
        category: "1",
        date: new Date(),
        time: new Date(),
        pattern: {
          type: "single",
          interval: 1,
          daysOfWeek: [],
        },
        end_date: null,
        monitoring_start: null,
        monitoring_end: null,
        task_revaluation_date: null,
        task_revaluation_time: null,
        task_description: null,
        active_meals: ["pranzo", "cena", "colazione", "spuntino", "merenda"],
      },
      submitLoading: false,
      showAbsentPatientAlert: false,
      showOldAbsencePatientAlert: false,
      patient: null,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ 'background': [] }],
      ]
    };
  },
  computed: {
    patients() {
      return this.$store.state.patients;
    },
  },
  watch: {
    form: {
      deep: true,
      handler(data) {
        if(this.form.patient_id){
            this.patient = _.filter(this.patients, { id: this.form.patient_id })[0];
            this.showAbsentPatientAlert = this.patient.absence_info.absence_status;
            this.showOldAbsencePatientAlert = false;
            if(!this.showAbsentPatientAlert && this.patient.absence_info.last_absence){
                var date = `${moment(this.form.date).format("YYYY-MM-DD")} ${moment(this.form.time).format("HH:mm:ss")}`;
                if (date > this.patient.absence_info.last_absence['confirmation_date'] && date < this.patient.absence_info.last_absence['verification_date']) {
                    this.showOldAbsencePatientAlert = true;
                }
            }
        }
      }
    },
  },
  methods: {
    close() {
      this.patternType = "single";
      this.form = {
        category: "1",
        date: new Date(),
        time: new Date(),
        pattern: {},
        end_date: null,
        monitoring_start: null,
        monitoring_end: null,
        task_revaluation_date: null,
        task_revaluation_time: null,
        task_description: null,
        active_meals: ["pranzo", "cena", "colazione", "spuntino", "merenda"],
      };
      this.submitLoading = false;
      this.showDialog = false;
      this.showAbsentPatientAlert = false;
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.insert();
        } else {
          return false;
        }
      });
    },
    onIntervalChange(value) {
      if (!value) {
        this.form.daysOfWeek = [];
      }
    },
    insert() {
      this.submitLoading = true;
      const payload = {
        patient_id: this.form.patient_id,
        date: moment(this.form.date).format("YYYY-MM-DD"),
        time: moment(this.form.time).set("second", 0).format("HH:mm:ss"),
        return_date: moment(this.form.return_date).format("YYYY-MM-DD"),
        return_time: moment(this.form.return_time)
          .set("second", 0)
          .format("HH:mm:ss"),
        description: this.form.description
          ? this.form.description
              .replace(/<p>/g, "<div>")
              .replace(/<\/p>/g, "</div>")
          : this.form.description,
        alternative_practices: this.form.alternative_practices,
        type: this.form.option,
        category: this.form.category,
        pattern: { ...this.form.pattern, type: this.patternType },
        end_date: this.form.end_date,
        structure_id: this.$store.state.structureId,
        title: this.form.title,
        message: this.form.message,
        monitoring_start: this.form.monitoring_start,
        monitoring_end: this.form.monitoring_end,
        task_revaluation_date: this.form.task_revaluation_date,
        task_revaluation_time: this.form.task_revaluation_time,
        task_description: this.form.task_description,
        active_meals: this.form.active_meals,
      };
      axios
        .post(this.action, payload)
        .then((res) => {
          this.$emit("submit");
          this.close();
          this.submitLoading = false;
        })
        .catch((error) => {
          this.submitLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.switcher {
  display: flex;
  justify-content: center;
}
</style>
