import Api from '@/js/api/Api';

export default class SuperadminDropdownService {

    static endpoint = 'superadmin/dropdowns';

    static get() {
        return Api.get(`${this.endpoint}`);
    }
    static store(params) {
        return Api.post(`${this.endpoint}`, params);
    }
    static update(params) {
        return Api.post(`${this.endpoint}/update`, params);
    }
}
