import ArkiBaseService from './ArkiBaseService';
import Api from '@/js/api/Api';

export default class Subministration extends ArkiBaseService {

    static endpoint = 'subministrations';

    static fetch(params, options = {}) {
        return Api.get(this.endpoint, { params, ...options });
    }

    static notifications(params, options = {}) {
        return Api.get(`${this.endpoint}/notifications`, { params, ...options });
    }

    static times(departmentId) {
        return Api.get(`${this.endpoint}/times/${departmentId}`);
    }

    static status(departmentId) {
        return Api.get(`${this.endpoint}/subministrationProgressStatus/${departmentId}`);
    }

    static summary(patientId) {
        return Api.get(`${this.endpoint}/summary/${patientId}`);
    }
}
