<script>
import { Lookup } from "@/js/api/services/activity";
import _ from "lodash";

export default {
  name: "SelectParticipation",
  inheritAttrs: false,
  props: ["value", "defaultValue"],
  data() {
    return {
      loading: false,
      options: [],
    };
  },
  methods: {
    onChange(x) {
      this.$emit("input", x);
    },
    onClear() {
      this.$emit("input", null);
    },
  },
  async created() {
    Lookup.findByCode("tipo-partecipazione")
      .then(
        ({ data }) =>
          (this.options = _.sortBy(_.get(data, "items", []), ["display_order"]))
      )
      .catch((err) => this.onError(err))
      .finally(() => (this.loading = false));
  },
};
</script>

<template>
  <el-select
    v-bind="$attrs"
    :value="value"
    @change="onChange"
    @clear="onClear"
    clearable
    filterable
    :loading="loading"
    loading-text="Loading..."
    size="small"
    value-key="id"
    placeholder="Tipo Partecipazione"
  >
    <el-option
      v-for="item in options"
      :key="item.id"
      :label="item.label"
      :value="item"
    />
  </el-select>
</template>

<style lang="scss" scoped>
</style>