<template>
  <el-row :gutter="20" class="no-margin-row">
    <div>
      <el-table
        class="paginate-table"
        :data="criticalEvents.data"
        :default-sort="{ prop: 'time', order: 'descending' }"
        style="width: 100%"
        :row-class-name="rowClass"
        v-loading="loading"
        :max-height="maxHeight"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-col :xl="12" :lg="12" :md="24">
              <el-col :span="24">
                <b>Inserimento:</b>
                {{ scope.row.full_creator }} - {{ scope.row.created_at_time }}
              </el-col>
            </el-col>
          </template>
        </el-table-column>
        <el-table-column prop="full_patient" label="Ospite" min-width="100">
        </el-table-column>
        <el-table-column label="Tipo" min-width="100">
          <template slot-scope="scope">{{
            scope.row.event_type_label
          }}</template>
        </el-table-column>
        <el-table-column label="Descrizione" min-width="100">
          <template slot-scope="scope">{{ scope.row.description }}</template>
        </el-table-column>
        <el-table-column
          prop="clean_date_time"
          label="Data e Ora"
          sortable
          align="center"
          width="200"
        >
        </el-table-column>
        <el-table-column
          prop="clean_resolution_date"
          label="Risoluzione"
          sortable
          align="center"
          width="200"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="criticalEvents.per_page"
        :total="criticalEvents.total"
        :current-page="current_page"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </el-row>
</template>
<script>
import CriticalEvent from "@/js/api/services/CriticalEvent";

export default {
  name: "CriticalEvents",
  props: ["filters"],
  data() {
    return {
      loading: true,
      submitLoading: false,
      criticalEvents: {
        data: [],
        per_page: null,
        total: null,
      },
      current_page: 1,
      changedFilter: false,
      paginateTable: null,
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 300;
    },
  },
  watch: {
    filters: {
      handler: function () {
        this.changedFilter = true;
        this.fetch();
      },
      deep: true,
    },
  },
  methods: {
    async fetch() {
      // need to reset page in case filter change
      if (!_.isEqual(this.params, this.filters)) {
        this.current_page = 1;
      }

      this.loading = true;
      this.params = this.filters;

      this.params.page = this.current_page;

      const response = await CriticalEvent.fetch(this.params);
      this.criticalEvents.data = response.data.criticalEvents.data;
      this.criticalEvents.total = response.data.criticalEvents.total;
      this.criticalEvents.per_page = response.data.criticalEvents.per_page;
      this.loading = false;
    },
    changePage(page) {
      this.loading = true;
      this.current_page = page;
      this.filters.page = page;
    },
    rowClass({ row, rowIndex }) {
      if (row.enabled == 0) {
        return "deleted-row";
      } else if (row.verification_result == 2) {
        return "warning-row";
      } else if (row.verification_result == 1) {
        return "success-row";
      }
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
