<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-row type="flex" justify="center">
      <h3>AREE DI VALUTAZIONE</h3>
    </el-row>
    <el-form
      ref="areaForm"
      label-position="top"
      label-width="80px"
      :disabled="disabledForm"
    >
      <el-row
        :gutter="20"
        v-for="(area, id_area) in config.pai_aree"
        :key="id_area"
      >
        <el-col :span="24">
          <el-divider content-position="left">
            <h3>{{ area.name }}</h3>
          </el-divider>
          ( {{ area.desc }} )
        </el-col>
        <el-col :span="24">
          <el-form-item label="Problema/Valutazione">
            <el-input
              type="textarea"
              :rows="3"
              v-model="problems[id_area]"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Obiettivo">
            <el-input
              type="textarea"
              :rows="3"
              v-model="results[id_area]"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <h3>Aggiungi intervento</h3>
          <el-col :span="6">
            <el-form-item label="Figura Coinvolta" prop="professions">
              <el-select
                v-model="methodsForm[id_area]['operatore']"
                placeholder="Seleziona Figura Coinvolta"
                clearable
              >
                <el-option
                  v-for="(profession, id_profession) in stateActiveProfessions"
                  :key="id_profession"
                  :label="profession.label"
                  :value="id_profession"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Intervento">
              <el-input
                type="textarea"
                :rows="2"
                v-model="methodsForm[id_area]['note']"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Frequenza">
              <el-input
                type="textarea"
                :rows="2"
                v-model="methodsForm[id_area]['frequenza']"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-button
              size="small"
              @click="addMethodArea(id_area)"
              :disabled="isDisabled(id_area)"
              type="success"
              class="float-right"
              >Aggiungi</el-button
            >
          </el-col>
          <el-col :span="22" :offset="1">
            <el-row type="flex" justify="space-between" :gutter="20">
              <el-col :span="6">
                <b>Figura</b>
              </el-col>
              <el-col :span="8">
                <b>Intervento</b>
              </el-col>
              <el-col :span="8">
                <b>Frequenza</b>
              </el-col>
              <el-col :span="2"></el-col>
            </el-row>
            <el-divider />
            <el-row
              :gutter="20"
              type="flex"
              v-for="item in methods[id_area]"
              :key="item.uid"
              class="sig-row"
              align="middle"
              justify="center"
            >
              <el-col v-if="stateProfessions[item.operatore]" :span="6">{{
                stateProfessions[item.operatore].label
              }}</el-col>
              <el-col :span="8">{{ item.note }}</el-col>
              <el-col :span="8">{{ item.frequenza }}</el-col>
              <el-col :span="2">
                <el-button
                  type="danger"
                  size="small"
                  plain
                  @click="deleteMethod(id_area, item)"
                >
                  <i class="el-icon-delete"></i>
                </el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
  </el-row>
</template>
<script>
import Pai from "@/js/api/services/Pai";

export default {
  name: "PaiAree",
  props: [
    "pai",
    "paiOperation",
    "patient",
    "paiAree",
    "latestPai",
    "persistentDraft",
  ],
  data() {
    return {
      operatorInStructure: {},
      loading: false,
      problems: {},
      results: {},
      professions: {},
      methodsForm: {},
      methods: {},
    };
  },
  computed: {
    config() {
      return this.$store.state.config;
    },
    disabledForm() {
      return this.paiOperation == "show";
    },
    stateProfessions() {
      return this.$store.state.professions;
    },
    stateActiveProfessions() {
      return Object.fromEntries(
        Object.entries(this.$store.state.professions).filter(
          ([id, profession]) => profession.enabled === 1
        )
      );
    },
  },
  methods: {
    async setupShow() {
      let aree = await this.fetchPaiAree(this.pai.id);
      _.forIn(aree, (value, key) => {
        this.problems[key] = value.problems;
        this.results[key] = value.result;
        this.extractMethods(value.methods, key);
      });
      this.$forceUpdate();
    },
    extractMethods(methods, key) {
      methods.forEach((m) => {
        const data = {
          uid: this.$uuid.v1(),
          operatore: m.profession_id,
          note: m.methods,
          frequenza: m.frequenza,
        };
        this.methods[key].push(data);
      });
    },
    async fetchPaiAree(paiId) {
      const res = await Pai.aree(paiId);
      return _.get(res, "data", []);
    },
    isDisabled(area_id) {
      const { operatore, note } = this.methodsForm[area_id];
      return [operatore, note].some(_.isNil);
    },
    addMethodArea(area_id) {
      const data = { uid: this.$uuid.v1(), ...this.methodsForm[area_id] };
      this.methods[area_id].push(data);
      this.methodsForm[area_id] = {
        operatore: null,
        note: null,
        frequenza: null,
      };
      const areaName = this.getAreaName(area_id);
      this.$message(`${areaName}: intervento aggiunto`);
    },
    getAreaName(id) {
      return _.get(this.config, ["pai_aree", id, "name"], "");
    },
    deleteMethod(area_id, item) {
      let areaMethods = this.methods[area_id];
      this.methods[area_id] = areaMethods.filter((m) => m.uid !== item.uid);
      const areaName = this.getAreaName(area_id);
      this.$message(`${areaName}: intervento rimosso`);
    },
    validate() {
      let errors = [];
      let isValid = false;

      _.forEach(this.problems, (value, key) => {
        if (value) {
          let hasResult = !_.isNil(this.results[key]);
          let hasMethods = !_.isEmpty(this.methods[key]);
          if (!hasResult || !hasMethods) {
            errors.push(this.config.pai_aree[key].name);
          }
        }
      });
      if (_.isEmpty(errors)) {
        isValid = true;
      }
      if (isValid) this.updatePaiAree();
      return [errors, isValid];
    },
    updatePaiAree() {
      let areaForm = [];
      _.forEach(this.config.pai_aree, (value, key) => {
        areaForm.push({
          area_type: key,
          problems: this.problems[key],
          results: this.results[key],
          professions: this.methods[key],
        });
      });
      this.$emit("update:paiAree", areaForm);
    },
    dataWatcher() {
      if (this.paiOperation == "new") {
        this.updatePaiAree();
      }
    },
    startWatching() {
      this.$watch("$data", this.dataWatcher, { deep: true });
    },
    applyAree(paiAree) {
      _.forIn(paiAree, (value, key) => {
        this.problems[key] = value.problems;
        this.results[key] = value.result;
        this.extractMethods(value.methods, key);
      });
      this.$forceUpdate();
    },
  },
  created() {
    let configs = this.$store.state.config;
    _.forIn(configs.pai_aree, (value, key) => {
      this.$set(this.methods, key, []);
      this.$set(this.methodsForm, key, {
        operatore: null,
        note: null,
        frequenza: null,
      });
    });

    if (this.paiOperation == "show") {
      return this.setupShow();
    }
    const hasDraft = Boolean(_.get(this.persistentDraft, "id"));
    const latest = hasDraft ? this.persistentDraft : this.latestPai;
    const paiAree = _.get(latest, "aree", {});
    _.forIn(paiAree, (value, key) => {
      this.$set(this.problems, key, value.problems);
      this.$set(this.results, key, value.result);
      value.methods.forEach((m) => {
        const data = {
          uid: this.$uuid.v1(),
          operatore: m.profession_id,
          note: m.methods,
          frequenza: m.frequenza,
        };
        this.$set(this.methods, key, this.methods[key].concat(data));
      });
    });
    this.updatePaiAree();
    this.startWatching();
  },
};
</script>
<style lang="css">
.actions {
  margin-top: 20px;
}
.sig-row {
  margin-top: 20px;
}
</style>
