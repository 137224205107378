<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_cdrs)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_cdrs.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_cdrs.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_cdrs)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_cdrs" :test_model="test_model_cdrs" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-row :gutter="20">
            <el-divider content-position="center">Memoria</el-divider>
            <el-row>
              <el-col :offset="1" :span="24">
                <el-row>
                  <el-form-item prop="q1_result">
                    <el-radio-group v-model="test_cdrs.q1_result" style="width: 90%">
                      <el-row>
                        <el-col :span="18">
                          <el-form-item>Memoria adeguata o smemoratezza occasionale</el-form-item>
                        </el-col>
                        <el-col :span="6">
                          <el-form-item>
                            <el-radio :label="0">Normale</el-radio>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="18">
                          <el-form-item>Lieve smemoratezza permanente; parziale rievocazione di eventi</el-form-item>
                        </el-col>
                        <el-col :span="6">
                          <el-form-item>
                            <el-radio :label="0.5">Demenza dubbia</el-radio>
                          </el-form-item>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Perdita di memoria modesta per eventi recenti; interferenza con attività quotidiane</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="1">Demenza lieve</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Perdita di memoria severa; materiale nuovo perso rapidamente</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="2">Demenza moderata</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Perdita di memoria grave; rimangono alcuni frammenti</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="3">Demenza grave</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Il paziente presenta severo deficit del linguaggio o della comprensione, problemi nel riconoscere i famigliari, incapacità a deambulare in modo autonomo, problemi ad alimentarsi da solo e nel controllare la funzione intestinale o vescicale.</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="4">Demenza molto grave</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Il paziente richiede assistenza totale perché completamente incapace di comunicare, in stato vegetativo, allettato, incontinente.</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="5">Demenza terminale</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
          </el-row>

          <el-row :gutter="20" class="section">
            <el-divider content-position="center">Orientamento</el-divider>
            <el-row>
              <el-col :offset="1" :span="24">
                <el-row>
                  <el-form-item prop="q2_result">
                    <el-radio-group v-model="test_cdrs.q2_result" style="width: 90%">
                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Perfettamente orientato</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="0">Normale</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Alcune difficoltà nel tempo; possibile disorientamento topografico</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="1">Demenza lieve</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Usualmente disorientamento temporale, spesso spaziale</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="2">Demenza moderata</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Orientamento solo personale</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="3">Demenza grave</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Il paziente presenta severo deficit del linguaggio o della comprensione, problemi nel riconoscere i famigliari, incapacità a deambulare in modo autonomo, problemi ad alimentarsi da solo e nel controllare la funzione intestinale o vescicale.</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="4">Demenza molto grave</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Il paziente richiede assistenza totale perché completamente incapace di comunicare, in stato vegetativo, allettato, incontinente.</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="5">Demenza terminale</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
          </el-row>

          <el-row :gutter="20" class="section">
            <el-divider content-position="center">Giudizio e soluzione di problemi</el-divider>
            <el-row>
              <el-col :offset="1" :span="24">
                <el-row>
                  <el-form-item prop="q3_result">
                    <el-radio-group v-model="test_cdrs.q3_result" style="width: 90%">
                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Risolve bene i problemi giornalieri; giudizio adeguato rispetto al passato</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="0">Normale</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Dubbia compromissione nella soluzione di problemi; analogie e differenze (prove di ragionamento)</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="0.5">Demenza dubbia</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Difficoltà moderata; esecuzione di problemi complessi; giudizio sociale adeguato</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="1">Demenza lieve</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Difficoltà severa nell’esecuzione di problemi complessi; giudizio sociale compromesso</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="2">Demenza moderata</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Incapace di dare giudizi o di risolvere problemi</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="3">Demenza grave</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Il paziente presenta severo deficit del linguaggio o della comprensione, problemi nel riconoscere i famigliari, incapacità a deambulare in modo autonomo, problemi ad alimentarsi da solo e nel controllare la funzione intestinale o vescicale.</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="4">Demenza molto grave</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Il paziente richiede assistenza totale perché completamente incapace di comunicare, in stato vegetativo, allettato, incontinente.</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="5">Demenza terminale</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
          </el-row>

          <el-row :gutter="20" class="section">
            <el-divider content-position="center">Attività sociali</el-divider>
            <el-row>
              <el-col :offset="1" :span="24">
                <el-row>
                  <el-form-item prop="q4_result">
                    <el-radio-group v-model="test_cdrs.q4_result" style="width: 90%">
                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Attività indipendente e livelli usuali in lavoro, acquisti, pratiche burocratiche</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="0">Normale</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Solo dubbia compromissione nelle attività descritte</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="0.5">Demenza dubbia</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Incapace di compiere indipendentemente le attività, a esclusione di attività facili</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="1">Demenza lieve</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Nessuna pretesa di attività indipendente fuori casa; in grado di essere portato fuori casa</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="2">Demenza moderata</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Nessuna pretesa di attività indipendente fuori casa; non in grado di uscire</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="3">Demenza grave</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Il paziente presenta severo deficit del linguaggio o della comprensione, problemi nel riconoscere i famigliari, incapacità a deambulare in modo autonomo, problemi ad alimentarsi da solo e nel controllare la funzione intestinale o vescicale.</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="4">Demenza molto grave</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Il paziente richiede assistenza totale perché completamente incapace di comunicare, in stato vegetativo, allettato, incontinente.</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="5">Demenza terminale</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
          </el-row>

          <el-row :gutter="20" class="section">
            <el-divider content-position="center">Casa e tempo libero</el-divider>
            <el-row>
              <el-col :offset="1" :span="24">
                <el-row>
                  <el-form-item prop="q5_result">
                    <el-radio-group v-model="test_cdrs.q5_result" style="width: 90%">
                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Vita domestica e interessi intellettuali conservati</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="0">Normale</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Vita domestica e interessi attuali lievemente compromessi</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="0.5">Demenza dubbia</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Lieve ma sensibile compromissione della vita domestica; abbandono hobby e interessi</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="1">Demenza lieve</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Interessi ridotti, non sostenuti, vita domestica ridotta a funzioni semplici</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="2">Demenza moderata</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Nessuna funzionalità fuori dalla propria camera</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="3">Demenza grave</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Il paziente presenta severo deficit del linguaggio o della comprensione, problemi nel riconoscere i famigliari, incapacità a deambulare in modo autonomo, problemi ad alimentarsi da solo e nel controllare la funzione intestinale o vescicale.</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="4">Demenza molto grave</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Il paziente richiede assistenza totale perché completamente incapace di comunicare, in stato vegetativo, allettato, incontinente.</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="5">Demenza terminale</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
          </el-row>

          <el-row :gutter="20" class="section">
            <el-divider content-position="center">Cura personale</el-divider>
            <el-row>
              <el-col :offset="1" :span="24">
                <el-row>
                  <el-form-item prop="q6_result">
                    <el-radio-group v-model="test_cdrs.q6_result" style="width: 90%">
                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Interamente capace di curarsi della propria persona</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="0">Normale</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Richiede facilitazione</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="0.5">Demenza dubbia</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Richiede aiuto per vestirsi, igiene, utilizzazione di effetti personali</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="1">Demenza lieve</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Richiede molta assistenza per cura personale; non incontinenza urinaria</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="2">Demenza moderata</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Richiede molta assistenza per cura personale; incontinenza urinaria</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="3">Demenza grave</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Il paziente presenta severo deficit del linguaggio o della comprensione, problemi nel riconoscere i famigliari, incapacità a deambulare in modo autonomo, problemi ad alimentarsi da solo e nel controllare la funzione intestinale o vescicale.</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="4">Demenza molto grave</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                      <el-divider />

                      <el-row>
                        <el-col :span="24">
                          <el-col :span="18">
                            <el-form-item>Il paziente richiede assistenza totale perché completamente incapace di comunicare, in stato vegetativo, allettato, incontinente.</el-form-item>
                          </el-col>
                          <el-col :span="6">
                            <el-form-item>
                              <el-radio :label="5">Demenza terminale</el-radio>
                            </el-form-item>
                          </el-col>
                        </el-col>
                      </el-row>
                    </el-radio-group>
                  </el-form-item>
                </el-row>
              </el-col>
            </el-row>
          </el-row>
          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_cdrs.total_string}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "cdrs",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_cdrs: this.test,
      test_prev_cdrs: this.test_prev,
      test_next_cdrs: this.test_next,
      test_name_cdrs: this.test_name,
      test_model_cdrs: this.test_model,
      test_type_cdrs: this.test_type,
      patient_id_cdrs: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_cdrs,
        test_model: this.test_model_cdrs,
        test_id: test_id,
        type_id: this.test_type_cdrs
      };

      const response = await Test.show(params);
      this.test_cdrs = response.data.test;
      this.test_name_cdrs = response.data.test_name;
      this.test_prev_cdrs = response.data.test_prev;
      this.test_next_cdrs = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_cdrs === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_cdrs === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>

<style scoped>
  .section {
    margin-top: 3rem;
  }
</style>
