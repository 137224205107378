export const cdi1Rules = {
  cod_ats               : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  cod_struttura         : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  anno_ricovero         : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  num_pratica           : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  data_ammissione       : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  cod_ats_prov          : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  com_nascita           : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  cittadinanza          : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  stato_civile          : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  titolo_studio         : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  com_resid             : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  cod_ats_resid         : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  com_resid_carico      : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  prov_assistito        : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  richiesta_insert      : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  val_richiesta_insert  : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  motiv_richiesta_insert: [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  tariffa_ssr           : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  nazione               : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  num_id_ts             : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  scadenza_ts           : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  num_id_istituzione    : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  nom_id_istituzione    : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
}

export const cdi2Rules = {
  data_valutazione        : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  tipo_valutazione        : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  bagno                   : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  vestirsi                : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  toilette                : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  spostarsi               : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  continenza              : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  alimentazione           : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  ind_adl                 : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  ind_iadl                : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  disturbi_comp           : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  stato_cognitivo         : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  deambulazione           : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  ass_form                : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  ass_inform              : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  ossigenoterapia         : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  tracheostomia           : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  vent_assistita          : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  insuff_renale           : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  les_decubito            : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  les_cutanee             : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  incont_urinaria         : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  incont_fecale           : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  aus_movimento           : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  aus_dotazione           : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  nut_artificiale         : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  reg_alimentare          : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  diagn_p                 : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
  diagn_s1                : [{ required: true, message: "Campo Obbligatorio", trigger: "blur" }],
}

export const rules = { cdi1Rules, cdi2Rules }