<template>
    <el-row :gutter="20" class="no-margin-row">
        <el-col :span="24"><h2><font-awesome-icon icon="bath"/> Bagni</h2></el-col>
        <el-col :span="24">
            <arki-table action="bath" :profile_id="profile_id"/>
        </el-col>
        <el-col :span="24"><h2><font-awesome-icon icon="toilet"/> Evacuazioni</h2></el-col>
        <el-col :span="24">
            <arki-table action="purge" :profile_id="profile_id" :hasInfo="true"/>
        </el-col>
        <el-col :span="24"><h2><font-awesome-icon icon="shield-alt"/> Contenzioni</h2></el-col>
        <el-col :span="24">
            <arki-table action="protection" :profile_id="profile_id" :hasInfo="true" :hasVerification="true" :extendedNotes="true"/>
        </el-col>
        <el-col :span="24"><h2><font-awesome-icon icon="tablets"/> Somministrazioni Terapie</h2></el-col>
        <el-col :span="24">
            <arki-table action="subministration" :profile_id="profile_id" :noAction="true" :extendedNotes="true"/>
        </el-col>
        <el-col :span="24"><h2><font-awesome-icon icon="user-injured"/> Lesioni</h2></el-col>
        <el-col :span="24">
            <arki-table action="wound" :profile_id="profile_id" :noAction="true"/>
        </el-col>
        <el-col :span="24"><h2><font-awesome-icon icon="dumbbell"/> Fisioterapie</h2></el-col>
        <el-col :span="24">
            <arki-table action="physiotherapy" :profile_id="profile_id" :noAction="true"/>
        </el-col>
    </el-row>
</template>
<script>
import ArkiTable from '@/js/components/ArkiTable';
export default {
    name: 'Cartella',
    props: {
        profile_id: String,
    },
    components:{
        ArkiTable
    },
}
</script>
