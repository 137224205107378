<template>
  <div>
    <!-- Anamnesi ponderale -->
    <div
      style="text-align: center; text-decoration: underline; font-weight: bold"
    >
      ANAMNESI PONDERALE
    </div>
    <br />
    <div>
      <b> Peso corporeo attuale (Kg):</b>
      {{ registry.peso }} <br />
      <b> Altezza/ALtezza stimata (Cm): </b>
      {{ registry.altezza }} <br />
      <b> BMI (Kg/m2):</b>
      {{ registry.bmi }}
      <br />
      <b> Perdita di peso negli ultimi 6 mesi >5%:</b>
      {{
        lastSixMonthWeightDiminution && lastSixMonthWeightDiminution >= 5
          ? "Si (" + lastSixMonthWeightDiminution + "%)"
          : "No"
      }}
      <br />
      <b> MUST:</b>
      <span v-if="mustTest">
        {{ mustTest.total_description }} (punteggio:
        {{ mustTest.total_string }}) - eseguito il:
        {{ mustTest.clean_date_time_test }}
      </span>
      <span v-else>Test non eseguito</span>
    </div>
    <br />
    <!-- Anamnesi alimentare -->
    <div
      style="text-align: center; text-decoration: underline; font-weight: bold"
    >
      ANAMNESI ALIMENTARE
    </div>
    <br />
    <div>
      <b>Alimentazione:</b>
      {{ config.nutrizione_artificiale[registry.nutrizione_artificiale] }}
      <br />
      <b>Composizione quali-quantitativa:</b>
      {{ config.dieta[registry.dieta] }}
      <br />
      <b>Integratori alimentari:</b>
      {{ config.integratori_alimentari[registry.integratori_alimentari] }}
      <br />
      <b>Potus</b>
      {{ config.potus[registry.potus] }}
      <br />
      <b>Consumo di:</b>
      <el-tag
        effect="dark"
        v-for="(item, index) in consumo_alcolici"
        :key="index"
      >
        {{ item }}
      </el-tag>
      <br />
      <b>Autonomia nell'alimentazione:</b>
      {{
        config.autonomia_nella_alimentazione[
          registry.autonomia_nella_alimentazione
        ]
      }}
      <br />
      <b>Difficoltà nella masticazione:</b>
      {{
        config.difficolta_nella_masticazione[
          registry.difficolta_nella_masticazione
        ]
      }}
      <br />
      <b>Disfagia:</b>
      {{ config.disfagia[registry.disfagia] }}
      <br />
      <b>Note sull'alimentazione:</b>
      {{ registry.alimentazione_note }}
    </div>
    <br />
    <!-- Esame obiettivo -->
    <div
      style="text-align: center; text-decoration: underline; font-weight: bold"
    >
      ESAME OBIETTIVO
    </div>
    <br />
    <div>
      <b>Secchezza della cute:</b>
      {{ config.secchezza_cute[registry.secchezza_cute] }}
      <br />
      <b>Pallore cutaneo:</b>
      {{ config.cute[registry.cute] }}
      <br />
      <b>Sarcopenia:</b>
      {{ config.sarcopenia[registry.sarcopenia] }}
      <br />
      <b>Edemi declivi:</b>paiAree
      {{ config.edemi_declivi[registry.edemi_declivi] }}
      <br />
      <b>Astenia:</b>
      {{ config.astenia[registry.astenia] }}
      <br />
    </div>
    <br />
  </div>
</template>
<script>
export default {
  name: "NutritionalEvaluationCard",
  props: {
    registry: Object,
    lastSixMonthWeightDiminution: {
      type: [Number, Boolean],
      required: false,
    },
    mustTest: Object,
  },
  data() {
    return {};
  },
  computed: {
    config() {
      return this.$store.state.config.anagrafica;
    },
    consumo_alcolici() {
      let items = [];
      this.registry.fumo_sig_die > 0
        ? items.push("Fumo (al giorno: " + this.registry.fumo_sig_die + ")")
        : null;
      this.registry.fumo == 2 && this.registry.fumo_sig_die > 0
        ? items.push("Ex Fumo (al giorno: " + this.registry.fumo_sig_die + ")")
        : null;
      this.registry.vino_die > 0
        ? items.push("Vino (al giorno: " + this.registry.vino_die + ")")
        : null;
      this.registry.birra_die > 0
        ? items.push("Birra (al giorno: " + this.registry.birra_die + ")")
        : null;
      this.registry.liquori_die > 0
        ? items.push("Liquori (al giorno: " + this.registry.liquori_die + ")")
        : null;
      return items;
    },
  },
};
</script>