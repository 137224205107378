<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-divider content-position="center">Bmi (Kg/M2)</el-divider>
          <el-col :span="24" class="category">
            <el-col :span="24">
              <el-form-item prop="q1_result">
                <el-row>
                  <el-col :span="16">
                    <el-form-item label="Punteggio BMI" required />
                  </el-col>
                  <el-col :span="8">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q1_result">
                            <el-form-item>
                              <el-radio :label="2">&lt; 18.5 (Sottopeso)</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">18.5-20 (Sottopeso)</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="0">20-25 (Peso Desiderabile)</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="3">25-30 (Sovrappeso)</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="4">&gt; 30 (Obeso)</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-divider content-position="center">Perdita di peso</el-divider>
          <el-col :span="24" class="category">
            <el-col :span="24">
              <el-form-item prop="q2_result">
                <el-row>
                  <el-col :span="16">
                    <el-form-item
                      label="Non programmata perdita di peso negli ultimi 3 mesi"
                      required
                    />
                  </el-col>
                  <el-col :span="8">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q2_result">
                            <el-form-item>
                              <el-radio :label="2">&gt; 10%</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="1">5-10%</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="0">&lt; 5%</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-divider content-position="center">Malattie acute fisiche/psicologiche</el-divider>
          <el-col :span="24" class="category">
            <el-col :span="24">
              <el-form-item prop="q3_result">
                <el-row>
                  <el-col :span="16">
                    <el-col :span="20">
                      <el-form-item
                        label="Presenza di malattie acute quando il paziente non si nutre per più di 5 gg."
                        required
                      />
                    </el-col>
                  </el-col>
                  <el-col :span="8">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="result.q3_result">
                            <el-form-item>
                              <el-radio :label="2">Malattia Presente</el-radio>
                            </el-form-item>
                            <el-form-item>
                              <el-radio :label="0">Malattia Non Presente</el-radio>
                            </el-form-item>
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Rischio">
              <el-input v-model="totalDescription" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Must",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 6
      },
      result: {},
      testRules: rules(3)
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_description: this.totalDescription
          };
          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    },
    totalDescription() {
      let condMap = _.cond([
        [_.matches({ total: 0 }), _.constant("Basso rischio")],
        [_.matches({ total: 1 }), _.constant("Medio Rischio")],
        [_.conforms({ total: n => _.gte(n, 2) }), _.constant("Alto rischio")]
      ]);
      return condMap(this.form);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(filterResults(this.result));
        const toZero = [3, 4];
        results = results.map(v => {
          toZero.some(x => x == v) && (v = 0);
          return v;
        });
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.category {
  margin-bottom: 15px;
  margin-top: 15px;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>