<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24" class="relative">
          <el-form-item label="Cognome e Nome Familiare" required>
            <el-col :span="12">
              <el-form-item>
                <el-autocomplete
                  v-model.trim="result.patient_relative_lastname"
                  :fetch-suggestions="querySearchCognome"
                  placeholder="Cognome"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <el-autocomplete
                  v-model.trim="result.patient_relative_firstname"
                  :fetch-suggestions="querySearchNome"
                  placeholder="Nome"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>

        <el-col v-if="relativeInserted()" :span="24" v-for="idx in 15" :key="'q-' + idx">
          <el-form-item :prop="`q${idx}_result`">
            <el-row>
              <el-col :span="15">
                <el-form-item :label="questions[idx]" required />
              </el-col>
              <el-col :span="9">
                <el-row>
                  <el-col>
                    <div>
                      <el-radio-group v-model="result[`q${idx}_result`]">
                        <el-form-item v-for="opt in grades" :key="'optt-' + opt.points">
                          <el-radio :label="opt.points">{{opt.desc}}</el-radio>
                        </el-form-item>
                      </el-radio-group>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form-item>
          <el-divider />
        </el-col>
      </el-row>

      <el-row :gutter="20" v-if="relativeInserted()" class="bottom-space">
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Grado di STRESS">
              <el-input v-model="stressLevel" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults } from "../constants";
import TestActions from "../components/TestActions";
import Test from "@/js/api/services/Test";

export default {
  name: "Rss",
  props: ["loading", "patientName", "patientId"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 60
      },
      result: {},
      testRules: rules(15),
      questions: {
        "1": "Pensate mai di non riuscire a fronteggiare la situazione?",
        "2": "Pensate mai di aver bisogno di una pausa?",
        "3": "Vi capita di sentirvi depressi dalla situazione?",
        "4": "La vostra salute ha in qualche modo sofferto?",
        "5": `Vi preoccupate che succedano incidenti a ${this.patientName}?`,
        "6": "Pensate mai che il problema non abbia vie di uscite?",
        "7": "Avete difficoltà ad andare in vacanza?",
        "8": "Quanto è stata modificata la vostra vita sociale?",
        "9": "Quanto è turbato il vostro 'menage' familiare?",
        "10": `Il vostro sonno viene interrotto da ${this.patientName}?`,
        "11": "Si è abbassato il vostro tenore di vita?",
        "12": `Vi sentite imbarazzati da ${this.patientName}?`,
        "13": `Siete nell' assoluta impossibilità di ricevere visite?`,
        "14": `Vi capita mai di essere contrariati o arrabbiati con ${this.patientName}?`,
        "15": `Vi capita a volte di sentirvi frustrati a causa di ${this.patientName}?`
      },
      grades: [
        { points: 0, desc: "Mai-assolutamente" },
        { points: 1, desc: "Di rado-un pó" },
        { points: 2, desc: "A volte-moderatamente" },
        { points: 3, desc: "Frequentemente-molto" },
        { points: 4, desc: "Sempre-moltissimo" }
      ],
      firstName: {},
      lastName: {}
    };
  },
  methods: {
    async initTest() {
      let params = {
        model: "Rss",
        patient_id: this.patientId
      };

      const response = await Test.getTestData(params);
      this.firstName = response.data.firstName || [];
      this.lastName = response.data.lastName || [];
    },
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            total_description: this.stressLevel
          };
          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    relativeInserted() {
      let fields = [
        this.result.patient_relative_lastname,
        this.result.patient_relative_firstname
      ];
      return fields.every(f => !_.isNil(f) && f.length >= 3);
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    },
    querySearchNome(queryString, cb) {
      var links = this.firstName;
      var results = queryString
        ? links.filter(this.createFilter(queryString))
        : links;
      // call callback function to return suggestions
      cb(results);
    },
    querySearchCognome(queryString, cb) {
      var links = this.lastName;
      var results = queryString
        ? links.filter(this.createFilter(queryString))
        : links;
      // call callback function to return suggestions
      cb(results);
    },
    createFilter(queryString) {
      return link => {
        return (
          link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {}
  },
  computed: {
    stressLevel() {
      let condMap = _.cond([
        [
          _.conforms({ total: n => _.inRange(n, 0, 16) }),
          _.constant("Assente o lieve")
        ],
        [
          _.conforms({ total: n => _.inRange(n, 16, 31) }),
          _.constant("Moderato")
        ],
        [_.conforms({ total: n => _.inRange(n, 31, 60) }), _.constant("Grave")]
      ]);
      return condMap(this.form);
    },
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  },
  mounted() {
    this.initTest();
  }
};
</script>

<style lang="css">
.relative {
  margin-top: 30px;
}
.bottom-space {
  margin-bottom: 50px;
}
.el-autocomplete {
  width: 100% !important;
}
</style>