import _ from 'lodash';


const ageA = _.cond([
  [
    _.conforms({ age: n => _.lt(n, 65) }),
    ({ age }) => _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [0-4]",
      age_interval: `INTERVALLO DI ETÁ (${age}) [ < 65 ]`,
      factor: "0",
    })(age),
  ],
  [
    _.conforms({ age: n => _.inRange(n, 65, 70) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [0-4]",
      age_interval: "INTERVALLO DI ETÁ [65-69]",
      factor: "+0.4",
    }),
  ],
  [
    _.conforms({ age: n => _.inRange(n, 70, 75) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [0-4]",
      age_interval: "INTERVALLO DI ETÁ [70-74]",
      factor: "+0.7",
    }),
  ],
  [
    _.conforms({ age: n => _.inRange(n, 75, 80) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [0-4]",
      age_interval: "INTERVALLO DI ETÁ [75-79]",
      factor: "+1.0",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 80, 85) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [0-4]",
      age_interval: "INTERVALLO DI ETÁ [80-84]",
      factor: "+1.5",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 85, 90) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [0-4]",
      age_interval: "INTERVALLO DI ETÁ [85-89]",
      factor: "+2.2",
    })
  ],
  [
    _.conforms({ age: n => _.gt(n, 89) }),
    ({ age }) => _.constant({
        education_years: "ANNI DI SCOLARIZZAZIONE [0-4]",
        age_interval: `INTERVALLO DI ETÁ (${age}) [ > 90 ]`,
        factor: "0",
    })(age),
  ]
]);

const ageB = _.cond([
  [
    _.conforms({ age: n => _.lt(n, 65) }),
    ({ age }) => _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [5-7]",
      age_interval: `INTERVALLO DI ETÁ (${age}) [ < 65 ]`,
      factor: "0",
    })(age),
  ],
  [
    _.conforms({ age: n => _.inRange(n, 65, 70) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [5-7]",
      age_interval: "INTERVALLO DI ETÁ [65-69]",
      factor: "-1.1",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 70, 75) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [5-7]",
      age_interval: "INTERVALLO DI ETÁ [70-74]",
      factor: "-0.7",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 75, 80) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [5-7]",
      age_interval: "INTERVALLO DI ETÁ [75-79]",
      factor: "-0.3",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 80, 85) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [5-7]",
      age_interval: "INTERVALLO DI ETÁ [80-84]",
      factor: "+0.4",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 85, 90) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [5-7]",
      age_interval: "INTERVALLO DI ETÁ [85-89]",
      factor: "+1.4",
    })
  ],
  [
    _.conforms({ age: n => _.gt(n, 89) }),
    ({ age }) => _.constant({
        education_years: "ANNI DI SCOLARIZZAZIONE [5-7]",
        age_interval: `INTERVALLO DI ETÁ (${age}) [ > 90 ]`,
        factor: "0",
    })(age),
  ]
]);

const ageC = _.cond([
  [
    _.conforms({ age: n => _.lt(n, 65) }),
    ({ age }) => _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [8-12]",
      age_interval: `INTERVALLO DI ETÁ (${age}) [ < 65 ]`,
      factor: "0",
    })(age),
  ],
  [
    _.conforms({ age: n => _.inRange(n, 65, 70) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [8-12]",
      age_interval: "INTERVALLO DI ETÁ [65-69]",
      factor: "-2.0",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 70, 75) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [8-12]",
      age_interval: "INTERVALLO DI ETÁ [70-74]",
      factor: "-1.6",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 75, 80) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [8-12]",
      age_interval: "INTERVALLO DI ETÁ [75-79]",
      factor: "-1.0",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 80, 85) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [8-12]",
      age_interval: "INTERVALLO DI ETÁ [80-84]",
      factor: "-0.3",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 85, 90) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [8-12]",
      age_interval: "INTERVALLO DI ETÁ [85-89]",
      factor: "+0.8",
    })
  ],
  [
    _.conforms({ age: n => _.gt(n, 89) }),
    ({ age }) => _.constant({
        education_years: "ANNI DI SCOLARIZZAZIONE [8-12]",
        age_interval: `INTERVALLO DI ETÁ (${age}) [ > 90 ]`,
        factor: "0",
    })(age),
  ]
]);

const ageD = _.cond([
  [
    _.conforms({ age: n => _.lt(n, 65) }),
    ({ age }) => _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [13-17]",
      age_interval: `INTERVALLO DI ETÁ (${age}) [ < 65 ]`,
      factor: "0",
    })(age),
  ],
  [
    _.conforms({ age: n => _.inRange(n, 65, 70) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [13-17]",
      age_interval: "INTERVALLO DI ETÁ [65-69]",
      factor: "-2.8",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 70, 75) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [13-17]",
      age_interval: "INTERVALLO DI ETÁ [70-74]",
      factor: "-2.3",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 75, 80) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [13-17]",
      age_interval: "INTERVALLO DI ETÁ [75-79]",
      factor: "-1.7",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 80, 85) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [13-17]",
      age_interval: "INTERVALLO DI ETÁ [80-84]",
      factor: "-0.9",
    })
  ],
  [
    _.conforms({ age: n => _.inRange(n, 85, 90) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [13-17]",
      age_interval: "INTERVALLO DI ETÁ [85-89]",
      factor: "+0.3",
    })
  ],
  [
        _.conforms({ age: n => _.gt(n, 89) }),
        ({ age }) => _.constant({
            education_years: "ANNI DI SCOLARIZZAZIONE [13-17]",
            age_interval: `INTERVALLO DI ETÁ (${age}) [ > 90 ]`,
            factor: "0",
        })(age),
  ]
]);

const ageE = _.cond([
  [
    _.conforms({ age: n => _.gt(n, 89) }),
    _.constant({
      education_years: "ANNI DI SCOLARIZZAZIONE [> 17]",
      age_interval: "INTERVALLO DI ETÁ [ > 89]",
      factor: "0",
    })
  ],
  [_.stubTrue, ({ age }) => _.constant({
    education_years: "ANNI DI SCOLARIZZAZIONE [> 17]",
    age_interval: `INTERVALLO DI ETÁ [ ${age} ]`,
    factor: "0",
  })(age)]
]);



const educationMap = _.cond([
  [_.conforms({ educationYears: n => _.inRange(n, 0, 5) }), ageA],
  [_.conforms({ educationYears: n => _.inRange(n, 5, 8) }), ageB],
  [_.conforms({ educationYears: n => _.inRange(n, 8, 13) }), ageC],
  [_.conforms({ educationYears: n => _.inRange(n, 13, 18) }), ageD],
  [_.conforms({ educationYears: n => _.gt(n, 17) }), ageE]
]);



const totalDescription = _.cond([
    [_.conforms({ correct_total: n => _.lt(n, 11) }), _.constant('Compromissione cognitiva severa.')],
    [_.conforms({ correct_total: n => _.inRange(n, 11, 18) }), _.constant('Compromissione cognitiva moderata')],
    [_.conforms({ correct_total: n => _.inRange(n, 18, 24) }), _.constant('Compromissione cognitiva lieve')],
    [_.conforms({ correct_total: n => _.inRange(n, 24, 26) }), _.constant('Borderline')],
    [_.conforms({ correct_total: n => _.inRange(n, 26, 31) }), _.constant('Performance cognitiva nella norma')]
]);


export default { totalDescription, educationMap };
