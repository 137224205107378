<template>
  <div>
    <arki-filters
      ref="filters"
      @filtered="fetch"
      target="reports"
      :subTarget="activeName"
      :dateRangeFilter="true"
      :optionsFilter="optionFilterEnabled[activeName]"
      :optionSpan="8"
    />
    <div class="with-filters">
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="Eventi Critici" name="criticalEvents"></el-tab-pane>
        <el-tab-pane label="Bagni" name="bathsReport"></el-tab-pane>
      </el-tabs>
      <div>
        <!-- table data -->
        <div :span="24">
          <component
            :is="activeComponent"
            :key="componentKey"
            :filters="params"
            class="margin-top-10"
          ></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ArkiFilters from "@/js/components/ArkiFilters";
import CriticalEventsReport from "./ReportComponents/CriticalEventsReport";
import BathsReport from "./ReportComponents/BathsReport.vue";

export default {
  name: "Reports",
  components: {
    ArkiFilters,
    CriticalEventsReport,
    BathsReport,
  },
  data() {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    return {
      filters: {
        dateRange: [oneMonthAgo, new Date()],
        date: new Date(),
        option: [],
        patient_id: null,
        criticals: null,
      },
      currentFilters: {},
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      loading: true,
      submitLoading: false,
      criticalEventTypes: [],
      activeName: "criticalEvents",
      activeComponent: "CriticalEventsReport",
      componentKey: 0,
      label: "Eventi Critici",
      params: {},
      optionFilterEnabled: {
        criticalEvents: true,
        bathsReport: false,
      },
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    patients() {
      return this.$store.state.patients;
    },
    config() {
      return this.$store.state.config.wounds;
    },
    patients() {
      return this.$store.state.patients;
    },
  },
  methods: {
    async fetch(filters) {
      this.loading = true;

      this.params = {
        date_from: moment(filters.dateRange[0]).format("DD/MM/YYYY"),
        date_to: moment(filters.dateRange[1]).format("DD/MM/YYYY"),
        patient_id: filters.patient_id,
        event_type: filters.option,
        piano:
          filters.piano && filters.piano != ""
            ? filters.piano
            : 0 + "-" + this.$store.state.structureId,
        page: 1,
      };

      this.loading = false;
    },

    async handleClick(tab, event) {
      const component = {
        criticalEvents: "CriticalEventsReport",
        bathsReport: "BathsReport",
      }[tab.name];
      if (component) {
        this.activeName = tab.name;
        this.activeComponent = component;
        this.label = tab.label;
       }
      this.componentKey += 1;
    },
  },
};
</script>
