<template>
  <el-container class="login-container">
    <el-main>
      <el-alert
        v-if="shouldChangePassword"
        type="warning"
        show-icon
        @close="onAlertClosed"
        class="alert-notification"
        center
      >
        <slot name="title">
          La password LDAP sta per scadere
          <el-button
            plain
            round
            @click="changePwdFormVisible = true"
            size="mini"
            style="margin-left: 15px"
            >Aggiorna</el-button
          ></slot
        >
      </el-alert>
      <el-row type="flex" class="form-login-container" justify="center">
        <el-col :xs="22" :sm="16" :md="16" :lg="12" :xl="12">
          <el-card shadow="always">
            <div class="logo-container">
              <img src="/images/arkicare_logo.jpg" alt="logo" />
            </div>
            <el-steps
              :active="active"
              finish-status="success"
              class="margin-bottom"
              align-center
            >
              <el-step title="Autenticazione"></el-step>
              <el-step title="Codice di verifica"></el-step>
              <el-step title="Struttura"></el-step>
            </el-steps>
            <el-tabs v-model="activeTabs" class="arkitab">
              <el-tab-pane label="Login" name="login">
                <el-form
                  label-position="top"
                  label-width="100px"
                  :model="formLogin"
                  :rules="rules"
                  ref="formLogin"
                >
                  <el-form-item label="Username" prop="username">
                    <el-input
                      v-model="formLogin.username"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="Password" prop="password">
                    <el-input
                      v-model="formLogin.password"
                      type="password"
                      autocomplete="off"
                      show-password
                    ></el-input>
                  </el-form-item>
                  <el-link type="primary" @click="showFormResetPassword = true">Reset Password</el-link>
                  <el-form-item class="float-right">
                    <el-button
                      type="primary"
                      native-type="submit"
                      @click.native.prevent="login('formLogin')"
                      plain
                      :loading="loginButtonLoading"
                      class="float-right"
                      >Avanti</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane
                label="Two factor authentication"
                name="twoFactorAuth"
              >
                <el-form
                  label-position="top"
                  label-width="100px"
                  :model="formTwoFactorAuth"
                  :rules="twoFactorAuthRules"
                  ref="formTwoFactorAuth"
                >
                  <!-- <el-form-item label="Codice di autenticazione" prop="code">
                    <el-input v-model="formTwoFactorAuth.code" autocomplete="off"></el-input>
                  </el-form-item>-->

                  <el-form-item label="Codice di autenticazione" prop="code">
                    <el-input
                      v-model="formTwoFactorAuth.code"
                      autocomplete="off"
                    >
                      <el-button
                        type="primary"
                        plain
                        slot="append"
                        :disabled="isDisabled"
                        @click.native.prevent="renewCode('formTwoFactorAuth')"
                        icon="el-icon-refresh"
                        >Rigenera codice</el-button
                      >
                    </el-input>
                  </el-form-item>

                  <el-col :span="24">
                    <md-progress-spinner
                      v-if="renewCodeLoader"
                      :md-diameter="15"
                      :md-stroke="3"
                      md-mode="indeterminate"
                    ></md-progress-spinner>
                    <span v-if="resendConfirm">Nuovo codice inviato!</span>
                  </el-col>

                  <el-form-item class="float-right">
                    <el-button
                      type="primary"
                      native-type="submit"
                      @click.native.prevent="
                        twoFactorAuthCheck('formTwoFactorAuth')
                      "
                      plain
                      :loading="twoFactorAuthButtonLoading"
                      class="float-right"
                      >Avanti</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="Struttura" name="selectStructure">
                <el-form
                  :inline="true"
                  label-position="top"
                  label-width="100px"
                  :model="formStructure"
                  :rules="structuresRules"
                  ref="formStructure"
                >
                  <el-form-item label="Struttura" prop="structure">
                    <el-select
                      filterable
                      v-model="formStructure.structure"
                      placeholder="Seleziona Struttura"
                      @change="getAuthDepartments"
                      :loading="Structureloading"
                    >
                      <el-option
                        v-for="(item, index) in structures"
                        :key="index"
                        :label="item"
                        :value="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="Reparto" prop="department">
                    <el-select
                      v-model="formStructure.department"
                      placeholder="Seleziona Reparto"
                      :loading="Departmentloading"
                    >
                      <el-option
                        v-for="item in departments"
                        :key="item.key"
                        :label="item.value"
                        :value="item.key"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item class="float-right">
                    <el-button
                      type="primary"
                      @click.native.prevent="checkDepartment('formStructure')"
                      :loading="loginLoading"
                      plain
                      class="float-right"
                      >Accedi</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>
      </el-row>
      <el-dialog
        title="LDAP"
        :visible.sync="changePwdFormVisible"
        :destroy-on-close="true"
        :close-on-press-escape="false"
        :before-close="onDialogClose"
      >
        <el-form
          :model="ldapPwdForm"
          :rules="ldapRules"
          ref="ldapForm"
          :disabled="changingLdapPassword"
        >
          <el-form-item label="Password corrente" prop="oldPassword" required>
            <el-input v-model="ldapPwdForm.oldPassword"></el-input>
          </el-form-item>
          <el-form-item label="Nuova password" prop="newPassword" required>
            <el-input v-model="ldapPwdForm.newPassword"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="onDialogClose(null)"
            :disabled="changingLdapPassword"
            >Annulla</el-button
          >
          <el-button
            type="primary"
            @click="changeLdapPwd"
            :disabled="changingLdapPassword"
            :loading="changingLdapPassword"
            >Conferma</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="RESET PASSWORD"
        :visible.sync="showFormResetPassword"
        :destroy-on-close="true"
        :close-on-press-escape="false"
        :before-close="onDialogClose"
        @keydown.native="handleKeydown"
      >
        <el-form
          :model="ldapPwdResetForm"
          :rules="ldapResetRules"
          ref="ldapResetForm"
          :disabled="resettingLdapPassword"
        >
          <el-form-item label="Username" prop="username" required>
            <el-input v-model="ldapPwdResetForm.username"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="onDialogResetClose(null)"
            :disabled="resettingLdapPassword"
            >Annulla</el-button
          >
          <el-button
            type="primary"
            @click="resetLdapPwd"
            :disabled="resettingLdapPassword"
            :loading="resettingLdapPassword"
            >Conferma</el-button
          >
        </span>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import Auth from "@/js/api/services/Auth";
import _ from "lodash";

export default {
  data() {
    return {
      active: 0,
      activeTabs: "login",
      formLogin: {},
      formStructure: {},
      formTwoFactorAuth: {},
      twoFactorAuthUserId: null,

      loginButtonLoading: false,
      twoFactorAuthButtonLoading: false,
      loginLoading: false,
      Structureloading: true,
      Departmentloading: true,

      structures: null,
      departments: null,
      twoFactorAuth: false,

      renewCodeLoader: false,
      resendConfirm: false,
      renewCodeShow: true,
      isDisabled: false,

      showFormResetPassword: false,
      changePwdFormVisible: false,
      ldapPwdForm: {
        oldPassword: null,
        newPassword: null,
      },
      ldapPwdResetForm: {
        username: null,
      },
      shouldChangePassword: false,
      changingLdapPassword: false,
      resettingLdapPassword: false,

      rules: {
        username: [
          { required: true, message: "Inserire username", trigger: "blur" },
        ],
        password: [
          { required: true, message: "Inserire password", trigger: "blur" },
        ],
      },
      twoFactorAuthRules: {
        code: [
          { required: true, message: "Inserire un codice", trigger: "blur" },
        ],
      },
      structuresRules: {
        structure: [
          { required: true, message: "Selezionare Struttura", trigger: "blur" },
        ],
        department: [
          { required: true, message: "Selezionare Reparto", trigger: "blur" },
        ],
      },
      ldapRules: {
        oldPassword: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" },
        ],
      },
      ldapResetRules: {
        username: [
          { required: true, message: "Campo obbligatorio", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    login(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginButtonLoading = true;
          this.submitLogin();
        } else {
          return false;
        }
      });
    },
    twoFactorAuthCheck(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.twoFactorAuthButtonLoading = true;
          this.submitLogin();
        } else {
          return false;
        }
      });
    },
    async getAuthStructures() {
      const response = await Auth.getStructures();
      this.structures =
        response.data && response.data.structures
          ? response.data.structures
          : response.data;
      this.Structureloading = false;
    },
    async getAuthDepartments() {
      let params = {
        structure_id: this.formStructure.structure,
      };
      const response = await Auth.fetchDepartments(params);
      this.departments = response.data;
      this.Departmentloading = false;
    },
    checkLdapPassword({ data } = { data: {} }) {
      const info = _.pick(data.shouldUpdateLdapPassword, [
        "success",
        "value",
        "error",
      ]);
      if (!info.success) {
        return this.$message({
          type: "warning",
          message: info.error,
        });
      }
      if (info.value) this.shouldChangePassword = true;
    },
    onAlertClosed() {
      this.shouldChangePassword = false;
      if (this.changePwdFormVisible) {
        this.changePwdFormVisible = false;
      }
    },
    onDialogClose(done) {
      if (this.changingLdapPassword) return;
      this.$refs["ldapForm"] && this.$refs["ldapForm"].resetFields();
      done && done();
      this.changePwdFormVisible = false;
    },
    onDialogResetClose(done) {
      if (this.resettingLdapPassword) return;
      this.$refs["ldapResetForm"] && this.$refs["ldapResetForm"].resetFields();
      done && done();
      this.showFormResetPassword = false;
    },
    changeLdapPwd() {
      this.$refs["ldapForm"].validate((valid) => {
        if (valid) {
          this.doLdapChange();
        } else {
          this.$message({
            type: "error",
            message: `Campi non validi`,
          });
          return false;
        }
      });
    },
    resetLdapPwd() {
      this.$refs["ldapResetForm"].validate((valid) => {
        if (valid) {
          this.doLdapResetPassword();
        } else {
          this.$message({
            type: "error",
            message: `Campi non validi`,
          });
          return false;
        }
      });
    },
    handleKeydown(event) {
      // Check if the pressed key is Enter (key code 13)
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent default form submission
        this.resetLdapPwd(); // Trigger the confirm button's action
      }
    },
    async doLdapChange() {
      try {
        this.changingLdapPassword = true;
        const payload = {
          username: this.formLogin.username,
          oldPassword: this.ldapPwdForm.oldPassword,
          newPassword: this.ldapPwdForm.newPassword,
        };
        const result = await Auth.updateLdapPassword(payload);
        if (result.success) {
          this.changingLdapPassword = false;
          this.shouldChangePassword = false;
          this.onDialogClose(null);
          return this.$message({
            type: "info",
            message: `Operazione eseguita con successo!`,
          });
        }
        this.$message({
          type: "error",
          message: `${result.error}`,
        });
      } catch (error) {
        this.$message({
          type: "error",
          message: error.message,
        });
      } finally {
        this.changingLdapPassword = false;
      }
    },
    async doLdapResetPassword() {
      try {
        this.resettingLdapPassword = true;
        const payload = {
          username: this.ldapPwdResetForm.username,
        };
        const result = await Auth.resetLdapPassword(payload);
        if (result.success) {
          this.resettingLdapPassword = false;
          this.onDialogClose(null);
          return this.$message({
            type: "info",
            message: `La nuova password ti è stata inviata tramite mail!`,
          });
        }
        this.$message({
          type: "error",
          message: `${result.error}`,
        });
      } catch (error) {
        this.$message({
          type: "error",
          message: error.message,
        });
      } finally {
        this.resettingLdapPassword = false;
        this.showFormResetPassword = false;
      }
    },
    submitLogin() {
      return this.$auth.login({
        params: {
          username: this.formLogin.username,
          password: this.formLogin.password,
          code: this.formTwoFactorAuth.code,
        },
        success: function (data) {
          this.$notify({
            title: "OK",
            message: "Autenticazione effettuata",
            type: "success",
          });
          this.checkLdapPassword(data);
          this.active = 2;
          this.activeTabs = "selectStructure";
          this.loginButtonLoading = false;
          this.getAuthStructures();
        },
        error: function (error) {
          if (error.response.status == 406) {
            this.twoFactorAuthUserId = error.response.data.userId;
            this.twoFactorAuth = true;
            this.active = 1;
            this.activeTabs = "twoFactorAuth";
            this.loginLoading = false;
            this.loginButtonLoading = false;
            if ((error.response.data.status = "error-mail"))
              this.$notify({
                title: "Errore",
                message: error.response.data.message,
                type: "error",
              });
          } else {
            this.has_error = true;
            this.$notify({
              title: "Errore",
              message: error.response.data.message,
              type: "error",
            });
            this.loginLoading = false;
            this.loginButtonLoading = false;
            this.twoFactorAuthButtonLoading = false;
          }
        },
        fetchUser: true,
        rememberMe: true,
      });
    },
    checkDepartment(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loginLoading = true;
          this.submit();
        } else {
          return false;
        }
      });
    },
    async submit() {
      let params = {
        structure: this.formStructure.structure,
        department: this.formStructure.department,
      };
      const response = await Auth.checkDepartment(params);
      this.$store.commit("setAuth", true);
      this.store();
    },
    async store() {
      this.$store.commit("setUser", this.$auth.watch.data);
      const response = await this.$store.dispatch("init");
      this.$router.push({ name: "Diario" });
      this.loginLoading = false;
    },
    async renewCode() {
      this.isDisabled = true;
      this.renewCodeShow = false;
      this.renewCodeLoader = true;
      let params = {
        username: this.formLogin.username,
        password: this.formLogin.password,
      };
      await Auth.reGenerateTwoFactorCode(params)
        .then((res) => {
          this.renewCodeLoader = false;
          this.renewCodeShow = true;
          this.resendConfirm = true;
          this.isDisabled = false;
          setTimeout(() => (this.resendConfirm = false), 30000);
        })
        .catch((error) => {
          this.isDisabled = false;
          this.renewCodeLoader = false;
          this.$notify({
            title: "Errore",
            message: error.response.data.message,
            type: "error",
          });
        });
    },
  },
};
</script>
<style lang="scss">
.logo-container {
  text-align: center;
  margin-bottom: 15px;
  img {
    height: 145px;
    width: auto;
  }
}
.el-form-item,
.el-select {
  width: 100%;
}
.arkitab .el-tabs__header {
  display: none !important;
}
.hidden-tab {
  display: none;
}
.alert-notification {
  margin-bottom: 20px;
}
</style>
