<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_barthel)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_barthel.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_barthel.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button class="float-right" round :disabled="disableNext" @click="show(test_next_barthel)">
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_barthel" :test_model="test_model_barthel" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>

              <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item />

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q1_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Alimentazione" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="test_barthel.q1_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="2" />
                            <el-radio-button :label="5" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="10" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q2_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Farsi il bagno / la doccia (lavarsi)" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="test_barthel.q2_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="1" />
                            <el-radio-button :label="3" />
                            <el-radio-button :label="4" />
                            <el-radio-button :label="5" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q3_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Igiene personale" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="test_barthel.q3_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="1" />
                            <el-radio-button :label="3" />
                            <el-radio-button :label="4" />
                            <el-radio-button :label="5" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q4_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Vestirsi (Abbigliamento)" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="test_barthel.q4_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="2" />
                            <el-radio-button :label="5" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="10" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q5_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Continenza intestinale" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="test_barthel.q5_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="2" />
                            <el-radio-button :label="5" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="10" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q6_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Continenza urinaria" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="test_barthel.q6_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="2" />
                            <el-radio-button :label="5" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="10" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q7_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Uso dei servizi (Toilette)" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="test_barthel.q7_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="2" />
                            <el-radio-button :label="5" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="10" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q8_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Trasferimento letto/sedia" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="test_barthel.q8_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="3" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="12" />
                            <el-radio-button :label="15" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q9_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Deambulazione" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="test_barthel.q9_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="3" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="12" />
                            <el-radio-button :label="15" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q10_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Salire le scale" required />
                  </el-col>
                  <el-col :span="12">
                    <el-row>
                      <el-col>
                        <div>
                          <el-radio-group v-model="test_barthel.q10_result">
                            <el-radio-button :label="0" />
                            <el-radio-button :label="2" />
                            <el-radio-button :label="5" />
                            <el-radio-button :label="8" />
                            <el-radio-button :label="10" />
                          </el-radio-group>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q11_result">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="Uso carrozzina" required />
                  </el-col>
                  <el-col :span="12">
                    <el-col :span="24">
                      <el-radio-group v-model="test_barthel.q11_result">
                        <el-radio-button :label="0" />
                        <el-radio-button :label="1" />
                        <el-radio-button :label="3" />
                        <el-radio-button :label="4" />
                        <el-radio-button :label="5" />
                      </el-radio-group>
                    </el-col>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>

      </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_barthel.total_string}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Valutazione">{{test_barthel.total_description}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Barthel",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_barthel: this.test,
      test_prev_barthel: this.test_prev,
      test_next_barthel: this.test_next,
      test_name_barthel: this.test_name,
      test_model_barthel: this.test_model,
      test_type_barthel: this.test_type,
      patient_id_barthel: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_barthel,
        test_model: this.test_model_barthel,
        test_id: test_id,
        type_id: this.test_type_barthel
      };

      const response = await Test.show(params);
      this.test_barthel = response.data.test;
      this.test_name_barthel = response.data.test_name;
      this.test_prev_barthel = response.data.test_prev;
      this.test_next_barthel = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_barthel === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_barthel === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
