<script>
import SosiaService from '@/js/api/services/Sosia2';
import SosiaEvaluationForm from './SosiaEvaluationForm.vue';
import SosiaValidationForm from './SosiaValidationForm.vue';

export default {
  name: 'SosiaEvaluationsTable',
  props: ['loading', 'list', 'config', 'hasDismissal', 'patientId', 'treatmentInfo'],
  components: { SosiaEvaluationForm, SosiaValidationForm },
  data() {
    return {
      evaluationFormVisible: false,
      validationFormVisible: false,
      record: null,
      parent: null
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 195;
    },
    isInitial() {
      return this.list && this.list.length == 0;
    },
    initialDate() {
      return _.get(
        this.list.find((x) => x.tipologia_di_verifica == 'I'),
        'data_inizio_condizione'
      );
    }
  },
  methods: {
    editRecord(row) {
      this.record = row;
      this.parent = null;
      this.evaluationFormVisible = true;
    },
    createRecord() {
      this.record = null;
      this.parent = null;
      this.evaluationFormVisible = true;
    },
    deleteRecord(id) {
      this.$confirm(`Sicuro di voler cancellare?`, 'Attenzione', {
        type: 'warning'
      })
        .then(() => {
          SosiaService.deleteEvaluation(id).then(() => {
            this.$notify({ type: 'success', message: 'Cancellazione eseguita' });
            this.$root.$emit('fetchPatient');
          });
        })
        .catch(() => {});
    },
    convalidaRecord(row) {
      this.parent = row;
      this.record = null;
      this.validationFormVisible = true;
    },
    editConvalida(row, parent) {
      this.record = row;
      this.parent = parent;
      this.validationFormVisible = true;
    },
    colClass({ row }) {
      if (row.deleted_at) return 'deleted-row';
      else return;
    },
    isClosed(row) {
      return !_.isNil(row.data_fine_condizione);
    }
  }
};
</script>

<template>
  <div>
    <el-table
      :data="list"
      ref="evalTable"
      style="width: 100%; font-size: 12px;"
      highlight-current-row
      row-key="id"
      :cell-class-name="colClass"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
            :data="props.row.convalide"
            ref="convTable"
            style="width: 100%; font-size: 12px"
            row-key="id"
            border
          >
            <el-table-column label="Data Convalida" prop="clean_convalida" min-width="50" />
            <el-table-column label="Tipologia Verifica" prop="clean_convalida" min-width="50">
              <template slot-scope="scope">
                {{ config['tipologia_di_verifica'][scope.row.tipologia_di_verifica] }}
              </template>
            </el-table-column>
            <el-table-column align="right" v-if="!hasDismissal" min-width="50">
              <template slot="header" slot-scope="scope"> </template>
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="danger"
                  @click="deleteRecord(scope.row.id)"
                  :disabled="Boolean(scope.row.deleted_at)"
                  class="s-button"
                  >Cancella</el-button
                >
                <el-button
                  size="mini"
                  :disabled="Boolean(scope.row.deleted_at)"
                  @click="editConvalida(scope.row, props.row)"
                  class="s-button"
                  >Modifica</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="Inizio" prop="clean_inizio" min-width="40" />
      <el-table-column label="Fine" prop="clean_fine" min-width="40" />
      <el-table-column label="Tipologia Verifica" min-width="50">
        <template slot-scope="scope"> {{ config['tipologia_di_verifica'][scope.row.tipologia_di_verifica] }} </template>
      </el-table-column>
      <el-table-column label="Classe" prop="classe" min-width="40" />

      <el-table-column align="right" v-if="!hasDismissal" min-width="90">
        <template slot="header" slot-scope="scope">
          <el-button type="primary" size="mini" icon="el-icon-plus" @click="createRecord()" v-show="!hasDismissal"
            >Nuova Valutazione</el-button
          >
        </template>
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            class="s-button"
            @click="deleteRecord(scope.row.id)"
            :disabled="Boolean(scope.row.deleted_at)"
            >Cancella</el-button
          >
          <el-button
            size="mini"
            :disabled="Boolean(scope.row.deleted_at)"
            @click="editRecord(scope.row)"
            class="s-button"
            >Modifica</el-button
          >

          <el-button
            :type="isClosed(scope.row) ? 'info' : 'success'"
            size="mini"
            :disabled="Boolean(scope.row.deleted_at)"
            @click="convalidaRecord(scope.row)"
            class="s-button"
            v-if="['I', 'S'].includes(scope.row.tipologia_di_verifica)"
            >Convalida</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <sosia-evaluation-form
      v-if="evaluationFormVisible"
      :patientId="patientId"
      :config="config"
      :visible.sync="evaluationFormVisible"
      :record="record"
      :treatmentInfo="treatmentInfo"
      :isInitial="isInitial"
      :initialDate="initialDate"
    />
    <sosia-validation-form
      v-if="validationFormVisible"
      :patientId="patientId"
      :config="config"
      :visible.sync="validationFormVisible"
      :record="record"
      :parent="parent"
      :treatmentInfo="treatmentInfo"
    />
  </div>
</template>

<style lang="scss">
/* .s-button {
  padding: 5px;
  width: 60px;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
} */
</style>