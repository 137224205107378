<template>
  <div>
    <!-- TERAPIA ABITUDINALE -->
    <el-row>
      <el-col :span="24" style="padding: 0 10px">
        <h2><center>TERAPIA ABITUDINALE</center></h2></el-col
      >
      <el-col :span="24">
        <el-table
          :data="therapies"
          style="width: 100%"
          :cell-class-name="cellClass"
          :header-cell-class-name="cellHeaderClass"
          :span-method="arraySpanMethod"
          v-loading="loading"
        >
          <el-table-column
            label="Farmaco"
            prop="therapy.farmaco_name"
            width="300"
            column-key="farmaco_name"
            fixed
          >
          </el-table-column>
          <el-table-column
            label="Q.tà"
            prop="therapy.quantity"
            width="60"
            align="center"
            column-key="quantity"
          >
          </el-table-column>
          <el-table-column
            label="Somm."
            prop="therapy.subministration_type.label"
            width="105"
            align="center"
            column-key="subministration_type"
          >
          </el-table-column>
          <el-table-column
            label="Inzio Terapia"
            prop="start_therapy"
            align="center"
            width="105"
            column-key="start_therapy"
          >
          </el-table-column>
          <el-table-column
            label="Fine Terapia"
            prop="end_therapy"
            align="center"
            width="105"
            column-key="end_therapy"
          >
          </el-table-column>
          <el-table-column label="Orario" align="center" column-key="allTime">
            <el-table-column
              :label="value"
              width="50"
              v-for="(value, index) in subministrationTimes"
              :key="index"
              align="center"
              column-key="time"
            >
              <template slot-scope="scope">
                <!-- v-if="scope.row.orario && scope.row.orario == index" -->
                <i
                  v-if="scope.row.orario && scope.row.orario.includes(index)"
                  class="el-icon el-icon-check"
                ></i>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="Giorno" align="center" column-key="allDate">
            <el-table-column
              :label="index"
              width="40"
              v-for="(value, index) in weekDay"
              :key="index"
              align="center"
              column-key="date"
            >
              <template slot-scope="scope">
                <span
                  v-if="
                    !scope.row.therapy.weekday && scope.row.therapy.every > 1
                  "
                  >Ogni {{ scope.row.therapy.every }} giorni</span
                >
                <i
                  v-else-if="
                    (scope.row.therapy.weekday &&
                      scope.row.therapy.weekday == value) ||
                    !scope.row.therapy.weekday
                  "
                  class="el-icon el-icon-circle-check"
                ></i>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            label="Indicazioni"
            prop="therapy.description"
            align="center"
            min-width="150px"
          >
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- TERAPIA AL BISOGNO -->
    <el-row style="margin-top: 30px">
      <el-col :span="24" style="padding: 0 10px">
        <h2><center>TERAPIA AL BISOGNO</center></h2></el-col
      >
      <el-col :span="24">
        <el-table
          :data="bisognoTherapies"
          style="width: 100%"
          v-loading="loading"
          border
        >
          <el-table-column
            label="Farmaco"
            prop="farmaco_name"
            width="300"
            fixed
          >
          </el-table-column>
          <el-table-column
            label="Q.tà"
            prop="quantity"
            width="60"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="Somm."
            prop="subministration_type.label"
            width="105"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="Inzio Terapia"
            prop="start_date"
            align="center"
            width="105"
          >
          </el-table-column>
          <el-table-column
            label="Fine Terapia"
            prop="clean_therapy_end_date"
            align="center"
            width="105"
          >
          </el-table-column>
          <el-table-column
            label="Condizione"
            prop="bisogno"
            align="center"
            min-width="150px"
          >
          </el-table-column>
          <el-table-column
            label="Indicazioni"
            prop="description"
            align="center"
            min-width="150"
          >
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- TERAPIA LASSATIVA -->
    <el-row style="margin: 30px 0">
      <el-col :span="24" style="padding: 0 10px">
        <h2><center>TERAPIA LASSATIVA</center></h2></el-col
      >
      <el-col :span="24">
        <el-table
          :data="lassativaTherapies"
          style="width: 100%"
          v-loading="loading"
          border
        >
          <el-table-column
            label="Farmaco"
            prop="farmaco_name"
            width="300"
            fixed
          >
          </el-table-column>
          <el-table-column
            label="Q.tà"
            prop="quantity"
            width="60"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="Somm."
            prop="subministration_type.label"
            width="105"
            align="center"
          >
          </el-table-column>
          <el-table-column
            label="Inzio Terapia"
            prop="start_date"
            align="center"
            width="105"
          >
          </el-table-column>
          <el-table-column
            label="Fine Terapia"
            prop="clean_therapy_end_date"
            align="center"
            width="105"
          >
          </el-table-column>
          <el-table-column
            label="Condizione"
            prop="bisogno"
            align="center"
            min-width="150"
          >
            <template slot-scope="scope">
              Se non evacua per {{ scope.row.every }} giorni
            </template>
          </el-table-column>
          <el-table-column
            label="Indicazioni"
            prop="description"
            align="center"
            min-width="150"
          >
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Subministration from "@/js/api/services/Subministration";
export default {
  name: "TherapySummary",
  props: ["profile_id"],
  data() {
    return {
      loading: false,
      weekDay: {
        Lu: "Lunedí",
        Ma: "Martedí",
        Me: "Mercoledí",
        Gi: "Giovedí",
        Ve: "Venerdí",
        Sa: "Sabato",
        Do: "Domenica",
      },
      subministrationTimes: {},
      therapies: [],
      bisognoTherapies: [],
      lassativaTherapies: [],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    async fetch() {
      this.loading = true;
      const response = await Subministration.summary(this.profile_id);
      this.therapies = response.data.therapies;
      this.bisognoTherapies = response.data.bisogno_therapies;
      this.lassativaTherapies = response.data.lassativa_therapies;
      this.subministrationTimes = response.data.subministration_times;
      this.loading = false;
    },
    cellClass({ row, column, rowIndex, columnIndex }) {
      if (column.columnKey == "time") {
        return "timeCell";
      }
      return "";
    },
    cellHeaderClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1) {
        return "lowPadding";
      }
      return "";
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (
        column.columnKey == "date" &&
        !row.therapy.weekday &&
        row.therapy.every > 1
      ) {
        if (column.label == "Lu") {
          return [1, 7];
        } else {
          return [0, 0];
        }
      }
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style lang="css">
.timeCell {
  background-color: #ecf5ff;
}
.lowPadding .cell {
  padding: 0 5px !important;
}
</style>