<template>
  <md-app md-mode="fixed" v-loading="!isReady">
    <md-app-content v-if="isReady">
      <arki-toolbar ref="toolbar" />
      <arki-sidenav />
      <arki-read-comunication />
      <router-view :roles="this.roles" />
      <div v-if="false" class="onWorkShift">ATTENZIONE! Utente fuori turno</div>
    </md-app-content>
  </md-app>
</template>
    <script>
import ArkiToolbar from "@/js/components/ArkiToolbar";
import ArkiSidenav from "@/js/components/ArkiSidenav";
import ArkiReadComunication from "@/js/components/ArkiReadComunication";
import Comunication from "@/js/api/services/Comunication";
import Log from "@/js/api/services/Log";
import createPersistedState from "vuex-persistedstate";
import axios from "axios";
import Auth from "@/js/api/services/Auth";
import Subministration from "@/js/api/services/Subministration";
export default {
  name: "App",
  data() {
    return {
      roles: [],
      isReady: false,
      onWorkShiftAlert: false,
      timeoutinms: 7200000, // 120 minutes -> 120 * 60 * 1000
      timeoutid: null,
    };
  },
  components: {
    ArkiToolbar,
    ArkiSidenav,
    ArkiReadComunication,
  },
  computed: {
    response() {
      return this.$store.state.response;
    },
    departmentId() {
      return this.$store.state.departmentId;
    },
  },
  watch: {
    $route(to, from) {
      this.log(to, from);
    },
    response(newResponse, oldResponse) {
      if (axios.isCancel(this.$store.state.response)) {
        return;
      }
      if (this.$store.state.error) {
        this.error();
      } else if (this.$store.state.success) {
        this.success();
      }
    },
    departmentId() {
      this.broadcast();
    },
  },
  methods: {
    async init() {
      // da riveedere
      if (!this.$store.state.auth) {
        await this.store();
        this.broadcast();

        // controllo utente fuori turno
        let params = {
          mail: false,
          structure: this.$store.state.structureId,
          department: this.$store.state.departmentId,
        };
        const response = await Auth.checkDepartment(params);
        this.$store.commit("setOnWorkShift", response.data.data.onWorkShift);
      } else {
        this.isReady = true;
      }
      if (
        this.$store.state.onWorkShift != null &&
        this.$store.state.onWorkShift != this.$store.state.structureId
      ) {
        this.onWorkShiftAlert = true;
      }
    },
    async store() {
      this.$store.commit("setUser", this.$auth.watch.data);
      const response = await this.$store.dispatch("init");
      this.isReady = true;
    },
    success() {
      if (this.$store.state.response.data.message) {
        this.$notify({
          title: "Ok!",
          message: this.$store.state.response.data.message,
          type: "success",
        });
      }
    },
    error() {
      const errCodes = [406, 500];
      const response = this.$store.state.response.response;
      let message = response.statusText;

      if (errCodes.includes(response.status)) {
        message = response.data.message;
      }
      this.$notify({
        title: "Errore",
        message: message,
        type: "error",
      });
    },
    broadcast() {
      if (this.$store.state.departmentId) {
        this.$echo
          .channel(`arkicare-activity.${this.$store.state.departmentId}`)
          .listen(".patient.created", (e) => {
            this.$store.dispatch("fetchPatients");
          });

        this.$echo
          .channel(`arkicare-activity.${this.$store.state.departmentId}`)
          .listen(".subministration.created", async (e) => {
            const respStatus = await Subministration.status(
              this.$store.state.departmentId
            );
            var subministrationStatus = respStatus.data;
            _.map(this.$store.state.patients, function (patient) {
              if (subministrationStatus[patient.id]) {
                patient["subministrationProgressStatus"] =
                  subministrationStatus[patient.id];
              }
            });
          });
      }
    },
    async log(to, from) {
      if (from.name == to.name) {
        return;
      }
      let params = {
        type: 1,
        from: from.name,
        to: to.name,
      };
      await Log.store(params);
    },

    handleinactive() {
      // here you want to logout a user and/or ping your token
      this.$auth.logout();
    },

    starttimer() {
      // settimeout returns an id (can be used to start or clear a timer)
      this.timeoutid = setTimeout(this.handleinactive, this.timeoutinms);
    },

    resettimer(event) {
      clearTimeout(this.timeoutid);
      this.starttimer();
    },

    setuptimers() {
      document.body.addEventListener("keydown", this.resettimer, false);
      document.body.addEventListener("mousemove", this.resettimer, false);
      document.body.addEventListener("mousedown", this.resettimer, false);
      document.body.addEventListener("touchstart", this.resettimer, false);
      document.body.addEventListener("touchmove", this.resettimer, false);
      document.body.addEventListener("pointermove", this.resettimer, false);

      this.starttimer();
    },
  },
  mounted() {
    this.init();
    this.broadcast();
    this.setuptimers();
  },
};
</script>

<style lang="scss" scoped>
.md-app {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid rgba(#000, 0.12);
}

// Demo purposes only
.md-drawer {
  top: 65px;
  width: 230px;
  max-width: calc(100vw - 125px);
  height: calc(100vh - 131px);
}

.md-active {
  top: 0px !important;
}

.md-icon-button {
  margin-left: 8px;
}

.el-drawer__header {
  margin-bottom: 0px;
}

.el-tabs__header {
  display: none;
}

.login-container {
  background: #f5f8fa;
  height: 100vh;
}

.logo-container {
  img {
    position: absolute;
    left: calc(50% - 74px);
  }
}

.form-login-container {
  margin-top: 170px;
}

.margin-bottom {
  margin-bottom: 20px;
}
.holder {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}
.onWorkShift {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f56c6c;
  color: #fff;
  text-align: center;
  padding: 0.2em;
  z-index: 99;
}
</style>
