<template>
    <div>
        <arki-broadcast ref="broadcast" listen=".bath.created" @update="fetch"/>
        <el-input v-model="search" size="large" placeholder="Cerca ospite" class="search-input" clearable/>
        <div class="block with-search" v-loading="loading">
            <el-table :data="patients.filter(data => !search || data.full_name.toLowerCase().includes(search.toLowerCase()))" style="max-width: 100%" height="100%" :fit="true" >
                <el-table-column prop="bath_order" width="90" label="Ordine" align="center" sortable ></el-table-column>
                <el-table-column label="Ospite" sortable prop="full_name">
                    <template slot-scope="scope">
                        <font-awesome-icon class="warning-text" icon="exclamation-triangle" v-if="scope.row.critical.length"/>
                        <font-awesome-icon class="success-text" icon="toilet" v-if="scope.row.has_purge_critical"/>
                        {{scope.row.full_name}}
                    </template>
                </el-table-column>
                <el-table-column width="140" prop="clean_last_bath" label="Ultima volta"></el-table-column>
                <el-table-column width="85" label="Priorità" align="center" v-if="isChiefNurse" >
                    <template slot-scope="scope">
                        <el-button
                        size="small"
                        type="primary"
                        plain
                        @click="handleProprity(scope.row.id, scope.row.full_name)">
                            <font-awesome-icon icon="level-up-alt" size="2x" />
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column
                    label=""
                    align="center"
                    prop="has_priority_bath"
                    v-else-if="!isChiefNurse"
                    fixed="right"
                    width="85"
                    >
                    <template slot-scope="scope">
                        <font-awesome-icon icon="level-up-alt" size="2x" v-if="scope.row.has_priority_bath"/>
                    </template>
                </el-table-column>
                <el-table-column abel="Azione" width="85">
                    <template slot-scope="scope">
                        <arki-check-dialog buttonType="success" icon="bath" :title="`Bagno a ${scope.row.full_name}`" :action="`baths/${scope.row.id}`" :patient_id.sync="scope.row.id" :rules="rules" @success="fetch()"/>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import moment from 'moment';

import Bath from '@/js/api/services/Bath';
import ArkiCheckDialog from '@/js/components/ArkiCheckDialog';
import ArkiBroadcast from '@/js/components/ArkiBroadcast';

export default {
    name: 'Baths',
    components:{
        ArkiCheckDialog,
        ArkiBroadcast
    },
    data(){
        return {
            loading: true,
            labelPosition: 'right',
            search: '',
            rules: {
                time: [ { required: true, message: 'Inserire Orario', trigger: 'blur' } ],
                date: [ { required: true, message: 'Inserire Data', trigger: 'blur' } ]
            },
            patients: [],
        }
    },
    computed: {
        isChiefNurse() { return this.$store.state.user.is_chief_nurse },
    },
    methods: {
        async fetch(){
            this.loading = true;
            const response = await Bath.fetch();
            this.patients = response.data;
            this.loading = false;
        },

        handleProprity(id, patient){
            this.$confirm(`Assegnare priorità a ${patient} ?`, 'Attenzione', {
                confirmButtonText: 'Sì',
                cancelButtonText: 'Annulla',
                type: 'warning'
            }).then(() => {
                this.priority(id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: 'Operazione annullata'
                });
            });
        },
        async priority(id){
            const response = await Bath.priority(id);
            this.fetch();
        },
    },
    mounted () {
        this.fetch();
    }
}
</script>
<style lang="scss" scoped>
    .search-input{
        padding: 10px;
    }
    .paginate{
        padding-top: 15px;
    }
</style>
