import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class MealVerification extends ArkiBaseService {

    static endpoint = 'meals-verifications';

    static summary(patientId, params = {}) {
        return Api.get(`${this.endpoint}/summary/${patientId}`, {
            params: params
        });
    }

}