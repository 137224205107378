<template>
  <div>
    <arki-filters
      ref="filters"
      @filtered="fetch"
      target="prescriptedMeals"
      :dateRangeFilter="true"
      :optionsFilter="false"
      :patientSpan="12"
    />

    <arki-create-dialog
      title="NUOVO MONITORAGGIO PASTI"
      dateOptions="past"
      :rules="rules"
      action="/meals"
      @submit="update"
      ref="createDialog"
    />

    <arki-broadcast ref="broadcast" listen=".meal.created" @update="update" />

    <div class="block with-search-margin">
      <el-table
        :class="paginateTable"
        :data="meals.data"
        :default-sort="{ prop: 'full_name', order: 'ascending' }"
        style="width: 100%"
        v-loading="loading"
        :max-height="maxHeight"
        :row-class-name="rowClass"
        :header-cell-class-name="cellHeaderClass"
      >
        <el-table-column type="expand" width="30">
          <template slot-scope="scope">
            <el-col :span="24">
              <b>Inserimento:</b> {{ scope.row.full_creator }} -
              {{ scope.row.clean_date_time }}
            </el-col>
            <el-col :span="24" v-if="scope.row.enabled === 0">
              <b>Rimossa:</b> {{ scope.row.full_remover }} -
              {{ scope.row.removed_at_time }}
            </el-col>
          </template>
        </el-table-column>
        <el-table-column
          prop="full_patient"
          label="Ospite"
          width="230"
        ></el-table-column>
        <el-table-column
          prop="clean_monitoring_start"
          label="Data Inizio"
          sortable
          width="180"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="clean_monitoring_end"
          label="Data Fine"
          sortable
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.monitoring_end">
              {{ scope.row.clean_monitoring_end }}
            </span>
            <span v-else style="font-size: 20px"> &#8734; </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="description"
          label="Motivazione"
          min-width="230"
        ></el-table-column>
        <el-table-column label="Pasti" align="center" column-key="pasti">
          <el-table-column
            v-for="(value, index) in config.meal_types"
            :label="value"
            width="50"
            :key="index"
            align="center"
            column-key="pasto"
          >
            <template slot-scope="scope">
              <i
                v-if="scope.row[value] === 1"
                class="el-icon el-icon-check"
              ></i>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="Azioni" width="160" align="center">
          <template slot-scope="scope">
            <el-button
              type="warning"
              plain
              :disabled="disabledTerminateButton(scope.row)"
              @click="terminateMonitoring(scope.row.id)"
              ><i class="el-icon-circle-close" style="text-decoration: none"></i
            ></el-button>
            <el-button
              type="danger"
              plain
              :disabled="scope.row.enabled === 0"
              @click="deleteMonitoring(scope.row.id)"
              ><i class="el-icon-delete" style="text-decoration: none"></i
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="paginate"
        background
        layout="prev, pager, next"
        :page-size="meals.per_page"
        :total="meals.total"
        :hide-on-single-page="true"
        @current-change="changePage"
        :disabled="!pagination"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ArkiFilters from "@/js/components/ArkiFilters";
import ArkiCreateDialog from "@/js/components/ArkiCreateDialog";
import ArkiBroadcast from "@/js/components/ArkiBroadcast";
import Meal from "@/js/api/services/Meal";

export default {
  name: "Meals",
  components: {
    ArkiFilters,
    ArkiCreateDialog,
    ArkiBroadcast,
  },
  data() {
    var self = this;
    var checkDataMonitoringStart = (rule, value, callback) => {
      var monitoring_end = this.$refs.createDialog.form.monitoring_end;
      if (!value) {
        return callback(new Error("Inserire Data Inizio"));
      }
      if (value > monitoring_end) {
        return callback(new Error("Data errata"));
      }
      return callback();
    };
    var checkDataMonitoringEnd = (rule, value, callback) => {
      var monitoring_start = this.$refs.createDialog.form.monitoring_start;
      if (value < monitoring_start) {
        return callback(new Error("Data errata"));
      }
      return callback();
    };
    return {
      rules: {
        patient_id: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        time: [{ required: true, message: "Inserire Orario", trigger: "blur" }],
        // date: [{ required: true, message: "Inserire Data", trigger: "blur" }],
        description: [
          { required: true, message: "Inserire Descrizione", trigger: "blur" },
        ],
        monitoring_start: [
          {
            validator: checkDataMonitoringStart,
            trigger: "blur",
          },
          { required: true, message: "Inserire Data", trigger: "blur" },
        ],
        monitoring_end: [
          {
            validator: checkDataMonitoringEnd,
            trigger: "blur",
          },
        ],
      },
      meals: {},
      meals_type: [],
      loading: false,
      meals: {
        data: [],
        per_page: null,
        total: null,
      },

      pagination: true,
      paginateTable: null,
    };
  },
  computed: {
    config() {
      return this.$store.state.config.meals;
    },
    maxHeight() {
      return window.innerHeight - 235;
    },
  },
  watch: {
    meals: function () {
      this.meals.total / this.meals.per_page > 1
        ? (this.paginateTable = "paginate-table")
        : (this.paginateTable = null);
    },
  },
  methods: {
    async fetch(filters) {
      this.loading = true;
      let params = {
        date_from: moment(filters.dateRange[0]).format("DD/MM/YYYY"),
        date_to: moment(filters.dateRange[1]).format("DD/MM/YYYY"),
        patient_id: filters.patient_id,
      };
      const response = await Meal.fetch(params);
      this.meals = response.data.meals;
      this.loading = false;
    },
    update() {
      if (this.$refs.filters) {
        this.$refs.filters.emit();
        this.$refs.broadcast.reset();
      }
    },
    changePage(page) {
      this.loading = true;
      this.current_page = page;
      this.update();
    },
    rowClass({ row, rowIndex }) {
      if (row.enabled === 0) {
        return "deleted";
      }
      if (row.monitoring_end <= new moment().format("YYYY-MM-DD")) {
        return "terminated";
      }

      return "";
    },
    async terminateMonitoring(id) {
      this.$confirm(`Terminare adesso il monitoraggio?`, "Attenzione", {
        confirmButtonText: "OK",
        cancelButtonText: "Annulla",
        type: "warning",
      })
        .then(async () => {
          const response = await Meal.update(id);
          this.update();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        });
    },
    async deleteMonitoring(id) {
      this.$confirm(
        `Eliminare il monitoraggio? Saranno eliminate anche le verifiche e l'eventuale consegna associata`,
        "Attenzione",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Annulla",
          type: "warning",
        }
      )
        .then(async () => {
          const response = await Meal.destroy(id);
          this.update();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        });
    },
    cellHeaderClass({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1) {
        return "text-rotated";
      }
      return "";
    },
    disabledTerminateButton(scope) {
      if (
        scope.monitoring_end <= moment().format("YYYY-MM-DD") ||
        scope.enabled === 0 ||
        scope.monitoring_start > moment().format("YYYY-MM-DD")
      ) {
        return true;
      }

      return false;
    },
  },
  mounted() {},
};
</script>

<style>
.deleted {
  text-decoration: line-through;
}

.text-rotated {
  padding: 5px !important;
  font-size: smaller;
}
.text-rotated .cell {
  transform: rotate(210deg);
  white-space: nowrap;
  writing-mode: vertical-rl;
  text-transform: capitalize;
}
</style>