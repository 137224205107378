import _ from 'lodash';

const rules = (numberOfQuestions) => {
  let defaultRule = { required: true, message: "Campo Obbligatorio", trigger: "change" };
  return _.range(numberOfQuestions)
    .reduce((acc, next, idx) => {
      idx++;
      acc[`q${idx}_answer`] = defaultRule;
      acc[`q${idx}_result`] = defaultRule;
      return acc;
    }, {})
}

const createResults = (min, max) => {
  return _.range(min, max).map(idx => `q${idx}_result`)
}

const filterResults = (data, specific) => {
  if (specific) {
    return _.pickBy(data, (_, key) => specific.includes(key));
  }
  return _.pickBy(data, (_, key) => key.endsWith("result"));
}

const totalOf = (specific, data) => {
  let results = _.values(filterResults(data, specific));
  return results.reduce(_.add, 0);
}

const instruction = (totals) => {
  return _.cond([
    [
      _.conforms({ totalInit: n => _.inRange(n, 1, 5) }),
      _.constant("Indagare ulteriormente")
    ],
    [
      _.matches({ totalInit: 5, totalCreamy: 0 }),
      _.constant("Continuare con la 2 parte")
    ],
    [
      _.conforms({ totalCreamy: n => _.inRange(n, 1, 5) }),
      _.constant("Avvisa il medico")
    ],
    [
      _.matches({ totalCreamy: 5, totalLiquid: 0 }),
      _.constant("Continuare con i cibi LIQUIDI")
    ],
    [
      _.conforms({ totalLiquid: n => _.inRange(n, 1, 5) }),
      _.constant("Avvisa il medico")
    ],
    [
      _.matches({ totalLiquid: 5, totalSolid: 0 }),
      _.constant("Continuare con i cibi SOLIDI")
    ],
    [
      _.conforms({ totalSolid: n => _.inRange(n, 1, 5) }),
      _.constant("Avvisa il medico")
    ],
    [_.matches({ totalSolid: 5 }), _.constant("Nella norma")]
  ])(totals)
}

const totalDescription = (form) => {
  return _.cond([
    [
      _.conforms({ total: n => _.inRange(n, 0, 10) }),
      _.constant(
        "Valutazione preliminare non possibile o disfagia per i cibi cremosi"
      )
    ],
    [
      _.conforms({ total: n => _.inRange(n, 10, 15) }),
      _.constant(
        "Buona gestione dei cibi cremosi. Difficoltà con i liquidi."
      )
    ],
    [
      _.conforms({ total: n => _.inRange(n, 15, 20) }),
      _.constant(
        "Buona gestione dei liquidi e degli alimenti cremosi. Difficoltà con i cibi solidi."
      )
    ],
    [_.matches({ total: 20 }), _.constant("Nella norma")]
  ])(form);
}

export default { rules, filterResults, createResults, totalOf, instruction, totalDescription };
