<script>
import SosiaAbsenceForm from "./components/SosiaAbsenceForm.vue";
import SosiaTeamForm from "./components/SosiaTeamForm.vue";
import SosiaRateForm from "./components/SosiaRateForm.vue";
import SosiaTreatmentForm from "./components/SosiaTreatmentForm.vue";
import SosiaEvaluationForm from "./components/SosiaEvaluationForm.vue";
import SosiaService from "@/js/api/services/Sosia2";
import SosiaPatientsTable from "./SosiaPatientsTable.vue";
import SosiaPatientsToolbar from "./components/SosiaPatientsToolbar.vue";
import SosiaExportModal from "./components/SosiaExportModal";
import ArkFile from "@/js/helpers/file";

export default {
  name: "SosiaPatients",
  components: {
    SosiaAbsenceForm,
    SosiaTeamForm,
    SosiaRateForm,
    SosiaTreatmentForm,
    SosiaEvaluationForm,
    SosiaPatientsTable,
    SosiaPatientsToolbar,
    SosiaExportModal,
  },
  data() {
    return {
      loading: false,
      patients: [],
      exportLoading: false,
      search: "",
      params: {},
      period: {},
      field: null,
      config: {},
    };
  },
  computed: {
    structureId() {
      return this.$store.state.structureId;
    },
    dataTable() {
      const q = this.search ? this.search.trim().toLowerCase() : null;
      if (!q || !this.field) return this.patients;
      return this.patients.filter((p) => {
        if (this.field == "nome") {
          return _.get(p, "full_name", "").toLowerCase().includes(q);
        } else if (this.field == "codiceFiscale") {
          return p.cf && p.cf.toLowerCase().includes(q);
        }
      });
    },
  },
  methods: {
    fetchPatients() {
      this.loading = true;
      const params = {
        ...this.params,
        ...this.period,
        structureId: this.structureId,
      };
      SosiaService.patients(params)
        .then(({ data }) => {
          this.patients = data.patients;
          this.config = data.config;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    doSearch({ field, value }) {
      if (!value || value.trim() == "") return;
      if (field == "pratica") {
        this.field = field;
        this.params = {
          [field]: value.trim(),
        };
        this.fetchPatients();
      } else {
        this.field = field;
        this.search = value;
      }
    },
    changePeriod(period) {
      this.period = period;
      this.fetchPatients();
    },
    async clearSearch() {
      if (this.field == "pratica") {
        this.params = {};
        this.fetchPatients();
      } else {
        this.params = {};
        this.search = "";
      }
      this.field = null;
    },
    exportPdf() {
      this.loading = true;
      const params = {
        ...this.period,
        structureId: this.structureId,
      };
      ArkFile.downloadFromRequest(
        SosiaService.exportPdf(params),
        "pdf_sosia.zip"
      ).finally(() => {
        this.loading = false;
      });
    },
    exportExcel() {
      this.loading = true;
      const params = { structureId: this.$store.state.structureId };
      ArkFile.downloadFromRequest(
        SosiaService.exportExcel(params),
        "sosia_list.xlsx"
      ).finally(() => {
        this.loading = false;
      });
    },

    exportSosia() {
      this.loading = true;
      const params = {
        ...this.period,
        structureId: this.structureId,
      };
      ArkFile.downloadFromRequest(SosiaService.export(params), "sosia.zip")
        .catch((e) => {
          if (e.response.status == 406) {
            this.$message({
              type: "info",
              message: "Nessun ospite ha il SOSIA per la data inserita",
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {
    this.fetchPatients();
  },
};
</script>

<template>
  <el-container class="container" direction="vertical">
    <el-button
      size="mini"
      class="export-excel-btn"
      type="success"
      plain
      :disabled="loading"
      :loading="exportLoading"
      @click="exportExcel"
      >EXPORT SOSIA LIST</el-button
    >
    <el-button
      size="mini"
      class="add-button float-left export-btn"
      type="success"
      plain
      :disabled="loading"
      :loading="exportLoading"
      @click="exportPdf"
      >EXPORT PDF</el-button
    >
    <el-button
      size="mini"
      class="add-button"
      type="success"
      plain
      :disabled="loading"
      :loading="exportLoading"
      @click="exportSosia"
      >EXPORT TXT</el-button
    >
    <sosia-patients-toolbar
      @doSearch="doSearch"
      @changePeriod="changePeriod"
      :loading="loading"
      @clearSearch="clearSearch"
    />
    <el-main style="padding-top: 5px !important">
      <sosia-patients-table
        :list="dataTable"
        :loading="loading"
        :config="config"
      />
    </el-main>
  </el-container>
</template>

<style scss scoped>
.container {
  height: 100%;
  margin: 5px;
}
.export-btn {
  right: 130px;
}
.export-excel-btn {
  z-index: 2;
  position: fixed;
  left: 100px;
  top: 75px;
  background: transparent !important;
  padding: 12px 12px !important;
}
</style>
