<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-divider content-position="center"></el-divider>

          <el-col :span="24">
            <el-col :span="6">
              <el-form-item label="  " />
            </el-col>
            <el-col :span="18">
              <el-row type="flex" class="row-bg" justify="center">
                <el-col>
                  <center class="head">Mai</center>
                </el-col>
                <el-col>
                  <center class="head">Meno di una volta alla settimana</center>
                </el-col>
                <el-col>
                  <center class="head">Una o due volte alla settimana</center>
                </el-col>
                <el-col>
                  <center class="head">Parecchie volte alla settimana</center>
                </el-col>

                <el-col>
                  <center class="head">Una o due volte al giorno</center>
                </el-col>
                <el-col>
                  <center class="head">Parecchie volte al giorno</center>
                </el-col>
                <el-col>
                  <center class="head">Parecchie volte in un ora</center>
                </el-col>
              </el-row>
              <el-divider content-position="center"></el-divider>
            </el-col>
          </el-col>

          <el-col :span="24" v-for="(item, idx) in comportamenti" :key="idx">
            <el-form-item :prop="`q${++idx}_result`">
              <el-col :span="6">
                <el-form-item :label="item" required />
              </el-col>
              <el-col :span="18">
                <el-row type="flex" justify="center">
                  <el-col v-for="opt in 7" :key="opt + '-opt'">
                    <el-col>
                      <center>
                        <el-radio :label="opt" v-model="result[`q${idx}_result`]" />
                      </center>
                    </el-col>
                  </el-col>
                </el-row>
              </el-col>
            </el-form-item>
            <el-divider content-position="center"></el-divider>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults, createResults } from "../constants";
import TestActions from "../components/TestActions";

export default {
  name: "Cmai",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 203
      },
      result: {},
      testRules: rules(29),
      comportamenti: [
        "Colpire (se stessi)",
        "Calciare",
        "Aggrapparsi alle persone",
        "Spingere",
        "Gettare delle cose",
        "Mordere",
        "Graffiare",
        "Sputare",
        "Ferisce se stesso o altri",
        "Rompere/Distruggere cose proprie",
        "Fare avance fisiche sessuali",
        "Vagare senza meta",
        "Abiti inappropriati o spogliarsi",
        "Cercare di andare in posti diversi",
        "Cadere intenzionalmente",
        "Mangiare/Bere sostanze inappropriate",
        "Gestire cose in modo inappropriato",
        "Nascondere cose",
        "Accumulare delle cose",
        "Eseguire manierismi ripetitivi",
        "Irrequietezza generale",
        "Urlare",
        "Fare advance verbali sessuali",
        "Bestemmiare/Aggressivitá verbale",
        "Frasi e domande ripetitive",
        "Strani rumori (risate o pianti)",
        "Lamentarsi",
        "Negativismo",
        "Costante richiesta di aiuto o attenzione"
      ]
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$emit("submitTest", this.form, this.result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        this.$refs["form"].clearValidate();
        let results = _.values(filterResults(this.result));
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 80%;
}
.collapse-header {
  font-size: 16px;
}
.head {
  padding: 4px;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>