<template>
  <div>
    <md-dialog
      :md-active.sync="showNursingCard"
      :md-click-outside-to-close="false"
      style="top: 50% !important"
    >
      <md-dialog-title
        >Scheda Infermieristica
        <span class="float-right">
          <el-button
            type="primary"
            size="medium"
            round
            @click="close('reset')"
            :loading="submitLoading"
            >Annulla</el-button
          >
          <el-button
            type="success"
            size="medium"
            round
            @click="save()"
            :loading="submitLoading"
            >Salva</el-button
          >
          <el-button
            type="info"
            size="medium"
            round
            @click="close('close')"
            :loading="submitLoading"
            >Chiudi</el-button
          >
        </span>
      </md-dialog-title>
      <md-dialog-content>
        <el-form
          label-position="top"
          label-width="120px"
          :model="form"
          ref="form"
        >
          <el-row :gutter="20">
            <!-- divider  DATI ANAMNESTICI-->
            <el-col :span="24">
              <el-divider content-position="center">
                <h2>Dati Anamnestici</h2></el-divider
              >
            </el-col>
            <!-- anamnesi infermieristica -->
            <el-col :span="24">
              <el-form-item label="Anamnesi Infermieristica">
                <el-input
                  type="textarea"
                  :rows="2"
                  v-model="form.anamnesi_infermieristica"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- allergie -->
            <el-col :span="24">
              <el-form-item label="Allergie">
                <el-input
                  type="textarea"
                  :rows="2"
                  v-model="form.allergie"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- positività sierologica -->
            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="24">
                  <el-divider content-position="left"
                    >Positività sierologia</el-divider
                  >
                </el-col>
                <el-col :span="8">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.positivita_hcv"
                      label="HCV"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.positivita_hbv"
                      label="HBV"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.positivita_hav"
                      label="HAV"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.positivita_hiv"
                      label="HIV"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.positivita_klebsiella"
                      label="Klebsiella"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.positivita_clostridium"
                      label="Clostridium"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="Altro">
                    <el-input
                      type="textarea"
                      :rows="2"
                      v-model="form.note_positivita"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <!-- pacemaker -->
            <el-col :span="24">
              <el-divider content-position="left">Pacemaker</el-divider>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="Portatore di Pacemaker">
                    <el-select v-model="form.pacemaker" clearable>
                      <el-option
                        v-for="(item, index) in config.portatore_di_pacemaker"
                        :key="index"
                        :label="item"
                        :value="Number(index)"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Applicato in data" prop="date">
                    <el-date-picker
                      v-model="form.pacemaker_apply_date"
                      type="date"
                      placeholder="Scegli data"
                      :editable="false"
                      :picker-options="{
                        firstDayOfWeek: 1,
                      }"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Da sostituire in data" prop="date">
                    <el-date-picker
                      v-model="form.pacemaker_replace_date"
                      type="date"
                      placeholder="Scegli data"
                      :editable="false"
                      :picker-options="{
                        firstDayOfWeek: 1,
                      }"
                      format="dd/MM/yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <!-- divider RESPIRO -->
            <el-col :span="24">
              <el-divider content-position="center">
                <h2>Respiro</h2></el-divider
              >
            </el-col>
            <!-- respiro -->
            <el-col :span="24">
              <el-form-item label="Tipo Respiro">
                <el-select v-model="form.respiro" clearable>
                  <el-option
                    v-for="(item, index) in config.respiro"
                    :key="index"
                    :label="item"
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- ossigenoterapia -->
            <el-col :span="24">
              <el-divider content-position="left">Ossigenoterapia</el-divider>
              <el-row :gutter="20">
                <el-col :span="8">
                  <el-form-item label="Ossigenoterapia">
                    <el-select v-model="form.ossigenoterapia" clearable>
                      <el-option
                        v-for="(item, index) in config.ossigenoterapia"
                        :key="index"
                        :label="item"
                        :value="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Tipo Ossigenoterapia">
                    <el-select v-model="form.ossigenoterapia_type" clearable>
                      <el-option
                        v-for="(item, index) in config.tipo_ossigenoterapia"
                        :key="index"
                        :label="item"
                        :value="index"
                        :disabled="form.ossigenoterapia != 0"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Flusso Ossigenoterapia">
                    <el-input
                      v-model="form.ossigenoterapia_flusso"
                      :disabled="form.ossigenoterapia_type != 1"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <!-- tracheotomia -->
            <el-col :span="24">
              <el-divider content-position="left">Tracheotomia</el-divider>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Tipo Tracheostomia">
                    <el-select
                      v-model="form.tracheostomia_type"
                      clearable
                      :disabled="form.respiro != 5"
                    >
                      <el-option
                        v-for="(item, index) in config.tipo_tracheostomia"
                        :key="index"
                        :label="item"
                        :value="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Cannula Tracheostomia">
                    <el-input
                      v-model="form.tracheostomia_cannula_type"
                      :disabled="form.respiro != 5"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <!-- divider MOVIMENTO -->
            <el-col :span="24">
              <el-divider content-position="center">
                <h2>Movimento</h2></el-divider
              >
            </el-col>
            <!-- deambulazione -->
            <el-col :span="12">
              <el-form-item label="Deambulazione">
                <el-select v-model="form.deambulazione" clearable>
                  <el-option
                    v-for="(item, index) in config.deambulazione"
                    :key="index"
                    :label="item"
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- carrozzina -->
            <el-col :span="12">
              <el-form-item label="Carrozzina">
                <el-select v-model="form.trasferimento_letto_sedia" clearable>
                  <el-option
                    v-for="(item, index) in config.trasferimento_letto_sedia"
                    :key="index"
                    :label="item"
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- lesioni decubito -->
            <el-col :span="24">
              <el-divider content-position="left">Presenza Decubiti</el-divider>
              <el-tag
                v-for="(woundD, index) in woundsDecubito"
                :key="index"
                type="info"
              >
                Sede: {{ woundD.sede_lesione_description }} - Stadio:{{
                  woundD.last_medication.stadio_npuap
                }}
              </el-tag>
              <el-tag type="info" v-if="woundsDecubito.length == 0">
                - Nessuna presenza di decubiti -
              </el-tag>
            </el-col>
            <!-- lesioni altre -->
            <el-col :span="24">
              <el-divider content-position="left"
                >Presenza Medicazioni</el-divider
              >
              <el-tag
                v-for="(woundO, index) in woundsOthers"
                :key="index"
                type="info"
              >
                Sede: {{ woundO.sede_lesione_description }}
              </el-tag>
              <el-tag type="info" v-if="woundsOthers.length == 0">
                - Nessuna medicazione presente -
              </el-tag>
            </el-col>
            <!-- MAD -->
            <el-col :span="24">
              <el-form-item label="Posizionato MAD">
                <el-checkbox v-model="form.mad" label="Si" border></el-checkbox>
              </el-form-item>
            </el-col>
            <!-- divider COMUNICAZIONE -->
            <el-col :span="24">
              <el-divider content-position="center">
                <h2>Comunicazione</h2></el-divider
              >
            </el-col>
            <!-- stato di coscienza -->
            <el-col :span="12">
              <el-form-item label="Stato di Coscienza">
                <el-select v-model="form.stato_di_coscienza" clearable>
                  <el-option
                    v-for="(item, index) in config.stato_di_coscienza"
                    :key="index"
                    :label="item"
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- orientamento -->
            <el-col :span="12">
              <el-form-item label="Orientamento">
                <el-select
                  v-model="form.orientamento_spazio_temporale"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in config.orientamento"
                    :key="index"
                    :label="item"
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- Linguaggio -->
            <el-col :span="24">
              <el-divider content-position="left">Linguaggio</el-divider>
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.linguaggio_normale"
                      label="Normale"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.linguaggio_afasia"
                      label="Afasia"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.linguaggio_disartria"
                      label="Disartria"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.linguaggio_anomie"
                      label="Anomie"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="Altro">
                    <el-input
                      type="textarea"
                      :rows="2"
                      v-model="form.note_linguaggio"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <!-- sensoriale -->
            <el-col :span="24">
              <el-divider content-position="left">Sensoriale</el-divider>
              <el-row :gutter="10">
                <el-col :span="8">
                  <el-form-item label="Udito">
                    <el-select v-model="form.udito" clearable>
                      <el-option
                        v-for="(item, index) in config.udito"
                        :key="index"
                        :label="item"
                        :value="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Protesi Acustica">
                    <el-checkbox
                      v-model="form.protesi_acustica_sx"
                      label="Sinistra"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Protesi Acustica">
                    <el-checkbox
                      v-model="form.protesi_acustica_dx"
                      label="Destra"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item label="Vista">
                    <el-select v-model="form.vista" clearable>
                      <el-option
                        v-for="(item, index) in config.vista"
                        :key="index"
                        :label="item"
                        :value="Number(index)"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="Olfatto">
                    <el-select v-model="form.olfatto" clearable>
                      <el-option
                        v-for="(item, index) in config.olfatto"
                        :key="index"
                        :label="item"
                        :value="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="Altro">
                    <el-input
                      type="textarea"
                      :rows="2"
                      v-model="form.note_sensoriale"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <!-- divider ELIMINAZIONE URINARIA -->
            <el-col :span="24">
              <el-divider content-position="center">
                <h2>Eliminazione Urinaria</h2></el-divider
              >
            </el-col>
            <!-- Continenza Vescicale -->
            <el-col :span="12">
              <el-form-item label="Continenza Vescicale">
                <el-select v-model="form.continenza_vescicale" clearable>
                  <el-option
                    v-for="(item, index) in config.continenza_urinaria"
                    :key="index"
                    :label="item"
                    :value="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- Catetere Vescicale -->
            <el-col :span="12">
              <el-form-item class="without-label">
                <el-checkbox
                  v-model="form.catetere_vescicale"
                  label="Catetere Vescicale"
                  border
                ></el-checkbox>
              </el-form-item>
            </el-col>
            <!-- altro -->
            <el-col :span="24">
              <el-form-item label="Altro">
                <el-input
                  type="textarea"
                  :rows="2"
                  v-model="form.abitudini_urinarie_altro"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- divider EVACUAZIONE INTESTINALE -->
            <el-col :span="24">
              <el-divider content-position="center">
                <h2>Evacuazione Intestinale</h2></el-divider
              >
              <el-row :gutter="10">
                <!-- alvo -->
                <el-col :span="12">
                  <el-form-item label="Alvo">
                    <el-select v-model="form.alvo" clearable>
                      <el-option
                        v-for="(item, index) in config.alvo"
                        :key="index"
                        :label="item"
                        :value="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- Utilizzo Lassativi -->
                <el-col :span="12">
                  <el-form-item class="without-label">
                    <el-checkbox
                      v-model="form.lassativi"
                      label="Utilizzo Lassativi"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <!-- Utilizzo Enteroclismi -->
                <el-col :span="12">
                  <el-form-item class="without-label">
                    <el-checkbox
                      v-model="form.enteroclismi"
                      label="Utilizzo Enteroclismi"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <!-- Presenza Stomie -->
                <el-col :span="12" class="without-label">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.stomie"
                      label="Presenza Stomie"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <!-- divider CONTENZIONI -->
            <el-col :span="24">
              <el-divider content-position="center">
                <h2>Contenzioni</h2></el-divider
              >
            </el-col>
            <el-col :span="24">
              <el-tag
                v-for="(protection, index) in protections"
                :key="index"
                type="info"
              >
                {{ protection.type_name }}
              </el-tag>
              <el-tag type="info" v-if="protections.length == 0">
                - Nessuna contenzione applicata -
              </el-tag>
            </el-col>
            <!-- divider SONNO E RIPOSO -->
            <el-col :span="24">
              <el-divider content-position="center">
                <h2>Sonno e Riposo</h2></el-divider
              >
            </el-col>
            <!-- sonno -->
            <el-col :span="24">
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.sonno_regolare"
                      label="Sonno Regolare"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.risveglio_precoce"
                      label="Risveglio Precoce"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.addormentamento_tardivo"
                      label="Sonno Tardivo"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <el-checkbox
                      v-model="form.insonnia"
                      label="Insonnia"
                      border
                    ></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <!-- divider ALIMENTAZIONE -->
            <el-col :span="24">
              <el-divider content-position="center">
                <h2>Alimentazione</h2></el-divider
              >
            </el-col>
            <el-col :span="24">
              <el-row :gutter="10">
                <!-- autonomia alimentare -->
                <el-col :span="12">
                  <el-form-item label="Autonomia alimentazione">
                    <el-select
                      v-model="form.autonomia_nella_alimentazione"
                      clearable
                    >
                      <el-option
                        v-for="(
                          item, index
                        ) in config.autonomia_nella_alimentazione"
                        :key="index"
                        :label="item"
                        :value="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- protesi dentarie -->
                <el-col :span="12">
                  <el-form-item label="Protesi Dentale">
                    <el-select v-model="form.protesi_dentale" clearable>
                      <el-option
                        v-for="(item, index) in config.protesi_dentale"
                        :key="index"
                        :label="item"
                        :value="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- dieta -->
                <el-col :span="12">
                  <el-form-item label="Dieta">
                    <el-select v-model="form.dieta" clearable>
                      <el-option
                        v-for="(item, index) in dietType"
                        :key="index"
                        :label="item"
                        :value="Number(index)"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- nutrizione artificiale -->
                <el-col :span="12">
                  <el-form-item label="Nutrizione Artificiale">
                    <el-select v-model="form.nutrizione_artificiale" clearable>
                      <el-option
                        v-for="(item, index) in config.nutrizione_artificiale"
                        :key="index"
                        :label="item"
                        :value="index"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- note alimentari -->
                <el-col :span="24">
                  <el-form-item label="Note sull'alimentazione">
                    <el-input
                      type="textarea"
                      :rows="2"
                      v-model="form.alimentazione_note"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form>
      </md-dialog-content>
      <!-- <md-dialog-actions>
        <el-button type="info" plain @click="close">Chiudi</el-button>
        <el-button
          type="success"
          plain
          @click="submit('form')"
          :loading="submitLoading"
          >Salva</el-button
        >
      </md-dialog-actions> -->
    </md-dialog>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "NursingCard",
  props: {
    form: Object,
    showNursingCard: Boolean,
    config: Object,
    protections: [Object, Array],
    woundsDecubito: [Object, Array],
    woundsOthers: [Object, Array],
    dietType: [Object, Array],
  },
  data() {
    return {
      //   showDialog: false,
      //   form: {},
      submitLoading: false,
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },
    close(action) {
      this.$emit("close", {
        tabPane: "scheda_infermieristica",
        action: action,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-checkbox,
.el-radio {
  width: 100%;
}

.el-input-number {
  width: 100% !important;
}

.without-label {
  margin-top: 40px;
}

div.el-divider--horizontal .el-divider__text h2 {
  margin: 4px 0;
  text-align: center;
  font-size: 19px;
}
</style>