<template>
  <div>
    <md-dialog :md-active.sync="show">
      <md-dialog-title>{{ form.full_name }}</md-dialog-title>
      <el-form
        label-position="right"
        label-width="80px"
        :model="form"
        :rules="rulesDepartment"
        ref="formDepartment"
      >
        <el-row :gutter="20">
          <el-col :span="18">
            <el-form-item label="Reparto" prop="department_id">
              <el-select
                v-model="form.department_id"
                placeholder="Seleziona Reparto"
              >
                <el-option
                  v-for="item in optionDepartments"
                  :key="item.id_pianoreparto"
                  :label="item.desc_pianoreparto"
                  :value="item.id_pianoreparto"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button
              type="success"
              plain
              @click="submit('Department')"
              :loading="submitLoading"
              >Salva</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </md-dialog>
    <md-dialog :md-active.sync="showDismissal">
      <md-dialog-title
        >Dimissioni di {{ formDismissal.full_name }}</md-dialog-title
      >
      <el-form
        label-position="top"
        label-width="80px"
        :model="formDismissal"
        :rules="rulesDismissal"
        ref="formDismissal"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Tipologia" prop="type">
              <el-select v-model="formDismissal.type">
                <el-option
                  v-for="(item, index) in optionDismissals"
                  :key="index"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Data" prop="date">
              <el-date-picker
                v-model="formDismissal.date"
                type="date"
                placeholder="Scegli data"
                :editable="false"
                :picker-options="{
                  firstDayOfWeek: 1,
                  disabledDate(time) {
                    return time.getTime() > Date.now();
                  },
                }"
                format="dd/MM/yyyy"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Orario" prop="time">
              <el-time-picker
                v-model="formDismissal.time"
                :editable="false"
                format="HH:mm"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="Seleziona Orario"
              >
              </el-time-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <md-dialog-actions>
        <el-button
          type="success"
          plain
          @click="submit('Dismissal')"
          :loading="submitLoading"
          >Salva</el-button
        >
      </md-dialog-actions>
    </md-dialog>
    <el-button
      size="large"
      class="export-patients"
      type="success"
      @click="exportPatients"
      plain
      :loading="exportingPatients"
      >EXPORT OSPITI</el-button
    >
    <el-button
      class="add-button"
      type="success"
      style="margin-right: 120px"
      @click="showDialog = true"
      >IMPORT FILE</el-button
    >
    <router-link to="/settings/patients/create"
      ><el-button class="float-right add-button no-broadcast" type="success"
        ><font-awesome-icon icon="plus" /> INSERISCI</el-button
      ></router-link
    >

    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Import pazienti da file</md-dialog-title>
      <el-form label-position="top" ref="form">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-upload
              :drag="!this.disabled"
              :action="`/api/patients/checkFile`"
              :auto-upload="true"
              :before-upload="beforeUpload"
              :on-success="saveFile"
              :on-error="warningFile"
              :on-remove="resumeDrag"
              multiple
              :limit="1"
              :show-file-list="true"
              :with-credentials="true"
              :headers="uploadHeaders"
            >
              <i v-if="!this.disabled" class="el-icon-upload"></i>
              <div v-if="!this.disabled" class="el-upload__text">
                Trascina i files oppure <em>clicca</em>
              </div>
            </el-upload>
          </el-col>
        </el-row>
      </el-form>
      <md-dialog-actions class="noPositionFixed">
        <div>
          <el-button
            id="buttonDownloadExample"
            type="info"
            plain
            @click="exampleExport('xls')"
            :loading="downloadLoading"
            >Scarica esempio excel</el-button
          >
          <el-button
            id="buttonDownloadExample"
            type="info"
            plain
            @click="exampleExport('csv')"
            :loading="downloadLoading"
            >Scarica esempio CSV</el-button
          >
        </div>
        <div>
          <el-button
            id="buttonDownloadExample"
            type="info"
            plain
            @click="showDialog = false"
            :disabled="downloadLoading"
            >Annulla</el-button
          >
          <el-button
            id="buttonDownloadExample"
            type="success"
            plain
            @click="importFile()"
            :loading="downloadLoading"
            >Importa</el-button
          >
        </div>
      </md-dialog-actions>
    </md-dialog>

    <el-row>
      <el-col :span="18">
        <el-input
          v-model="search"
          size="large"
          placeholder="Cerca ospite"
          class="search-input p-10"
        />
      </el-col>
      <el-col :span="6">
        <el-select v-model="patientList.option" @change="fetch" class="p-10">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-table
      class="patient-table"
      :data="
        patients.filter(
          (data) =>
            !search ||
            data.full_name.toLowerCase().includes(search.toLowerCase())
        )
      "
      style="width: 100%"
      :fit="true"
      :max-height="maxHeight"
      v-loading="loading"
      :row-class-name="rowClass"
    >
      <el-table-column
        fixed
        prop="full_name"
        label="Ospite"
        width="275"
        sortable
      ></el-table-column>
      <el-table-column
        prop="cf"
        label="Codice Fiscale"
        width="200"
        align="center"
      ></el-table-column>

      <el-table-column label="Nascita" align="center">
        <el-table-column
          prop="full_birthday"
          label="Data"
          width="125"
          align="center"
        ></el-table-column>
        <el-table-column
          width="200"
          label="Comune (o Stato estero)"
          prop="birthplace"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="birthplace_provincia"
          label="Provincia"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="birthplace_cap"
          label="CAP"
          width="120"
          align="center"
        ></el-table-column>
      </el-table-column>

      <el-table-column label="Residenza" align="center">
        <el-table-column label="Comune" width="200" align="center">
          <template slot-scope="scope">
            {{ scope.row.registry.residency }}
          </template>
        </el-table-column>
        <el-table-column label="Provincia" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.registry.residency_provincia }}
          </template>
        </el-table-column>
        <el-table-column label="Indirizzo" width="300" align="center">
          <template slot-scope="scope">
            {{ scope.row.registry.residency_address }}
          </template>
        </el-table-column>
        <el-table-column label="CAP" width="120" align="center">
          <template slot-scope="scope">
            {{ scope.row.registry.residency_cap }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column
        prop="desc_pianoreparto"
        label="Reparto"
        width="200"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Data Ammissione"
        width="140"
        prop="clean_admission_date"
        align="center"
      ></el-table-column>
      <el-table-column
        label="Data Dimissione"
        min-width="140"
        prop="clean_dismissal_date"
        align="center"
        v-if="patientList.option == 2"
      ></el-table-column>
      <el-table-column
        label="Dimissione"
        min-width="180"
        prop="clean_dismissal_type"
        align="center"
        v-if="patientList.option == 2"
      ></el-table-column>
      <el-table-column
        fixed="right"
        label="Azioni"
        align="rights"
        min-width="250"
        v-if="patientList.option == 1"
      >
        <template slot-scope="scope">
          <el-button-group>
            <el-tooltip
              content="Modifica Ospite"
              placement="bottom"
              effect="light"
            >
              <el-button
                type="success"
                size="small"
                plain
                @click="editPatient(scope.row.id, scope.row)"
                ><i class="el-icon-edit"></i
              ></el-button>
            </el-tooltip>
            <el-tooltip
              content="Cambia Reparto"
              placement="bottom"
              effect="light"
            >
              <el-button
                type="primary"
                size="small"
                plain
                style="padding-bottom: 6px"
                @click="
                  updatePatient(
                    scope.row.id,
                    scope.row.full_name,
                    scope.row.department
                  )
                "
              >
                <i
                  class="el-icon-sort el-icon-sort-rotate"
                  style="
                    transform: rotate(90deg);
                    -webkit-writing-mode: vertical-rl;
                  "
                ></i>
              </el-button>
            </el-tooltip>
            <el-tooltip
              content="Dimetti Ospite"
              placement="bottom"
              effect="light"
            >
              <el-button
                type="warning"
                size="small"
                plain
                @click="dismissPatient(scope.row.id, scope.row.full_name)"
                ><i class="el-icon-switch-button"></i
              ></el-button>
            </el-tooltip>
            <el-tooltip
              content="Rimuovi Ospite"
              placement="bottom"
              effect="light"
            >
              <el-button
                type="danger"
                size="small"
                plain
                @click="deletePatient(scope.row.id, scope.row.full_name)"
                v-if="scope.row.enabled == 1"
                ><i class="el-icon-delete"></i
              ></el-button>
            </el-tooltip>
            <el-tooltip
              content="Ripristina Ospite"
              placement="bottom"
              effect="light"
            >
              <el-button
                type="success"
                size="small"
                plain
                @click="restorePatient(scope.row.id, scope.row.full_name)"
                v-if="scope.row.enabled == 0"
                ><i class="el-icon-refresh" style="text-decoration: none"></i
              ></el-button>
            </el-tooltip>
          </el-button-group>
        </template>
      </el-table-column>

      <el-table-column fixed="right" align="center" v-else min-width="180">
        <template slot-scope="scope">
          <el-button-group>
            <el-tooltip
              content="Mostra Cartella"
              placement="bottom"
              effect="light"
            >
              <el-button
                type="primary"
                size="small"
                plain
                @click="showPatientRegistry(scope.row.id)"
                ><i class="el-icon-folder-opened"></i
              ></el-button>
            </el-tooltip>
            <el-tooltip
              content="Annulla Dimissione"
              placement="bottom"
              effect="light"
            >
              <el-button
                :loading="restoreLoading"
                type="warning"
                size="small"
                plain
                @click="restoreAdmission(scope.row.id, scope.row.full_name)"
                ><i class="el-icon-back"></i
              ></el-button>
            </el-tooltip>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import Patient from "@/js/api/services/Patient";
import SuperadminPatientsService from "@/js/api/services/superadmin/Patients";
import ArkFile from "@/js/helpers/file";
import moment from "moment";

export default {
  name: "Patients",
  data() {
    return {
      search: "",
      exportLoading: false,
      exportingPatients: false,
      loading: true,
      submitLoading: false,
      restoreLoading: false,
      showDialog: false,
      disabled: false,
      downloadLoading: false,
      file: null,
      patients: [],
      show: false,
      showDismissal: false,
      form: {},
      formDismissal: {},
      uploadHeaders: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        Authorization: `Bearer ${localStorage.getItem("arkicare_session")}`,
      },
      typeList: ["text/csv", "application/vnd.ms-excel"],
      rulesDepartment: {
        department_id: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
      },
      rulesDismissal: {
        type: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        date: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        time: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
      },
      patientList: {
        option: 1,
      },
      options: [
        {
          value: 1,
          label: "Ammessi",
        },
        {
          value: 2,
          label: "Dimessi",
        },
      ],
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 195;
    },
    optionDepartments() {
      return this.$store.state.departmentList;
    },
    optionDismissals() {
      return this.$store.state.config.dismissal_type;
    },
  },
  methods: {
    async fetch() {
      this.loading = true;
      const response = await SuperadminPatientsService.getPatients(
        this.$store.state.structureId,
        this.patientList.option
      );
      this.patients = response.data.patients;
      this.loading = false;
    },
    async destroy(id) {
      await Patient.destroy(id);
      this.$emit("anamnesiUpdate");
      this.fetch();
    },
    async restore(id) {
      await Patient.restore(id);
      this.$emit("anamnesiUpdate");
      this.fetch();
    },
    async destroyDismission(id) {
      await Patient.restoreAdmission(id)
        .then(() => {
          this.$emit("anamnesiUpdate");
          this.fetch();
        })
        .catch(() => {});
      this.restoreLoading = false;
    },
    restoreAdmission(id, full_name) {
      this.$confirm(`Annullare dimissioni di ${full_name} ?`, "Attenzione", {
        confirmButtonText: "Sì",
        cancelButtonText: "Annulla",
        type: "warning",
      })
        .then(() => {
          this.restoreLoading = true;
          this.destroyDismission(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
          this.restoreLoading = false;
        });
    },
    dismissPatient(id, full_name) {
      this.formDismissal = {
        patient_id: id,
        full_name: full_name,
      };
      this.showDismissal = true;
    },
    deletePatient(id, full_name) {
      this.$confirm(`Eliminare ${full_name} ?`, "Attenzione", {
        confirmButtonText: "Sì",
        cancelButtonText: "Annulla",
        type: "warning",
      })
        .then(() => {
          this.destroy(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        });
    },
    restorePatient(id, full_name) {
      this.$confirm(`Ripristinare ${full_name} ?`, "Attenzione", {
        confirmButtonText: "Sì",
        cancelButtonText: "Annulla",
        type: "success",
      })
        .then(() => {
          this.restore(id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        });
    },
    updatePatient(id, full_name, department_id) {
      this.form = {
        patient_id: id,
        full_name: full_name,
        department_id: department_id,
      };
      this.show = true;
    },
    async submit(form) {
        this.submitLoading = true;
        this.$refs['form' + form].validate((valid) =>
        {
            if(valid){
                if (form == "Department")
                Patient.update(this.form.patient_id, this.form);
                if (form == "Dismissal") {
                let params = {
                    date: moment(this.formDismissal.date).format("YYYY-MM-DD"),
                    time: moment(this.formDismissal.time).format("HH:mm:ss"),
                    type: this.formDismissal.type,
                };
                Patient.dismiss(this.formDismissal.patient_id, params);
                }
                this.close();
                this.$emit("anamnesiUpdate");
                this.fetch();
            } else {
                this.submitLoading = false;
                return false;
            }
        });
    },
    editPatient(id, patient) {
      this.$router.push({
        name: "Modifica Ospite",
        params: { id, patient },
      });
    },
    showPatientRegistry(id) {
      this.$router.push(`/patients/${id}`);
    },
    exportPatients() {
      this.exportingPatients = true;
      const params = { structureId: this.$store.state.structureId };
      ArkFile.downloadFromRequest(
        SuperadminPatientsService.export(params),
        "patients.xlsx"
      )
        .catch(() => {
          this.exportingPatients = false;
        })
        .finally(() => {
          this.exportingPatients = false;
        });
    },
    saveFile(file) {
      this.file = file;
      this.disabled = true;
    },
    resumeDrag() {
      this.disabled = false;
    },
    beforeUpload(file) {
      const accettable = this.typeList.includes(file.type);

      const isLt50M = file.size / 1024 / 1024 < 50;

      if (!accettable) {
        this.$notify({
          title: "Error",
          message: "Formato del File non supportato!",
          type: "error",
        });
      }
      if (!isLt50M) {
        this.$notify({
          title: "Error",
          message: "La dimensione del file supera il limite di 50MB!",
          type: "error",
        });
      }
      return accettable && isLt50M;
    },
    warningFile(error) {
      const obj = JSON.parse(error.message);
      this.$notify({
        title: "Errore",
        message: obj.message,
        type: "error",
      });
    },
    async exampleExport(fileType) {
      this.downloadLoading = true;
      const params = { fileType: fileType };
      ArkFile.downloadFromRequest(Patient.exampleExport(params), "")
        .then(() => {
          this.$notify({
            title: "Ok!",
            message: "Download completato",
            type: "success",
          });
        })
        .catch(() => {
          this.downloadLoading = false;
        })
        .finally(() => {
          this.downloadLoading = false;
        });
    },
    importFile() {
      this.downloadLoading = true;
      const params = {
        file: this.file,
        structureId: this.$store.state.structureId,
      };
      Patient.importFile(params)
        .then((response) => {
          if (response.data.status == "warning") {
            this.generateAndDownloadFile(response.data.mess);
            this.$notify({
              title: "Warning",
              message: "Diversi warning presenti, ecco il file di riepilogo.",
              type: "warning",
            });
          }
        })
        .catch((error) => {
          this.warningFile(error);
        })
        .finally(() => {
          this.fetch();
          this.downloadLoading = false;
          this.showDialog = false;
          this.file = null;
          this.disabled = false;
        });
    },
    generateAndDownloadFile(message) {
      const link = document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent("\uFEFF" + message)
      );
      link.setAttribute("download", "Import_warnings.txt");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    rowClass({ row, rowIndex }) {
      if (row.enabled == 0) {
        return "deleted-row";
      }
    },
    close() {
      this.formDismissal = {};
      this.submitLoading = false;
      this.showDismissal = false;
      this.show = false;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style lang="scss" scoped>
.margin-top-bottom {
  height: 50px;
  font-size: 20px;
}
.p-10 {
  padding: 10px;
}
.el-button.is-loading {
  position: fixed !important;
}
.export-patients {
  z-index: 2;
  position: fixed;
  left: 100px;
  top: 75px;
  background: transparent !important;
  padding: 12px 12px !important;
}

button#buttonDownloadExample.el-button.is-loading {
  position: relative !important;
}

.noPositionFixed {
  justify-content: space-between;
  padding: 8px;
}
</style>
