<template>
    <el-row v-loading="loading" :gutter="20" class="no-margin-row">
        <el-card class="box-card">
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_aged)">
                        <font-awesome-icon icon="long-arrow-alt-left" />Indietro
                    </el-button>
                </el-col>

                <el-col :span="12" class="text-center titleCol">
                    <span :class="{ 'deleted-row': test_aged.enabled == 0 }">
                        <b class="title">{{ this.test_name }}</b>
                        <br />
                        <small>{{ test_aged.clean_date_time_test }}</small>
                    </span>
                </el-col>

                <el-col :span="6">
                    <el-button class="float-right" round :disabled="disableNext" @click="show(test_next_aged)">
                        Avanti
                        <font-awesome-icon icon="long-arrow-alt-right" />
                    </el-button>
                </el-col>
            </el-row>
            <br />
            <br />

            <el-row :gutter="20">
                <tests-disable :test="test_aged" :test_model="test_model_aged" @showparent="show" />
            </el-row>
            <br />
            <br />

            <el-row :gutter="20">
                <el-form ref="form" label-position="top" label-width="80px" disabled>
                    <!-- DATA -->

                    <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                        <el-divider content-position="left">COERENZA</el-divider>
                        <el-form-item prop="q1_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Coerente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q1_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Parzialmente o saltuariamente incoerente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q1_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Incoerente (bisogno di sorveglianza continua)
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q1_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-divider content-position="left">ORIENTAMENTO</el-divider>
                        <el-form-item prop="q2_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Ben orientato
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q2_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Parzialmente o saltuariamente disorientato
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q2_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Disorientamento Temporo-spaziale
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q2_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Sorveglianza a incoerente e/o confuso
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q20_result_a > 0" v-model="test_aged.q20_result_a" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Sorveglianza a incoerente e/o confuso itinerante
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="3" :checked="test_aged.q20_result_b > 0" v-model="test_aged.q20_result_b" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>

                    <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                        <el-divider content-position="left">INSERIMENTO SOCIALE</el-divider>
                        <el-form-item prop="q3_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Nessun probl. di inserimen. nel proprio contesto
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q3_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Ha qualche difficoltâ (rapporti con frequenti contrasti)
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q3_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Gravi problemi (non riesce a mantenere rapporti)
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q3_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Parziale  aiuto all'inserimento in comunità
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q21_result_a > 0" v-model="test_aged.q21_result_a" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto all'insermiento in comunità
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q21_result_b > 0" v-model="test_aged.q21_result_b" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>

                    <el-card style="margin: 1rem 4rem" body-style="margin: 20px 40px">
                        <el-divider content-position="left">VISTA</el-divider>
                        <el-form-item prop="q4_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Vede bene (anche con lenti)
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q4_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    La funzione é parzialmente conservata
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q4_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Gravi problemi (non riesce a mantenere rapporti)
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q4_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Impegno assistenziale specifico medio
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="0.5" :checked="test_aged.q22_result_a > 0" v-model="test_aged.q22_result_a" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Impegno assistenziale specifico alto
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q22_result_b > 0" v-model="test_aged.q22_result_b" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>

                    <el-card style="margin: 1rem 4rem" body-style="margin: 20px 40px">
                        <el-divider content-position="left">UDITO</el-divider>
                        <el-form-item prop="q5_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Sente bene (anche con protesi acustica)
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q5_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    La funzione é parzialmente conservata
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q5_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Non udente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q5_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Impegno assistenziale specifico medio
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="0.5" :checked="test_aged.q23_result_a > 0" v-model="test_aged.q23_result_a" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Impegno assistenziale specifico alto
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q23_result_b > 0" v-model="test_aged.q23_result_b" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>

                    <el-card style="margin: 1rem 4rem" body-style="margin: 20px 40px">
                        <el-divider content-position="left">PAROLA</el-divider>
                        <el-form-item prop="q6_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Parla e comprende normalmente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q6_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Difficoltà espressione verbale (Disartria)
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q6_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Turbe gravi sia dell’espres. che della compr. verb.
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q6_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Afasia con comprensione parz. conservata
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="0.5" :checked="test_aged.q24_result_a > 0" v-model="test_aged.q24_result_a" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Afasia grave
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q24_result_b > 0" v-model="test_aged.q24_result_b" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>
                    <el-card style="margin: 1rem 4rem" body-style="margin: 20px 40px">
                        <el-divider content-position="left">IGIENE SUPERIORE</el-divider>
                        <el-form-item prop="q7_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q7_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Parzialmente autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q7_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Non autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q7_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-divider content-position="left">IGIENE INFERIORE</el-divider>
                        <el-form-item prop="q8_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q8_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Parzialmente autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q8_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Non autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q8_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto ad autosufficiente parziale
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q25_result_a > 0" v-model="test_aged.q25_result_a" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Non autosufficiente fisico
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q25_result_b > 0" v-model="test_aged.q25_result_b" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Non autosuff. incoerente e\o confuso
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q25_result_c > 0" v-model="test_aged.q25_result_c" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Non autosufficiente incontinente lucido
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="3" :checked="test_aged.q25_result_d > 0" v-model="test_aged.q25_result_d" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Non autosuff. incoerente e/o confuso incontinente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="4" :checked="test_aged.q25_result_e > 0" v-model="test_aged.q25_result_e" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>
                    <el-card style="margin: 1rem 4rem" body-style="margin: 20px 40px">
                        <el-divider content-position="left">ABBIGLIAMENTO SUPERIORE</el-divider>
                        <el-form-item prop="q9_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q9_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Parzialmente autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q9_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Non autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q9_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-divider content-position="left">ABBIGLIAMENTO CENTRALE</el-divider>
                        <el-form-item prop="q8_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q10_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Parzialmente autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q10_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Non autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q10_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-divider content-position="left">ABBIGLIAMENTO INFERIORE</el-divider>
                        <el-form-item prop="q11_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q11_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Parzialmente autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q11_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Non autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q11_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto ad autosufficiente parziale
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q26_result_a > 0" v-model="test_aged.q26_result_a" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto a non autosufficiente fisico
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q26_result_b > 0" v-model="test_aged.q26_result_b" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto a non autosufficiente confuso
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q26_result_c > 0" v-model="test_aged.q26_result_c" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto a non autosuff. incont. lucido senza dispos.
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q26_result_d > 0" v-model="test_aged.q26_result_d" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Non auto. incoer. e/o confuso incont.senza dispositivi
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="3" :checked="test_aged.q26_result_e > 0" v-model="test_aged.q26_result_e" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>

                    <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                        <el-divider content-position="left">TIPO DI ALIMENTAZIONE</el-divider>
                        <el-form-item prop="q12_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Normale (per os)
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q12_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Liquida o semiliquida per os
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q12_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Artificiale (SNG, PEG, NPT)
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q12_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Preparazione alimentazione parenterale o con sonda
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q27_result > 0" v-model="test_aged.q27_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>

                    <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                        <el-divider content-position="left">AIUTO ALLA ALIMENTAZIONE</el-divider>
                        <el-form-item prop="q13_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Nessun aiuto
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q13_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Adattamento del pasto (aiuto per tagliare i cibi)
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q13_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto totale e sorveglianza continua
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q13_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Adattamento del pasto
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q28_result_a > 0" v-model="test_aged.q28_result_a" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto a non autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="6" :checked="test_aged.q28_result_c > 0" v-model="test_aged.q28_result_c" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Non autosufficiente SNG o PEG
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="6" :checked="test_aged.q28_result_b > 0" v-model="test_aged.q28_result_b" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>

                    <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                        <el-divider content-position="left">CONTINENZA URINARIA</el-divider>
                        <el-form-item prop="q14_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Continente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q14_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Incontinente saltuario
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q14_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Incontinente frequente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q14_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-divider content-position="left">CONTINENZA FECALE</el-divider>
                        <el-form-item prop="q15_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Continente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q15_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Incontinente saltuario
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q15_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Incontinente frequente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q15_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Assist. padella o pappagallo e aiuto aIl’uso del wc
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q29_result_a > 0" v-model="test_aged.q29_result_a" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Assistenza uso assorbente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q29_result_b > 0" v-model="test_aged.q29_result_b" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Usa assorbente anche di notte?
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio label="No" v-model="test_aged.q29_result_e" border />
                                    <el-radio label="Si" v-model="test_aged.q29_result_e" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Assistenza per catetere vescicale
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q29_result_f > 0" v-model="test_aged.q29_result_f" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Assistenza per raccoglitore esterno (tipo Conveyn)
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q29_result_c > 0" v-model="test_aged.q29_result_c" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Assistenza per neostoma uretero-cutaneo
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q29_result_g > 0" v-model="test_aged.q29_result_g" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Assistenza per neostoma entero-cutaneo
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q29_result_d > 0" v-model="test_aged.q29_result_d" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>

                    <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                        <el-divider content-position="left">MOBILIZZAZIONE DAL LETTO</el-divider>
                        <el-form-item prop="q16_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Si alza senza aiuto o non si alza
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q16_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Deve essere aiutato
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q16_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Deve essere alzato Passivamente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q16_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto umano per lasciare il letto
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q30_result > 0" v-model="test_aged.q30_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>

                    <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                        <el-divider content-position="left">MOBILITA’ INTERNA</el-divider>
                        <el-form-item prop="q17_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Si muove senza limiti nella residenza
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q17_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Si muove solo in camera
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q17_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Non può essere alzato mai dal letto
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q17_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Prevenzione decubiti
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q31_result_a > 0" v-model="test_aged.q31_result_a" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Mobilizzazione passiva a letto
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q31_result_b > 0" v-model="test_aged.q31_result_b" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>

                    <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                        <el-divider content-position="left">AIUTO ALLA DEAMBULAZIONE</el-divider>
                        <el-form-item prop="q18_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Nessun aiuto
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q18_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto meccanico (bastone,tripode,carrozzella autosuf.)
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q18_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto umano (anche per spingere carrozzella)
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q18_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto umano per spostamento in carrozzella
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q32_result_a > 0" v-model="test_aged.q32_result_a" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto umano per deambulazione in reparto/casa
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q32_result_b > 0" v-model="test_aged.q32_result_b" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>

                    <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                        <el-divider content-position="left">IGIENE POSTO LETTO</el-divider>
                        <el-form-item prop="q19_result">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Autosufficiente
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="2" v-model="test_aged.q19_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Necessita di aiuto
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="1" v-model="test_aged.q19_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Nessuna attività
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-radio :label="0" v-model="test_aged.q19_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Aiuto igiene posto letto
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="0.5" :checked="test_aged.q33_result_a > 0" v-model="test_aged.q33_result_a" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Igiene posto letto a non autosufficiente fisico
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q33_result_c > 0" v-model="test_aged.q33_result_c" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Igiene posto letto a incont. lucido senza disp.
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q33_result_d > 0" v-model="test_aged.q33_result_d" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Igiene posto letto a incoerente e/o confuso
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q33_result_b > 0" v-model="test_aged.q33_result_b" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Igiene p.l. a incoerente e/o confuso senza disp.
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="3" :checked="test_aged.q33_result_e > 0" v-model="test_aged.q33_result_e" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>

                    <el-card style="margin:1rem 4rem" body-style="margin:20px 40px">
                        <el-divider content-position="left">NURSING INFERMIERISTICO</el-divider>
                        <el-form-item class="form-item-blue">
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Preparazione e distribuz. terapia per os e parenterale
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q34_result > 0" v-model="test_aged.q34_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Preparazione terapia intensiva EV
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q35_result > 0" v-model="test_aged.q35_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Medicazione presidi (stomie) e lesioni cutanee
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q36_result > 0" v-model="test_aged.q36_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Sorveglianza infermieristica a NPT
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="3" :checked="test_aged.q37_result > 0" v-model="test_aged.q37_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Sorveglianza infermieristica a terapia EV
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="3" :checked="test_aged.q38_result > 0" v-model="test_aged.q38_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Sorv. inferm. ad alim. per stomia entero-cutanea
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q39_result > 0" v-model="test_aged.q39_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Sorveglianza infermieristica a fleboclisi
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="1" :checked="test_aged.q40_result > 0" v-model="test_aged.q40_result" border />
                                </el-col>
                            </el-row>
                            <el-row :gutter="40">
                                <el-col :span="16">
                                    Sorveglianza infermieristica a terapia peridurale
                                </el-col>
                                <el-col :span="8" class="text-left">
                                    <el-checkbox :label="2" :checked="test_aged.q41_result > 0" v-model="test_aged.q41_result" border />
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-card>

                    <!-- TOTAL -->
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-divider></el-divider>
                        </el-col>
                        <el-col :span="24">
                            <el-col :span="12">
                                <el-form-item label="Totale">{{ test_aged.total }}</el-form-item>
                            </el-col>
                        </el-col>
                    </el-row>
                </el-form>
            </el-row>
        </el-card>

    </el-row>
</template>

<script>
import Test from "@/js/api/services/Test";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
    name: "aged",
    components: {
        TestsDisable,
    },
    props: [
        "test",
        "test_prev",
        "test_next",
        "test_name",
        "test_model",
        "test_type"
    ],
    data() {
        return {
            test_aged: this.test,
            test_prev_aged: this.test_prev,
            test_next_aged: this.test_next,
            test_name_aged: this.test_name,
            test_model_aged: this.test_model,
            test_type_aged: this.test_type,
            patient_id_aged: this.test.patient_id,
            loading: true,
            disablePrev: false,
            disableNext: false,
        };
    },
    methods: {
        async show(test_id) {
            this.loading = true;

            let params = {
                patient_id: this.patient_id_aged,
                test_model: this.test_model_aged,
                test_id: test_id,
                type_id: this.test_type_aged
            };

            const response = await Test.show(params);
            this.test_aged = response.data.test;
            this.test_name_aged = response.data.test_name;
            this.test_prev_aged = response.data.test_prev;
            this.test_next_aged = response.data.test_next;

            this.checkPrevNext();
        },
        checkPrevNext() {
            if (this.test_prev_aged === null) this.disablePrev = true;
            else this.disablePrev = false;

            if (this.test_next_aged === null) this.disableNext = true;
            else this.disableNext = false;

            this.loading = false;
        }
    },
    beforeMount() {
        this.checkPrevNext();
    }
};
</script>
<style scoped>
.radio-label {
    white-space: normal;
}

.radio-label_text {
    margin-left: 20px;
}

.el-form-item {
    padding: 1rem;
    border: 1px solid transparent;
}

.form-item-blue {
    background-color: #f3f7fa;
}
</style>
