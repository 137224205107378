<script>
import SelectActivity from "./SelectActivity.vue";
import SelectMonth from "./SelectMonth.vue";
import SelectParticipation from "./SelectParticipation.vue";
import SelectPatient from "./SelectPatient.vue";
import SelectActivityStatus from "./SelectActivityStatus.vue";
import CreateActivityEntry from "../dialogs/CreateActivityEntry.vue";
import ArkFile from "@/js/helpers/file";
import { PatientActivity } from "@/js/api/services/activity";
import moment from "moment";
import CreatePatientActivity from "../dialogs/CreatePatientActivity.vue";

export default {
  components: {
    SelectActivity,
    SelectMonth,
    SelectParticipation,
    SelectPatient,
    SelectActivityStatus,
    CreateActivityEntry,
    CreatePatientActivity,
  },
  name: "ActivityFilters",
  props: ["patient", "filterPatient", "disabled"],
  data() {
    return {
      form: {
        date: new Date(),
        activityStatus: 1,
        activity: null,
        patient_id: null,
      },
      showAbsentPatientAlert: false,
      showCreateActivityDialog: false,
    };
  },
  computed:{
    patients() { return this.$store.state.patients },
  },
  methods: {
    showActivityForm() {
      this.showCreateActivityDialog = true;
    },
    activitySubmitted(submitted) {
      if (this.$refs.selectActivity) {
        this.$refs.selectActivity.getOptions();
      }
      this.$emit("patientActivitySubmitted", {
        patient_id: this.getPatientId(),
        submitted,
      });
    },
    getPatientId() {
      if (this.patient) {
        return this.patient.id;
      }
      return this.form.patient_id;
    },
    onFilterChange(form) {
      this.showAbsentPatientAlert = false;
      this.$emit("filterChange", this.provideFilters());
    },
    getDateFromForm() {
      return this.form.date ? moment(this.form.date) : undefined;
    },
    provideFilters() {
      const date = this.getDateFromForm();
      const params = {
        patient_id: this.getPatientId(),
        enabled: this.form.activityStatus,
        year: date ? date.year() : undefined,
        month: date ? date.month() + 1 : undefined,
        activity_id: _.get(this.form, "activity.id") || undefined,
      };
      return params;
    },
    downloadPdf() {
      this.loading = true;
      const params = this.provideFilters();
      if (!params.patient_id) return;
      ArkFile.downloadFromRequest(
        PatientActivity.exportPdf(params),
        ""
      ).finally(() => {
        this.loading = false;
      });
    },
    copyPreviousActivities() {
      this.$emit("copyPreviousActivities");
    },
  },
  watch: {
    form: {
      deep: true,
      handler(data) {
        this.onFilterChange(data);
        if(this.form.patient_id){
            var patient = _.filter(this.patients, { id: this.form.patient_id })[0];
            this.showAbsentPatientAlert = patient.absence_info.absence_status;
        }
      },
    },
  },
};
</script>
<template>
  <div class="wrapper">
    <create-patient-activity
      @submitted="activitySubmitted"
      :patient="patient"
      :patientId="form.patient_id"
      :date="getDateFromForm()"
      :visible.sync="showCreateActivityDialog"
    >
    </create-patient-activity>
    <el-row :gutter="20" justify="space-between" align="center" type="flex">
      <el-col :span="6" :lg="4" v-if="filterPatient">
        <select-patient
          v-model="form.patient_id"
          :disabled="disabled"
        ></select-patient>
        <span v-if="showAbsentPatientAlert">
            <font-awesome-icon icon="exclamation" class="danger-text" />
            <font style="color: red"
            >Attenzione: l'ospite è attualmente assente</font
            >
        </span>
      </el-col>

      <el-col :span="6" :lg="4">
        <select-activity-status
          v-model="form.activityStatus"
          :disabled="disabled"
        ></select-activity-status>
      </el-col>

      <el-col :span="8">
        <select-activity
          ref="selectActivity"
          v-model="form.activity"
          label="Attivitá"
          :disabled="disabled"
        ></select-activity>
      </el-col>

      <el-col :span="6" :lg="4">
        <select-month
          v-model="form.date"
          :defaultValue="new Date()"
          :disabled="disabled"
        ></select-month>
      </el-col>

      <el-col :span="6" class="actionsGroup">
        <el-button-group>
            <el-button
                type="warning"
                size="small"
                icon="el-icon-document-copy"
                @click="copyPreviousActivities"
                :disabled="disabled || !Boolean(getPatientId())"
            ></el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-download"
            @click="downloadPdf"
            :disabled="disabled || !Boolean(getPatientId())"
          ></el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="showActivityForm"
            :disabled="disabled || !Boolean(getPatientId())"
          ></el-button>
        </el-button-group>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.wrapper {
  padding: 0 20px;
}

.actionsGroup {
  display: flex;
  flex: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
</style>
