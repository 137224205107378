<template>
  <div class="block no-filters">
    <el-row v-loading="loading" :gutter="20">
      <el-col :span="24" class="margin-top">
        <el-form
          :model="form"
          label-position="top"
          label-width="100px"
          :rules="rules"
          ref="form"
        >
          <el-col :span="12">
            <el-form-item label="Ruolo" prop="role_id">
              <el-select v-model="form.role_id" placeholder="Selezionare ruolo">
                <el-option
                  v-for="(item, index) in roles"
                  :key="index"
                  :label="item"
                  :value="index"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Username LDAP" prop="username">
              <el-input type="username" v-model="form.username"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="Professione" prop="profession_id">
              <el-select
                v-model="form.profession_id"
                placeholder="Selezionare professione"
              >
                <el-option
                  v-for="(item, index) in professions"
                  :key="index"
                  :label="item.label"
                  :value="index"
                  :disabled="!item.enabled"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="14" class="checkbox-title">
            Strutture Associate
          </el-col>
          <el-col :span="10" align="right">
            <el-checkbox @change="handleCheckAll" v-model="allSelected"
              >Seleziona Tutto</el-checkbox
            >
          </el-col>

          <el-col :span="24">
            <el-divider></el-divider>
          </el-col>

          <el-checkbox-group v-model="form.user_structures">
            <el-col
              :span="12"
              :gutter="25"
              v-for="(item, index) in structures"
              :key="index"
            >
              <el-checkbox :label="index" @change="checkClicked">
                {{ item }}
              </el-checkbox>
            </el-col>
          </el-checkbox-group>

          <el-col :span="24">
            <el-divider></el-divider>
            <el-col :span="12" :gutter="0">
              <el-button
                size="large"
                type="warning"
                @click="$router.push('/superadmin/operators')"
              >
                Annulla
              </el-button>
            </el-col>

            <el-col :span="12" :gutter="0" align="right">
              <el-button
                size="large"
                type="success"
                @click.native.prevent="submit()"
                :loading="loading"
              >
                Aggiorna Operatore
              </el-button>
            </el-col>
          </el-col>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SuperadminOperatorsService from "@/js/api/services/superadmin/Operators";
import User from "@/js/api/services/User";

export default {
  name: "SuperadminOperatorsInsert",
  data() {
    return {
      loading: true,
      roles: [],
      professions: [],
      structures: [],
      form: {
        role_id: null,
        profession_id: null,
        username: null,
        password: null,
        user_structures: [],
      },
      rules: {
        role_id: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        profession_id: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        username: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        password: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
      },
      allSelected: false,
    };
  },
  computed: {
    maxHeight() {
      return window.innerHeight - 205;
    },
  },
  methods: {
    async fetch() {
      const response = await SuperadminOperatorsService.createOperator();
      this.roles = response.data.roles;

      this.professions = this.$store.state.professions;

      this.structures = response.data.structures;
      this.loading = false;
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.store();
        } else {
          return false;
        }
      });
    },
    async store() {
      const response = await User.store(this.form);
      this.$router.push({ path: "/superadmin/operators" });
    },
    handleCheckAll() {
      this.form.user_structures = [];

      if (this.allSelected) {
        for (let index in this.structures) {
          this.form.user_structures.push(index);
        }
      }
    },
    checkClicked() {
      this.allSelected = false;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>


<style lang="scss" scoped>
.checkbox-title {
  color: #606266;
}
.md-app-content {
  height: auto !important;
}
</style>

