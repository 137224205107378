<template>
  <div>
    <!-- <arki-check-two-factor
      :prescriptions="prescriptions"
      :updates="toUpdate"
      :profile_id="profile_id"
      ref="arkiCheckTwoFactor"
      @success="backToTherapies()"
    /> -->

    <el-row :gutter="20">
      <md-tabs
        class="md-transparent"
        md-alignment="fixed"
        :md-active-tab="activeTab"
        @md-changed="onTabChange"
        ref="therapyTabs"
      >
        <!-- Therapies Table -->
        <md-tab id="therapies" md-label="Terapie">
          <therapy-table
            action="therapy"
            :profile_id="profile_id"
            :show-dialog.sync="showAddDialogForm"
            :to-update.sync="toUpdate"
            :prescriptions="filteredTherapiesPrescriptions"
            :loadingButton.sync="loadingButton"
            @updateModal="updateTherapy"
            @updateSubmit="therapySubmit()"
            @clearDraft="clearDraft()"
          />
        </md-tab>

        <!-- Protections Table -->
        <md-tab id="protections" md-label="Indicazioni Contenzioni">
          <therapy-table
            action="protection"
            :profile_id="profile_id"
            :show-dialog.sync="showAddDialogForm"
            :to-update.sync="toUpdate"
            :prescriptions="filteredProtectionsPrescriptions"
            :loadingButton.sync="loadingButton"
            @updateModal="updateTherapy"
            @updateSubmit="therapySubmit()"
            @clearDraft="clearDraft()"
          />
        </md-tab>

        <!-- Create/edit New Therapy -->
        <el-dialog
          :title="modalEditMode ? 'Modifica' : 'Aggiungi'"
          append-to-body
          :center="true"
          :visible.sync="showAddDialogForm"
          :before-close="onDialogClose"
          width="90%"
        >
          <el-form
            label-position="top"
            label-width="80px"
            :model="form"
            :rules="rules"
            :validate-on-rule-change="false"
            ref="form"
          >
            <el-row :gutter="20">
              <el-col :span="24" style="padding-bottom: 15px">
                <b>Allergie:</b>
                {{ _.get(this.patient, "registry.allergie") }}
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item label="Tipologia" prop="type">
                  <el-select
                    v-model="form.type"
                    @change="onTypeChange"
                    :disabled="this.modalEditMode"
                  >
                    <el-option
                      v-for="item in newOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-if="form.type && form.type != 4">
              <el-col :xs="12" :sm="16" :md="16" :lg="12" :xl="12">
                <el-form-item label="Farmaco" prop="farmaco">
                  <el-select
                    v-model="form.farmaco"
                    data-is-search="true"
                    clearable
                    filterable
                    remote
                    allow-create
                    reserve-keyword
                    :remote-method="fetchFarmaco"
                    :loading="loadingFarmaco"
                    placeholder="Cerca..."
                    ref="selectFarmaco"
                    :disabled="this.modalEditMode"
                  >
                    <el-option
                      v-for="item in farmaci"
                      class="select-down-farm"
                      :key="item.id"
                      :label="`${item.denominazione_confezione} (${item.titolare_aic})`"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="12" :sm="8" :md="8" :lg="6" :xl="6">
                <el-form-item label="Somministrazione" prop="somministrazione">
                  <el-select
                    v-model="form.somministrazione"
                    filterable
                    allow-create
                    ref="somministrazione"
                  >
                    <el-option
                      v-for="item in subministrations"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col v-if="[1, 2, 3].some((type) => type == form.type)">
                <el-divider />
                <el-row type="flex" justify="center">
                  <el-col :span="12" v-if="form.type == 1">
                    <el-form-item class="switcher">
                      <el-switch
                        @change="onIntervalChange"
                        v-model="interval"
                        active-text="Settimanale"
                        inactive-text="Intervallo di giorni"
                        style="display: block"
                      />
                    </el-form-item>
                  </el-col>
                  <el-divider
                    v-if="form.type == 1"
                    direction="vertical"
                  ></el-divider>
                  <el-col :span="12">
                    <el-form-item class="switcher">
                      <el-switch
                        v-model="dataFine"
                        active-text="Data fine"
                        inactive-text="Data rivalutazione"
                        style="display: block"
                        @change="onDataFineChange"
                      >
                      </el-switch>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-divider />
              </el-col>

              <div v-if="interval">
                <el-col :span="24">
                  <el-form-item label="Giorni" prop="intervalDays">
                    <el-select
                      v-model="intervalDays"
                      multiple
                      placeholder="Seleziona"
                    >
                      <el-option
                        v-for="item in daysOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                  <el-row
                    :gutter="20"
                    v-for="(item, idx) in intervalDays"
                    :key="idx"
                  >
                    <el-col :span="12">
                      <el-col>
                        <el-form-item
                          :label="`Quantità per ${item}`"
                          :prop="`quantity_${item}`"
                          :rules="dynamicRule(true)"
                        >
                          <el-input-number
                            v-model="form[`quantity_${item}`]"
                            :min="0.25"
                            :step="0.25"
                          ></el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                        :label="`Orario per ${item}`"
                        :prop="`orario_${item}`"
                        style="display: block"
                        :rules="dynamicRule()"
                      >
                        <el-select
                          v-model="form[`orario_${item}`]"
                          filterable
                          @change="resetField(`orario_${item}`)"
                          allow-create
                          multiple
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-col>
              </div>

              <el-col :xs="6" :sm="8" :md="6" :lg="6" :xl="6" v-if="!interval">
                <el-form-item label="Quantità" prop="quantity">
                  <el-input-number
                    v-model="form.quantity"
                    :min="0"
                    :step="0.25"
                  ></el-input-number>
                </el-form-item>
              </el-col>

              <el-col
                :xs="18"
                :sm="16"
                :md="6"
                :lg="18"
                :xl="18"
                v-if="form.type == 2"
              >
                <el-form-item label="Condizioni e Tempistiche" prop="bisogno">
                  <el-input v-model="form.bisogno"></el-input>
                </el-form-item>
              </el-col>

              <el-col
                :xs="6"
                :sm="8"
                :md="6"
                :lg="6"
                :xl="6"
                v-if="form.type == 1 && !interval"
              >
                <el-form-item label="Ogni n° Giorni" prop="every">
                  <el-input-number
                    v-model="form.every"
                    :min="1"
                  ></el-input-number>
                </el-form-item>
              </el-col>

              <el-col
                :xs="6"
                :sm="4"
                :md="6"
                :lg="6"
                :xl="6"
                v-if="form.type == 3"
              >
                <el-form-item label="Dopo n° Giorni" prop="days">
                  <el-input-number
                    v-model="form.days"
                    :min="1"
                  ></el-input-number>
                </el-form-item>
              </el-col>

              <el-col
                :xs="12"
                :sm="8"
                :md="12"
                :lg="6"
                :xl="6"
                v-if="form.type == 1 && !interval"
              >
                <el-form-item label="Orario" prop="orario">
                  <el-select
                    v-model="form.orario"
                    filterable
                    allow-create
                    multiple
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col
                :xs="12"
                :sm="12"
                :md="12"
                :lg="6"
                :xl="6"
                v-if="form.type == 1"
              >
                <el-form-item label="Prima somministrazione" prop="date_time">
                  <el-date-picker
                    v-model="form.date_time"
                    :editable="false"
                    type="date"
                    :picker-options="{
                      firstDayOfWeek: 1,
                      disabledDate(time) {
                        return time.getTime() < Date.now() - 3600 * 1000 * 24;
                      },
                    }"
                    placeholder="Scegli data"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="12" :sm="12" :md="12" :lg="6" :xl="6">
                <el-form-item
                  label="Data rivalutazione"
                  prop="verification_date"
                  v-if="!dataFine"
                >
                  <el-date-picker
                    v-model="form.verification_date"
                    type="date"
                    placeholder="Scegli data"
                    :editable="false"
                    :picker-options="{
                      shortcuts,
                      firstDayOfWeek: 1,
                      disabledDate(time) {
                        return time.getTime() < Date.now() - 3600 * 1000 * 24;
                      },
                    }"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item
                  label="Data Fine"
                  prop="therapy_end_date"
                  v-if="dataFine"
                >
                  <el-date-picker
                    v-model="form.therapy_end_date"
                    type="date"
                    placeholder="Scegli data"
                    :editable="false"
                    :picker-options="{
                      firstDayOfWeek: 1,
                      disabledDate(time) {
                        return time.getTime() < Date.now();
                      },
                      shortcuts,
                    }"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="Note" prop="description">
                  <el-input
                    :rows="4"
                    type="textarea"
                    placeholder="Ulteriori indicazioni"
                    v-model="form.description"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-else-if="form.type">
              <el-col :span="12">
                <el-form-item label="Categoria" prop="protection_type">
                  <el-select
                    v-model="form.protection_type"
                    placeholder="Seleziona Categoria"
                    ref="protection_type"
                  >
                    <el-option
                      v-for="item in protection_types"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Data rivalutazione" prop="end_date">
                  <el-date-picker
                    v-model="form.end_date"
                    type="date"
                    placeholder="Scegli data"
                    :editable="false"
                    :picker-options="{
                      firstDayOfWeek: 1,
                      disabledDate(time) {
                        return time.getTime() < Date.now() - 3600 * 1000 * 24;
                      },
                    }"
                    format="dd/MM/yyyy"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                  <small
                    >Attenzione: inserire una data coerente con gli obblighi di
                    legge</small
                  >
                </el-form-item>
              </el-col>
              <el-col :xs="24">
                <el-form-item label="Tipologia" prop="category">
                  <el-radio v-model="form.category" label="3" value="3" border
                    >Monitoraggio 2 Ore</el-radio
                  >
                  <el-radio v-model="form.category" label="4" value="4" border
                    >Monitoraggio 30 Minuti</el-radio
                  >
                  <el-radio v-model="form.category" label="6" value="6" border
                    >Al bisogno</el-radio
                  >
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Pratiche alternative testate"
                  prop="alternative_practices"
                >
                  <el-input
                    type="textarea"
                    placeholder="Pratiche alternative"
                    v-model="form.alternative_practices"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item
                  label="Condizioni ed Indicazioni"
                  prop="indication"
                >
                  <el-input
                    type="textarea"
                    placeholder="Ulteriori indicazioni"
                    v-model="form.indication"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" v-if="form.type">
            <el-button type="warning" @click="resetDialogForm"
              >Annulla</el-button
            >
            <el-button type="success" @click="submit" :loading="submitLoading">
              {{ modalEditMode ? "Modifica" : "Aggiungi" }}
            </el-button>
          </div>
        </el-dialog>
      </md-tabs>
    </el-row>
  </div>
</template>
<script>
import moment from "moment";
import Therapy from "@/js/api/services/Therapy";
import Protection from "@/js/api/services/Protection";
import ArkiCheckTwoFactor from "@/js/components/ArkiCheckTwoFactor";
import TherapyTable from "./TherapyTable";

const DAYS = [
  "Lunedí",
  "Martedí",
  "Mercoledí",
  "Giovedí",
  "Venerdí",
  "Sabato",
  "Domenica",
];

const Week = {
  Lunedí: 1,
  Martedí: 2,
  Mercoledí: 3,
  Giovedí: 4,
  Venerdí: 5,
  Sabato: 6,
  Domenica: 7,
};

const dynamicKeys = () => {
  let ks = [];
  DAYS.forEach((d) => {
    ks = [...ks, `orario_${d}`, `quantity_${d}`];
  });
  return ks;
};

export default {
  name: "Therapy",
  props: ["profile_id"],
  components: {
    TherapyTable,
    ArkiCheckTwoFactor,
  },
  data() {
    return {
      interval: false,
      intervalDays: [],
      activeTab: "therapies",
      tabId: "therapies",
      submitLoading: false,
      loadingFarmaco: false,
      showAddDialogForm: false,
      toUpdate: {},
      form: {
        quantity: 0,
        therapy_end_date: null,
      },
      newOptions: [],
      subministrations: [],
      farmaci: [],
      protection_types: [],
      patient: {},

      prescriptions: [],

      dataFine: false,

      options: [
        {
          value: "08:00",
          label: "08:00",
        },
        {
          value: "12:00",
          label: "12:00",
        },
        {
          value: "16:00",
          label: "16:00",
        },
        {
          value: "18:00",
          label: "18:00",
        },
        {
          value: "20:00",
          label: "20:00",
        },
      ],

      daysOptions: [
        {
          value: "Lunedí",
          label: "Lunedí",
        },
        {
          value: "Martedí",
          label: "Martedí",
        },
        {
          value: "Mercoledí",
          label: "Mercoledí",
        },
        {
          value: "Giovedí",
          label: "Giovedí",
        },
        {
          value: "Venerdí",
          label: "Venerdí",
        },
        {
          value: "Sabato",
          label: "Sabato",
        },
        {
          value: "Domenica",
          label: "Domenica",
        },
      ],
      shortcuts: [
        {
          text: "Annulla",
          onClick: (picker) => {
            picker.$emit("pick", null);
            this.$refs["form"] && this.$refs["form"].clearValidate();
          },
        },
      ],
      modalEditMode: false,
      loadingButton: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    rules() {
      const VerificationDateValidator = (rule, value, callback) => {
        if (moment(value).diff(moment(), "years") >= 2) {
          callback(new Error("Massimo 2 anni"));
        } else {
          callback();
        }
      };
      const OrarioValidator = (rule, value, callback) => {
        let ok = true;
        value.forEach((element) => {
          if (element.length !== 5) {
            callback(new Error("Richiesto formato HH:mm"));
            ok = false;
          }
        });
        if (ok) {
          callback();
        }
      };
      const IntervalValidator = (rule, value, callback) => {
        return _.isEmpty(this.intervalDays)
          ? callback(new Error("Seleziona almeno un giorno"))
          : callback();
      };
      return {
        orario: [
          { required: true, message: "Inserire Orario", trigger: "blur" },
          { validator: OrarioValidator, trigger: "blur" },
        ],
        verification_date: [
          {
            required: true,
            message: "Campo Obbligatorio",
            trigger: "change",
          },
          { validator: VerificationDateValidator, trigger: "blur" },
        ],
        quantity: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" },
        ],
        description: [
          {
            required: Number(this.form.quantity) == 0,
            message: "Campo Obbligatorio",
            trigger: "blur",
          },
        ],
        farmaco: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        somministrazione: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],

        every: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        bisogno: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        days: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        date_time: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],

        protection_type: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        category: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        indication: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],
        end_date: [
          {
            required: true,
            message: "Campo Obbligatorio",
            trigger: "change",
          },
        ],
        therapy_end_date: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" },
        ],

        intervalDays: [
          {
            required: true,
            message: "Seleziona i giorni",
            validator: IntervalValidator,
            trigger: "change",
          },
        ],
      };
    },
    filteredTherapiesPrescriptions() {
      return _.filter(this.prescriptions, (p) => p.type !== 4);
    },
    filteredProtectionsPrescriptions() {
      return _.filter(this.prescriptions, (p) => p.type === 4);
    },
  },
  methods: {
    resetField(field) {
      this.$refs["form"].clearValidate(field);
    },
    onTypeChange() {
      if (this.$refs["form"]) {
        ["therapy_end_date", "verification_date"].forEach((f) =>
          this.$delete(this.form, f)
        );
        this.dataFine = false;
        this.$refs["form"].clearValidate();
      }
    },
    dynamicRule: (qty) => ({
      required: true,
      message: "Campo Obbligatorio",
      trigger: qty ? "change" : "blur",
    }),
    resetInterval() {
      dynamicKeys().map((k) => this.$delete(this.form, k));
      this.intervalDays = [];
    },
    onIntervalChange(val) {
      if (!val) this.resetInterval();
    },
    resetDialogForm() {
      this.$refs["form"].resetFields();
      this.resetInterval();
      this.interval = false;
      this.form = {};
      this.modalEditMode = false;
      this.farmaci = [];
      this.dataFine = false;
      this.showAddDialogForm = false;
    },
    onDialogClose(done) {
      this.resetDialogForm();
      done();
    },
    clearDraft() {
      this.toUpdate = {};
      this.prescriptions = [];
    },
    onTabChange(tabId) {
      this.tabId = tabId;
      let terapie = [
        { value: 1, label: "Terapia Abitudinale" },
        { value: 2, label: "Terapia Al Bisogno" },
        { value: 3, label: "Terapia Lassativa" },
      ];
      let contenzioni = [{ value: 4, label: "Indicazione Contenzione" }];
      this.newOptions = tabId == "therapies" ? terapie : contenzioni;
    },
    onDataFineChange(value) {
      this.$delete(this.form, value ? "therapy_end_date" : "verification_date");
      this.$refs["form"] && this.$refs["form"].clearValidate();
    },
    async init() {
      const response = await Therapy.settings();
      this.subministrations = response.data;
      const res = await Protection.settings();
      this.protection_types = res.data;
      this.patient = this.$store.state.patients.find(
        (patient) => patient.full_name == this.$store.state.patientName
      );
    },
    fetchFarmaco(query) {
      if (query !== "" && query.length > 3) {
        this.loadingFarmaco = true;
        this.search(query);
      } else {
        this.loadingFarmaco = false;
      }
    },
    async search(query) {
      const response = await Therapy.search(query);
      this.farmaci = response.data;
      this.loadingFarmaco = false;
    },
    onWeeklySubmit() {
      let p = {
        ...this.form, //, uuid: this.$uuid.v1()
      };
      if (p.type == 4) {
        let monitoraggioLabels = {
          3: "Monitoraggio 2 Ore",
          4: "Monitoraggio 30 Minuti",
          6: "Al bisogno",
        };
        p.type_name = _.find(this.protection_types, [
          "id",
          p.protection_type,
        ]).label;
        p.type_description = monitoraggioLabels[p.category];
      }
      this.prescriptions.push(p);
      this.form = {};
    },
    onIntervalSubmit() {
      let selectedDays = this.intervalDays;
      const today = moment().format("YYYY-MM-DD");
      const {
        type,
        farmaco,
        farmacoName,
        somministrazione,
        verification_date,
        somministrazioneLabel,
        description,
        therapy_end_date,
        drop_down_farmaco,
        principio_attivo,
        id,
      } = this.form;

      let data = selectedDays.map((d) => {
        let dayValue = Week[d];
        let dayInfo = _.pickBy(this.form, (value, k) => k.endsWith(d));
        let { quantity, orario } = _.mapKeys(dayInfo, (v, k) => {
          if (k.startsWith("quantity")) return "quantity";
          if (k.startsWith("orario")) return "orario";
          return k;
        });

        let date_time = moment(this.form.date_time)
          .weekday(dayValue)
          .format("YYYY-MM-DD");
        let isBefore = moment(date_time).isBefore(today);
        if (isBefore) {
          date_time = moment(date_time).add(1, "week").format("YYYY-MM-DD");
        }
        return {
          [d]: dayValue,
          weekDay: d,
          quantity,
          orario,
          date_time,
        };
      });

      data.forEach(({ date_time, quantity, orario, weekDay: weekday }) =>
        this.prescriptions.push({
          type,
          farmaco,
          farmacoName,
          somministrazione,
          verification_date,
          date_time,
          quantity,
          orario,
          every: 7,
          somministrazioneLabel,
          description,
          weekday,
          therapy_end_date,
          drop_down_farmaco,
          principio_attivo,
          id,
        })
      );
      this.resetInterval();
      this.interval = false;
      this.form = {};
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.$refs["selectFarmaco"]) {
            this.form.farmacoName = this.$refs["selectFarmaco"].selected.label;
          } else if (this.$refs["protection_type"]) {
            this.form.farmacoName =
              this.$refs["protection_type"].selected.label;
          }
          if (this.$refs["somministrazione"]) {
            this.form.somministrazioneLabel =
              this.$refs["somministrazione"].selected.label;
          }
          this.interval ? this.onIntervalSubmit() : this.onWeeklySubmit();
          this.resetDialogForm();
        } else {
          return false;
        }
      });
    },
    async therapySubmit() {
      await this.$confirm("Procedere con il salvataggio?", "Attenzione", {
        confirmButtonText: "Sì",
        cancelButtonText: "Annulla",
        type: "success",
      })
        .then(async () => {
          this.loadingButton = true;
          let params = {
            toCreate: this.prescriptions,
            toUpdate: this.toUpdate,
            patient_id: this.profile_id,
          };

          const response = await Therapy.upsert(params);
          if (response.data.verification_status == "OK") {
            this.backToTherapies();
            this.$root.$emit("therapies_upserted");
          } else {
            this.error(response.data);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        })
        .finally(() => {
          this.loadingButton = false;
        });
    },
    backToTherapies() {
      this.$store.commit("setResponse", {
        data: {
          message: "Operazione eseguita con successo",
        },
      });
      this.$store.commit("setError", false);
      this.$store.commit("setSuccess", true);

      this.prescriptions = [];
      this.toUpdate = {};
      this.selection = [];
    },
    async updateTherapy(therapy, therapyType) {
      this.modalEditMode = true;
      var farmacoId = null;

      if (therapyType != "protectionsTherapies") {
        if (_.isArray(therapy) && therapy[0].type === 1) {
          this.interval = true;
          this.form = therapy[0];
          let self = this;
          _.forEach(
            _.filter(therapy, function (t) {
              return t.status_update != 3;
            }),
            function (t) {
              self.intervalDays.push(t.weekday);
              self.form = {
                ...self.form,
                ["orario_" + t.weekday]: _.filter(
                  _.split(t.orari, " "),
                  function (o) {
                    return o;
                  }
                ),
              };
              self.form["quantity_" + t.weekday] = t.quantity;
            }
          );
          farmacoId = therapy[0].farmaco ?? null;
        } else {
          this.form = therapy;
          this.form = {
            ...this.form,
            orario: _.filter(_.split(therapy.orari, " "), function (o) {
              return o;
            }),
          };

          if (therapy.type === 3) {
            this.form.days = therapy.every;
          }
          farmacoId = therapy.farmaco ?? null;
        }

        this.form.date_time = moment().format("yyyy-MM-DD") + " 00:00:00";
        this.dataFine = this.form.therapy_end_date ? true : false;

        if (!_.isNaN(parseInt(farmacoId))) {
          await this.search(farmacoId);
          this.form.farmaco = this.farmaci[0].id;
          this.form.farmaco_name = this.farmaci[0].denominazione_confezione;
          this.form.farmacoName = this.farmaci[0].denominazione_confezione;
          this.form.principio_attivo = this.farmaci[0].principio_attivo;
          this.form.drop_down_farmaco = this.farmaci[0];
        } else {
          this.form.farmaco = farmacoId;
          this.form.farmaco_name = farmacoId;
          this.form.farmacoName = farmacoId;
          this.form.principio_attivo = farmacoId;
          //   this.form.drop_down_farmaco = this.farmaci[0];
        }
      } else if (therapyType == "protectionsTherapies") {
        this.form = therapy;
        this.form = {
          ...this.form,
          protection_type: therapy.type,
          category: therapy.category.toString(),
          type: 4,
        };
      }
      this.showAddDialogForm = true;
    },
  },
  watch: {
    "form.quantity": {
      handler(v) {
        this.$refs["form"] && this.$refs["form"].clearValidate();
      },
    },
    "form.verification_date": {
      handler(v) {
        if (!v) return;
        this.form.therapy_end_date && (this.form.therapy_end_date = null);
        this.$refs["form"] && this.$refs["form"].clearValidate();
      },
    },
    "form.therapy_end_date": {
      handler(v) {
        if (!v) return;
        this.form.verification_date && (this.form.verification_date = null);
        this.$refs["form"] && this.$refs["form"].clearValidate();
      },
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="scss" scoped>
.margin {
  margin-top: 15px;
}
.select-down-farm {
  width: 600px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.switcher {
  display: flex;
  justify-content: center;
}
</style>
