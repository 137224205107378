import axios from 'axios';
import store from '@/js/core/store';

let token = document.head.querySelector('meta[name="csrf-token"]');
axios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;

const errorHandler = (error) => {
    if (error) {
        store.commit('setResponse', error);
        store.commit('setError', true);
        store.commit('setSuccess', false);
    }
    return Promise.reject(error);
}

const successHandler = (response) => {
    store.commit('setResponse', response);
    store.commit('setSuccess', true);
    store.commit('setError', false);
    return response
}

axios.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)
    
export default axios;