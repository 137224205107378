import Api from "@/js/api/Api";
import ArkiBaseService from "./ArkiBaseService";

export default class Pai extends ArkiBaseService {
    static endpoint = "pai";

    static find(id) {
        return Api.get(`${this.endpoint}/${id}`);
    }

    static async latestByPatient(patientId) {
        return Api.get(`${this.endpoint}/patients/${patientId}/latest`).then(
            ({ data }) => data
        );
    }

    static tests(id) {
        return Api.get(`${this.endpoint}/${id}/tests`);
    }

    static aree(id) {
        return Api.get(`${this.endpoint}/${id}/aree`);
    }

    static export(id, patient) {
        return axios({
            url: `${this.endpoint}/${id}/export/${patient}`,
            method: "GET",
            responseType: "blob"
        });
    }

    static deadline() {
        return Api.get(`${this.endpoint}/deadline`);
    }

    static upload(id, params) {
        return Api.post(`${this.endpoint}/${id}/upload`, params);
    }

    static isConfirmed(id) {
        return Api.get(`${this.endpoint}/${id}/isconfirmed`);
    }

    static download(id) {
        return axios({
            url: `pai/${id}/download`,
            method: "GET",
            responseType: "blob"
        });
    }

    static storeDraft(payload) {
        return Api.post("paidraft", payload);
    }

    static getDraft(id) {
        return Api.get(`paidraft/${id}`);
    }

    static updateDraft(id, payload) {
        return Api.post(`paidraft/${id}`, payload);
    }

    static deleteDraft(id) {
        return Api.delete(`paidraft/${id}`);
    }

    static confirmDraft(id, payload) {
        return Api.post(`paidraft/${id}/confirm`, payload);
    }

    static findDraft(patientId) {
        return Api.get(`paidraft/patient/${patientId}`).then(
            ({ data }) => data
        );
    }
}
