<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_vas)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_vas.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_vas.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button class="float-right" round :disabled="disableNext" @click="show(test_next_vas)">
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_vas" :test_model="test_model_vas" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-row>
            <el-col :span="24">
              <el-image style="width: 100%" :src="image_url"></el-image>
            </el-col>
            <el-form-item prop="q1_result" class="test_vas">
              <el-col :span="4">
                <el-radio v-model="test_vas.q1_result" :label="0">0</el-radio>
                <br />Nessun dolore
              </el-col>
              <el-col :span="4">
                <el-radio v-model="test_vas.q1_result" :label="1">1</el-radio>
                <br />Dolore lieve
              </el-col>
              <el-col :span="4">
                <el-radio v-model="test_vas.q1_result" :label="2">2</el-radio>
                <br />Dolore moderato
              </el-col>
              <el-col :span="4">
                <el-radio v-model="test_vas.q1_result" :label="3">3</el-radio>
                <br />Dolore forte
              </el-col>
              <el-col :span="4">
                <el-radio v-model="test_vas.q1_result" :label="4">4</el-radio>
                <br />Dolore molto forte
              </el-col>
              <el-col :span="4">
                <el-radio v-model="test_vas.q1_result" :label="5">5</el-radio>
                <br />Il più forte dolore immaginabile
              </el-col>
              <!-- </el-radio-group> -->
            </el-form-item>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_vas.total_string}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Vas",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_vas: this.test,
      test_prev_vas: this.test_prev,
      test_next_vas: this.test_next,
      test_name_vas: this.test_name,
      test_model_vas: this.test_model,
      test_type_vas: this.test_type,
      patient_id_vas: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
      image_url: "/images/test_vas.png"
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_vas,
        test_model: this.test_model_vas,
        test_id: test_id,
        type_id: this.test_type_vas
      };

      const response = await Test.show(params);
      this.test_vas = response.data.test;
      this.test_name_vas = response.data.test_name;
      this.test_prev_vas = response.data.test_prev;
      this.test_next_vas = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_vas === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_vas === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
<style lang="scss" scoped>
.test_vas .el-col {
  text-align: center;
  line-height: 20px;
}
</style>
