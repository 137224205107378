<script>
import KeyValueRow from './KeyValueRow.vue';
import _ from 'lodash';

export default {
  name: 'CdiData',
  props: ['data', 'config', 'patient'],
  components: { KeyValueRow },
  methods: {},
  computed: {
    residenzaPresaInCarico() {
      return _.get(this.data, 'clean_com_resid_carico.comune', '');
    },
    domicilioPresaInCarico() {
      return _.get(this.data, 'clean_com_domicilio_carico.comune', '');
    },
    trattamento() {
      if (!this.data) return '';
      return `${this.data.anno_ricovero}${String(this.data.num_pratica).padStart(6, '0')}`;
    },
    cittadinanza() {
      const value = _.get(this.data, 'clean_cittadinanza.stato');
      return value || '';
    },
    residenza() {
      const fields = ['ind_resid', 'civico_resid', 'clean_com_resid'];
      let [ind, civ, com] = fields.map((x) => _.get(this.data, x) || '');
      if (com) com = com.comune;
      return `${ind}${ind && ','} ${civ} ${civ && '-'} ${com}`;
    },
    domicilio() {
      const fields = ['ind_domicilio', 'civico_domicilio', 'clean_com_domicilio'];
      let [ind, civ, com] = fields.map((x) => _.get(this.data, x) || '');
      if (com) com = com.comune;
      return `${ind}${ind && ','} ${civ} ${civ && '-'} ${com}`;
    }
  }
};
</script>


<template>
  <div>
    <el-row :gutter="5">
      <el-col :span="12">
        <key-value-row label="Nome" :value="patient.name" />
        <key-value-row label="Cognome" :value="patient.surname" />
        <key-value-row label="Sesso" :value="patient.gender" />
        <key-value-row label="Data di nascita" :value="patient.birthday | moment('DD/MM/YYYY')" />
        <key-value-row label="Luogo di nascita" :value="patient.birthplace" />
        <key-value-row label="Cittadinanza" :value="cittadinanza" />
        <key-value-row label="Residenza" :value="residenza" />
        <key-value-row label="Domicilio" :value="domicilio" />
      </el-col>
      <el-col :span="12">
        <key-value-row label="ATS di appartenenza" :value="_.get(data, 'clean_cod_ats_resid.desc_asl')" />
        <key-value-row label="Numero pratica" :value="trattamento" />
        <key-value-row label="Codice ATS/ASST" :value="_.get(data, 'clean_cod_ats.desc_asl')" />
        <key-value-row label="Codice Struttura" :value="data.cod_struttura" />
        <key-value-row label="Codice individuale" :value="data.cod_individuale" />
        <key-value-row label="Data di ammissione" :value="data.data_ammissione | moment('DD/MM/YYYY')" />
      </el-col>
    </el-row>

    <el-row :gutter="5">
      <el-divider content-position="right">Ammissione</el-divider>
      <el-col :span="12">
        <key-value-row
          label="Richiesta di inserimento"
          :value="config['richiesta_inserimento'][data.richiesta_insert]"
        />
        <key-value-row
          label="Motivazione della richiesta di inserimento"
          :value="config['motivazione_richiesta_inserimento'][data.motiv_richiesta_insert]"
        />
        <key-value-row
          label="Valutazione richiesta di inserimento"
          :value="config['valutazione_richiesta_inserimento'][data.val_richiesta_insert]"
        />
        <key-value-row label="Stato Civile" :value="config['stato_civile'][data.stato_civile]" />
        <key-value-row label="Titolo di studio" :value="config['titolo_di_studio'][data.titolo_studio]" />
        <key-value-row label="Professione" :value="config['posizione_nella_professione'][data.professione]" />
      </el-col>
      <el-col :span="12">
        <key-value-row label="Provenienza Assistito" :value="config['provenienza_assistito'][data.prov_assistito]" />
        <key-value-row label="Codice ATS di provenienza" :value="_.get(data, 'clean_cod_ats_prov.desc_asl')" />
        <key-value-row label="Comune di residenza di presa in carico" :value="residenzaPresaInCarico" />
        <key-value-row label="Comune di domicilio di presa in carico" :value="domicilioPresaInCarico" />
      </el-col>
    </el-row>

    <el-row :gutter="5">
      <el-divider content-position="right">Rette</el-divider>
      <el-col :span="12">
        <key-value-row label="Tariffa SSR" :value="data.tariffa_ssr" />
        <key-value-row label="Retta utente applicata" :value="data.retta_utente" />
        <key-value-row label="Retta a carico del Comune" :value="data.retta_comune" />
      </el-col>
      <el-col :span="12">
        <key-value-row label="Retta a carico di altri Enti" :value="data.retta_enti" />
        <key-value-row label="Tipologia altri enti" :value="data.tipo_enti" />
      </el-col>
    </el-row>
  </div>
</template>

<style lang="scss" scoped>
.label-right {
  text-align: right;
}
</style>
