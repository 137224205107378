<template>
    <div class="block no-filters" v-loading="loading">
        <el-table
            :data="patients"
            style="max-width: 100%"
            height="100%"
            >
            <el-table-column
                label="Ospite"
                align="left"
                prop="full_name"
                sortable
                width="230"
                fixed
                >
                <template slot-scope="scope">
                    <font-awesome-icon class="warning-text" icon="exclamation-triangle" v-if="scope.row.critical.length"/>
                    <font-awesome-icon class="success-text" icon="toilet" v-if="scope.row.has_purge_critical"/>
                    {{scope.row.full_name}}
                </template>
            </el-table-column>
            <el-table-column
                label="Tipologia"
                align="center"
                prop="mobilizzazioni"
                width="400"
                sortable
                >
                <template slot-scope="scope">
                    <b v-if="config.mobilizzazione && scope.row.registry.mobilizzazione !== null">{{config.mobilizzazione[scope.row.registry.mobilizzazione]}}</b>
                </template>
            </el-table-column>
            <el-table-column
                label="Note"
                align="center"
                prop="mobilizzazioni"
                >
                <template slot-scope="scope">
                    <p>{{scope.row.registry.note_mobilita}}</p>
                </template>
            </el-table-column>

        </el-table>
    </div>
</template>
<script>
export default {
    name: 'Mobilizzazioni',
    data(){
        return{
            loading: false,
        }
    },
    computed:{
        patients(){ return this.$store.state.patients },
        config(){ return this.$store.state.config.anagrafica }
    }
}
</script>
