<template>
  <div>
    <patient-activities :filterPatient="true"></patient-activities>
  </div>
</template>
<script>
import moment from "moment";
import PatientActivities from "../../components/Activity/PatientActivities";

export default {
  name: "Activities",
  components: { PatientActivities },
  data() {
    return {
      loading: true,
    };
  },
  computed: {},
  methods: {
    async fetch(filters) {},
    async load() {},
  },
};
</script>
