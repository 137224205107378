import _ from 'lodash';

const rules = (numberOfQuestions) => {
  let defaultRule = { required: true, message: "Campo Obbligatorio", trigger: "change" };
  return _.range(numberOfQuestions)
    .reduce((acc, next, idx) => {
      idx++;
      acc[`q${idx}_result`] = defaultRule;
      return acc;
    }, {})
}

const filterResults = (data, specific) => {
  if (specific) {
    return _.pickBy(data, (_, key) => specific.includes(key));
  }
  return _.pickBy(data, (_, key) => key.endsWith("result"));
}


const categories = [
  {
    name: '1. Medicazioni',
    result: 'q1_result',
    options: [
      {
        label: 'Non necessarie',
        points: 10
      },
      {
        label: 'Per ulcerazioni e distrofie cutanee',
        points: 30
      },
      {
        label: 'Per catetere a permanenza o stomia',
        points: 40
      },
      {
        label: 'Per ulcere da decubito',
        points: 100
      },
    ]
  },
  {
    name: '2. Necessitá di prestazioni sanitarie',
    result: 'q2_result',
    options: [
      {
        label: 'Buona salute',
        points: 10
      },
      {
        label: 'Necessitá di prestazioni sanitarie',
        info: 'Non continuative',
        points: 20
      },
      {
        label: 'Necessitá di prestazioni sanitarie esterne',
        info: 'Terapia radiante, consulenza specialistica',
        points: 40
      },
      {
        label: 'Necessitá di prestazioni sanitarie continuative',
        points: 100
      },
    ]
  },
  {
    name: '3. Controllo sfinterico',
    result: 'q3_result',
    options: [
      {
        label: 'Presente',
        points: 10
      },
      {
        label: 'Incontinenza urinaria o fecale episodica',
        points: 30
      },
      {
        label: 'Incontinenza urinaria o fecale permanente',
        points: 70
      },
      {
        label: 'Incontinenza totale',
        info: 'Vescicale ed anale',
        points: 100
      },
    ]
  },
  {
    name: '4. Disturbi cognitivi e/o comportamentali',
    result: 'q4_result',
    options: [
      {
        label: 'Assenti',
        points: 10
      },
      {
        label: `Disturbi dell'umore`,
        info: 'Aspetto triste, malinconico, ansioso, chiede sempre nuovi farmaci',
        points: 50
      },
      {
        label: 'Disturbi congitivi',
        info: 'Non riesce a gestire i propri compiti e le proprie necessitá, ma NON presenta gravi e costanti BPSD disturbi comportamentali',
        points: 80
      },
      {
        label: 'Frequente/Costante comportamento disturbante e/o atteggiamento aggressivo e/o violento',
        info: 'Agitato, irrequieto, pericoloso per sé e per gli altri',
        points: 100
      },
    ]
  },
  {
    name: '5. Funzioni del linguaggio e della comunicazione',
    result: 'q5_result',
    options: [
      {
        label: 'Comprende e si esprime normalmente',
        points: 10
      },
      {
        label: 'Linguaggio menomato ma esprime comunque il suo pensiero',
        points: 30
      },
      {
        label: 'Comprende solo ordini semplici, non riesce ad esprimere con coerenza il proprio pensiero',
        points: 70
      },
      {
        label: 'Non comprende, non si esprime',
        points: 100
      },
    ]
  },
  {
    name: '6. Deficit sensoriali (si consideri prevalentemente udito e vista)',
    result: 'q6_result',
    options: [
      {
        label: 'Normale',
        points: 10
      },
      {
        label: `Deficit correggibile con l'aiuto di protesi e/o occhiali`,
        points: 20
      },
      {
        label: 'Grave deficit non correggibile',
        points: 80
      },
      {
        label: 'Cecitá e/o sorditá completa',
        points: 100
      },
    ]
  },
  {
    name: '7. Mobilitá (libertà di movimento)',
    result: 'q7_result',
    options: [
      {
        label: 'Cammina autonomamente senza aiuto',
        points: 10
      },
      {
        label: 'Cammina con ausili e con aiuto programmato',
        info: 'Oppure saltuario',
        points: 30
      },
      {
        label: 'Si sposta solo con aiuto di altre persone e con sedia a ruote',
        points: 80
      },
      {
        label: 'Totalmente dipendente dagli operatori per le alzate e la mobilizzazione',
        points: 100
      },
    ]
  },
  {
    name: '8. Attivitá della vita quotidiana (igiene personale, abbigliamento, alimentazione)',
    result: 'q8_result',
    options: [
      {
        label: 'Totalmente indipendente',
        points: 10
      },
      {
        label: 'Aiuto programmato',
        info: 'Oppure saltuario',
        points: 20
      },
      {
        label: 'Aiuto quotidiano necessario ma parziale',
        points: 50
      },
      {
        label: 'Totalmente dipendente',
        points: 100
      },
    ]
  },
  {
    name: '9. Attivitá occupazionali e impiego del tempo',
    result: 'q9_result',
    options: [
      {
        label: 'Entra ed esce liberamente dalla residenza',
        points: 10
      },
      {
        label: `E' motivato a svolgere attivitá costruttive solo all'interno della residenza`,
        points: 30
      },
      {
        label: `E' in grado di svolgere attivitá all'interno della residenza con lo stimolo e la supervisione degli operatori`,
        points: 60
      },
      {
        label: 'Non svolge alcuna attività',
        points: 100
      },
    ]
  },
  {
    name: '10. Stato della rete familiare e sociale (valutazione della disponibilità relazionale, affettiva ed assistenziale della rete familiare e sociale)',
    result: 'q10_result',
    options: [
      {
        label: 'Mantiene buoni e regolari rapporti con la famiglia',
        points: 10
      },
      {
        label: 'Non ha buoni e regolari rapporti con la famiglia ma solo con parenti e amici',
        points: 30
      },
      {
        label: 'Parenti, amici o volontari sono disponibili solo al bisogno',
        info: 'Problemi sanitari, pratiche burocratiche, difficoltà economiche, disagi esistenziali',
        points: 70
      },
      {
        label: `L'anziano puó contare solo sull'aiuto istituzionale`,
        info: 'Rete formale',
        points: 100
      },
    ]
  },
]

export default { rules, filterResults, categories };
