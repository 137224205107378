<template>
    <div class="block no-filters">
        <el-row :gutter="20">
            <el-col :span="24" class="margin-top">
                <el-form label-position="top" label-width="100px" :model="form" :rules="rules" ref="form">
                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                        <el-form-item label="Cognome" prop="surname">
                            <el-input v-model="form.surname"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                        <el-form-item label="Nome" prop="name">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                        <el-form-item label="Codice Fiscale" prop="cf">
                            <el-input v-model="form.cf" maxlength="16" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                        <el-form-item
                            label="Codice Sanitario"
                            prop="codice_sanitario" >
                            <el-input
                                v-model="form.codice_sanitario"
                                maxlength="8"
                                show-word-limit>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="8" :lg="6" :xl="6">
                        <el-form-item label="Genere" prop="gender">
                            <el-radio-group v-model="form.gender">
                                <el-radio-button label="F">Donna</el-radio-button>
                                <el-radio-button label="M">Uomo</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="6" :md="8" :lg="6" :xl="6">
                        <el-form-item label="Data di Nascita" prop="birthday">
                            <el-date-picker
                                v-model="form.birthday"
                                :editable="false"
                                type="date"
                                placeholder="Selezionare Data"
                                format="dd/MM/yyyy"
                                value-format="yyyy-MM-dd"
                                default-value="1930-01-01">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                        <el-form-item label="Comune di Nascita / Stato (se estero)" prop="birthplace">
                            <el-input v-model="form.birthplace"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="6" :md="12" :lg="6" :xl="6">
                        <el-form-item label="Provincia di Nascita" prop="birthplace_provincia">
                            <el-input v-model="form.birthplace_provincia" maxlength="2" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
                        <el-form-item label="Indirizzo di Residenza" prop="residency_address">
                            <el-input v-model="form.residency_address"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                        <el-form-item label="Comune di Residenza" prop="residency">
                            <el-input v-model="form.residency"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5" :xl="5">
                        <el-form-item label="Provincia di Residenza" prop="residency_provincia">
                            <el-input v-model="form.residency_provincia" maxlength="2" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5" :xl="5"></el-col>
                    <el-col :xs="24" :sm="12" :md="12" :lg="5" :xl="5">
                        <el-form-item label="CAP di Residenza" prop="residency_cap">
                            <el-input v-model="form.residency_cap" maxlength="5" show-word-limit></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Data di Inserimento in Struttura" prop="admission_date">
                            <el-date-picker
                                v-model="form.admission_date"
                                :editable="false"
                                type="date"
                                placeholder="Selezionare Data"
                                format="dd/MM/yyyy"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="Reparto" prop="department_id">
                            <el-select v-model="form.department_id" placeholder="Seleziona Reparto">
                                <el-option
                                    v-for="item in optionDepartments"
                                    :key="item.id_pianoreparto"
                                    :label="item.desc_pianoreparto"
                                    :value="item.id_pianoreparto">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-col>
            <el-col :span="24" class="text-right">
                <el-button size="large" type="success" @click="submit()" plain :loading="loading"><font-awesome-icon icon="plus" /> Conferma Ospite</el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import Patient from '@/js/api/services/Patient';
export default {
    name:"CreatePatient",
    data(){
        return{
            form:{},
            loading: false,
            rules: {
                name: [ { required: true, message: 'Campo Obbligatorio', trigger: 'blur' } ],
                surname: [ { required: true, message: 'Campo Obbligatorio', trigger: 'blur' } ],
                cf: [ { required: true, message: 'Campo Obbligatorio', trigger: 'blur' } ],
                codice_sanitario: [ { required: this.$store.state.isLombardia, message: 'Campo Obbligatorio', trigger: 'blur' } ],
                gender: [ { required: true, message: 'Campo Obbligatorio', trigger: 'blur' } ],
                birthday: [ { required: true, message: 'Campo Obbligatorio', trigger: 'blur' } ],
                birthplace: [ { required: true, message: 'Campo Obbligatorio', trigger: 'blur' } ],
                birthplace_provincia: [ { required: true, message: 'Campo Obbligatorio', trigger: 'blur' } ],
                birthplace_cap: [ { required: true, message: 'Campo Obbligatorio', trigger: 'blur' } ],
                admission_date: [ { required: true, message: 'Campo Obbligatorio', trigger: 'blur' } ],
                department_id: [ { required: true, message: 'Campo Obbligatorio', trigger: 'blur' } ],
            },
        }
    },
    computed:{
        optionDepartments() { return this.$store.state.departmentList }
    },
    methods:{
        submit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.check();
                }  else {
                    return false;
                }
            })
        },
        async check(){
            try{
                await Patient.check(this.form);
                this.store();
            } catch(error){
                this.loading = false;
            }
        },
        async store(){
            try{
                await Patient.store(this.form);
                this.success();
            } catch(error){
                 this.loading = false;
            }
        },
        success(){
            this.$store.dispatch('fetchPatients');
            this.$emit('anamnesiUpdate');
            this.$router.push({path: '/settings/patients'})
            this.form = {};
            this.loading = false;
        }
    }
}
</script>
<style lang="scss" scoped>
    .el-input-number{
        width: 100%;
    }
</style>