<template>
    <el-tooltip content="Aggiorna" placement="bottom" effect="light" class="broadcast-button">
        <el-badge :value="counter" class="item" type="info">
            <el-button icon="el-icon-refresh" circle @click="update"></el-button>
        </el-badge>
    </el-tooltip>
</template>
<script>
export default {
    name: 'ArkiBroadcast',
    props: ['listen'],
    data(){
        return{
            counter: null
        }
    },
    methods:{
        broadcast(){
            this.$echo.channel(`arkicare-activity.${this.$store.state.departmentId}`).listen(this.listen, (e) => {
                this.counter++;
            });
        },
        reset(){
            this.counter = null;
        },
        update(){
            this.$emit('update');
            this.reset();
        }
    },
    mounted(){
        this.broadcast();
    }
}
</script>
<style lang="scss" scoped>
    .broadcast-button{
        background: transparent!important;
        z-index: 2;
        position: fixed;
        right: 68px;
        top: 75px;
        color: white;
    }
</style>