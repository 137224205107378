import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import Auth from "@/js/api/services/Auth";
import Patient from "@/js/api/services/Patient";
import User from "@/js/api/services/User";
import draft from './draft';

export default new Vuex.Store({
    state: {
        // AUTH STATES
        auth: false,
        onWorkShift: true,
        user: null,
        roles: null,
        professions: null,

        //REQUESTS ERROR
        error: false,
        success: true,
        response: {},

        //STRUCTURE AND DEPARTMENT STATES
        departmentId: null,
        department: null,
        structureId: null,
        structure: null,
        departmentList: null,
        isLombardia: false,
        config: [],

        //PATIENTS STATES
        patients: [],
        patientName: '',
    },
    mutations: {
        //AUTH MUTATIONS
        setAuth(state, auth) { state.auth = auth; },
        setOnWorkShift(state, onWorkShift) { state.onWorkShift = onWorkShift; },
        setUser(state, user) { state.user = user; },

        setError(state, error) { state.error = error },
        setSuccess(state, success) { state.success = success },
        setResponse(state, response) { state.response = response },

        //STRUCTURE ADN DEPARTMENT MUTATIONS
        setDepartmentId(state, id) { state.departmentId = id; },
        setStructureId(state, id) { state.structureId = id; },
        setDepartment(state, department) { state.department = department; },
        setStructure(state, structure) { state.structure = structure; },
        setDepartmentList(state, departmentList) { state.departmentList = departmentList; },
        setIsLombardia(state, isLombardia) { state.isLombardia = isLombardia; },
        setRoles(state, roles) { state.roles = roles; },
        setProfessions(state, professions) { state.professions = professions; },
        setConfig(state, config) { state.config = config },

        //PATIENTS MUTATIONS
        setPatients(state, patients) { state.patients = patients; },
        setPatientName(state, patientName) { state.patientName = patientName; },
        updatePatientRegistry(state, registry) {
            const patients = state.patients || [];
            _.set(_.find(patients, { id: registry.patient_id }), 'registry', registry);
            state.patients = patients;
        }
    },
    actions: {
        async init() {
            const response = await Auth.getDepartment();
            this.commit("setDepartment", response.data.department);
            this.commit("setDepartmentId", response.data.department_id);
            this.commit("setStructure", response.data.structure);
            this.commit("setStructureId", response.data.structure_id);
            this.commit("setDepartmentList", response.data.department_list);
            this.commit("setIsLombardia", response.data.isLombardia);
            this.commit("setConfig", response.data.config);
            await this.dispatch('fetchRoles');
            await this.dispatch('fetchProfessions');
            await this.dispatch('fetchPatients');

        },

        async fetchUser() {
            const response = await Auth.getUser();
            this.commit('setUser', response.data.data);
        },

        async fetchRoles() {
            const response = await User.config();
            this.commit('setRoles', response.data);
        },

        async fetchProfessions() {
            const response = await User.professions();
            this.commit('setProfessions', response.data);
        },

        async fetchPatients() {
            const response = await Patient.list();
            this.commit("setPatients", response.data);
        },
    },

    modules: { draft },
});
