<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_guss)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_guss.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_guss.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_guss)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_guss" :test_model="test_model_guss" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-col :span="24">
            <el-divider content-position="left">Prova indiretta di deglutizione</el-divider>
          </el-col>
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item
                label="Vigilanza (Il paziente deve essere vigile per almeno 15 minuti)"
              />
            </el-col>
            <el-col :span="12">
              <el-form-item prop="test_guss.q1_result">
                <el-radio-group v-model="test_guss.q1_result">
                  <el-radio :label="1" border>Si</el-radio>
                  <el-radio :label="0" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="Tossire/o schiarire la voce (tosse volontaria)" />
            </el-col>
            <el-col :span="12">
              <el-form-item prop="test_guss.q2_result">
                <el-radio-group v-model="test_guss.q2_result">
                  <el-radio :label="1" border>SI</el-radio>
                  <el-radio :label="0" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>

          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="Deglutizione senza difficoltà" />
            </el-col>
            <el-col :span="12">
              <el-form-item prop="test_guss.q3_result">
                <el-radio-group v-model="test_guss.q3_result">
                  <el-radio :label="1" border>SI</el-radio>
                  <el-radio :label="0" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="Perdita della saliva" />
            </el-col>
            <el-col :span="12">
              <el-form-item prop="test_guss.q4_result">
                <el-radio-group v-model="test_guss.q4_result">
                  <el-radio :label="0" border>SI</el-radio>
                  <el-radio :label="1" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item label="Modifica della voce (rauca, gorgogliante, velata, debole)" />
            </el-col>
            <el-col :span="12">
              <el-form-item prop="test_guss.q5_result">
                <el-radio-group v-model="test_guss.q5_result">
                  <el-radio :label="0" border>SI</el-radio>
                  <el-radio :label="1" border>NO</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-col>

          <!-- CREMOSO -->

          <el-row :gutter="20" v-if="creamy">
            <el-col :span="24">
              <el-divider content-position="left">Prova diretta di deglutizione</el-divider>
            </el-col>
            <el-col :span="24">
              <el-divider content-position="center">CREMOSO</el-divider>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-form-item label="DEGLUTIZIONE" />
              </el-col>

              <el-col :span="24">
                <el-form-item>
                  <el-radio-group v-model="test_guss.q6_result">
                    <el-col :span="24">
                      <el-col :span="12">
                        <el-form-item label="Deglutizione non possibile" />
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-radio :label="0" border>Non possibile</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>

                    <el-col :span="24">
                      <el-col :span="12">
                        <el-form-item label="Deglutizione ritardata ( > 2 sec )" />
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-radio :label="1" border>Ritardata</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>

                    <el-col :span="24">
                      <el-col :span="12">
                        <el-form-item label="Deglutizione avvenuta correttamente" />
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-radio :label="2" border>Corretta</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="12">
                <el-form-item
                  label="TOSSE (involontaria): prima, durante o dopo la deglutizione – fino a 3 minuti dopo"
                />
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-radio-group v-model="test_guss.q7_result">
                    <el-radio :label="0" border>SI</el-radio>
                    <el-radio :label="1" border>NO</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="SCIALORREA" />
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-radio-group v-model="test_guss.q8_result">
                    <el-radio :label="0" border>SI</el-radio>
                    <el-radio :label="1" border>NO</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="12">
                <el-form-item
                  label="MODIFICA DELLA VOCE: (ascoltare la voce prima e dopo la deglutizione – il paziente deve pronunciare 'O')"
                />
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-radio-group v-model="test_guss.q9_result">
                    <el-radio :label="0" border>SI</el-radio>
                    <el-radio :label="1" border>NO</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-col>
          </el-row>

          <!-- LIQUIDO -->

          <el-row :gutter="20" v-if="liquid">
            <el-col :span="24">
              <el-divider content-position="center">LIQUIDO</el-divider>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-form-item label="DEGLUTIZIONE" />
              </el-col>

              <el-col :span="24">
                <el-form-item>
                  <el-radio-group v-model="test_guss.q10_result">
                    <el-col :span="24">
                      <el-col :span="12">
                        <el-form-item label="Deglutizione non possibile" />
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-radio :label="0" border>Non possibile</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>

                    <el-col :span="24">
                      <el-col :span="12">
                        <el-form-item label="Deglutizione ritardata ( > 2 sec )" />
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-radio :label="1" border>Ritardata</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>

                    <el-col :span="24">
                      <el-col :span="12">
                        <el-form-item label="Deglutizione avvenuta correttamente" />
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-radio :label="2" border>Corretta</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="12">
                <el-form-item
                  label="TOSSE (involontaria): prima, durante o dopo la deglutizione – fino a 3 minuti dopo"
                />
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-radio-group v-model="test_guss.q11_result">
                    <el-radio :label="0" border>SI</el-radio>
                    <el-radio :label="1" border>NO</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="SCIALORREA" />
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-radio-group v-model="test_guss.q12_result">
                    <el-radio :label="0" border>SI</el-radio>
                    <el-radio :label="1" border>NO</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="12">
                <el-form-item
                  label="MODIFICA DELLA VOCE: (ascoltare la voce prima e dopo la deglutizione – il paziente deve pronunciare 'O')"
                />
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-radio-group v-model="test_guss.q13_result">
                    <el-radio :label="0" border>SI</el-radio>
                    <el-radio :label="1" border>NO</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-col>
          </el-row>

          <!-- SOLIDO -->
          <el-row :gutter="20" v-if="solid">
            <el-col :span="24">
              <el-divider content-position="center">SOLIDO</el-divider>
            </el-col>

            <el-col :span="24">
              <el-col :span="24">
                <el-form-item label="DEGLUTIZIONE" />
              </el-col>

              <el-col :span="24">
                <el-form-item>
                  <el-radio-group v-model="test_guss.q14_result">
                    <el-col :span="24">
                      <el-col :span="12">
                        <el-form-item label="Deglutizione non possibile" />
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-radio :label="0" border>Non possibile</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>

                    <el-col :span="24">
                      <el-col :span="12">
                        <el-form-item label="Deglutizione ritardata ( > 10 sec )" />
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-radio :label="1" border>Ritardata</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>

                    <el-col :span="24">
                      <el-col :span="12">
                        <el-form-item label="Deglutizione avvenuta correttamente" />
                      </el-col>
                      <el-col :span="12">
                        <el-form-item>
                          <el-radio :label="2" border>Corretta</el-radio>
                        </el-form-item>
                      </el-col>
                    </el-col>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="12">
                <el-form-item
                  label="TOSSE (involontaria): prima, durante o dopo la deglutizione – fino a 3 minuti dopo"
                />
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-radio-group v-model="test_guss.q15_result">
                    <el-radio :label="0" border>SI</el-radio>
                    <el-radio :label="1" border>NO</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="SCIALORREA" />
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-radio-group v-model="test_guss.q16_result">
                    <el-radio :label="0" border>SI</el-radio>
                    <el-radio :label="1" border>NO</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-col>

            <el-col :span="24">
              <el-col :span="12">
                <el-form-item
                  label="MODIFICA DELLA VOCE: (ascoltare la voce prima e dopo la deglutizione – il paziente deve pronunciare 'O')"
                />
              </el-col>
              <el-col :span="12">
                <el-form-item>
                  <el-radio-group v-model="test_guss.q17_result">
                    <el-radio :label="0" border>SI</el-radio>
                    <el-radio :label="1" border>NO</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-col>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider></el-divider>
            </el-col>
            <el-col :span="24">
              <el-col :span="12">
                <el-form-item label="Totale">{{test_guss.total_string}}</el-form-item>
              </el-col>
            </el-col>
            <el-col :span="24">
             <el-divider></el-divider>
                <el-form-item label="Risultato">{{test_guss.total_description}}</el-form-item>
            </el-col>
          </el-row>


        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "Guss",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_guss: this.test,
      test_prev_guss: this.test_prev,
      test_next_guss: this.test_next,
      test_name_guss: this.test_name,
      test_model_guss: this.test_model,
      test_type_guss: this.test_type,
      patient_id_guss: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false
    };
  },
  computed: {
    creamy() {
      let { total } = this.test_guss;
      return _.gt(total, 5);
    },
    liquid() {
      let { total } = this.test_guss;
      return _.gt(total, 10);
    },
    solid() {
      let { total } = this.test_guss;
      return _.gt(total, 15);
    }
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_guss,
        test_model: this.test_model_guss,
        test_id: test_id,
        type_id: this.test_type_guss
      };

      const response = await Test.show(params);
      this.test_guss = response.data.test;
      this.test_name_guss = response.data.test_name;
      this.test_prev_guss = response.data.test_prev;
      this.test_next_guss = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_guss === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_guss === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
