import Api from '@/js/api/Api';

export default class ArkiBaseService{
    
    /**
    * resource endpoint eg. /tasks
    */

    static endpoint

    /**
    * Get endopint laravel config
    */

    static config() {
        return Api.get(`config/${this.endpoint}`);
    }

    /**
    * Get endopint settings
    */

    static settings(){
        return Api.get(`${this.endpoint}/settings`);
    }
    
    /**
    *  get all records
    */

    static fetch(params){
        return Api.get(this.endpoint, {
            params: params
        }); 
    }

    /**
    *  get a specific record
    */

    static get(id){
        return Api.get(`${this.endpoint}/${id}`);
    }

    /**
    *  save a specific record
    */

    static store(params){
        return Api.post(this.endpoint , params);
    }

    /**
    *  Update a specific record
    */

    static update(id, params){
        return Api.patch(`${this.endpoint}/${id}`, params);
    }

    /**
    *  delete a specific record
    */

    static destroy(id){
        return Api.delete(`${this.endpoint}/${id}`);
    }

    static pdf(id) {
        return axios({
            url: `pdf/${this.endpoint}/${id}`,
            method: 'GET',
            responseType: 'blob',
        });
    }
}