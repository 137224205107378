import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Drop extends ArkiBaseService {

    static endpoint = 'drops';

    static download(id){
        return axios({
            url: `${this.endpoint}/${id}/download`,
            method: 'GET',
            responseType: 'blob',
        });
    }

    static toggleExportFlag(id, value) {
        return Api.post(`${this.endpoint}/${id}/toggleExport`, {value})
    }
}