<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button
            class="float-left"
            round
            :disabled="disablePrev"
            @click="show(test_prev_stratify)"
          >
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>

        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_stratify.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_stratify.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_stratify)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>

      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable
          :test="test_stratify"
          :test_model="test_model_stratify"
          @showparent="show"
        />
      </el-row>
      <br />
      <br />
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form
            label-position="top"
            label-width="80px"
            :model="test_stratify"
            ref="form"
            disabled
          >
            <!-- tab 1 -->
            <el-row :gutter="20" style="padding: 20px 20px 0">
              <el-col :span="24">
                <el-row :gutter="20">
                  <!-- 1 -->
                  <el-col :span="24">
                    <el-row type="flex" justify="space-between" align="middle">
                      <el-col :span="16"
                        >1. Il paziente è stato inserito in seguito ad una
                        caduta, oppure è caduto durante la degenza? (esame della
                        documentazione)</el-col
                      >
                      <el-col :span="8">
                        <el-form-item prop="q1_result">
                          <el-radio-group
                            v-model="test_stratify.q1_result"
                            style="display: flex; justify-content: center"
                          >
                            <el-radio :label="1" border class="no-margin"
                              >Si</el-radio
                            >
                            <el-radio :label="0" border class="no-margin"
                              >No</el-radio
                            >
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                  <!-- BREAK Submit -->
                  <el-col :span="24">
                    <el-divider>Ritieni che l'ospite:</el-divider>
                  </el-col>
                  <!-- 2 -->
                  <el-col :span="24">
                    <el-row type="flex" justify="space-between" align="middle">
                      <el-col :span="16"
                        >2. Sia agitato? (definizione: eccessiva attività
                        motoria, solitamente non finalizzata ed associato ad
                        agitazione interiore. Es: incapacità a stare seduto
                        fermo, si muove con irrequietezza, si tira i vestiti,
                        ecc.)</el-col
                      >
                      <el-col :span="8">
                        <el-form-item prop="q2_result">
                          <el-radio-group
                            v-model="test_stratify.q2_result"
                            style="display: flex; justify-content: center"
                          >
                            <el-radio :label="1" border class="no-margin"
                              >Si</el-radio
                            >
                            <el-radio :label="0" border class="no-margin"
                              >No</el-radio
                            >
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                  <!-- 3 -->
                  <el-col :span="24">
                    <el-row type="flex" justify="space-between" align="middle">
                      <el-col :span="16"
                        >3. Abbia un calo della vista tale da compromettere
                        tutte le altre funzioni quotidiane?</el-col
                      >
                      <el-col :span="8">
                        <el-form-item prop="q3_result">
                          <el-radio-group
                            v-model="test_stratify.q3_result"
                            style="display: flex; justify-content: center"
                          >
                            <el-radio :label="1" border class="no-margin"
                              >Si</el-radio
                            >
                            <el-radio :label="0" border class="no-margin"
                              >No</el-radio
                            >
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                  <!-- 4 -->
                  <el-col :span="24">
                    <el-row type="flex" justify="space-between" align="middle">
                      <el-col :span="16"
                        >4. Necessiti di andare in bagnocon paritcolare
                        frequenza? ({{ "<3ore" }})
                      </el-col>
                      <el-col :span="8">
                        <el-form-item prop="q4_result">
                          <el-radio-group
                            v-model="test_stratify.q4_result"
                            style="display: flex; justify-content: center"
                          >
                            <el-radio :label="1" border class="no-margin"
                              >Si</el-radio
                            >
                            <el-radio :label="0" border class="no-margin"
                              >No</el-radio
                            >
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                  <!-- 5 -->
                  <el-col :span="24">
                    <el-row type="flex" justify="space-between" align="middle">
                      <el-col :span="16"
                        >5. L'ospite ha un punteggio di mobilità corrispondente
                        a 3 o a 4? (vedi schema di calcolo sottostante)</el-col
                      >
                      <el-col :span="8">
                        <el-form-item prop="q5_result">
                          <el-radio-group
                            v-model="test_stratify.q5_result"
                            style="display: flex; justify-content: center"
                          >
                            <el-radio
                              disabled
                              :label="1"
                              border
                              class="no-margin"
                              >Si</el-radio
                            >
                            <el-radio
                              disabled
                              :label="0"
                              border
                              class="no-margin"
                              >No</el-radio
                            >
                          </el-radio-group>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="bottom-space">
                  <!-- BREAK Submit -->
                  <el-col :span="24">
                    <el-divider></el-divider>
                  </el-col>
                  <!-- submit -->
                  <el-col :span="24">
                    <el-col :span="12">
                      <el-form-item label="Totale">{{
                        test_stratify.total_string
                      }}</el-form-item>
                    </el-col>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
            <!-- tab 2 -->
            <el-col :span="24">
              <el-divider
                >Schema per il calcolo del punteggio di mobilità</el-divider
              >
            </el-col>
            <el-row :gutter="20" style="padding: 0 20px">
              <!-- intestazione -->
              <el-col :span="24" class="border-bottom">
                <el-row
                  :span="24"
                  type="flex"
                  justify="space-around"
                  align="middle"
                  style="font-weight: 800"
                >
                  <el-col :span="8">
                    <el-col> Il paziente è ingrado di: </el-col>
                  </el-col>
                  <el-col :span="4">
                    <el-col>
                      <center>NO</center>
                    </el-col>
                  </el-col>
                  <el-col :span="4">
                    <el-col>
                      <center>Con aiuto maggiore</center>
                    </el-col>
                  </el-col>
                  <el-col :span="4">
                    <el-col>
                      <center>Con aiuto minore</center>
                    </el-col>
                  </el-col>
                  <el-col :span="4">
                    <el-col>
                      <center>Indipendente</center>
                    </el-col>
                  </el-col>
                </el-row>
              </el-col>
              <!-- 1 -->
              <el-col :span="24" class="border-bottom">
                <el-row :span="24" type="flex" justify="center" align="middle">
                  <el-col :span="8">
                    Spostarsi dalla sedia al letto e ritornare (include il
                    sedersi sul letto)
                  </el-col>
                  <el-col :span="4" v-for="el in 4" :key="el">
                    <el-col>
                      <center>
                        <el-radio
                          v-model="test_stratify.m1_result"
                          :label="el - 1"
                        ></el-radio>
                      </center>
                    </el-col>
                  </el-col>
                </el-row>
              </el-col>
              <!-- 2 -->
              <el-col :span="24" class="border-bottom">
                <el-row :span="24" type="flex" justify="center" align="middle">
                  <el-col :span="8">
                    Camminare sul piano (spingere la sedia a rotelle se non
                    deambula)
                  </el-col>
                  <el-col :span="4" v-for="el in 4" :key="el">
                    <el-col>
                      <center>
                        <el-radio
                          v-model="test_stratify.m2_result"
                          :label="el - 1"
                        ></el-radio>
                      </center>
                    </el-col>
                  </el-col>
                </el-row>
              </el-col>
              <!-- totale mobilità -->
              <el-col :span="24" class="border-bottom">
                <el-row :span="24" type="flex" justify="end" align="middle">
                  <el-col
                    :span="24"
                    style="
                      display: flex;
                      justify-content: end;
                      align-items: center;
                      font-weight: 800;
                    "
                  >
                    TOTALE MOBILITA'

                    <el-form-item>{{
                      test_stratify.total_mobility_string
                    }}</el-form-item>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "stratify",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type",
  ],
  data() {
    return {
      test_stratify: this.test,
      test_prev_stratify: this.test_prev,
      test_next_stratify: this.test_next,
      test_name_stratify: this.test_name,
      test_model_stratify: this.test_model,
      test_type_stratify: this.test_type,
      patient_id_stratify: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_stratify,
        test_model: this.test_model_stratify,
        test_id: test_id,
        type_id: this.test_type_stratify,
      };

      const response = await Test.show(params);
      this.test_stratify = response.data.test;
      this.test_name_stratify = response.data.test_name;
      this.test_prev_stratify = response.data.test_prev;
      this.test_next_stratify = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_stratify === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_stratify === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    },
  },
  beforeMount() {
    this.checkPrevNext();
  },
};
</script>

<style scoped>
.border-bottom {
  padding: 5px 0;
  border-bottom: 1px solid #dcdfe6;
}
.bottom-space {
  margin-bottom: 10px;
}

.no-margin {
  margin: 0;
}
</style>
