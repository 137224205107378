<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q1_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Alimentazione" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q1_result">
                      <el-form-item>
                        <el-radio :label="0">deve essere alimentato</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="5">ha bisogno di aiuto</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="10">indipendente</el-radio>
                      </el-form-item>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q2_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Bagno" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q2_result">
                      <el-form-item>
                        <el-radio :label="0">possibile solo se aiutato</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="5">possibile senza aiuto</el-radio>
                      </el-form-item>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q3_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Abbigliamento" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q3_result">
                      <el-form-item>
                        <el-radio :label="0">completamente dipendente</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="5">solo con aiuto</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="10">indipendente</el-radio>
                      </el-form-item>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q4_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Controllo intestino" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q4_result">
                      <el-form-item>
                        <el-radio :label="0">evacuazione involontaria</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio
                          :label="5"
                        >occasionalmente evacuazione involontaria, ha bisogno di aiuto</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="10">autonomo</el-radio>
                      </el-form-item>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q5_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Controllo vescica" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q5_result">
                      <el-form-item>
                        <el-radio :label="0">incontinenza o catetere a permanenza</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio
                          :label="5"
                        >occasionalmente minzione involontaria, ha bisogno di aiuto</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="10">autonomo</el-radio>
                      </el-form-item>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q6_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Utilizzo della toilette" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q6_result">
                      <el-form-item>
                        <el-radio :label="0">allettato, completamente dipendente</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="5">richiede aiuto</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="10">autonomo</el-radio>
                      </el-form-item>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q7_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Trasferimento sedia – letto" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q7_result">
                      <el-form-item>
                        <el-radio :label="0">allettato</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="5">forte assistenza</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="10">assistenza minima</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="15">autonomo</el-radio>
                      </el-form-item>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q8_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Deambulazione in piano" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q8_result">
                      <el-form-item>
                        <el-radio
                          :label="0"
                        >non riesce a muoversi autonomamente con la sedia a rotelle</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="5">riesce a muoversi con la sedia a rotelle</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="10">può fare 50 passi solo con aiuto</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="15">può fare 50 passi anche senza aiuto</el-radio>
                      </el-form-item>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>

        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q9_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Salire le scale" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q9_result">
                      <el-form-item>
                        <el-radio :label="0">impossibile</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="5">possibile con aiuto</el-radio>
                      </el-form-item>
                      <el-form-item>
                        <el-radio :label="10">autonomo</el-radio>
                      </el-form-item>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-divider content-position="center">Reazioni psicologiche ed emotive all’ingresso</el-divider>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q10_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Accettazione" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q10_result" class="radio-group">
                      <el-row type="flex" justify="center">
                        <el-form-item>
                          <el-radio :label="0">si</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="5">no</el-radio>
                        </el-form-item>
                      </el-row>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q11_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Sopportazione" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q11_result" class="radio-group">
                      <el-row type="flex" justify="center">
                        <el-form-item>
                          <el-radio :label="0">si</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="5">no</el-radio>
                        </el-form-item>
                      </el-row>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q12_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Indifferenza" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q12_result" class="radio-group">
                      <el-row type="flex" justify="center">
                        <el-form-item>
                          <el-radio :label="0">si</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="5">no</el-radio>
                        </el-form-item>
                      </el-row>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-divider content-position="center">Problemi associati all’ingresso</el-divider>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q13_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Vigilanza" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q13_result" class="radio-group">
                      <el-row type="flex" justify="center">
                        <el-form-item>
                          <el-radio :label="1">si</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="0">no</el-radio>
                        </el-form-item>
                      </el-row>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q14_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Attenzione" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q14_result" class="radio-group">
                      <el-row type="flex" justify="center">
                        <el-form-item>
                          <el-radio :label="1">si</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="0">no</el-radio>
                        </el-form-item>
                      </el-row>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q15_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Memoria BT" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q15_result" class="radio-group">
                      <el-row type="flex" justify="center">
                        <el-form-item>
                          <el-radio :label="1">si</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="0">no</el-radio>
                        </el-form-item>
                      </el-row>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q16_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Memoria LT" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q16_result" class="radio-group">
                      <el-row type="flex" justify="center">
                        <el-form-item>
                          <el-radio :label="1">si</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="0">no</el-radio>
                        </el-form-item>
                      </el-row>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q17_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Comprensione" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q17_result" class="radio-group">
                      <el-row type="flex" justify="center">
                        <el-form-item>
                          <el-radio :label="1">si</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="0">no</el-radio>
                        </el-form-item>
                      </el-row>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q18_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Aprassia" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q18_result" class="radio-group">
                      <el-row type="flex" justify="center">
                        <el-form-item>
                          <el-radio :label="1">si</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="0">no</el-radio>
                        </el-form-item>
                      </el-row>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q19_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Anosognosia" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q19_result" class="radio-group">
                      <el-row type="flex" justify="center">
                        <el-form-item>
                          <el-radio :label="1">si</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="0">no</el-radio>
                        </el-form-item>
                      </el-row>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q20_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Linguaggio" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q20_result" class="radio-group">
                      <el-row type="flex" justify="center">
                        <el-form-item>
                          <el-radio :label="1">si</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="0">no</el-radio>
                        </el-form-item>
                      </el-row>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-form-item prop="q21_orientation_result">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="Orientamento T/S/P" required />
                  </el-col>
                  <el-col :span="16">
                    <el-radio-group v-model="result.q21_orientation_result" class="radio-group">
                      <el-row type="flex" justify="center">
                        <el-form-item>
                          <el-radio :label="0">T</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="1">S</el-radio>
                        </el-form-item>
                        <el-form-item>
                          <el-radio :label="2">P</el-radio>
                        </el-form-item>
                      </el-row>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="Umore" required />
                </el-col>
                <el-col :span="16">
                  <el-form-item prop="q22_mood">
                    <el-input v-model="result.q22_mood" type="textarea" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider />
            </el-col>
          </el-col>
        </el-col>
        <el-col :span="24">
          <el-col :span="24">
            <el-col :span="24">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="Altro" required />
                </el-col>
                <el-col :span="16">
                  <el-form-item prop="q23_other">
                    <el-input v-model="result.q23_other" type="textarea" />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-col>
        </el-col>
      </el-row>
      <el-divider />
      <el-row :gutter="20" type="flex" justify="center" class="bottom-space">
        <el-col :span="8">
          <el-form-item label="Totale">
            <el-input v-model="form.total" :disabled="true">
              <template slot="append">/ {{form.total_quest}}</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import { rules, filterResults, createResults, totalOf } from "../constants";
import TestActions from "../components/TestActions";

const custom = ["q21_orientation_result", "q22_mood", "q23_other"];

export default {
  name: "Adl",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 108
      },
      result: {},
      testRules: rules(20, custom)
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$emit("submitTest", this.form, this.result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    resetForm() {
      let keys = _.flatten([createResults(1, 21), custom]);
      this.$refs["form"].resetFields();
      keys.map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(
          filterResults(this.result, null, ["q22_mood", "q23_other"])
        );
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.radio-label {
  white-space: normal;
}
.radio-label_text {
  margin-left: 20px;
}
.partial {
  font-weight: bold;
  margin-left: 10px;
}
.radio-group {
  width: 100%;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>