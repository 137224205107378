<template>
  <div>
    <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Export per Ospedalizzazione</md-dialog-title>
      <el-form label-position="top" ref="form">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item
              label="Informazioni Aggiuntive / Motivo del ricovero"
              prop="exportDescription"
            >
              <el-input
                type="textarea"
                :rows="2"
                v-model="exportDescription"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <md-dialog-actions>
        <el-button
          type="info"
          plain
          @click="showDialog = false"
          :disabled="downloadLoading"
          style="margin-right: 5px"
          >Annulla</el-button
        >
        <el-button
          type="success"
          plain
          @click="submitExport()"
          :loading="downloadLoading"
          >Esporta</el-button
        >
      </md-dialog-actions>
    </md-dialog>

    <md-dialog :md-active.sync="showCheckCategories">
      <md-dialog-title>Export per Categoria</md-dialog-title>
      <md-dialog-content>
        <el-form label-position="top" ref="form">
          <el-row :gutter="20">
            <el-col :span="12">
              <!-- Bagni -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  id="baths"
                  label="baths"
                  style="padding-top: 5px"
                >
                  Bagni</el-checkbox
                >
              </div>
              <!-- Assenze -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  id="absences"
                  label="absences"
                  style="padding-top: 5px"
                >
                  Assenze</el-checkbox
                >
              </div>
              <!-- Evacuazioni -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  id="purges"
                  label="purges"
                  style="padding-top: 5px"
                >
                  Evacuazioni</el-checkbox
                >
              </div>
              <!-- Medicazioni -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  id="medications"
                  label="medications"
                  style="padding-top: 5px"
                >
                  Medicazioni</el-checkbox
                >
              </div>
              <!-- Diario -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  id="diary"
                  label="diary"
                  style="padding-top: 5px"
                >
                  Diario</el-checkbox
                >
                <el-col
                  :span="12"
                  style="float: right"
                  v-if="exportForm.categories.includes('diary')"
                >
                  <el-select
                    v-model="exportForm.profession"
                    placeholder="Professione"
                    clearable
                    size="small"
                    @change="emit('manual')"
                  >
                    <el-option
                      v-for="professions in professionAll"
                      :key="professions.value"
                      :label="professions.label"
                      :value="professions.value"
                    >
                    </el-option>
                    <el-option
                      v-for="(item, index) in professionSelects"
                      :key="index"
                      :label="item.label"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </div>
              <!-- Fisioterapie -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  id="physiotherapies"
                  label="physiotherapies"
                  style="padding-top: 5px"
                >
                  Fisioterapie</el-checkbox
                >
              </div>
              <!-- Verifiche contenzioni -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  label="protection"
                  style="padding-top: 5px"
                >
                  Verifiche contenzioni</el-checkbox
                >
              </div>
              <!-- Consegne -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  id="tasks"
                  label="tasks"
                  style="padding-top: 5px"
                  >Consegne</el-checkbox
                >
                <el-checkbox
                  v-if="exportForm.categories.includes('tasks')"
                  style="float: right; padding-top: 7px"
                  v-model="exportForm.type4"
                  id="next"
                  label="next"
                  >Aggiungere le future</el-checkbox
                >
              </div>
              <!-- Attivitá -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  label="activities"
                  style="padding-top: 5px"
                >
                  Attivitá</el-checkbox
                >
              </div>
              <!-- Rilevamenti -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  id="exams"
                  label="exams"
                  style="padding-top: 5px"
                >
                  Rilevamenti</el-checkbox
                >
                <el-col
                  :span="12"
                  style="float: right"
                  v-if="exportForm.categories.includes('exams')"
                >
                  <el-select
                    v-model="exportForm.examsFilter"
                    multiple
                    collapse-tags
                    style="width: 180%"
                    placeholder="Tipo"
                    clearable
                    size="small"
                    @change="emit('manual')"
                  >
                    <el-option
                      v-for="(item, index) in examsType"
                      :key="index"
                      :label="item.label"
                      :value="item.id"
                      :disabled="
                        exportForm.examsFilter == 'tutti' ||
                        (!exportForm.examsFilter.includes('tutti') &&
                          exportForm.examsFilter.length > 0 &&
                          item.id === 'tutti')
                      "
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </div>
              <!-- Somministrazioni -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  id="subministrations"
                  label="subministrations"
                  style="padding-top: 5px"
                >
                  Somministrazioni</el-checkbox
                >
              </div>
              <!-- Monitoraggio Pasti  -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  label="meals"
                  style="padding-top: 5px"
                >
                  Monitoraggio Pasti</el-checkbox
                >
              </div>
            </el-col>
            <el-col :span="12">
              <div :span="24" style="display: flex; flex-direction: column">
                <el-radio v-model="exportForm.type1" id="type1" label="week"
                  >Ultima settimana</el-radio
                >
                <el-radio
                  style="margin-top: 5px"
                  v-model="exportForm.type1"
                  id="type1"
                  label="month"
                  >Ultimo mese</el-radio
                >
                <el-radio
                  style="margin-top: 5px"
                  v-model="exportForm.type1"
                  id="type1"
                  label="all"
                  >Tutto</el-radio
                >
                <el-radio
                  style="margin-top: 5px"
                  v-model="exportForm.type1"
                  id="type1"
                  label="date"
                  >Range</el-radio
                >
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 5px;
                  "
                >
                  <el-col :span="24" v-if="exportForm.type1.includes('date')">
                    <el-date-picker
                      v-model="dateRange"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="Data inizio"
                      end-placeholder="Data fine"
                      format="dd MMMM"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerOptions"
                      :editable="false"
                      :clearable="false"
                      size="small"
                      @change="dateRangeChange"
                    >
                    </el-date-picker>
                  </el-col>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row :gutter="20" style="margin-top: 15px">
            <el-col :span="12">
              <!-- Schede Valutazione -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  id="tests"
                  label="tests"
                  >Schede di Valutazione</el-checkbox
                >
              </div>
              <!-- PAI -->
              <div style="display: block">
                <el-checkbox
                  display=""
                  v-model="exportForm.categories"
                  id="pai"
                  label="pai"
                  style="padding-top: 5px"
                  >PI/PAI</el-checkbox
                >
              </div>
              <!-- Anamnesi -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  label="registry"
                  style="padding-top: 5px"
                  >Anamnesi</el-checkbox
                >
              </div>
              <!-- Contenzioni indicate -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  label="prescribed_protections"
                  style="padding-top: 5px"
                >
                  Contenzioni indicate</el-checkbox
                >
              </div>
              <!-- Terapie -->
              <div style="display: block">
                <!-- <input type="checkbox" v-model="exportForm.categories" id="therapies" value="therapies"> Terapie -->
                <el-checkbox
                  v-model="exportForm.categories"
                  label="therapies"
                  style="padding-top: 5px"
                >
                  Terapie</el-checkbox
                >
              </div>
              <div style="display: block">
                <!-- <input type="checkbox" v-model="exportForm.categories" id="therapies" value="therapies"> Terapie -->
                <el-checkbox
                  v-model="exportForm.categories"
                  label="therapy_summary"
                  style="padding-top: 5px"
                >
                  Riepilogo Terapia</el-checkbox
                >
              </div>
              <!-- Scheda infermieristica -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  label="nursing_card"
                  style="padding-top: 5px"
                >
                  Scheda Infermieristica</el-checkbox
                >
              </div>
              <!-- Scheda nutrizionale -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  label="nutritional_card"
                  style="padding-top: 5px"
                >
                  Scheda Nutrizionale</el-checkbox
                >
              </div>
            </el-col>
            <el-col :span="12">
              <div :span="24" style="display: flex; flex-direction: column">
                <el-radio v-model="exportForm.type2" id="type2" label="last"
                  >Dato più recente</el-radio
                >
                <el-radio
                  style="margin-top: 5px"
                  v-model="exportForm.type2"
                  id="type2"
                  label="all"
                  >Tutto</el-radio
                >
              </div>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <el-row :gutter="20" style="margin-top: 15px">
            <el-col :span="12">
              <!-- Allegati -->
              <div style="display: block">
                <el-checkbox
                  v-model="exportForm.categories"
                  id="files"
                  label="files"
                >
                  Allegati</el-checkbox
                >
              </div>
            </el-col>
            <el-col :span="12">
              <div :span="24" style="display: flex; flex-direction: column">
                <el-radio v-model="exportForm.type3" id="type3" label="hospital"
                  >Selezionati per export Ospedalizzazione</el-radio
                >
                <el-radio
                  style="margin-top: 5px"
                  v-model="exportForm.type3"
                  id="type3"
                  label="all"
                  >Tutti i files</el-radio
                >
              </div>
            </el-col>
          </el-row>
        </el-form>
      </md-dialog-content>
      <md-dialog-actions>
        <el-button
          type="info"
          plain
          @click="showCheckCategories = false"
          :disabled="downloadLoading"
          style="margin-right: 5px"
          >Annulla</el-button
        >
        <el-button
          type="success"
          plain
          @click="submitCategoriesExport()"
          :loading="downloadLoading"
          >Esporta</el-button
        >
      </md-dialog-actions>
    </md-dialog>
    <div class="block no-filters">
      <el-button-group class="add-button no-broadcast export-buttons" disabled>
        <el-tooltip
          class="item"
          effect="light"
          content="Export Ospedalizzazioni"
          placement="bottom"
        >
          <el-button
            :loading="downloadLoading"
            type="primary"
            class="md-primary-dark"
            @click="hospitalExport()"
            round
            icon="el-icon-document-add"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="light"
          content="Export per categoria"
          placement="bottom"
        >
          <el-button
            :loading="downloadLoading"
            class="md-primary-dark"
            type="primary"
            icon="el-icon-s-unfold"
            @click="categoriesExport()"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="light"
          content="Export Cartella Sanitaria"
          placement="bottom"
        >
          <el-button
            :loading="downloadLoading"
            type="primary"
            class="md-primary-dark"
            round
            icon="el-icon-folder-checked"
            @click="globalExport()"
          ></el-button>
        </el-tooltip>
      </el-button-group>
      <el-card class="box-card" v-loading="loading">
        <el-row>
          <el-col :span="4">
            <el-upload
              class="avatar-uploader"
              :action="`/api/patients/${patient.id}/upload`"
              ref="avatar"
              :show-file-list="false"
              :on-success="handleUpload"
              :before-upload="beforeUpload"
              :with-credentials="true"
              :headers="uploadHeaders"
            >
              <img
                v-if="patient.registry && patient.registry.image_path"
                :src="`/app/public/${patient.registry.image_path}`"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-col>
          <el-col :span="8" class="padding">
            <p>
              <b>Data Ammissione:</b>
              <span>{{ patient.clean_admission_date }}</span>
            </p>
            <p v-if="patient.clean_dismissal_date != null">
              <b>Data Dimissione:</b>
              <span>{{ patient.clean_dismissal_date }}</span>
            </p>
            <p>
              <b>Igiene:</b>
              <span v-if="this.patient.registry && this.config.igiene">{{
                this.config.igiene[this.patient.registry.igiene]
              }}</span>
            </p>
            <p>
              <b>Vestizione:</b>
              <span v-if="this.patient.registry && this.config.vestizione">{{
                this.config.vestizione[this.patient.registry.vestizione]
              }}</span>
            </p>
            <p>
              <b>Mobilizzazione:</b>
              <span
                v-if="this.patient.registry && this.config.mobilizzazione"
                >{{
                  this.config.mobilizzazione[
                    this.patient.registry.mobilizzazione
                  ]
                }}</span
              >
              <span>({{ _.get(patient, "registry.note_mobilita") }})</span>
            </p>
            <p>
              <b>Dieta:</b>
              <span v-if="this.patient.registry && this.config.dieta">{{
                this.config.dieta[this.patient.registry.dieta]
              }}</span>
            </p>
            <p>
              <b>Età:</b>
              <span>{{ patient.age }} anni</span>
            </p>
            <p v-if="_.get(this.patient, 'registry.note_rilevanti')">
              <b>Note Rilevanti:</b>
              <span>{{ _.get(this.patient, "registry.note_rilevanti") }}</span>
            </p>
          </el-col>
          <el-col :span="6">
            <p>
              <font-awesome-icon
                icon="exclamation-triangle"
                class="warning-text"
              />
              <span v-if="this.patient.critical">{{
                this.patient.critical.length
              }}</span>
              <span v-else>Nessuna</span>
              Criticità
            </p>

            <p v-if="this.patient.days_since_last_purge !== 'Mai'">
              <font-awesome-icon icon="toilet" class="success-text" />
              Evacuato {{ this.patient.days_since_last_purge }} Giorni fa
            </p>
            <p v-else>
              <font-awesome-icon icon="toilet" class="success-text" />Mai
              evacuato
            </p>

            <p>
              <font-awesome-icon icon="shield-alt" class="primary-text" />
              {{ this.patient.active_protection }}
            </p>
            <p>
              <font-awesome-icon icon="user-injured" class="danger-text" />
              {{ this.patient.active_wound }}
            </p>
            <p>
              <b>C.F.</b>
              {{ patient.cf }}
            </p>
          </el-col>
          <el-col :span="6">
            <p>
              <b>Allergie:</b>
              {{ _.get(patient, "registry.allergie") }}
            </p>
          </el-col>
          <el-col :span="6" v-if="nutrizioneArtificiale">
            <p>
              <b>Nutrizione Artificiale:</b>
              {{ nutrizioneArtificiale }}
            </p>
          </el-col>
        </el-row>
      </el-card>
      <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
        <el-tab-pane label="Cartella" name="cartella"></el-tab-pane>
        <el-tab-pane label="Anagrafica" name="anagrafica"></el-tab-pane>
        <el-tab-pane label="Anamnesi" name="anamnesi"></el-tab-pane>
        <el-tab-pane
          label="Prescrizioni"
          name="terapie"
          v-if="user.is_doctor"
        ></el-tab-pane>
        <el-tab-pane label="Files" name="files"></el-tab-pane>
        <el-tab-pane label="Contatti" name="contatti"></el-tab-pane>
        <el-tab-pane label="Schede Valutazione" name="tests"></el-tab-pane>
        <el-tab-pane label="PI/PAI" name="pai"></el-tab-pane>
        <el-tab-pane
          label="Documento dimissione"
          name="dismissions"
        ></el-tab-pane>
        <el-tab-pane label="Attivitá" name="activities"></el-tab-pane>
        <el-tab-pane label="Eventi Critici" name="criticalEvents"></el-tab-pane>
        <el-tab-pane
          label="Riepilogo Terapia"
          name="therapySummary"
        ></el-tab-pane>
        <el-tab-pane
          label="Riepilogo Pasti"
          name="mealVerificationSummary"
        ></el-tab-pane>
      </el-tabs>
      <component
        :is="activeComponent"
        :profile_id="profile_id"
        :config="config"
        :patient-name="patientName"
        :patient="patient"
        @anamnesiUpdate="anamnesiUpdate()"
        :key="componentKey"
        class="margin-top-10"
      ></component>
    </div>
  </div>
</template>

<script>
import Patient from "@/js/api/services/Patient";
import PatientRegistry from "./components/Registry";
import PatientAnamnesi from "./components/Anamnesi";
import PatientTherapy from "./components/Therapy";
import PatientCartella from "./components/Cartella";
import PatientContacts from "./components/Contacts";
import PatientDrop from "./components/Drop";
import Tests from "./components/Tests";
import Pai from "./components/Pai";
import ArkFile from "@/js/helpers/file";
import Dimissions from "./components/Dimissions";
import PatientActivities from "../../components/Activity/PatientActivities";
import CriticalEvents from "./components/CriticalEvents.vue";
import moment from "moment";
import TherapySummary from "./components/TherapySummary.vue";
import MealVerificationSummary from "./components/MealVerificationSummary.vue";

export default {
  name: "PatientProfile",
  data() {
    return {
      examsType: [
        { id: "tutti", label: "Tutti" },
        { id: "1", label: "Temperatura (°C)" },
        { id: "2", label: "Freq. Cardiaca (Bpm)" },
        { id: "3", label: "Pres. Arteriosa (SIS)" },
        { id: "4", label: "Pres. Arteriosa (DIA)" },
        { id: "5", label: "Freq. Respiratoria (Apm)" },
        { id: "6", label: "SPO2 (%)" },
        { id: "7", label: "Glicemia (mg / dL)" },
        { id: "8", label: "Peso (Kg)" },
        { id: "9", label: "Diuresi (mL)" },
      ],
      professionAll: [
        {
          value: "tutti",
          label: "Tutti",
        },
      ],
      profile_id: this.$route.params.id,
      patient: {},
      loading: true,
      downloadLoading: false,
      dateRange: [moment().format("YYYY-MM-DD"), moment().format("YYYY-MM-DD")],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      exportForm: {
        categories: [],
        type1: "week",
        type2: "last",
        type3: "all",
        type4: null,
        date_from: moment().format("YYYY-MM-DD"),
        date_to: moment().format("YYYY-MM-DD"),
        examsFilter: "",
        profession: null,
      },
      isRequired: true,
      activeName: "cartella",
      activeComponent: "PatientCartella",
      uploadHeaders: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        Authorization: `Bearer ${localStorage.getItem("arkicare_session")}`,
      },
      exportDescription: null,
      showDialog: false,
      showCheckCategories: false,
      componentKey: 0,
    };
  },
  watch: {
    dateRange(newValue) {
      this.exportForm.date_from = newValue[0];
      this.exportForm.date_to = newValue[1];
    },
  },
  components: {
    PatientRegistry,
    PatientAnamnesi,
    PatientTherapy,
    PatientCartella,
    PatientContacts,
    PatientDrop,
    Tests,
    Pai,
    Dimissions,
    PatientActivities,
    CriticalEvents,
    TherapySummary,
    MealVerificationSummary,
  },
  computed: {
    professionSelects() {
      return Object.fromEntries(
        Object.entries(this.$store.state.professions).filter(
          ([id, profession]) => profession.enabled === 1
        )
      );
    },
    isLombardia() {
      return this.$store.state.isLombardia;
    },
    user() {
      return this.$store.state.user;
    },
    config() {
      return this.$store.state.config.anagrafica;
    },
    patientName() {
      return this.patient.name;
    },
    nutrizioneArtificiale() {
      const value = _.get(this.patient, "registry.nutrizione_artificiale");
      return (
        ![0, 5].includes(value) &&
        _.get(this.config, "nutrizione_artificiale." + value)
      );
    },
  },
  methods: {
    dateRangeQueryParam() {
      const date = this.$route.query.date;
      if (!date) return;
      return [
        moment(date).startOf("day").toDate(),
        moment(date).endOf("day").toDate(),
      ];
    },
    emit(type) {
      if (type != "manual") {
        const dateRange = this.dateRangeQueryParam();
        dateRange && (this.exportForm.dateRange = dateRange);
      }
      this.$emit("filtered", { ...this.exportForm, type });
    },
    dateRangeChange() {
      this.emit("manual");
    },
    async fetch() {
      const response = await Patient.profile(this.profile_id);
      this.patient = response.data.patient;
      this.$store.commit("setPatientName", this.patient.full_name);
      this.loading = false;
      this.isRequired = true;
      const { section } = this.$route.query;
      if (section) {
        this.handleClick({ name: section });
      }
    },

    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 4;

      if (!isJPG) {
        this.$message.error("Solo il formato JPG è supportato");
      }
      if (!isLt2M) {
        this.$message.error("Dimensione massima 4MB");
      }
      return isJPG && isLt2M;
    },
    hospitalExport() {
      this.showDialog = true;
      this.exportDescription = null;
    },
    categoriesExport() {
      this.showCheckCategories = true;
    },
    async submitCategoriesExport() {
      if (!this.exportForm.categories.length) {
        this.$message({
          type: "info",
          message: "Nessuna categoria selezionata.",
        });
        return;
      }
      this.downloadLoading = true;
      this.showCheckCategories = false;
      const response = await Patient.exportCategories(
        this.profile_id,
        this.exportForm
      );
      let title = `${this.patient.full_name}_Export_Categorizzato.zip`;
      this.file(response, title);
      if (this.exportForm.categories.includes("diary")) {
        this.exportForm.profession = null;
      }
      if (this.exportForm.categories.includes("exams")) {
        this.exportForm.examsFilter = "";
      }
      this.downloadLoading = false;
      this.exportForm.categories = [];
      this.exportForm.type1 = "week";
      this.exportForm.type2 = "last";
      this.exportForm.type3 = "all";
      this.exportForm.type4 = null;
      (this.dateRange = [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ]),
        (this.exportForm.date_from = null);
      this.exportForm.date_to = null;
    },
    async submitExport() {
      this.downloadLoading = true;
      ArkFile.downloadFromRequest(
        Patient.hospitalExport(this.profile_id, this.exportDescription),
        ""
      ).finally(() => {
        this.downloadLoading = false;
        this.showDialog = false;
      });
    },
    globalExport() {
      this.$confirm(`Esportare la cartella sanitaria?`, "Attenzione", {
        confirmButtonText: "OK",
        cancelButtonText: "Annulla",
        type: "warning",
      })
        .then(() => {
          this.downloadGlobalExport();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operazione annullata",
          });
        });
    },
    async downloadGlobalExport() {
      this.downloadLoading = true;
      const response = await Patient.globalExport(this.profile_id);
      let title = `${this.patient.full_name}_Export_Cartella_Sanitaria.zip`;
      this.file(response, title);
    },
    file(response, title) {
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", title);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.downloadLoading = false;
      this.showDialog = false;
    },
    handleUpload(res, file) {
      this.$message.success("Upload completato");
      this.fetch();
    },
    anamnesiUpdate() {
      this.fetch();
      this.$emit("anamnesiUpdate");
    },
    handleClick(tab, event) {
      const component = {
        cartella: "PatientCartella",
        anagrafica: "PatientRegistry",
        anamnesi: "PatientAnamnesi",
        terapie: "PatientTherapy",
        contatti: "PatientContacts",
        files: "PatientDrop",
        tests: "Tests",
        pai: "Pai",
        dismissions: "Dimissions",
        activities: "PatientActivities",
        criticalEvents: "CriticalEvents",
        therapySummary: "TherapySummary",
        mealVerificationSummary: "MealVerificationSummary",
      }[tab.name];
      if (component) {
        this.activeName = tab.name;
        this.activeComponent = component;
      }
      this.componentKey += 1;
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      async (toParams, fromParams) => {
        if (
          toParams.id != fromParams.id ||
          this.activeName != toParams.section
        ) {
          this.profile_id = toParams.id;
          this.loading = true;
          await this.fetch();
        }
      }
    );
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style lang="scss" scoped>
.box-card {
  .el-card__body {
    padding: 8px !important;
  }

  .clearfix {
    text-align: center;
    span {
      font-weight: bold;
      font-size: 20px;
    }
  }
}
</style>
