<template>
  <div v-if="!loading">
    <el-row :gutter="20" v-if="!registry.hasEducation">
      <el-divider />
      <center
        class="warning"
      >Dato mancante nella scheda del paziente e necessario per la compilazione del test</center>
      <el-divider />
      <el-col :span="8">
        <center>
          <el-form-item label="Anni di scolarizzazione" />
        </center>
      </el-col>
      <el-col :span="8">
        <el-input-number v-model="education_years" :min="0" :max="20" :disabled="serverError" />
      </el-col>
      <el-col :span="8">
        <center>
          <el-button
            type="primary"
            @click="updatePatient"
            :disabled="serverError"
            :loading="updating"
          >Aggiorna Paziente</el-button>
        </center>
      </el-col>
    </el-row>
    <el-form
      label-position="top"
      label-width="80px"
      :model="result"
      ref="form"
      :rules="testRules"
      v-if="registry.hasEducation"
    >
      <el-row :gutter="20">
        <!-- BREAK Orientamento Temporale -->
        <el-col :span="24">
          <el-divider content-position="center">Orientamento Temporale</el-divider>
        </el-col>

        <!-- In che anno siamo? -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="In che anno siamo?" prop="q1_answer">
              <el-input v-model="result.q1_answer"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Esito" prop="q1_result">
              <el-radio-group v-model="result.q1_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>

        <!-- Di che stagione? -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Di che stagione?" prop="q2_answer">
              <el-input v-model="result.q2_answer"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Esito" prop="q2_result">
              <el-radio-group v-model="result.q2_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>

        <!-- In che mese siamo? -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="In che mese siamo?" prop="q3_answer">
              <el-input v-model="result.q3_answer"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Esito" prop="q3_result">
              <el-radio-group v-model="result.q3_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>

        <!-- In che giorno del mese siamo, oggi? -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="In che giorno del mese siamo, oggi?" prop="q4_answer">
              <el-input v-model="result.q4_answer"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Esito" prop="q4_result">
              <el-radio-group v-model="result.q4_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>

        <!-- Che giorno della settimana è, oggi? -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Che giorno della settimana è, oggi?" prop="q5_answer">
              <el-input v-model="result.q5_answer"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Esito" prop="q5_result">
              <el-radio-group v-model="result.q5_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>

        <!-- BREAK Orientamento Spaziale -->
        <el-col :span="24" class="spaced">
          <el-divider content-position="center">Orientamento Spaziale</el-divider>
        </el-col>

        <!-- In che nazione siamo? -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="In che nazione siamo?" prop="q6_answer">
              <el-input v-model="result.q6_answer"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Esito" prop="q6_result">
              <el-radio-group v-model="result.q6_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>

        <!-- In quale regione? -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="In quale regione?" prop="q7_answer">
              <el-input v-model="result.q7_answer"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Esito" prop="q7_result">
              <el-radio-group v-model="result.q7_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>

        <!-- In quale città? -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="In quale città?" prop="q8_answer">
              <el-input v-model="result.q8_answer"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Esito" prop="q8_result">
              <el-radio-group v-model="result.q8_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>

        <!-- Dove ci troviamo ora? -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Dove ci troviamo ora?" prop="q9_answer">
              <el-input v-model="result.q9_answer"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Esito" prop="q9_result">
              <el-radio-group v-model="result.q9_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>

        <!-- A che piano? -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="A che piano?" prop="q10_answer">
              <el-input v-model="result.q10_answer"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Esito" prop="q10_result">
              <el-radio-group v-model="result.q10_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>

        <el-col :span="24" class="spaced">
          <!-- BREAK Memoria di Fissazione -->
          <el-col :span="24">
            <el-divider content-position="center">Memoria di Fissazione</el-divider>
          </el-col>

          <!-- Riperete tre parole: Pane Casa Gatto -->
          <el-col :span="24">
            <el-form-item label="Riperete tre parole: Pane Casa Gatto" prop="q11_result">
              <el-slider
                v-model="result.q11_result"
                :step="1"
                :max="3"
                show-stops
                :marks="marks_0_3"
                :show-tooltip="false"
              ></el-slider>
            </el-form-item>
          </el-col>

          <!-- BREAK Calcolo -->
          <el-col :span="24" class="spaced">
            <el-divider content-position="center">Calcolo</el-divider>
          </el-col>

          <!-- Togliere 7 da 100 per 5 volte: 93 86 79 72 65 / Ripetere la parola MONDO al contrario: ODNOM -->
          <el-col :span="24">
            <el-form-item
              label="Togliere 7 da 100 per 5 volte: 93 86 79 72 65 / Ripetere la parola MONDO al contrario: ODNOM"
              prop="q12_result"
            >
              <el-slider
                v-model="result.q12_result"
                :step="1"
                :max="5"
                show-stops
                :marks="marks_0_5"
                :show-tooltip="false"
              ></el-slider>
            </el-form-item>
          </el-col>

          <!-- BREAK Memoria di Rievocazione -->
          <el-col :span="24" class="spaced">
            <el-divider content-position="center">Memoria di Rievocazione</el-divider>
          </el-col>

          <!-- Ripetere le tre parole: Pane Casa Gatto -->
          <el-col :span="24">
            <el-form-item label="Ripetere le tre parole: Pane Casa Gatto" prop="q13_result">
              <el-slider
                v-model="result.q13_result"
                :step="1"
                :max="3"
                show-stops
                :marks="marks_0_3"
                :show-tooltip="false"
              ></el-slider>
            </el-form-item>
          </el-col>

          <!-- BREAK Linguaggio -->
          <el-col :span="24" class="spaced">
            <el-divider content-position="center">Linguaggio</el-divider>
          </el-col>

          <!-- Far nominare una penna ed un orologio indicandoli al pz -->
          <el-col :span="24">
            <el-form-item
              label="Far nominare una penna ed un orologio indicandoli al pz"
              prop="q14_result"
            >
              <!-- <span class="demonstration">Far nominare una penna ed un orologio indicandoli al pz</span> -->
              <el-slider
                v-model="result.q14_result"
                :step="1"
                :max="2"
                show-stops
                :marks="marks_0_2"
                :show-tooltip="false"
              ></el-slider>
            </el-form-item>
          </el-col>

          <!-- Eseguire: "Prenda un foglio con la mano dx, lo pieghi a metà e lo butti via" -->
          <el-col :span="24">
            <el-form-item
              label="Eseguire: Prenda un foglio con la mano dx, lo pieghi a metà e lo butti via"
              prop="q15_result"
            >
              <el-slider
                v-model="result.q15_result"
                :step="1"
                :max="3"
                show-stops
                :marks="marks_0_3"
                :show-tooltip="false"
              ></el-slider>
            </el-form-item>
          </el-col>

          <!-- Ripetere: "Non c'è ne sè ne ma che tenga" -->
          <el-col :span="12">
            <el-form-item label="Ripetere: Non c&#39;è ne sè ne ma che tenga" prop="q16_result">
              <el-radio-group v-model="result.q16_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <!-- Far leggere ed eseguire: "Chiuda gli occhi" -->
          <el-col :span="12">
            <el-form-item label="Far leggere ed eseguire: Chiuda gli occhi" prop="q17_result">
              <el-radio-group v-model="result.q17_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <!-- Frase di senso compiuto -->
          <el-col :span="12">
            <el-form-item label="Frase di senso compiuto" prop="q18_result">
              <el-radio-group v-model="result.q18_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>

          <!-- Coppia di pentagoni intrecciati -->
          <el-col :span="12">
            <el-form-item label="Coppia di pentagoni intrecciati" prop="q19_result">
              <el-radio-group v-model="result.q19_result">
                <el-radio :label="1" border>Corretto</el-radio>
                <el-radio :label="0" border>Errato</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="12">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Corretto">
              <el-input v-model="result.correct_total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Totale con coefficiente">
              <el-input v-model="this.form.total_with_factor" :disabled="true" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Valutazione del punteggio MMSE">
              <el-input v-model="totalDescription" :disabled="true" />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template>
<script>
import { MMSE } from "../constants";
import { isDev } from "@/js/helpers";
import Patient from "@/js/api/services/Patient";
import TestActions from "../components/TestActions";

export default {
  name: "MiniMentalStateExamination",
  props: ["loading", "patientRegistry", "patientId"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 30,
        total_with_factor: 0
      },
      education_years: null,
      registry: {},
      result: {
        correct_total: 0
      },
      updating: false,
      serverError: false,
      marks_0_2: {
        0: "0",
        1: "1",
        2: "2"
      },
      marks_0_3: {
        0: "0",
        1: "1",
        2: "2",
        3: "3"
      },
      marks_0_5: {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5"
      },
      testRules: {
        q1_answer: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q1_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q2_answer: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q2_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q3_answer: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q3_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q4_answer: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q4_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q5_answer: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q5_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q6_answer: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q6_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q7_answer: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q7_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q8_answer: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q8_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q9_answer: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q9_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q10_answer: [
          { required: true, message: "Campo Obbligatorio", trigger: "blur" }
        ],
        q10_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q11_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q12_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q13_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q14_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q15_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q16_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q17_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q18_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q19_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ]
      }
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let result = {
            ...this.result,
            ...MMSE.educationMap(this.registry),
            total_with_factor: this.form.total_with_factor,
            total_description: this.totalDescription
          };
          this.$emit("submitTest", this.form, result);
        } else {
          this.onInvalidForm();
          return false;
        }
      });
    },
    onInvalidForm() {
      this.$notify({
        title: "Attenzione",
        message: "Ci sono campi obbligatori che devono essere compilati",
        type: "error"
      });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    },
    filterResults() {
      return _.pickBy(this.result, (_, key) => key.endsWith("result"));
    },
    processRegistry(response) {
      const yearsPath = ["data", "items", "education_years"];
      const idPath = ["data", "items", "id"];
      const agePath = ["data", "patient", "age"];

      let educationYears = _.get(response, yearsPath);
      let id = _.get(response, idPath);
      let age = _.get(response, agePath);
      this.registry = {
        age,
        educationYears,
        id,
        hasEducation: !_.isNil(educationYears)
      };
      this.serverError = false;
    },
    onLastRegistryFailed(error) {
      this.serverError = true;
      let { exception, message, file } = _.get(error, ["response", "data"]);
      message = JSON.stringify({ file, message }, null, 2);
      if (isDev()) {
        this.$alert(message, "DEV", {
          confirmButtonText: "OK",
          center: true,
          type: "error"
        });
      }
    },
    hideLoader() {
      this.$emit("update:loading", false);
    },
    showLoader() {
      this.$emit("update:loading", true);
    },
    fetchLastRegistry() {
      this.showLoader();
      Patient.registry(this.patientId)
        .then(this.processRegistry)
        .catch(this.onLastRegistryFailed)
        .finally(this.hideLoader);
    },
    onUpdateFailed(error) {
      this.$notify({
        title: "Errore",
        message: "Errore durante l'aggiornamento dei dati del paziente",
        type: "error"
      });
    },
    updatePatient() {
      let payload = { education_years: this.education_years };
      this.updating = true;
      Patient.patch(this.registry.id, payload)
        .then(this.fetchLastRegistry)
        .catch(this.onUpdateFailed)
        .finally(() => (this.updating = false));
    },
    computeWithFactor(total) {
      let { factor } = MMSE.educationMap(this.registry);
      let floatFactor = parseFloat(factor);
      return _.add(total, floatFactor);
    }
  },
  computed: {
    totalDescription() {
      return MMSE.totalDescription(this.result);
    },
    showActions() {
      const formValues = _.values(this.result);
      return !formValues.every(item => item === 0);
    }
  },
  watch: {
    result: {
      handler: function() {
        if (this.loading) return;
        let results = _.values(this.filterResults());
        let total = results.reduce(_.add, 0);
        this.form.total = total;
        let compute = this.computeWithFactor(total);
        if(compute <= 30){
            this.result.correct_total = compute;
        } else{
            this.result.correct_total = 30;
        }
        this.form.total_with_factor = this.computeWithFactor(total);
      },
      deep: true
    }
  },
  created() {
    this.fetchLastRegistry();
  }
};
</script>
<style scoped>
.warning {
  color: red;
  font-weight: bold;
}
.spaced {
  margin-top: 50px;
}
.bottom-space {
  margin-bottom: 50px;
}
</style>
