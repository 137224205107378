<template>
  <el-row v-loading="loading" :gutter="20" class="no-margin-row">
    <el-card class="box-card">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-button class="float-left" round :disabled="disablePrev" @click="show(test_prev_cirs)">
            <font-awesome-icon icon="long-arrow-alt-left" />Indietro
          </el-button>
        </el-col>


        <el-col :span="12" class="text-center titleCol">
          <span :class="{ 'deleted-row': test_cirs.enabled == 0 }">
            <b class="title">{{ this.test_name }}</b>
            <br />
            <small>{{ test_cirs.clean_date_time_test }}</small>
          </span>
        </el-col>

        <el-col :span="6">
          <el-button
            class="float-right"
            round
            :disabled="disableNext"
            @click="show(test_next_cirs)"
          >
            Avanti
            <font-awesome-icon icon="long-arrow-alt-right" />
          </el-button>
        </el-col>
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <tests-disable :test="test_cirs" :test_model="test_model_cirs" @showparent="show" />
      </el-row>
      <br />
      <br />

      <el-row :gutter="20">
        <el-form ref="form" label-position="top" label-width="80px" disabled>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider content-position="center"></el-divider>

              <el-col :span="24">
                <el-col :span="8">
                  <el-form-item label="  " />
                </el-col>
                <el-col :span="16">
                  <el-row type="flex" class="row-bg" justify="center">
                    <el-col>
                      <center class="head">Assente</center>
                    </el-col>
                    <el-col>
                      <center class="head">Lieve</center>
                    </el-col>
                    <el-col>
                      <center class="head">Moderato</center>
                    </el-col>
                    <el-col>
                      <center class="head">Grave</center>
                    </el-col>

                    <el-col>
                      <center class="head">Molto Grave</center>
                    </el-col>
                  </el-row>
                  <el-divider content-position="center"></el-divider>
                </el-col>
              </el-col>

              <el-col :span="24" v-for="(item, idx) in items" :key="idx">
                <el-form-item :prop="`q${++idx}_result`">
                  <el-col :span="8">
                    <el-form-item :label="item" required />
                  </el-col>
                  <el-col :span="16">
                    <el-row type="flex" justify="center">
                      <el-col v-for="opt in 5" :key="opt + '-opt'">
                        <el-col>
                          <center>
                            <el-radio :label="opt" v-model="test_cirs[`q${idx}_result`]" />
                          </center>
                        </el-col>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-form-item>
                <el-divider content-position="center"></el-divider>
              </el-col>
            </el-col>
          </el-row>

          <!-- TOTAL -->
          <el-row :gutter="20">
            <el-col :span="24">
              <el-col :span="8">
                <el-form-item label="Indice di severitá">
                  <el-input v-model="test_cirs.total_sev" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Indice di comorbiditá">
                  <el-input v-model="test_cirs.total_com" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="Totale">{{test_cirs.total_string}}</el-form-item>
              </el-col>
            </el-col>
          </el-row>
        </el-form>
      </el-row>
    </el-card>
  </el-row>
</template>

<script>
import moment from "moment";
import Test from "@/js/api/services/Test";
import Tests from "@/js/pages/Patients/components/Tests";
import TestsDisable from "@/js/pages/Patients/components/TestsDisable";

export default {
  name: "cirs",
  components: {
    TestsDisable,
  },
  props: [
    "test",
    "test_prev",
    "test_next",
    "test_name",
    "test_model",
    "test_type"
  ],
  data() {
    return {
      test_cirs: this.test,
      test_prev_cirs: this.test_prev,
      test_next_cirs: this.test_next,
      test_name_cirs: this.test_name,
      test_model_cirs: this.test_model,
      test_type_cirs: this.test_type,
      patient_id_cirs: this.test.patient_id,
      loading: true,
      disablePrev: false,
      disableNext: false,
      items: [
        "Patologie cardiache (solo cuore)",
        "Ipertensione (si valuta la severità, gli organi coinvolti sono da considerarsi separatamente)",
        "Patologie vascolari (sangue, vasi, midollo, milza, sistema linfatico)",
        "Patologie respiratorie (polmoni, bronchi, trachea sotto la laringe)",
        "O.O.N.G.L. (occhio, orecchio, naso, gola, laringe)",
        "Apparato GI superiore (esofago, stomaco, duodeno, albero biliare, pancreas)",
        "Apparato GI inferiore (intestino, ernie)",
        "Patologie epatiche (solo fegato)",
        "Patologie renali (solo rene)",
        "Altre patologie genito-urinarie (ureteri, vescica, uretra, prostata, genitali ",
        "Sistema muscolo-scheletro-cute, muscoli, scheletro, tegumenti)",
        "Patologie sistema nervoso (sistema nervoso centrale e periferico; non include la demenza)",
        "Patologie endocrine-metaboliche (include diabete, infezioni, sepsi, stati tossici)",
        "Patologie psichiatriche-comportamentali (include demenza, depressione, ansia, agitazione, psicosi)"
      ]
    };
  },
  methods: {
    async show(test_id) {
      this.loading = true;

      let params = {
        patient_id: this.patient_id_cirs,
        test_model: this.test_model_cirs,
        test_id: test_id,
        type_id: this.test_type_cirs
      };

      const response = await Test.show(params);
      this.test_cirs = response.data.test;
      this.test_name_cirs = response.data.test_name;
      this.test_prev_cirs = response.data.test_prev;
      this.test_next_cirs = response.data.test_next;

      this.checkPrevNext();
    },
    checkPrevNext() {
      if (this.test_prev_cirs === null) this.disablePrev = true;
      else this.disablePrev = false;

      if (this.test_next_cirs === null) this.disableNext = true;
      else this.disableNext = false;

      this.loading = false;
    }
  },
  beforeMount() {
    this.checkPrevNext();
  }
};
</script>
