<template>
  <div>
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-row :gutter="20">
        <el-col :span="12">
          <!-- A -->
          <el-col :span="24">
            <el-form-item label="A - Percezione sensoriale" prop="q1_result">
              <el-select v-model="result.q1_result" placeholder="Seleziona valore">
                <el-option
                  v-for="item in options_A"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- B -->
          <el-col :span="24">
            <el-form-item label="B - Umidità" prop="q2_result">
              <el-select v-model="result.q2_result" placeholder="Seleziona valore">
                <el-option
                  v-for="item in options_B"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- C -->
          <el-col :span="24">
            <el-form-item label="C - Attività" prop="q3_result">
              <el-select v-model="result.q3_result" placeholder="Seleziona valore">
                <el-option
                  v-for="item in options_C"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :span="12">
          <!-- D -->
          <el-col :span="24">
            <el-form-item label="D - Mobilità" prop="q4_result">
              <el-select v-model="result.q4_result" placeholder="Seleziona valore">
                <el-option
                  v-for="item in options_D"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- E -->
          <el-col :span="24">
            <el-form-item label="E - Nutrizione" prop="q5_result">
              <el-select v-model="result.q5_result" placeholder="Seleziona valore">
                <el-option
                  v-for="item in options_E"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- F -->
          <el-col :span="24">
            <el-form-item label="F - Frizione e scivolamento" prop="q6_result">
              <el-select v-model="result.q6_result" placeholder="Seleziona valore">
                <el-option
                  v-for="item in options_F"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template> 
<script>
import TestActions from "../components/TestActions";
export default {
  name: "Braden",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 23
      },
      result: {},
      options_A: [
        {
          value: 1,
          label: "Completamente limitata"
        },
        {
          value: 2,
          label: "Molto limitata"
        },
        {
          value: 3,
          label: "Leggermente limitata"
        },
        {
          value: 4,
          label: "Non limitata"
        }
      ],
      options_B: [
        {
          value: 1,
          label: "Sempre bagnato"
        },
        {
          value: 2,
          label: "Spesso bagnato"
        },
        {
          value: 3,
          label: "Occasionalmente bagnato"
        },
        {
          value: 4,
          label: "Raramente bagnato"
        }
      ],
      options_C: [
        {
          value: 1,
          label: "Allettato"
        },
        {
          value: 2,
          label: "In poltrona"
        },
        {
          value: 3,
          label: "Cammina occasionalmente"
        },
        {
          value: 4,
          label: "Cammina frequentemente"
        }
      ],
      options_D: [
        {
          value: 1,
          label: "Immobile"
        },
        {
          value: 2,
          label: "Mobilità molto limitata"
        },
        {
          value: 3,
          label: "Mobilità parzialmente limitata"
        },
        {
          value: 4,
          label: "Limitazioni assenti"
        }
      ],
      options_E: [
        {
          value: 1,
          label: "Inadeguata"
        },
        {
          value: 2,
          label: "Probabilmente inadeguata"
        },
        {
          value: 3,
          label: "Adeguata"
        },
        {
          value: 4,
          label: "Eccellente"
        }
      ],
      options_F: [
        {
          value: 1,
          label: "Problema"
        },
        {
          value: 2,
          label: "Problema potenziale"
        },
        {
          value: 3,
          label: "Senza problemi"
        }
      ],
      testRules: {
        q1_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q2_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q3_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q4_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q5_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ],
        q6_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ]
      }
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$emit("submitTest", this.form, this.result);
        } else {
          return false;
        }
      });
    },
    filterResults() {
      return _.pickBy(this.result, (_, key) => key.endsWith("result"));
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(this.filterResults());
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>