<script>
export default {
  name: 'SelectAts',
  inheritAttrs: false,
  props: ['type', 'value', 'defaultValue'],
  data() {
    return {
      loadingSelect: false,
      items: []
    };
  },
  methods: {
    fetchAts(query) {
      const valid = query !== '' && query.length > 2;
      if (!valid) return;
      this.loadingSelect = true;
      let params = { string: query };
      if (this.type != 'code') {
        params.regioni = 1;
        params.stati = 1;
      }
      axios
        .get('/config/ats', { params })
        .then(({ data }) => {
          this.items = data;
        })
        .finally(() => (this.loadingSelect = false));
    },
    onChange(x) {
      this.$emit('input', x);
    },
    onClear() {
      this.$emit('input', null);
      this.items = [];
    }
  },
  created() {
    if (this.defaultValue) {
      this.items = [this.defaultValue];
      this.$emit('input', this.defaultValue.cod_asl);
    }
  }
};
</script>

<template>
  <el-select
    v-bind="$attrs"
    :value="value"
    @change="onChange"
    @clear="onClear"
    clearable
    filterable
    remote
    :remote-method="fetchAts"
    :loading="loadingSelect"
  >
    <el-option v-for="item in items" :key="item.id" :label="item.desc_asl" :value="item.cod_asl"> </el-option>
  </el-select>
</template>

<style lang="scss" scoped>
</style>