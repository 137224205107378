import Api from '@/js/api/Api';
import ArkiBaseService from './ArkiBaseService';

export default class Medication extends ArkiBaseService {

    static endpoint = 'medications';

    static show(id) {
        return Api.get(`${this.endpoint}/${id}`);
    }

    static showPrevious(id) {
        return Api.get(`${this.endpoint}/${id}/prev`);
    }

    static store(id, params) {
        return Api.patch(`${this.endpoint}/${id}`, params);
    }

    static reschedule(id, params) {
        return Api.post(`${this.endpoint}/${id}/reschedule`, params);
    }

}