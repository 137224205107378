<template>
  <div id="test_nrs">
    <el-form label-position="top" label-width="80px" :model="result" ref="form" :rules="testRules">
      <el-form-item prop="q1_result">
        <el-row :gutter="20" justify="center">
          <el-col :span="24">
            <el-col :span="6" :offset="6">Assenza dolore</el-col>
            <el-col :span="12">
              <el-radio v-model="result.q1_result" :label="0" value="0" border>0</el-radio>
            </el-col>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-col :span="6" :offset="6">Dolore leggero</el-col>
            <el-col :span="12">
              <el-radio v-model="result.q1_result" :label="1" value="1" border prop="q1_result">1</el-radio>
            </el-col>
            <el-col :span="12" :offset="12">
              <el-radio v-model="result.q1_result" :label="2" value="2" border prop="q1_result">2</el-radio>
            </el-col>
            <el-col :span="12" :offset="12">
              <el-radio v-model="result.q1_result" :label="3" value="3" border prop="q1_result">3</el-radio>
            </el-col>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-col :span="6" :offset="6">Dolore moderato</el-col>
            <el-col :span="12">
              <el-radio v-model="result.q1_result" :label="4" value="4" border prop="q1_result">4</el-radio>
            </el-col>
            <el-col :span="12" :offset="12">
              <el-radio v-model="result.q1_result" :label="5" value="5" border prop="q1_result">5</el-radio>
            </el-col>
            <el-col :span="12" :offset="12">
              <el-radio v-model="result.q1_result" :label="6" value="6" border prop="q1_result">6</el-radio>
            </el-col>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-col :span="6" :offset="6">Dolore severo</el-col>
            <el-col :span="12">
              <el-radio v-model="result.q1_result" :label="7" value="7" border prop="q1_result">7</el-radio>
            </el-col>
            <el-col :span="12" :offset="12">
              <el-radio v-model="result.q1_result" :label="8" value="8" border prop="q1_result">8</el-radio>
            </el-col>
            <el-col :span="12" :offset="12">
              <el-radio v-model="result.q1_result" :label="9" value="9" border prop="q1_result">9</el-radio>
            </el-col>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-col :span="6" :offset="6">Peggior dolore possibile</el-col>
            <el-col :span="12">
              <el-radio v-model="result.q1_result" :label="10" value="10" border prop="q1_result">10</el-radio>
            </el-col>
          </el-col>
        </el-row>
      </el-form-item>
      <el-row :gutter="20" class="bottom-space">
        <!-- BREAK Submit -->
        <el-col :span="24">
          <el-divider></el-divider>
        </el-col>
        <!-- submit -->
        <el-col :span="24">
          <el-col :span="8">
            <el-form-item label="Totale">
              <el-input v-model="form.total" :disabled="true">
                <template slot="append">/ {{form.total_quest}}</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-divider></el-divider>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Note">
              <el-input type="textarea" v-model="result.note"></el-input>
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
    <TestActions @reset="resetForm" @save="submit" :loading="loading" :show-actions="showActions" />
  </div>
</template>
<script>
import TestActions from "../components/TestActions";

export default {
  name: "Nrs",
  props: ["loading"],
  components: { TestActions },
  data() {
    return {
      form: {
        total: 0,
        total_quest: 10
      },
      result: {},
      testRules: {
        q1_result: [
          { required: true, message: "Campo Obbligatorio", trigger: "change" }
        ]
      }
    };
  },
  methods: {
    async submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.$emit("submitTest", this.form, this.result);
        } else {
          return false;
        }
      });
    },
    filterResults() {
      return _.pickBy(this.result, (_, key) => key.endsWith("result"));
    },
    resetForm() {
      this.$refs["form"].resetFields();
      _.keys(this.result).map(k => this.$delete(this.result, k));
    }
  },
  computed: {
    showActions() {
      const formValues = _.values(this.result);
      return !_.every(formValues, _.isNil);
    }
  },
  watch: {
    result: {
      handler: function() {
        let results = _.values(this.filterResults());
        let total = results.reduce(_.add, 0);
        this.form.total = total;
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.bottom-space {
  margin-bottom: 50px;
}
</style>
